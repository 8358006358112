import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import LoaderIcon from "react-loader-icon";
import Select from "react-select";
import BarChart from "./BarChart";
import Filter from "./Filter";
import LineChart from "./LineChart";
import Timeline from "./Timeline";

const hexToRgb = (hex) => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return [r, g, b];
};

const generateGradualColors = (numColors, s, e) => {
  const colors = [];
  const startColor = hexToRgb(s);
  const endColor = hexToRgb(e);

  for (let i = 0; i < numColors; i++) {
    const r = Math.round(
      startColor[0] + (endColor[0] - startColor[0]) * (i / (numColors - 1))
    );
    const g = Math.round(
      startColor[1] + (endColor[1] - startColor[1]) * (i / (numColors - 1))
    );
    const b = Math.round(
      startColor[2] + (endColor[2] - startColor[2]) * (i / (numColors - 1))
    );
    colors.push(`rgba(${r}, ${g}, ${b}, 1)`);
  }

  return colors;
};

export default function Overall(props) {
  const {
    userTimelineLoading,
    events,
    timelineRef,
    weeklyTimeline,
    weeklyTimelineLoading,
    topActionsLoading,
    topActions,
    topUsers,
    topUsersLoading,
  } = props;

  return (
    <div id="overallAnalytics">
      <div style={{ marginTop: "20px", width: "100%" }}>
        {userTimelineLoading ? (
          <LoaderIcon type="spin" style={{ fill: "#FBCD4E" }} />
        ) : (
          events &&
          events.length > 0 && (
            <>
              <Timeline timelineRef={timelineRef} events={events} x={10.55} />
            </>
          )
        )}
      </div>
      <div style={{ marginTop: "20px", width: "100%" }}>
        {weeklyTimelineLoading ? (
          <div
            style={{
              height: "400px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <LoaderIcon type="spin" style={{ fill: "#FBCD4E" }} />
          </div>
        ) : (
          <div
            style={{
              backgroundColor: "#222831",
              padding: "20px",
              borderRadius: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "10px",
              }}
            >
              <div>
                <h3 className="cyberFont yellowColor">
                  LAST {props.timeframe.label} ACTIONS
                </h3>
              </div>
              <div style={{ position: "relative", top: "-5px" }}>
                <Select
                  value={props.timeframe}
                  defaultValue={props.timeframe}
                  onChange={props.setTimeframe}
                  placeholder={props.placeholder}
                  className="desktop searchBy timelineFilters"
                  options={props.options}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    backgroundColor: "black",
                    colors: {
                      ...theme.colors,
                      primary25: "#FBCD4E",
                      primary: "black",
                    },
                  })}
                />
              </div>
            </div>
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
                width: "60%",
                flexDirection: "row",
                // columnGap: "5px",
                backgroundColor: "#1A1C20",
                borderRadius: "10px",
                position: "relative",
              }}
            >
              <div style={{ display: "block", padding: "15px 5px 15px 15px" }}>
                <Filter
                  placeholder="Filter by User"
                  onChange={props.onTimelineUserFilterChange}
                  onSubmit={props.onTimelineFilter}
                  value={props.userFilter}
                  clear={props.clearTimelineUserFilter}
                />
                {props.userSuggestions && props.userSuggestions.length > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      backgroundColor: "#1A1C20",
                      width: "300px",
                      zIndex: 2,
                    }}
                  >
                    {props.userSuggestions.map((item, index) => (
                      <div
                        onClick={() => {
                          props.setTimelineUserFilter(item.user);
                          props.setUserSuggestions([]);
                        }}
                        className="yellowBgOnHover"
                        key={index}
                        style={{
                          padding: "15px",
                          borderBottom: "1px solid #FBCD4E",
                          cursor: "pointer",
                          fontSize: "14px",
                        }}
                      >
                        {item.user}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div style={{ display: "block" }}>
                <Filter
                  placeholder="Filter by Action"
                  onChange={props.onTimelineActionFilterChange}
                  onSubmit={props.onTimelineFilter}
                  value={props.actionFilter}
                  clear={props.clearTimelineActionFilter}
                />
                {props.actionSuggestions &&
                  props.actionSuggestions.length > 0 && (
                    <div
                      style={{
                        position: "absolute",
                        backgroundColor: "#1A1C20",
                        width: "300px",
                        zIndex: 2,
                      }}
                    >
                      {props.actionSuggestions.map((item, index) => (
                        <div
                          onClick={() => {
                            let value = `${item.action} - ${item.type
                              .split("_")
                              .join(" ")}`.toLocaleUpperCase();
                            props.setTimelineActionFilter(value);
                            props.setActionSuggestions([]);
                          }}
                          className="yellowBgOnHover"
                          key={index}
                          style={{
                            padding: "15px",
                            borderBottom: "1px solid #FBCD4E",
                            cursor: "pointer",
                            fontSize: "14px",
                          }}
                        >
                          {item.action.toLocaleUpperCase()} -{" "}
                          {item.type.split("_").join(" ").toLocaleUpperCase()}
                        </div>
                      ))}
                    </div>
                  )}
              </div>
              <div style={{ position: "absolute", right: "0px" }}>
                <IconButton
                  onClick={props.onTimelineFilter}
                  // type="submit"
                  aria-label="search"
                  style={{ padding: "0px" }}
                >
                  <SearchIcon
                    style={{
                      fill: "#1A1C20",
                      backgroundColor: "#E69600",
                      fontSize: "52px",
                      borderRadius: "50px 0px 0px 50px",
                      padding: "5px",
                    }}
                  />
                </IconButton>
              </div>
            </div>

            <hr />
            {props.lineChartLoading ? (
              <div
                style={{
                  height: "400px",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <LoaderIcon type="spin" style={{ fill: "#FBCD4E" }} />
              </div>
            ) : weeklyTimeline && weeklyTimeline.length > 0 ? (
              <LineChart
                indexAxis="x"
                data={weeklyTimeline}
                title={`LAST ${props.timeframe.label} ACTIONS`}
                labels={weeklyTimeline.map((s) => s.day)}
                backgroundColor={"#FDA503"}
                // backgroundColor={generateGradualColors(
                //   weeklyTimeline.length,
                //   "#FDCD4E",
                //   "#FDA503"
                // )}
              />
            ) : (
              <div
                style={{
                  height: "400px",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <h3>NO RESULTS FOUND</h3>
              </div>
            )}
          </div>
        )}
      </div>
      <div style={{ marginTop: "20px", width: "100%" }}>
        {topActionsLoading ? (
          <LoaderIcon type="spin" style={{ fill: "#FBCD4E" }} />
        ) : (
          topActions &&
          topActions.length > 0 && (
            <div
              style={{
                backgroundColor: "#222831",
                padding: "20px",
                borderRadius: "20px",
              }}
            >
              <h3 className="cyberFont yellowColor">TOP ACTIONS (20)</h3>
              <hr />
              <BarChart
                indexAxis="x"
                data={topActions}
                title={"TOP ACTIONS (20)"}
                labels={topActions.map((s) =>
                  `${s.action} - ${s?.type
                    ?.split("_")
                    ?.join(" ")}`.toLocaleUpperCase()
                )}
                backgroundColor={generateGradualColors(
                  topActions.length,
                  "#FDCD4E",
                  "#FDA503"
                )}
              />
            </div>
          )
        )}
      </div>
      <div style={{ marginTop: "20px", width: "100%" }}>
        {topUsersLoading ? (
          <LoaderIcon type="spin" style={{ fill: "#FBCD4E" }} />
        ) : (
          topUsers &&
          topUsers.length > 0 && (
            <div
              style={{
                backgroundColor: "#222831",
                padding: "20px",
                borderRadius: "20px",
              }}
            >
              <h3 className="cyberFont yellowColor">
                TOP USERS - ACTION COUNT (20)
              </h3>
              <hr />
              <BarChart
                data={topUsers}
                title={"TOP USERS (20)"}
                labels={topUsers.map((s) => s.email)}
                indexAxis="y"
                backgroundColor={generateGradualColors(
                  topUsers.length,
                  "#FDA503",
                  "#FDCD4E"
                )}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
}
