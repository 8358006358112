/* eslint-disable */

import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import React, { useState } from "react";
import LoaderIcon from "react-loader-icon";
import CreateApiClient from "../../API";
import { adminUrl } from "../../Config";
import CompanyDataTable from "../partials/CompanyDataTable";
import ToggleButtons from "../partials/Toggle";
import MultiUserSelection from "./MultiUserSelection";
import Permissions from "./Permissions";

const api = CreateApiClient();

export default function BulkUserEdit(props) {
  const [users, setUsers] = useState([]);
  const [usersSelected, setUsersSelected] = useState([]);
  const [domains, setDomains] = useState([]);
  const [domainsInput, setDomainsInput] = useState("");

  const [apks, setApks] = useState([]);
  const [apksInput, setApksInput] = useState("");

  const [externals, setExternals] = useState([]);
  const [externalsInput, setExternalsInput] = useState("");

  const [alertEmails, setAlertEmails] = useState([]);
  const [alertEmailsInput, setAlertEmailsInput] = useState("");

  const [domainsToRemove, setDomainsToRemove] = useState([]);
  const [domainsInputToRemove, setDomainsInputToRemove] = useState("");

  const [apksToRemove, setApksToRemove] = useState([]);
  const [apksInputToRemove, setApksInputToRemove] = useState("");

  const [externalsToRemove, setExternalsToRemove] = useState([]);
  const [externalsInputToRemove, setExternalsInputToRemove] = useState("");

  const [alertEmailsToRemove, setAlertEmailsToRemove] = useState([]);
  const [alertEmailsInputToRemove, setAlertEmailsInputToRemove] = useState("");

  const [permissions, setPermissions] = useState([]);
  const [censoredFields, setCensoredFields] = useState([]);
  const [uiPermissions, setUiPermissions] = useState([]);

  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [permissionsMode, setPermissionsMode] = useState("none");

  function handleUiPermissionChange(value) {
    if (!uiPermissions.includes(value)) {
      setUiPermissions([...uiPermissions, value]);
    } else {
      setUiPermissions(uiPermissions.filter((p) => p !== value));
    }
  }

  function handlePermissionChange(value) {
    if (!permissions.includes(value)) {
      setPermissions([...permissions, value]);
    } else {
      setPermissions(permissions.filter((p) => p !== value));
    }
  }

  function handleCensoredFieldsChange(value) {
    if (!censoredFields.includes(value)) {
      setCensoredFields([...censoredFields, value]);
    } else {
      setCensoredFields(censoredFields.filter((f) => f !== value));
    }
  }

  const addAlertEmails = (list, input, set, setInput) => {
    let _domains = input.replace(/ /, "").split(",");
    let filtered = _domains.filter((d) => !list.includes(d));
    set([...list, ...filtered]);
    setInput("");
  };

  const removeAlertEmail = (list, _domain, set) => {
    let filtered = list.filter((d) => d !== _domain);
    set(filtered);
  };

  const addDomains = (list, input, set, setInput) => {
    let _domains = input.replace(/ /, "").split(",");
    let filtered = _domains.filter((d) => !list.includes(d));
    set([...list, ...filtered]);
    setInput("");
  };

  const removeDomain = (list, _domain, set) => {
    let filtered = list.filter((d) => d !== _domain);
    set(filtered);
  };

  const addApks = (list, input, set, setInput) => {
    let _apks = input.replace(/ /, "").split(",");
    let filtered = _apks.filter((d) => !list.includes(d));
    set([...list, ...filtered]);
    setInput("");
  };

  const removeApk = (list, _apk, set) => {
    let filtered = list.filter((a) => a !== _apk);
    set(filtered);
  };

  const addExternalDomains = (list, input, set, setInput) => {
    let _domains = input.replace(/ /, "").split(",");
    let filtered = _domains.filter((d) => !list.includes(d));
    set([...list, ...filtered]);
    setInput("");
  };

  const removeExternalDomain = (list, _domain, set) => {
    let filtered = list.filter((d) => d !== _domain);
    set(filtered);
  };

  const searchersData = [
    {
      name: "Alert Emails",
      field: "alert_emails",
      list: alertEmails,
      removeFunc: (email) =>
        removeAlertEmail(alertEmails, email, setAlertEmails),
      addFunc: () =>
        addAlertEmails(
          alertEmails,
          alertEmailsInput,
          setAlertEmails,
          setAlertEmailsInput
        ),
      inputValue: alertEmailsInput,
      onChange: (e) => setAlertEmailsInput(e.target.value),
    },
  ];

  const selectorsData = [
    {
      name: "Domains",
      field: "domains",
      list: domains,
      removeFunc: (domain) => removeDomain(domains, domain, setDomains),
      addFunc: () =>
        addDomains(domains, domainsInput, setDomains, setDomainsInput),
      inputValue: domainsInput,
      onChange: (e) => setDomainsInput(e.target.value),
    },
    {
      name: "APK's",
      field: "apks",
      list: apks,
      removeFunc: (domain) => removeApk(apks, domain, setApks),
      addFunc: () => addApks(apks, apksInput, setApks, setApksInput),
      inputValue: apksInput,
      onChange: (e) => setApksInput(e.target.value),
    },
    {
      name: "External Domains",
      field: "external_domains",
      list: externals,
      removeFunc: (domain) =>
        removeExternalDomain(externals, domain, setExternals),
      addFunc: () =>
        addExternalDomains(
          externals,
          externalsInput,
          setExternals,
          setExternalsInput
        ),
      inputValue: externalsInput,
      onChange: (e) => setExternalsInput(e.target.value),
    },
  ];

  const searchersRemoveData = [
    {
      name: "Alert Emails",
      field: "alert_emails",
      list: alertEmailsToRemove,
      removeFunc: (email) =>
        removeAlertEmail(alertEmailsToRemove, email, setAlertEmailsToRemove),
      addFunc: () =>
        addAlertEmails(
          alertEmailsToRemove,
          alertEmailsInputToRemove,
          setAlertEmailsToRemove,
          setAlertEmailsInputToRemove
        ),
      inputValue: alertEmailsInputToRemove,
      onChange: (e) => setAlertEmailsInputToRemove(e.target.value),
    },
  ];

  const selectorsRemoveData = [
    {
      name: "Domains",
      field: "domains",
      list: domainsToRemove,
      removeFunc: (domain) =>
        removeDomain(domainsToRemove, domain, setDomainsToRemove),
      addFunc: () =>
        addDomains(
          domainsToRemove,
          domainsInputToRemove,
          setDomainsToRemove,
          setDomainsInputToRemove
        ),
      inputValue: domainsInputToRemove,
      onChange: (e) => setDomainsInputToRemove(e.target.value),
    },
    {
      name: "APK's",
      field: "apks",
      list: apksToRemove,
      removeFunc: (domain) => removeApk(apksToRemove, domain, setApksToRemove),
      addFunc: () =>
        addApks(
          apksToRemove,
          apksInputToRemove,
          setApksToRemove,
          setApksInputToRemove
        ),
      inputValue: apksInputToRemove,
      onChange: (e) => setApksInputToRemove(e.target.value),
    },
    {
      name: "External Domains",
      field: "external_domains",
      list: externalsToRemove,
      removeFunc: (domain) =>
        removeExternalDomain(externalsToRemove, domain, setExternalsToRemove),
      addFunc: () =>
        addExternalDomains(
          externalsToRemove,
          externalsInputToRemove,
          setExternalsToRemove,
          setExternalsInputToRemove
        ),
      inputValue: externalsInputToRemove,
      onChange: (e) => setExternalsInputToRemove(e.target.value),
    },
  ];

  const resetForm = () => {
    setSaved(false);
    setDomains([]);
    setApks([]);
    setExternals([]);
    setAlertEmails([]);
    setDomainsInput("");
    setApksInput("");
    setExternalsInput("");
    setAlertEmailsInput("");
    setDomainsToRemove([]);
    setApksToRemove([]);
    setExternalsToRemove([]);
    setAlertEmailsToRemove([]);
    setDomainsInputToRemove("");
    setApksInputToRemove("");
    setExternalsInputToRemove("");
    setAlertEmailsInputToRemove("");
  };

  const updateUsers = async (
    domains,
    apks,
    externals,
    alertEmails,
    domainsToRemove,
    apksToRemove,
    externalsToRemove,
    alertEmailsToRemove,
    permissions,
    uiPermissions,
    censoredFields
  ) => {
    setError("");
    setLoading(true);

    const body = {
      emails: usersSelected.map((item) => item.value),
      domains: domains,
      apks: apks,
      externalDomains: externals,
      alertEmails: alertEmails,
      domainsToRemove: domainsToRemove,
      apksToRemove: apksToRemove,
      externalsToRemove: externalsToRemove,
      alertEmailsToRemove: alertEmailsToRemove,
      apiPermissions: permissions,
      uiPermissions: uiPermissions,
      censoredFields: censoredFields,
    };

    const response = await api.adminReq(
      "POST",
      `${adminUrl}/bulk-user-update?permissionsMode=${permissionsMode}`,
      {
        "auth-token": props.token,
      },
      body
    );

    if (response && response.data === "UPDATED") {
      setLoading(false);
      setSaved(true);
      setTimeout(() => {
        resetForm();
      }, 2000);
    } else {
      setLoading(false);
      if (typeof response === "string") {
        setError(response);
      } else {
        setError("Something went wrong, please try again");
      }
    }
  };

  const permissionOptions = [
    {
      label: "NONE",
      value: "none",
      // icon: <FaFolderOpen size={16} style={{ marginRight: "4px" }} />,
    },
    {
      label: "SET",
      value: "set",
      // icon: <FaFolder size={16} style={{ marginRight: "4px" }} />,
    },
    {
      label: "ADD",
      value: "add",
      // icon: <FaFolder size={16} style={{ marginRight: "4px" }} />,
    },
  ];

  return (
    <div className="darkText">
      <h2 className="cyberFont bold text-center yellowColor">
        Welcome to the new bulk user editing experience!
      </h2>
      <hr />
      <div style={{ width: "70%", margin: "auto" }}>
        <MultiUserSelection
          setUsers={setUsers}
          users={users}
          setUsersSelected={setUsersSelected}
          usersSelected={usersSelected}
          headers={{
            "auth-token": props.token,
          }}
        />
        <br />
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
            textAlign: "center",
            color: "black",
            padding: "10px",
          }}
        >
          <div style={{ display: "grid" }}>
            <span>NONE: Saving will ignore these configurations.</span>
            <span>
              SET: Saving will set these exact configurations. (Meaning if
              nothing is set, all permission will be removed.)
            </span>
            <span>
              ADD: Saving will only add the permissions/fields that missing and
              keep the old ones.
            </span>
          </div>

          <ToggleButtons
            options={permissionOptions}
            value={permissionsMode}
            onChange={(e, newValue) => setPermissionsMode(newValue)}
          />
        </div>
        <Permissions
          role={props.role}
          token={props.token}
          permissions={permissions}
          uiPermissions={uiPermissions}
          censoredFields={censoredFields}
          handlePermissionChange={handlePermissionChange}
          handleUiPermissionChange={handleUiPermissionChange}
          handleCensoredFieldsChange={handleCensoredFieldsChange}
          // updatePermissions={(permissions, censoredFields, uiPermissions) =>
          //   updatePermissions(permissions, censoredFields, uiPermissions)
          // }
        />
        <div className="yellowBG userDataBox lightText text-center">
          <h2>Add Data</h2>
          <CompanyDataTable data={selectorsData} view={"admin"} />
          <CompanyDataTable data={searchersData} view={"admin"} />
        </div>
        <br />
        <div className="yellowBG userDataBox lightText text-center">
          <h2>Remove Data</h2>
          <CompanyDataTable data={selectorsRemoveData} view={"admin"} />
          <CompanyDataTable data={searchersRemoveData} view={"admin"} />
        </div>
        <Button
          style={{
            width: "100%",
            margin: "10px auto",
            backgroundColor: "#fbcd4e",
            color: "black",
          }}
          variant="contained"
          color="primary"
          className=""
          type="button"
          onClick={() =>
            updateUsers(
              domains,
              apks,
              externals,
              alertEmails,
              domainsToRemove,
              apksToRemove,
              externalsToRemove,
              alertEmailsToRemove,
              permissions,
              uiPermissions,
              censoredFields
            )
          }
          endIcon={
            loading ? (
              <LoaderIcon className="buttonLoader tableLoader" type="spin" />
            ) : saved ? (
              <Icon>done</Icon>
            ) : (
              <Icon>send</Icon>
            )
          }
        >
          SAVE USER
        </Button>
      </div>
    </div>
  );
}
