/* eslint-disable */

import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import BarChartIcon from "@material-ui/icons/BarChart";
import axios from "axios";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { CSVLink } from "react-csv";
import { AiFillDatabase } from "react-icons/ai";
import { DiAndroid } from "react-icons/di";
import {
  FaCheckCircle,
  FaExternalLinkAlt,
  FaFileCsv,
  FaFolder,
  FaFolderOpen,
  FaSitemap,
  FaUserTie,
  FaUsers,
} from "react-icons/fa";
import { ImCheckmark, ImCross, ImShare2 } from "react-icons/im";
import { MdNotifications, MdRefresh } from "react-icons/md";
import { VscGistSecret } from "react-icons/vsc";
import LoaderIcon from "react-loader-icon";
import MapGL, {
  FullscreenControl,
  GeolocateControl,
  NavigationControl,
  Popup,
  ScaleControl,
} from "react-map-gl";
import { MultiSelect } from "react-multi-select-component";
import CreateApiClient from "../API";
import { APIFullAddress, JSON_API, MapApi } from "../Config";
import slackIcon from "../images/Slack_icon.png";
import AssetsDiscovery from "./AssetsDiscovery";
import BarChart from "./BarChart";
import CityInfo from "./CityInfo";
import DbTabs from "./DbTabs";
import ExportCookies from "./ExportCookies";
import ExportToCsv from "./ExportToCsv";
import LineChart from "./LineChart";
import Pins from "./MarkersMap";
import Container from "./ProgressBar";
import SearchBy from "./SearchBy";
import StackedBar from "./StackedBar";
import CustomPaginationActionsTable from "./Table";
import EmployeeTabs from "./Tabs";
import ThirdPartyTabs from "./ThirdPartyTabs";
import Autocomplete from "./partials/Autocomplete";
import DataHeader from "./partials/DataHeader";
import ExportToPDFButton from "./partials/ExportToPdf";
import TimeFilters from "./partials/TimeFilters";
import AntiVirus from "./stats/AntiVirus";
import Applications from "./stats/Applications";

const api = CreateApiClient();
/* eslint import/no-webpack-loader-syntax: off */
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "#1A1C20",
  },
}));

export default function ScrollableTabsButtonForce(props) {
  let defaultSortBy;
  let dateUploadedUsers = [
    "cohav.giller@bluevine.com",
    "oren.berman@bluevine.com",
  ];
  if (dateUploadedUsers.includes(props.user.email)) {
    defaultSortBy = "date_added";
  } else {
    defaultSortBy = "date";
  }
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [type, setType] = useState("");
  const [page, setPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [sortType, setSortType] = useState(defaultSortBy);
  const [timeSelected, setTimeSelected] = useState([]);
  const [userUrls, setUserUrls] = useState([]);
  const [empUrls, setEmpUrls] = useState([]);
  const [empExpand, setEmpExpand] = useState(null);
  const [userExpand, setUserExpand] = useState(null);
  const [userLoading, setUserLoading] = useState(null);
  const [empLoading, setEmpLoading] = useState(null);

  const [exportedPasswords, setExportedPasswords] = useState([]);
  const [exportPasswordsLoading, setExportPasswordsLoading] = useState(null);
  const [exportedDomain, setExportedDomain] = useState("");
  const [archiveAllLoader, setArchiveAllLoader] = useState(false);

  const [archiveMode, setArchiveMode] = useState("unresolved");
  const [keyword, setKeyword] = useState("");
  const [keywordInput, setKeywordInput] = useState("");
  const [keywordSuggestions, setKeywordSuggestions] = useState(null);
  const [keywordLoading, setKeywordLoading] = useState(false);
  const [wildcardType, setWildcardType] = useState("");
  const [loadOptions, setLoadOptions] = useState(false);

  const [cancelToken, setCancelToken] = useState(null);
  const [viewport, setViewport] = useState({
    latitude: 20,
    longitude: 0,
    zoom: 1.5,
    bearing: 0,
    pitch: 0,
  });
  const [popupInfo, setPopupInfo] = useState(null);

  const [settings, setSettings] = useState({
    scrollZoom: false,
    dragRotate: false,
    // dragPan: false,
  });

  const closeOnMapClick = () => {
    popupInfo && setPopupInfo(null);
  };

  const click = () => {
    document.getElementById("csvDownload").click();
  };

  const onChangeSystem = async (e) => {
    const q = e.target.value;
    if (q.length > 0) {
      setKeyword(q);
    } else {
      setKeyword("*");
    }
    setKeywordInput(q);
  };

  useEffect(() => {
    if (keyword) {
      if (cancelToken) {
        cancelToken.cancel("Canceled");
      }
      const source = axios.CancelToken.source();
      setCancelToken(source);
      setKeywordLoading(true);
      const keywordTabs = [1, 3, 4, 5];
      if (keywordTabs.includes(value)) {
        const keywordType = value === 1 ? "employee" : "client";
        const externals = props.selectedExternals.map((s) => s.value).join(",");
        const mode =
          value === 1
            ? "employees"
            : value === 3
            ? "users"
            : value === 4
            ? "apks"
            : "externals";

        axios
          .get(
            `${JSON_API}/autocomplete/keywords?query=${keyword}&type=${keywordType}&externals=${externals}&mode=${mode}&domains=${props.domain
              .split("&")
              .join(",")}&companySearch=${
              props.searchMode === "company" ? true : false
            }`,
            {
              cancelToken: source.token,
              headers: {
                "auth-token": props.user.Token,
              },
            }
          )
          .then((res) => {
            setKeywordLoading(false);
            setKeywordSuggestions(res.data);
          })
          .catch((err) => {
            // setKeywordLoading(false);
            console.log(err);
          });
      } else if (value === 2) {
        axios
          .get(
            `${JSON_API}/autocomplete/third-party-domains?query=${keyword}&domains=${props.domain
              .split("&")
              .join(",")}&companySearch=${
              props.searchMode === "company" ? true : false
            }`,
            {
              cancelToken: source.token,
              headers: {
                "auth-token": props.user.Token,
              },
            }
          )
          .then((res) => {
            setKeywordLoading(false);
            setKeywordSuggestions(res.data);
          })
          .catch((err) => {
            // setKeywordLoading(false);
            console.log(err);
          });
      }
    } else {
      setKeywordSuggestions(null);
      setKeyword("");
      setKeywordInput("");
      setKeywordLoading(false);
    }
    return () => {
      if (cancelToken) {
        cancelToken.cancel("Canceled");
      }
    };
  }, [keyword]);

  useEffect(() => {
    setPage(0);
    if (
      props.selectedExternals &&
      props.selectedExternals.length > 0 &&
      props.section.includes("external-domains")
    ) {
      props.getData(
        [],
        `external-domains?sortby=${sortType}`,
        0,
        false,
        null,
        true
      );
    }
  }, [props.selectedExternals]);

  const csvTitle = [
    "These passwords represent a sample size of the actual amount of passwords belonging to the domain.",
  ];

  const wildcardRoles = ["Admin", "master", "Customer", "User", "MSSP"];
  const searchers = ["Admin", "master", "MSSP"];
  let config = {
    headers: {
      "auth-token": props.user.Token,
    },
  };

  const DefaultItemRenderer = ({ checked, option, onClick, disabled }) => (
    <div className={`item-renderer ${disabled && "disabled"}`}>
      {loadOptions ? (
        <LoaderIcon className="tabsLoader" type={"spinningBubbles"} />
      ) : (
        <>
          <input
            type="checkbox"
            onChange={onClick}
            checked={checked}
            tabIndex={-1}
            disabled={disabled}
          />
          <span>{option.label}</span>
        </>
      )}
    </div>
  );

  const resolveAll = async (domain, type) => {
    setArchiveAllLoader(true);
    const domains = domain.split("&");
    await axios
      .post(
        `${APIFullAddress}/archive?id=all&type=${type}`,
        { domains: domains },
        config
      )
      .then((res) => {
        const timeBased = ["users", "apks"];
        if (!timeBased.includes(type)) {
          archiveMode === "unresolved"
            ? setArchiveMode("resolved")
            : setArchiveMode("unresolved");
        }
      })
      .catch((err) => console.log(err));
    setArchiveAllLoader(false);
  };

  const unresolveAll = async (domain, type) => {
    setArchiveAllLoader(true);
    const domains = domain.split("&");
    await axios
      .post(
        `${APIFullAddress}/archive/unresolve?id=all&type=${type}`,
        { domains: domains },
        config
      )
      .then((res) => {
        archiveMode === "unresolved"
          ? setArchiveMode("resolved")
          : setArchiveMode("unresolved");
      })
      .catch((err) => console.log(err));
    setArchiveAllLoader(false);
  };

  const onModeChange = (e, newValue, thirdParty, users, apks) => {
    setPage(0);
    setArchiveMode(newValue);
    if (users) {
      props.getData(
        [],
        `clients?android=${apks}&sortby=${sortType}&mode=${newValue}&time=${
          timeSelected[0] ? timeSelected[0].value : "all"
        }`,
        0,
        true,
        null,
        true
      );
    } else {
      props.getData(
        [],
        `employees?thirdparties=${thirdParty}&mode=${newValue}&sortby=${sortType}&time=${
          timeSelected[0] ? timeSelected[0].value : "all"
        }`,
        0,
        true,
        null,
        true
      );
    }
  };

  let externalDomainsOptions = [];
  if (props.user.external_domains && props.user.external_domains.length > 0) {
    externalDomainsOptions = props.user.external_domains.map((item) => {
      return {
        value: item.domain,
        label: item.domain,
      };
    });
  }

  const options = [
    { value: "date", label: "Date Compromised" },
    { value: "date_added", label: "Date Added" },
  ];

  const timeOptions = [
    { value: "week", label: "Last 7 Days" },
    { value: "month", label: "Last 30 Days" },
    { value: "quarter", label: "Last 3 Months" },
    { value: "year", label: "Last 12 Months" },
    { value: "all", label: "All Time" },
  ];

  const archiveOptions = [
    {
      label: "Resolved",
      value: "resolved",
      icon: <FaFolderOpen size={16} style={{ marginRight: "4px" }} />,
    },
    {
      label: "Unresolved",
      value: "unresolved",
      icon: <FaFolder size={16} style={{ marginRight: "4px" }} />,
    },
  ];

  const exportPasswords = async (e, domain, type) => {
    e.stopPropagation();
    setExportedPasswords([]);
    setExportPasswordsLoading(`${domain}_${type}`);
    setExportedDomain(`${domain}_${type}`);
    let config = {
      headers: {
        "auth-token": props.user.Token,
      },
    };

    const response = await api.fetchData(
      `stats/passwords/csv?domain=${domain}&type=${type}`,
      {},
      config
    );
    setExportedPasswords(response);
    setExportPasswordsLoading(null);
    click();
  };

  const getUrlsStrength = async (domain, type) => {
    // setExpand(`${domain}_${type}`);
    // setLoading(`${domain}_${type}`);
    type === "employee"
      ? setEmpExpand(`${domain}_${type}`)
      : setUserExpand(`${domain}_${type}`);
    type === "employee"
      ? setEmpLoading(`${domain}_${type}`)
      : setUserLoading(`${domain}_${type}`);

    let config = {
      headers: {
        "auth-token": props.user.Token,
      },
    };

    const response = await api.fetchData(
      `stats/passwords/urls-strength?domain=${domain}&type=${type}`,
      {},
      config
    );
    type === "employee" ? setEmpUrls(response) : setUserUrls(response);
    // setUrls(response);
    type === "employee" ? setEmpLoading(null) : setUserLoading(null);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMapChange = async (e) => {
    setType(e.target.value);
    await props.getMapData(`stats/ips?type=${e.target.value}`, props.user);
    popupInfo && setPopupInfo(null);
  };

  const filterByKeyword = (type, subject, thirdParty, android) => {
    setPage(0);
    setSortType(type);
    props.getData(
      [],
      `${subject}?thirdparties=${thirdParty}&mode=${archiveMode}&android=${android}&sortby=${
        type.value
      }&time=${
        timeSelected[0] ? timeSelected[0].value : "all"
      }&keyword=${encodeURIComponent(keywordInput)}&key=${wildcardType}`,
      0,
      true,
      null,
      true
    );
  };

  const changeSortType = (type, subject, android, thirdParty) => {
    setPage(0);
    setSortType(type.value);
    props.getData(
      [],
      `${subject}?thirdparties=${thirdParty}&mode=${archiveMode}&sortby=${
        type.value
      }&android=${android}&time=${
        timeSelected[0] ? timeSelected[0].value : "all"
      }&keyword=${encodeURIComponent(keywordInput)}&key=${wildcardType}`,
      0,
      true,
      null,
      true
    );
  };

  const filterByTime = (e, type, subject, android, thirdParty) => {
    setPage(0);
    if (timeSelected.length > 0) {
      setTimeSelected([]);
      e.shift();
      setTimeSelected(e);
    } else {
      setTimeSelected(e);
    }
    props.getData(
      [],
      `${subject}?thirdparties=${thirdParty}&mode=${archiveMode}&sortby=${type}&android=${android}&time=${
        e[0] ? e[0].value : "all"
      }&keyword=${keywordInput}&key=${wildcardType}`,
      0,
      true,
      null,
      true
    );
  };

  useEffect(() => {
    setKeyword("");
    setKeywordInput("");
    setValue(0);
    setSortType(defaultSortBy);
  }, [props.domain]);

  useEffect(() => {
    if (props.employeesCount > 0) {
      setType("employees");
    } else {
      setType("users");
    }
  }, [props.employeesCount]);

  const loadMore = (type) => {
    setLoader(true);
    setPage(page + 1);
    props.getData(props.data, type, page + 1, false);
  };

  const counters = [
    {
      label: (
        <div className="counter tabsCounter">
          <CountUp
            separator=","
            start={0}
            end={props.stealersCount}
            duration={2.75}
            suffix={props.floored.total ? "+" : ""}
          />
          Overview <br /> Dashboard
        </div>
      ),
      icon: <BarChartIcon className="materialIcons" id="firstIcon" />,
      count: props.stealersCount,
      path: "overview",
      class: "overviewTab",
      func: () => {
        props.setExternalDomain("all");
        setKeyword("");
        setKeywordInput("");
        setKeywordSuggestions(null);
        setTimeSelected([]);
        setPage(0);
        {
          props.year !== "All Time"
            ? props.getStats(
                `stats?frame=monthly&year=${props.year}`,
                props.user
              )
            : props.getStats(
                `stats?frame=all&currentyear=${props.currentYear}`,
                props.user
              );
        }
      },
    },
    {
      label: (
        <div className="counter tabsCounter">
          <CountUp
            separator=","
            start={0}
            end={props.employeesCount}
            duration={2.75}
            suffix={props.floored.employees ? "+" : ""}
          />
          Compromised <br /> Employees
        </div>
      ),
      icon: <FaUserTie size={30} className="counterIcon" />,
      count: props.employeesCount,
      // class: "employeeHorizontalTab",
      class: props.employeesCount > 0 ? "employeeHorizontalTab" : "hiddenTab",
      func: () => {
        props.setExternalDomain("all");
        setKeyword("");
        setKeywordInput("");
        setKeywordSuggestions(null);
        setArchiveMode("unresolved");
        setTimeSelected([]);
        setSortType(defaultSortBy);
        setPage(0);
        props.getData([], `employees?sortby=${defaultSortBy}`, 0, false);
      },
    },
    {
      label: (
        <div className="counter tabsCounter">
          <CountUp
            separator=","
            start={0}
            end={props.partiesCount}
            duration={2.75}
            suffix={props.floored.parties ? "+" : ""}
          />
          Third Party <br /> Credentials
        </div>
      ),
      icon: <FaSitemap size={30} className="counterIcon" />,
      count: props.partiesCount,
      // class: "employeeHorizontalTab",
      class: props.partiesCount > 0 ? "employeeHorizontalTab" : "hiddenTab",
      func: () => {
        props.setExternalDomain("all");
        setKeyword("");
        setKeywordInput("");
        setKeywordSuggestions(null);
        setArchiveMode("unresolved");
        setTimeSelected([]);
        setSortType(defaultSortBy);
        setPage(0);
        props.getData(
          [],
          `employees?thirdparties=true&sortby=${defaultSortBy}`,
          0,
          false
        );
      },
    },
    {
      label: (
        <div className="counter tabsCounter">
          <CountUp
            separator=","
            start={0}
            end={props.clientsCount}
            duration={2.75}
            suffix={props.floored.users ? "+" : ""}
          />
          Compromised <br /> Users
        </div>
      ),
      icon: <FaUsers size={30} className="counterIcon" />,
      count: props.clientsCount,
      path: "clients",
      // class: "clientsTab",
      class: props.clientsCount > 0 ? "clientsTab" : "hiddenTab",
      func: () => {
        props.setExternalDomain("all");
        setArchiveMode("unresolved");
        setKeyword("");
        setKeywordInput("");
        setKeywordSuggestions(null);
        setTimeSelected([]);
        setSortType(defaultSortBy);
        setPage(0);
        props.getData(
          [],
          `clients?android=false&sortby=${defaultSortBy}`,
          0,
          false
        );
      },
    },
    {
      label: (
        <div className="counter tabsCounter">
          {/* {typeof props.androidsCount !== "boolean" ? (
            <CountUp
              separator=","
              start={0}
              end={props.androidsCount}
              duration={2.75}
              suffix={props.floored.apks ? "+" : ""}
            />
          ) : (
            <div>
              {props.androidsCount === true ? (
                <ImCheckmark className="iconIndicator" size={25} />
              ) : (
                <ImCross className="iconIndicator" size={25} />
              )}
            </div>
          )} */}
          <CountUp
            separator=","
            start={0}
            end={props.androidsCount}
            duration={2.75}
            suffix={props.floored.apks ? "+" : ""}
          />
          Compromised <br /> App Users
        </div>
      ),
      icon: <DiAndroid size={30} className="counterIcon" />,
      count: props.androidsCount,
      path: "androids",
      // class: "appsTab",
      class: props.androidsCount > 0 ? "appsTab" : "hiddenTab",
      func: () => {
        props.setExternalDomain("all");
        setArchiveMode("unresolved");
        setKeyword("");
        setKeywordInput("");
        setKeywordSuggestions(null);
        setTimeSelected([]);
        setSortType(defaultSortBy);
        setPage(0);
        props.getData(
          [],
          `clients?android=true&sortby=${defaultSortBy}`,
          0,
          false
        );
      },
    },
    {
      label: (
        <div className="counter tabsCounter">
          <CountUp
            separator=","
            start={0}
            end={props.externalCount}
            duration={2.75}
            suffix={props.floored.externals ? "+" : ""}
          />
          External <br /> Domains
        </div>
      ),
      icon: <FaExternalLinkAlt size={30} className="counterIcon" />,
      count: props.externalCount,
      path: "externals",
      class: props.externalCount > 0 ? "monitorTab" : "hiddenTab",
      func: () => {
        props.setExternalDomain("all");
        setKeyword("");
        setKeywordInput("");
        setKeywordSuggestions(null);
        setPage(0);
        setTimeSelected([]);
        props.getData([], "external-domains?sortby=date", 0, false);
      },
    },
    {
      label: (
        <div className="counter tabsCounter">
          <CountUp
            separator=","
            start={0}
            end={props.dbsCount}
            duration={2.75}
            suffix={"+"}
          />
          Compromised <br /> DBs Accounts
        </div>
      ),
      icon: <AiFillDatabase size={30} className="counterIcon" />,
      count: props.dbsCount,
      path: "databases",
      class: props.dbsCount > 0 ? "dbsTab" : "hiddenTab",
      func: () => {
        props.setExternalDomain("all");
        setTimeSelected([]);
        setSortType("date");
        setPage(0);
        props.getData([], "dbs/domainsearch", 0, false);
      },
    },
    {
      label: (
        <div className="counter tabsCounter">
          <CountUp
            separator=","
            start={0}
            end={props.monitorCount}
            duration={2.75}
            suffix={"+"}
          />
          Third Party <br /> Risk Assessment
        </div>
      ),
      icon: <ImShare2 size={30} className="counterIcon" />,
      count: props.monitorCount,
      path: "thirdparty",
      class: props.monitorCount > 0 ? "monitorTab" : "hiddenTab",
      func: () => {
        props.setExternalDomain("all");
        setPage(0);
        setTimeSelected([]);
        props.getMonitoredDomains();
      },
    },
    {
      label: (
        <div className="counter tabsCounter">
          <CountUp
            separator=","
            start={0}
            end={props.assetsCount}
            duration={2.75}
            suffix={props.floored.assets ? "+" : ""}
          />
          Assets <br /> Discovery
        </div>
      ),
      icon: <VscGistSecret size={30} className="counterIcon" />,
      count: props.assetsCount,
      path: "assets",
      class: props.assetsCount > 0 ? "monitorTab" : "hiddenTab",
      func: () => {
        props.setExternalDomain("all");
        setPage(0);
        setTimeSelected([]);
        props.getAssetDiscovery("employees");
      },
    },
  ];

  const geolocateStyle = {
    top: 0,
    left: 0,
    padding: "10px",
  };

  const fullscreenControlStyle = {
    top: 36,
    left: 0,
    padding: "10px",
  };

  const navStyle = {
    top: 72,
    left: 0,
    padding: "10px",
  };

  const scaleControlStyle = {
    bottom: 36,
    left: 0,
    padding: "10px",
  };

  // const onChangeSystem = async (e) => {
  //   const q = e.target.value.toLowerCase();
  //   if (q.length > 1) {
  //     setKeywordLoading(true);
  //   }
  //   setKeyword(q);
  //   if (cancelToken) {
  //     cancelToken.cancel("Canceled");
  //   }
  //   cancelToken = axios.CancelToken.source();
  //   await axios
  //     .get(
  //       `${JSON_API}/autocomplete/keywords?query=${q}&domains=${props.domain}`,
  //       {
  //         // signal: signal,
  //         cancelToken: cancelToken.token,
  //       }
  //     )
  //     .then((res) => {
  //       setKeywordLoading(false);
  //       setKeywordSuggestions(res.data);
  //     })
  //     .catch((err) => {
  //       setKeywordLoading(false);
  //       console.log(err);
  //     });

  //   if (!q) {
  //     setKeywordSuggestions(null);
  //     setKeyword("");
  //   }
  // };

  const externalOptions = [];
  if (props.searchMode === "company") {
    props.currentCompany &&
      props.currentCompany.data &&
      props.currentCompany.data.external_domains.map((item) => {
        externalOptions.push({
          label: item,
          value: item,
        });
      });
  } else if (
    props.user.external_domains &&
    props.user.external_domains.length > 0
  ) {
    props.user.external_domains &&
      props.user.external_domains.map((item) => {
        externalOptions.push({
          label: item.domain,
          value: item.domain,
        });
      });
  } else if (props.externalSuggestions) {
    props.externalSuggestions.map((item) => {
      externalOptions.push({
        label: item.domain,
        value: item.domain,
      });
    });
  }

  return (
    <div className={classes.root}>
      {counters.find((c) => c.count > 0) && (
        <AppBar position="static" className="navigationTabs">
          <Tabs
            id="horizontalTabs"
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs"
          >
            {!props.loading &&
              counters.map((counter, index) =>
                counter.count > 0 ? (
                  <Tab
                    className={counter.class}
                    key={index}
                    onClick={counter.count > 0 && counter.func}
                    label={counter.label}
                    icon={counter.icon}
                    {...a11yProps(index)}
                  />
                ) : (
                  <Tab
                    className={counter.class}
                    disabled
                    key={index}
                    label={counter.label}
                    icon={counter.icon}
                    {...a11yProps(index)}
                  />
                )
              )}
          </Tabs>
        </AppBar>
      )}

      <hr id="hthr"></hr>
      <TabPanel value={value} index={0} className="desktop">
        {!props.loading && props.stealersCount && props.stealersCount > 0 ? (
          <>
            <div
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <ExportToPDFButton
                email={props.user.email}
                token={props.token}
                domains={props.domain}
                company={
                  props.searchMode === "company" &&
                  props.currentCompany &&
                  props.currentCompany._id
                }
              />
              <div>
                {props.user.webhook_urls &&
                props.user.webhook_urls.length > 0 ? (
                  <span></span>
                ) : (
                  <a
                    href="https://slack.com/oauth/v2/authorize?client_id=2147716558119.3810903297856&scope=incoming-webhook"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      backgroundColor: "white",
                      padding: "9px 10px",
                      borderRadius: "4px",
                    }}
                  >
                    <img
                      alt="Slack Notifications"
                      src={slackIcon}
                      style={{ width: "20px", marginRight: "5px" }}
                    />
                    Slack Notifications →
                  </a>
                )}
              </div>
            </div>

            <LineChart
              id={"mainTimeline"}
              chartLoader={props.chartLoader}
              labelColor="#fff"
              domains={props.domain}
              yearsOptions={props.yearsOptions}
              monthly={props.monthly}
              switchToAllTimeChart={props.switchToAllTimeChart}
              switchToMonthlyChart={props.switchToMonthlyChart}
              handleYearChange={props.handleYearChange}
              year={props.year}
              employeesStats={props.employeesStats}
              clientsStats={props.clientsStats}
              data={props.counts}
              labels={props.labels}
              loading={props.loading}
              stealersCount={props.stealersCount}
              logo={
                props.searchMode === "company" &&
                props.currentCompany &&
                props.currentCompany.data
                  ? props.currentCompany.data.domains[0]
                  : props.domain
              }
            />
            <br />
            <span id="assets"></span>
            <br />
            <div className="donuts desktop">
              <div className="leftCol">
                {props.employeesCount > 0 ? (
                  <BarChart
                    labelColor="#fff"
                    screen="main"
                    data={props.employeesUrls}
                    dataCount={props.employeesUrlsCount}
                    sfLoading={props.urlsLoading}
                    type="(Employees)"
                    height={20}
                    width={100}
                    subtitle={"DISCOVER MORE"}
                    discoverMore={() => {
                      props.getAssetDiscovery("employees", true);
                      window.location.href = "#horizontalTabs";
                      setValue(8);
                    }}
                  />
                ) : (
                  <BarChart
                    percentage={true}
                    labelColor="#fff"
                    screen="main"
                    stealerFamilyStats={props.stealerFamilyStats}
                    data={
                      props.stealerFamilyStats &&
                      props.stealerFamilyStats[0] &&
                      Object.keys(props.stealerFamilyStats[0])
                    }
                    dataCount={
                      props.stealerFamilyStats &&
                      props.stealerFamilyStats[0] &&
                      Object.keys(props.stealerFamilyStats[0]).map(
                        (key) => props.stealerFamilyStats[0][key]
                      )
                    }
                    loading={props.loading}
                    type=""
                    title="Stealer Family"
                    spacer={true}
                    height={20}
                    width={100}
                    customTitle={"Stealer Family Statistics"}
                    sfLoading={props.sfLoading}
                  />
                )}
              </div>
              <div className="rightCol">
                <BarChart
                  height={20}
                  width={100}
                  labelColor="#fff"
                  screen="main"
                  data={props.clientsUrls}
                  dataCount={props.clientsUrlsCount}
                  sfLoading={props.urlsLoading}
                  type="(Users)"
                  subtitle={"DISCOVER MORE"}
                  discoverMore={() => {
                    props.getAssetDiscovery("users", true);
                    window.location.href = "#horizontalTabs";
                    setValue(8);
                  }}
                />
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <div
              className="desktop"
              style={{
                padingBottom: "1rem",
                // minHeight: "100vh",

                width: "100%",
                display: "inline-flex",
                // maxHeight: "600px",
                // border: "2px solid #fecd4e",
              }}
            >
              {props.employeesCount > 0 && (
                <div
                  className="scrollBar"
                  style={{
                    width: "50%",
                    margin: "0px 8px",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    maxHeight: "400px",
                    backgroundColor: "#222831",
                    borderRadius: "20px",
                    padding: "1rem",
                    border: "2px solid #222831",
                  }}
                >
                  <div className="x-axis">
                    <h1 style={{ color: "#fecd4e" }}>
                      Employee Password Strength
                    </h1>
                    <ul className="legend">
                      <Tooltip title="Password with a length of less than 6 characters and only 1 type of character (lowercase, uppercase, numbers and symbols).">
                        <li>Too Weak</li>
                      </Tooltip>
                      <Tooltip title="Password with a length of 6-8 characters and a diversity of 2-3 types of characters (lowercase, uppercase, numbers and symbols).">
                        <li>Weak</li>
                      </Tooltip>
                      <Tooltip title="Password with a length of 8-10 characters and a diversity of 4 types of characters (lowercase, uppercase, numbers and symbols).">
                        <li>Medium</li>
                      </Tooltip>
                      <Tooltip title="Password that is at least 10 characters long and has a diversity of 4 types of characters (lowercase, uppercase, numbers and symbols).">
                        <li>Strong</li>
                      </Tooltip>
                    </ul>
                  </div>
                  {props.passwordStats &&
                  props.passwordStats.length > 0 &&
                  props.passwordStats.find(
                    (s) => s && s.employees && s.employees.has_stats
                  ) ? (
                    props.passwordStats
                      .filter((s) => s && s.employees && s.employees.has_stats)
                      .map(
                        (domain, index) =>
                          domain.employees &&
                          domain.employees.has_stats && (
                            <div key={index}>
                              <StackedBar
                                bg="#1A1C20"
                                user={props.user}
                                type="Employees"
                                key={index}
                                domain={domain}
                                arr={domain.employees}
                                typeFilter="employee"
                                getUrlsStrength={() => {
                                  if (
                                    empExpand === `${domain.domain}_employee`
                                  ) {
                                    setEmpExpand(null);
                                  } else {
                                    getUrlsStrength(domain.domain, "employee");
                                  }
                                }}
                                exportBtn={
                                  exportedPasswords.length > 0 &&
                                  exportedDomain ===
                                    `${domain.domain}_employee` ? (
                                    <CSVLink
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                      id="csvDownload"
                                      data={exportedPasswords}
                                      filename={`${domain.domain}_passwords_employees.csv`}
                                      className=""
                                      target="_blank"
                                      rel="noreferrer"
                                      style={{
                                        // width: "15%",
                                        // backgroundColor: "#FCCE4C",
                                        padding: "1px 8px",
                                        position: "absolute",
                                        top: "15px",
                                        right: "20%",
                                        color: "white",
                                      }}
                                    >
                                      Download CSV
                                    </CSVLink>
                                  ) : (
                                    props.user.ui_permissions.includes(
                                      "csv_export"
                                    ) && (
                                      <Button
                                        variant="contained"
                                        className="searchBtn"
                                        // endIcon={<Icon>send</Icon>}
                                        onClick={(e) =>
                                          exportPasswords(
                                            e,
                                            domain.domain,
                                            "employee"
                                          )
                                        }
                                        style={{
                                          // width: "15%",
                                          backgroundColor: "#FCCE4C",
                                          padding: "1px 8px",
                                          position: "absolute",
                                          top: "15px",
                                          right: "20%",
                                        }}
                                      >
                                        Export To CSV
                                        {exportPasswordsLoading ===
                                        `${domain.domain}_employee` ? (
                                          <LoaderIcon
                                            id="csvLoader"
                                            className="tableLoader csvLoader"
                                            type={"spin"}
                                            style={{
                                              position: "relative",
                                              top: "-3px",
                                              left: "3px",
                                            }}
                                          />
                                        ) : (
                                          <FaFileCsv size={20} />
                                        )}
                                      </Button>
                                    )
                                  )
                                }
                                expandBtn={
                                  <Button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (
                                        empExpand ===
                                        `${domain.domain}_employee`
                                      ) {
                                        setEmpExpand(null);
                                      } else {
                                        getUrlsStrength(
                                          domain.domain,
                                          "employee"
                                        );
                                      }
                                    }}
                                    style={{
                                      width: "15%",
                                      backgroundColor: "#FCCE4C",
                                      padding: "1px 8px",
                                      position: "absolute",
                                      top: "15px",
                                      right: "15px",
                                    }}
                                  >
                                    {empExpand === `${domain.domain}_employee`
                                      ? "Close ↑"
                                      : "Expand ↓"}
                                  </Button>
                                }
                                expand={empExpand}
                                setExpand={setEmpExpand}
                                child={
                                  empExpand === `${domain.domain}_employee` && (
                                    <div
                                      className="scrollBox"
                                      style={{
                                        borderRadius: "20px",
                                        // width: "95%",
                                        margin: "auto",
                                        backgroundColor: "#FCCE4C",
                                      }}
                                    >
                                      {empLoading ===
                                        `${domain.domain}_employee` && (
                                        <LoaderIcon
                                          type="spin"
                                          style={{
                                            fill: "black",
                                            width: "100px",
                                            height: "auto",
                                            position: "relative",
                                            top: "5rem",
                                          }}
                                        />
                                      )}
                                      {!empLoading && (
                                        <>
                                          {empUrls.map(
                                            (item, index) =>
                                              item.employees.totalPass > 0 && (
                                                <StackedBar
                                                  key={index}
                                                  bg="#222831"
                                                  user={props.user}
                                                  type="Employees"
                                                  domain={item.domain}
                                                  arr={item.employees}
                                                  typeFilter="employee"
                                                />
                                              )
                                          )}
                                        </>
                                      )}
                                    </div>
                                  )
                                }
                              />
                            </div>
                          )
                      )
                  ) : props.passwordsLoading ? (
                    <>
                      <LoaderIcon type="spin" style={{ fill: "white" }} />
                      <br />
                      Loading Password Strength Data...
                    </>
                  ) : (
                    <h2>No data found</h2>
                  )}
                </div>
              )}
              <div
                className="scrollBar"
                style={{
                  width: props.employeesCount > 0 ? "50%" : "100%",
                  margin: "0px 8px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  maxHeight: "400px",
                  backgroundColor: "#222831",
                  borderRadius: "20px",
                  padding: "1rem",
                  border: "2px solid #222831",
                }}
              >
                <div className="x-axis">
                  <h1 style={{ color: "#fecd4e" }}>User Password Strength</h1>
                  <ul className="legend">
                    <Tooltip title="Password with a length of less than 6 characters and only 1 type of character (lowercase, uppercase, numbers and symbols).">
                      <li>Too Weak</li>
                    </Tooltip>
                    <Tooltip title="Password with a length of 6-8 characters and a diversity of 2-3 types of characters (lowercase, uppercase, numbers and symbols).">
                      <li>Weak</li>
                    </Tooltip>
                    <Tooltip title="Password with a length of 8-10 characters and a diversity of 4 types of characters (lowercase, uppercase, numbers and symbols).">
                      <li>Medium</li>
                    </Tooltip>
                    <Tooltip title="Password that is at least 10 characters long and has a diversity of 4 types of characters (lowercase, uppercase, numbers and symbols).">
                      <li>Strong</li>
                    </Tooltip>
                  </ul>
                </div>
                {props.passwordStats &&
                props.passwordStats.length > 0 &&
                props.passwordStats.find(
                  (s) => s && s.users && s.users.has_stats
                ) ? (
                  props.passwordStats.map(
                    (domain, index) =>
                      domain.users &&
                      domain.users.has_stats && (
                        <div key={index}>
                          <StackedBar
                            bg="#1A1C20"
                            user={props.user}
                            type="Users"
                            key={index}
                            domain={domain}
                            arr={domain.users}
                            typeFilter="client"
                            getUrlsStrength={() => {
                              if (userExpand === `${domain.domain}_client`) {
                                setUserExpand(null);
                              } else {
                                getUrlsStrength(domain.domain, "client");
                              }
                            }}
                            setExpand={setUserExpand}
                            expand={userExpand}
                            exportBtn={
                              exportedPasswords.length > 0 &&
                              exportedDomain === `${domain.domain}_client` ? (
                                <CSVLink
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  id="csvDownload"
                                  data={exportedPasswords}
                                  filename={`${domain.domain}_passwords_users.csv`}
                                  className=""
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    // width: "15%",
                                    // backgroundColor: "#FCCE4C",
                                    padding: "1px 8px",
                                    position: "absolute",
                                    top: "15px",
                                    right: "20%",
                                    color: "white",
                                  }}
                                >
                                  Download CSV
                                </CSVLink>
                              ) : (
                                props.user.ui_permissions.includes(
                                  "csv_export"
                                ) && (
                                  <Button
                                    variant="contained"
                                    className="searchBtn"
                                    // endIcon={<Icon>send</Icon>}
                                    onClick={(e) =>
                                      exportPasswords(
                                        e,
                                        domain.domain,
                                        "client"
                                      )
                                    }
                                    style={{
                                      // width: "15%",
                                      backgroundColor: "#FCCE4C",
                                      padding: "1px 8px",
                                      position: "absolute",
                                      top: "15px",
                                      right: "20%",
                                    }}
                                  >
                                    Export To CSV
                                    {exportPasswordsLoading ===
                                    `${domain.domain}_client` ? (
                                      <LoaderIcon
                                        id="csvLoader"
                                        className="tableLoader csvLoader"
                                        type={"spin"}
                                        style={{
                                          position: "relative",
                                          top: "-3px",
                                          left: "3px",
                                        }}
                                      />
                                    ) : (
                                      <FaFileCsv size={20} />
                                    )}
                                  </Button>
                                )
                              )
                            }
                            expandBtn={
                              <Button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (
                                    userExpand === `${domain.domain}_client`
                                  ) {
                                    setUserExpand(null);
                                  } else {
                                    getUrlsStrength(domain.domain, "client");
                                  }
                                }}
                                style={{
                                  width: "15%",
                                  backgroundColor: "#FCCE4C",
                                  padding: "1px 8px",
                                  position: "absolute",
                                  top: "15px",
                                  right: "15px",
                                }}
                              >
                                {userExpand === `${domain.domain}_client`
                                  ? "Close ↑"
                                  : "Expand ↓"}
                              </Button>
                            }
                            child={
                              userExpand === `${domain.domain}_client` && (
                                <div
                                  className="scrollBox"
                                  style={{
                                    borderRadius: "20px",
                                    // width: "95%",
                                    margin: "auto",
                                    backgroundColor: "#FCCE4C",
                                  }}
                                >
                                  {userLoading ===
                                    `${domain.domain}_client` && (
                                    <LoaderIcon
                                      type="spin"
                                      style={{
                                        fill: "black",
                                        width: "100px",
                                        height: "auto",
                                        position: "relative",
                                        top: "5rem",
                                      }}
                                    />
                                  )}
                                  {!userLoading && (
                                    <>
                                      {userUrls.map(
                                        (item, index) =>
                                          item.users.totalPass > 0 && (
                                            <StackedBar
                                              key={index}
                                              bg="#222831"
                                              user={props.user}
                                              type="Users"
                                              domain={item.domain}
                                              arr={item.users}
                                              typeFilter="client"
                                            />
                                          )
                                      )}
                                    </>
                                  )}
                                </div>
                              )
                            }
                          />
                        </div>
                      )
                  )
                ) : props.passwordsLoading ? (
                  <>
                    <LoaderIcon type="spin" style={{ fill: "white" }} />
                    <br />
                    Loading Password Strength Data...
                  </>
                ) : (
                  <h2>No data found</h2>
                )}
              </div>
            </div>
            <>
              {props.employeesCount > 0 && (
                <div
                  style={{
                    display: "inline-flex",
                    width: "100%",
                    marginTop: "30px",
                    // overflowY: "scroll",
                    // maxHeight: "600px",
                    // border: "2px solid #fecd4e",
                  }}
                >
                  <div
                    className="scrollBar"
                    style={{
                      width: "50%",
                      margin: "5px",
                      overflowY: "scroll",
                      overflowX: "hidden",
                      maxHeight: "400px",
                      backgroundColor: "#222831",
                      borderRadius: "20px",
                      padding: "1rem",
                      border: "2px solid #222831",
                    }}
                  >
                    <br />
                    <AntiVirus
                      data={props.avStats}
                      typeLabel="Employee"
                      type={"employees"}
                      avLoading={props.avLoading}
                    />
                    <br />
                  </div>

                  <div
                    className="scrollBar"
                    style={{
                      width: "50%",
                      margin: "5px",
                      overflowY: "scroll",
                      overflowX: "hidden",
                      maxHeight: "400px",
                      backgroundColor: "#222831",
                      borderRadius: "20px",
                      padding: "1rem",
                      border: "2px solid #222831",
                    }}
                  >
                    <br />
                    <Applications
                      data={props.appStats}
                      typeLabel="Employee"
                      type={"employees"}
                      appsLoading={props.appsLoading}
                    />
                    <br />
                  </div>
                </div>
              )}
            </>
            <br />
            <br />
            {props.employeesCount > 0 && (
              <div className="donuts desktop">
                <div className="leftCol">
                  <BarChart
                    labelColor="#fff"
                    screen="main"
                    stealerFamilyStats={props.stealerFamilyStats}
                    data={
                      props.stealerFamilyStats &&
                      props.stealerFamilyStats[0] &&
                      Object.keys(props.stealerFamilyStats[0])
                    }
                    dataCount={
                      props.stealerFamilyStats &&
                      props.stealerFamilyStats[0] &&
                      Object.keys(props.stealerFamilyStats[0]).map(
                        (key) => props.stealerFamilyStats[0][key]
                      )
                    }
                    percentage={true}
                    loading={props.loading}
                    type=""
                    title="Stealer Family"
                    height={20}
                    width={100}
                    customTitle={"Stealer Family Statistics"}
                    sfLoading={props.sfLoading}
                  />
                </div>
                <div className="rightCol">
                  <BarChart
                    labelColor="#fff"
                    screen="main"
                    data={props.cookieStats && Object.keys(props.cookieStats)}
                    dataCount={
                      props.cookieStats &&
                      Object.keys(props.cookieStats).map(
                        (key) => props.cookieStats[key]
                      )
                    }
                    loading={props.loading}
                    type=""
                    title="Cookies"
                    height={20}
                    width={100}
                    customTitle={"Top Employee Session Cookie"}
                    sfLoading={props.cookieLoading}
                  />
                </div>
              </div>
            )}
            <h1 id="geoTitle" className="chartTitle">
              Geo’s of Compromised Computers{" "}
            </h1>
            {props.employeesCount > 0 && props.clientsCount > 0 && (
              <FormControl component="fieldset" className="inputs">
                <RadioGroup
                  onChange={handleMapChange}
                  row
                  aria-label="position"
                  name="position"
                  value={type ? type : "employees"}
                >
                  <FormControlLabel
                    value="employees"
                    control={<Radio color="primary" />}
                    label={
                      <div className="typeIcon">
                        <FaUserTie size={22} className="" />
                        Employees
                      </div>
                    }
                    labelPlacement="top"
                  />
                  <FormControlLabel
                    value="users"
                    control={<Radio color="primary" />}
                    label={
                      <div className="typeIcon">
                        <FaUsers size={25} className="" />
                        Users
                      </div>
                    }
                    labelPlacement="top"
                  />
                </RadioGroup>
              </FormControl>
            )}

            <hr></hr>
            {props.mapLoading ? (
              <LoaderIcon
                className="tabsLoader mapLoader"
                type={"spinningBubbles"}
              />
            ) : (
              <MapGL
                {...viewport}
                {...settings}
                width="100%"
                height="500px"
                mapStyle="mapbox://styles/mapbox/dark-v9"
                onViewportChange={setViewport}
                mapboxApiAccessToken={MapApi}
                onClick={closeOnMapClick}
              >
                <Pins
                  data={props.cities}
                  onClick={setPopupInfo}
                  clientsCities={props.clientsCities}
                  employeesCities={props.employeesCities}
                  type={type}
                />
                {popupInfo && (
                  <Popup
                    tipSize={5}
                    anchor="top"
                    longitude={popupInfo.longitude}
                    latitude={popupInfo.latitude}
                    city={popupInfo.city}
                    country={popupInfo.country}
                    region={popupInfo.region}
                    timezone={popupInfo.timezone}
                    isp={popupInfo.isp}
                    proxy={popupInfo.proxy}
                    closeOnClick={false}
                    onClose={setPopupInfo}
                  >
                    <CityInfo info={popupInfo} type={type} />
                  </Popup>
                )}

                <GeolocateControl style={geolocateStyle} />
                <FullscreenControl style={fullscreenControlStyle} />
                <NavigationControl style={navStyle} />
                <ScaleControl style={scaleControlStyle} />
              </MapGL>
            )}
          </>
        ) : props.loading ? (
          <Container paddingTop={"4rem"} classes={"mainNewLoader"} />
        ) : (
          <div style={{ height: "50vh" }}>
            {props.responseError ? (
              <div>
                <div
                  style={{
                    backgroundColor: "#fecd4e",
                    color: "black",
                    width: "100px",
                    height: "100px",
                    borderRadius: "100px",
                    fontSize: "60px",
                    textAlign: "center",
                    margin: "150px auto 20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  X
                </div>
                <h2>Failed to load data, please reload the page</h2>
              </div>
            ) : (
              <div>
                <div
                  style={{
                    backgroundColor: "green",
                    color: "white",
                    width: "100px",
                    height: "100px",
                    borderRadius: "100px",
                    fontSize: "60px",
                    textAlign: "center",
                    margin: "150px auto 20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  0
                </div>
                <h2>The specified domains have no results</h2>
                {props.searchMode !== "company" &&
                searchers.includes(props.user.Role) &&
                !props.successNotify ? (
                  <Button
                    variant="contained"
                    className="searchBtn"
                    endIcon={
                      props.notifyLoading ? (
                        <LoaderIcon
                          style={{
                            width: "16px",
                            height: "16px",
                            fill: "black",
                            position: "relative",
                            top: "-10px",
                          }}
                          type={"spin"}
                        />
                      ) : (
                        <MdNotifications />
                      )
                    }
                    onClick={props.addAlertDomains}
                    style={{
                      // width: "15%",
                      backgroundColor: "#FCCE4C",
                      padding: "5px 8px",
                    }}
                  >
                    Notify me of new data
                  </Button>
                ) : (
                  <h4>
                    <ImCheckmark size={22} /> Domains successfully added!
                  </h4>
                )}
              </div>
            )}
          </div>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {!props.dataLoading ? (
          <div className="tabScreen">
            <div
              style={{
                backgroundColor: "#E0E8EB",
                borderRadius: "10px",
                padding: "1rem",
                marginBottom: "2rem",
                width: "100%",
                position: "relative",
                top: "-20px",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "90%",
                }}
              >
                {" "}
                {wildcardRoles.includes(props.user.Role) && (
                  <MdRefresh
                    className="cursor"
                    size={35}
                    style={{
                      fill: "#222831",
                      position: "absolute",
                      top: "20px",
                      left: "20px",
                    }}
                    onClick={() => {
                      setKeywordInput("");
                      setWildcardType("");
                      setPage(0);
                      setTimeSelected([]);
                      props.getData(
                        [],
                        `employees?sortby=${sortType}`,
                        0,
                        null,
                        null,
                        true
                      );
                    }}
                  />
                )}
                <>
                  <div
                    className="screen-tools"
                    style={{
                      display: "inline-flex",
                      position: "relative",
                      // top: "-0.5rem",
                      width: "50%",
                    }}
                  >
                    <TimeFilters
                      timeOptions={timeOptions}
                      timeSelected={timeSelected}
                      onChange={(e) =>
                        filterByTime(e, sortType, "employees", false)
                      }
                    />
                    <SearchBy
                      searchType={sortType}
                      changeSearchType={(sortType) =>
                        changeSortType(sortType, "employees", false)
                      }
                      options={options}
                      placeholder="Sort By"
                    />
                  </div>
                </>
                <ExportToCsv
                  keywordInput={keywordInput}
                  wildcardType={wildcardType}
                  sortType={sortType}
                  user={props.user}
                  expiry={props.expiry}
                  type="employees"
                  handleHomeLogOut={props.handleHomeLogOut}
                  selectedDomain={props.domain}
                  data={props.data}
                  section={props.section}
                  count={props.employeesCount}
                  timeSelected={timeSelected}
                  company={props.company}
                  searchMode={props.searchMode}
                />
                <ExportCookies
                  keywordInput={keywordInput}
                  wildcardType={wildcardType}
                  sortType={sortType}
                  user={props.user}
                  expiry={props.expiry}
                  type="employees"
                  handleHomeLogOut={props.handleHomeLogOut}
                  selectedDomain={props.domain}
                  data={props.data}
                  section={props.section}
                  count={props.employeesCount}
                  timeSelected={timeSelected}
                  company={props.company}
                  searchMode={props.searchMode}
                />
                <div
                  style={{
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "50%",
                    margin: "0px 5px 0px 0px",
                  }}
                >
                  <DataHeader
                    mode={archiveMode}
                    onModeChange={(e, newValue) =>
                      onModeChange(e, newValue, false)
                    }
                    options={archiveOptions}
                    reRender={(newMode) => {
                      setPage(0);
                      props.getData(
                        [],
                        `employees?sortby=date&mode=${newMode}&time=${
                          timeSelected[0] ? timeSelected[0].value : "all"
                        }&keyword=${keywordInput}&key=${wildcardType}`,
                        0,
                        null,
                        null,
                        true
                      );
                    }}
                  />
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    {props.data && props.data.length > 0 ? (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          archiveMode === "unresolved"
                            ? resolveAll(props.domain, "employees")
                            : unresolveAll(props.domain, "employees");
                        }}
                        style={{
                          height: "38px",
                          width: "100%",
                          display: "inline-flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="cardBtn shadow-box"
                      >
                        <span>
                          {archiveMode === "resolved"
                            ? "Unresolve"
                            : "Resolve All"}
                        </span>
                        {archiveAllLoader ? (
                          <LoaderIcon
                            style={{
                              width: "16px",
                              height: "16px",
                              fill: "black",
                              position: "relative",
                              top: "-5px",
                              left: "2px",
                            }}
                            type={"spin"}
                          />
                        ) : archiveMode === "unresolved" ? (
                          <FaCheckCircle
                            size={16}
                            style={{ marginLeft: "2px" }}
                            className=""
                          />
                        ) : (
                          <ImCross
                            size={16}
                            style={{ marginLeft: "2px" }}
                            className=""
                          />
                        )}
                      </button>
                    ) : (
                      <button
                        style={{
                          height: "38px",
                          width: "100%",
                          display: "inline-flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="cardBtn shadow-box grayBtn"
                      >
                        <span>
                          {archiveMode === "resolved"
                            ? "Unresolve"
                            : "Resolve All"}
                        </span>
                      </button>
                    )}
                  </div>
                </div>
              </div>

              {wildcardRoles.includes(props.user.Role) && (
                <Autocomplete
                  setKeywordInput={setKeywordInput}
                  setWildcardType={setWildcardType}
                  loading={keywordLoading}
                  placeholder="Filter by Keyword/Username/PC Name/IP Address"
                  onChange={(e) => {
                    onChangeSystem(e, "employees");
                  }}
                  onFocus={(e) => {
                    if (keywordInput === "") {
                      onChangeSystem(e, "employees");
                    }
                  }}
                  value={keywordInput}
                  suggestions={keywordSuggestions}
                  fields={["url_part", "occurrence"]}
                  filterKeyword={() => {
                    filterByKeyword(sortType, "employees", false);
                  }}
                />
              )}
            </div>

            {!props.inPageLoading && props.data && props.data.length > 0 ? (
              <EmployeeTabs
                companySearch={props.searchMode === "company"}
                hasNextPage={props.hasNextPage}
                archiveMode={archiveMode}
                data={props.data}
                userRole={props.role}
                selectedDomain={props.domain}
                clientsCount={props.clientsCount}
                machines={props.machines}
                setData={(newData) => props.setData(newData)}
                reRender={() => {
                  setPage(0);
                  props.getData(
                    props.data,
                    `employees?sortby=date&archive=${archiveMode}&time=${
                      timeSelected[0] ? timeSelected[0].value : "all"
                    }&keyword=${keywordInput}&key=${wildcardType}`,
                    page,
                    null,
                    null,
                    true
                  );
                }}
                loadMore={() =>
                  loadMore(
                    `employees?sortby=${sortType}&mode=${archiveMode}&time=${
                      timeSelected[0] ? timeSelected[0].value : "all"
                    }&keyword=${keywordInput}&key=${wildcardType}`
                  )
                }
                loader={props.loader}
                employeesCount={props.employeesCount}
                finalRes={props.finalRes}
                config={props.config}
                unlockedStealers={props.unlockedStealers}
                overlay={props.inPageLoading}
                page={page}
                getCounters={props.getCounters}
                user={props.user}
                empLength={props.empLength}
              />
            ) : props.inPageLoading ? (
              <Container />
            ) : (
              <div style={{ height: "100vh", textAlign: "center" }}>
                <h1 className="capitalize">No {archiveMode} Data</h1>
              </div>
            )}
          </div>
        ) : props.dataLoading ? (
          <Container />
        ) : (
          <div style={{ height: "100vh", textAlign: "center" }}>
            <h1 className="capitalize">No Results</h1>
          </div>
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {!props.dataLoading ? (
          <div className="tabScreen">
            <div
              style={{
                backgroundColor: "#E0E8EB",
                borderRadius: "10px",
                padding: "1rem",
                marginBottom: "2rem",
                width: "100%",
                position: "relative",
                top: "-20px",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "90%",
                }}
              >
                {wildcardRoles.includes(props.user.Role) && (
                  <MdRefresh
                    className="cursor"
                    size={35}
                    style={{
                      fill: "#222831",
                      position: "absolute",
                      top: "20px",
                      left: "20px",
                    }}
                    onClick={() => {
                      setKeywordInput("");
                      setWildcardType("");
                      setPage(0);
                      setTimeSelected([]);
                      props.getData(
                        [],
                        `employees?sortby=${sortType}&thirdparties=true&time=all`,
                        0,
                        null,
                        null,
                        true
                      );
                    }}
                  />
                )}
                <>
                  <div
                    className="screen-tools"
                    style={{
                      display: "inline-flex",
                      position: "relative",
                      width: "50%",
                    }}
                  >
                    <TimeFilters
                      timeOptions={timeOptions}
                      timeSelected={timeSelected}
                      onChange={(e) =>
                        filterByTime(e, sortType, "employees", false, true)
                      }
                    />
                    <SearchBy
                      searchType={sortType}
                      changeSearchType={(sortType) =>
                        changeSortType(sortType, "employees", false, true)
                      }
                      options={options}
                      placeholder="Sort By"
                    />
                  </div>
                </>
                <ExportToCsv
                  keywordInput={keywordInput}
                  wildcardType={wildcardType}
                  sortType={sortType}
                  user={props.user}
                  expiry={props.expiry}
                  type="thirdparties"
                  handleHomeLogOut={props.handleHomeLogOut}
                  selectedDomain={props.domain}
                  data={props.data}
                  section={props.section}
                  count={props.partiesCount}
                  timeSelected={timeSelected}
                  company={props.company}
                  searchMode={props.searchMode}
                />
                <ExportCookies
                  keywordInput={keywordInput}
                  wildcardType={wildcardType}
                  sortType={sortType}
                  user={props.user}
                  expiry={props.expiry}
                  type="thirdparties"
                  handleHomeLogOut={props.handleHomeLogOut}
                  selectedDomain={props.domain}
                  data={props.data}
                  section={props.section}
                  count={props.partiesCount}
                  timeSelected={timeSelected}
                  company={props.company}
                  searchMode={props.searchMode}
                />
                <div
                  style={{
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "50%",
                    margin: "0px 5px 0px 0px",
                  }}
                >
                  <DataHeader
                    mode={archiveMode}
                    onModeChange={(e, newValue) =>
                      onModeChange(e, newValue, true)
                    }
                    options={archiveOptions}
                    reRender={(newMode) => {
                      setPage(0);
                      props.getData(
                        [],
                        `employees?thirdparties=true&sortby=date&mode=${newMode}&time=${
                          timeSelected[0] ? timeSelected[0].value : "all"
                        }&keyword=${keywordInput}&key=${wildcardType}`,
                        0,
                        null,
                        null,
                        true
                      );
                    }}
                  />
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    {props.data && props.data.length > 0 ? (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          archiveMode === "unresolved"
                            ? resolveAll(props.domain, "third_parties")
                            : unresolveAll(props.domain, "third_parties");
                        }}
                        style={{
                          height: "38px",
                          width: "100%",
                          display: "inline-flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="cardBtn shadow-box"
                      >
                        <span>
                          {archiveMode === "resolved"
                            ? "Unresolve"
                            : "Resolve All"}
                        </span>
                        {archiveAllLoader ? (
                          <LoaderIcon
                            style={{
                              width: "16px",
                              height: "16px",
                              fill: "black",
                              position: "relative",
                              top: "-5px",
                              left: "2px",
                            }}
                            type={"spin"}
                          />
                        ) : archiveMode === "unresolved" ? (
                          <FaCheckCircle
                            size={16}
                            style={{ marginLeft: "2px" }}
                            className=""
                            // onClick={(e) => {
                            //   e.stopPropagation();
                            //   archiveStealer(row, index, machineData);
                            // }}
                          />
                        ) : (
                          <ImCross
                            size={16}
                            style={{ marginLeft: "2px" }}
                            className=""
                            // onClick={(e) => {
                            //   e.stopPropagation();
                            //   archiveStealer(row, index, machineData);
                            // }}
                          />
                        )}
                      </button>
                    ) : (
                      <button
                        style={{
                          height: "38px",
                          width: "100%",
                          display: "inline-flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="cardBtn shadow-box grayBtn"
                      >
                        <span>
                          {archiveMode === "resolved"
                            ? "Unresolve"
                            : "Resolve All"}
                        </span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {wildcardRoles.includes(props.user.Role) && (
                <Autocomplete
                  setKeywordInput={setKeywordInput}
                  setWildcardType={setWildcardType}
                  loading={keywordLoading}
                  placeholder="Filter by Domains/Username/PC Name/IP Address"
                  onChange={(e) => {
                    onChangeSystem(e, "employees");
                  }}
                  onFocus={(e) => {
                    if (keywordInput === "") {
                      onChangeSystem(e, "employees");
                    }
                  }}
                  value={keywordInput}
                  suggestions={keywordSuggestions}
                  fields={["url_part", "occurrence"]}
                  filterKeyword={() => {
                    filterByKeyword(sortType, "employees", true);
                  }}
                />
              )}
            </div>
            {!props.inPageLoading && props.data && props.data.length > 0 ? (
              <EmployeeTabs
                companySearch={props.searchMode === "company"}
                hasNextPage={props.hasNextPage}
                archiveMode={archiveMode}
                data={props.data}
                userRole={props.role}
                selectedDomain={props.domain}
                clientsCount={props.clientsCount}
                machines={props.machines}
                isThirdParty={true}
                setData={(newData) => props.setData(newData)}
                reRender={() => {
                  setPage(0);
                  props.getData(
                    props.data,
                    `employees?thirdparties=true&sortby=date&mode=${archiveMode}&time=${
                      timeSelected[0] ? timeSelected[0].value : "all"
                    }&keyword=${keywordInput}&key=${wildcardType}`,
                    page,
                    true
                  );
                }}
                loadMore={() =>
                  loadMore(
                    `employees?thirdparties=true&mode=${archiveMode}&sortby=${sortType}&time=${
                      timeSelected[0] ? timeSelected[0].value : "all"
                    }&keyword=${keywordInput}&key=${wildcardType}`
                  )
                }
                loader={props.loader}
                employeesCount={props.partiesCount}
                finalRes={props.finalRes}
                config={props.config}
                unlockedStealers={props.unlockedStealers}
                overlay={props.inPageLoading}
                page={page}
                getCounters={props.getCounters}
                user={props.user}
                empLength={props.empLength}
              />
            ) : props.inPageLoading ? (
              <Container />
            ) : (
              <div style={{ height: "100vh", textAlign: "center" }}>
                <h1 className="capitalize">No {archiveMode} Data</h1>
              </div>
            )}
          </div>
        ) : props.dataLoading ? (
          <Container />
        ) : (
          <div style={{ height: "100vh", textAlign: "center" }}>
            <h1>No Results</h1>
          </div>
        )}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {!props.dataLoading ? (
          <div className="tabScreen">
            <div
              style={{
                backgroundColor: "#E0E8EB",
                borderRadius: "20px",
                padding: "1rem 5rem",
                // marginBottom: "2rem",
                width: "100%",
                position: "relative",
                // left: "-25px",
                top: "-20px",
                margin: "auto",
              }}
            >
              <MdRefresh
                className="cursor"
                size={35}
                style={{
                  fill: "#222831",
                  position: "absolute",
                  top: "20px",
                  left: "20px",
                }}
                onClick={() => {
                  setPage(0);
                  setKeyword("");
                  setKeywordInput("");
                  setKeywordSuggestions([]);
                  setSortType("date");
                  props.getData(
                    [],
                    `clients?android=false&sortby=date`,
                    0,
                    false,
                    "all",
                    true
                  );
                }}
              />
              <div
                className="screen-tools users-screen"
                style={{
                  display: "inline-flex",
                  position: "relative",
                  // top: "-0.5rem",
                  width: "100%",
                }}
              >
                <TimeFilters
                  timeOptions={timeOptions}
                  timeSelected={timeSelected}
                  onChange={(e) => filterByTime(e, sortType, "clients", false)}
                />
                <SearchBy
                  searchType={sortType}
                  changeSearchType={(sortType) =>
                    changeSortType(sortType, "clients", false)
                  }
                  options={options}
                  placeholder="Sort By"
                />
                <ExportToCsv
                  sortType={sortType}
                  user={props.user}
                  expiry={props.expiry}
                  type="users"
                  handleHomeLogOut={props.handleHomeLogOut}
                  selectedDomain={props.domain}
                  data={props.data}
                  section={props.section}
                  count={props.clientsCount}
                  timeSelected={timeSelected}
                  company={props.company}
                  searchMode={props.searchMode}
                  keywordInput={keywordInput}
                  wildcardType={wildcardType}
                />
                {props.user.ui_permissions &&
                  props.user.ui_permissions.includes("user_cookies") && (
                    <ExportCookies
                      sortType={sortType}
                      user={props.user}
                      expiry={props.expiry}
                      type="users"
                      handleHomeLogOut={props.handleHomeLogOut}
                      selectedDomain={props.domain}
                      data={props.data}
                      section={props.section}
                      count={props.clientsCount}
                      timeSelected={timeSelected}
                      company={props.company}
                      searchMode={props.searchMode}
                      keywordInput={keywordInput}
                      wildcardType={wildcardType}
                    />
                  )}
              </div>
              {wildcardRoles.includes(props.user.Role) && (
                <Autocomplete
                  width="100%"
                  setKeywordInput={setKeywordInput}
                  setWildcardType={setWildcardType}
                  loading={keywordLoading}
                  placeholder="Filter by Keyword/Username/PC Name/IP Address"
                  onChange={(e) => {
                    onChangeSystem(e, "clients");
                  }}
                  onFocus={(e) => {
                    if (keywordInput === "") {
                      onChangeSystem(e, "clients");
                    }
                  }}
                  value={keywordInput}
                  suggestions={keywordSuggestions}
                  fields={["url_part", "occurrence"]}
                  filterKeyword={() => {
                    filterByKeyword(sortType, "clients", true, false);
                  }}
                />
              )}
            </div>

            {!props.inPageLoading && props.data && props.data.length > 0 ? (
              <CustomPaginationActionsTable
                hasNextPage={props.hasNextPage}
                user={props.user}
                data={props.data}
                userRole={props.role}
                selectedDomain={props.domain}
                config={props.config}
                clientsCount={props.clientsCount}
                machines={props.machines}
                finalRes={props.finalRes}
                loadMore={() =>
                  loadMore(
                    `clients?android=false&sortby=${sortType}&time=${
                      timeSelected[0] ? timeSelected[0].value : "all"
                    }`
                  )
                }
                loader={props.loader}
                sortType={sortType}
              />
            ) : props.inPageLoading ? (
              <Container />
            ) : (
              <div style={{ height: "100vh", textAlign: "center" }}>
                <h1 className="capitalize">No {archiveMode} Data</h1>
              </div>
            )}
          </div>
        ) : props.dataLoading ? (
          <Container />
        ) : (
          <h1>No Results</h1>
        )}
      </TabPanel>
      <TabPanel value={value} index={4}>
        {!props.dataLoading ? (
          <div className="tabScreen">
            <div
              style={{
                backgroundColor: "#E0E8EB",
                borderRadius: "20px",
                padding: "1rem 8rem",
                // marginBottom: "2rem",
                width: "100%",
                position: "relative",
                // left: "-25px",
                top: "-20px",
                margin: "auto",
              }}
            >
              <MdRefresh
                className="cursor"
                size={35}
                style={{
                  fill: "#222831",
                  position: "absolute",
                  top: "20px",
                  left: "20px",
                }}
                onClick={() => {
                  setPage(0);
                  setKeyword("");
                  setKeywordInput("");
                  setKeywordSuggestions([]);
                  setSortType("date");
                  props.getData(
                    [],
                    `clients?android=true&sortby=date`,
                    0,
                    false,
                    "all",
                    true
                  );
                }}
              />
              <div
                className="screen-tools"
                style={{
                  display: "inline-flex",
                  position: "relative",
                  // top: "-0.5rem",
                  minWidth: "100%",
                }}
              >
                <TimeFilters
                  timeOptions={timeOptions}
                  timeSelected={timeSelected}
                  onChange={(e) => filterByTime(e, sortType, "clients", true)}
                />
                <SearchBy
                  searchType={sortType}
                  changeSearchType={(sortType) =>
                    changeSortType(sortType, "clients", true)
                  }
                  options={options}
                  placeholder="Sort By"
                />
                <ExportToCsv
                  keywordInput={keywordInput}
                  wildcardType={wildcardType}
                  sortType={sortType}
                  user={props.user}
                  expiry={props.expiry}
                  type="users_android"
                  handleHomeLogOut={props.handleHomeLogOut}
                  selectedDomain={props.domain}
                  data={props.data}
                  section={props.section}
                  count={props.androidsCount}
                  timeSelected={timeSelected}
                  company={props.company}
                  searchMode={props.searchMode}
                />
              </div>
              {wildcardRoles.includes(props.user.Role) && (
                <Autocomplete
                  width="100%"
                  setKeywordInput={setKeywordInput}
                  setWildcardType={setWildcardType}
                  loading={keywordLoading}
                  placeholder="Filter by Keyword/Username/PC Name/IP Address"
                  onChange={(e) => {
                    onChangeSystem(e, "apk");
                  }}
                  onFocus={(e) => {
                    if (keywordInput === "") {
                      onChangeSystem(e, "apk");
                    }
                  }}
                  value={keywordInput}
                  suggestions={keywordSuggestions}
                  fields={["url_part", "occurrence"]}
                  filterKeyword={() => {
                    filterByKeyword(sortType, "clients", true, true);
                  }}
                />
              )}
            </div>

            {!props.inPageLoading && props.data && props.data.length > 0 ? (
              <CustomPaginationActionsTable
                hasNextPage={props.hasNextPage}
                user={props.user}
                data={props.data}
                userRole={props.role}
                selectedDomain={props.domain}
                config={props.config}
                clientsCount={props.androidsCount}
                machines={props.machines}
                finalRes={props.finalRes}
                loadMore={() =>
                  loadMore(
                    `clients?android=true&sortby=${sortType}&time=${
                      timeSelected[0] ? timeSelected[0].value : "all"
                    }`
                  )
                }
                loader={props.loader}
                androidsCount={props.androidsCount}
                sortType={sortType}
              />
            ) : props.inPageLoading ? (
              <Container />
            ) : (
              <div style={{ height: "100vh", textAlign: "center" }}>
                <h1 className="capitalize">No {archiveMode} Data</h1>
              </div>
            )}
          </div>
        ) : props.dataLoading ? (
          <Container />
        ) : (
          <h1>No Results</h1>
        )}
      </TabPanel>
      <TabPanel value={value} index={5}>
        {!props.dataLoading ? (
          <div className="tabScreen">
            <div
              style={{
                backgroundColor: "#E0E8EB",
                borderRadius: "20px",
                padding: "1rem 5rem",
                // marginBottom: "2rem",
                width: "100%",
                position: "relative",
                // left: "-25px",
                top: "-20px",
                margin: "auto",
                textAlign: "center",
              }}
            >
              <MdRefresh
                className="cursor"
                size={35}
                style={{
                  fill: "#222831",
                  position: "absolute",
                  top: "20px",
                  left: "20px",
                }}
                onClick={() => {
                  props.setSelectedExternals(externalOptions);
                  setPage(0);
                  setKeyword("");
                  setKeywordInput("");
                  setKeywordSuggestions([]);
                  setSortType("date");
                  props.getData(
                    [],
                    `external-domains?sortby=date`,
                    0,
                    false,
                    "all",
                    true
                  );
                }}
              />
              <div
                className="screen-tools"
                style={{
                  display: "inline-flex",
                  position: "relative",
                  // top: "-1rem",
                  textAlign: "left",
                  minWidth: "80%",
                  justifyContent: "center",
                }}
              >
                <FormControl
                  className={classes.formControl}
                  style={{ width: "50%", margin: "0px 5px", maxWidth: "300px" }}
                >
                  {props.user.external_domains && (
                    <MultiSelect
                      overrideStrings={{
                        allItemsAreSelected: "All domains are selected.",
                      }}
                      hasSelectAll={true}
                      ItemRenderer={DefaultItemRenderer}
                      shouldToggleOnHover={true}
                      options={externalOptions}
                      value={
                        props.selectedExternals.length > externalOptions.length
                          ? externalOptions
                          : props.selectedExternals
                      }
                      onChange={props.setSelectedExternals}
                      labelledBy="Select"
                    />
                  )}
                </FormControl>
                <>
                  <SearchBy
                    searchType={sortType}
                    changeSearchType={(sortType) =>
                      changeSortType(sortType, "external-domains", false)
                    }
                    options={options}
                    placeholder="Sort By"
                  />
                  <ExportToCsv
                    keywordInput={keywordInput}
                    wildcardType={wildcardType}
                    sortType={sortType}
                    user={props.user}
                    expiry={props.expiry}
                    type="externals"
                    handleHomeLogOut={props.handleHomeLogOut}
                    selectedDomain={props.externalDomain}
                    data={props.data}
                    section="external"
                    timeSelected={timeSelected}
                    searchMode={props.searchMode}
                    company={props.company}
                    externalSuggestions={props.externalSuggestions}
                  />
                </>
              </div>
              {wildcardRoles.includes(props.user.Role) && (
                <Autocomplete
                  width="80%"
                  setKeywordInput={setKeywordInput}
                  setWildcardType={setWildcardType}
                  loading={keywordLoading}
                  placeholder="Filter by Keyword/Username/PC Name/IP Address"
                  onChange={(e) => {
                    onChangeSystem(e, "externals");
                  }}
                  onFocus={(e) => {
                    if (keywordInput === "") {
                      onChangeSystem(e, "externals");
                    }
                  }}
                  value={keywordInput}
                  suggestions={keywordSuggestions}
                  fields={["url_part", "occurrence"]}
                  filterKeyword={() => {
                    filterByKeyword(sortType, "external-domains", true, true);
                  }}
                />
              )}
            </div>

            {!props.inPageLoading && props.data && props.data.length > 0 ? (
              <CustomPaginationActionsTable
                hasNextPage={props.hasNextPage}
                user={props.user}
                data={props.data}
                userRole={props.role}
                selectedDomain={props.domain}
                config={props.config}
                clientsCount={props.clientsCount}
                machines={props.machines}
                finalRes={props.finalRes}
                loadMore={() => loadMore(`external-domains?sortby=${sortType}`)}
                loader={props.loader}
                sortType={sortType}
                externals={true}
              />
            ) : props.inPageLoading ? (
              <Container />
            ) : (
              <h1 style={{ textAlign: "center" }}>No Results</h1>
            )}
          </div>
        ) : props.dataLoading ? (
          <Container />
        ) : (
          <h1 style={{ textAlign: "center" }}>No Results</h1>
        )}
      </TabPanel>
      <TabPanel value={value} index={6}>
        {!props.dataLoading &&
        props.data &&
        props.data.length > 0 &&
        props.dbsCount > 0 ? (
          <div className="tabScreen">
            <ExportToCsv
              sortType={sortType}
              user={props.user}
              expiry={props.expiry}
              type="databases"
              handleHomeLogOut={props.handleHomeLogOut}
              selectedDomain={props.domain}
              data={props.data}
              section={props.section}
              timeSelected={timeSelected}
              color="white"
            />
            <DbTabs
              user={props.user}
              clientsCount={props.dbsCount}
              loading={props.loading}
              finalRes={props.finalRes}
              domain={props.domain}
              token={props.token}
              data={props.data}
              userRole={props.role}
              selectedDomain={props.domain}
              // clientsCount={clientsCount}
              machines={props.machines}
              loadMore={() => loadMore("dbs/domainsearch")}
              loader={props.loader}
              employeesCount={props.employeesCount}
              config={props.config}
            />
          </div>
        ) : props.dataLoading ? (
          <Container />
        ) : (
          <h1>No Results</h1>
        )}
      </TabPanel>
      <TabPanel value={value} index={7}>
        {!props.dataLoading &&
        props.allMonitoredCounts &&
        props.monitorCount > 0 ? (
          <div className="tabScreen">
            <ThirdPartyTabs
              data={props.allMonitoredCounts}
              monitoredDomains={props.monitoredDomains}
              token={props.token}
              yearsOptions={props.yearsOptions}
              monthly={props.monthly}
              switchToAllTimeChart={props.switchToAllTimeChart}
              switchToMonthlyChart={props.switchToMonthlyChart}
              handleYearChange={props.handleYearChange}
              year={props.year}
              employeesStats={props.employeesStats}
              clientsStats={props.clientsStats}
              counts={props.counts}
              labels={props.labels}
              loading={props.loading}
              // getStats={() => props.getStats("query", "User")}
              user={props.user}
            />
          </div>
        ) : props.dataLoading ? (
          <Container />
        ) : (
          <h1>No Results</h1>
        )}
      </TabPanel>

      <TabPanel value={value} index={8}>
        {!props.dataLoading ? (
          <div className="tabScreen">
            <AssetsDiscovery
              currentCsvDomain={props.currentCsvDomain}
              company={props.currentCompany}
              assetsType={props.assetsType}
              assetsCount={props.assetsCount}
              data={props.assets.data}
              numbers={props.assets.visualize}
              assets={props.assets}
              user={props.user}
              token={props.token}
              resetUser={props.resetUser}
              exportUrls={(domain, type, typeList) =>
                props.exportUrls(domain, type, typeList)
              }
              csvUrls={props.csvUrls}
              csvUrlsLoading={props.csvUrlsLoading}
              getAssetDiscovery={(type, inPageLoading) =>
                props.getAssetDiscovery(type, inPageLoading)
              }
              inPageLoading={props.inPageLoading}
              hasExternals={
                (props.user.external_domains &&
                  props.user.external_domains.length > 0) ||
                (props.currentCompany &&
                  props.currentCompany.data &&
                  props.currentCompany.data.external_domains &&
                  props.currentCompany.data.external_domains.length > 0)
              }
            />
          </div>
        ) : props.dataLoading ? (
          <Container />
        ) : (
          <h1>No Results</h1>
        )}
      </TabPanel>
      <div className="mobile">
        {!props.loading ? (
          counters.map((counter, index) =>
            counter.count > 0 ? (
              <Tab
                className={counter.class}
                key={index}
                onClick={counter.count > 0 && counter.func}
                label={counter.label}
                icon={counter.icon}
                {...a11yProps(index)}
              />
            ) : (
              <Tab
                className={counter.class}
                disabled
                key={index}
                label={counter.label}
                icon={counter.icon}
                {...a11yProps(index)}
              />
            )
          )
        ) : (
          <LoaderIcon className="homeLoader" type={"spinningBubbles"} />
        )}
      </div>
    </div>
  );
}
