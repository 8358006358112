/* eslint-disable */

import React, { useEffect, useState } from "react";
import { FaUserTie, FaUsers } from "react-icons/fa";
import LoaderIcon from "react-loader-icon";
import CreateApiClient from "../../API";
import filters from "../../company_filters.json";
import cavaLogo from "../../images/thumbnail.jpeg";
import LineChart from "../LineChart";
import Carousel from "../partials/Carousel";
import CompanyFilters from "../partials/CompanyFilters";
import GradualBoxes from "../partials/GradualBoxes";

const defaultSrc = (ev) => {
  ev.target.src = cavaLogo;
};
const api = CreateApiClient();

export default function Timeline(props) {
  const [loading, setLoading] = useState(false);
  const [timeframe, setTimeframe] = useState("yearly");
  const [year, setYear] = useState("All Time");
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [industriesSelected, setIndustriesSelected] = useState([]);
  const [countriesSelected, setCountriesSelected] = useState([]);
  const [isUsa] = useState(false);
  const [domains, setDomains] = useState([]);
  const [mode, setMode] = useState("unfiltered");

  useEffect(() => {
    let preferences = localStorage.getItem("preferences");
    preferences = JSON.parse(preferences);
    if (mode === "unfiltered" && !preferences) {
      getStats();
    } else {
      if (preferences) {
        setIndustriesSelected(preferences.industries);
        setCountriesSelected(preferences.countries);
        getTimeline(preferences.industries, preferences.countries);
      } else {
        getTimeline(industriesSelected, countriesSelected);
      }
    }
  }, [year]);

  const handleYearChange = (event) => {
    if (event.target.value !== "All Time") {
      setTimeframe("monthly");
    } else {
      setTimeframe("yearly");
    }
    setYear(event.target.value);
  };

  const getStats = async () => {
    setLoading(true);
    let query;
    if (year !== "All Time") {
      query = `stats/overall?frame=monthly&year=${year}`;
    } else {
      query = `stats/overall?frame=yearly`;
    }

    let config = {
      headers: {
        "auth-token": props.user.Token
          ? props.user.Token
          : JSON.parse(props.user).Token,
      },
    };

    const response = await api.fetchData(query, {}, config);

    if (response.clientsCount) {
      setCount(1);
      setData(response);
      setDomains(response.domains);
    } else {
      setCount(0);
    }
    setLoading(false);
  };

  const getTimeline = async (industries, countries) => {
    setMode("filtered");
    setIndustriesSelected(industries);
    setCountriesSelected(countries);

    localStorage.setItem(
      "preferences",
      JSON.stringify({
        industries: industries,
        countries: countries,
      })
    );

    setLoading(true);

    let url = `stats/filter?frame=${timeframe}&year=${year}`;

    let config = {
      headers: {
        "auth-token": props.user.Token
          ? props.user.Token
          : JSON.parse(props.user).Token,
      },
    };

    let body = {
      industries: industries,
      countries: countries,
      all: {
        industries: filters.industries,
        countries: filters.countries,
      },
    };

    const response = await api.fetchData(url, body, config);
    setData(response);

    if (response.clientsCount) {
      setCount(1);
      setDomains(response.domains);
    } else {
      setCount(0);
    }
    setLoading(false);
  };

  return (
    <div
      style={{
        backgroundColor: "#222831",
        padding: "1rem 2rem",
        borderRadius: "20px",
      }}
    >
      <LineChart
        component={
          <CompanyFilters
            user={props.user}
            timeframe={timeframe}
            year={year}
            getTimeline={(industries, countries) =>
              getTimeline(industries, countries)
            }
            clear={() => {
              localStorage.removeItem("preferences");
              setMode("unfiltered");
              setIndustriesSelected([]);
              setCountriesSelected([]);
              setTimeframe("yearly");
              setDomains([]);
              if (year !== "All Time") {
                setYear("All Time");
              } else {
                getStats();
              }
            }}
            isUsa={isUsa}
          />
        }
        custom={true}
        id={"mainTimeline"}
        chartLoader={loading}
        labelColor="#fff"
        yearsOptions={data.years}
        handleYearChange={handleYearChange}
        year={year}
        employeesStats={data.employeesCount}
        clientsStats={data.clientsCount}
        labels={data.labels}
        stealersCount={count}
      />
      {domains && domains.length > 0 && !loading ? (
        <div>
          {mode === "filtered" && (
            <span
              className="capitalize"
              style={{
                backgroundColor: "#FBCD4E",
                color: "#222831",
                margin: "10px 0px",
                padding: "2px 8px",
                borderRadius: "20px",
              }}
            >
              {industriesSelected.length === 147
                ? "All Industries"
                : industriesSelected[0].label}{" "}
              -{" "}
              {countriesSelected.length === 247
                ? "Worldwide"
                : countriesSelected.length > 1
                ? countriesSelected.length + " Countries"
                : countriesSelected[0].label}
            </span>
          )}
          {data.topEmps && data.topEmps.length > 0 && (
            <div style={{ marginTop: "20px" }}>
              {data.topEmps && data.topEmps.length > 6 ? (
                <>
                  <FaUserTie
                    size={35}
                    style={{ fill: "#FBCD4E", marginBottom: "10px" }}
                  />
                  <h2
                    style={{
                      color: "#FBCD4E",
                    }}
                  >
                    Top Compromised Employees
                  </h2>
                  <Carousel
                    loading={loading}
                    onClick={(e, item) => props.onClick(e, item)}
                    topEmps={data.topEmps}
                    defaultSrc={defaultSrc}
                  />
                </>
              ) : (
                data.topEmps.find((s) => s.count > 0) && (
                  <>
                    <FaUserTie
                      size={35}
                      style={{ fill: "#FBCD4E", marginBottom: "10px" }}
                    />
                    <h2
                      style={{
                        color: "#FBCD4E",
                      }}
                    >
                      Top Compromised Employees
                    </h2>
                    <GradualBoxes
                      onClick={(e, item) => props.onClick(e, item)}
                      options={domains.slice(0, 6)}
                      topEmps={data.topEmps}
                      loading={loading}
                    />
                  </>
                )
              )}
            </div>
          )}
          <br />
          {data.topUsers && data.topUsers.length > 0 && (
            <div>
              {data.topUsers && data.topUsers.length > 6 ? (
                <>
                  <FaUsers size={40} style={{ fill: "#FBCD4E" }} />
                  <h2
                    style={{
                      color: "#FBCD4E",
                    }}
                  >
                    Top Compromised Users
                  </h2>
                  <Carousel
                    loading={loading}
                    onClick={(e, item) => props.onClick(e, item)}
                    topUsers={data.topUsers}
                    defaultSrc={defaultSrc}
                  />
                </>
              ) : (
                <>
                  <FaUsers size={40} style={{ fill: "#FBCD4E" }} />
                  <h2
                    style={{
                      color: "#FBCD4E",
                    }}
                  >
                    Top Compromised Users
                  </h2>
                  <GradualBoxes
                    onClick={(e, item) => props.onClick(e, item)}
                    options={domains.slice(0, data.topUsers.length)}
                    topUsers={data.topUsers}
                    loading={loading}
                  />
                </>
              )}
            </div>
          )}
        </div>
      ) : (
        loading && (
          <div
            style={{
              height: "650px",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <LoaderIcon type="spin" style={{ fill: "white" }} />
          </div>
        )
      )}
    </div>
  );
}
