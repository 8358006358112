import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import React, { useState } from "react";
import { APIFullEmailAddress } from "../../Config";

export default function FeedbackModal(props) {
  const [text, setText] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const onChangeText = (e) => {
    setError(null);
    setSuccess(false);
    setText(e.target.value);
  };

  const sendFeedback = async () => {
    let msg = text;
    if (props.page) {
      msg = `${text}\npage: ${props.page}`;
    }
    const response = axios.post(
      `${APIFullEmailAddress}/send/feedback`,
      {
        text: msg,
        mode: props.mode,
      },
      { headers: { "auth-token": props.user.Token } }
    );
    response
      .then((res) => {
        setSuccess(true);
      })
      .catch((err) => {
        setError(err.response.data);
      });
  };

  const sendCustomMessage = async () => {
    const response = axios.post(
      `${APIFullEmailAddress}/send/message`,
      {
        text: props.customMessage,
      },
      { headers: { "auth-token": props.user.Token } }
    );
    response
      .then((res) => {
        setSuccess(true);
      })
      .catch((err) => {
        setError(err.response.data);
      });
  };

  return (
    <div>
      {success ? (
        <Dialog
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="form-dialog-title"
          id="feedbackModal"
        >
          <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
            {props.thanks ? props.thanks : "Thank you for your feedback!"}
          </DialogTitle>
        </Dialog>
      ) : error ? (
        <Dialog
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="form-dialog-title"
          id="feedbackModal"
        >
          <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
            {error}
          </DialogTitle>
        </Dialog>
      ) : (
        <Dialog
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="form-dialog-title"
          id="feedbackModal"
        >
          <DialogTitle id="form-dialog-title">
            {props.title ? props.title : "Send Feedback"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {props.text
                ? props.text
                : "Please tell us your suggestion or report an issue."}
            </DialogContentText>
            {!props.hideInput && (
              <TextField
                autoFocus
                margin="dense"
                id="feedback"
                label={props.label ? props.label : "Your Feedback"}
                type="text"
                fullWidth
                onChange={onChangeText}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={props.customMessage ? sendCustomMessage : sendFeedback}
              color="primary"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
