import * as React from "react";

function CityInfo(props) {
  const { info } = props;
  const displayName = `${info.city}, ${info.region}, ${info.country}`;

  return (
    <div className="lightText merkerPopUp">
      {displayName}
      <div>
        <ul className="marketList">
          <li>Type: {info.type}</li>
          <li>Longitude: {info.longitude}</li>
          <li>latitude: {info.latitude}</li>
          <li>timezone: {info.timezone}</li>
          <li>isp: {info.isp}</li>
          <li>proxy: {info.proxy}</li>
          <li>VPN: {info.vpn}</li>
          <li>tor: {info.tor}</li>
          <li>Source Name: {info.area_source_name}</li>
          <li>Source Domain: {info.area_source_domain}</li>
          <li>Source Route: {info.area_source_route}</li>
          <li>Source Type: {info.area_source_type}</li>
        </ul>
      </div>
    </div>
  );
}

export default React.memo(CityInfo);
