import Collapse from "@material-ui/core/Collapse";
import SvgIcon from "@material-ui/core/SvgIcon";
import { alpha, makeStyles, withStyles } from "@material-ui/core/styles";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import { animated, useSpring } from "@react-spring/web"; // web.cjs is required for IE 11 support
import PropTypes from "prop-types";
import React from "react";
import { FaFile } from "react-icons/fa";
import { RiFolderOpenFill } from "react-icons/ri";

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: "translate3d(20px,0,0)" },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    "& .close": {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha("#1A1C20", 0.4)}`,
  },
}))((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
));

const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
});

export default function CustomizedTreeView(props) {
  const classes = useStyles();
  console.log(props.data);
  return (
    <TreeView
      className={classes.root}
      defaultExpanded={["1"]}
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<RiFolderOpenFill size={25} />}
      defaultEndIcon={<FaFile size={12} />}
    >
      {props.data.children &&
        props.data.children.map((child, index) =>
          child.type === "directory" ? (
            <StyledTreeItem
              key={index}
              nodeId={index}
              label={<span className="directoryLabel">{child.name}</span>}
            >
              <CustomizedTreeView data={child} />
            </StyledTreeItem>
          ) : (
            <StyledTreeItem
              key={index}
              nodeId={index}
              label={<span className="fileLabel">{child.name}</span>}
            ></StyledTreeItem>
          )
        )}
    </TreeView>
  );
}
