/* eslint-disable */

import Button from "@material-ui/core/Button";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { FaCookieBite } from "react-icons/fa";
import LoaderIcon from "react-loader-icon";
import CreateApiClient from "../API";
import SimpleModal from "./partials/SimpleModal";

const api = CreateApiClient();

export default function ExportCookies(props) {
  const [csv, setCsv] = useState([]);
  const [csvLoading, setCsvLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [alertBody, setAlertBody] = useState("");
  const [bigExport, setBigExport] = useState(false);

  useEffect(() => {
    setCsv([]);
  }, [props.timeSelected, props.sortType, props.data]);

  const exportToCsv = async () => {
    const embedded = ["employees", "thirdparties", "cookies"];
    const loggedInUser = localStorage.getItem("user");

    if (loggedInUser) {
      const foundUser = await JSON.parse(loggedInUser);
      const time = Date.now().toString().substr(0, 10);
      const nowNum = Number(time);
      if (foundUser.expiry < nowNum) {
        props.handleHomeLogOut();
      } else {
        setCsvLoading(true);
        let endpoint, domainQuery;
        let timeFrame =
          props.timeSelected && props.timeSelected[0]
            ? props.timeSelected[0].value
            : "all";
        switch (props.type) {
          case "employees":
            endpoint = `employees/csv?cookies=true&time=${timeFrame}&sortby=${
              props.sortType
            }&keyword=${props.keywordInput && props.keywordInput}&key=${
              props.wildcardType
            }`;
            break;
          case "thirdparties":
            endpoint = `employees/csv?cookies=true&thirdparties=true&time=${timeFrame}&sortby=${
              props.sortType
            }&keyword=${props.keywordInput && props.keywordInput}&key=${
              props.wildcardType
            }`;
            break;
          case "users":
            endpoint = `clients/csv?android=false&time=${timeFrame}&sortby=${
              props.sortType
            }&cookies=true&keyword=${
              props.keywordInput && props.keywordInput
            }&key=${props.wildcardType}`;
            break;
          case "users_android":
            endpoint = `clients/csv?android=true&time=${timeFrame}&sortby=${
              props.sortType
            }&keyword=${props.keywordInput && props.keywordInput}&key=${
              props.wildcardType
            }`;
            break;
          case "databases":
            endpoint = `dbs?csv=true`;
            break;
          case "externals":
            endpoint = `external-domains/csv?keyword=${
              props.keywordInput && props.keywordInput
            }&key=${props.wildcardType}`;
            break;
          case "cookies":
            endpoint = `counts/cookies?domain=${props.selectedDomain}&csv=true`;
            break;
          case "keyword":
            endpoint = `counts/export-keyword?keyword=${props.selectedDomain}`;
            break;
        }

        if (props.selectedDomain.includes("&")) {
          domainQuery = props.selectedDomain.split("&");
        } else {
          domainQuery = props.selectedDomain;
        }

        const query = {
          domain: domainQuery,
          company: props.searchMode === "company" ? props.company : null,
        };

        const config = {
          headers: {
            "auth-token": foundUser.Token,
          },
        };

        let csvData = await api.fetchData(endpoint, query, config);
        if (csvData === "OK") {
          setAlertBody(
            <div className="flexAlertBox">
              <div>
                {props.count > 30000 && (
                  <h1>
                    Due to the amount of results, it may take more than 1 hour
                    to export the data, set a shorter period for a faster export
                  </h1>
                )}
                <br />
                <h3
                  className="lightText"
                  style={{
                    lineHeight: "2",
                    color: "black",
                  }}
                >
                  ZIP file will be ready to download from{" "}
                  <a
                    href="/my-account"
                    target="_blank"
                    rel="noreferrer"
                    className="yellow"
                  >
                    My Account
                  </a>{" "}
                  page. <br />
                  An email will be sent to {props.user.email} once ready.
                </h3>
              </div>
            </div>
          );
          setModalOpen(true);

          setBigExport(true);
        } else if (csvData === "LIMIT") {
          setAlertBody(
            <div className="flexAlertBox">
              <h1>
                Due to the amount of results, please contact us at{" "}
                <a href="mailto:sales@hudsonrock.com">sales@hudsonrock.com</a>{" "}
                for export.
              </h1>
            </div>
          );
          setModalOpen(true);
          setBigExport(true);
        } else if (!csvData[0]) {
          setAlertBody(
            <div className="flexAlertBox">
              <h1>
                No data to export, if you believe this is a mistake please
                contact us at{" "}
                <a href="mailto:support@hudsonrock.com">
                  support@hudsonrock.com
                </a>{" "}
              </h1>
            </div>
          );
          setModalOpen(true);
          setBigExport(true);
        }

        if (embedded.includes(props.type) && csvData !== "OK") {
          csvData = csvData.data;
        }

        csvData.length > 0
          ? setCsv(csvData) && setCsvLoading(true)
          : setCsv([]);

        setCsvLoading(false);

        if (document.getElementById("csvCookiesDownload") && csvData !== "OK") {
          click();
        }
      }
    }
  };

  const click = () => {
    document.getElementById("csvCookiesDownload").click();
  };

  return props.user.ui_permissions.includes("cookies") ? (
    <div className="exportDiv">
      <SimpleModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        html={alertBody}
      />
      {props.user.ui_permissions.includes("csv_export") &&
      csv.length <= 0 &&
      !csvLoading &&
      props.data.length > 0 &&
      !message ? (
        <Button
          variant="contained"
          className="searchBtn"
          onClick={() => {
            exportToCsv();
          }}
          style={{
            height: "38px",
            width: props.type !== "databases" && "100%",
          }}
        >
          <FaCookieBite size={20} style={{ marginRight: "5px" }} />
          Export Cookies
        </Button>
      ) : (
        csv.length <= 0 &&
        !message &&
        !csvLoading && (
          <Button
            variant="contained"
            className="searchBtn grayBtn"
            style={{
              height: "38px",
              width: props.type !== "databases" && "100%",
            }}
          >
            <FaCookieBite size={20} style={{ marginRight: "5px" }} />
            Export Cookies
          </Button>
        )
      )}
      {message && (
        <span
          className="csvLoadingLabel"
          style={{ fontSize: "16px", lineHeight: "2", color: "black" }}
        >
          {message}
        </span>
      )}
      {csvLoading && (
        <>
          <LoaderIcon
            id="csvLoader"
            className={
              props.color === "white"
                ? "tabsLoader csvLoader"
                : "tableLoader csvLoader"
            }
            type={"spin"}
          />
          <span
            className="csvLoadingLabel"
            style={{
              fontSize: "16px",
              lineHeight: "2",
              color: props.color ? props.color : "black",
            }}
          >
            Preparing CSV file{" "}
          </span>
        </>
      )}
      {csv.length > 0 && !csvLoading && (
        <>
          {bigExport ? (
            <span
              className="lightText"
              style={{
                fontSize: "12px",
                lineHeight: "2",
                color: "black",
              }}
            >
              CSV file will be ready to download from{" "}
              <a
                href="/my-account"
                target="_blank"
                rel="noreferrer"
                className="yellow"
                // style={{ color: "#FBCD4E" }}
              >
                My Account
              </a>{" "}
              page. An email will be sent to {props.user.email} once ready.
            </span>
          ) : (
            <CSVLink
              id="csvCookiesDownload"
              data={csv}
              filename={`${props.type}_cookies_${new Date()
                .toISOString()
                .substring(0, 10)}.csv`}
              className={props.color === "white" ? "darkText" : "lightText"}
              style={{
                color: props.color ? props.color : "black",
              }}
              target="_blank"
              rel="noreferrer"
            >
              Click here if the file has not been downloaded
            </CSVLink>
          )}
        </>
      )}
    </div>
  ) : (
    <div style={{ height: "50px" }}></div>
  );
}
