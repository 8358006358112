/* eslint-disable */

import { useEffect, useState } from "react";
import { MdRefresh } from "react-icons/md";
import CreateApiClient from "../../API";
import { adminUrl } from "../../Config";
import AppBarNew from "../AppBar";
import AdminGrid from "./AdminGrid";

const api = CreateApiClient();

export default function Panel(props) {
  const [page, setPage] = useState(0);
  const [users, setUsers] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState([]);
  const [updateLoader, setUpdateLoader] = useState(null);
  const [pageSize, setPageSize] = useState(100);
  const [newDomain, setNewDomain] = useState("");
  const [csv, setCsv] = useState([]);
  const [restarted, setRestarted] = useState("Don't Click Me");

  useEffect(() => {
    getUsers(filters);
  }, [page, pageSize, filters]);

  const headers = {
    "auth-token": props.token,
  };

  const exportAllToCsv = async (visibleColumns, columns) => {
    let projectionObj = {};
    columns.map((item) => {
      let unchecked = visibleColumns[item.field] === false;
      if (!unchecked) {
        projectionObj[item.field] = 1;
      }
    });
    setLoading(true);
    const res = await api.adminReq(
      "POST",
      `${adminUrl}/users?csv=true`,
      headers,
      { filters: filters, projection: projectionObj }
    );
    setCsv(res.data.users);
    setLoading(false);
    click();
  };

  const getUsers = async (filters) => {
    setLoading(true);
    const res = await api.adminReq(
      "POST",
      `${adminUrl}/users?page=${page}&size=${pageSize}`,
      headers,
      { filters: filters }
    );
    setTable(res);
    setLoading(false);
  };

  const updateUsers = async (action, id, field, value) => {
    setUpdateLoader(`${id}_${value}`);
    const res = await api.adminReq(
      "POST",
      `${adminUrl}/users/update?page=${page}&action=${action}&id=${id}&field=${field}&value=${value}&size=${pageSize}`,
      headers,
      { filters: filters }
    );
    setTable(res);
    setUpdateLoader(null);
    setNewDomain("");
  };

  const bulkUpdateUsers = async (field, value, rows) => {
    let warn;
    if (field === "active") {
      if (value === true) {
        warn = "activate";
      } else {
        warn = "deactivate";
      }
    } else if (field === "delete") {
      warn = "delete";
    } else {
      warn = "edit";
    }

    let emails = rows.map((row) => row.email);
    emails = emails.join("\n");
    let confirmed = window.confirm(
      `Are you sure you want to ${warn} \n${emails}?`
    );

    if (confirmed) {
      setLoading(true);
      const res = await api.adminReq(
        "POST",
        `${adminUrl}/users/bulk-update?page=${page}&field=${field}&value=${value}&size=${pageSize}`,
        headers,
        { filters: filters, users: rows }
      );
      setTable(res);
      setLoading(false);
    }
  };

  const filterTable = async (e) => {
    setFilters(e.items[0]);
  };

  const setTable = (res) => {
    res.data.users && setUsers(res.data.users);
    res.data.count && setUsersCount(res.data.count);
    res.data.count >= 100 ? setPageSize(100) : setPageSize(res.data.count);
  };

  const click = () => {
    document.getElementById("csvDownload").click();
  };

  const restartServer = async () => {
    const res = await api.adminReq(
      "POST",
      `${adminUrl}/restart-server`,
      headers
    );
    if (res && res.data) {
      setRestarted(res.data);
    } else {
      setRestarted("Cavalier restarted successfully.");
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    }
  };

  return (
    <div
      id="hector-panel"
      className="App"
      style={{ backgroundColor: "white", overflowY: "hidden" }}
    >
      <AppBarNew
        className="mobileHeader"
        user={props.user}
        role={props.role}
        token={props.token}
        email={props.email}
        domains={props.domains}
        handleLogOut={props.handleHomeLogOut}
      />
      <div
        style={{
          backgroundColor: "white",
          marginTop: "5rem",
          color: "black",
          padding: "10px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <h4 style={{ textAlign: "left" }} className="cyberFont">
              Welcome dear admin, please convert me 💸 💰 💵 🤑
            </h4>
          </div>
          {/* <div>
            <button className="uppercase" onClick={restartServer}>
              {restarted}
            </button>
          </div> */}
          <div>
            <MdRefresh
              size={30}
              onClick={() => {
                setPage(0);
                setCsv([]);
                setFilters([]);
              }}
              className="cursor"
            />
          </div>
        </div>
        <AdminGrid
          csv={csv}
          users={users}
          setPage={setPage}
          page={page}
          usersCount={usersCount}
          loading={loading}
          filterTable={filterTable}
          updateUsers={updateUsers}
          updateLoader={updateLoader}
          pageSize={pageSize}
          bulkUpdateUsers={bulkUpdateUsers}
          newDomain={newDomain}
          setNewDomain={setNewDomain}
          exportAllToCsv={exportAllToCsv}
        />
      </div>
    </div>
  );
}
