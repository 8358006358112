import { CodeBlock, CopyBlock, dracula } from "react-code-blocks";

export default function Block({
  copy,
  title,
  code,
  language,
  showLineNumbers,
}) {
  return (
    <div className="codeBlock m-20">
      {title && (
        <p
          className="text-left"
          style={{
            margin: "0px",
            backgroundColor: "black",
            padding: "8px",
            borderRadius: "8px 8px 0px 0px",
          }}
        >
          {title}
        </p>
      )}
      {copy ? (
        <CopyBlock
          text={code}
          language={language}
          showLineNumbers={showLineNumbers}
          theme={dracula}
          // wrapLines
          codeBlock
        />
      ) : (
        <CodeBlock
          text={code}
          language={language}
          showLineNumbers={showLineNumbers}
          theme={dracula}
          wrapLines
          codeBlock
        />
      )}
    </div>
  );
}
