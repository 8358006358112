/* eslint-disable */

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import Axios from "axios";
import cryptoRandomString from "crypto-random-string";
import React, { useEffect, useState } from "react";
import { DiAndroid } from "react-icons/di";
import { FaCopy, FaExternalLinkAlt, FaGlobe } from "react-icons/fa";
import { FcCheckmark } from "react-icons/fc";
import { ImShare2 } from "react-icons/im";
import { RiNotification3Fill } from "react-icons/ri";
import LoaderIcon from "react-loader-icon";
import { Link } from "react-router-dom";
import CreateApiClient from "../API";
import { JSON_API, authAddress, publicAddress } from "../Config";
import { getItem, updateProperties } from "../hooks/useLs";
import cavaLogo from "../images/thumbnail.jpeg";
import AppBarNew from "./AppBar";
import ChangePass from "./ChangePass";
import DownloadButton from "./partials/DownloadZip";
import FeedbackModal from "./partials/FeedbackModal";
import Footer from "./partials/Footer";
import PdfDownload from "./partials/PdfDownload";

const api = CreateApiClient();

const useStyles = makeStyles({
  table: {
    minWidth: 350,
  },
});

function BasicTable(props) {
  const classes = useStyles();
  const [error, setError] = useState("");
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [role] = useState("");
  const [password, setPassword] = useState("");
  const [domains, setDomains] = useState([]);
  const [apps, setApps] = useState([]);
  const [success, setSuccess] = useState([]);
  const [demoUsers, setDemoUsers] = useState([]);
  const [monitoredDomains, setMonitoredDomain] = useState("");
  const [addedDomain, setAddedDomain] = useState("");
  const [newApp, setNewApp] = useState("");
  const [newDomain, setNewDomain] = useState("");
  const [thirdPartyList, setThirdPartyList] = useState([]);
  const [userDomainsList, setUserDomainsList] = useState([]);
  const [userAppsList, setUserAppsList] = useState([]);
  const [loader, setloader] = useState(false);
  const [mainLoader, setMainLoader] = useState(false);
  const [copied, isCopied] = useState("");
  const [addLoader, setAddLoader] = useState(false);
  const [alertEmails, setAlertEmails] = useState([]);
  const [addedEmail, setAddedEmail] = useState("");
  const [emailsToAdd] = useState([]);
  const [alertDomains, setAlertDomains] = useState([]);
  const [qrCode, setQrCode] = useState("");
  const [secret, setSecret] = useState("");
  const [message, setMessage] = useState("");
  const [totp, setTotp] = useState();
  const [companyLogo, setCompanyLogo] = useState("");
  const [addedDomains, setAddedDomains] = useState([]);
  const [addedApps, setAddedApps] = useState([]);
  const [addedParties, setAddedParties] = useState([]);
  const [suggestedExternalDomains, setSuggestedExternalDomains] = useState([]);
  const [initExternalDomains] = useState([]);
  const [webhooks, setWebhooks] = useState([]);
  const [registerLoading, setRegisterLoading] = useState(false);
  const [censorDate] = useState(new Date());
  const [hasCensorDate] = useState(false);
  const [since] = useState("");
  const [until] = useState("");
  const [keyword] = useState("");
  const [keywordLoading, setKeywordLoading] = useState(false);
  const [keywordPage, setKeywordPage] = useState(1);
  const [keywordPageLoading, setKeywordPageLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [userCsvFiles, setUsersCsvFiles] = useState([]);
  const [userExternalDomains, setUserExternalDomains] = useState([]);
  const [apiKey, setApiKey] = useState("");
  const [reports, setReports] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [mfaLoading, setMfaLoading] = useState(false);

  const [cancelToken, setCancelToken] = useState(null);

  const searchers = ["MSSP", "Admin"];
  const selectors = ["User", "Customer"];
  const middleColRoles = ["User", "Customer", "Admin", "MSSP"];

  const config = {
    headers: {
      "auth-token": props.token,
    },
  };

  const isValidEmail = (email) => {
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
  };

  const onChangeName = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const onChangeCompany = (e) => {
    e.preventDefault();
    setCompany(e.target.value);
  };

  const onChangeEmail = (e) => {
    e.preventDefault();
    setEmail(e.target.value.toLowerCase());
  };

  const onChangePassword = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  const onChangeDomains = (e) => {
    e.preventDefault();
    if (e.target.value.includes(" ")) {
      setDomains(e.target.value.split(" ").join("").toLowerCase());
    } else {
      setDomains(e.target.value.toLowerCase());
    }
  };

  const onChangeApps = (e) => {
    e.preventDefault();
    if (e.target.value.includes(" ")) {
      setApps(e.target.value.split(" ").join("").toLowerCase());
    } else {
      setApps(e.target.value.toLowerCase());
    }
  };

  useEffect(() => {
    if (keyword && keyword.length > 1) {
      console.log("search");
      onChangeExternalDomains();
    }
  }, [keywordPage]);

  const onChangeExternalDomains = (e) => {
    e && e.preventDefault();
    if (!keyword) return;
    if (keywordPage > 1) {
      setKeywordPageLoading(true);
    } else {
      setSuggestedExternalDomains([]);
      setKeywordLoading(true);
    }

    if (cancelToken) {
      cancelToken.cancel("Canceled");
    }
    const source = Axios.CancelToken.source();
    setCancelToken(source);
    let _domains,
      _apps,
      _allDomains = [];
    _domains = domains.length > 0 && domains.split(",");
    _apps = apps.length > 0 && apps.split(",");
    _allDomains =
      _domains.length > 0 && _apps.length > 0
        ? _domains.concat(_apps)
        : _domains.length > 0
        ? _domains
        : _apps.length > 0 && _apps;
    Axios.post(
      `${JSON_API}/stats/website-results/search-by-keyword?system=${keyword}&noalert=true&page=${keywordPage}`,
      {
        domains: _allDomains,
      },
      {
        headers: { "auth-token": props.user.Token },
        cancelToken: source.token,
      }
    )
      .then((response) => {
        if (keywordPage > 1) {
          setKeywordPageLoading(false);
          setSuggestedExternalDomains([
            ...suggestedExternalDomains,
            ...response.data.data,
          ]);
        } else {
          setKeywordLoading(false);
          setSuggestedExternalDomains(response.data.data);
        }
        setLoadMore(response.data.loadMore);
      })
      .catch((err) => {
        if (keywordPage > 1) {
          setKeywordPageLoading(false);
        } else {
          setKeywordLoading(false);
        }
        console.log(err);
      });
    return () => {
      if (cancelToken) {
        cancelToken.cancel("Canceled");
      }
    };
  };

  const registerUser = async (e) => {
    setRegisterLoading(true);
    e.preventDefault();
    let alert_emails = [];
    let domainsArr = [];
    let appsArr = [];
    let externalsArr = [];
    if (typeof domains === "string") {
      domainsArr = domains.split(",");
    } else if (domains !== "") {
      domainsArr = domains;
    } else {
      domainsArr = domains;
    }
    if (typeof apps === "string") {
      appsArr = apps.split(",");
    } else if (apps !== "") {
      appsArr = apps;
    } else {
      appsArr = apps;
    }

    if (typeof initExternalDomains !== "string") {
      externalsArr = initExternalDomains.map((item) => {
        let system = item.split(".")[0];
        let domain = item.replace(`${system}.`, "");
        let obj = {
          name: item,
          system: item.split(".")[0],
          domain: domain,
        };
        return obj;
      });
    } else {
      externalsArr = initExternalDomains
        .replace(/ /g, "")
        .split(",")
        .map((item) => {
          let system = item.split(".")[0];
          let domain = item.replace(`${system}.`, "");
          let obj = {
            name: item,
            system: item.split(".")[0],
            domain: domain,
          };
          return obj;
        });
    }

    if (emailsToAdd.length > 0) {
      alert_emails = [...new Set(emailsToAdd.replace(/ /g, "").split(","))];
    }

    const api_token = cryptoRandomString({ length: 64, type: "url-safe" });
    const url_hash = cryptoRandomString({ length: 64, type: "url-safe" });

    const newUser = {
      name: name,
      email: email,
      password: password,
      company: company,
      role: props.role === "Admin" ? role : "User",
      domains: domainsArr,
      apps: appsArr,
      external_domains: externalsArr,
      api_key: api_token,
      url_hash: url_hash,
      alert_emails: alert_emails,
      censor_date: hasCensorDate ? censorDate : null,
      exposed_dates: {
        since: since,
        until: until,
      },
    };

    const config = {
      headers: {
        "auth-token": props.token,
      },
    };

    let isDemo;
    props.role === "MSSP" ? (isDemo = true) : (isDemo = false);

    const response = await api.registerUser(
      `register?demo=${isDemo}`,
      newUser,
      config
    );

    if (response.User) {
      setError("");
      setSuccess("User Successfully Added!");
      setTimeout(() => {
        props.refresh();
        setSuccess("");
      }, 1000);
    } else {
      setSuccess("");
      setError("Something Went Wrong!");
    }
    setRegisterLoading(false);
  };

  const getReports = async () => {
    let url = `${authAddress}/reports`;
    await Axios.post(url, {}, config)
      .then((res) => {
        console.log(res.data);
        setReports(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getCsvFiles = async () => {
    let url = `${authAddress}/zip-files`;
    await Axios.post(url, {}, config)
      .then((res) => {
        setUsersCsvFiles(res.data);
      })
      .catch((err) => console.log(err));
  };

  const deleteCsvFile = async (fileId) => {
    let url = `${authAddress}/download-zip-file?fileId=${fileId}`;
    await Axios.post(url, {}, config)
      .then((res) => {
        console.log("File deleted from server");
        getCsvFiles();
      })
      .catch((err) => console.log(err));
  };

  const getPresets = async (user) => {
    setMainLoader(true);
    getCsvFiles();
    getReports();
    const body = {
      email: props.email,
    };

    if (user && user.Domains && user.Domains[0]) {
      await getCompanyLogo(user.Domains[0]);
    }
    if (props.role === "MSSP" && body.email) {
      await Axios.post(`${authAddress}/demousers`, body, config)
        .then((res) => {
          setDemoUsers(res.data.demo_users);
          setAlertEmails(res.data.alert_emails);
        })
        .catch((err) => console.log(err));
    }

    await Axios.post(`${authAddress}/getalldomains`, body, config)
      .then((res) => {
        if (props.role === "User" || props.role === "Customer") {
          setUserDomainsList(res.data.domains);
          setUserAppsList(res.data.apps);
          setThirdPartyList(res.data.monitored_domains);
          setAlertEmails(res.data.alert_emails);
          setWebhooks(res.data.webhook_urls);
          setUserExternalDomains(res.data.external_domains);
        } else {
          setAlertEmails(res.data.alert_emails);
          setAlertDomains(res.data.all_domains);
          setWebhooks(res.data.webhook_urls);
        }
        setTotp(res.data.totp);
        setApiKey(res.data.api_key);
      })
      .catch((err) => console.log(err));
    setMainLoader(false);
  };

  useEffect(() => {
    const user = getItem("user");
    getPresets(user);
  }, []);

  const onAddEmail = (e) => {
    setAddedEmail(e.target.value);
  };

  const addDomain = async (type) => {
    setAddLoader(type);
    let _addedDomains;
    if (type === "Domain") {
      _addedDomains = addedDomains;
    } else if (type === "APK") {
      _addedDomains = addedApps;
    } else if (type === "3rd Party") {
      _addedDomains = addedParties;
    }

    const body = {
      email: props.email,
      domain: _addedDomains,
    };
    await Axios.post(`${authAddress}/add-domain?type=${type}`, body, config)
      .then((res) => {
        setUserDomainsList(res.data.domains);
        setUserAppsList(res.data.apps);
        setThirdPartyList(res.data.monitored_domains);
        updateProperties("user", [
          { name: "Domains", value: res.data.domains },
          { name: "apps", value: res.data.apps },
          { name: "monitored_domains", value: res.data.monitored_domains },
          { name: "all_domains", value: res.data.all_domains },
        ]);
      })
      .catch((err) => console.log(err));

    setNewDomain("");
    setNewApp("");
    setMonitoredDomain("");
    setAddedDomain("");
    setAddLoader(null);
  };

  const addAlertEmail = async () => {
    setAddLoader("email");
    if (isValidEmail(addedEmail)) {
      const body = {
        email: addedEmail,
      };
      await Axios.post(`${authAddress}/add-alert-email`, body, config).then(
        (res) => {
          console.log(res.data);
          setAlertEmails(res.data);
        }
      );
      setAddedEmail("");
    } else {
      setAddedEmail("");
    }
    setAddLoader("");
  };

  const removeDomain = async (e, type) => {
    setloader(e.target.value);
    const body = {
      domain: e.target.value,
    };
    await Axios.post(`${authAddress}/removedomain?type=${type}`, body, config)
      .then((res) => {
        setUserDomainsList(res.data.domains);
        setUserAppsList(res.data.apps);
        setThirdPartyList(res.data.monitored_domains);
        updateProperties("user", [
          { name: "Domains", value: res.data.domains },
          { name: "apps", value: res.data.apps },
          { name: "monitored_domains", value: res.data.monitored_domains },
          { name: "all_domains", value: res.data.all_domains },
        ]);
        if (props.role === "Admin") {
          setAlertDomains(res.data.all_domains);
        }
      })
      .catch((err) => console.log(err));
    setloader(null);
  };

  const removeAlertEmail = async (e, type) => {
    setloader(e.target.value);
    const body = {
      email: e.target.value,
    };
    await Axios.post(`${authAddress}/remove-alert-email`, body, config)
      .then((res) => {
        setAlertEmails(res.data);
      })
      .catch((err) => console.log(err));
    setloader(null);
  };

  const copyToClipboard = async (index, url) => {
    await navigator.clipboard.writeText(url);
    isCopied(index);
    setTimeout(() => {
      isCopied(null);
    }, 2000);
  };

  const removeUser = async (e) => {
    setloader(e.target.value);
    const body = {
      email: props.email,
      childEmail: e.target.value,
    };

    await Axios.post(`${authAddress}/remove-demo-user`, body, config)
      .then((res) => {
        setDemoUsers(res.data.demo_users);
      })
      .catch((err) => console.log(err));
    setloader(null);
  };

  const createQR = async () => {
    setMfaLoading(true);
    await Axios.post(`${authAddress}/create-2fa`, {}, config)
      .then((res) => {
        setMfaLoading(false);
        window.open(res.data, "_blank");
      })
      .catch((error) => {
        console.log(error.response.data);
        setMfaLoading(false);
      });
  };

  const checkDigits = async (digits) => {
    await Axios.post(
      `${authAddress}/add-2fa?digits=${JSON.stringify(
        digits
      )}&secret=${secret}`,
      {},
      config
    )
      .then((res) => {
        setMessage(res.data);
        if (res.data === "Verified") {
          setTotp(true);
        }
      })
      .catch((error) => {
        setMessage(error.response.data);
      });
  };

  const disableTotp = async () => {
    setMfaLoading(true);
    await Axios.post(`${authAddress}/remove-totp`, {}, config)
      .then((res) => {
        setMfaLoading(false);

        setTotp(res.data.totp);
      })
      .catch((err) => {
        console.log(err);
        setMfaLoading(false);
      });
  };

  const defaultSrc = (ev) => {
    ev.target.src = cavaLogo;
  };

  const getCompanyLogo = async (company) => {
    if (company) {
      const companies = await api.fetchSuggestions(company);
      if (companies && companies[0]) {
        const logo = companies[0].logo;
        setCompanyLogo(logo);
      }
    }
  };

  const onChangeDomain = (e, type) => {
    if (type === "domain") {
      setNewDomain(e.target.value);
      setAddedDomains([
        ...new Set(
          e.target.value
            .replace(/ /g, "")
            .replace(/www./g, "")
            .split(",")
            .filter((s) => s !== "")
        ),
      ]);
    } else if (type === "apk") {
      setNewApp(e.target.value);
      setAddedApps([
        ...new Set(
          e.target.value
            .replace(/ /g, "")
            .replace(/www./g, "")
            .split(",")
            .filter((s) => s !== "")
        ),
      ]);
    } else if (type === "3rd party") {
      setMonitoredDomain(e.target.value);
      setAddedParties([
        ...new Set(
          e.target.value
            .replace(/ /g, "")
            .replace(/www./g, "")
            .split(",")
            .filter((s) => s !== "")
        ),
      ]);
    }
  };

  return !mainLoader ? (
    <div
      className={
        searchers.includes(props.role)
          ? "account-page light-bg"
          : "account-page light-bg"
      }
    >
      <div className="upperDiv">
        <div>
          <div
            className="row"
            style={{
              borderBottom: searchers.includes(props.role)
                ? "1px solid lightgray"
                : "0px",
              justifyContent: !middleColRoles.includes(props.role) && "center",
            }}
          >
            <div className="col-md-3 border-right account-col">
              <div className="d-flex flex-column align-items-center text-center lightText">
                <img
                  className="mt-5"
                  style={{
                    border: "2px solid black",
                    borderRadius: "10px",
                    padding: "5px",
                  }}
                  width="100px"
                  src={companyLogo}
                  onError={defaultSrc}
                  alt="logo"
                />

                <h2 className={"lightText capitalize"}>{props.user.company}</h2>
                <h6 className={"lightText"}>{props.user.email}</h6>
                {props.role === "Customer" && (
                  <div style={{ width: "60%" }}>
                    <hr />
                    <FeedbackModal
                      mode="domains"
                      user={props.user}
                      open={modalOpen}
                      handleClose={() => setModalOpen(false)}
                      title={"Add Monitored Domains"}
                      text={
                        "Enter the list of domains you would like to add here:"
                      }
                      label={"Domains"}
                      thanks={
                        "We have received your message and will respond promptly"
                      }
                    />
                    <h6>To add additional domains to monitor click here:</h6>
                    <Button
                      onClick={() => setModalOpen(true)}
                      className="searchBtn spaced"
                    >
                      add domains
                    </Button>
                  </div>
                )}
                {props.role === "User" && (
                  <div className="styledBox yellow-bg">
                    <div
                      style={{
                        display: "inline-flex",
                        marginTop: "5px",
                      }}
                    >
                      <input
                        style={{ height: "40px" }}
                        value={newDomain && newDomain}
                        type="domain"
                        placeholder="New Domain"
                        margin="normal"
                        id="newDomainField"
                        label="New Domain"
                        name="domain"
                        className="form-control spaced"
                        onChange={(e) => onChangeDomain(e, "domain")}
                        maxLength={500}
                      />
                      <div>
                        {newDomain.includes(".") &&
                        !newDomain.includes(":") &&
                        !newDomain.includes("/") &&
                        /^[a-zA-Z]+$/.test(newDomain[newDomain.length - 1]) ? (
                          <button
                            onClick={() => addDomain("Domain")}
                            type="submit"
                            className="iconBtn spaced"
                          >
                            {addLoader === "Domain" ? (
                              <LoaderIcon
                                style={{
                                  margin: "auto",
                                  width: "24px",
                                  height: "24px",
                                }}
                              />
                            ) : (
                              "+"
                            )}
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className={"iconBtn grayBtn spaced"}
                          >
                            +
                          </button>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "inline-flex",
                        marginTop: "5px",
                      }}
                    >
                      <input
                        style={{ height: "40px" }}
                        value={newApp && newApp}
                        type="domain"
                        placeholder="New APK"
                        margin="normal"
                        id="newDomainField"
                        label="New Domain"
                        name="domain"
                        className="form-control spaced"
                        onChange={(e) => onChangeDomain(e, "apk")}
                        maxLength={500}
                      />
                      <div>
                        {newApp.includes(".") &&
                        !newApp.includes(":") &&
                        !newApp.includes("/") &&
                        /^[a-zA-Z]+$/.test(newApp[newApp.length - 1]) ? (
                          <button
                            onClick={() => addDomain("APK")}
                            type="submit"
                            className="iconBtn spaced"
                          >
                            {addLoader === "APK" ? (
                              <LoaderIcon
                                style={{
                                  margin: "auto",
                                  width: "24px",
                                  height: "24px",
                                }}
                              />
                            ) : (
                              "+"
                            )}
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className={"iconBtn grayBtn spaced"}
                          >
                            +
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={"styledBox dark-bg"}
                  style={{ marginTop: "2rem" }}
                >
                  <h4 className={"darkText"}>Account Security</h4>
                  <div>
                    <ChangePass />
                    {!totp ? (
                      <Button
                        className="searchBtn spaced grayBg"
                        onClick={createQR}
                        style={{ width: "180px" }}
                      >
                        Enable 2FA{" "}
                        {mfaLoading && (
                          <LoaderIcon
                            type="spin"
                            className="removeLoader black-fill"
                            style={{
                              top: "0px",
                              right: "0px",
                              margin: "0px 0px 0px 10px",
                            }}
                          />
                        )}
                      </Button>
                    ) : (
                      <Button
                        className="searchBtn spaced grayBg"
                        onClick={disableTotp}
                        style={{ width: "180px" }}
                      >
                        Disable 2FA
                        {mfaLoading && (
                          <LoaderIcon
                            type="spin"
                            className="removeLoader black-fill"
                            style={{
                              top: "0px",
                              right: "0px",
                              margin: "0px 0px 0px 10px",
                            }}
                          />
                        )}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                searchers.includes(props.role)
                  ? "col-md-5 border-right account-col"
                  : "col-md-6 border-right account-col"
              }
            >
              <div className="p-3 py-5 text-left lightText">
                <div className="justify-content-between align-items-center mb-3">
                  {props.role === "Admin" || props.role === "MSSP" ? (
                    <div
                      className="text-center registerUserDiv"
                      style={{ padding: "0rem 1rem" }}
                    >
                      <h2 className="lightText">Register New User</h2>
                      <div
                        id="registerUserForm"
                        className={classes.form}
                        style={{ padding: "0rem 3rem" }}
                      >
                        <input
                          type="text"
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="usernameField"
                          placeholder="Name"
                          name="username"
                          className="form-control"
                          onChange={onChangeName}
                          style={{
                            marginTop: "20px",
                          }}
                        />
                        <input
                          type="text"
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="companyField"
                          placeholder="Company"
                          name="company"
                          className="form-control"
                          onChange={onChangeCompany}
                        />
                        <input
                          type="email"
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="emailField"
                          placeholder="Email Address"
                          name="email"
                          className="form-control"
                          onChange={onChangeEmail}
                        />
                        <input
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          name="password"
                          placeholder="Password"
                          type="password"
                          id="passwordField"
                          className="form-control"
                          onChange={onChangePassword}
                        />
                        <input
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="domainsField"
                          placeholder="Domains (Comma Separated)"
                          name="domains"
                          className="form-control"
                          onChange={onChangeDomains}
                          value={
                            typeof domains == "string"
                              ? domains
                              : domains.map((item) => item).join(",")
                          }
                        />
                        <input
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="appsField"
                          placeholder="Apps (Comma Separated)"
                          name="apps"
                          className="form-control"
                          onChange={onChangeApps}
                          value={
                            typeof apps == "string"
                              ? apps
                              : apps.map((item) => item).join(",")
                          }
                        />

                        {success === "" && (
                          <Button
                            onClick={registerUser}
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit + " searchBtn"}
                          >
                            {registerLoading ? (
                              <LoaderIcon
                                className="addLoader"
                                style={{ margin: "0px" }}
                              />
                            ) : (
                              "Add User"
                            )}
                          </Button>
                        )}
                        <Grid container>
                          <Grid item xs>
                            <p className="error">{error}</p>
                            <p className="success">{success}</p>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  ) : (
                    props.role !== "master" && (
                      <>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow className="light-bg">
                              {selectors.includes(props.role) &&
                                userDomainsList &&
                                userDomainsList.length > 0 && (
                                  <TableCell
                                    align="center"
                                    className="lightText noBorder text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <span className="accountTh">
                                      <FaGlobe
                                        size={20}
                                        className="credsIcons accountIcons"
                                      />
                                      My <br /> Domains
                                    </span>
                                  </TableCell>
                                )}
                              {selectors.includes(props.role) &&
                                userAppsList &&
                                userAppsList.length > 0 && (
                                  <TableCell
                                    align="center"
                                    className="lightText noBorder text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <span className="accountTh">
                                      <DiAndroid
                                        size={20}
                                        className="credsIcons accountIcons"
                                      />
                                      My <br /> APK's
                                    </span>
                                  </TableCell>
                                )}
                              {selectors.includes(props.role) &&
                                thirdPartyList &&
                                thirdPartyList.length > 0 && (
                                  <TableCell
                                    align="center"
                                    className="lightText noBorder text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <span className="accountTh">
                                      <ImShare2
                                        size={20}
                                        className="credsIcons accountIcons"
                                      />
                                      3rd Party <br /> Domains
                                    </span>
                                  </TableCell>
                                )}
                              {selectors.includes(props.role) &&
                                userExternalDomains &&
                                userExternalDomains.length > 0 && (
                                  <TableCell
                                    align="center"
                                    className="lightText noBorder text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <span className="accountTh">
                                      <FaExternalLinkAlt
                                        size={20}
                                        className="credsIcons accountIcons"
                                      />
                                      External <br /> Domains
                                    </span>
                                  </TableCell>
                                )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow className="light-bg">
                              {selectors.includes(props.role) &&
                                userDomainsList &&
                                userDomainsList.length > 0 && (
                                  <TableCell
                                    align="left"
                                    className="lightText verticalTop"
                                  >
                                    {userDomainsList &&
                                    userDomainsList.length > 0 ? (
                                      <>
                                        <ul
                                          className="domainList"
                                          style={{
                                            maxHeight: "300px",
                                            overflowY: "auto",
                                          }}
                                        >
                                          {userDomainsList.map((domain) => (
                                            <li key={domain}>
                                              {!props.isDemo && (
                                                <button
                                                  className="remove"
                                                  onClick={(e) => {
                                                    let confirmed =
                                                      window.confirm(
                                                        `Are you sure you want to remove ${domain}?`
                                                      );
                                                    if (confirmed) {
                                                      removeDomain(e, "domain");
                                                    }
                                                  }}
                                                  value={domain}
                                                >
                                                  {loader === domain ? (
                                                    <LoaderIcon
                                                      className="removeLoader black-fill"
                                                      style={{
                                                        top: "0px",
                                                        right: "0px",
                                                      }}
                                                    />
                                                  ) : (
                                                    "X"
                                                  )}
                                                </button>
                                              )}
                                              {domain}
                                            </li>
                                          ))}
                                        </ul>
                                      </>
                                    ) : selectors.includes(props.role) ? (
                                      <span></span>
                                    ) : (
                                      <span>Multi</span>
                                    )}
                                  </TableCell>
                                )}
                              {selectors.includes(props.role) &&
                                userAppsList &&
                                userAppsList.length > 0 && (
                                  <TableCell
                                    align="left"
                                    className="lightText verticalTop"
                                  >
                                    {userAppsList && userAppsList.length > 0 ? (
                                      <>
                                        <ul
                                          className="domainList"
                                          style={{
                                            maxHeight: "300px",
                                            overflowY: "auto",
                                          }}
                                        >
                                          {userAppsList.map((app) => (
                                            <li key={app}>
                                              {!props.isDemo && (
                                                <button
                                                  className="remove"
                                                  onClick={(e) => {
                                                    let confirmed =
                                                      window.confirm(
                                                        `Are you sure you want to remove ${app}?`
                                                      );
                                                    if (confirmed) {
                                                      removeDomain(e, "app");
                                                    }
                                                  }}
                                                  value={app}
                                                >
                                                  {loader === app ? (
                                                    <LoaderIcon
                                                      className="removeLoader black-fill"
                                                      style={{
                                                        top: "0px",
                                                        right: "0px",
                                                      }}
                                                    />
                                                  ) : (
                                                    "X"
                                                  )}
                                                </button>
                                              )}
                                              {app}
                                            </li>
                                          ))}
                                        </ul>
                                      </>
                                    ) : selectors.includes(props.role) ? (
                                      <span></span>
                                    ) : (
                                      <span>Multi</span>
                                    )}
                                  </TableCell>
                                )}
                              {selectors.includes(props.role) &&
                                thirdPartyList &&
                                thirdPartyList.length > 0 && (
                                  <TableCell
                                    align="left"
                                    className="lightText verticalTop"
                                  >
                                    {thirdPartyList &&
                                    thirdPartyList.length > 0 ? (
                                      <>
                                        <ul
                                          className="domainList"
                                          style={{
                                            maxHeight: "300px",
                                            overflowY: "auto",
                                          }}
                                        >
                                          {thirdPartyList &&
                                            thirdPartyList.map((domain) => (
                                              <li key={domain}>
                                                {!props.isDemo && (
                                                  <button
                                                    className="remove"
                                                    onClick={(e) =>
                                                      removeDomain(
                                                        e,
                                                        "thirdParty"
                                                      )
                                                    }
                                                    value={domain}
                                                  >
                                                    {loader === domain ? (
                                                      <LoaderIcon
                                                        className="removeLoader black-fill"
                                                        style={{
                                                          top: "0px",
                                                          right: "0px",
                                                        }}
                                                      />
                                                    ) : (
                                                      "X"
                                                    )}
                                                  </button>
                                                )}
                                                {domain}
                                              </li>
                                            ))}
                                        </ul>
                                      </>
                                    ) : selectors.includes(props.role) ? (
                                      <span></span>
                                    ) : (
                                      <span>Multi</span>
                                    )}
                                  </TableCell>
                                )}
                              {selectors.includes(props.role) &&
                                userExternalDomains &&
                                userExternalDomains.length > 0 && (
                                  <TableCell
                                    align="left"
                                    className="lightText verticalTop"
                                  >
                                    {userExternalDomains &&
                                    userExternalDomains.length > 0 ? (
                                      <>
                                        <ul
                                          className="domainList"
                                          style={{
                                            maxHeight: "300px",
                                            overflowY: "auto",
                                          }}
                                        >
                                          {userExternalDomains &&
                                            userExternalDomains.map(
                                              (domain) => (
                                                <li key={domain}>{domain}</li>
                                              )
                                            )}
                                        </ul>
                                      </>
                                    ) : selectors.includes(props.role) ? (
                                      <span></span>
                                    ) : (
                                      <span>Multi</span>
                                    )}
                                  </TableCell>
                                )}
                            </TableRow>
                          </TableBody>
                        </Table>
                        <br />
                        <Link
                          to={`/search-by-domain/${userDomainsList
                            .concat(userAppsList)
                            .join("&")}`}
                        >
                          <Button className="searchBtn spaced">
                            Display Data
                          </Button>
                        </Link>
                      </>
                    )
                  )}
                </div>
                <br />
                {userCsvFiles && userCsvFiles.length > 0 ? (
                  <div
                    style={{
                      backgroundColor: props.role === "Admin" && "white",
                      textAlign: props.role === "Admin" && "center",
                      borderRadius: props.role === "Admin" && "10px",
                      padding: props.role === "Admin" && "2rem",
                    }}
                  >
                    <h4>YOUR ZIP FILES:</h4>
                    <h5>
                      Exports are limited to 300,000 results, in order to
                      retrieve results going back further, please use{" "}
                      <a
                        className="yellow"
                        href="/docs"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Hudson Rock's API
                      </a>
                    </h5>
                    {userCsvFiles
                      .filter((s) => !s.archive)
                      .map((item, index) => (
                        <div key={index} style={{ margin: "10px" }}>
                          <span style={{ margin: "0px 2px" }}>
                            {item.filename}
                          </span>
                          <DownloadButton
                            file={item.file}
                            filename={item.filename}
                            onClick={() => {
                              deleteCsvFile(item.filename);
                            }}
                            style={{
                              color: "black",
                              backgroundColor: "#FBCD4E",
                              borderRadius: "4px",
                              padding: "6px 8px",
                              marginLeft: "4px",
                            }}
                          />
                        </div>
                      ))}
                    {!reports && <hr />}
                    {userCsvFiles.filter((s) => s.archive).length > 0 && (
                      <>
                        <h5>History:</h5>
                        <table className="styled-table">
                          <thead>
                            <tr>
                              <th>File Name</th>
                              <th>Created at</th>
                              <th>Expiry</th>
                              <th>Download</th>
                            </tr>
                          </thead>
                          <tbody>
                            {userCsvFiles
                              .filter((s) => s.archive)
                              .map((item, index) => (
                                <tr key={index}>
                                  <td>{item.filename}</td>
                                  <td>{item.createdAt}</td>
                                  <td>{item.expiry}</td>
                                  <td>
                                    <DownloadButton
                                      file={item.file}
                                      filename={item.filename}
                                      style={{
                                        color: "black",
                                        backgroundColor: "#FBCD4E",
                                        borderRadius: "4px",
                                        padding: "6px 8px",
                                        marginLeft: "4px",
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </>
                    )}
                  </div>
                ) : (
                  <div>
                    <h1>ZIP FILES WILL BE SHOWN HERE</h1>
                  </div>
                )}
                <hr />
                {reports && reports.length > 0 ? (
                  <PdfDownload reports={reports} token={props.token} />
                ) : (
                  <div>
                    <h1>PDF REPORTS WILL BE SHOWN HERE</h1>
                  </div>
                )}
              </div>
            </div>
            <div
              className="col-md-3 account-col"
              style={{
                overflowY: "scroll",
                maxHeight: "110vh",
              }}
            >
              <div className="p-3 py-5 text-left">
                {apiKey && (
                  <div>
                    <h3 className="lightText">API Key</h3>
                    <div className="lightText inline-flex w-100">
                      <div
                        className="copyInputStart"
                        onClick={() => {
                          copyToClipboard(0, apiKey);
                        }}
                      >
                        <FaCopy />
                      </div>
                      <div
                        style={{
                          backgroundColor: "lightgray",
                          padding: "4px 8px",
                          borderRadius: "0px 4px 4px 0px",
                          width: "90%",
                          overflowX: "scroll",
                          textWrap: "nowrap",
                        }}
                      >
                        {copied === 0 ? "Copied!" : apiKey}
                      </div>
                    </div>
                    <br />
                    <br />
                  </div>
                )}
                {props.user.rate_limit &&
                  props.user.rateLimitEndpoints &&
                  props.user.rateLimitEndpoints.length > 0 && (
                    <div className="lightText" style={{ margin: "10px 0px" }}>
                      <h3 className="lightText">Rate Limits</h3>
                      <div
                        style={{
                          display: "grid",
                          backgroundColor: "#D3D3D3",
                          padding: "4px 20px",
                          borderRadius: "4px",
                        }}
                      >
                        <span>
                          Limit Period: {props.user.rate_limit.period}
                        </span>
                        <span>
                          Limit Expiry:{" "}
                          {props.user.rate_limit.expiry.split("T")[0]}
                        </span>
                        <span>
                          API Calls: {props.user.rate_limit.count}/
                          {props.user.rate_limit.limit}
                        </span>
                      </div>
                    </div>
                  )}
                <h3 className="lightText">Slack Notifications</h3>
                {webhooks && webhooks.length > 0 ? (
                  <h5 className="lightText" style={{ margin: "0px" }}>
                    <FcCheckmark size={35} /> Slack notifications are on
                  </h5>
                ) : (
                  <a
                    href="https://slack.com/oauth/v2/authorize?client_id=2147716558119.3810903297856&scope=incoming-webhook"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="Add to Slack"
                      height="40"
                      width="139"
                      src="https://platform.slack-edge.com/img/add_to_slack.png"
                      srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                    />
                  </a>
                )}

                <div
                  className="styledBox gray-bg lightText"
                  style={{ width: "100%", marginTop: "4rem" }}
                >
                  <h5>
                    <RiNotification3Fill
                      size={25}
                      className="credsIcons accountIcons"
                    />
                    Email Notifications
                  </h5>
                  {alertEmails && alertEmails.length > 0 ? (
                    <>
                      <ul className="domainList">
                        {alertEmails.map((email) => (
                          <li key={email} style={{ fontSize: "14px" }}>
                            {!props.isDemo && (
                              <button
                                className="remove"
                                onClick={(e) => removeAlertEmail(e, "email")}
                                value={email}
                              >
                                {loader === email ? (
                                  <LoaderIcon
                                    className="removeLoader black-fill"
                                    style={{
                                      top: "0px",
                                      right: "0px",
                                    }}
                                  />
                                ) : (
                                  "X"
                                )}
                              </button>
                            )}
                            {email}
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <span></span>
                  )}
                  <div
                    style={{
                      display: "inline-flex",
                    }}
                  >
                    <input
                      value={addedEmail && addedEmail}
                      type="text"
                      style={{
                        width: "80%",
                        margin: "0px",
                      }}
                      margin="normal"
                      required
                      fullWidth
                      id="newEmailField"
                      placeholder="New Email"
                      name="email"
                      className="form-control spaced"
                      onChange={(e) => onAddEmail(e)}
                    />
                    <button
                      onClick={addAlertEmail}
                      type="submit"
                      className="iconBtn spaced"
                    >
                      {addLoader === "email" ? (
                        <LoaderIcon
                          style={{
                            margin: "auto",
                            width: "24px",
                            height: "24px",
                          }}
                        />
                      ) : (
                        "+"
                      )}
                    </button>
                  </div>
                </div>
                {props.role === "Admin" &&
                  alertDomains &&
                  alertDomains.length > 0 && (
                    <>
                      <ul className="domainList">
                        {alertDomains.map((domain) => (
                          <li key={domain}>
                            {!props.isDemo && (
                              <button
                                className="remove"
                                onClick={(e) => removeDomain(e, "domain")}
                                value={domain}
                              >
                                {loader === domain ? (
                                  <LoaderIcon
                                    className="removeLoader white-fill"
                                    style={{
                                      top: "0px",
                                      right: "0px",
                                    }}
                                  />
                                ) : (
                                  "X"
                                )}
                              </button>
                            )}
                            {domain}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
              </div>
            </div>
          </div>

          {props.role === "MSSP" && demoUsers && (
            <div className="demoTable" style={{ margin: "0rem 5rem" }}>
              <h2 className="lightText">Demo Users:</h2>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="demoTh">
                        <p>User Email</p>
                      </TableCell>
                      <TableCell className="demoTh">
                        <p>Unique URL</p>
                      </TableCell>
                      <TableCell className="demoTh">
                        <p>Status</p>
                      </TableCell>
                      <TableCell className="demoTh">
                        <p>Expiry Date</p>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {demoUsers.map((user, index) => (
                      <TableRow key={user.url_hash}>
                        <TableCell className="demoTd">
                          {loader === user.email ? (
                            <LoaderIcon className="removeLoader" />
                          ) : (
                            <button
                              className="remove removeUser"
                              onClick={(e) => removeUser(e)}
                              value={user.email}
                            >
                              X
                            </button>
                          )}
                          <p>{user.email}</p>
                        </TableCell>
                        <TableCell className="demoTd">
                          <a
                            className="darkText"
                            href={`${publicAddress}/tokenaccess?token=${user.url_hash}`}
                            target="_blank"
                            rel="noreferrer"
                          >{`${publicAddress}/tokenaccess?token=${user.url_hash}`}</a>
                          <div className="copyUrlBtn">
                            <Button
                              onClick={() =>
                                copyToClipboard(
                                  index,
                                  `${publicAddress}/tokenaccess?token=${user.url_hash}`
                                )
                              }
                              className="searchBtn"
                            >
                              Copy
                            </Button>
                            {copied === index && (
                              <span className="success">Copied!</span>
                            )}
                          </div>
                        </TableCell>
                        <TableCell className="demoTd">
                          {user.expiry > props.now * 1000 ? (
                            <p className="activeStatus">Active</p>
                          ) : (
                            <p className="error">Expired</p>
                          )}
                        </TableCell>
                        <TableCell className="demoTd">
                          {new Date(user.expiry)
                            .toString()
                            .split(" ")
                            .slice(0, 6)
                            .join(" ")}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="load-wrapper" style={{ minHeight: "100vh" }}>
      <LoaderIcon type={"spin"} className="tabsLoader page-loader" />
    </div>
  );
}

function Account(props) {
  return (
    props.user && (
      <div className="App">
        <AppBarNew
          className="mobileHeader"
          user={props.user}
          role={props.role}
          token={props.token}
          email={props.email}
          domains={props.domains}
          handleLogOut={props.handleHomeLogOut}
        />
        <BasicTable
          refresh={props.refresh}
          now={props.now}
          email={props.email}
          domains={props.domains}
          role={props.role}
          handleLogOut={props.handleHomeLogOut}
          token={props.token}
          demoUsers={props.demoUsers}
          apps={props.apps}
          onlyDomains={props.onlyDomains}
          isDemo={props.isDemo}
          user={props.user}
        />
        <Footer />
      </div>
    )
  );
}

export default Account;
