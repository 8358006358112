import Tooltip from "@material-ui/core/Tooltip";
import * as SimpleIcons from "react-icons/si";

export default function InstalledSoftware(props) {
  const icons = [
    {
      name: /\bmicrosoft\b/i,
      icon: <SimpleIcons.SiMicrosoft />,
    },
    {
      name: /\bmicrosoft edge\b/i,
      icon: <SimpleIcons.SiMicrosoftedge />,
    },
    {
      name: /\badobe\b/i,
      icon: <SimpleIcons.SiAdobeacrobatreader />,
    },
    {
      name: /\bsteam\b/i,
      icon: <SimpleIcons.SiSteam />,
    },
    {
      name: /\bgoogle chrome\b/i,
      icon: <SimpleIcons.SiGooglechrome />,
    },
    {
      name: /\bbrave\b/i,
      icon: <SimpleIcons.SiBrave />,
    },
    {
      name: /\bepic\b/i,
      icon: <SimpleIcons.SiEpicgames />,
    },
    {
      name: /\bubisoft\b/i,
      icon: <SimpleIcons.SiUbisoft />,
    },
    {
      name: /\banydesk\b/i,
      icon: <SimpleIcons.SiAnydesk />,
    },
    {
      name: /\bnotepad\b/i,
      icon: <SimpleIcons.SiNotepadplusplus />,
    },
    {
      name: /\bgit\b/i,
      icon: <SimpleIcons.SiGithub />,
    },
    {
      name: /\bnode.js\b/i,
      icon: <SimpleIcons.SiNodedotjs />,
    },
    {
      name: /\bunity\b/i,
      icon: <SimpleIcons.SiUnity />,
    },
    {
      name: /\bnvidia\b/i,
      icon: <SimpleIcons.SiNvidia />,
    },
    {
      name: /\bvisual studio\b/i,
      icon: <SimpleIcons.SiVisualstudio />,
    },
    {
      name: /\bteamviewer\b/i,
      icon: <SimpleIcons.SiTeamviewer />,
    },
    {
      name: /\bautodesk\b/i,
      icon: <SimpleIcons.SiAutodesk />,
    },
    {
      name: /\bapple\b/i,
      icon: <SimpleIcons.SiApple />,
    },
    {
      name: /\bmysql\b/i,
      icon: <SimpleIcons.SiMysql />,
    },
    {
      name: /\bpycharm\b/i,
      icon: <SimpleIcons.SiPycharm />,
    },
    {
      name: /\bwebstorm\b/i,
      icon: <SimpleIcons.SiWebstorm />,
    },
    {
      name: /\bmcafee\b/i,
      icon: <SimpleIcons.SiMcafee />,
    },
    {
      name: /\bexpressvpn\b/i,
      icon: <SimpleIcons.SiExpressvpn />,
    },
    {
      name: /\bhp\b/i,
      icon: <SimpleIcons.SiHp />,
    },
    {
      name: /\bamd\b/i,
      icon: <SimpleIcons.SiAmd />,
    },
    {
      name: /\bskype\b/i,
      icon: <SimpleIcons.SiSkype />,
    },
    {
      name: /\bpython\b/i,
      icon: <SimpleIcons.SiPython />,
    },
    {
      name: /\bxampp\b/i,
      icon: <SimpleIcons.SiXampp />,
    },
    {
      name: /\bfilezilla\b/i,
      icon: <SimpleIcons.SiFilezilla />,
    },
    {
      name: /\bnordvpn\b/i,
      icon: <SimpleIcons.SiNordvpn />,
    },
    {
      name: /\bmozile firefox\b/i,
      icon: <SimpleIcons.SiFirefox />,
    },
    {
      name: /\bobs\b/i,
      icon: <SimpleIcons.SiObsstudio />,
    },
    {
      name: /\basus\b/i,
      icon: <SimpleIcons.SiAsus />,
    },
    {
      name: /\bepson\b/i,
      icon: <SimpleIcons.SiEpson />,
    },
    {
      name: /\bvlc\b/i,
      icon: <SimpleIcons.SiVlcmediaplayer />,
    },
    {
      name: /\bsamsung\b/i,
      icon: <SimpleIcons.SiSamsung />,
    },
    {
      name: /\b.net\b/i,
      icon: <SimpleIcons.SiDotnet />,
    },
    {
      name: /\bbing\b/i,
      icon: <SimpleIcons.SiMicrosoftbing />,
    },
    {
      name: /\byahoo\b/i,
      icon: <SimpleIcons.SiYahoo />,
    },
    {
      name: /\bintel\b/i,
      icon: <SimpleIcons.SiIntel />,
    },
    {
      name: /\bdropbox\b/i,
      icon: <SimpleIcons.SiDropbox />,
    },
  ];

  //   const nameToIcon = (name) => {
  //     let foundIcon = icons.find((i) => name.toLowerCase().includes(i.name));
  //     if (foundIcon) {
  //       foundIcon.active = true;
  //     }
  //   };

  //   useEffect(() => {
  //     props.data.map((item) => {
  //       nameToIcon(item.program);
  //     });
  //   }, []);

  return (
    <>
      {icons
        .filter((i) =>
          props.data.find((s) => s.program.toLowerCase().match(i.name))
        )
        .map((item, index) => (
          <Tooltip
            title={`${
              props.data.find((s) => s.program.toLowerCase().match(item.name))
                .program
            } - Version: ${
              props.data.find((s) => s.program.toLowerCase().match(item.name))
                .version
                ? props.data.find((s) =>
                    s.program.toLowerCase().match(item.name)
                  ).version
                : "UNKNOWN"
            }`}
          >
            <span className="yellow-chip cursor" key={index}>
              {item.icon}
            </span>
          </Tooltip>
        ))}
      {/* {props.data.map((app, index) => (
        <span
          // onClick={() => showApplication(app.name)}
          className="yellow-chip cursor"
          key={index}
        >
          {nameToIcon(app.program)}
        </span>
      ))} */}
    </>
  );
}
