import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Link, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export default function SearchBtn(props) {
  const history = useHistory();
  const classes = useStyles();

  const search = async (e) => {
    e.preventDefault();
    const domainsArr = await Promise.all(
      props.activeDomains.map((domain) => {
        return domain.value;
      })
    ).catch((err) => console.log(err));
    let queryTxt = domainsArr.join("&");
    history.push(`/search-by-domain/${queryTxt}`);
  };

  return (
    <div>
      {props.searchType && props.searchType.value === "company" ? (
        props.role === "Admin" ||
        props.role === "MSSP" ||
        props.role === "master" ? (
          props.company ? (
            <Link to={`/search-by-company/${props.company.id}`}>
              <Button
                type="submit"
                variant="contained"
                className={classes.button + " searchBtn desktopSearchBtn"}
                endIcon={<Icon>send</Icon>}
              >
                Search
              </Button>
            </Link>
          ) : (
            <Button
              type="submit"
              variant="contained"
              className={classes.button + " searchBtn grayBtn desktopSearchBtn"}
              endIcon={<Icon>send</Icon>}
            >
              Search
            </Button>
          )
        ) : (
          <Button
            type="submit"
            variant="contained"
            className={classes.button + " searchBtn grayBtn desktopSearchBtn"}
            endIcon={<Icon>send</Icon>}
          >
            Search
          </Button>
        )
      ) : props.searchType && props.searchType.value === "stealer" ? (
        props.stealer !== null && props.stealer !== "" ? (
          <Link to={`/search-by-stealer/${props.stealer}`}>
            <Button
              type="submit"
              variant="contained"
              className={classes.button + " searchBtn desktopSearchBtn"}
              endIcon={<Icon>send</Icon>}
            >
              Search
            </Button>
          </Link>
        ) : (
          <Button
            type="submit"
            variant="contained"
            className={classes.button + " searchBtn grayBtn desktopSearchBtn"}
            endIcon={<Icon>send</Icon>}
          >
            Search
          </Button>
        )
      ) : props.searchType && props.searchType.value === "email" ? (
        props.email !== null && props.email !== "" ? (
          <Link to={`/search-by-email/${props.email}`}>
            <Button
              type="submit"
              variant="contained"
              className={classes.button + " searchBtn desktopSearchBtn"}
              endIcon={<Icon>send</Icon>}
            >
              Search
            </Button>
          </Link>
        ) : (
          <Button
            type="submit"
            variant="contained"
            className={classes.button + " searchBtn grayBtn desktopSearchBtn"}
            endIcon={<Icon>send</Icon>}
          >
            Search
          </Button>
        )
      ) : props.searchType && props.searchType.value === "ip" ? (
        props.ip !== null && props.ip !== "" ? (
          <Link to={`/search-by-ip/${props.ip}`}>
            <Button
              type="submit"
              variant="contained"
              className={classes.button + " searchBtn desktopSearchBtn"}
              endIcon={<Icon>send</Icon>}
            >
              Search
            </Button>
          </Link>
        ) : (
          <Button
            type="submit"
            variant="contained"
            className={classes.button + " searchBtn grayBtn desktopSearchBtn"}
            endIcon={<Icon>send</Icon>}
          >
            Search
          </Button>
        )
      ) : props.role === "Admin" ||
        props.role === "MSSP" ||
        props.role === "master" ? (
        props.domain !== null &&
        props.domain.includes(".") &&
        !props.domain.includes(":") ? (
          <Link to={`/search-by-domain/${props.domain}`}>
            <Button
              type="submit"
              variant="contained"
              className={classes.button + " searchBtn desktopSearchBtn"}
              endIcon={<Icon>send</Icon>}
            >
              Search
            </Button>
          </Link>
        ) : (
          <Button
            type="submit"
            variant="contained"
            className={classes.button + " searchBtn grayBtn desktopSearchBtn"}
            endIcon={<Icon>send</Icon>}
          >
            Search
          </Button>
        )
      ) : props.activeDomains.length > 0 ? (
        <Button
          onClick={search}
          type="submit"
          variant="contained"
          className={classes.button + " searchBtn desktopSearchBtn"}
          endIcon={<Icon>send</Icon>}
        >
          Search
        </Button>
      ) : (
        <Button
          type="submit"
          variant="contained"
          className={classes.button + " searchBtn grayBtn desktopSearchBtn"}
          endIcon={<Icon>send</Icon>}
        >
          Search
        </Button>
      )}
    </div>
  );
}
