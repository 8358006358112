import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiFillDatabase } from "react-icons/ai";
import { BsEyeFill, BsFillArrowDownCircleFill } from "react-icons/bs";
import {
  FaCookie,
  FaCookieBite,
  FaFacebook,
  FaRegCalendarAlt,
  FaRegIdCard,
  FaShieldAlt,
  FaUserCircle,
  FaUserSecret,
  FaViruses,
} from "react-icons/fa";
import { GiFamilyTree, GiWireframeGlobe } from "react-icons/gi";
import { MdPassword, MdSchedule, MdSettingsEthernet } from "react-icons/md";
import {
  RiComputerLine,
  RiExternalLinkFill,
  RiLockPasswordFill,
  RiVirusLine,
} from "react-icons/ri";
import { VscDiffAdded } from "react-icons/vsc";
import LoaderIcon from "react-loader-icon";
import { APIFullAddress } from "../Config";
import cavaLogo from "../images/thumbnail.jpeg";
import BlurryText from "./Blur";
import FlowChart from "./partials/FlowChart";

const defaultSrc = (ev) => {
  ev.target.src = cavaLogo;
};

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

const emailRe =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export default function CustomPaginationActionsTable(props) {
  const classes = useStyles2();
  const [blur, setBlur] = useState(true);
  const [open, setOpen] = useState();
  const [stealerDataLoading, setStealerDataLoading] = useState(false);
  const [currentFirstSeen, setCurrentFirstSeen] = useState(null);
  const [currentTopLogins, setCurrentTopLogins] = useState(null);
  const [currentCookies, setCurrentCookies] = useState(null);
  const [cookiesDomain, setCookiesDomain] = useState("");
  const [blurCookies, setBlurCookies] = useState(false);

  const userRole = props.userRole;
  const config = props.config;

  useEffect(() => {
    userRole === "MSSP" || userRole === "User" ? setBlur(true) : setBlur(false);
  }, [blur, userRole]);

  const getStealerData = async (
    id,
    date_uploaded,
    date_compromised,
    index,
    selectedDomain
  ) => {
    setOpen(null);
    setStealerDataLoading(true);
    if (open !== index) {
      setOpen(index);
      await axios
        .post(
          `${APIFullAddress}/machine/get-first-added?id=${id}&date_uploaded=${date_uploaded}&date_compromised=${date_compromised}&type=${
            props.androidsCount
              ? "apks"
              : props.externals
              ? "externals"
              : "users"
          }`,
          { domains: selectedDomain },
          config
        )
        .then((res) => {
          setCurrentFirstSeen(res.data.firstSeen);
          setCurrentTopLogins(res.data.topLogins);
          setCurrentCookies(res.data.cookies);
          setBlurCookies(res.data.cookie_blur);
        })
        .catch((err) => console.log(err));
    } else {
      setOpen(null);
      setCurrentFirstSeen(null);
    }
    setStealerDataLoading(false);
  };

  const toggleCookies = (domain) => {
    if (cookiesDomain === domain) {
      setCookiesDomain("");
    } else {
      setCookiesDomain(domain);
    }
  };

  return (
    <>
      {props.data.length > 0 ? (
        <TableContainer className="tableContainer" component={Paper}>
          <Table
            className={classes.table + " credsTable"}
            aria-label="custom pagination sticky collapsible table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell className="zeroWidth" style={{ width: "5%" }} />
                <TableCell align="left" style={{ width: "13.6%" }}>
                  <FaRegIdCard size={20} className="credsIcons" />
                  Stealer ID
                </TableCell>
                <TableCell align="left" style={{ width: "13.6%" }}>
                  <GiWireframeGlobe size={20} className="credsIcons" />
                  URL
                </TableCell>
                <TableCell align="left" style={{ width: "13.6%" }}>
                  <FaUserCircle size={20} className="credsIcons" />
                  Login
                </TableCell>
                <TableCell align="left" style={{ width: "13.6%" }}>
                  <RiLockPasswordFill size={20} className="credsIcons" />
                  Password
                </TableCell>
                <TableCell align="left" style={{ width: "13.6%" }}>
                  <MdSettingsEthernet size={20} className="credsIcons" />
                  IP
                </TableCell>
                <TableCell
                  align="left"
                  className={props.sortType === "date" && "marked"}
                  style={{ width: "13.6%" }}
                >
                  <FaRegCalendarAlt size={20} className="credsIcons" />
                  Date Compromised
                </TableCell>
                <TableCell
                  align="left"
                  className={props.sortType === "date_added" && "marked"}
                  style={{ width: "13.6%" }}
                >
                  <VscDiffAdded size={20} className="credsIcons" />
                  Last time added
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.map((row, index) => (
                <React.Fragment key={row.id}>
                  <TableRow
                    className={
                      open === index
                        ? "activeRow credsRowUsers credsRow"
                        : "credsRowUsers credsRow"
                    }
                    onClick={() =>
                      getStealerData(
                        row._id,
                        row.date_added,
                        row.date,
                        index,
                        props.selectedDomain
                      )
                    }
                  >
                    <TableCell className="zeroWidth" align="center">
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() =>
                          getStealerData(
                            row._id,
                            row.date_added,
                            row.date,
                            index,
                            props.selectedDomain
                          )
                        }
                      >
                        {open === index ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </TableCell>
                    {row.blur ? (
                      <TableCell className="machineId" value={row.source}>
                        <BlurryText value={row.source} />
                      </TableCell>
                    ) : (
                      <TableCell
                        className="machineId"
                        component="th"
                        scope="row"
                        value={row.source}
                      >
                        {row.source}
                      </TableCell>
                    )}
                    <TableCell>
                      {/* {row.url.split("•••••••••••")[0]}
                      <BlurryText value="•••••••••••" />
                      {row.url.split("•••••••••••")[1]} */}
                      {row.url}
                    </TableCell>
                    {props.user.ui_permissions &&
                    !props.user.ui_permissions.includes("usernames") ? (
                      <TableCell>
                        {row.login && row.login.includes("@") ? (
                          <span>
                            <BlurryText value={row.login.split("@")[0]} />@
                            {row.login.split("@")[1]}
                          </span>
                        ) : row.login !== "Not Found" ? (
                          <BlurryText value={row.login} />
                        ) : (
                          "Not Found"
                        )}
                      </TableCell>
                    ) : (
                      <TableCell>
                        {row.login === "Not Found" ? (
                          <span
                            style={{
                              textDecoration: "underline",
                            }}
                          >
                            Suggested Logins ↓
                          </span>
                        ) : props.user?.ui_permissions?.includes(
                            "search-by-email"
                          ) && emailRe.test(row.login) ? (
                          <a
                            href={`/search-by-email/${row.login}`}
                            target="_blank"
                            style={{
                              textDecoration: "underline",
                            }}
                          >
                            {row.login} →
                          </a>
                        ) : (
                          <span>{row.login}</span>
                        )}
                      </TableCell>
                    )}

                    {row.blur_password ? (
                      <TableCell>
                        <BlurryText value={row.password} />
                        <br />
                        <span className={row.passStrength.class}>
                          {row.passStrength.value}
                        </span>
                      </TableCell>
                    ) : (
                      row.passStrength && (
                        <TableCell>
                          {row.password}
                          <br />
                          <span className={row.passStrength.class}>
                            {row.passStrength.value}
                          </span>
                        </TableCell>
                      )
                    )}
                    {row.blur && row.ip !== "Not Found" ? (
                      <TableCell>
                        <BlurryText value={row.ip} />
                      </TableCell>
                    ) : (
                      <TableCell>
                        {props.user?.ui_permissions?.includes("search-by-ip") &&
                        row.ip != "Not Found" ? (
                          <a
                            href={`/search-by-ip/${row.ip}`}
                            target="_blank"
                            style={{
                              textDecoration: "underline",
                            }}
                          >
                            {row.ip} →
                          </a>
                        ) : (
                          row.ip
                        )}
                      </TableCell>
                    )}
                    <TableCell>{row.date}</TableCell>
                    <TableCell>{row.date_added}</TableCell>
                  </TableRow>
                  <TableRow
                    style={{ backgroundColor: "#fbcd4e" }}
                    className={
                      open === index ? "activeRow expandedRow" : "expandedRow"
                    }
                  >
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={12}
                    >
                      <Collapse
                        in={open === index}
                        timeout="auto"
                        unmountOnExit
                        style={{
                          boxShadow: "inset 0 0 10px #000000",
                        }}
                      >
                        <Box
                          margin={1}
                          style={{
                            minHeight: "450px",
                            margin: "30px",
                            backgroundColor: "#fbcd4e",
                          }}
                        >
                          {stealerDataLoading ? (
                            <div
                              style={{
                                minHeight: "250px",
                                backgroundColor: "#fbcd4e",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <LoaderIcon
                                className="tableLoader"
                                type={"spin"}
                              />
                            </div>
                          ) : (
                            <div className="machine-items">
                              <h3
                                id="profileTitle"
                                className="lightText credTitle"
                              >
                                <span className="titleIcon">
                                  <FaUserSecret size={30} />
                                </span>
                                Profile
                              </h3>
                              <Table
                                size="small"
                                aria-label="more"
                                className="credsTable systemTable"
                              >
                                <TableHead>
                                  <TableRow className="credsRow credsRowYellow">
                                    <TableCell className="profileTh">
                                      <span>
                                        <RiComputerLine
                                          size={20}
                                          className="credsIcons"
                                        />
                                      </span>
                                      Computer Name
                                    </TableCell>
                                    <TableCell className="profileTh">
                                      <span>
                                        <FaViruses
                                          size={20}
                                          className="credsIcons"
                                        />
                                      </span>
                                      Stealer Family
                                    </TableCell>
                                    <TableCell className="profileTh">
                                      <span>
                                        <RiVirusLine
                                          size={20}
                                          className="credsIcons"
                                        />
                                      </span>
                                      Malware Path
                                    </TableCell>
                                    <TableCell className="profileTh">
                                      <span>
                                        <AiFillDatabase
                                          size={20}
                                          className="credsIcons"
                                        />
                                      </span>
                                      Operating System
                                    </TableCell>
                                    <TableCell className="profileTh">
                                      <span>
                                        <FaShieldAlt
                                          size={20}
                                          className="credsIcons"
                                        />
                                      </span>
                                      Anti Virus
                                    </TableCell>
                                    <TableCell className="profileTh">
                                      <span>
                                        <FaFacebook
                                          size={20}
                                          className="credsIcons"
                                        />
                                      </span>
                                      Facebook
                                    </TableCell>
                                    <TableCell className="profileTh">
                                      <span>
                                        <BsEyeFill
                                          size={20}
                                          className="credsIcons"
                                        />
                                      </span>
                                      First time added
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow className="credsRow credsTable credsRowYellow">
                                    {row.blur && row.pc !== "Not Found" ? (
                                      <TableCell>
                                        <BlurryText value={row.pc} />
                                      </TableCell>
                                    ) : (
                                      <TableCell>{row.pc}</TableCell>
                                    )}
                                    <TableCell className="capitalize">
                                      {row.stealer_family &&
                                      row.stealer_family !== ""
                                        ? row.stealer_family
                                        : "Not Found"}
                                    </TableCell>
                                    <TableCell>
                                      {row.blur ? (
                                        <span>
                                          {row.path &&
                                          row.path.includes(".") ? (
                                            <span>
                                              <BlurryText
                                                value={row.path.split(".")[0]}
                                              />
                                              .{row.path.split(".")[1]}
                                            </span>
                                          ) : (
                                            <BlurryText value={row.path} />
                                          )}
                                        </span>
                                      ) : (
                                        <>{row.path}</>
                                      )}
                                    </TableCell>
                                    <TableCell>{row.os}</TableCell>
                                    <TableCell>{row.av}</TableCell>
                                    <TableCell>
                                      {row.fb !== "Not Found" ? (
                                        <button className="cardBtn">
                                          <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={`https://facebook.com/${row.fb}`}
                                          >
                                            View Profile
                                            <RiExternalLinkFill
                                              size={20}
                                              className="smIcons"
                                            />
                                          </a>
                                        </button>
                                      ) : (
                                        <button className="cardBtn grayBtn">
                                          Not Found
                                        </button>
                                      )}
                                    </TableCell>
                                    <TableCell>{currentFirstSeen}</TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                              {currentTopLogins &&
                                currentTopLogins.length > 0 &&
                                row.login === "Not Found" && (
                                  <div
                                    style={{
                                      color: "black",
                                      display: "flex",
                                      justifyContent: "start",
                                      alignItems: "center",
                                    }}
                                  >
                                    <FaUserCircle
                                      size={20}
                                      className="credsIcons"
                                    />
                                    Suggested Logins Found{" "}
                                    <Tooltip title="No login found in this credential, here are the most common logins found on this computer.">
                                      <div
                                        style={{
                                          backgroundColor: "#222831",
                                          color: "#FBCD4E",
                                          borderRadius: "20px",
                                          width: "15px",
                                          height: "15px",
                                          cursor: "pointer",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          margin: "0px 2px",
                                        }}
                                      >
                                        ℹ
                                      </div>
                                    </Tooltip>
                                    <span>: </span>
                                    <div
                                      style={{
                                        display: "inline-flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      {currentTopLogins.map((item) => (
                                        <div
                                          style={{
                                            backgroundColor: "#222831",
                                            color: "#FBCD4E",
                                            borderRadius: "20px",
                                            padding: "4px 15px",
                                            margin: "0px 5px",
                                            display: "inline-flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          {props.user?.ui_permissions?.includes(
                                            "search-by-email"
                                          ) && emailRe.test(item) ? (
                                            <a
                                              href={`/search-by-email/${item}`}
                                              target="_blank"
                                              style={{
                                                textDecoration: "underline",
                                                color: "#FBCD4E",
                                              }}
                                            >
                                              {item} →
                                            </a>
                                          ) : (
                                            <div>{item}</div>
                                          )}
                                        </div>
                                      ))}
                                      {/* {currentTopLogins.map((item) => (
                                        <StyledBadge
                                          badgeContent={item.count}
                                          color="primary"
                                          max={99999}
                                        >
                                          <div
                                            style={{
                                              backgroundColor: "#222831",
                                              color: "#FBCD4E",
                                              borderRadius: "20px",
                                              padding: "4px 15px",
                                              margin: "0px 5px",
                                              display: "inline-flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div>{item.login}</div>
                                          </div>
                                        </StyledBadge>
                                      ))} */}
                                    </div>
                                  </div>
                                )}
                              {currentCookies && currentCookies.length > 0 && (
                                <>
                                  <hr
                                    style={{ borderTop: "2px black dashed" }}
                                  />
                                  <h3 className="lightText credTitle">
                                    <span className="titleIcon">
                                      <FaCookieBite size={30} />
                                    </span>
                                    Domains With Sensitive Cookies Found:
                                    {currentCookies.length}{" "}
                                  </h3>
                                  <Table
                                    size="small"
                                    aria-label="more"
                                    className="credsTable userCookiesTable"
                                  >
                                    <TableHead>
                                      <TableRow className="credsRow">
                                        <TableCell
                                          style={{
                                            borderRadius: "20px 0px 0px 0px",
                                          }}
                                        >
                                          <span>
                                            <GiWireframeGlobe
                                              size={20}
                                              className="credsIcons"
                                            />
                                          </span>
                                          Domain
                                        </TableCell>
                                        <TableCell>
                                          <span>
                                            <FaCookie
                                              size={20}
                                              className="credsIcons"
                                            />
                                          </span>
                                          Cookies
                                        </TableCell>
                                        <TableCell>
                                          <span>
                                            <MdSchedule
                                              size={20}
                                              className="credsIcons"
                                            />
                                          </span>
                                          Valid Until
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            width: "0px",
                                            borderRadius: "0px 20px 0px 0px",
                                          }}
                                        ></TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {currentCookies.map((item, index) => (
                                        <>
                                          <TableRow
                                            className="credsRow credsTable"
                                            key={index}
                                            style={{
                                              borderTop: "2px black dashed",
                                            }}
                                          >
                                            <TableCell
                                              style={{
                                                borderRadius:
                                                  index ===
                                                  currentCookies.length - 1
                                                    ? "0px 0px 0px 20px"
                                                    : "0px",
                                              }}
                                            >
                                              <img
                                                alt="logo"
                                                src={`https://logo.clearbit.com/${item.domain}`}
                                                style={{
                                                  width: "40px",
                                                  borderRadius: "50px",
                                                }}
                                                onError={defaultSrc}
                                              />{" "}
                                              <span>{item.domain}</span>
                                            </TableCell>
                                            <TableCell>
                                              {item.cookies.length}
                                            </TableCell>
                                            <TableCell>
                                              {item.cookies[0].expiry}
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                borderRadius:
                                                  index ===
                                                  currentCookies.length - 1
                                                    ? "0px 0px 20px 0px"
                                                    : "0px",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  backgroundColor: "#fdcd4e",
                                                  display: "flex",
                                                  borderRadius: "4px",
                                                  padding: "8px 5px",
                                                  justifyContent: "center",
                                                }}
                                                className="cursor"
                                                onClick={() =>
                                                  toggleCookies(item.domain)
                                                }
                                              >
                                                VIEW ALL
                                                <BsFillArrowDownCircleFill
                                                  size={20}
                                                  style={{ marginLeft: "5px" }}
                                                />
                                              </div>
                                            </TableCell>
                                          </TableRow>
                                          {cookiesDomain === item.domain && (
                                            <TableRow
                                              style={{
                                                backgroundColor: "#fbcd4e",
                                              }}
                                              className={
                                                cookiesDomain === item.domain
                                                  ? "activeRow expandedRow"
                                                  : "expandedRow"
                                              }
                                            >
                                              <TableCell
                                                style={{
                                                  paddingBottom: 0,
                                                  paddingTop: 0,
                                                }}
                                                colSpan={12}
                                              >
                                                <Collapse
                                                  in={
                                                    cookiesDomain ===
                                                    item.domain
                                                  }
                                                  timeout="auto"
                                                  unmountOnExit
                                                  style={{
                                                    boxShadow:
                                                      "inset 0 0 10px #000000",
                                                  }}
                                                >
                                                  <Box
                                                    margin={1}
                                                    style={{
                                                      height: "250px",
                                                      margin: "30px",
                                                      backgroundColor:
                                                        "#fbcd4e",
                                                      overflowY: "scroll",
                                                    }}
                                                  >
                                                    {" "}
                                                    <Table
                                                      size="small"
                                                      aria-label="more"
                                                      className="credsTable"
                                                    >
                                                      <TableHead>
                                                        <TableRow className="credsRow credsRowYellow">
                                                          <TableCell>
                                                            <span>
                                                              <GiWireframeGlobe
                                                                size={20}
                                                                className="credsIcons"
                                                              />
                                                            </span>
                                                            URL
                                                          </TableCell>
                                                          <TableCell>
                                                            <span>
                                                              <FaCookie
                                                                size={20}
                                                                className="credsIcons"
                                                              />
                                                            </span>
                                                            Name
                                                          </TableCell>
                                                          <TableCell>
                                                            <span>
                                                              <MdPassword
                                                                size={20}
                                                                className="credsIcons"
                                                              />
                                                            </span>
                                                            Value
                                                          </TableCell>
                                                          <TableCell>
                                                            <span>
                                                              <MdSchedule
                                                                size={20}
                                                                className="credsIcons"
                                                              />
                                                            </span>
                                                            Expiry
                                                          </TableCell>
                                                        </TableRow>
                                                      </TableHead>
                                                      <TableBody>
                                                        {item.cookies.map(
                                                          (_item, index) => (
                                                            <TableRow
                                                              className="credsRow credsTable credsRowYellow"
                                                              key={index}
                                                            >
                                                              <TableCell>
                                                                {_item.url}
                                                              </TableCell>
                                                              <TableCell>
                                                                {
                                                                  _item.cookie_name
                                                                }
                                                              </TableCell>
                                                              {blurCookies ? (
                                                                <TableCell>
                                                                  <BlurryText
                                                                    value={
                                                                      _item.value
                                                                    }
                                                                  />
                                                                </TableCell>
                                                              ) : (
                                                                <TableCell>
                                                                  {_item.value}
                                                                </TableCell>
                                                              )}
                                                              <TableCell>
                                                                {_item.expiry}
                                                              </TableCell>
                                                            </TableRow>
                                                          )
                                                        )}
                                                      </TableBody>
                                                    </Table>
                                                  </Box>
                                                </Collapse>
                                              </TableCell>
                                            </TableRow>
                                          )}
                                        </>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </>
                              )}
                            </div>
                          )}

                          {row.tree && (
                            <div
                              className="flow-chart-div"
                              style={{
                                height: "550px",
                                width: "100%",
                                color: "white",
                                textAlign: "center",
                                marginTop: "10px",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#222831",
                                  padding: "15px",
                                  borderRadius: "15px 15px 0px 0px",
                                }}
                              >
                                <GiFamilyTree
                                  size={25}
                                  className="credsIcons"
                                />

                                <h3>Virtualization</h3>
                                {row.visualize && (
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`/search-by-stealer/${row.source}`}
                                    style={{
                                      backgroundColor: "#FCCD4E",
                                      color: "#222831",
                                      fontSize: "14px",
                                      padding: "8px 16px",
                                      border: "0px",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    Analyze with AI (NEW)
                                  </a>
                                )}
                              </div>
                              <FlowChart data={row.tree} />
                            </div>
                          )}
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
          {props.loader ? (
            <LoaderIcon className="tableLoader" type={"spinningBubbles"} />
          ) : (
            <div style={{ textAlign: "center" }}>
              {props.hasNextPage ? (
                <button
                  className="cardBtn loadMore tableLoadMore"
                  onClick={props.loadMore}
                >
                  Load More
                </button>
              ) : (
                <div>
                  <h3 className="align-center">No More Results</h3>
                </div>
              )}
            </div>
          )}
        </TableContainer>
      ) : (
        <>{!props.onSearch ? "" : <h2>No Results, Try Another Domain</h2>}</>
      )}
    </>
  );
}
