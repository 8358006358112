import React, { useEffect, useState } from "react";
import Container from "../ProgressBar";
import AnalyzeStealer from "../partials/AnalyzeStealer";

const AiLoader = () => {
  return (
    <div class="spinner-box">
      <div class="leo-border-1">
        <div class="leo-core-1"></div>
      </div>
      <div class="leo-border-2">
        <div class="leo-core-2"></div>
      </div>
    </div>
  );
};

export default function AiAnalysis(props) {
  const [stage, setStage] = useState(props.stage);
  const stealerId = props.stealerId;
  const headers = props.headers;

  useEffect(() => {
    setStage(props.stage);
  }, [props.stage]);

  return (
    <div
      style={{
        color: "#FFA500",
        // backgroundColor: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "95vh",
        width: "100%",
      }}
    >
      {stage ? (
        <div style={{ display: "grid", placeItems: "center", width: "100%" }}>
          <Container
            paddingTop={"3rem"}
            classes={"mainNewLoader"}
            width={"100%"}
            customText={stage}
          />
        </div>
      ) : (
        <div style={{ display: "grid", placeItems: "center" }}>
          <AnalyzeStealer
            parent={"ai_analysis"}
            stealerId={
              props.stealerData.stealer_pure
                ? props.stealerData.stealer_pure
                : props.stealerData.stealer
            }
            headers={headers}
          />
        </div>
      )}
    </div>
  );
}
