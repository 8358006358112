import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(0),
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 90,
  },
  select: {
    "&:before": {
      borderColor: "black",
    },
    "&:after": {
      borderColor: "black",
    },
  },
  icon: {
    fill: "black",
  },
}));

export default function ControlledOpenSelect(props) {
  const classes = useStyles();

  return (
    <div>
      <FormControl variant="standard" className={classes.formControl}>
        <InputLabel
          style={{ top: "-10px" }}
          id="demo-controlled-open-select-label"
        >
          {props.label}
        </InputLabel>
        <Select
          className={classes.select + " urlBuilderSelect"}
          style={{ marginTop: "0px", width: props.width }}
          //   labelId="demo-controlled-open-select-label"
          //   id="demo-controlled-open-select"
          open={props.open}
          onClose={props.onClose}
          onOpen={props.onOpen}
          value={props.value}
          onChange={props.onChange}
        >
          {props.options.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
