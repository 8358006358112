import React from "react";
import Tree from "react-d3-tree";

export default function FlowChart(props) {
  console.log(window.innerWidth);
  return (
    // `<Tree />` will fill width/height of its container; in this case `#treeWrapper`.
    <div id="treeWrapper" style={{ width: "100%", height: "450px" }}>
      <Tree
        data={props.data}
        orientation="vertical"
        rootNodeClassName="node__root"
        branchNodeClassName="node__branch"
        leafNodeClassName="node__leaf"
        shouldCollapseNeighborNodes={true}
        enableLegacyTransitions={true}
        translate={{
          x:
            window.innerWidth <= 1440
              ? window.innerWidth / 2.15
              : window.innerWidth / 2.12,
          y: 25,
        }}
        initialDepth={1}
        dimensions={{
          height: 0,
          width: 1600,
        }}
        separation={{
          siblings: 1,
          nonSiblings: 3,
        }}
        nodeSize={{
          x: 350,
          y: 350,
        }}
      />
    </div>
  );
}
