import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { FaUser, FaUserNinja, FaUserSecret, FaUserTie } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    maxWidth: "100%",
    padding: theme.spacing(1),
  },
  paper: {
    textAlign: "center",
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    backgroundColor: "#fbcd4e",
  },
}));

const iconsByRole = [
  {
    name: "User",
    icon: <FaUser size={25} />,
  },
  {
    name: "Customer",
    icon: <FaUserTie size={25} />,
  },
  {
    name: "MSSP",
    icon: <FaUserSecret size={25} />,
  },
  {
    name: "master",
    icon: <FaUserNinja size={25} />,
  },
];

export default function LastUsersCreated(props) {
  const classes = useStyles();

  return (
    <div>
      <h4 className="cyberFont bold text-center">QUICK CLONE →</h4>
      <Grid container spacing={2} className={classes.root}>
        {props.data
          .filter((s) => s.role !== "Admin")
          .map((item, index) => (
            <Grid key={index} item xs={12} sm={3}>
              <Paper
                className={classes.paper + " grid-center lightText cursor"}
                onClick={() => props.pickUser(item.email.toLowerCase())}
              >
                {iconsByRole.find((i) => i.name === item.role).icon}
                <span className="cyberFont bold">
                  {item.role.toUpperCase()}
                </span>
                <span className="cyberFont bold">{item.email}</span>
              </Paper>
            </Grid>
          ))}
      </Grid>
    </div>
  );
}
