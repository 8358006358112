import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
// import SearchIcon from "@material-ui/icons/Search"
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 300,
    backgroundColor: "#222831",
    color: "#E69600",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: "#E69600",
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
    backgroundColor: "#E69600",
  },
}));

export default function Filter(props) {
  const classes = useStyles();

  return (
    <Paper component="form" className={classes.root} onSubmit={props.onSubmit}>
      <InputBase
        className={classes.input}
        placeholder={props.placeholder}
        inputProps={{ "aria-label": props.placeholder }}
        onChange={props.onChange}
        value={props.value}
      />
      {/* <IconButton
        type="submit"
        className={classes.iconButton}
        aria-label="search"
      >
        <SearchIcon style={{ fill: "#E69600" }} />
      </IconButton> */}
      <Divider
        id="highDivider"
        className={classes.divider}
        orientation="vertical"
        light={true}
        style={{
          height: "28px !important",
        }}
      />
      <IconButton
        className={classes.iconButton}
        aria-label="clear"
        onClick={props.clear}
      >
        <ClearIcon style={{ fill: "#E69600" }} />
      </IconButton>
    </Paper>
  );
}
