import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import LoaderIcon from "react-loader-icon";
import CreateApiClient from "../../API";
import { APIFullAddress, adminUrl } from "../../Config";
import AddExternalDomains from "./AddExternalDomains";

const api = CreateApiClient();

export default function ExternalDomains(props) {
  const [externalDomains, setExternalDomains] = useState("");
  const [panoraysReport, setPanoraysReport] = useState([]);
  const [panoraysLoading, setPanoraysLoading] = useState(false);
  const [emailList, setEmailList] = useState([]);

  const getAllEmails = async () => {
    const response = await api.adminReq(
      "POST",
      `${adminUrl}/all-emails`,
      {
        "auth-token": props.token,
      },
      {}
    );
    setEmailList(response.data);
  };

  useEffect(() => {
    getAllEmails();
  });

  const changeExternalDomains = (e) => {
    setExternalDomains(e.target.value);
  };

  const config = {
    headers: {
      "auth-token": props.token,
    },
  };

  const exportExternal = async () => {
    setPanoraysLoading(true);
    let exArr;
    if (externalDomains.includes(",")) {
      exArr = externalDomains.split(",");
    } else if (externalDomains !== "") {
      exArr = [externalDomains];
    } else {
      exArr = externalDomains;
    }

    await Axios.post(
      `${APIFullAddress}/external-domains/panorays`,
      { domains: exArr },
      config
    )
      .then((res) => {
        setPanoraysReport(res.data);
        setPanoraysLoading(false);
        click();
      })
      .catch((err) => {
        setPanoraysLoading(false);
        console.log(err);
      });
  };

  const click = () => {
    document.getElementById("csvDownload").click();
  };

  return (
    <div className="darkText">
      <h2 className="cyberFont bold text-center yellowColor">
        Welcome to the new external domains experience!
      </h2>
      <hr />
      <div style={{ width: "50%", margin: "auto" }}>
        <h2 className="darkText cyberFont bold text-center">
          External Domains Export
        </h2>
        <input
          type="text"
          variant="outlined"
          margin="normal"
          fullWidth
          id="externalDomainsField"
          placeholder="Domains (Comma Separated)"
          name="domains"
          className="form-control"
          onChange={changeExternalDomains}
        />
        <br />
        {panoraysReport.length > 0 ? (
          <div className="text-center">
            <CSVLink
              id="csvDownload"
              data={panoraysReport}
              filename={`${externalDomains}.csv`}
              className="darkText"
              target="_blank"
              rel="noreferrer"
            >
              Download file
            </CSVLink>
          </div>
        ) : (
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={"searchBtn"}
            onClick={exportExternal}
            endIcon={
              panoraysLoading ? (
                <LoaderIcon className="buttonLoader tableLoader" type="spin" />
              ) : (
                <Icon>send</Icon>
              )
            }
          >
            Export{" "}
          </Button>
        )}
        <br />
        <br />
        <h2 className="darkText cyberFont bold text-center">
          Add External Domains
        </h2>
        <AddExternalDomains usersList={emailList} user={props.user} />
      </div>
    </div>
  );
}
