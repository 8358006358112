/* eslint-disable */

import { Grid, Paper } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import React, { useEffect, useState } from "react";
import LoaderIcon from "react-loader-icon";
import Select from "react-select";
import CreateApiClient from "../../API";
import { adminUrl } from "../../Config";
import AppBarNew from "../AppBar";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    maxWidth: 1400,
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}));

const api = CreateApiClient();

export default function ApiDashboard(props) {
  const classes = useStyles();

  const [loadingUsers, setLoadingUsers] = useState(false);
  const [users, setUsers] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [user, setUser] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [censoredFields, setCensoredFields] = useState([]);
  const [uiPermissions, setUiPermissions] = useState([]);
  const [formPermissions, setFormPermissions] = useState([]);
  const [formCensorFields, setFormCensorFields] = useState([]);
  const [formUiPermissions, setFormUiPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("");
  const [success, setSuccess] = useState(false);

  let headers = {
    "auth-token": props.token,
  };

  useEffect(() => {
    fetchTable();
  }, []);

  async function fetchTable() {
    const response = await api
      .adminReq("GET", `${adminUrl}/api-table?role=master`, headers, {})
      .then((res) => res.data)
      .catch((err) => err);
    setFormPermissions(response.permissions);
    setFormCensorFields(response.censored_fields);
    setFormUiPermissions(response.ui_permissions);
  }

  function handleUserChange(e) {
    setSelectedUser(e.value);
  }

  function handleUiPermissionChange(value) {
    if (!uiPermissions.includes(value)) {
      setUiPermissions([...uiPermissions, value]);
    } else {
      setUiPermissions(uiPermissions.filter((p) => p !== value));
    }
  }

  function handlePermissionChange(value) {
    if (!permissions.includes(value)) {
      setPermissions([...permissions, value]);
    } else {
      setPermissions(permissions.filter((p) => p !== value));
    }
  }

  function handleCensoredFieldsChange(value) {
    if (!censoredFields.includes(value)) {
      setCensoredFields([...censoredFields, value]);
    } else {
      setCensoredFields(censoredFields.filter((f) => f !== value));
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    api
      .adminReq(
        "POST",
        `${adminUrl}/mamtzetza?email=${selectedUser.value}`,
        headers,
        {
          permissions: permissions,
          censored_fields: censoredFields,
          ui_permissions: uiPermissions,
        }
      )
      .then((res) => {
        setKey(res.data.api_key);
        setLoading(false);
        setSuccess(true);
        // const video = videoRef.current;
        // video.muted = false;
        // video.play();
        alert("Permissions updated!");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        alert(err);
      });
  }

  const handleInputChange = (value) => {
    setSuccess(false);
    setUserInput(value);
    if (value) {
      setLoadingUsers(true);
      api
        .adminReq(
          "GET",
          `${adminUrl}/autocomplete-users?q=${value}`,
          headers,
          {}
        )
        .then((res) => {
          setUsers(res.data);
          setLoadingUsers(false);
        })
        .catch((err) => {
          console.log(err);
          setLoadingUsers(false);
        });
    } else {
      setUsers([]);
    }
  };

  const handleSelectChange = (option) => {
    setSelectedUser(null);
    setPermissions([]);
    setCensoredFields([]);
    setKey("");

    if (option) {
      setUserInput(option.label);
      api
        .adminReq(
          "GET",
          `${adminUrl}/user-permissions?email=${option.value}`,
          headers,
          {}
        )
        .then((res) => {
          setSelectedUser(option);
          setPermissions(res.data.api_permissions);
          setCensoredFields(res.data.censored_fields);
          setUiPermissions(res.data.ui_permissions);
          setKey(res.data.api_key);
          setUser(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div
      className="App"
      style={{ backgroundColor: "white", overflowY: "hidden" }}
    >
      <AppBarNew
        className="mobileHeader"
        user={props.user}
        role={props.role}
        token={props.token}
        email={props.email}
        domains={props.domains}
        handleLogOut={props.handleHomeLogOut}
      />
      <div
        style={{
          backgroundColor: "white",
          marginTop: "5rem",
          color: "black",
          padding: "10px",
        }}
      >
        <div></div>
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Paper className={classes.paper} style={{ height: "100%" }}>
                <h2>API Permissions</h2>
                <Stack
                  direction="row"
                  sx={{
                    display: "block",
                    overflowY: "scroll",
                    // maxHeight: "7rem",
                  }}
                >
                  {formPermissions.map((item, index) => (
                    <Tooltip key={index} title={item.description}>
                      <Chip
                        className="apiChips"
                        onClick={() => handlePermissionChange(item.value)}
                        sx={{
                          backgroundColor: permissions.includes(item.value)
                            ? "#fabe44"
                            : "lightgray",
                          color: "black",
                          margin: "2px",
                          padding: "2px",
                          fontSize: "12px",
                        }}
                        label={item.label}
                        variant="filled"
                      />
                    </Tooltip>
                  ))}
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper className={classes.paper} style={{ height: "100%" }}>
                <h2>Censored Fields</h2>
                <Stack
                  direction="row"
                  sx={{
                    display: "block",
                    overflowY: "scroll",
                    // maxHeight: "7rem",
                  }}
                >
                  {formCensorFields.map((item, index) => (
                    <Chip
                      className="apiChips"
                      onClick={() => handleCensoredFieldsChange(item.value)}
                      sx={{
                        backgroundColor: censoredFields.includes(item.value)
                          ? "#fabe44"
                          : "lightgray",
                        color: "black",
                        margin: "2px",
                        padding: "2px",
                        fontSize: "12px",
                      }}
                      key={index}
                      label={item.value}
                      variant="filled"
                    />
                  ))}
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper className={classes.paper} style={{ height: "100%" }}>
                <h2>UI Permissions</h2>
                <Stack
                  direction="row"
                  sx={{
                    display: "block",
                    overflowY: "scroll",
                    // maxHeight: "7rem",
                  }}
                >
                  {formUiPermissions.map((item, index) => (
                    <Tooltip key={index} title={item.description}>
                      <Chip
                        className="apiChips"
                        onClick={() => handleUiPermissionChange(item.value)}
                        sx={{
                          backgroundColor: uiPermissions.includes(item.value)
                            ? "#fabe44"
                            : "lightgray",
                          color: "black",
                          margin: "2px",
                          padding: "2px",
                          fontSize: "12px",
                        }}
                        label={item.label}
                        variant="filled"
                      />
                    </Tooltip>
                  ))}
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper className={classes.paper} style={{ height: "100%" }}>
                <h2>User</h2>
                <Select
                  //   inputRef={inputRef}
                  className="blackTextDiv"
                  options={users}
                  //   inputValue={inputValue}
                  onInputChange={handleInputChange}
                  isLoading={loadingUsers}
                  isClearable
                  isSearchable
                  //   getOptionLabel={(option) => option.label}
                  //   getOptionValue={(option) => option.value}
                  //   value={selectedOption}
                  onChange={handleSelectChange}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#FBCD4E",
                      primary: "black",
                    },
                  })}
                />
                <br />
                {selectedUser && (
                  <p
                    style={{ "overflow-wrap": "anywhere" }}
                    className="lightText"
                  >
                    {key ? key : "User has no API key yet"}
                  </p>
                )}
                <button
                  onClick={handleSubmit}
                  style={{
                    backgroundColor: "#fbcd4e",
                    color: "black",
                    border: "0px",
                    borderRadius: "20px",
                    padding: "5px 20px",
                    width: "70%",
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span>Update Permissions </span>
                  <span>
                    {loading && (
                      <LoaderIcon
                        style={{
                          width: "16px",
                          height: "16px",
                          margin: "-10px 0px 0px 10px",
                        }}
                        type="spin"
                      />
                    )}
                  </span>
                </button>
              </Paper>
            </Grid>
          </Grid>
          {user &&
            user.rate_limit &&
            user.rateLimitEndpoints &&
            user.rateLimitEndpoints.length > 0 && (
              <div className="lightText" style={{ margin: "10px 0px" }}>
                <h3 className="lightText">Rate Limits</h3>
                <div
                  style={{
                    display: "grid",
                    backgroundColor: "#D3D3D3",
                    padding: "20px",
                    borderRadius: "4px",
                    width: "20%",
                    margin: "auto",
                  }}
                >
                  <span>Limit Period: {user.rate_limit.period}</span>
                  <span>
                    Limit Expiry: {user.rate_limit.expiry.split("T")[0]}
                  </span>
                  <span>
                    API Calls: {user.rate_limit.count}/{user.rate_limit.limit}
                  </span>
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}
