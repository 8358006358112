/* eslint-disable */

import axios from "axios";
import React, { useEffect, useState } from "react";
import { FcCheckmark } from "react-icons/fc";
import LoaderIcon from "react-loader-icon";
import { useLocation } from "react-router";
import { authAddress } from "../Config";
import logo from "../images/hrlogo_new.png";

export default function ResetPassword() {
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const token = location.pathname.split("/")[3];
  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [inputError, setInputError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    checkExpiry();
  }, []);

  const checkExpiry = async () => {
    setLoading(true);
    await axios
      .post(`${authAddress}/password-reset-expiry/${id}/${token}`)
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        setError(err.response.data);
        console.log(err);
      });
    setLoading(false);
  };

  const onChangeConfirmPass = (e) => {
    setInputError("");
    e.preventDefault();
    setConfirmPassword(e.target.value);
  };

  const onChangeNewPass = (e) => {
    setInputError("");
    e.preventDefault();
    setNewPassword(e.target.value);
  };

  const reset = async () => {
    setLoader(true);
    setInputError("");
    const strongRegex = new RegExp(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?\W).{8,}$/
    );

    if (newPassword === confirmPassword) {
      const data = {
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      };

      if (strongRegex.test(newPassword)) {
        const response = await axios.post(
          `${authAddress}/password-reset/${id}/${token}`,
          data
        );
        response.data === "OK" ? setSuccess(true) : setSuccess(false);
      } else {
        setInputError(
          "Password must be at least 8 character long and contain at least one special character, one lowercase character, one uppercase character, and one number."
        );
      }
    } else {
      setInputError("Passwords Do Not Match");
    }
    setLoader(false);
  };

  return (
    <div className="App">
      <img
        src={logo}
        width="220px"
        style={{ position: "absolute", left: "10px", top: "10px" }}
      />
      {loading ? (
        <LoaderIcon
          type="spin"
          style={{
            height: "80px",
            width: "80px",
            margin: "300px auto",
            fill: "white",
          }}
        />
      ) : (
        <div
          className="styledBox yellow-bg"
          style={{
            margin: "auto",
            width: "50%",
            position: "relative",
            top: "20%",
            height: "400px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {error ? (
            <div>
              <h2 className="lightText">{error}</h2>
              <a
                href="/"
                className="dark-bg no-border padding-btn radius-5 darkText"
              >
                Reset Again
              </a>
            </div>
          ) : (
            <>
              {user && (
                <div style={{ margin: "auto" }}>
                  {!success ? (
                    <>
                      <h2 className="lightText">
                        Hello {user.email}, <br />
                      </h2>
                      <h4 className="lightText">Choose Your New Password</h4>
                      <input
                        type="password"
                        placeholder="New Password"
                        className="no-border padding-btn radius-5 margin-5"
                        onChange={onChangeNewPass}
                        value={newPassword}
                      />
                      <br />
                      <input
                        type="password"
                        placeholder="Repeat New Password"
                        className="no-border padding-btn radius-5 margin-5"
                        onChange={onChangeConfirmPass}
                        value={confirmPassword}
                      />
                      <br />
                      <button
                        onClick={reset}
                        className="dark-bg no-border padding-btn radius-5 darkText margin-5 inline-flex"
                      >
                        Submit
                        {loader && (
                          <span>
                            <LoaderIcon
                              type="spin"
                              className="tabsLoader"
                              style={{
                                height: "16px",
                                width: "16px",
                                marginLeft: "5px",
                              }}
                            />
                          </span>
                        )}
                      </button>
                      <p className="error">{inputError}</p>
                    </>
                  ) : (
                    <>
                      <FcCheckmark size={35} />
                      <h4 className="lightText">
                        Your password has been successfully changed
                      </h4>
                      <a
                        href="/"
                        className="dark-bg no-border padding-btn radius-5 darkText"
                      >
                        Login
                      </a>
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}
