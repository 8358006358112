/* eslint-disable */

import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import React, { useEffect, useState } from "react";
import { FaCopy, FaFileCsv } from "react-icons/fa";
import LoaderIcon from "react-loader-icon";
import { publicAddress } from "../../Config";
import CompanyDataTable from "../partials/CompanyDataTable";
import DomainsByKeyword from "../partials/DomainsByKeyword";
import SearchForm from "../partials/SearchForm";
import CensorDates from "./CensorDates";
import UserPermissions from "./UserPermissions";

const blacklistDomains = [
  "google.com",
  "facebook.com",
  "live.com",
  "instagram.com",
  "netflix.com",
  "discord.com",
  "roblox.com",
  "twitter.com",
  "com.facebook.katana",
  "steampowered.com",
  "paypal.com",
  "twitch.tv",
  "mega.nz",
  "epicgames.com",
  "riotgames.com",
  "steamcommunity.com",
  "com.netflix.mediaclient",
  "com.instagram.android",
  "linkedin.com",
  "spotify.com",
  "com.spotify.music",
  "yahoo.com",
  "com.discord",
  "com.roblox.client",
  "tv.twitch.android.app",
  "rockstargames.com",
  "discordapp.com",
  "minecraft.net",
  "tlauncher.org",
  "leagueoflegends.com",
  "aliexpress.com",
  "com.snapchat.android",
  "com.twitter.android",
];

export default function UserData(props) {
  const [domains, setDomains] = useState([]);
  const [domainsInput, setDomainsInput] = useState("");

  const [apks, setApks] = useState([]);
  const [apksInput, setApksInput] = useState("");

  const [externals, setExternals] = useState([]);
  const [externalsInput, setExternalsInput] = useState("");

  const [alertEmails, setAlertEmails] = useState([props.email]);
  const [alertEmailsInpuit, setAlertEmailsInput] = useState("");

  const [copied, isCopied] = useState("");

  const [permissions, setPermissions] = useState({});

  const addAlertEmails = () => {
    let _domains = alertEmailsInpuit.replace(/ /, "").split(",");
    let filtered = _domains.filter((d) => !alertEmails.includes(d));
    setAlertEmails([...alertEmails, ...filtered]);
    setAlertEmailsInput("");
  };

  const removeAlertEmail = (_domain) => {
    let filtered = alertEmails.filter((d) => d !== _domain);
    setAlertEmails(filtered);
  };

  const addDomains = () => {
    let _domains = domainsInput.replace(/ /, "").split(",");
    let filtered = _domains.filter((d) => !domains.includes(d));
    setDomains([...domains, ...filtered]);
    setDomainsInput("");
  };

  const removeDomain = (_domain) => {
    let filtered = domains.filter((d) => d !== _domain);
    setDomains(filtered);
  };

  const addApks = () => {
    let _apks = apksInput.replace(/ /, "").split(",");
    let filtered = _apks.filter((d) => !apks.includes(d));
    setApks([...apks, ...filtered]);
    setApksInput("");
  };

  const removeApk = (_apk) => {
    let filtered = apks.filter((a) => a !== _apk);
    setApks(filtered);
  };

  const addExternalDomains = () => {
    let _domains = externalsInput.replace(/ /, "").split(",");
    let filtered = _domains.filter((d) => !externals.includes(d));
    setExternals([...externals, ...filtered]);
    setExternalsInput("");
  };

  const removeExternalDomain = (_domain) => {
    let filtered = externals.filter((d) => d !== _domain);
    setExternals(filtered);
  };

  const searchersData = [
    {
      name: "Alert Emails",
      field: "alert_emails",
      list: alertEmails,
      removeFunc: (email) => removeAlertEmail(email),
      addFunc: () => addAlertEmails(),
      inputValue: alertEmailsInpuit,
      onChange: (e) => setAlertEmailsInput(e.target.value),
    },
  ];

  const selectorsData = [
    {
      name: "Domains",
      field: "domains",
      list: domains,
      removeFunc: (domain) => removeDomain(domain),
      addFunc: () => addDomains(),
      inputValue: domainsInput,
      onChange: (e) => setDomainsInput(e.target.value),
    },
    {
      name: "APK's",
      field: "apks",
      list: apks,
      removeFunc: (domain) => removeApk(domain),
      addFunc: () => addApks(),
      inputValue: apksInput,
      onChange: (e) => setApksInput(e.target.value),
    },
    {
      name: "External Domains",
      field: "external_domains",
      list: externals,
      removeFunc: (domain) => removeExternalDomain(domain),
      addFunc: () => addExternalDomains(),
      inputValue: externalsInput,
      onChange: (e) => setExternalsInput(e.target.value),
    },
  ];

  const addDomain = (item, type) => {
    if (type === "domain") {
      setDomains([...domains, item]);
    } else if (type === "apk") {
      setApks([...apks, item]);
    } else if (type === "external") {
      setExternals([...externals, `${props.keyword}.${item}`]);
    }
  };

  const autoPopulateDomains = (mode) => {
    window.location.href = "#userData";
    let validDomains = props.suggestedDomains.filter(
      (s) => !blacklistDomains.includes(s.domain)
    );
    const _apks = validDomains.filter((s) => s.android).map((s) => s.domain);
    const _domains = validDomains
      .filter((s) => !_apks.includes(s.domain) && s.type === "primary")
      .map((s) => s.domain);
    const _externals = validDomains
      .filter((s) => !_apks.concat(_domains).includes(s.domain))
      .map(
        (s) => `${props.keyword ? props.keyword : props.company}.${s.domain}`
      );

    if (mode === "export") {
      let data = _apks.concat(_domains).concat(_externals);
      data = data.map((item) => {
        let type;
        if (_apks.includes(item)) {
          type = "APK";
        } else if (_domains.includes(item)) {
          type = "Primary Domain";
        } else {
          type = "External Domain";
        }

        let obj = {
          domain: item,
          type: type,
        };
        return obj;
      });
      JSONtoCSV(data);
      return;
    }

    setDomains([...domains, ..._domains]);
    setApks([...apks, ..._apks]);
    setExternals([...externals, ..._externals]);

    // if (props.mode === "edit") {
    //   setDomains([...domains, ..._domains]);
    //   setApks([...apks, ..._apks]);
    //   setExternals([...externals, ..._externals]);
    // } else {
    //   setDomains(_domains);
    //   setApks(_apks);
    //   setExternals(_externals);
    // }
  };

  function JSONtoCSV(jsonArray) {
    const csvRows = [];

    // Extracting the headers
    const headers = Object.keys(jsonArray[0]);
    csvRows.push(headers.join(","));

    // Extracting the data
    jsonArray.forEach((item) => {
      const values = headers.map((header) => {
        const escapedValue = item[header].toString().replace(/"/g, '\\"');
        return `"${escapedValue}"`;
      });
      csvRows.push(values.join(","));
    });

    // Joining rows into a single CSV string
    const csvString = csvRows.join("\n");

    // Creating a data URI for downloading
    const csvDataURI = `data:text/csv;charset=utf-8,${encodeURIComponent(
      csvString
    )}`;

    // Creating a link element for downloading
    const downloadLink = document.createElement("a");
    downloadLink.href = csvDataURI;
    downloadLink.download = "data.csv";
    downloadLink.click();
  }

  const copyToClipboard = async (value, domains) => {
    await navigator.clipboard.writeText(value);
    if (domains) {
      isCopied("domains");
      autoPopulateDomains("export");
    } else {
      isCopied(value);
    }
    setTimeout(() => {
      isCopied(null);
    }, 2000);
  };

  const updatePermissions = (permissions, censoredFields, uiPermissions) => {
    setPermissions({ permissions, censoredFields, uiPermissions });
  };

  useEffect(() => {
    const listLimit = 120;
    if (props.role !== "Admin") {
      if (
        domains.length > listLimit ||
        apks.length > listLimit ||
        externals.length > listLimit
      ) {
        window.alert("Domain list is limited to 120 domains.");
      }

      if (domains.length > listLimit) {
        let sliced = domains.slice(0, listLimit);
        setDomains(sliced);
      }

      if (apks.length > listLimit) {
        let sliced = apks.slice(0, listLimit);
        setApks(sliced);
      }

      if (externals.length > listLimit) {
        let sliced = externals.slice(0, listLimit);
        setExternals(sliced);
      }
    }
  }, [domains, apks, externals]);

  useEffect(() => {
    if (props.saved) {
      setDomains([]);
      setApks([]);
      setExternals([]);
      setAlertEmails([]);
      setDomainsInput("");
      setApksInput("");
      setExternalsInput("");
      setAlertEmailsInput("");
    }
  }, [props.saved]);

  useEffect(() => {
    setAlertEmails([props.email]);
  }, [props.email]);

  const updateDomainsFromBox = (name, type) => {
    const domainTypes = [
      {
        name: "domain",
        func: (value) => setDomains(value),
        list: domains,
      },
      {
        name: "apk",
        func: (value) => setApks(value),
        list: apks,
      },
      {
        name: "external",
        func: (value) => setExternals(value),
        list: externals,
      },
    ];

    const target = domainTypes.find((s) => s.name === type);
    if (type === "external") {
      name = `${props.keyword}.${name}`;
    }
    if (typeof target.list !== "string") {
      if (target.list.includes(name)) {
        let filterted = target.list.filter((e) => e !== name);
        target.func(filterted);
      } else {
        if (target.list) {
          target.func([...target.list, name]);
        } else {
          target.func([name]);
        }
      }
    } else {
      if (target.list.replace(/ /, "").split(",").includes(name)) {
        let filterted = target.list.filter((e) => e !== name);
        target.func(filterted);
      } else {
        if (target.list) {
          target.func([...target.list.replace(/ /, "").split(","), name]);
        } else {
          target.func([name]);
        }
      }
    }
  };

  const removeAddedDomain = (item) => {
    const foundDomain = domains.find((d) => d === item);
    const foundApk = apks.find((d) => d === item);
    const foundExternal = externals.find(
      (d) => d === `${props.keyword}.${item}`
    );

    let type;
    if (foundDomain) {
      type = "domain";
    }

    if (foundApk) {
      type = "apk";
    }

    if (foundExternal) {
      type = "external";
    }
    updateDomainsFromBox(item, type);
  };

  return (
    <div className="text-center">
      {props.selectors.includes(props.role) && (
        <div className="companyDomainsBox">
          <SearchForm
            onChange={(e) => props.setKeyword(e.target.value)}
            onSubmit={props.searchKeyword}
            value={props.keyword}
            placeholder="Enter Keyword"
            formStyle={{
              backgroundColor: "#E7F0FE",
              color: "#222831",
              borderColor: "#222831",
              margin: "5px 2px",
              borderRadius: "20px 0px 0px 20px",
            }}
            btnStyle={{
              backgroundColor: "#FECD4E",
              color: "#222831",
              borderRadius: "0px 20px 20px 0px",
            }}
          />
          {props.keywordLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflowY: "scroll",
                minHeight: "26rem",
                maxHeight: "26rem",
                backgroundColor: "#222831",
                borderRadius: "10px",
                padding: "1rem 0rem",
                marginBottom: "10px",
              }}
            >
              <LoaderIcon type={"spokes"} className="yellowLoader" />
            </div>
          ) : (
            <>
              <DomainsByKeyword
                urlsLoading={props.urlsLoading}
                lazyLoad={true}
                addExternalDomain={(item, type) => addDomain(item, type)}
                removeDomain={(domain) => removeAddedDomain(domain)}
                getDomainUrls={(domain) => props.getDomainUrls(domain)}
                keywordLoading={props.keywordLoading}
                addedDomains={domains.concat(apks).concat(externals)}
                activeUrlList={props.activeUrlList}
                setActiveUrlList={props.setActiveUrlList}
                keywordDomains={props.suggestedDomains}
                loadMore={props.loadMore}
                keywordPageLoading={props.keywordPageLoading}
                page={props.keywordPage}
                setPage={props.setKeywordPage}
                tooltip={true}
                keywordData={props.keywordData}
              />

              {props.suggestedDomains && props.suggestedDomains.length > 0 && (
                <div style={{ margin: "20px 0px" }}>
                  <div>
                    {copied === "domains" ? (
                      "Exported!"
                    ) : (
                      <div
                        className="yellowBG inline-flex justify-center align-items-center"
                        style={{
                          borderRadius: "50px",
                          width: "50px",
                          height: "50px",
                          color: "black",
                        }}
                      >
                        <FaFileCsv
                          size={30}
                          onClick={() =>
                            copyToClipboard(props.suggestedDomains, true)
                          }
                          className="cursor"
                        />
                      </div>
                    )}
                  </div>
                  <h5 style={{ margin: "10px", color: "white" }}>OR</h5>
                  <Button
                    variant="outlined"
                    onClick={autoPopulateDomains}
                    style={{ color: "white", borderColor: "white" }}
                  >
                    AUTO POPULATE
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      )}
      <div
        id="userData"
        className="yellowBG userDataBox"
        style={{ width: props.selectors.includes(props.role) ? "70%" : "50%" }}
      >
        <CompanyDataTable
          view={"admin"}
          data={
            props.selectors.includes(props.role) ? selectorsData : searchersData
          }
        />
        {props.selectors.includes(props.role) && (
          <CompanyDataTable data={searchersData} view={"admin"} />
        )}
      </div>
      <div className="userPermissionsBox">
        <div className="keys">
          <div className="inline-flex w-100 space-between">
            <div>
              <span>API KEY: </span>
              <span>{props.apiKey}</span>
            </div>
            <div>
              {copied === props.apiKey ? (
                "Copied!"
              ) : (
                <FaCopy
                  onClick={() => copyToClipboard(props.apiKey)}
                  className="cursor"
                />
              )}
            </div>
          </div>
          <div className="inline-flex w-100 space-between">
            <div>
              <span>LOGIN URL: </span>
              <span>{`${publicAddress}/tokenaccess?token=${props.urlHash}`}</span>
            </div>
            <div>
              {copied ===
              `${publicAddress}/tokenaccess?token=${props.urlHash}` ? (
                "Copied!"
              ) : (
                <FaCopy
                  onClick={() =>
                    copyToClipboard(
                      `${publicAddress}/tokenaccess?token=${props.urlHash}`
                    )
                  }
                  className="cursor"
                />
              )}
            </div>
          </div>
        </div>
        <UserPermissions
          saved={props.saved}
          role={props.role}
          token={props.token}
          updatePermissions={(permissions, censoredFields, uiPermissions) =>
            updatePermissions(permissions, censoredFields, uiPermissions)
          }
        />
        <div className="userCensorDates">
          <h4 className="text-center">UI Censorship</h4>
          <CensorDates
            hasCensorDate={props.hasCensorDate}
            setHasCensorDate={props.setHasCensorDate}
            censorDate={props.censorDate}
            setCensorDate={props.setCensorDate}
            since={props.since}
            setSince={props.setSince}
            until={props.until}
            setUntil={props.setUntil}
            onChangeCensorDate={props.onChangeCensorDate}
          />
        </div>
      </div>
      <Button
        style={{
          width: "70%",
          margin: "10px auto",
          backgroundColor: "#fbcd4e",
          color: "black",
        }}
        variant="contained"
        color="primary"
        className=""
        type="button"
        onClick={() =>
          props.saveUser(domains, apks, externals, alertEmails, permissions)
        }
        endIcon={
          props.loading ? (
            <LoaderIcon className="buttonLoader tableLoader" type="spin" />
          ) : props.saved ? (
            <Icon>done</Icon>
          ) : (
            <Icon>send</Icon>
          )
        }
      >
        SAVE USER
      </Button>
      {props.error && <p className="error">{props.error}</p>}
    </div>
  );
}
