/* eslint-disable */

import React, { useEffect, useRef, useState } from "react";
import { GiArtificialIntelligence, GiCheckMark } from "react-icons/gi";
import CreateApiClient from "../../API";
import { APIFullAddress } from "../../Config";
import "../../css/AiLoading.css";

import { AiOutlineClose, AiOutlineCopy } from "react-icons/ai";
import { FaAddressBook, FaUserSecret } from "react-icons/fa";
import { HiIdentification } from "react-icons/hi2";
import { MdOutlinePendingActions, MdSettings } from "react-icons/md";
import { PiWarningFill } from "react-icons/pi";
import { RiNavigationLine } from "react-icons/ri";
const api = CreateApiClient();

const AiLoader = () => {
  return (
    <div class="spinner-box">
      <div class="leo-border-1">
        <div class="leo-core-1"></div>
      </div>
      <div class="leo-border-2">
        <div class="leo-core-2"></div>
      </div>
    </div>
  );
};

export function ParsedText({ message }) {
  // Function to process the message and return an array of text and code blocks
  const processMessage = (message) => {
    // Splitting the message by lines
    const lines = message.split("\n");
    const processed = [];
    let codeBlock = null;

    lines.forEach((line) => {
      if (line.startsWith("```")) {
        if (codeBlock !== null) {
          // End of a code block
          processed.push({ type: "code", content: codeBlock });
          codeBlock = null;
        } else {
          // Start of a code block
          codeBlock = "";
        }
      } else if (codeBlock !== null) {
        // Inside a code block, accumulate the line
        codeBlock += line + "\n";
      } else {
        // Plain text line
        processed.push({ type: "text", content: line });
      }
    });

    // If a code block is open when the message ends, push it as a code block
    if (codeBlock !== null) {
      processed.push({ type: "code", content: codeBlock });
    }

    return processed;
  };

  // Process the message to separate text and code blocks
  const contentBlocks = processMessage(message);

  return (
    <div>
      {contentBlocks.map((block, index) => {
        if (block.type === "code") {
          // Render code block with a styled <pre> element
          return (
            <pre key={index} className="code-block">
              {block.content}
            </pre>
          );
        } else {
          return block.content.split("\n").map((line, lineIndex) => (
            <React.Fragment key={`${index}-${lineIndex}`}>
              <span className="typewriter">{line}</span>
              <br />
            </React.Fragment>
          ));
        }
      })}
    </div>
  );
}

export default function AnalyzeStealer(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [analysis, setAnalysis] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [height, setHeight] = useState(null);
  const [copied, isCopied] = useState(false);
  const [msg, setMsg] = useState("");

  const containerRef = useRef(null);
  const messagesEndRef = useRef(null);
  const readerRef = useRef(null);

  const handleCopy = async (data) => {
    const txt = data.map((item) => `${item.q}\n${item.a}\n`);
    await navigator.clipboard.writeText(txt);
    isCopied(true);
    setTimeout(() => {
      isCopied(null);
    }, 2000);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const toggleExpand = () => {
    setData([]);
    checkAnalysis();
    setIsExpanded((prev) => !prev);
  };

  const handleClose = () => {
    if (readerRef.current) {
      readerRef.current
        .cancel()
        .then(() => {
          console.log("Stream canceled successfully");
        })
        .catch((err) => {
          console.error("Failed to cancel the stream", err);
        });
    }

    setData([]);
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (containerRef.current) {
      setHeight(70);
    }
  }, [isExpanded]);

  const stealerId = props.stealerId;
  const headers = props.headers;

  const getAiAnalysis = async () => {
    await api.fetchData(`stealers/ai-analysis`, { id: stealerId }, { headers });
  };

  useEffect(() => {
    getAiAnalysis();
  }, []);

  const checkAnalysis = async (initial) => {
    let url = initial
      ? `${APIFullAddress}/stealers/check-initial-analysis`
      : `${APIFullAddress}/stealers/check-analysis`;

    if (readerRef.current) {
      await readerRef.current.cancel();
      readerRef.current = null;
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ id: stealerId }),
      });

      if (initial && response) {
        const jsonResponse = await response.json();
        if (jsonResponse && jsonResponse.ai_analysis.length) {
          setAnalysis(jsonResponse.ai_analysis);
          setData(jsonResponse.ai_analysis);
          setLoading(false);
          if (props.parent === "ai_analysis") {
            toggleExpand();
          }
        }
        return;
      }

      const reader = response.body.getReader();
      readerRef.current = reader;
      let finalData = [];

      const read = () => {
        reader
          .read()
          .then(async ({ done, value }) => {
            if (done) {
              console.log("Stream completed");
              readerRef.current = null;
              return;
            }

            const text = new TextDecoder().decode(value);
            try {
              let question = JSON.parse(text);
              if (typeof question === "object") {
                question = question.q;
                finalData.push({ q: question, a: "" });
                setData((prevData) => [...prevData, { q: question, a: "" }]);
              } else {
                finalData[finalData.length - 1].a += text;

                setData((prevData) => {
                  let questionCopy = [...prevData];
                  const lastQuestion = questionCopy[questionCopy.length - 1];

                  if (lastQuestion) {
                    questionCopy[questionCopy.length - 1] = {
                      ...lastQuestion,
                      a: lastQuestion.a + text,
                    };
                  }
                  return questionCopy;
                });
              }
            } catch (err) {
              finalData[finalData.length - 1].a += text;

              setData((prevData) => {
                let questionCopy = [...prevData];
                const lastQuestion = questionCopy[questionCopy.length - 1];

                if (lastQuestion) {
                  questionCopy[questionCopy.length - 1] = {
                    ...lastQuestion,
                    a: lastQuestion.a + text,
                  };
                }
                return questionCopy;
              });
            }
            // scrollToBottom();
            read();
          })
          .catch((err) => {
            console.error("Error reading from stream:", err);
            setLoading(false);
          });
      };

      read();
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    let intervalId;
    if (loading) {
      intervalId = setInterval(() => checkAnalysis(true), 2000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [loading]);

  useEffect(() => {
    let timeoutId;

    if (loading) {
      timeoutId = setTimeout(() => {
        setMsg(
          "This might take a few minutes, feel free to leave this page, you will receive an email once ready."
        );
      }, 5000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [loading]);

  const icons = [
    {
      label: "First and last name",
      icon: (
        <HiIdentification
          size={25}
          fill="#FFA500"
          style={{ marginRight: "5px" }}
        />
      ),
    },
    {
      label: "Home address and phone number",
      icon: (
        <FaAddressBook
          size={25}
          fill="#FFA500"
          style={{ marginRight: "5px" }}
        />
      ),
    },
    {
      label: "Online behavior profiling",
      icon: (
        <RiNavigationLine
          size={25}
          fill="#FFA500"
          style={{ marginRight: "5px" }}
        />
      ),
    },
    {
      label: "Top 3 risks identified",
      icon: (
        <PiWarningFill
          size={25}
          fill="#FFA500"
          style={{ marginRight: "5px" }}
        />
      ),
    },
    {
      label: "Hacker use cases",
      icon: (
        <FaUserSecret size={25} fill="#FFA500" style={{ marginRight: "5px" }} />
      ),
    },
    {
      label: "Browsing habits, interests and preferences",
      icon: (
        <MdSettings size={25} fill="#FFA500" style={{ marginRight: "5px" }} />
      ),
    },
    {
      label: "Steps should be taken",
      icon: (
        <MdOutlinePendingActions
          size={25}
          fill="#FFA500"
          style={{ marginRight: "5px" }}
        />
      ),
    },
  ];

  const styles =
    props.parent === "file_explorer"
      ? {
          height: isExpanded ? `${height}vh` : loading ? "185px" : "140px",
          backgroundColor: loading ? "#000000cc" : "#000000cc",
          color: "#FFA500",
          borderTop: "2px solid #FFA500",
          borderBottom: "0px",
          borderRight: "2px solid #FFA500",
          borderLeft: "2px solid #FFA500",
        }
      : {
          height: "95vh",
          overflowY: "scroll",
          backgroundColor: "#000000cc",
          color: "#FFA500",
          // borderTop: "2px solid #FFA500",
          borderBottom: "0px",
          borderRight: "2px solid #FFA500",
          borderLeft: "2px solid #FFA500",
          width: "100%",
          borderRadius: "0px",
          paddingTop: "2rem",
        };

  return (
    <div id="stealerAnalysisBox">
      <div
        ref={containerRef}
        className={!isExpanded ? "grid-center" : "expanded"}
        style={styles}
      >
        {loading ? (
          <>
            <AiLoader />
            <h6>AI Analysis Running (BETA)...</h6>
            <h6 style={{ margin: "0px" }}>{msg}</h6>
          </>
        ) : !isExpanded ? (
          <>
            <GiArtificialIntelligence size={50} fill="#FFA500" />
            <button
              style={{
                border: "0px",
                borderRadius: "30px 30px 0px 0px",
                position: "relative",
                top: "10px",
                padding: "1rem",
                backgroundColor: "#FFA500",
              }}
              onClick={toggleExpand}
            >
              Click here to view AI analysis
            </button>
          </>
        ) : (
          <div style={{ textAlign: "left" }}>
            <div
              style={{
                position: "absolute",
                top: "0px",
                right: "0px",
                padding:
                  props.parent === "file_explorer" ? "20px" : "4rem 0 0 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "0px 0px 0px 50px",
                // backgroundColor: "#222831",
                // height: "80px",
                // width: "60px",
              }}
            >
              {copied ? (
                <GiCheckMark size={25} />
              ) : (
                <AiOutlineCopy
                  size={25}
                  className="custom-cursor-hover"
                  onClick={() => handleCopy(analysis)}
                  style={{ marginRight: 10 }}
                />
              )}
              {props.parent === "file_explorer" && (
                <AiOutlineClose
                  size={25}
                  className="custom-cursor-hover"
                  onClick={() => handleClose()}
                />
              )}
            </div>

            <div
              style={{
                backgroundColor: "#000000cc",
                padding: "20px 30px",
                color: "#FFA500",
                height: props.parent === "ai_analysis" ? "120px" : "80px",
                borderBottom: "1px solid #FFA500",
              }}
            >
              <h2 className="" style={{ margin: "0px" }}>
                <GiArtificialIntelligence
                  size={40}
                  fill="#FFA500"
                  style={{ position: "relative", top: "-5px", right: "5px" }}
                />
                AI Analysis
              </h2>
              {props.parent === "ai_analysis" && <span>{props.stealerId}</span>}
            </div>

            <div style={{ padding: "2rem" }}>
              {data &&
                data.map((item, index) => (
                  <div key={index}>
                    <h5
                      style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                      className=""
                    >
                      {icons.find((i) => i.label === item.q) ? (
                        icons.find((i) => i.label === item.q).icon
                      ) : (
                        <GiArtificialIntelligence
                          size={25}
                          fill="#FFA500"
                          style={{ marginRight: "5px" }}
                        />
                      )}
                      {/* <GiArtificialIntelligence size={22} fill="#FFA500" />{" "} */}
                      {item.q}:
                    </h5>
                    <ParsedText message={item.a} />
                    <br />
                    <hr />
                    <br />
                  </div>
                ))}
            </div>

            <div ref={messagesEndRef}></div>
          </div>
        )}
      </div>
    </div>
  );
}
