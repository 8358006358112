/* eslint-disable */

import React, { useEffect, useState } from "react";
import Select from "react-select";

export default function SearchBy(props) {
  const [searchType, setSearchType] = useState();

  useEffect(() => {
    if (props.searchType) {
      setSearchType(
        props.options?.filter((s) => s.value === props.searchType)[0]
      );
    } else {
      setSearchType(props.options[0]);
    }
  }, [props.searchType]);

  return (
    <Select
      value={props.searchType}
      defaultValue={props.searchType}
      onChange={props.changeSearchType}
      placeholder={props.placeholder}
      className="search-by-select desktop searchBy"
      options={props.options}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: "#FBCD4E",
          primary: "black",
        },
      })}
    />
  );
}
