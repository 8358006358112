/* eslint-disable */

import ChartDataLabels from "chartjs-plugin-datalabels";
import React from "react";
import { Line } from "react-chartjs-2";
import { MdOutlineTimeline } from "react-icons/md";
import LoaderIcon from "react-loader-icon";
import cavaLogo from "../images/thumbnail.jpeg";
import NativeSelect from "./NativeSelect";
import Container from "./ProgressBar";

function LineChart(props) {
  const defaultSrc = (ev) => {
    ev.target.src = cavaLogo;
  };

  const data = {
    labels: props.labels,
    datasets: [
      {
        label: "Employees",
        data: props.employeesStats,
        fill: true,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 2,
        datalabels: {
          color: "#FFCE56",
          align: -120,
        },
      },
      {
        label: "Users",
        data: props.clientsStats,
        fill: true,
        backgroundColor: ["rgb(54, 162, 235, 0.2)"],
        borderColor: ["rgb(54, 162, 235, 1)"],
        borderWidth: 2,
        datalabels: {
          color: "#FFCE56",
          align: -120,
        },
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        align: "start",
        labels: {
          usePointStyle: true,
          // pointStyle: "circle",
          color: props.labelColor,
        },
      },
      datalabels: {
        align: "bottom",
        display: true,
        color: "#FFCE56",
        padding: 5,
        clamp: true,
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  // const [year, setYear] = React.useState("");
  // const handleYearChange = (event) => {
  //   setYear(event.target.value);
  // };

  return (
    <div className="lineChart" id={props.id}>
      {!props.loading ? (
        <>
          <div className="header">
            {props.domains && props.domains.split("&").length === 1 ? (
              <img
                alt="logo"
                onError={defaultSrc}
                src={`https://logo.clearbit.com/${props.logo}`}
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "10px",
                }}
              ></img>
            ) : (
              <MdOutlineTimeline
                size={40}
                style={{ fill: "#FECD4E", marginBottom: "10px" }}
              />
            )}
            <h1 className="title chartTitle">Compromised Computers Timeline</h1>
            {props.component && props.component}
            <hr></hr>
            {props.stealersCount > 0 && (
              <div className="chartSelect">
                <NativeSelect
                  handleYearChange={props.handleYearChange}
                  yearsOptions={props.yearsOptions}
                  year={props.year}
                />
              </div>
            )}
          </div>
          <div>
            {props.chartLoader ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: !props.custom && "280px",
                }}
              >
                <LoaderIcon className="tabsLoader" type={"spin"} />
              </div>
            ) : props.stealersCount > 0 ? (
              <Line
                plugins={[ChartDataLabels]}
                data={data}
                options={options}
                width={100}
                height={props.custom ? 25 : 20}
              />
            ) : (
              <div
                style={{
                  height: "350px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1>No data found</h1>
              </div>
            )}
          </div>
        </>
      ) : (
        <Container />
      )}
    </div>
  );
}

export default LineChart;
