/* eslint-disable */

import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import logo from "../../images/hrlogo_new.png";

import Collapse from "@material-ui/core/Collapse";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import axios from "axios";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { ImCross } from "react-icons/im";
import ReactJson from "react-json-view";
import LoaderIcon from "react-loader-icon";
import { APIFullEmailAddress, baseUrl, RECAPTCHA_KEY } from "../../Config";
import cavaLogo from "../../images/thumbnail.jpeg";
import BarChart from "../BarChart";
import ControlledOpenSelect from "./SimpleSelect";

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  samples: {
    [theme.breakpoints.up("sm")]: {
      width: "40%",
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      backgroundColor: "#1a1c20",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#1a1c20",
  },
  content: {
    flexGrow: 1,
    minHeight: "100vh",
    padding: theme.spacing(3),
    backgroundColor: "#171717",
    textAlign: "left",
  },
  contentDark: {
    flexGrow: 1,
    minHeight: "100vh",
    padding: theme.spacing(3),
    backgroundColor: "#1A1C20",
    textAlign: "left",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const isMobile = window.innerWidth <= 768;

function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [loader, setLoader] = useState(null);
  const [loginSample, setLoginSample] = useState("");
  const [login, setLogin] = useState("john123@gmail.com");
  const [loginList, setLoginList] = useState([
    "john123@gmail.com",
    "eric123@gmail.com",
  ]);
  const [domainSample, setDomainSample] = useState("");
  const [domainCounts, setDomainCounts] = useState("");
  const [ipSample, setIpSample] = useState("");
  const [domains, setDomains] = useState(["disney.com"]);
  const [keywords, setKeywords] = useState([]);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState([]);
  const [open, setOpen] = React.useState(null);
  const [opens, setOpens] = useState(new Set());
  const [error, setError] = useState(null);
  const [jsonResponse, setJsonResponse] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [key, setKey] = useState("");
  const [domainsInput, setDomainsInput] = useState("disney.com");
  const [keywordsInput, setKeywordsInput] = useState("");
  const [loginsInput, setLoginsInput] = useState(
    "john123@gmail.com,eric123@gmail.com"
  );
  const [ip, setIp] = useState("186.18.216.186");
  const [stealer, setStealer] = useState("EG_196.158.196.83");
  const [systemPreview, setSystemPreview] = useState("");
  const [system, setSystem] = useState("rdweb");
  const [selectOpen, setSelectOpen] = useState(null);
  const [queryUrl, setQueryUrl] = useState(
    `https://cavalier.hudsonrock.com/api/json/v2/search-by-domain`
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [requestLoading, setRequestLoading] = useState(false);
  const [msg, setMsg] = useState("");

  const [params, setParams] = useState({});

  const searchParams = new URLSearchParams();

  const onChangeCompany = (e) => {
    setCompany(e.target.value);
  };

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSelectClose = () => {
    setSelectOpen(null);
  };

  const handleSelectOpen = (e, item) => {
    setSelectOpen(item.name);
  };

  const onSelectChange = (e, item, remove) => {
    if (remove) {
      let newParams = params;
      delete newParams[item.name];
      setParams(newParams);
    } else {
      setParams({ ...params, [item.name]: e.target.value });
    }

    for (const prop in params) {
      searchParams.append(prop, params[prop]);
    }

    if (!params[item.name] && !remove) {
      searchParams.append(item.name, e.target.value);
    } else {
      if (remove) {
        searchParams.delete(item.name);
      } else {
        searchParams.set(item.name, e.target.value);
      }
    }

    let _params = searchParams.toString();
    let endpoint;
    if (activeTab.id === 5.4) {
      endpoint = "search-by-domain";
    } else if (activeTab.id === 5.5) {
      endpoint = "search-by-ip";
    }
    setQueryUrl(`${baseUrl}/${endpoint}?${_params}`);
  };

  const validLoginsArray = (e) => {
    setLoginsInput(e.target.value);
    setLoginList([
      ...new Set(
        e.target.value
          .replace(/ /g, "")
          .replace(/www./g, "")
          .split(",")
          .filter((s) => s !== "")
      ),
    ]);
  };

  const validKeywordsArray = (e) => {
    setKeywordsInput(e.target.value);
    setKeywords([
      ...new Set(
        e.target.value
          .replace(/ /g, "")
          .split(",")
          .filter((s) => s !== "")
      ),
    ]);
  };

  const validDomainsArray = (e) => {
    setDomainsInput(e.target.value);
    setDomains([
      ...new Set(
        e.target.value
          .replace(/ /g, "")
          .replace(/www./g, "")
          .split(",")
          .filter((s) => s !== "")
      ),
    ]);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navLinkClick = async (item) => {
    let endpoint;
    if (item.id === 5.4) {
      endpoint = `${baseUrl}/search-by-domain`;
    } else if (item.id === 5.5) {
      endpoint = `${baseUrl}/search-by-ip`;
    } else if (item.id === 5.9) {
      endpoint = `${baseUrl}/search-by-stealer`;
    }

    setQueryUrl(endpoint);
    setParams({});
    setNoResults(false);
    setError(null);
    if (item.parent) {
      if (Array.from(opens).includes(item.id)) {
        setOpens((prevIds) => {
          const newIds = new Set(prevIds);
          if (newIds.has(item.id)) {
            newIds.delete(item.id);
          }
          return newIds;
        });
      } else {
        setOpens((prevIds) => {
          const newIds = new Set(prevIds);
          newIds.add(item.id);
          return newIds;
        });
      }

      if (open === item.id) {
        setOpen(null);
        // setActiveMenu(null);
      } else {
        setOpen(item.id);
        // setActiveMenu(item);
      }
    } else {
      setMobileOpen(false);
      setActiveTab(item);
      setJsonResponse(null);
      // setPageLoading(true);
      setLoader(item.id);
      switch (item.id) {
        case 5.2:
          key
            ? await loginQuery(login)
            : setJsonResponse(props.samples?.loginSample);

          break;
        case 5.3:
          key
            ? await loginListQuery(loginList)
            : setJsonResponse(props.samples?.bulkLoginSample);
          break;
        case 5.4:
          key
            ? await domainsQuery(domains, keywords, endpoint)
            : setJsonResponse(props.samples?.domainSample);
          break;
        case 5.5:
          key
            ? await ipQuery(ip, endpoint)
            : setJsonResponse(props.samples?.ipSample);
          break;
        case 5.6:
          key
            ? await statsQuery(domainCounts, "search-by-domain/assessment")
            : setJsonResponse(props.samples?.thirdPartySample);
          break;
        case 5.7:
          key
            ? await statsQuery(domainCounts, "search-by-domain/discovery")
            : setJsonResponse(props.samples?.discoverySample);
          break;
        case 5.8:
          key
            ? await systemQuery(system)
            : setJsonResponse(props.samples?.systemSample);
          break;
        case 5.9:
          key
            ? await stealerQuery(stealer, endpoint)
            : setJsonResponse(props.samples?.stealerSample);
          break;
      }
      setLoader(null);
      // setPageLoading(false);
    }
  };

  const onChangeLogin = (e) => {
    setLoginSample(e.target.value);
  };

  const onChangeDomain = (e) => {
    setDomainSample(e.target.value);
  };

  const onChangeIp = (e) => {
    setIpSample(e.target.value);
  };

  const submitRequest = async () => {
    setRequestLoading(true);
    await axios
      .post(
        `${APIFullEmailAddress}/send/api-key-request`,
        {
          company: company,
          email: email,
        },
        {}
      )
      .then((res) => {
        setMsg(res.data);
        setRequestLoading(false);
        setCompany("");
        setEmail("");
      })
      .catch((err) => {
        console.log(err);
        setMsg(err.response.data);
        setRequestLoading(false);
      });
  };

  const submit = async (e, id) => {
    e.preventDefault();
    setError(null);
    setJsonResponse(null);
    setNoResults(false);
    setLoader(id);
    switch (id) {
      case 5.2:
        await loginQuery(login);
        break;
      case 5.3:
        await loginListQuery(loginList);
        break;
      case 5.4:
        await domainsQuery(domains, keywords);
        break;
      case 5.5:
        await ipQuery(ip);
        break;
      case 5.6:
        await statsQuery(domainCounts, "search-by-domain/assessment");
        break;
      case 5.7:
        await statsQuery(domainCounts, "search-by-domain/discovery");
        break;
      case 5.8:
        await systemQuery(system);
        break;
      case 5.9:
        await stealerQuery(stealer);
        break;
    }
    setLoader(null);
  };

  const systemQuery = async (system) => {
    const captcha_token = await grecaptcha.execute(RECAPTCHA_KEY, {
      action: "submit",
    });

    await axios
      .post(
        `${baseUrl}/search-by-system?sample=true`,
        {
          system: system,
          "g-recaptcha-response": captcha_token,
        },
        {
          headers: {
            "api-key": key,
          },
        }
      )
      .then((res) => {
        if (res.data) {
          if (res.data.data.length > 0) {
            setJsonResponse(res.data);
          } else {
            setNoResults(true);
          }
        }
      })
      .catch((err) => {
        setJsonResponse(err.response);
      });
  };

  const domainsQuery = async (domains, keywords, endpoint) => {
    const captcha_token = await grecaptcha.execute(RECAPTCHA_KEY, {
      action: "submit",
    });

    let _url;

    if (endpoint) {
      _url = endpoint.includes("?")
        ? `${endpoint}&sample=true`
        : `${endpoint}?sample=true`;
    } else {
      _url = queryUrl.includes("?")
        ? `${queryUrl}&sample=true`
        : `${queryUrl}?sample=true`;
    }

    await axios
      .post(
        _url,
        {
          domains: domains,
          keywords: keywords,
          "g-recaptcha-response": captcha_token,
        },
        {
          headers: {
            "api-key": key,
          },
        }
      )
      .then((res) => {
        if (res.data) {
          if (res.data.data) {
            setJsonResponse(res.data);
          } else if (res.data.length > 0) {
            setJsonResponse(res.data);
          } else {
            setNoResults(true);
          }
        } else {
          setNoResults(true);
        }
      })
      .catch((err) => {
        setJsonResponse(err.response);
      });
  };

  const loginListQuery = async (logins) => {
    const captcha_token = await grecaptcha.execute(RECAPTCHA_KEY, {
      action: "submit",
    });

    await axios
      .post(
        `${baseUrl}/search-by-login/bulk?sample=true`,
        {
          logins: logins,
          "g-recaptcha-response": captcha_token,
        },
        {
          headers: {
            "api-key": key,
          },
        }
      )
      .then((res) => {
        if (res.data) {
          if (res.data.length > 0) {
            setJsonResponse(res.data);
          } else {
            setNoResults(true);
          }
        }
      })
      .catch((err) => {
        setJsonResponse(err.response);
      });
  };

  const loginQuery = async (login) => {
    const captcha_token = await grecaptcha.execute(RECAPTCHA_KEY, {
      action: "submit",
    });

    await axios
      .post(
        `${baseUrl}/search-by-login?sample=true`,
        {
          login: login,
          "g-recaptcha-response": captcha_token,
        },
        {
          headers: {
            "api-key": key,
          },
        }
      )
      .then((res) => {
        if (res.data) {
          if (res.data.length > 0) {
            setJsonResponse(res.data);
          } else {
            setNoResults(true);
          }
        }
      })
      .catch((err) => {
        setJsonResponse(err.response);

        // setError(err.response.data);
      });
  };

  const stealerQuery = async (stealer, endpoint) => {
    let _url;

    if (endpoint) {
      _url = endpoint.includes("?")
        ? `${endpoint}&sample=true`
        : `${endpoint}?sample=true`;
    } else {
      _url = queryUrl.includes("?")
        ? `${queryUrl}&sample=true`
        : `${queryUrl}?sample=true`;
    }

    const captcha_token = await grecaptcha.execute(RECAPTCHA_KEY, {
      action: "submit",
    });

    await axios
      .post(
        _url,
        {
          stealers: [stealer],
          "g-recaptcha-response": captcha_token,
        },
        {
          headers: {
            "api-key": key,
          },
        }
      )
      .then((res) => {
        if (res.data) {
          if (res.data.length > 0) {
            setJsonResponse(res.data);
          } else {
            setNoResults(true);
          }
        }
      })
      .catch((err) => {
        setJsonResponse(err.response);
      });
  };

  const ipQuery = async (ip, endpoint) => {
    let _url;

    if (endpoint) {
      _url = endpoint.includes("?")
        ? `${endpoint}&sample=true`
        : `${endpoint}?sample=true`;
    } else {
      _url = queryUrl.includes("?")
        ? `${queryUrl}&sample=true`
        : `${queryUrl}?sample=true`;
    }

    const captcha_token = await grecaptcha.execute(RECAPTCHA_KEY, {
      action: "submit",
    });

    await axios
      .post(
        _url,
        {
          ip: ip,
          "g-recaptcha-response": captcha_token,
        },
        {
          headers: {
            "api-key": key,
          },
        }
      )
      .then((res) => {
        if (res.data) {
          if (res.data.length > 0) {
            setJsonResponse(res.data);
          } else {
            setNoResults(true);
          }
        }
      })
      .catch((err) => {
        setJsonResponse(err.response);
      });
  };

  const statsQuery = async (domain, endpoint) => {
    const captcha_token = await grecaptcha.execute(RECAPTCHA_KEY, {
      action: "submit",
    });

    await axios
      .post(
        `${baseUrl}/${endpoint}`,
        {
          domain: domain,
          "g-recaptcha-response": captcha_token,
        },
        {
          headers: {
            "api-key": key,
          },
        }
      )
      .then((res) => {
        if (res.data) {
          if (res.data) {
            setJsonResponse(res.data);
          } else {
            setNoResults(true);
          }
        }
      })
      .catch((err) => {
        setError(err.response.data);
        setJsonResponse(err.response);
      });
  };

  useEffect(() => {
    setLoginSample("john@gmail.com");
    setDomainSample("tesla.com");
    setDomainCounts("trendmicro.com");
    setSystemPreview("rdweb");
    setActiveTab(props.menu[0]);
  }, []);

  const defaultSrc = (ev) => {
    ev.target.src = cavaLogo;
  };

  const drawer = (
    <div style={{ textAlign: "left", margin: "5px" }}>
      <div
        className="desktop"
        style={{
          textAlign: "left",
          display: "inline-flex",
          alignItems: "end",
          margin: "20px 5px",
        }}
      >
        <img alt="img" src={props.img} width="40px" />
        <h5 className="darkText text-left" style={{ marginLeft: "5px" }}>
          {props.title}
        </h5>
      </div>
      <div className={classes.toolbar + " mobile"} />
      <Divider />
      <List style={{ padding: "10px" }}>
        {props.menu.map((item, index) => (
          <div key={index}>
            <ListItem
              className={
                activeTab.id === item.id
                  ? "cursor sidebar-li activeNav"
                  : "cursor sidebar-li"
              }
              key={index}
              id={`docs-tab-${item.id}`}
              component="a"
              href={item.link ? item.link : undefined}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                if (item.id !== 7) {
                  navLinkClick(item);
                }
              }}
            >
              <ListItemText primary={item.label} className="capitalize" />
              {item.parent && open === item.id ? (
                <ExpandLess />
              ) : (
                item.parent && <ExpandMore />
              )}
            </ListItem>
            {item.parent && Array.from(opens).includes(item.id) && (
              <Collapse
                in={Array.from(opens).includes(item.id)}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {Array.from(opens).includes(item.id) &&
                    props.menu &&
                    props.menu.length > 0 &&
                    item.children.map((item, index) => (
                      <div key={index}>
                        <ListItem
                          component="a"
                          href={item.link ? item.link : undefined}
                          target="_blank"
                          rel="noreferrer"
                          onClick={() => {
                            if (item.id !== 7) {
                              navLinkClick(item);
                            }
                          }}
                          className={
                            activeTab.id === item.id
                              ? classes.nested +
                                " cursor activeNav nestedItem sidebar-li"
                              : classes.nested + " cursor nestedItem sidebar-li"
                          }
                          id={`docs-tab-${item.id}`}
                        >
                          <ListItemText
                            className="capitalize"
                            primary={item.label}
                            style={{ fontSize: "14px" }}
                          />
                          {item.parent &&
                          Array.from(opens).includes(item.id) ? (
                            <ExpandLess />
                          ) : (
                            item.parent && <ExpandMore />
                          )}
                        </ListItem>
                        {item.parent && Array.from(opens).includes(item.id) && (
                          <Collapse
                            in={Array.from(opens).includes(item.id)}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              {Array.from(opens).includes(item.id) &&
                                props.menu &&
                                props.menu.length > 0 &&
                                item.children.map((item, index) => (
                                  <ListItem
                                    component="a"
                                    href={item.link ? item.link : undefined}
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={() => {
                                      if (item.id !== 7) {
                                        navLinkClick(item);
                                      }
                                    }}
                                    className={
                                      activeTab.id === item.id
                                        ? classes.nested +
                                          " cursor activeNav nestedItem sidebar-li subNested"
                                        : classes.nested +
                                          " cursor nestedItem sidebar-li subNested"
                                    }
                                    key={index}
                                    id={`docs-tab-${item.id}`}
                                  >
                                    <ListItemText
                                      className="capitalize"
                                      primary={item.label}
                                      style={{ fontSize: "14px" }}
                                    />
                                    {item.parent &&
                                    Array.from(opens).includes(item.id) ? (
                                      <ExpandLess />
                                    ) : (
                                      item.parent && <ExpandMore />
                                    )}
                                  </ListItem>
                                ))}
                            </List>
                          </Collapse>
                        )}
                      </div>
                    ))}
                </List>
              </Collapse>
            )}
          </div>
        ))}
      </List>
      <Divider />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root + " mobile-block"}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={classes.appBar}
        style={{ backgroundColor: "#1a1c20" }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <img
            alt="img"
            src={props.img}
            width="40px"
            style={{ marginRight: "5px" }}
          />
          <Typography variant="h6" noWrap>
            Cavalier API Docs
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main
        className={
          activeTab.id === 6
            ? classes.contentDark
            : classes.content + " mobile-50-vh mobile-x"
        }
      >
        <div className={classes.toolbar} />
        {/* {props.menu.map((item) => (
          <div>
            {item.parent ? (
              item.children.map((child) => (
                <>
                  <br />
                  <h4 className="capitalize">{child.headline}</h4>
                  <br />
                  {child.content}
                </>
              ))
            ) : (
              <>
                <br />
                <h4 className="capitalize">{item.headline}</h4>
                <br />
                {item.content}
              </>
            )}
          </div>
        ))} */}
        <h4 className="capitalize">{activeTab.headline}</h4>
        <br />
        {activeTab.content}
        {activeTab.id === 4.5 ||
        activeTab.id === 5.6 ||
        activeTab.id === 4.6 ||
        activeTab.id === 5.7 ||
        activeTab.id === 4.7 ? (
          <>
            {pageLoading ? (
              <LoaderIcon
                type={"spin"}
                className="tabsLoader"
                style={{
                  position: "relative",
                  bottom: "0px",
                  right: "0px",
                  top: "15rem",
                }}
              />
            ) : jsonResponse && jsonResponse.visualize && !error ? (
              <>
                <br />
                {/* <h1 style={{ color: "#FBCD4E" }}>
                    Visualize data to your customers
                  </h1> */}
                <BarChart
                  labelColor="#fff"
                  screen="main"
                  data={
                    jsonResponse.visualize &&
                    jsonResponse.visualize.employees_urls
                  }
                  dataCount={jsonResponse.visualize.employees_count}
                  loading={pageLoading}
                  type="(Employees)"
                  height={20}
                  width={100}
                  totalCount={jsonResponse.visualize.totalEmployees}
                />
                <br />
                <BarChart
                  labelColor="#fff"
                  screen="main"
                  data={jsonResponse.visualize.clients_urls}
                  dataCount={jsonResponse.visualize.clients_count}
                  loading={pageLoading}
                  type="(Users)"
                  height={20}
                  width={100}
                  totalCount={jsonResponse.visualize.totalUsers}
                />
                <br />
                {(activeTab.id === 5.7 || activeTab.id === 4.6) && (
                  <BarChart
                    labelColor="#fff"
                    screen="main"
                    data={jsonResponse.visualize.third_party_urls}
                    dataCount={jsonResponse.visualize.third_party_count}
                    loading={pageLoading}
                    type="(Third Party)"
                    height={20}
                    width={100}
                    totalCount={jsonResponse.visualize.total_third_parties}
                  />
                )}
              </>
            ) : (
              systemPreview &&
              !error && (
                <div
                  style={{
                    display: "inline-flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  {jsonResponse &&
                    jsonResponse.data &&
                    jsonResponse.data.length > 0 &&
                    jsonResponse.data.slice(0, 5).map((item, index) => (
                      <div
                        key={index}
                        style={{
                          margin: "5px",
                          textAlign: "center",
                          backgroundColor: "#D3D2D2",
                          width: "150px",
                          height: "175px",
                          borderRadius: "20px",
                          padding: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ alignSelf: "center" }}>
                          <img
                            alt="img"
                            src={`https://logo.clearbit.com/${item.domain}`}
                            style={{
                              borderRadius: "5px",
                              width: "80px",
                            }}
                            onError={defaultSrc}
                          />
                          <p
                            className="lightText"
                            style={{ marginTop: "5px", marginBottom: "0px" }}
                          >
                            {item.domain}
                          </p>
                          <p
                            className="lightText capitalize"
                            style={{ margin: "0px", fontSize: "14px" }}
                          >
                            URLs: {item.count}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              )
            )}
          </>
        ) : (
          <div>{error}</div>
        )}
      </main>
      <div
        className={classes.samples + " mobile-50-vh mobile-padding-20"}
        aria-label="mailbox folders"
      >
        <div
          className="topBar"
          style={{
            width: "100%",
            // height: "60px",
            // backgroundColor: "#171717",
            color: "black",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#FDCE4D",
              borderRadius: isMobile ? "50px" : "50px 0px 0px 50px",
              padding: "1rem 2rem",
              cursor: "pointer",
            }}
            onClick={() => setModalOpen(true)}
          >
            <h4
              style={{
                margin: "0px",
                fontWeight: "bold",
                color: "#1a1c20",
                lineHeight: isMobile && 2,
                cursor: "pointer",
              }}
              className="cyberFont"
            >
              Click here to get your custom API key →
              {/* <span
                style={{
                  color: "#FDCE4D",
                  padding: "0.5rem",
                  borderRadius: "20px",
                  backgroundColor: "#1a1c20",
                }}
              >
                HERE
              </span> */}
            </h4>
          </div>
          <Dialog
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            aria-labelledby="form-dialog-title"
            id="feedbackModal"
          >
            <div
              style={{
                padding: "10px",
                borderBottom: "1px dashed lightgray",
              }}
            >
              <img src={logo} width="200" />
            </div>
            <DialogTitle id="form-dialog-title">
              Get your custom API key
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please enter your company name and corporate email address
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id={Date.now()}
                label={"Company"}
                type="text"
                fullWidth
                onChange={onChangeCompany}
                value={company}
                className="whiteInput"
              />
              <TextField
                margin="dense"
                id={Date.now()}
                label={"Corporate Email Address"}
                type="text"
                fullWidth
                onChange={onChangeEmail}
                value={email}
                className="whiteInput"
              />
            </DialogContent>
            <DialogActions>
              {msg && (
                <span
                  style={{
                    color: msg.includes("success") ? "green" : "red",
                    padding: "0px 0px 15px 15px",
                    width: "100%",
                  }}
                >
                  {msg}
                </span>
              )}
              <Button
                onClick={() => {
                  setModalOpen(false);
                  setMsg("");
                }}
                color="primary"
              >
                Cancel
              </Button>
              <Button onClick={submitRequest} color="primary">
                {requestLoading ? (
                  <LoaderIcon
                    type="spin"
                    color="white"
                    style={{
                      width: "16px",
                      height: "16px",
                      marginTop: "-15px",
                    }}
                  />
                ) : (
                  "Submit"
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        {!pageLoading ? (
          <div style={{ padding: !isMobile && "20px" }}>
            {activeTab.blocks &&
              activeTab.blocks.map((block, index) => (
                <div key={index}>{block}</div>
              ))}
            {activeTab.explain}
            <br />
            <br />
            {activeTab.sample && (
              <div
                className="text-left"
                style={{ width: "100%", margin: "auto" }}
              >
                {activeTab.limit && (
                  <h6>
                    Documentation results are limited to {activeTab.limit}{" "}
                    items.
                  </h6>
                )}
                {activeTab.id < 5 ? (
                  <h4>
                    {activeTab.id === 4.2
                      ? "Try any email for free"
                      : activeTab.id === 4.4
                      ? "Try any IP for free"
                      : activeTab.id === 4.7
                      ? "Try any system for free"
                      : "Try any domain for free"}
                  </h4>
                ) : (
                  <h4>Make a sample request</h4>
                )}
                <form onSubmit={(e) => submit(e, activeTab.id)}>
                  {activeTab.id === 4.2 ? (
                    <input
                      onChange={onChangeLogin}
                      placeholder={"Email Address/Username"}
                      value={loginSample}
                      style={{
                        width: "100%",
                        padding: "6px",
                        marginRight: "2px",
                        borderRadius: "5px",
                      }}
                      required
                    />
                  ) : activeTab.id === 4.3 ? (
                    <input
                      onChange={onChangeDomain}
                      placeholder={"Domain"}
                      value={domainSample}
                      style={{
                        width: "100%",
                        padding: "6px",
                        marginRight: "2px",
                        borderRadius: "5px",
                      }}
                      required
                    />
                  ) : activeTab.id === 4.4 ? (
                    <input
                      onChange={onChangeIp}
                      placeholder={"IP Address"}
                      value={ipSample}
                      style={{
                        width: "100%",
                        padding: "6px",
                        marginRight: "2px",
                        borderRadius: "5px",
                      }}
                      required
                    />
                  ) : activeTab.id === 4.5 || activeTab.id === 4.6 ? (
                    <input
                      onChange={(e) => setDomainCounts(e.target.value)}
                      placeholder={"Domain"}
                      value={domainCounts}
                      style={{
                        width: "100%",
                        padding: "6px",
                        marginRight: "2px",
                        borderRadius: "5px",
                      }}
                      required
                    />
                  ) : activeTab.id === 4.7 ? (
                    <input
                      onChange={(e) => setSystemPreview(e.target.value)}
                      placeholder={"System"}
                      value={systemPreview}
                      style={{
                        width: "100%",
                        padding: "6px",
                        marginRight: "2px",
                        borderRadius: "5px",
                      }}
                      required
                    />
                  ) : (
                    <>
                      {activeTab.id >= 5.2 && activeTab.id < 6 && (
                        <>
                          {(activeTab.id === 5.4 || activeTab.id === 5.5) && (
                            <>
                              {/* <h5>Query Builder:</h5> */}
                              <div style={{ width: "100%" }}>
                                {activeTab.params.map((item, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      backgroundColor: params[item.name]
                                        ? "#fbcd4e"
                                        : "lightgray",
                                      padding: "8px",
                                      borderRadius:
                                        index === 0
                                          ? "5px"
                                          : index % 2 == 0
                                          ? "0px 5px 5px 0px"
                                          : "5px 0px 0px 5px",
                                      margin: "8px 2px",
                                      color: "black",
                                      display: "inline-flex",
                                      width:
                                        index > 0 && !isMobile ? "49%" : "98%",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "50%",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {item.name}
                                    </div>
                                    <div>
                                      {item.type !== "date" ? (
                                        <ControlledOpenSelect
                                          width={item.width}
                                          options={item.options}
                                          open={selectOpen === item.name}
                                          onClose={handleSelectClose}
                                          onOpen={(e) =>
                                            handleSelectOpen(e, item)
                                          }
                                          onChange={(e) =>
                                            onSelectChange(e, item)
                                          }
                                          label={item.default}
                                        />
                                      ) : (
                                        <input
                                          onChange={(e) => {
                                            if (
                                              params["compromised_since"] &&
                                              item.name.includes("added")
                                            ) {
                                              onSelectChange(
                                                e,
                                                { name: "compromised_since" },
                                                true
                                              );
                                            }

                                            if (
                                              params["compromised_until"] &&
                                              item.name.includes("added")
                                            ) {
                                              onSelectChange(
                                                e,
                                                { name: "compromised_until" },
                                                true
                                              );
                                            }

                                            if (
                                              params["added_since"] &&
                                              item.name.includes("compromised")
                                            ) {
                                              onSelectChange(
                                                e,
                                                { name: "added_since" },
                                                true
                                              );
                                            }

                                            if (
                                              params["added_until"] &&
                                              item.name.includes("compromised")
                                            ) {
                                              onSelectChange(
                                                e,
                                                { name: "added_until" },
                                                true
                                              );
                                            }
                                            onSelectChange(e, item);
                                          }}
                                          style={{
                                            borderRadius: "4px",
                                            padding: "2px 4px",
                                            border: "2px solid",
                                            fontSize: "12px",
                                          }}
                                          type="date"
                                          id="start"
                                          name="date"
                                          min="2000-01-01"
                                          max={new Date()
                                            .toISOString()
                                            .substr(0, 10)}
                                        />
                                      )}
                                    </div>
                                    {params[item.name] && (
                                      <div
                                        className="cursor"
                                        onClick={(e) => {
                                          onSelectChange(e, item, true);
                                        }}
                                        style={{ margin: "0px 2px" }}
                                      >
                                        <ImCross size={15} />
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                              <input
                                onChange={(e) => setQueryUrl(e.target.value)}
                                placeholder={"URL"}
                                value={queryUrl}
                                style={{
                                  width: "100%",
                                  padding: "6px",
                                  marginRight: "2px",
                                  marginBottom: "5px",
                                  borderRadius: "5px",
                                  backgroundColor: "white",
                                }}
                                required
                                disabled
                              />
                            </>
                          )}
                          <input
                            onChange={(e) => setKey(e.target.value)}
                            placeholder={"API Key"}
                            value={key}
                            style={{
                              width: "100%",
                              padding: "6px",
                              marginBottom: "5px",
                              borderRadius: "5px",
                            }}
                            required
                          />
                        </>
                      )}
                      {activeTab.id === 5.2 ? (
                        <input
                          onChange={(e) => setLogin(e.target.value)}
                          placeholder={"Email Address/Username"}
                          value={login}
                          style={{
                            width: "100%",
                            padding: "6px",
                            marginRight: "2px",
                            borderRadius: "5px",
                          }}
                          required
                        />
                      ) : activeTab.id === 5.3 ? (
                        <input
                          onChange={(e) => validLoginsArray(e)}
                          placeholder={
                            "Email Addresses/Usernames (Comma-separated)"
                          }
                          value={loginsInput}
                          style={{
                            width: "100%",
                            padding: "6px",
                            marginRight: "2px",
                            borderRadius: "5px",
                          }}
                          required
                        />
                      ) : activeTab.id === 5.4 ? (
                        <>
                          <input
                            onChange={(e) => validDomainsArray(e)}
                            placeholder={"Domains (Comma-separated)"}
                            value={domainsInput}
                            style={{
                              width: "100%",
                              padding: "6px",
                              marginRight: "2px",
                              borderRadius: "5px",
                            }}
                            required
                          />
                          <input
                            onChange={(e) => validKeywordsArray(e)}
                            placeholder={"Keywords (Comma-separated)"}
                            value={keywordsInput}
                            style={{
                              width: "100%",
                              padding: "6px",
                              marginRight: "2px",
                              borderRadius: "5px",
                            }}
                          />
                        </>
                      ) : activeTab.id === 5.5 ? (
                        <input
                          onChange={(e) => setIp(e.target.value)}
                          placeholder={"IP Address"}
                          value={ip}
                          style={{
                            width: "100%",
                            padding: "6px",
                            marginRight: "2px",
                            borderRadius: "5px",
                          }}
                          required
                        />
                      ) : activeTab.id === 5.6 ? (
                        <input
                          onChange={(e) => setDomainCounts(e.target.value)}
                          placeholder={"Domain"}
                          value={domainCounts}
                          style={{
                            width: "100%",
                            padding: "6px",
                            marginRight: "2px",
                            borderRadius: "5px",
                          }}
                          required
                        />
                      ) : activeTab.id === 5.7 ? (
                        <input
                          onChange={(e) => setDomainCounts(e.target.value)}
                          placeholder={"Domain"}
                          value={domainCounts}
                          style={{
                            width: "100%",
                            padding: "6px",
                            marginRight: "2px",
                            borderRadius: "5px",
                          }}
                          required
                        />
                      ) : activeTab.id === 5.9 ? (
                        <input
                          onChange={(e) => setStealer(e.target.value)}
                          placeholder={"Stealer ID"}
                          value={stealer}
                          style={{
                            width: "100%",
                            padding: "6px",
                            marginRight: "2px",
                            borderRadius: "5px",
                          }}
                          required
                        />
                      ) : (
                        activeTab.id === 5.8 && (
                          <input
                            onChange={(e) => setSystem(e.target.value)}
                            placeholder={"System"}
                            value={system}
                            style={{
                              width: "100%",
                              padding: "6px",
                              marginRight: "2px",
                              borderRadius: "5px",
                            }}
                            required
                          />
                        )
                      )}
                    </>
                  )}

                  <Button
                    className="searchBtn"
                    type="submit"
                    style={{ marginTop: "5px", width: "100%" }}
                  >
                    {loader === activeTab.id ? (
                      <LoaderIcon
                        type={"spin"}
                        className="removeLoader tableLoader"
                        style={{
                          position: "relative",
                          bottom: "0px",
                          right: "0px",
                          top: "0px",
                          fontSize: "12px",
                        }}
                      />
                    ) : (
                      "Send"
                    )}
                  </Button>
                </form>
                <br />
                {jsonResponse ? (
                  <>
                    {activeTab.limit && (
                      <h6>
                        Documentation results are limited to {activeTab.limit}{" "}
                        items.
                      </h6>
                    )}
                    <ReactJson
                      shouldCollapse={(field) => {
                        if (field.name === "installed_software") {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                      // collapsed={2}
                      src={
                        activeTab.id === 4.5 ||
                        (activeTab.id === 5.6 && !error) ||
                        activeTab.id === 4.6 ||
                        (activeTab.id === 5.7 && !error)
                          ? jsonResponse.data
                          : jsonResponse
                      }
                      theme="monokai"
                      displayDataTypes={false}
                      className="jsonResponse"
                    />
                  </>
                ) : (
                  <>
                    {noResults && (
                      <h4 style={{ textAlign: "center" }}>No Results</h4>
                    )}
                    {error && <h4 className="error">{error}</h4>}
                  </>
                )}
              </div>
            )}
          </div>
        ) : (
          <LoaderIcon
            type={"spin"}
            className="tabsLoader"
            style={{
              position: "relative",
              bottom: "0px",
              right: "0px",
              top: "15rem",
              //   fontSize: "12px",
            }}
          />
        )}
      </div>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
