/* eslint-disable */

import React, { useState } from "react";
import CreateApiClient from "../../../API";
import { adminUrl } from "../../../Config";
import EmailTemplate from "./EmailTemplate";

const api = CreateApiClient();

export default function Newsletter(props) {
  const [subject, setSubject] = useState("Email Subject Example...");
  const [articles, setArticles] = useState([
    {
      link: "https://www.infostealers.com/article/open-sourced-infostealers-about-to-fuel-new-wave-of-computer-infections/",
      imageLink:
        "https://www.infostealers.com/wp-content/uploads/2024/06/jsodfjsdjkfsdjk.png",
      title:
        "Open-Sourced Infostealers About to Fuel New Wave of Computer Infections",
    },
  ]);
  const [testLoading, setTestLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleAddArticle = () => {
    setArticles([...articles, { link: "", imageLink: "", title: "" }]);
  };

  const handleArticleChange = (index, field, value) => {
    const updatedArticles = articles.map((article, i) =>
      i === index ? { ...article, [field]: value } : article
    );
    setArticles(updatedArticles);
  };

  const handleRemoveArticle = (index) => {
    const updatedArticles = articles.filter((_, i) => i !== index);
    setArticles(updatedArticles);
  };

  const sendToTest = async () => {
    let confirmed = window.confirm("Are you sure you want to send email?");
    if (confirmed) {
      setTestLoading(true);
      const response = await api.adminReq(
        "POST",
        `${adminUrl}/newsletter/infostealers/test`,
        {
          "auth-token": props.token,
        },
        { data: articles, subject: subject }
      );
      setTestLoading(false);
      window.alert(response.data);
    }
  };

  const sendToProd = async () => {
    let confirmed = window.confirm("Are you sure you want to send email?");
    if (confirmed) {
      setLoading(true);
      const response = await api.adminReq(
        "POST",
        `${adminUrl}/newsletter/infostealers`,
        {
          "auth-token": props.token,
        },
        { data: articles, subject: subject }
      );
      setLoading(false);
      window.alert(response.data);
    }
  };

  return (
    <div style={{ padding: "0rem 0rem 0rem 1rem" }} className="darkText">
      <div>
        <h2 className="cyberFont bold yellowColor">INFOSTEALERS NEWSLETTER</h2>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            right: "40px",
            top: "110px",
          }}
        >
          <button
            onClick={sendToTest}
            style={{
              padding: "10px 20px",
            }}
          >
            {testLoading ? "SENDING..." : "TEST EMAIL"}
          </button>
          <button
            onClick={sendToProd}
            style={{
              padding: "10px 20px",
              backgroundColor: "#f6cd65",
            }}
          >
            {loading ? "SENDING..." : "SEND TO SUBSCRIBERS"}
          </button>
        </div>

        <hr />
        <h4>Create a single send:</h4>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "top",
            columnGap: "10px",
          }}
        >
          <div style={{ width: "50%" }}>
            <input
              style={{ width: "100%", padding: "10px" }}
              placeholder="Email Subject"
              value={subject}
              onChange={handleSubjectChange}
            />
            <hr />
            <h5>Articles:</h5>
            <div>
              {articles.map((article, index) => (
                <div key={index} style={{ marginBottom: "10px" }}>
                  <div style={{ position: "relative" }}>
                    <span>Article #{index + 1}:</span>
                    <br />
                    <br />
                    <button
                      onClick={() => handleRemoveArticle(index)}
                      style={{
                        padding: "5px 10px",
                        position: "absolute",
                        right: "0px",
                        top: "0px",
                        borderRadius: "50px",
                      }}
                    >
                      X
                    </button>
                  </div>

                  <input
                    style={{
                      width: "100%",
                      padding: "10px",
                      marginBottom: "5px",
                    }}
                    placeholder="Article Link"
                    value={article.link}
                    onChange={(e) =>
                      handleArticleChange(index, "link", e.target.value)
                    }
                  />
                  <input
                    style={{
                      width: "100%",
                      padding: "10px",
                      marginBottom: "5px",
                    }}
                    placeholder="Article Image Link"
                    value={article.imageLink}
                    onChange={(e) =>
                      handleArticleChange(index, "imageLink", e.target.value)
                    }
                  />
                  <input
                    style={{
                      width: "100%",
                      padding: "10px",
                      marginBottom: "5px",
                    }}
                    placeholder="Article Title"
                    value={article.title}
                    onChange={(e) =>
                      handleArticleChange(index, "title", e.target.value)
                    }
                  />
                </div>
              ))}
              <button
                onClick={handleAddArticle}
                style={{ padding: "10px 20px", marginTop: "10px" }}
              >
                Add Article
              </button>
            </div>
          </div>
          <div style={{ width: "50%" }}>
            {articles && articles.length > 0 && (
              <EmailTemplate items={articles} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
