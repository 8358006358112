import React from "react";

function PdfDownload({ reports, token }) {
  const downloadFile = (file, fileName) => {
    const fileData = new Uint8Array(file.data);
    const blob = new Blob([fileData], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    // deleteFile(fileName);
  };

  // const deleteFile = async (file) => {
  //   let url = `${authAddress}/delete-report?fileId=${file}`;
  //   await axios.post(
  //     url,
  //     {},
  //     {
  //       headers: {
  //         "auth-token": token,
  //       },
  //     }
  //   );
  // };

  return (
    <div>
      <h1>PDF Reports:</h1>
      <table className="styled-table">
        <thead>
          <tr>
            <th>File Name</th>
            <th>Created at</th>
            <th>Expiry</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          {reports.map((item, index) => (
            <tr key={index}>
              <td>{item.filename}</td>
              <td>{item.createdAt}</td>
              <td>{item.expiry}</td>
              <td>
                <button
                  onClick={() => downloadFile(item.file, item.filename)}
                  style={{
                    color: "black",
                    backgroundColor: "#FBCD4E",
                    borderRadius: "4px",
                    padding: "6px 8px",
                    marginLeft: "4px",
                    border: "0px",
                  }}
                >
                  Download
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PdfDownload;
