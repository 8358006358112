/* eslint-disable */

import Button from "@material-ui/core/Button";
import axios from "axios";
import { useEffect, useState } from "react";
import { DiAndroid } from "react-icons/di";
import { FaDesktop, FaExternalLinkAlt } from "react-icons/fa";
import LoaderIcon from "react-loader-icon";
import { APIFullAddress, JSON_API } from "../../Config";
import cavaLogo from "../../images/enterprise.png";
import govLogo from "../../images/gov.png";
import DomainsByKeyword from "./DomainsByKeyword";
import FetchSuggestions from "./FetchSuggestions";
import GovSearch from "./GovSearch";
import NewCompanyData from "./NewCompanyData";

const options = [
  {
    country: "india",
    type: "gov",
    code: "in",
    flag: "https://flagsapi.com/IN/flat/64.png",
    value: "india",
    label: "INDIA",
  },
  {
    country: "brazil",
    type: "gov",
    code: "br",
    flag: "https://flagsapi.com/BR/flat/64.png",
    value: "brazil",
    label: "BRAZIL",
  },
  {
    country: "turkey",
    type: "gov",
    code: "tr",
    flag: "https://flagsapi.com/TR/flat/64.png",
    value: "turkey",
    label: "TURKEY",
  },
  {
    country: "pakistan",
    type: "gov",
    code: "pk",
    flag: "https://flagsapi.com/PK/flat/64.png",
    value: "pakistan",
    label: "PAKISTAN",
  },
  {
    country: "malaysia",
    type: "gov",
    code: "my",
    flag: "https://flagsapi.com/MY/flat/64.png",
    value: "malaysia",
    label: "MALAYSIA",
  },
  {
    country: "saudi arabia",
    type: "gov",
    code: "sa",
    flag: "https://flagsapi.com/SA/flat/64.png",
    value: "saudi arabia",
    label: "SAUDI ARABIA",
  },
  {
    country: "egypt",
    type: "gov",
    code: "eg",
    flag: "https://flagsapi.com/EG/flat/64.png",
    value: "egypt",
    label: "EGYPT",
  },
  {
    country: "philippines",
    type: "gov",
    code: "ph",
    flag: "https://flagsapi.com/PH/flat/64.png",
    value: "philippines",
    label: "PHILIPPINES",
  },
  {
    country: "nepal",
    type: "gov",
    code: "np",
    flag: "https://flagsapi.com/NP/flat/64.png",
    value: "nepal",
    label: "NEPAL",
  },
  {
    country: "croatia",
    type: "gov",
    code: "co",
    flag: "https://flagsapi.com/CO/flat/64.png",
    value: "croatia",
    label: "CROATIA",
  },
  {
    country: "czechia",
    type: "gov",
    code: "cz",
    flag: "https://flagsapi.com/CZ/flat/64.png",
    value: "czechia",
    label: "Czechia (Czech Republic)",
  },
  {
    country: "emirates",
    type: "gov",
    code: "ae",
    flag: "https://flagsapi.com/AE/flat/64.png",
    value: "emirates",
    label: "EMIRATES",
  },
  {
    country: "bengladesh",
    type: "gov",
    code: "bd",
    flag: "https://flagsapi.com/BD/flat/64.png",
    value: "bengladesh",
    label: "BENGLADESH",
  },
  {
    country: "belgium",
    type: "fgov",
    code: "be",
    flag: "https://flagsapi.com/BE/flat/64.png",
    value: "belgium",
    label: "BELGIUM",
  },
  {
    country: "germany",
    type: "gov",
    code: "de",
    flag: "https://flagsapi.com/DE/flat/64.png",
    value: "germany",
    label: "GERMANY",
  },
  {
    country: "france",
    type: "gouv",
    code: "fr",
    flag: "https://flagsapi.com/FR/flat/64.png",
    value: "france",
    label: "FRANCE",
  },
  {
    country: "portugal",
    type: "gov",
    code: "pt",
    flag: "https://flagsapi.com/PT/flat/64.png",
    value: "portugal",
    label: "PORTUGAL",
  },
  {
    country: "kuwait",
    type: "gov",
    code: "kw",
    flag: "https://flagsapi.com/KW/flat/64.png",
    value: "kuwait",
    label: "KUWAIT",
  },
  {
    country: "united kingdom",
    type: "gov",
    code: "uk",
    flag: "https://flagsapi.com/GB/flat/64.png",
    value: "united kingdom",
    label: "UNITED KINGDOM",
  },
  {
    country: "australia",
    type: "gov",
    code: "au",
    flag: "https://flagsapi.com/AU/flat/64.png",
    value: "australia",
    label: "AUSTRALIA",
  },
  {
    country: "taiwan",
    type: "gov",
    code: "tw",
    flag: "https://flagsapi.com/TW/flat/64.png",
    value: "taiwan",
    label: "TAIWAN",
  },
  {
    country: "thailand",
    type: "go",
    code: "th",
    flag: "https://flagsapi.com/TH/flat/64.png",
    value: "thailand",
    label: "THAILAND",
  },
  {
    country: "indonesia",
    type: "go",
    code: "id",
    flag: "https://flagsapi.com/ID/flat/64.png",
    value: "indonesia",
    label: "INDONESIA",
  },
  {
    country: "vietnam",
    type: "gov",
    code: "vn",
    flag: "https://flagsapi.com/VN/flat/64.png",
    value: "vietnam",
    label: "VIETNAM",
  },
  {
    country: "singapore",
    type: "gov",
    code: "sg",
    flag: "https://flagsapi.com/SG/flat/64.png",
    value: "singapore",
    label: "SINGAPORE",
  },
  {
    country: "nigeria",
    type: "gov",
    code: "ng",
    flag: "https://flagsapi.com/NG/flat/64.png",
    value: "nigeria",
    label: "NIGERIA",
  },
  {
    country: "argentina",
    type: "gov",
    code: "ar",
    flag: "https://flagsapi.com/AR/flat/64.png",
    value: "argentina",
    label: "ARGENTINA",
  },
  {
    country: "hungary",
    type: "gov",
    code: "hu",
    flag: "https://flagsapi.com/HU/flat/64.png",
    value: "hungary",
    label: "HUNGARY",
  },
  {
    country: "israel",
    type: "gov",
    code: "il",
    flag: "https://flagsapi.com/IL/flat/64.png",
    value: "israel",
    label: "ISRAEL",
  },
  {
    country: "paraguay",
    type: "gov",
    code: "py",
    flag: "https://flagsapi.com/PY/flat/64.png",
    value: "paraguay",
    label: "PARAGUAY",
  },
  {
    country: "marocco",
    type: "gov",
    code: "ma",
    flag: "https://flagsapi.com/MA/flat/64.png",
    value: "marocco",
    label: "MAROCCO",
  },
  {
    country: "italy",
    type: "gov",
    code: "it",
    flag: "https://flagsapi.com/IT/flat/64.png",
    value: "italy",
    label: "ITALY",
  },
  {
    country: "poland",
    type: "gov",
    code: "pl",
    flag: "https://flagsapi.com/PL/flat/64.png",
    value: "poland",
    label: "POLAND",
  },
  {
    country: "jordan",
    type: "gov",
    code: "jo",
    flag: "https://flagsapi.com/JO/flat/64.png",
    value: "jordan",
    label: "JORDAN",
  },
  {
    country: "bahrain",
    type: "gov",
    code: "bh",
    flag: "https://flagsapi.com/BH/flat/64.png",
    value: "bahrain",
    label: "BAHRAIN",
  },
];

export default function NewCompanyForm(props) {
  const [domains, setDomains] = useState([]);
  const [apks, setApks] = useState([]);
  const [externals, setExternals] = useState([]);
  const [domainsInput, setDomainsInput] = useState("");
  const [apksInput, setApksInput] = useState("");
  const [externalsInput, setExternalsInput] = useState("");
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [keywordLoading, setKeywordLoading] = useState(false);
  const [activeUrlList, setActiveUrlList] = useState(null);
  const [keywordDomains, setKeywordDomains] = useState([]);
  const [keywordPage, setKeywordPage] = useState(1);
  const [keywordPageLoading, setKeywordPageLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [externalDomains, setExternalDomains] = useState([]);
  const [suggestedExternalDomains, setSuggestedExternalDomains] = useState([]);
  const [suggestedDomains, setSuggestedDomains] = useState([]);
  const [noResults, setNoResults] = useState(null);
  const [keywordData, setKeywordData] = useState({});
  const [urlsLoading, setUrlsLoading] = useState(null);

  const [selectedGov, setSelectedGov] = useState("");

  const [logo, setLogo] = useState("");

  const handleScroll = () => {
    setSuggestions([]);
  };

  useEffect(() => {
    let dialog = document.getElementById("newCompanyForm");
    dialog.addEventListener("scroll", handleScroll);

    return () => {
      dialog.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const defaultSrc = (ev) => {
    ev.target.src = props.type === "gov" ? govLogo : cavaLogo;
  };

  const addDomains = (e) => {
    let _domains = domainsInput.replace(/ /, "").split(",");

    let filtered = _domains.filter((d) => !domains.includes(d));
    setDomains([...domains, ...filtered]);
    setDomainsInput("");
  };

  const removeDomain = (_domain) => {
    let filtered = domains.filter((d) => d !== _domain);
    setDomains(filtered);
  };

  const addApks = () => {
    let _apks = apksInput.replace(/ /, "").split(",");
    let filtered = _apks.filter((d) => !apks.includes(d));
    setApks([...apks, ...filtered]);
    setApksInput("");
  };

  const removeApk = (_apk) => {
    let filtered = apks.filter((a) => a !== _apk);
    setApks(filtered);
  };

  const addExternalDomains = () => {
    let _domains = externalsInput.replace(/ /, "").split(",");
    let filtered = _domains.filter((d) => !externals.includes(d));
    setExternals([...externals, ...filtered]);
    setExternalsInput("");
  };

  const removeExternalDomain = (_domain) => {
    let filtered = externals.filter((d) => d !== _domain);
    setExternals(filtered);
  };

  const bulkSet = (func, initialData, data) => {
    let filtered = data.filter((s) => !initialData.includes(s));
    func([...initialData, ...filtered]);
  };

  const govData = [
    {
      name: "Domains",
      field: "domains",
      list: domains,
      removeFunc: (domain) => removeDomain(domain),
      addFunc: () => addDomains(),
      inputValue: domainsInput,
      onChange: (e) => {
        let value = e.target.value.replace(/ /g, "");
        setDomainsInput(value);
      },
      icon: <FaDesktop size={25} />,
      bulkSet: (_domains) =>
        bulkSet(
          setDomains,
          props.mode === "edit" ? props.openRow.data.domains : domains,
          _domains
        ),
    },
  ];

  const companyData = [
    {
      name: "Domains",
      field: "domains",
      list: domains,
      removeFunc: (domain) => removeDomain(domain),
      addFunc: (e, data) => addDomains(e, data),
      inputValue: domainsInput,
      onChange: (e) => {
        let value = e.target.value.replace(/ /g, "");
        setDomainsInput(value);
      },
      icon: <FaDesktop size={25} />,
      bulkSet: (_domains) =>
        bulkSet(
          setDomains,
          props.mode === "edit" ? props.openRow.data.domains : domains,
          _domains
        ),
    },
    {
      name: "APK's",
      field: "apks",
      list: apks,
      removeFunc: (domain) => removeApk(domain),
      addFunc: () => addApks(),
      onChange: (e) => {
        let value = e.target.value.replace(/ /g, "");
        setApksInput(value);
      },
      inputValue: apksInput,
      icon: <DiAndroid size={25} />,
      bulkSet: (_apks) =>
        bulkSet(
          setApks,
          props.mode === "edit" ? props.openRow.data.apks : apks,
          _apks
        ),
    },
    // {
    //   name: "3rd Party Domains",
    //   list: thirdParties,
    //   removeFunc: (domain) => removeThirdPartyDomain(domain),
    //   addFunc: () => addThirdPartyDomains(),
    //   inputValue: thirdPartiesInput,
    //   onChange: (e) => setThirdPartiesInput(e.target.value),
    // },
    {
      name: "External Domains",
      field: "external_domains",
      list: externals,
      removeFunc: (domain) => removeExternalDomain(domain),
      addFunc: () => addExternalDomains(),
      inputValue: externalsInput,
      onChange: (e) => setExternalsInput(e.target.value),
      onChange: (e) => {
        let value = e.target.value.replace(/ /g, "");
        setExternalsInput(value);
      },
      icon: <FaExternalLinkAlt size={25} />,
      bulkSet: (_domains) =>
        bulkSet(
          setExternals,
          props.mode === "edit"
            ? props.openRow.data.external_domains
            : externals,
          _domains
        ),
    },
  ];

  useEffect(() => {
    setNoResults(null);
  }, [companyName]);

  useEffect(() => {
    if (props.mode === "edit") {
      if (props.reDiscover) {
        if (props.reDiscover.type === "gov") {
          submitGov(null, true, props.reDiscover);
        } else {
          submitCompany(null, true, props.reDiscover);
        }
      }
    } else {
      if (companyName) {
        submitCompany();
      }
    }
  }, [keywordPage, props.reDiscover]);

  const newSubmission = (e) => {
    e && e.preventDefault();
    if (keywordPage > 1) {
      setKeywordPage(1);
    } else {
      submitCompany(e, true);
    }
  };

  const submitCompany = (e, newSub, company) => {
    e && e.preventDefault();
    if (keywordPage > 1) {
      setKeywordPageLoading(true);
    } else {
      setSuggestedExternalDomains([]);
      setKeywordLoading(true);
    }
    let body = {};

    if (props.mode === "edit") {
      let allCompanyDomains = company.data.domains
        .concat(company.data.apks)
        .concat(company.data.external_domains);
      body = {
        domains: allCompanyDomains,
      };
    }
    axios
      .post(
        `${JSON_API}/stats/website-results/search-by-keyword-dev?system=${
          company ? company.name : companyName
        }&noalert=true&page=${newSub ? 1 : keywordPage}`,
        body,
        {
          headers: { "auth-token": props.user.Token },
        }
      )
      .then((response) => {
        if (keywordPage > 1) {
          setKeywordPageLoading(false);
          setSuggestedExternalDomains([
            ...suggestedExternalDomains,
            ...response.data.data,
          ]);
        } else {
          setKeywordData({
            total_domains: response.data.total_domains,
            total_occurrences: response.data.total_occurrences,
          });
          setKeywordLoading(false);
          setSuggestedExternalDomains(response.data.data);
          if (response.data.data.length === 0 && props.mode === "edit") {
            setNoResults("No new domains were discovered");
          }
          if (response.data && response.data.total_domains === 0) {
            setNoResults("NO RESULTS");
          }
        }
        setLoadMore(response.data.loadMore);
      })
      .catch((err) => {
        setNoResults("NO RESULTS");
        if (keywordPage > 1) {
          setKeywordPageLoading(false);
        } else {
          setKeywordLoading(false);
        }
        console.log(err);
      });
  };

  const submitGov = (e, newSub, company) => {
    e && e.preventDefault();
    if (keywordPage > 1) {
      setKeywordPageLoading(true);
    } else {
      setSuggestedExternalDomains([]);
      setKeywordLoading(true);
    }
    let body = {};
    let term = "";

    if (props.mode === "edit") {
      let allCompanyDomains = company.data.domains
        .concat(company.data.apks)
        .concat(company.data.external_domains);
      body = {
        domains: allCompanyDomains,
      };
      term = `${company.type}.${company.code}`;
    } else {
      term = `${selectedGov.type}.${selectedGov.code}`;
    }

    axios
      .post(
        `${APIFullAddress}/assets-discovery/multi-keywords?term=${term}`,
        body,
        {
          headers: { "auth-token": props.user.Token },
        }
      )
      .then((response) => {
        if (keywordPage > 1) {
          setKeywordPageLoading(false);
          setSuggestedExternalDomains([
            ...suggestedExternalDomains,
            ...response.data,
          ]);
        } else {
          setKeywordLoading(false);
          setSuggestedExternalDomains(response.data);
        }
      })
      .catch((err) => {
        setNoResults("NO RESULTS");
        if (keywordPage > 1) {
          setKeywordPageLoading(false);
        } else {
          setKeywordLoading(false);
        }
        console.log(err);
      });
  };

  const addDomain = (item, type) => {
    if (type === "domain") {
      setDomains([...domains, item]);
    } else if (type === "apk") {
      setApks([...apks, item]);
    } else if (type === "thirdParty") {
      // setThirdParties([...thirdParties, item]);
    } else if (type === "external") {
      setExternals([...externals, item]);
    }
  };

  const removeItem = (item) => {
    const foundDomain = domains.find((d) => d === item);
    const foundApk = apks.find((d) => d === item);
    // const foundThirdParty = thirdParties.find((d) => d === item);
    const foundExternal = externals.find((d) => d === item);

    if (foundDomain) {
      removeDomain(item);
    }

    if (foundApk) {
      removeApk(item);
    }

    // if (foundThirdParty) {
    //   removeThirdPartyDomain(item);
    // }

    if (foundExternal) {
      removeExternalDomain(item);
    }
  };

  const autoPopulate = () => {
    window.location.href = "#userData";

    let validDomains = suggestedExternalDomains.filter(
      (s) => !props.blacklistDomains.includes(s.domain)
    );
    const _apks = validDomains.filter((s) => s.android).map((s) => s.domain);

    const _externals = validDomains
      .filter((s) => !s.android && !s.domain.includes(companyName))
      .map((s) => s.domain);

    let _domains = validDomains
      .filter((s) => !_externals.concat(_apks).includes(s.domain))
      .map((s) => s.domain);

    if (props.type === "gov") {
      _domains = validDomains.map((s) => s.domain);
    }

    if (props.mode === "edit") {
      setDomains([...domains, ..._domains]);
      setApks([...apks, ..._apks]);
      setExternals([...externals, ..._externals]);
    } else {
      setDomains(_domains);
      setApks(_apks);
      setExternals(_externals);
    }
  };

  const getDomainUrls = (domain) => {
    let found = suggestedExternalDomains.find((s) => s.domain === domain);
    if (found.urls_list) {
      return;
    } else {
      setUrlsLoading(domain);
      axios
        .post(
          `${JSON_API}/stats/website-results/urls-by-domain-dev?system=${
            props.type === "gov" ? "gov" : companyName
          }&domain=${domain}`,
          {},
          {
            headers: { "auth-token": props.user.Token },
          }
        )
        .then((response) => {
          const added = suggestedExternalDomains.map((s) => {
            if (s.domain === domain) {
              s.urls_list = response.data;
            }
            return s;
          });
          setSuggestedExternalDomains(added);
          setUrlsLoading(null);
        })
        .catch((err) => {
          console.log(err);
          setUrlsLoading(null);
        });
    }
  };

  useEffect(() => {
    const listLimit = 120;
    if (props.user.Role !== "Admin") {
      if (
        domains.length > listLimit ||
        apks.length > listLimit ||
        externals.length > listLimit
      ) {
        window.alert("Domain list is limited to 120 domains.");
      }

      if (domains.length > listLimit) {
        let sliced = domains.slice(0, listLimit);
        setDomains(sliced);
      }

      if (apks.length > listLimit) {
        let sliced = apks.slice(0, listLimit);
        setApks(sliced);
      }

      if (externals.length > listLimit) {
        let sliced = externals.slice(0, listLimit);
        setExternals(sliced);
      }
    }
  }, [domains, apks, externals]);

  const resetForm = () => {
    setDomains([]);
    setApks([]);
    setExternals([]);
    setDomainsInput("");
    setApksInput("");
    setExternalsInput("");
    setCompanyName("");
    setLogo("");
    setSuggestions([]);
    setActiveUrlList(null);
    setKeywordDomains([]);
    setKeywordPage(1);
    setLoadMore(false);
    setSuggestedDomains([]);
    setSuggestedExternalDomains([]);
    setExternalDomains([]);
  };

  useEffect(() => {
    resetForm();
  }, [props.open]);

  useEffect(() => {
    if (activeUrlList) {
      getDomainUrls(activeUrlList);
    }
  }, [activeUrlList]);

  useEffect(() => {
    if (props.openRow) {
      setCompanyName(props.openRow.name);
      setDomains(props.openRow.data.domains);
      setApks(props.openRow.data.apks);
      setExternals(props.openRow.data.external_domains);
    }
  }, [props.openRow]);

  useEffect(() => {
    if (selectedGov) {
      setLogo(selectedGov.flag);
      submitGov(null, true, null);
    }
  }, [selectedGov]);

  return (
    <div id="newCompanyForm">
      <div
        className={
          props.mode !== "edit" ? "styledBox newCompanyBox" : undefined
        }
        style={{ backgroundColor: "lightgray" }}
      >
        {props.mode !== "edit" &&
          suggestedExternalDomains &&
          suggestedExternalDomains.length > 0 && (
            <Button
              variant="outlined"
              onClick={resetForm}
              style={{ float: "left" }}
            >
              RESET FORM
            </Button>
          )}
        {props.mode !== "edit" &&
          (props.type !== "gov" ? (
            <div className="">
              <img src={logo} onError={defaultSrc} alt="logo" />
              <FetchSuggestions
                placeholder="Search company name"
                loading={suggestionsLoading}
                setLoading={setSuggestionsLoading}
                data={suggestions}
                setData={setSuggestions}
                url={`https://autocomplete.clearbit.com/v1/companies/suggest`}
                value={companyName}
                setValue={setCompanyName}
                setLogo={setLogo}
                onSubmit={newSubmission}
                inputDisabled={
                  suggestedExternalDomains &&
                  suggestedExternalDomains.length > 0
                }
              />
            </div>
          ) : (
            <div className="">
              <img src={logo} onError={defaultSrc} alt="logo" />
              <br />
              <br />
              <GovSearch
                setSelected={setSelectedGov}
                selected={selectedGov}
                options={options}
              />
              <br />
            </div>
          ))}
        {suggestedExternalDomains && suggestedExternalDomains.length > 0 ? (
          <DomainsByKeyword
            type={props.type}
            urlsLoading={urlsLoading}
            lazyLoad={true}
            addExternalDomain={(item, type) => addDomain(item, type)}
            removeDomain={(domain) => removeItem(domain)}
            getDomainUrls={(domain) => getDomainUrls(domain)}
            keywordLoading={keywordLoading}
            addedDomains={domains.concat(apks).concat(externals)}
            activeUrlList={activeUrlList}
            setActiveUrlList={setActiveUrlList}
            keywordDomains={suggestedExternalDomains}
            loadMore={loadMore}
            keywordPageLoading={keywordPageLoading}
            page={keywordPage}
            setPage={setKeywordPage}
            tooltip={true}
            keywordData={keywordData}
          />
        ) : keywordLoading ? (
          <div
            style={{
              height: "150px",
              width: "100%",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoaderIcon type="spin" />
          </div>
        ) : (
          noResults && <h3 className="lightText">{noResults}</h3>
        )}
        {suggestedExternalDomains.length > 0 && (
          <>
            <h5 style={{ margin: "10px", color: "black" }}>OR</h5>
            <Button variant="outlined" onClick={autoPopulate}>
              AUTO POPULATE
            </Button>
          </>
        )}
      </div>
      <NewCompanyData
        type={props.type}
        mode={props.mode}
        data={props.type === "gov" ? govData : companyData}
        companyName={companyName}
        setCompanyName={(e) => setCompanyName(e.target.value)}
        addedDomains={domains.concat(apks).concat(externals)}
        saveCompany={() =>
          props.saveCompany(
            companyName,
            props.type === "gov" ? govData : companyData,
            selectedGov
          )
        }
        loading={props.loading}
        saved={props.saved}
        saveError={props.saveError}
        blacklistDomains={props.blacklistDomains}
      />
    </div>
  );
}
