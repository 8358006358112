import React from "react";

export default function Overlay(props) {
  return (
    <div className="overlay">
      <div className="overlay__inner">
        <div className="overlay__content">
          <span className="spinner"></span>
          <h1>Logging in...</h1>
        </div>
      </div>
    </div>
  );
}
