/* eslint-disable */

import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Tab from "@material-ui/core/Tab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles } from "@material-ui/core/styles";
import { animated, useSpring } from "@react-spring/web"; // web.cjs is required for IE 11 support
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { AiFillDatabase } from "react-icons/ai";
import { FaRegCalendarAlt, FaUnlockAlt } from "react-icons/fa";
import { ImMail4 } from "react-icons/im";
import {
  MdContactPhone,
  MdNoEncryption,
  MdSettingsEthernet,
} from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import LoaderIcon from "react-loader-icon";
import CreateApiClient from "../API";
import BlurryText from "./Blur";

const api = CreateApiClient();

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: "translate3d(20px,0,0)" },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function DbTabs(props) {
  const [activeTab, setActiveTab] = useState(0);
  const [machineDetails, setMachineDetails] = useState([]);
  const [machineData, setMachineData] = useState([]);
  const [blur, setBlur] = useState(true);
  const [loading, isLoading] = useState(true);
  const userRole = props.userRole;
  const tableData = props.data;

  useEffect(() => {
    userRole === "MSSP" || userRole === "User" ? setBlur(true) : setBlur(false);
  }, [blur]);

  useEffect(() => {
    if (tableData.length > 0) {
      handleCollapse(
        tableData[activeTab].id,
        tableData[activeTab]._id,
        tableData[activeTab].domain
      );
    }
  }, [tableData]);

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCollapse = async (id, _id, domain) => {
    const activeIndex = tableData
      .map(function (e) {
        return e._id;
      })
      .indexOf(_id);

    setActiveTab(activeIndex);
    isLoading(true);

    const query = {
      domain: domain,
      id: _id,
    };

    const config = {
      headers: {
        "auth-token": props.token,
      },
    };

    getMachineData(query, config);

    async function getMachineData(query, config) {
      const response = await api.fetchData("dbs/emailsearch", query, config);
      setMachineData(response);
      isLoading(false);
    }

    const shownState = machineDetails.slice();
    const index = shownState.indexOf(id);

    if (index > 0) {
      shownState.splice(index, 1);
      setMachineDetails(shownState);
    } else {
      shownState.pop();
      shownState.push(id);
      setMachineDetails(shownState);
    }
  };

  return (
    <>
      {props.data.length > 0 ? (
        <div className={classes.root + " employeesTable dbsTable"}>
          <Tabs
            id="employeesTabs"
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.tabs + " employeeTabsList"}
          >
            {props.data.map((row, index) => (
              <Tab
                className="employeeTab"
                key={index}
                onClick={() => handleCollapse(row._id, row._id, row.domain)}
                label={
                  <ul className="employee-nav">
                    <li>
                      <ImMail4 size={20} className="credsIcons" />
                      <span className="employee-label">Email:</span>
                      {blur ? (
                        row.email.includes("@") ? (
                          <span>
                            <BlurryText value={row.email.split("@")[0]} />@
                            {row.email.split("@")[1]}
                          </span>
                        ) : (
                          <BlurryText value={row.email} />
                        )
                      ) : (
                        <span>{row.email}</span>
                      )}
                    </li>
                    <li>
                      <span className="employee-label">
                        {row.count} Compromised Users
                      </span>
                    </li>
                  </ul>
                }
                {...a11yProps(index)}
              />
            ))}
            {props.loader ? (
              <LoaderIcon className="tabsLoader" type={"spinningBubbles"} />
            ) : (
              <div style={{ textAlign: "center" }}>
                {props.clientsCount > 30 && !props.finalRes ? (
                  <button className="cardBtn loadMore" onClick={props.loadMore}>
                    Load More
                  </button>
                ) : (
                  <div>
                    <h3 className="align-center">No More Results</h3>
                  </div>
                )}
              </div>
            )}
          </Tabs>
          {props.data.map((row, index) => (
            <TabPanel
              value={value}
              index={index}
              key={row._id}
              className="tabPanel"
              id="contentTab"
            >
              {machineDetails && !loading ? (
                <div>
                  <h3 className="lightText credTitle">
                    <span className="titleIcon">
                      <FaUnlockAlt size={30} />
                    </span>
                    Corporate Credentials Found: {machineData.length}
                  </h3>
                  <Table size="small" aria-label="more" className="credsTable">
                    <TableHead>
                      <TableRow className="credsRow dbsCredsRow">
                        <TableCell>
                          <span>
                            <MdSettingsEthernet
                              size={20}
                              className="credsIcons"
                            />
                          </span>
                          IP Address
                        </TableCell>
                        <TableCell>
                          <span>
                            <MdNoEncryption size={20} className="credsIcons" />
                          </span>
                          Hashed Password
                        </TableCell>
                        <TableCell>
                          <span>
                            <RiLockPasswordFill
                              size={20}
                              className="credsIcons"
                            />
                          </span>
                          Password
                        </TableCell>

                        <TableCell>
                          <span>
                            <MdContactPhone size={20} className="credsIcons" />
                          </span>
                          Phone
                        </TableCell>

                        <TableCell>
                          <span>
                            <AiFillDatabase size={20} className="credsIcons" />
                          </span>
                          Database Name
                        </TableCell>
                        <TableCell>
                          <span>
                            <FaRegCalendarAlt
                              size={20}
                              className="credsIcons"
                            />
                          </span>
                          Date Compromised
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {machineData.map((item) => (
                        <TableRow className="credsRow credsTable" key={item.id}>
                          {console.log(item)}

                          {blur && item.ip_address !== "Not Found" ? (
                            <TableCell>
                              <BlurryText value={item.ip_address} />
                            </TableCell>
                          ) : (
                            <TableCell
                              className={
                                item.ip_address === "Not Found" && "cross"
                              }
                            >
                              {item.ip_address}
                            </TableCell>
                          )}
                          {blur && item.hashed_password !== "Not Found" ? (
                            <TableCell>
                              <BlurryText value={item.hashed_password} />
                            </TableCell>
                          ) : (
                            <TableCell
                              className={
                                item.hashed_password === "Not Found" && "cross"
                              }
                            >
                              {item.hashed_password}
                            </TableCell>
                          )}
                          {blur && item.password !== "Not Found" ? (
                            <TableCell>
                              <BlurryText value={item.password} />
                            </TableCell>
                          ) : (
                            <TableCell
                              className={
                                item.password === "Not Found" && "cross"
                              }
                            >
                              {item.password}
                            </TableCell>
                          )}

                          {blur && item.phone !== "Not Found" ? (
                            <TableCell>
                              <BlurryText value={item.phone} />
                            </TableCell>
                          ) : (
                            <TableCell
                              className={item.phone === "Not Found" && "cross"}
                            >
                              {item.phone}
                            </TableCell>
                          )}
                          <TableCell>{item.database_name}</TableCell>
                          <TableCell>{item.date ? item.date : "N/A"}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              ) : (
                <LoaderIcon className="tableLoader" type={"spinningBubbles"} />
              )}
            </TabPanel>
          ))}
        </div>
      ) : (
        <>
          {userRole === "Admin" || userRole === "MSSP" ? (
            <>
              {!props.onSearch ? "" : <h2>No Results, Try Another Domain</h2>}
            </>
          ) : (
            <>
              <h2>No Results, Try Another Domain</h2>
            </>
          )}
        </>
      )}
    </>
  );
}
