/* eslint-disable */

import React, { useEffect, useRef } from "react";

export default function FileUpload(props) {
  const fileReader = new FileReader();
  const dropContainerRef = useRef(null);

  const handleOnChange = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let file = e.target.files[0];
    handleFile(file);
  };

  const handleFileDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let file = e.dataTransfer.files[0];
    handleFile(file);
  };

  const handleFile = (file) => {
    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        const output = formatToArray(text);
        let data = output.map((item) => item);
        data = data.filter((s) => s !== "");
        props.setData(data);
      };

      fileReader.readAsText(file);
    }
  };

  const formatToArray = (text) => {
    // Remove any leading/trailing white spaces
    const trimmedText = text.trim();

    // Check if the format includes commas
    if (trimmedText.includes(",")) {
      // Split by commas and remove any leading/trailing white spaces from each item
      const items = trimmedText.split(",").map((item) => item.trim());

      // Remove quotes or double quotes from each item if present
      return items.map((item) =>
        item.replace(/^"(.*)"$/, "$1").replace(/^'(.*)'$/, "$1")
      );
    } else {
      // Split by line breaks and remove any leading/trailing white spaces from each item
      const items = trimmedText.split(/\r?\n/).map((item) => item.trim());

      // Remove quotes or double quotes from each item if present
      return items.map((item) =>
        item.replace(/^"(.*)"$/, "$1").replace(/^'(.*)'$/, "$1")
      );
    }
  };

  useEffect(() => {
    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      handleFileDrop(e);
    };

    const dropContainer = dropContainerRef.current;
    dropContainer.addEventListener("dragover", handleDragOver);
    dropContainer.addEventListener("dragenter", handleDragEnter);
    dropContainer.addEventListener("dragleave", handleDragLeave);
    dropContainer.addEventListener("drop", handleDrop);

    return () => {
      dropContainer.removeEventListener("dragover", handleDragOver);
      dropContainer.removeEventListener("dragenter", handleDragEnter);
      dropContainer.removeEventListener("dragleave", handleDragLeave);
      dropContainer.removeEventListener("drop", handleDrop);
    };
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      <label
        ref={dropContainerRef}
        className="drop-container"
        htmlFor={props.id}
        onDrop={handleFileDrop}
        onDragOver={(e) => e.preventDefault()}
        onDragEnter={(e) => e.preventDefault()}
        onDragLeave={(e) => e.preventDefault()}
      >
        <span className="drop-title">
          <span className="bold lightText">Choose file </span>or drag it here
        </span>
        <input
          style={{ display: "none" }}
          onChange={handleOnChange}
          type="file"
          id={props.id}
          accept=".csv,.txt"
        />
      </label>
    </div>
  );
}
