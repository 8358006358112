/* eslint-disable */

import React from "react";
import "../../css/App.css";
import cavaLogo from "../../images/thumbnail.jpeg";

export default function GradualBoxes(props) {
  const defaultSrc = (ev) => {
    ev.target.src = cavaLogo;
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className="gradualBox" style={{ minHeight: "200px" }}>
      {!props.loading &&
        props.options.map((item, index) => (
          <div
            key={index}
            className={`delay${index + 1} cursor animate`}
            style={{
              display: "block",
              margin: "0px 2px",
              backgroundColor: "#fecd4e",
              borderRadius: "20px",
              padding: "10px",
              width: "20%",
            }}
            onClick={(e) => {
              props.onClick(e, item);
            }}
          >
            <img
              onError={defaultSrc}
              src={`https://logo.clearbit.com/${item}`}
              className={`animate delay${index + 1}`}
              style={{ width: "60px", height: "60px", borderRadius: "60px" }}
              key={index}
              alt="logo"
            ></img>
            <p
              className={`delay${index + 1} lightText animate`}
              style={{
                margin: "4px 0px",
                wordBreak: "break-all",
                fontSize: "12px",
              }}
            >
              {item}
            </p>
            {props.topEmps ? (
              <p
                className={`delay${index + 1} animate`}
                style={{ margin: "4px 0px" }}
              >
                <span
                  style={{
                    backgroundColor: "#222831",
                    color: "#fecd4e",
                    borderRadius: "20px",
                    padding: "4px 10px",
                    margin: "0px",
                  }}
                >
                  {numberWithCommas(
                    props.topEmps.find((s) => s.domain === item).count
                  )}
                  +
                </span>
                <br />
                <span style={{ color: "#222831" }}>
                  Compromised <br /> Employees
                </span>
              </p>
            ) : (
              props.topUsers && (
                <p
                  className={`delay${index + 1} animate`}
                  style={{ margin: "4px 0px" }}
                >
                  <span
                    style={{
                      backgroundColor: "#222831",
                      color: "#fecd4e",
                      borderRadius: "20px",
                      padding: "4px 10px",
                      margin: "0px",
                    }}
                  >
                    {numberWithCommas(
                      props.topUsers.find((s) => s.domain === item).count
                    )}
                    +
                  </span>
                  <br />
                  <span style={{ color: "#222831" }}>
                    Compromised <br /> Users
                  </span>
                </p>
              )
            )}
          </div>
        ))}
    </div>
  );
}
