import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import Row from "../partials/CompanyRow";

export default function CollapsibleTable(props) {
  return (
    <TableContainer component={Paper}>
      <Table
        aria-label="collapsible table"
        className="styledTable"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <TableCell />
            {props.headers.map((item, index) => (
              <TableCell key={index}>{item.name}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, index) => (
            <Row
              blacklistDomains={props.blacklistDomains}
              key={index}
              row={row}
              data={props.createCompanyData}
              open={props.openRow}
              setOpen={props.setOpenRow}
              updateCompany={(company, data, id) =>
                props.updateCompany(company, data, id)
              }
              user={props.user}
              loading={props.loading}
              saved={props.saved}
              saveError={props.saveError}
              domains={props.domains}
              apks={props.apks}
              externals={props.externals}
              companyName={props.companyName}
              setCompanyName={props.setCompanyName}
              deleteCompany={(id) => props.deleteCompany(id)}
              deleteLoading={props.deleteLoading}
              alertLoading={props.alertLoading}
              toggleAlerts={(id, value) => props.toggleAlerts(id, value)}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
