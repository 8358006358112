import React from "react";

const DownloadButton = ({ file, filename, onClick, style }) => {
  const fileData = new Uint8Array(file.data);
  const blob = new Blob([fileData], { type: "application/zip" });
  const downloadUrl = URL.createObjectURL(blob);

  return (
    <a href={downloadUrl} download={filename} onClick={onClick} style={style}>
      Download
    </a>
  );
};

export default DownloadButton;
