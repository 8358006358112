import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import React from "react";
import LoaderIcon from "react-loader-icon";
import thumbnail from "../../images/thumbnail.jpeg";

export default function Applications(props) {
  const defaultSrc = (ev) => {
    ev.target.src = thumbnail;
  };

  const sensitive_apps = [
    "+cscoe+",
    "cscoe",
    "active-directory",
    "adfs",
    "auth",
    "bitbucket",
    "ciscovpn",
    "citrix",
    "confluence",
    "cpanel",
    "dana-na",
    "extranet",
    "ftp",
    "git",
    "github",
    "gitlab",
    "hipchat",
    "imap",
    "jira",
    "kaspersky",
    "okta",
    "oracle",
    "owa",
    "pentaho",
    "ping",
    "rlogin",
    "roundcube",
    "salesforce",
    "sap",
    "sharepoint",
    "ssh",
    "sso",
    "st",
    "sts",
    "twilio",
    "vpn",
    "webex",
    "webmail",
    "webvpn",
    "zendesk",
    "zimbra",
    "zoom",
  ];

  return (
    <>
      <h1 className="chartTitle">{props.typeLabel} Applications Identified</h1>
      <br />
      {props.data &&
      props.data[0] &&
      props.data.find((s) => s.all_apps.length > 0) ? (
        <div
          id="aplicationsBox"
          style={{
            margin: "auto",
            // backgroundColor: "#222831",
            padding: "10px",
          }}
          // className="styledBox"
        >
          {props.data
            .filter((a) => a.all_apps.length > 0)
            .map((item, index) => (
              <div
                key={index}
                style={{
                  display: "inline-flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "5px 0px",
                  borderRadius: "20px",
                  backgroundColor: "#1A1C20",
                  padding: "15px",
                }}
              >
                <div style={{ display: "block", textAlign: "center" }}>
                  <img
                    id="logoImg"
                    style={{
                      backgroundColor: "#1A1C20",
                      border: "0px",
                      width: "50px",
                      height: "50px",
                      borderRadius: "50px",
                      marginRight: "5px",
                    }}
                    src={`https://logo.clearbit.com/${item.domain}`}
                    onError={defaultSrc}
                    alt="logo"
                  />
                  <p
                    style={{
                      marginBottom: "0px",
                      marginTop: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {item.domain.toUpperCase()}
                  </p>
                </div>
                <div
                  className="skills"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {item.all_apps.length > 0 ? (
                    <Stack
                      direction="row"
                      sx={{
                        display: "block",
                        overflowY: "scroll",
                        maxHeight: "7rem",
                      }}
                    >
                      {item.all_apps &&
                        item.all_apps.length > 0 &&
                        item.all_apps.map((app, index) => (
                          <Chip
                            sx={{
                              backgroundColor: sensitive_apps.includes(
                                app.keyword
                              )
                                ? "#BA1216"
                                : "#fabe44",
                              color: sensitive_apps.includes(app.keyword)
                                ? "white"
                                : "black",
                              margin: "2px",
                              padding: "2px",
                              fontSize: "12px",
                            }}
                            key={index}
                            label={app.keyword.toUpperCase()}
                            variant="filled"
                            icon={
                              <span
                                style={{
                                  color: sensitive_apps.includes(app.keyword)
                                    ? "white"
                                    : "black",
                                }}
                              >
                                {app.count}
                              </span>
                            }
                          />
                        ))}
                    </Stack>
                  ) : (
                    <div>
                      <h2>No Applications Identified</h2>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      ) : props.appsLoading ? (
        <>
          <LoaderIcon type="spin" style={{ fill: "white" }} />
          <br />
          Loading Applications Data...
        </>
      ) : (
        <h2>No data found</h2>
      )}
    </>
  );
}
