import React from "react";
import { ImLinkedin, ImTwitter } from "react-icons/im";
import logo from "../../images/hrlogo_new.png";

export default function Footer(props) {
  return (
    <div
      className="footer"
      style={{ backgroundColor: props.checkout && "transparent" }}
    >
      <div>
        <img src={logo} width="200px" alt="logo" />
      </div>
      <div className="socialIcons">
        <a
          className="linkedin footer-social"
          href="https://www.linkedin.com/company/hudson-rock/"
          target="_blank"
          rel="noreferrer"
        >
          <ImLinkedin size={25} />
        </a>
        <a
          className="footer-social twitter"
          href="https://twitter.com/RockHudsonRock"
          target="_blank"
          rel="noreferrer"
        >
          <ImTwitter size={25} />
        </a>
      </div>
      <p
        style={{
          fontSize: "12px",
          color: "white",
          marginTop: "5px",
        }}
      >
        Logo images by Clearbit
      </p>
    </div>
  );
}
