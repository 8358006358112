/* eslint-disable */

import React, { useEffect } from "react";
const {
  select,
  json,
  geoPath,
  geoLarrivee,
  scaleQuantize,
  timeFormat,
  timeParse,
} = window.d3;
const { feature } = window.topojson;

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function GlobalMap(props) {
  const parseDate = timeParse("%Y-%m-%d");
  const formatDate = timeFormat("%b %d, %Y");

  const dimensions = {
    width: 800,
    height: 655,
  };

  const sphere = { type: "Sphere" };

  const projection = geoLarrivee()
    .fitSize([dimensions.width, dimensions.height], sphere)
    .translate([dimensions.width / 2, dimensions.height / 1.45])
    .scale(140)
    .rotate([-10, 0]);

  const pathGenerator = geoPath(projection);

  const colorStops = 5;
  const colors = [
    "rgb(255,255,255,1)",
    "rgb(255,234,181,1)",
    "rgb(255,227,152,1)",
    "rgb(255,216,113,1)",
    "rgb(255,212,100,1)",
    "rgb(254,205,78,1)",
  ];
  const colorScale = scaleQuantize().domain([0, 100000]).range(colors.slice(1));

  useEffect(() => {
    const svg = select("#globalMap")
      .append("svg")
      .attr("viewBox", `0 0 ${dimensions.width} ${dimensions.height}`)
      .attr("width", dimensions.width)
      .attr("height", dimensions.height);

    svg
      .append("text")
      .text("Loading data")
      .attr("fill", "currentColor")
      .attr("font-size", "24")
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .attr("x", dimensions.width / 2)
      .attr("y", dimensions.height / 2)
      .attr("font-weight", "bold");

    svg
      .append("text")
      .attr("y", "32")
      .attr("fill", "currentColor")
      .attr("font-weight", "bold")
      .attr("font-size", "32")
      .text("Compromised Computers Worldwide");

    const timeout = setTimeout(() => {
      drawWorld();
      clearTimeout(timeout);
    }, 2000);

    async function drawWorld() {
      const world = await json(
        "https://unpkg.com/world-atlas@2.0.2/countries-110m.json"
      );

      const countries = feature(world, world.objects.countries);

      svg.select("text").transition().attr("opacity", "0").remove();

      const dataGroup = svg.append("g");

      const legendRect = {
        width: 60,
        height: 10,
        margin: 5,
      };

      const legendGroup = dataGroup
        .append("g")
        .attr("transform", "translate(0 70)");

      const worldGroup = dataGroup.append("g");

      const highlightGroup = dataGroup.append("g");

      // legendGroup
      //   .append("text")
      //   .attr("fill", "currentColor")
      //   .attr("font-size", "16")
      //   .text("Population fully vaccinated");

      const legendGroups = legendGroup
        .append("g")
        .attr("transform", "translate(0 6)")
        .selectAll("g")
        .data(colorScale.range())
        .join("g")
        .attr("transform", (_, i) => `translate(${i * legendRect.width} 0)`);

      legendGroups
        .append("rect")
        .attr("width", legendRect.width)
        .attr("height", legendRect.height)
        .attr("fill", (d) => d);

      legendGroups
        .filter((_, i) => i > 0)
        .append("path")
        .attr("fill", "none")
        .attr("stroke", "currentColor")
        .attr("stroke-width", "2")
        .attr("d", `M 0 0 v ${legendRect.height + legendRect.margin}`);

      legendGroups
        .filter((_, i) => i > 0)
        .append("text")
        .attr("y", legendRect.height + legendRect.margin + 14)
        .attr("font-size", "13")
        .attr("fill", "currentColor")
        .attr("text-anchor", "middle")
        .text((d) => {
          return numberWithCommas(colorScale.invertExtent(d)[0]);
        });

      worldGroup
        .selectAll("path")
        .data(countries.features)
        .join("path")
        .attr("d", pathGenerator)
        .attr("fill", (d) =>
          props.data[d.properties.name]
            ? colorScale(props.data[d.properties.name].value)
            : colors[0]
        )
        .attr("stroke", colors[0])
        .attr("stroke-width", "0.5");

      const highlight = Object.entries(props.data).sort(
        (a, b) => b[1].value - a[1].value
      )[0];

      const highlightPosition = {
        x: dimensions.width - 5,
        y: 65,
      };

      const textGroup = highlightGroup
        .append("g")
        .attr("text-anchor", "end")
        .attr("fill", "currentColor")
        .attr(
          "transform",
          `translate(${highlightPosition.x - 10} ${highlightPosition.y})`
        );

      const text = textGroup.append("text").attr("font-weight", "bold");

      text.append("tspan").attr("font-size", "24").text(highlight[0]);

      text
        .append("tspan")
        .attr("font-size", "34")
        .text(` ${numberWithCommas(highlight[1].value)}`);

      const highlightFeature = countries.features.find(
        (d) => d.properties.name === highlight[0]
      );

      highlightGroup
        .append("path")
        .attr("fill", "hsl(0, 80%, 68%)")
        .attr("d", pathGenerator(highlightFeature));

      const [x, y] = pathGenerator.centroid(highlightFeature);

      highlightGroup
        .append("path")
        .attr("fill", "none")
        .attr("stroke", "currentColor")
        .attr("stroke-width", "2")
        .attr("stroke-dasharray", "5 10")
        .attr("stroke-dashoffset", "5")
        .attr(
          "d",
          `M ${highlightPosition.x} ${highlightPosition.y} v 30 L ${x} ${y}`
        );

      highlightGroup
        .append("circle")
        .attr("fill", "currentColor")
        .attr("r", "3.5")
        .attr("transform", `translate(${x} ${y})`);

      highlightGroup
        .append("circle")
        .attr("fill", "currentColor")
        .attr("r", "3.5")
        .attr(
          "transform",
          `translate(${highlightPosition.x} ${highlightPosition.y})`
        );

      // /* mouseenter on the countries
      worldGroup.selectAll("path").on("mouseenter", (_, d) => {
        const { name } = d.properties;
        const country = props.data[name];
        if (country) {
          text.select("tspan").text(name);
          text
            .select("tspan:nth-of-type(2)")
            .text(` ${numberWithCommas(country.value)}`);
          textGroup
            .select("text:nth-of-type(2)")
            .text(`Dated ${formatDate(parseDate(`${country.date}`))}`);

          highlightGroup.select("path").attr("d", pathGenerator(d));

          const [x, y] = pathGenerator.centroid(d);

          highlightGroup
            .select("path:nth-of-type(2)")
            .attr(
              "d",
              `M ${highlightPosition.x} ${highlightPosition.y} v 30 L ${x} ${y}`
            );

          highlightGroup
            .select("circle")
            .attr("transform", `translate(${x} ${y})`);
        }
      });

      dataGroup
        .attr("opacity", "0")
        .transition()
        .duration(1000)
        .attr("opacity", "1");
    }
  }, []);

  return props.data && <div id="globalMap"></div>;
}
