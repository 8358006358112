/* eslint-disable */

import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import FileUpload from "./FileUpload";
import SimpleModal from "./SimpleModal";

const blacklistDomains = [
  "google.com",
  "facebook.com",
  "live.com",
  "instagram.com",
  "netflix.com",
  "discord.com",
  "roblox.com",
  "twitter.com",
  "com.facebook.katana",
  "steampowered.com",
  "paypal.com",
  "twitch.tv",
  "mega.nz",
  "epicgames.com",
  "riotgames.com",
  "steamcommunity.com",
  "com.netflix.mediaclient",
  "com.instagram.android",
  "linkedin.com",
  "spotify.com",
  "com.spotify.music",
  "yahoo.com",
  "com.discord",
  "com.roblox.client",
  "tv.twitch.android.app",
  "rockstargames.com",
  "discordapp.com",
  "minecraft.net",
  "tlauncher.org",
  "leagueoflegends.com",
  "aliexpress.com",
  "com.snapchat.android",
  "com.twitter.android",
];

export default function CompanyDataTable(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalHTML, setModalHTML] = useState("");

  const handleClose = () => {
    setModalOpen(false);
  };

  const isValidDomainsArray = (domains, field) => {
    var pattern;
    if (typeof domains === "string") {
      domains = domains.replace(/ /, "").split(",");
    } else {
      domains = domains.join(",").replace(/ /, "").split(",");
    }

    if (field === "alert_emails") {
      pattern =
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    } else {
      pattern =
        /^(?!www\.)(?!.*:\/\/)(?!.*www\.)[a-zA-Z0-9]+([-.][a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/;
    }
    for (var i = 0; i < domains.length; i++) {
      if (!pattern.test(domains[i]) || blacklistDomains.includes(domains[i])) {
        return false;
      }
    }
    return true;
  };

  const filterInvalidDomains = (domains) => {
    let pattern =
      /^(?!www\.)(?!.*:\/\/)(?!.*www\.)[a-zA-Z0-9]+([-.][a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/;

    let invalidDomains = domains.filter(
      (s) => !pattern.test(s) || blacklistDomains.includes(s)
    );
    return invalidDomains;
  };

  return (
    <TableContainer
      id="companyDataTable"
      component={Paper}
      style={{
        backgroundColor: "transparent",
        boxShadow: "none",
      }}
    >
      <Table aria-label="collapsible table" className="equal-width">
        <TableHead>
          <TableRow style={{ backgroundColor: "transparent" }}>
            {props.data.map((item, index) => (
              <TableCell
                key={index}
                align="center"
                className="lightText noBorder text-center"
              >
                {item.icon}
                <br />
                {item.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow style={{ backgroundColor: "transparent" }}>
            {props.data.map((item, index) => (
              <TableCell key={index} align="left" className="vertical-bottom">
                {item.list && (
                  <ul className="domainList darkText newCompanyDomain">
                    {item.list.map((listItem, index) => (
                      <li key={index}>
                        <button
                          className="remove darkText"
                          onClick={() => item.removeFunc(listItem)}
                          value={listItem}
                        >
                          X
                        </button>
                        {listItem}
                      </li>
                    ))}
                  </ul>
                )}
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    item.addFunc(e);
                  }}
                >
                  <Form.Control
                    value={item.inputValue}
                    maxLength="5000"
                    onChange={(e) => item.onChange(e)}
                    name={
                      item.field === "alert_emails"
                        ? "emailsToAdd"
                        : "domainsToAdd"
                    }
                    placeholder={item.name + " (Comma separated)"}
                    className="blackTextInput"
                  />
                  <br />
                  {isValidDomainsArray(item.inputValue, item.field) ? (
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className="searchBtn"
                      type="submit"
                    >
                      Add
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className="searchBtn grayBtn"
                    >
                      Add
                    </Button>
                  )}
                  {props.view !== "admin" && (
                    <div>
                      <br />
                      <br />
                      <div className="text-center lightText">
                        <h6>OR</h6>
                      </div>
                      <FileUpload
                        list={item.list}
                        id={`files_${index}`}
                        label="UPLOAD CSV"
                        setData={(data) => {
                          if (isValidDomainsArray(data)) {
                            item.bulkSet(data);
                          } else {
                            let invalid = filterInvalidDomains(data);

                            let filtered = data.filter(
                              (s) => !invalid.includes(s)
                            );

                            item.bulkSet(filtered);
                            const html = (
                              <div>
                                <span className="cursor" onClick={handleClose}>
                                  X
                                </span>
                                <h2>
                                  Your domains were added, however some of them
                                  are invalid or too large to be monitored:
                                </h2>
                                <br />
                                <ul style={{ listStyle: "none" }}>
                                  {invalid.map((item, index) => (
                                    <li key={index}>{item}</li>
                                  ))}
                                </ul>
                              </div>
                            );
                            setModalHTML(html);
                            setModalOpen(true);
                          }
                        }}
                      />
                    </div>
                  )}
                </form>
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
      <SimpleModal
        open={modalOpen}
        html={modalHTML}
        halfWidth={true}
        handleClose={handleClose}
      />
    </TableContainer>
  );
}
