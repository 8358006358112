/* eslint-disable */

import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router";
import { authAddress } from "../Config";
import "../css/App.css";
import MobileSearchBtn from "./MobileSearchBtn";
import SearchBtn from "./SearchBtn";
import SearchBy from "./SearchBy";

function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  const handleClickInside = (event) => {
    setIsComponentVisible(true);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    let domainInput = document.getElementById("formBasicEmail");
    if (domainInput) {
      domainInput.addEventListener("click", handleClickInside, true);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}

const searchTypes = [
  {
    value: "domain",
    label: "Domain",
  },
  {
    value: "company",
    label: "Company",
  },
  {
    value: "stealer",
    label: "Stealer",
  },
  {
    value: "email",
    label: "Email",
  },
  {
    value: "ip",
    label: "IP",
  },
];

function Search(props) {
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const activeDomain = location.pathname.split("/")[2];
  const { ref, isComponentVisible } = useComponentVisible(true);
  const [searchType, setSearchType] = useState({});
  const [userCompanies, setUserCompanies] = useState([]);
  const [company, setCompany] = useState("");
  const [options, setOptions] = useState([]);

  let defaultSearchType;

  const config = {
    headers: {
      "auth-token": props.user.Token
        ? props.user.Token
        : JSON.parse(props.user).Token,
    },
  };

  useEffect(() => {
    let user = props.user.ui_permissions ? props.user : JSON.parse(props.user);

    setOptions(user.search_config);

    // let _options = options;
    // const stealerObj = { value: "stealer", label: "Stealer" };
    // const emailObj = { value: "email", label: "Email" };

    // if (
    //   user.ui_permissions &&
    //   (user.ui_permissions.includes("search-by-stealer") ||
    //     user.ui_permissions.includes("ai_analysis")) &&
    //   !_options.find((o) => o.value === "stealer")
    // ) {
    //   _options.push(stealerObj);
    //   setOptions(_options);
    // }

    // if (user?.ui_permissions?.includes("search-by-stealer")) {
    //   _options.push(emailObj);
    //   setOptions(_options);
    // }

    if (path === "search-by-company") {
      defaultSearchType = searchTypes[1];
    } else if (path === "search-by-stealer") {
      defaultSearchType = searchTypes[2];
    } else if (path === "search-by-email") {
      defaultSearchType = searchTypes[3];
    } else if (path === "search-by-ip") {
      defaultSearchType = searchTypes[4];
    } else {
      defaultSearchType = user.search_config[0];
    }
    setSearchType(defaultSearchType);
  }, []);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const getUserCompanies = async () => {
    const url = `${authAddress}/get-user-companies?options=true`;
    await axios
      .post(url, {}, config)
      .then((res) => {
        let data = res.data;
        let options = [];
        options.push({
          value: "addCompany",
          label: "ADD A NEW COMPANY",
        });
        if (data.length > 0) {
          data.map((item) => {
            options.push({
              value: item.name,
              label: capitalizeFirstLetter(item.name),
              id: item._id,
            });
          });
          if (activeDomain && path === "search-by-company") {
            setCompany(
              options.find((o) => o.id && o.id.toString() === activeDomain)
            );
          } else {
            setCompany(options[1]);
          }
        }
        setUserCompanies(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUserCompanies();
  }, [props.user.companies]);

  return (
    <div>
      <div className="formDiv">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            window.location.href = `/search-by-domain/${props.selectedDomain}`;
          }}
          autoComplete="off"
          className="freeSearchForm"
        >
          {options && (
            <Form.Group className="mainSearchBy">
              <SearchBy
                placeholder="Search By"
                searchType={searchType}
                options={options}
                changeSearchType={(type) => {
                  setSearchType(type);
                }}
              />
            </Form.Group>
          )}
          {searchType.value === "domain" ? (
            <Form.Group className="searchForm" controlId="formBasicEmail">
              <Form.Control
                maxLength="999"
                value={props.value.toLowerCase()}
                onFocus={props.onChange}
                onChange={props.onChange}
                placeholder={
                  activeDomain &&
                  path === "search-by-domain" &&
                  activeDomain.includes(".")
                    ? activeDomain
                    : "example.com"
                }
                required={true}
                name="domain"
              />

              {props.suggestions.length > 0 && (
                <div className="suggestionsBox" ref={ref}>
                  {isComponentVisible && (
                    <div>
                      {props.suggestions.map((company, index) => (
                        <Form.Text
                          value={company.domain}
                          onClick={props.pickDomain}
                          key={index}
                          className="text-muted companyRow"
                        >
                          <img
                            alt={company.domain}
                            value={company.domain}
                            className="companyLogo"
                            src={company.logo}
                          />
                          {company.name}
                          <p value={company.domain} className="companyDomain">
                            {company.domain}
                          </p>
                        </Form.Text>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </Form.Group>
          ) : searchType.value === "company" ? (
            <Form.Group
              className="mainSearchBy selectCompany"
              controlId="formBasicEmail"
            >
              <SearchBy
                placeholder="Choose Company"
                searchType={company}
                options={userCompanies}
                changeSearchType={(company) => {
                  if (company.value === "addCompany") {
                    window.location.href = "/companies";
                  } else {
                    setCompany(company);
                  }
                }}
              />
            </Form.Group>
          ) : searchType.value === "stealer" ? (
            <Form.Group className="searchForm" controlId="formBasicEmail">
              <Form.Control
                maxLength="999"
                value={props.stealer}
                onChange={props.onStealerChange}
                placeholder={
                  activeDomain && path === "search-by-stealer"
                    ? activeDomain
                    : "Enter a Stealer ID"
                }
                required={true}
                name="stealer"
              />
            </Form.Group>
          ) : searchType.value === "email" ? (
            <Form.Group className="searchForm" controlId="formBasicEmail">
              <Form.Control
                maxLength="999"
                value={props.email}
                onChange={props.onEmailChange}
                placeholder={
                  activeDomain && path === "search-by-email"
                    ? activeDomain
                    : "Enter an Email Address"
                }
                required={true}
                name="email"
              />
            </Form.Group>
          ) : (
            searchType.value === "ip" && (
              <Form.Group className="searchForm" controlId="formBasicEmail">
                <Form.Control
                  maxLength="999"
                  value={props.ip}
                  onChange={props.onIpChange}
                  placeholder={
                    activeDomain && path === "search-by-ip"
                      ? activeDomain
                      : "Enter an IP Address"
                  }
                  required={true}
                  name="ip"
                />
              </Form.Group>
            )
          )}

          <SearchBtn
            searchType={searchType}
            company={company}
            domain={props.selectedDomain}
            role={props.role}
            stealer={props.stealer}
            email={props.email}
            ip={props.ip}
            className="desktopSearchBtn"
          />
          <MobileSearchBtn
            domain={props.selectedDomain}
            role={props.role}
            className="mobileSearchBtn"
          />
        </Form>
      </div>
      {/* <div>{!props.loader && props.machines < 0 && <h2>No Results</h2>}</div> */}
    </div>
  );
}

export default Search;
