/* eslint-disable */

import React, { useEffect, useState } from "react";
import {
  AiOutlineClose,
  AiOutlineCopy,
  AiOutlineDownload,
} from "react-icons/ai";
import { GiCheckMark } from "react-icons/gi";
import LoaderIcon from "react-loader-icon";
import DocxViewer from "./DocxViewer";

function base64ToBlob(base64, mimeType) {
  // Decode the Base64 string
  const byteCharacters = atob(base64);
  // Create an array of byte values
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  // Convert the array to a typed array
  const byteArray = new Uint8Array(byteNumbers);
  // Create a blob from the typed array and specify the MIME type
  const blob = new Blob([byteArray], { type: mimeType });

  return blob;
}

function textToBlobUrl(text) {
  // Create a Blob from the text data
  const blob = new Blob([text], { type: "text/plain" });

  // Create a URL for the Blob
  const fileUrl = URL.createObjectURL(blob);
  // window.open(fileUrl);
  return fileUrl;
}

function pdfToBlobUrl(pdfData) {
  // Create a Blob from the PDF data
  const blob = new Blob([pdfData], { type: "application/pdf" });

  // Create a URL for the Blob
  const fileUrl = URL.createObjectURL(blob);

  return fileUrl;
}

const FileViewer = ({
  fileUrl,
  onClose,
  fileContent,
  filename,
  isVisible,
  allowCopy,
  loading,
}) => {
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [animationClass, setAnimationClass] = useState("");
  const [copied, isCopied] = useState(false);

  const handleCopy = async (txt) => {
    await navigator.clipboard.writeText(txt);
    isCopied(true);
    setTimeout(() => {
      isCopied(null);
    }, 2000);
  };

  //   useEffect(() => {
  //     const fetchContent = async () => {
  //       if (!fileUrl) return;

  //       setIsLoading(true);
  //       try {
  //         const response = await fetch(fileUrl);
  //         const text = await response.text(); // Use .json() if you expect JSON and want to parse it
  //         setContent(text);
  //       } catch (error) {
  //         console.error("Failed to fetch file content:", error);
  //         setContent("Error fetching file content");
  //       } finally {
  //         setIsLoading(false);
  //       }
  //     };

  //     fetchContent();
  //   }, [fileUrl]);

  useEffect(() => {
    if (isVisible) {
      setAnimationClass("slide-in-right");
    }
  }, [isVisible]);

  const handleClose = () => {
    setAnimationClass("slide-out-right");

    setTimeout(() => {
      if (typeof onClose === "function") {
        onClose();
      }
    }, 500);
  };

  const getFileType = (url) => {
    const extension = url.split(".").pop().toLowerCase();
    if (["png", "jpeg", "gif", "bmp", "jpg"].includes(extension)) {
      return "image";
    } else if (extension === "pdf") {
      return "pdf";
    } else if (extension === "json") {
      return "json";
    } else if (
      !["png", "jpeg", "gif", "bmp", "jpg", "xlsx", "docx", "pdf"].includes(
        extension
      )
    ) {
      return "text";
    } else if (["xlsx", "docx"].includes(extension)) {
      // Simple fallback for complex types, consider using a library or conversion for better handling
      return "complex";
    } else {
      return "unknown";
    }
  };

  const renderFileContent = () => {
    const fileType = getFileType(fileUrl);
    switch (fileType) {
      case "image":
        return (
          <img
            src={fileContent}
            alt="Document"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        );
      case "pdf":
        const pdfBlob = base64ToBlob(fileContent, "application/pdf");
        const pdfUrl = URL.createObjectURL(pdfBlob);
        // For local files, consider using a library like pdf.js
        return <iframe src={pdfUrl} width="100%" height="500px" />;
      case "text":
        return (
          <pre style={{ minHeight: "90%", textWrap: "balance" }}>
            {fileContent.length > 1 ? fileContent : "File is empty"}
          </pre>
        );
      case "json":
        return (
          <pre style={{ minHeight: "90%", textWrap: "balance" }}>
            {JSON.stringify(fileContent)}
          </pre>
        );
      case "complex":
        const docxBlob = base64ToBlob(
          fileContent,
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        );
        const docxUrl = URL.createObjectURL(docxBlob);
        return <DocxViewer docxUrl={docxUrl} />;
      default:
        return <p>Unsupported file type.</p>;
    }
  };

  const handleDownload = (content, filename) => {
    const _url = textToBlobUrl(content);
    // Simple download example
    const a = document.createElement("a");
    a.href = _url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div
      className={`fileViewer ${animationClass}`}
      style={{
        position: "relative",
        top: "1rem",
        right: "3rem",
        padding: "0rem 3rem 3rem 2rem",
        border: "2px solid",
        backgroundColor: "#000000cc",
        color: "#FFA500",
        // color: "#1DC121",
        width: "100%",
        height: "80vh",
        overflowY: "scroll",
        borderRadius: "20px",
        zIndex: "9",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          position: "sticky",
          backgroundColor: "#000000cc",
          top: 0,
          left: 0,
          padding: "1rem 0rem 1rem",
        }}
      >
        <div>
          <AiOutlineClose
            className="custom-cursor-hover"
            onClick={handleClose}
            style={{ marginRight: 10 }}
          />
          {allowCopy && (
            <>
              {copied ? (
                <GiCheckMark />
              ) : (
                <AiOutlineCopy
                  className="custom-cursor-hover"
                  onClick={() => handleCopy(fileContent)}
                  style={{ marginRight: 10 }}
                />
              )}
            </>
          )}
          {allowCopy && (
            <AiOutlineDownload
              className="custom-cursor-hover"
              onClick={() => handleDownload(fileContent, filename)}
            />
          )}
        </div>
        <div>
          <span>{filename}</span>
        </div>
      </div>
      {!loading ? (
        fileUrl && renderFileContent()
      ) : (
        <LoaderIcon
          type="spin"
          style={{
            fill: "#FFA500",
            width: "56px",
            height: "65vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      )}
    </div>
  );
};

export default FileViewer;
