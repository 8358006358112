/* eslint-disable */

import axios from "axios";
import { createBrowserHistory } from "history";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import CreateApiClient from "../API";
import { APIAlertsAddress, APIFullAddress, authAddress } from "../Config";
import "../css/App.css";
import AppBarNew from "./AppBar";
import ErrorBoundary from "./Error";
import ScrollableTabsButtonForce from "./HorizontalTabs";
import Footer from "./partials/Footer";

const history = createBrowserHistory();

const api = CreateApiClient();

function Domain(props) {
  const currentYear = new Date().getFullYear();
  const [employeesCities, setEmployeesCities] = useState([]);
  const [clientsCities, setClientsCities] = useState([]);
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const domain = location.pathname.split("/")[2];
  const [user, setUser] = useState(props.user);
  const [email, setEmail] = useState(props.email);
  const [domains, setDomains] = useState(props.domains);
  const [role, setRole] = useState(props.role);
  const [token, setToken] = useState(props.token);
  const [monitoredDomains, setMonitoredDomains] = useState(
    props.monitoredDomains
  );
  const [passwordStats, setPasswordStats] = useState([]);
  const [loading, setLoading] = useState();
  const [loadingCounters, setLoadingCounters] = useState();
  const [clientsCount, setClientssCount] = useState(0);
  const [stealersCount, setStealersCount] = useState(0);
  const [employeesCount, setEmployeesCount] = useState(0);
  const [partiesCount, setPartiesCount] = useState(0);
  const [androidsCount, setAndroidsCount] = useState(0);
  const [dbsCount, setDbsCount] = useState(0);
  const [timeLabels, setTimeLabels] = useState([]);
  const [counts, setCounts] = useState([]);
  const [resData, setResData] = useState([]);
  const [employeesStats, setEmployeesStats] = useState([]);
  const [clientsStats, setClientsStats] = useState([]);
  const [employeesUrls, setEmployeesUrls] = useState([]);
  const [clientsUrls, setClientsUrls] = useState([]);
  const [employeesUrlsCount, setEmployeesUrlsCount] = useState([]);
  const [clientsUrlsCount, setClientsUrlsCount] = useState([]);
  const [cities, setCities] = useState([]);
  const [activeDomains, setActiveDomains] = useState();
  const [config, setConfig] = useState("");
  const [data, setData] = useState([]);
  const [empLength, setEmpLength] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [resLength, setResLength] = useState(0);
  const [machines, setMachines] = useState(0);
  const [finalRes, setFinalRes] = useState(false);
  const [loader, setLoader] = useState(false);
  const [section, setSection] = useState("");
  const [monthly, isMonthly] = useState(true);
  const [year, setYear] = React.useState("All Time");
  const [yearsOptions, setYearsOptions] = useState([]);
  const [monitorCount, setMonitorCount] = useState("");
  const [allMonitoredCounts, setAllMonitoredCounts] = useState([]);
  const [mapLoading, setMapLoading] = useState(false);
  const [chartLoader, setChartLoader] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [assetsCount, setAssetsCount] = useState(0);
  const [assets, setAssets] = useState([]);
  const [csvUrlsLoading, setCsvUrlsLoading] = useState(null);
  const [csvUrls, setCsvUrls] = useState([]);
  const [externalCount, setExternalCount] = useState(0);
  const [externalDomain, setExternalDomain] = useState("");
  const [inPageLoading, setInPageLoading] = useState(false);
  const [avStats, setAvStats] = useState([]);
  const [appStats, setAppStats] = useState([]);
  const selectors = ["User", "Customer"];
  const [stealerFamilyStats, setStealerFamilyStats] = useState([]);
  const [cookieStats, setCookieStats] = useState([]);
  const [cookieLoading, setCookieLoading] = useState(false);
  const [selectedExternals, setSelectedExternals] = useState([]);

  const [passwordsLoading, setPasswordsLoading] = useState(false);
  const [avLoading, setAvLoading] = useState(false);
  const [sfLoading, setSfLoading] = useState(false);
  const [appsLoading, setAppsLoading] = useState(false);
  const [currentCompany, setCurrentCompany] = useState([]);
  const [assetsType, setAssetsType] = useState("employees");
  const [currentCsvDomain, setCurrentCsvDomain] = useState(null);
  const [externalSuggestions, setExternalSuggestions] = useState([]);
  const [responseError, setResponseError] = useState(false);
  const [successNotify, setSuccessNotify] = useState(false);
  const [notifyLoading, setNotifyLoading] = useState(false);
  const [urlsLoading, setUrlsLoading] = useState(false);
  const [floored, setFloored] = useState({});

  let company, searchMode;
  if (path === "search-by-company") {
    company = domain;
    searchMode = "company";
  } else {
    searchMode = "domain";
  }

  const cancelSearch = useRef(null);
  const handleLogOut = () => {
    localStorage.clear();
    redirectToLoginPage();
  };

  const redirectToLoginPage = () => {
    history.push("/api/auth/sso/auth0/login");
    window.location.reload();
  };

  const handleYearChange = (event) => {
    if (event.target.value === "All Time") {
      switchToAllTimeChart();
    } else {
      setTimelineChart(event.target.value, user);
    }
    setYear(event.target.value);
  };

  const switchToAllTimeChart = () => {
    setAllTimeChart(user);
    isMonthly(false);
  };

  const switchToMonthlyChart = () => {
    isMonthly(true);
    getStats(`stats?frame=monthly&year=${year}`, user);
  };

  const setTimelineChart = (year, loggedInUser) => {
    getStats(`stats?frame=monthly&year=${year}`, loggedInUser);
  };

  const setAllTimeChart = (loggedInUser) => {
    getStats(`stats?frame=all&currentyear=${currentYear}`, loggedInUser);
  };

  const domainsChecker = (arr, target) => target.every((v) => arr.includes(v));

  const fixPath = (user) => {
    history.push(`/search-by-domain/${user.all_domains.join("&")}`);
    window.location.reload();
  };

  const resetStats = () => {
    setCounts([]);
    setTimeLabels([]);
    setEmployeesStats([]);
    setClientsStats([]);
    setEmployeesCities([]);
    setClientsCities([]);
    setAvStats([]);
    setAppStats([]);
    setStealerFamilyStats([]);
    setCookieStats([]);
    setPasswordStats([]);
    setEmployeesUrls([]);
    setEmployeesUrlsCount(0);
    setClientsUrls([]);
    setExternalSuggestions([]);
    setClientsUrlsCount(0);
    setResponseError(false);
    setSuccessNotify(false);
    setNotifyLoading(false);
  };

  useEffect(() => {
    resetStats();
    let loggedInUser;
    typeof user === "string"
      ? (loggedInUser = JSON.parse(user))
      : (loggedInUser = user);
    if (loggedInUser) {
      const time = Date.now().toString().substr(0, 10);
      const nowNum = Number(time);
      if (props.expiry < nowNum) {
        props.handleLogOut();
      } else {
        setUser(loggedInUser);
        setEmail(loggedInUser.email);
        setDomains(loggedInUser.Domains);
        setRole(loggedInUser.Role);
        setToken(loggedInUser.Token);
        setMonitoredDomains(loggedInUser.monitored_domains);
        getPresets(loggedInUser);

        if (loggedInUser.companies) {
          let current = loggedInUser.companies.find(
            (s) => s._id.toString() === company
          );
          setCurrentCompany(current);
        }

        if (searchMode === "company" && company && loggedInUser.companies) {
          let foundCompany = loggedInUser.companies.find(
            (s) => s._id.toString() === company
          );
          if (foundCompany) {
            let companyExternals = foundCompany.data.external_domains.map(
              (item) => {
                return {
                  value: item,
                  label: item,
                };
              }
            );
            setSelectedExternals(companyExternals);
          }
        } else if (
          loggedInUser.external_domains &&
          loggedInUser.external_domains.length > 0
        ) {
          let _externals = loggedInUser.external_domains.map((item) => {
            let obj = {
              value: item.domain,
              label: item.domain,
            };
            return obj;
          });

          setSelectedExternals(_externals);
        }

        if (selectors.includes(loggedInUser.Role)) {
          if (!domainsChecker(loggedInUser.all_domains, domain.split("&"))) {
            return fixPath(loggedInUser);
          }
        }
      }
    }
  }, [domain]);

  async function getPresets(loggedInUser) {
    setLoading(true);
    setChartLoader(true);
    setMapLoading(true);
    setUrlsLoading(true);
    setPasswordsLoading(true);
    setAppsLoading(true);
    setCookieLoading(true);
    setSfLoading(true);
    setAvLoading(true);

    await getCounters(loggedInUser);

    if (year !== "All Time") {
      await getStats(`stats?frame=monthly&year=${year}`, loggedInUser);
    } else {
      await getStats(
        `stats?frame=all&currentyear=${currentYear}`,
        loggedInUser
      );
    }
    getStealerFamilyStats("stats/stealer-family", loggedInUser);
    getCookieStats("stats/cookies", loggedInUser);

    await getUrls("stats/urls", loggedInUser);
    await getPasswords("stats/passwords", loggedInUser);
    getAvStats("stats/anti-viruses", loggedInUser);
    getApplicationStats("stats/applications", loggedInUser);

    if (location.hash === "#assets") {
      window.location.href = "#assets";
    }
  }

  async function getCounters(loggedInUser) {
    try {
      const response = await api.fetchData(
        "counts/domain",
        {
          domain: domain,
          monitoredDomains: monitoredDomains,
          company: searchMode === "company" ? company : null,
          keyword_suggestions: true,
        },
        {
          headers: {
            "auth-token": token,
          },
        }
      );

      if (!response.stealers && response.stealers !== 0) {
        setResponseError(true);
      }

      const totalStealers = Number(response.stealers);
      const totalClients = Number(response.clients);
      const totalEmployees = Number(response.employees);
      const totalParties = Number(response.parties);
      const monitoredStealersCount = Number(response.monitoredStealersCount);
      const totalExternalCount = Number(response.externalCount);
      const totalAssetsCount = Number(response.assets);
      setFloored(response.floored);

      setStealersCount(totalStealers);
      setLoading(false);

      if (
        response.keyword_suggestions &&
        response.keyword_suggestions.length > 0
      ) {
        setExternalSuggestions(response.keyword_suggestions);
        let _externals = response.keyword_suggestions.map((item) => {
          let obj = {
            value: item.domain,
            label: item.domain,
          };
          return obj;
        });
        setSelectedExternals(_externals);
      }

      setAssetsCount(totalAssetsCount);
      setExternalCount(totalExternalCount);
      setClientssCount(totalClients);
      setEmployeesCount(totalEmployees);
      setPartiesCount(totalParties);
      setMonitorCount(monitoredStealersCount);
      typeof response.androids !== "boolean"
        ? setAndroidsCount(Number(response.androids))
        : setAndroidsCount(response.androids);

      let _type = totalEmployees > 0 ? "employees" : "users";
      getMapData(`stats/ips?type=${_type}`, loggedInUser);
    } catch (error) {
      console.log(error);
    }
  }

  const getStats = async (query, user) => {
    setChartLoader(true);
    if (query == `stats?frame=monthly&year=${year}`) {
      isMonthly(true);
    }
    setSection(query);
    let domainQuery;
    let config = {
      headers: {
        "auth-token": user.Token,
      },
    };

    if (domain.includes("&")) {
      domainQuery = domain.split("&");
    } else {
      domainQuery = domain;
    }
    let body = {
      domain: domainQuery,
      company: searchMode === "company" ? company : null,
    };

    const response = await api.fetchData(query, body, config);
    if (response) {
      if (response.datasets && response.datasets.length > 0) {
        setCounts(response.datasets);
      }
      setYearsOptions(response.years);
      setTimeLabels(response.labels);
      setEmployeesStats(response.employeesCount);
      setClientsStats(response.clientsCount);
    } else {
      setCounts([]);
      setTimeLabels([]);
      setEmployeesStats([]);
      setClientsStats([]);
    }
    setChartLoader(false);
  };

  const getMapData = async (query, user) => {
    setMapLoading(true);
    let domainQuery;
    let config = {
      headers: {
        "auth-token": user.Token,
      },
    };

    if (domain.includes("&")) {
      domainQuery = domain.split("&");
    } else {
      domainQuery = domain;
    }
    let body = {
      domain: domainQuery,
      company: searchMode === "company" ? company : null,
    };

    const response = await api.fetchData(query, body, config);
    if (response.length > 0) {
      setCities(response);
      splitIps(response);
    }
    setMapLoading(false);
  };

  const splitIps = (data) => {
    let employeesCities = [];
    let clientsCities = [];
    data.map((item) => {
      item.type === "employee"
        ? employeesCities.push(item)
        : clientsCities.push(item);
    });
    setEmployeesCities(employeesCities);
    setClientsCities(clientsCities);
  };

  const getUrls = async (query, user) => {
    setUrlsLoading(true);
    let domainQuery;
    let config = {
      headers: {
        "auth-token": user.Token,
      },
    };

    if (domain.includes("&")) {
      domainQuery = domain.split("&");
    } else {
      domainQuery = domain;
    }
    let body = {
      domain: domainQuery,
      company: searchMode === "company" ? company : null,
    };

    const response = await api.fetchData(query, body, config);
    setEmployeesUrls(response.employees_urls);
    setEmployeesUrlsCount(response.employees_count);
    setClientsUrls(response.clients_urls);
    setClientsUrlsCount(response.clients_count);
    setUrlsLoading(false);
  };

  const getPasswords = async (query, user) => {
    setPasswordsLoading(true);
    let domainQuery;
    let config = {
      headers: {
        "auth-token": user.Token,
      },
    };

    if (domain.includes("&")) {
      domainQuery = domain.split("&");
    } else {
      domainQuery = [domain];
    }
    let body = {
      domains: domainQuery,
      company: searchMode === "company" ? company : null,
    };

    const response = await api.fetchData(query, body, config);
    setPasswordStats(response);
    setPasswordsLoading(false);
  };

  const getApplicationStats = async (query, user) => {
    setAppsLoading(true);
    let domainQuery;
    let config = {
      headers: {
        "auth-token": user.Token,
      },
    };

    if (domain.includes("&")) {
      domainQuery = domain.split("&");
    } else {
      domainQuery = [domain];
    }
    let body = {
      domains: domainQuery,
      company: searchMode === "company" ? company : null,
    };

    const response = await api.fetchData(query, body, config);
    setAppStats(response);
    setAppsLoading(false);
  };

  const getCookieStats = async (query, user) => {
    setCookieLoading(true);
    let domainQuery;
    let config = {
      headers: {
        "auth-token": user.Token,
      },
    };

    if (domain.includes("&")) {
      domainQuery = domain.split("&");
    } else {
      domainQuery = [domain];
    }
    let body = {
      domains: domainQuery,
      company: searchMode === "company" ? company : null,
    };

    const response = await api.fetchData(query, body, config);
    setCookieStats(response);
    setCookieLoading(false);
  };

  const getStealerFamilyStats = async (query, user) => {
    setSfLoading(true);
    let domainQuery;
    let config = {
      headers: {
        "auth-token": user.Token,
      },
    };

    if (domain.includes("&")) {
      domainQuery = domain.split("&");
    } else {
      domainQuery = [domain];
    }
    let body = {
      domains: domainQuery,
      company: searchMode === "company" ? company : null,
    };

    const response = await api.fetchData(query, body, config);
    setStealerFamilyStats(response);
    setSfLoading(false);
  };

  const getAvStats = async (query, user) => {
    setAvLoading(true);
    let domainQuery;
    let config = {
      headers: {
        "auth-token": user.Token,
      },
    };

    if (domain.includes("&")) {
      domainQuery = domain.split("&");
    } else {
      domainQuery = [domain];
    }
    let body = {
      domains: domainQuery,
      company: searchMode === "company" ? company : null,
    };

    const response = await api.fetchData(query, body, config);
    setAvStats(response);
    setAvLoading(false);
  };

  const getMonitoredDomains = async () => {
    setDataLoading(true);
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      const time = Date.now().toString().substr(0, 10);
      const nowNum = Number(time);
      if (foundUser.expiry < nowNum) {
        handleLogOut();
      } else {
        setUser(foundUser);
        let config = {
          headers: {
            "auth-token": token,
          },
        };

        await axios
          .post(`${authAddress}/getmonitoreddomains`, {}, config)
          .then((res) => {
            setMonitoredDomains(res.data);
          })
          .catch((err) => console.log(err));

        const response = await api.fetchData(
          "counts/countallmonitored",
          { domains: monitoredDomains },
          config
        );
        setAllMonitoredCounts(response);
      }
    }
    setDataLoading(false);
  };

  const getAssetDiscovery = async (type, inPageLoading) => {
    setCsvUrls([]);
    setCurrentCsvDomain(null);
    setAssetsType(type);
    if (inPageLoading) {
      setInPageLoading(true);
    } else {
      setDataLoading(true);
    }
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      const time = Date.now().toString().substr(0, 10);
      const nowNum = Number(time);
      if (foundUser.expiry < nowNum) {
        handleLogOut();
      } else {
        setUser(foundUser);
        let config = {
          headers: {
            "auth-token": foundUser.Token,
          },
        };
        let domainQuery, body, url;
        if (domain.includes("&")) {
          domainQuery = domain.split("&");
          body = {
            domains: domainQuery,
          };
        } else {
          body = {
            domains: [domain],
            company: searchMode === "company" ? company : null,
          };
        }
        url = `${APIFullAddress}/assets-discovery?type=${type}`;

        await axios
          .post(url, body, config)
          .then(async (res) => setAssets(res.data))
          .catch((err) => err);
      }
    }
    if (inPageLoading) {
      setInPageLoading(false);
    } else {
      setDataLoading(false);
    }
  };

  const exportUrls = async (domain, type, typeList) => {
    setCsvUrlsLoading(`${domain}_${type}`);
    setCsvUrls([]);
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      const time = Date.now().toString().substr(0, 10);
      const nowNum = Number(time);
      if (foundUser.expiry < nowNum) {
        handleLogOut();
      } else {
        setUser(foundUser);
        let config = {
          headers: {
            "auth-token": foundUser.Token,
          },
        };
        let body, url;
        if (domain !== "all") {
          setCurrentCsvDomain(`${domain}_${type}`);
          body = {
            domains: [domain],
            company: searchMode === "company" ? currentCompany.name : null,
          };
        } else {
          setCurrentCsvDomain("all");
          body = {
            domains: assets.data[`${typeList}`].map((s) => s.domain),
            company: searchMode === "company" ? currentCompany.name : null,
          };
        }
        url = `${APIFullAddress}/assets-discovery/export-urls?csv=true&type=${type}`;

        await axios
          .post(url, body, config)
          .then(async (res) => {
            setCsvUrls(res.data);
          })
          .catch((err) => err);
        clickOnCsv(`${domain}_${type}_csv`);
      }
    }
    setCsvUrlsLoading(null);
  };

  const clickOnCsv = (id) => {
    let csvElement = document.getElementById(id);
    if (csvElement) {
      csvElement.click();
    }
  };

  const getData = async (
    oldData,
    type,
    page,
    unlock,
    switched,
    inPageLoading
  ) => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      const time = Date.now().toString().substr(0, 10);
      const nowNum = Number(time);
      if (foundUser.expiry < nowNum) {
        handleLogOut();
      } else {
        setUser(foundUser);
        setDomains(foundUser.Domains);
        setRole(foundUser.Role);
        setToken(foundUser.Token);
        setSection(type);
        let domainQuery, keywordQuery;

        if (type.includes("external-domains")) {
          domainQuery = selectedExternals;
        } else if (domain.includes("&")) {
          domainQuery = domain.split("&");
        } else {
          domainQuery = domain;
        }

        if (domain.includes("&")) {
          keywordQuery = domain.split("&")[0].split(".")[0];
        } else {
          keywordQuery = domain.split(".")[0];
        }

        let query = {
          domain: domainQuery,
          page: page,
          company: searchMode === "company" ? company : null,
          keyword: keywordQuery,
        };

        if (page === 0) {
          if (inPageLoading) {
            setInPageLoading(true);
          } else {
            setDataLoading(true);
          }
        } else {
          setLoader(true);
        }

        cancelRequest();

        let config = {
          headers: {
            "auth-token": foundUser.Token,
          },
          cancelToken: new axios.CancelToken(
            (cancel) => (cancelSearch.current = cancel)
          ),
        };

        setConfig(config);

        const response = await api.fetchData(type, query, config);
        if (response && response.message === "New search initiated") {
          console.log("new search");
        } else if (response) {
          if (response.length > 0) {
            if (data && data.length > 0) {
              if (page === 0) {
                setData(response);
              } else {
                setData([...oldData, ...response]);
              }
            } else {
              setData([...response]);
            }
            setFinalRes(false);
            setHasNextPage(response[0].hasNextPage);
            setResLength(response.length);
          } else {
            data.length > 0 ? setData(oldData) : setData([]);
            setFinalRes(true);
          }
          setInPageLoading(false);
          setDataLoading(false);
          setLoader(false);
        }
      }
    }
  };

  const addAlertDomains = async () => {
    setNotifyLoading(true);
    const domains = domain.split("&");
    const body = {
      domains: domains,
      email: email,
    };

    let config = {
      headers: {
        "auth-token": user.Token,
      },
    };

    await axios
      .post(`${APIAlertsAddress}/add-alert-domains`, body, config)
      .then((res) => {
        setNotifyLoading(false);
        setSuccessNotify(true);
      })
      .catch((err) => {
        setNotifyLoading(false);
        console.log(err);
      });
  };

  const cancelRequest = () => {
    if (cancelSearch.current) {
      cancelSearch.current("New search initiated");
    }
  };
  return (
    <ErrorBoundary token={token}>
      <div className="App">
        <AppBarNew
          className="mobileHeader"
          user={user}
          role={role}
          token={token}
          email={email}
          domains={domains}
          domain={domain}
          handleLogOut={props.handleHomeLogOut}
        />
        <div id="generalCounters">
          <div id="tabsWithResults">
            <ScrollableTabsButtonForce
              hasNextPage={hasNextPage}
              handleHomeLogOut={props.handleHomeLogOut}
              resData={resData}
              stealersCount={stealersCount}
              employeesCount={employeesCount}
              partiesCount={partiesCount}
              androidsCount={androidsCount}
              clientsCount={clientsCount}
              empLength={empLength}
              dbsCount={dbsCount}
              user={user}
              role={role}
              token={token}
              domains={domains}
              domain={domain}
              getStats={getStats}
              getData={getData}
              getMonitoredDomains={getMonitoredDomains}
              monitoredDomains={monitoredDomains}
              monitorCount={monitorCount}
              allMonitoredCounts={allMonitoredCounts}
              counts={counts}
              labels={timeLabels}
              loading={loading}
              employeesStats={employeesStats}
              clientsStats={clientsStats}
              cities={cities}
              employeesUrls={employeesUrls}
              clientsUrls={clientsUrls}
              employeesUrlsCount={employeesUrlsCount}
              clientsUrlsCount={clientsUrlsCount}
              data={data}
              userRole={role}
              selectedDomain={activeDomains}
              machines={machines}
              loader={loader}
              finalRes={finalRes}
              config={config}
              loadingCounters={loadingCounters}
              section={section}
              handleYearChange={handleYearChange}
              year={year}
              switchToAllTimeChart={switchToAllTimeChart}
              switchToMonthlyChart={switchToMonthlyChart}
              monthly={monthly}
              yearsOptions={yearsOptions}
              clientsCities={clientsCities}
              employeesCities={employeesCities}
              currentYear={currentYear}
              getMapData={getMapData}
              mapLoading={mapLoading}
              chartLoader={chartLoader}
              dataLoading={dataLoading}
              externalCount={externalCount}
              getCounters={getCounters}
              expiry={props.expiry}
              setExternalDomain={(e) => setExternalDomain(e)}
              externalDomain={externalDomain}
              getAssetDiscovery={(type, inPageLoading) =>
                getAssetDiscovery(type, inPageLoading)
              }
              assets={assets}
              assetsCount={assetsCount}
              exportUrls={(domain, type, typeList) =>
                exportUrls(domain, type, typeList)
              }
              csvUrls={csvUrls}
              csvUrlsLoading={csvUrlsLoading}
              inPageLoading={inPageLoading}
              passwordStats={passwordStats}
              urlsLoading={urlsLoading}
              avStats={avStats}
              appStats={appStats}
              stealerFamilyStats={stealerFamilyStats}
              cookieStats={cookieStats}
              cookieLoading={cookieLoading}
              setData={(newData) => setData(newData)}
              passwordsLoading={passwordsLoading}
              avLoading={avLoading}
              sfLoading={sfLoading}
              appsLoading={appsLoading}
              location={location}
              setSelectedExternals={setSelectedExternals}
              selectedExternals={selectedExternals}
              searchMode={searchMode}
              currentCompany={currentCompany}
              company={company}
              assetsType={assetsType}
              currentCsvDomain={currentCsvDomain}
              externalSuggestions={externalSuggestions}
              responseError={responseError}
              addAlertDomains={addAlertDomains}
              successNotify={successNotify}
              notifyLoading={notifyLoading}
              floored={floored}
            />
          </div>
          {/* )} */}
          {!loading && !dataLoading && <Footer />}
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default Domain;
