/* eslint-disable */

import { createBrowserHistory } from "history";
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import logo from "../images/logo-1.png";
import Container from "./ProgressBar";

const queryString = require("query-string");
const history = createBrowserHistory();

export default function UrlSignIn(props) {
  const location = useLocation();
  const queryToken = queryString.parse(location.search).token;

  useEffect(() => {
    props.login(queryToken);
  }, []);

  // useEffect(() => {
  //   if (props.user) {
  //     redirectSuccessLogin();
  //   }
  // }, [props.user]);

  const redirectSuccessLogin = () => {
    history.push(`/`);
    window.location.reload();
  };

  return (
    <>
      {props.loading ? (
        <div
          className="App"
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ display: "block", width: "100%" }}>
            <Container redirect={true} />
          </div>
        </div>
      ) : props.user ? (
        <div
          className="App"
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ display: "block" }}>
            <img className="loginLogo" src={logo} alt="logo" />
            <br />
            <br />
            <h1 className="">Redirecting...</h1>
          </div>
        </div>
      ) : (
        <>
          {!props.loginError ? (
            <div
              className="App"
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ display: "block", width: "100%" }}>
                <Container redirect={true} />
              </div>
            </div>
          ) : (
            <div
              className="App"
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ display: "block" }}>
                <img className="loginLogo" src={logo} alt="logo" />
                <br />
                <br />
                <h1 className="">
                  {props.loginError != "Access Denied" ? (
                    <span>
                      Your trial has ended.
                      <br />
                      For further information please reach out to:
                      <br />
                      <a href="sales@hudsonrock.com" className="yellow">
                        sales@hudsonrock.com
                      </a>
                    </span>
                  ) : (
                    props.loginError
                  )}
                </h1>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
