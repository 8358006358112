import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import LoaderIcon from "react-loader-icon";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  const handleClickInside = (event) => {
    setIsComponentVisible(true);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    document
      .getElementById("autoComplete")
      .addEventListener("click", handleClickInside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}

export default function Autocomplete(props) {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const classes = useStyles();

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      autoComplete="off"
      className=""
      style={{
        width: props.width ? props.width : "90%",
        margin: "5px auto 0px",
      }}
    >
      <Form.Group
        className="mb-3 searchForm"
        controlId="autoComplete"
        style={{
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div style={{ width: "100%" }}>
          <Form.Control
            style={{
              width: "100%",
              backgroundColor: "#222831",
              fontSize: "16px",
              margin: "0px 5px 0px 0px",
              padding: "6.5px 10px",
            }}
            maxLength="999"
            value={props.value}
            onFocus={props.onFocus}
            onChange={props.onChange}
            placeholder={props.placeholder}
            required={true}
            name="keyword"
          />
          <div
            className="multiSuggestionsBox"
            id="suggestionsBox"
            ref={ref}
            style={{
              width:
                document.querySelector("#autoComplete") &&
                `${document.querySelector("#autoComplete").offsetWidth}px`,
              // width: `${ref.current.previousSibling.offsetWidth}px`,
              maxHeight: "165px",
              overflowY: "scroll",
            }}
          >
            {isComponentVisible && props.suggestions ? (
              !props.loading ? (
                <div style={{ display: "inline-flex", width: "100%" }}>
                  {props.suggestions &&
                    Object.keys(props.suggestions).map((key) =>
                      props.suggestions[key].length > 0 ? (
                        <div
                          key={key}
                          style={{
                            width: `calc(100% / ${
                              Object.keys(props.suggestions).filter(
                                (key) => props.suggestions[key].length > 0
                              ).length
                            })`,
                          }}
                        >
                          <h6
                            className="capitalize"
                            style={{
                              backgroundColor: "#FDCD4E",
                              color: "black",
                              padding: "4px 2px",
                              border: "2px solid",
                              margin: "0px",
                            }}
                          >
                            {key.replace(/_/g, " ")}
                          </h6>
                          {props.suggestions[key].length > 0 &&
                            props.suggestions[key].map((item, index) => (
                              <div
                                value={item.value}
                                onClick={() => {
                                  setIsComponentVisible(false);
                                  props.setKeywordInput(item.value);
                                  props.setWildcardType(key);
                                }}
                                key={index}
                                className="text-muted systemRow"
                                style={{
                                  display: "inline-flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "100%",
                                  border: "1px solid white",
                                }}
                              >
                                <span>{item.value}</span>
                                {(key === "Keywords" ||
                                  key === "Third_Party_Domains") && (
                                  <span>{item.occurrence}</span>
                                )}
                              </div>
                            ))}
                        </div>
                      ) : (
                        Object.keys(props.suggestions).filter(
                          (key) => props.suggestions[key].length > 0
                        ).length === 0 && (
                          <div
                            key={key}
                            style={{
                              width: "100%",
                            }}
                          >
                            <h6
                              className="capitalize"
                              style={{
                                backgroundColor: "#FDCD4E",
                                color: "black",
                                padding: "4px 2px",
                                border: "2px solid",
                                margin: "0px",
                              }}
                            >
                              {key}
                            </h6>
                            <div
                              style={{
                                display: "inline-flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                height: "165px",
                              }}
                            >
                              No Results Found
                            </div>
                          </div>
                        )
                      )
                    )}
                </div>
              ) : (
                <div
                  style={{
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "165px",
                  }}
                >
                  <LoaderIcon type="spin" style={{ fill: "white" }} />
                </div>
              )
            ) : (
              props.loading && (
                <div
                  style={{
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "165px",
                  }}
                >
                  <LoaderIcon type="spin" style={{ fill: "white" }} />
                </div>
              )
            )}
          </div>
        </div>
        <Button
          style={{ margin: "0px 0px 0px 4px", height: "38px" }}
          onClick={props.filterKeyword}
          type="button"
          variant="contained"
          className={classes.button + " searchBtn desktopSearchBtn"}
          endIcon={<Icon>send</Icon>}
        >
          Filter
        </Button>
      </Form.Group>
    </Form>
  );
}
