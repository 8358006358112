import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@mui/material/Drawer";
import React from "react";
import { FaCity, FaHome, FaTools, FaUserSecret } from "react-icons/fa";
import { HiIdentification, HiMenu } from "react-icons/hi";
import {
  MdAdminPanelSettings,
  MdFeedback,
  MdHelp,
  MdIntegrationInstructions,
  MdLogout,
  MdOutlineClose,
} from "react-icons/md";
import { Link } from "react-router-dom";
import logo from "../images/logo-1.png";
import Form from "./Form";
import FeedbackModal from "./partials/FeedbackModal";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function AppBarNew(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    handleMenuClose();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const clickOutside = () => {
    anchorEl && setAnchorEl(null);
  };

  const menuId = "primary-search-account-menu";
  const userMenuId = "user-primary-search-account-menu";

  const renderMenu = (
    <Drawer
      anchor="right"
      id={props.role === "User" || props.role === "MSSP" ? userMenuId : menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
      className="sidebarMenu"
    >
      <IconButton
        aria-label="show more"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleMenuClose}
        color="inherit"
      >
        <MdOutlineClose className="close" style={{ fill: "black" }} />
      </IconButton>
      <Link to="/">
        <MenuItem>
          {" "}
          <FaHome style={{ margin: "0px 10px 0px 0px" }} size={20} />
          Home
        </MenuItem>
      </Link>
      <Link to="/my-account">
        <MenuItem>
          <FaUserSecret style={{ margin: "0px 10px 0px 0px" }} size={20} /> My
          account
        </MenuItem>
      </Link>
      {props.role === "User" && (
        <Link
          to={"#"}
          onClick={() => {
            window.open(
              "https://calendly.com/hudson-rock/hudson-rock-introduction-call",
              "_blank"
            );
          }}
        >
          <MenuItem>
            {" "}
            <HiIdentification
              style={{ margin: "0px 10px 0px 0px" }}
              size={20}
            />
            Unmask Data
          </MenuItem>
        </Link>
      )}
      {props.role === "Admin" && (
        <Link to="/hector-panel">
          <MenuItem>
            {" "}
            <MdAdminPanelSettings
              style={{ margin: "0px 10px 0px 0px" }}
              size={25}
            />
            Admin Panel
          </MenuItem>
        </Link>
      )}
      {props.role === "Admin" && (
        <Link to="/hazan-tools">
          <MenuItem>
            {" "}
            <FaTools style={{ margin: "0px 10px 0px 0px" }} size={25} />
            Admin Tools
          </MenuItem>
        </Link>
      )}
      {props.role === "Admin" && (
        <Link to="/api-admin">
          <MenuItem>
            {" "}
            <MdIntegrationInstructions
              style={{ margin: "0px 10px 0px 0px" }}
              size={20}
            />
            User Permissions
          </MenuItem>
        </Link>
      )}
      {props.user.ui_permissions &&
        props.user.ui_permissions.includes("companies") && (
          <Link to={"/companies"}>
            <MenuItem>
              {" "}
              <FaCity style={{ margin: "0px 10px 0px 0px" }} size={20} />
              Companies
            </MenuItem>
          </Link>
        )}
      <Link to="/docs">
        <MenuItem>
          {" "}
          <MdIntegrationInstructions
            style={{ margin: "0px 10px 0px 0px" }}
            size={20}
          />
          API Docs
        </MenuItem>
      </Link>
      <Link to={undefined} onClick={handleClickOpen}>
        <MenuItem>
          {" "}
          <MdFeedback style={{ margin: "0px 10px 0px 0px" }} size={20} />
          Send Feedback
        </MenuItem>
      </Link>
      <Link to="/help">
        <MenuItem>
          {" "}
          <MdHelp style={{ margin: "0px 10px 0px 0px" }} size={20} />
          Help
        </MenuItem>
      </Link>
      <Link to={undefined}>
        <MenuItem onClick={props.handleLogOut}>
          {" "}
          <MdLogout style={{ margin: "0px 10px 0px 0px" }} size={20} />
          Log Out
        </MenuItem>
      </Link>
    </Drawer>
  );

  return (
    <div className={classes.grow}>
      {(props.role === "User" || props.role === "MSSP") && (
        <div
          className="cursor"
          style={{
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#4a40c5",
            position: "fixed",
            top: "0px",
            width: "100%",
            zIndex: "99999",
          }}
          onClick={() => {
            window.open(
              "https://calendly.com/hudson-rock/hudson-rock-introduction-call",
              "_blank"
            );
          }}
        >
          <h5 style={{ textAlign: "center", margin: "0px", color: "white" }}>
            In order to unmask the data please schedule a{" "}
            <a
              onClick={(e) => e.preventDefault()}
              className="yellow underline"
              href="https://calendly.com/hudson-rock/hudson-rock-introduction-call"
              target="_blank"
              rel="noreferrer"
            >
              call
            </a>
          </h5>
        </div>
      )}
      <AppBar
        position="static"
        onClick={clickOutside}
        style={{
          marginTop: (props.role === "User" || props.role === "MSSP") && "40px",
        }}
      >
        <Toolbar className="Appbar-header">
          <Link className="logoLink" to="/">
            <img
              src={logo}
              className="Appbar-logo"
              alt="logo"
              width="60"
              height="60"
            />
          </Link>
          <div className={classes.sectionDesktop + " newMenu"}>
            <div className="headerForm">
              <Form
                user={props.user}
                email={props.email}
                token={props.token}
                role={props.role}
                domains={props.domains}
              />
            </div>
            <div
              className="headerButtons"
              style={{
                top: (props.role === "User" || props.role === "MSSP") && "40px",
              }}
            >
              <IconButton
                aria-label="show more"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <HiMenu className="hamburger" />
              </IconButton>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
      {open && (
        <FeedbackModal
          user={props.user}
          open={open}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
        />
      )}
    </div>
  );
}
