/* eslint-disable */

import ChartDataLabels from "chartjs-plugin-datalabels";
import { Line } from "react-chartjs-2";

export default function LineChart(props) {
  const options = {
    responsive: true,
    indexAxis: props.indexAxis,
    plugins: {
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.yLabel;
          },
        },
      },
    },
    elements: {
      line: {
        tension: 0.5,
      },
    },
  };

  const labels = props.labels;

  const data = {
    labels,
    datasets: [
      {
        label: props.title,
        data: props.data.map((s) => s.count),
        backgroundColor: props.backgroundColor,
        borderColor: "black",
        fill: true,
        datalabels: {
          color: "#FFCE56",
          align: -120,
        },
      },
    ],
  };

  return (
    <Line
      plugins={[ChartDataLabels]}
      options={options}
      data={data}
      height={100}
    />
  );
}
