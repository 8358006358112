/* eslint-disable */

import React from "react";
import "react-sweet-progress/lib/style.css";
import "../css/Loader.css";
import logo from "../images/hrlogo_new.png";

export default function Container(props) {
  return (
    <div
      className="loadingBar"
      style={{
        maxHeight: "90vh",
        minHeight: "60vh",
        paddingTop: props.paddingTop && props.paddingTop,
        width: props.width && props.width,
      }}
    >
      <div className={`newLoader ${props.classes}`}></div>
      <div className="svg-container">
        <img src={logo} style={{ width: "250px" }} alt="logo" />
      </div>
      {props.customText && (
        <h5
          style={{
            textAlign: "center",
            width: "50%",
            margin: "20px auto",
          }}
        >
          {props.customText}
        </h5>
      )}
      {!props.csv ? (
        <div style={{ textAlign: "center" }}>
          {props.domain ? (
            <h2 className="loaderHeadline">
              Loading Data For {props.domain}...
            </h2>
          ) : props.redirect ? (
            <div>
              <div style={{ display: "block" }}>
                <br />
                <br />
                <h1 className="loaderHeadline">Redirecting...</h1>
              </div>
            </div>
          ) : (
            <span className="loaderHeadline"></span>
          )}
        </div>
      ) : (
        <h2 className="loaderHeadline csvHeading">
          Preparing CSV File For {props.domain} {props.type}
        </h2>
      )}
    </div>
  );
}
