import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Link, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export default function MobileSearchBtn(props) {
  const history = useHistory();
  const classes = useStyles();

  const search = async (e) => {
    e.preventDefault();
    const domainsArr = await Promise.all(
      props.activeDomains.map((domain) => {
        return domain.value;
      })
    ).catch((err) => console.log(err));
    let queryTxt = domainsArr.join("&");
    history.push(`/search-by-domain/${queryTxt}`);
  };

  return (
    <div>
      {props.role === "Admin" ||
      props.role === "MSSP" ||
      props.role === "master" ? (
        props.domain != null &&
        props.domain.includes(".") &&
        !props.domain.includes(":") ? (
          <Link to={`/search-by-domain/${props.domain}`}>
            <Button
              type="button"
              variant="contained"
              className={classes.button + " searchBtn mobileSearchBtn"}
              endIcon={<Icon>send</Icon>}
            ></Button>
          </Link>
        ) : (
          <Button
            type="button"
            variant="contained"
            className={classes.button + " searchBtn grayBtn mobileSearchBtn"}
            endIcon={<Icon>send</Icon>}
          ></Button>
        )
      ) : props.activeDomains.length > 0 ? (
        <Button
          onClick={search}
          type="button"
          variant="contained"
          className={classes.button + " searchBtn mobileSearchBtn"}
          endIcon={<Icon>send</Icon>}
        ></Button>
      ) : (
        <Button
          type="button"
          variant="contained"
          className={classes.button + " searchBtn grayBtn mobileSearchBtn"}
          endIcon={<Icon>send</Icon>}
        ></Button>
      )}
    </div>
  );
}
