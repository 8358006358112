/* eslint-disable */

import { Grid, Paper } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import React, { useEffect, useState } from "react";
import CreateApiClient from "../../API";
import { adminUrl } from "../../Config";

const searchers = ["MSSP", "master"];

const defaultPermissions = {
  api: [
    "employees",
    "users",
    "apks",
    "sensitive",
    "credentials",
    "search-by-domain",
    "url",
    "usernames",
  ],
  censored_fields: [],
  ui_permissions: [
    "employees",
    "third_parties",
    "users",
    "apks",
    "externals",
    "logo",
    "csv_export",
    "cookies",
    "search-by-domain",
  ],
};

const defaultCensores = [
  "user_passwords",
  "employee_passwords",
  "external_passwords",
  "usernames",
  "cookie_values",
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    maxWidth: "100%",
    padding: theme.spacing(1),
  },
  paper: {
    textAlign: "center",
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}));

const api = CreateApiClient();

export default function UserPermissions(props) {
  const classes = useStyles();
  const [permissions, setPermissions] = useState([]);
  const [censoredFields, setCensoredFields] = useState([]);
  const [uiPermissions, setUiPermissions] = useState([]);
  const [formPermissions, setFormPermissions] = useState([]);
  const [formCensorFields, setFormCensorFields] = useState([]);
  const [formUiPermissions, setFormUiPermissions] = useState([]);

  let headers = {
    "auth-token": props.token,
  };

  useEffect(() => {
    console.log(uiPermissions);
  }, [uiPermissions]);

  useEffect(() => {
    fetchTable();
  }, [props.role]);

  async function fetchTable() {
    const response = await api
      .adminReq("GET", `${adminUrl}/api-table?role=${props.role}`, headers, {})
      .then((res) => res.data)
      .catch((err) => err);
    setFormPermissions(response.permissions);
    setFormCensorFields(response.censored_fields);
    setFormUiPermissions(response.ui_permissions);
    setUiPermissions(response.checked_ui_permissions);
    setPermissions(response.checked_api_permissions);
    setCensoredFields(response.checked_censored_fields);

    // let noPasswords = uiPermissions.filter((s) => !defaultCensores.includes(s));

    // if (searchers.includes(props.role)) {
    //   if (props.role === "MSSP") {
    //     setUiPermissions([...noPasswords, "companies"]);
    //   } else {
    //     setUiPermissions([
    //       ...uiPermissions.concat(defaultCensores),
    //       "companies",
    //     ]);
    //   }
    // } else {
    //   if (props.role === "Customer") {
    //     setUiPermissions([
    //       ...uiPermissions
    //         .filter((s) => s !== "companies")
    //         .concat(defaultCensores),
    //     ]);
    //   } else {
    //     setUiPermissions(noPasswords.filter((s) => s !== "companies"));
    //   }
    // }
  }

  function handleUiPermissionChange(value) {
    if (!uiPermissions.includes(value)) {
      setUiPermissions([...uiPermissions, value]);
    } else {
      setUiPermissions(uiPermissions.filter((p) => p !== value));
    }
  }

  function handlePermissionChange(value) {
    if (!permissions.includes(value)) {
      setPermissions([...permissions, value]);
    } else {
      setPermissions(permissions.filter((p) => p !== value));
    }
  }

  function handleCensoredFieldsChange(value) {
    if (!censoredFields.includes(value)) {
      setCensoredFields([...censoredFields, value]);
    } else {
      setCensoredFields(censoredFields.filter((f) => f !== value));
    }
  }

  useEffect(() => {
    props.updatePermissions(permissions, censoredFields, uiPermissions);
  }, [permissions, censoredFields, uiPermissions]);

  useEffect(() => {
    setPermissions([]);
    setCensoredFields([]);
    setUiPermissions([]);
    setFormPermissions([]);
    setFormCensorFields([]);
    setFormUiPermissions([]);
  }, [props.saved]);

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12} sm={4}>
        <Paper className={classes.paper} style={{ height: "100%" }}>
          <h2 className="cyberFont bold">API Permissions</h2>
          <Stack
            direction="row"
            sx={{
              display: "block",
              overflowY: "scroll",
            }}
          >
            {formPermissions.map((item, index) => (
              <Tooltip key={index} title={item.description}>
                <Chip
                  className="apiChips cyberFont bold"
                  onClick={() => handlePermissionChange(item.value)}
                  sx={{
                    backgroundColor: permissions.includes(item.value)
                      ? "#fabe44"
                      : "lightgray",
                    color: "black",
                    margin: "2px",
                    padding: "2px",
                    fontSize: "12px",
                  }}
                  label={item.label}
                  variant="filled"
                />
              </Tooltip>
            ))}
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Paper className={classes.paper} style={{ height: "100%" }}>
          <h2 className="cyberFont bold">Censored Fields</h2>
          <Stack
            direction="row"
            sx={{
              display: "block",
              overflowY: "scroll",
            }}
          >
            {formCensorFields.map((item, index) => (
              <Chip
                className="apiChips cyberFont bold"
                onClick={() => handleCensoredFieldsChange(item.value)}
                sx={{
                  backgroundColor: censoredFields.includes(item.value)
                    ? "#fabe44"
                    : "lightgray",
                  color: "black",
                  margin: "2px",
                  padding: "2px",
                  fontSize: "12px",
                }}
                key={index}
                label={item.value}
                variant="filled"
              />
            ))}
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Paper className={classes.paper} style={{ height: "100%" }}>
          <h2 className="cyberFont bold">UI Permissions</h2>
          <Stack
            direction="row"
            sx={{
              display: "block",
              overflowY: "scroll",
            }}
          >
            {formUiPermissions.map((item, index) => (
              <Tooltip key={index} title={item.description}>
                <Chip
                  className="apiChips cyberFont bold"
                  onClick={() => handleUiPermissionChange(item.value)}
                  sx={{
                    backgroundColor: uiPermissions.includes(item.value)
                      ? "#fabe44"
                      : "lightgray",
                    color: "black",
                    margin: "2px",
                    padding: "2px",
                    fontSize: "12px",
                  }}
                  label={item.label}
                  variant="filled"
                />
              </Tooltip>
            ))}
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  );
}
