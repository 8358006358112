/* eslint-disable */

import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { FaUser, FaUserNinja, FaUserSecret, FaUserTie } from "react-icons/fa";
import LoaderIcon from "react-loader-icon";
import Timeline from "./Timeline";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    maxWidth: "100%",
    padding: theme.spacing(1),
  },
  paper: {
    textAlign: "center",
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    backgroundColor: "#fbcd4e",
  },
}));

const iconsByRole = [
  {
    name: "User",
    icon: <FaUser size={25} />,
  },
  {
    name: "Customer",
    icon: <FaUserTie size={25} />,
  },
  {
    name: "MSSP",
    icon: <FaUserSecret size={25} />,
  },
  {
    name: "master",
    icon: <FaUserNinja size={25} />,
  },
];

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const formatDate = (dateString) => {
  // Parse the date string into a Date object
  const date = new Date(dateString);

  // Add 3 hours to the time
  date.setHours(date.getHours() + 3);

  // Format the date and time
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  // Return the formatted date string
  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export const LogoBackground = ({ email }) => {
  const [imageUrl, setImageUrl] = useState(
    `https://logo.clearbit.com/${email.split("@")[1]}`
  );

  useEffect(() => {
    const img = new Image();
    img.src = imageUrl;
    img.onerror = () => setImageUrl("https://logo.clearbit.com/hudsonrock.com");
  }, [imageUrl]);

  return (
    <div
      className="bg-cover"
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        borderRadius: "50px",
        width: "70px",
        height: "70px",
        position: "relative",
      }}
    ></div>
  );
};

export default function UsersList(props) {
  const classes = useStyles();

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {props.data
        .filter((s) => s.role !== "Admin")
        .map((item, index) => (
          <div
            style={{
              width: "100%",
            }}
            key={index}
            id={item.email}
          >
            <div
              style={{
                display: "inline-flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                color: "black",
                backgroundColor: "#FBCD4E",
                margin: "5px",
                borderRadius: "50px",
                cursor: "pointer",
                position: "relative",
              }}
              onClick={() => props.getUserActions(item.email)}
            >
              {item.tags && (
                <div
                  className="userTags"
                  style={{
                    top: "-10px",
                    right: "-10px",
                    left: "auto",
                    backgroundColor: "black",
                    color: "#E69600",
                    border: "2px solid",
                  }}
                >
                  {item.tags[0]}
                </div>
              )}

              {/* <LogoBackground email={item.email} /> */}
              <div style={{ width: "60px" }}>
                <img
                  src={`https://logo.clearbit.com/${item.email.split("@")[1]}`}
                  style={{ width: "60px", borderRadius: "50px" }}
                  onError={(e) =>
                    (e.target.src = `https://logo.clearbit.com/hudsonrock.com`)
                  }
                />
              </div>
              <div
                style={{
                  padding: "1rem",
                  display: "inline-flex",
                  width: "100%",
                  justifyContent: "start",
                  alignItems: "center",
                  textAlign: "left",
                }}
              >
                <div style={{ width: "3%" }}>
                  {iconsByRole.find((i) => i.name === item.role)?.icon}
                </div>
                <div style={{ width: "10%" }} className="cyberFont bold">
                  {item.role?.toUpperCase()}
                </div>
                <div style={{ width: "30%" }} className="cyberFont bold">
                  {item.email}
                </div>
                <div style={{ width: "20%" }} className="cyberFont bold">
                  Total Actions: {numberWithCommas(item.count)}
                </div>
                <div style={{ width: "30%" }} className="cyberFont bold">
                  Last Active: {formatDate(item.last_active)}
                </div>
                <div style={{ width: "10%" }}>
                  <button
                    className="cyberFont bold yellow"
                    style={{
                      backgroundColor: "#1A1C20",
                      border: "2px",
                      padding: "5px 10px",
                      borderRadius: "50px",
                      // marginTop: "5px",
                    }}
                    onClick={() => props.getUserActions(item.email)}
                  >
                    EXPAND
                  </button>
                </div>
              </div>
            </div>
            {props.userActionsLoading === item.email ? (
              <div style={{ margin: "10rem auto" }}>
                <LoaderIcon type="spin" style={{ fill: "#FBCD4E" }} />
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: "#222831",
                  color: "#FBCD4E",
                  borderRadius: "20px",
                  margin: "10px",
                }}
              >
                {props.events && props.events[0]?.user === item.email && (
                  <Timeline
                    timelineRef={props.timelineRef}
                    events={props.events}
                    x={10.55}
                  />
                )}
                {props.userActions &&
                  props.userActions.length > 0 &&
                  props.userActions[0].user === item.email && (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr 1fr",
                        gap: "1rem",
                        padding: "1rem",
                      }}
                    >
                      {props.userActions.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "start",
                            alignItems: "center",
                            textAlign: "center",
                            border: "1px solid #ddd",
                            padding: "1rem",
                            borderRadius: "8px",
                          }}
                        >
                          <div
                            className="cyberFont bold"
                            style={{ marginBottom: "0.5rem" }}
                          >
                            {item.action.toLocaleUpperCase()} -{" "}
                            {item.type.split("_").join(" ").toLocaleUpperCase()}
                          </div>
                          <div>{numberWithCommas(item.count)}</div>
                        </div>
                      ))}
                    </div>
                  )}
              </div>
            )}
          </div>
        ))}
    </div>
  );
}
