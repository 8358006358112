/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import CreateApiClient from "../../API";
import AppBarNew from "../AppBar";
import HorizontalTimeline from "../partials/HorizontalTimeline";
import MachineJson from "../partials/MachineJson";
import ScrollToTop from "../partials/ScrollToTop";
import Container from "../ProgressBar";
import Error from "./Error";

const api = CreateApiClient();

export default function Ip(props) {
  const location = useLocation();
  const ip = location.pathname.split("/")[2];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [machine, setMachine] = useState({});

  const config = {
    headers: {
      "auth-token": props.token,
    },
  };

  const getData = async () => {
    setError(null);
    setLoading(true);
    try {
      const response = await api.fetch_data(`ips/${ip}`, {}, config);
      setMachine(response[0]);
      setData(response);
    } catch (err) {
      console.log(err.message);
      setError(err.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [ip]);

  return (
    <div>
      <AppBarNew
        className="mobileHeader"
        user={props.user}
        role={props.role}
        token={props.token}
        email={props.email}
        handleLogOut={props.handleHomeLogOut}
        domains={props.domains}
      />
      <div
        style={{
          padding: "4rem 0 0 0",
        }}
      >
        {loading ? (
          <Container paddingTop={"3rem"} classes={"mainNewLoader"} />
        ) : !error ? (
          <div>
            <HorizontalTimeline
              data={data}
              machine={machine}
              setMachine={setMachine}
            />
            <MachineJson machine={machine} component="ip" />
          </div>
        ) : (
          <Error msg={error} user={props.user} />
        )}
      </div>
      <ScrollToTop />
    </div>
  );
}
