/* eslint-disable */

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import {
  FaExternalLinkAlt,
  FaFileCsv,
  FaUserTie,
  FaUsers,
} from "react-icons/fa";
import LoaderIcon from "react-loader-icon";
import { APIFullAddress } from "../Config";
import thumbnail from "../images/thumbnail.jpeg";
import BlurryText from "./Blur";
import Container from "./ProgressBar";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function AssetsDiscovery(props) {
  const radioOptions = [
    {
      value: "employees",
      label: "Employees",
      icon: <FaUserTie size={22} />,
      list: "employees_urls",
      count: "totalEmployees",
      visible: props.numbers && props.numbers.totalEmployees > 0 ? true : false,
    },
    {
      value: "users",
      label: "Users",
      icon: <FaUsers size={22} />,
      list: "clients_urls",
      count: "totalUsers",
      visible: props.numbers && props.numbers.totalUsers > 0 ? true : false,
    },
    {
      value: "externals",
      label: "Externals",
      icon: <FaExternalLinkAlt size={22} />,
      list: "external_urls",
      count: "totalExternals",
      visible: props.hasExternals ? true : false,
    },
  ];
  const [expand, setExapnd] = useState("");
  const [externalView, setExternalView] = useState(null);
  const [thirdPartyUrls, setThirdPartyUrls] = useState([]);
  const [externalLoading, setExternalLoading] = useState(false);
  const [urlList, setUrlList] = useState([]);

  const [type, setType] = useState({});
  const [page] = useState(0);

  const handleTypeChange = (e) => {
    let found = radioOptions.find((o) => o.value === e.target.value);
    props.getAssetDiscovery(found.value, true);
    setType(found);
  };

  const defaultSrc = (ev) => {
    ev.target.src = thumbnail;
  };

  const getThirdPartyUrls = async (domain) => {
    setExternalLoading(true);
    const _urls = await axios
      .post(
        `${APIFullAddress}/assets-discovery/third-party?domain=${domain}&page=${page}`,
        {},
        { headers: { "auth-token": props.token } }
      )
      .then((res) => res.data)
      .catch((err) => err);

    setThirdPartyUrls(_urls);
    setExternalLoading(false);
  };

  const getDomainUrls = (item) => {
    setUrlList([]);
    if (expand === `${item.id}_${type.value}`) {
      setExapnd(null);
    } else {
      setExapnd(`${item.id}_${type.value}`);
      setThirdPartyUrls([]);
      setExternalView(null);
    }

    if (item.onlyThirdParties) {
      setThirdPartyUrls(item.urls);
      setExternalView(item.domain);
    } else {
      setExternalLoading(true);
      axios
        .post(
          `${APIFullAddress}/assets-discovery/urls-by-domain?domain=${
            item.domain
          }&type=${type.value}&company=${
            props.company ? props.company._id : null
          }`,
          {},
          {
            headers: { "auth-token": props.token },
          }
        )
        .then((response) => {
          setExternalLoading(false);
          setUrlList(response.data);
        })
        .catch((err) => {
          console.log(err);
          setExternalLoading(false);
        });
    }
  };

  useEffect(() => {
    let _type = radioOptions.find((s) => s.value === props.assetsType);
    setType(_type);
  }, []);

  useEffect(() => {
    if (externalView) {
      setThirdPartyUrls([]);
      getThirdPartyUrls(externalView);
    }
  }, [externalView]);

  return (
    <div style={{ width: "70%", margin: "auto" }}>
      <div
        className="styledBox"
        style={{
          textAlign: "center",
          backgroundColor: "#fdcd4e",
          width: "100%",
          color: "#292F37",
        }}
      >
        <h1>Assets Discovery</h1>
        <FormControl component="fieldset" className="">
          <RadioGroup
            onChange={handleTypeChange}
            row
            aria-label="position"
            name="position"
            value={props.assetsType}
            className="blackRadios"
          >
            {radioOptions
              .filter((s) => s.visible)
              .map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item.value}
                  control={<Radio color="primary" />}
                  label={
                    <div className="grid-center lightText">
                      {item.icon}
                      {item.label}
                    </div>
                  }
                  labelPlacement="top"
                />
              ))}
          </RadioGroup>
        </FormControl>
        <br />
        <br />
        {props.numbers &&
          !props.inPageLoading &&
          props.data &&
          props.data[`${type.list}`] &&
          props.data[`${type.list}`].length > 0 && (
            <>
              <h2>
                Total URLs:{" "}
                {props.numbers[`${type.count}`] > 0
                  ? numberWithCommas(props.numbers[`${type.count}`])
                  : numberWithCommas(
                      props.data[`${type.list}`]
                        .filter((s) => s.count > 0)
                        .map((item, index) => item.count)
                    )}
              </h2>

              {(type.value !== "externals" ||
                (props.company && props.company.data)) && (
                <>
                  {props.csvUrls &&
                  props.csvUrls === "OK" &&
                  props.currentCsvDomain === "all" ? (
                    <span
                      className="lightText"
                      style={{
                        fontSize: "16px",
                        lineHeight: "2",
                        color: "black",
                      }}
                    >
                      CSV file will be ready to download from{" "}
                      <a
                        href="/my-account"
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "underline" }}
                      >
                        My Account
                      </a>{" "}
                      page. An email will be sent to {props.user.email} once
                      ready.
                    </span>
                  ) : props.csvUrls &&
                    props.csvUrls.length > 0 &&
                    props.currentCsvDomain === "all" ? (
                    <CSVLink
                      id={`all_${type.value}_csv`}
                      className="csvDownload"
                      data={props.csvUrls}
                      filename={`all_${type.value}.csv`}
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e) => e.stopPropagation()}
                    >
                      Click here if the file has not been downloaded
                    </CSVLink>
                  ) : (
                    props.user.ui_permissions.includes("csv_export") && (
                      <Button
                        variant="contained"
                        className="darkBg yellowColor"
                        onClick={(e) => {
                          e.stopPropagation();
                          props.exportUrls("all", type.value, `${type.list}`);
                        }}
                        style={{ height: "35px" }}
                      >
                        EXPORT ALL TO CSV
                        {props.csvUrlsLoading === `all_${type.value}` ? (
                          <LoaderIcon
                            type="spin"
                            style={{
                              width: "16px",
                              height: "auto",
                              fill: "white",
                            }}
                          />
                        ) : (
                          <FaFileCsv size={20} />
                        )}
                      </Button>
                    )
                  )}
                </>
              )}
            </>
          )}
      </div>
      <br />
      <div
        className="flex_box scrollBar"
        style={{
          minHeight: "100vh",
          maxHeight: "100vh",
          marginBottom: "15rem",
          overflowY: "scroll",
          backgroundColor: props.inPageLoading ? "#1A1C20" : "#222831",
          borderRadius: "20px",
          padding: "1rem",
          border: "2px solid #222831",
        }}
      >
        {props.data &&
        props.data[`${type.list}`] &&
        props.data[`${type.list}`].length > 0 ? (
          props.data[`${type.list}`]
            .filter((s) => s.count > 0)
            .map((item, index) => (
              <div
                key={index}
                style={{
                  marginTop: "20px",
                  padding: "20px",
                  borderRadius: "20px",
                  backgroundColor: "#1A1C20",
                }}
              >
                <div
                  className="cursor"
                  style={{
                    display: "inline-flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    padding: "20px",
                    borderRadius: "20px",
                    backgroundColor: "#1A1C20",
                    width: "100%",
                  }}
                  onClick={() => {
                    getDomainUrls(item);
                  }}
                >
                  <img
                    id="logoImg"
                    preserveDrawingBuffer={true}
                    className="roundSmallImg"
                    style={{
                      backgroundColor: "#222831",
                      border: "0px",
                    }}
                    src={
                      props.system && item.blur
                        ? thumbnail
                        : `https://logo.clearbit.com/${item.domain}`
                    }
                    onError={defaultSrc}
                    alt="logo"
                  />
                  <div>
                    <h5>
                      {props.system && item.blur ? (
                        <span>
                          <BlurryText value={item.domain.split(".")[0]} />.
                          {item.domain.split(".")[1]}
                        </span>
                      ) : (
                        item.domain
                      )}
                    </h5>
                    <h5>{numberWithCommas(item.count)} URLs</h5>
                  </div>{" "}
                  <Button
                    type="button"
                    variant="contained"
                    className={"searchBtn desktopSearchBtn"}
                    endIcon={<Icon>expand</Icon>}
                    onClick={() => {
                      getDomainUrls(item);
                    }}
                  >
                    VIEW ALL
                  </Button>
                  {props.csvUrls &&
                  props.csvUrls === "OK" &&
                  props.currentCsvDomain === `${item.domain}_${type.value}` ? (
                    <span
                      className="darkText"
                      style={{
                        fontSize: "16px",
                        lineHeight: "2",
                        textAlign: "center",
                        width: "30%",
                      }}
                    >
                      CSV file will be ready to download from{" "}
                      <a
                        href="/my-account"
                        target="_blank"
                        rel="noreferrer"
                        className="yellow"
                        style={{ textDecoration: "underline" }}
                      >
                        My Account
                      </a>{" "}
                      page. An email will be sent to {props.user.email} once
                      ready.
                    </span>
                  ) : props.csvUrls &&
                    props.csvUrls.length > 0 &&
                    props.currentCsvDomain ===
                      `${item.domain}_${type.value}` ? (
                    <CSVLink
                      id={`${item.domain}_${type.value}_csv`}
                      className="csvDownload"
                      data={externalView ? thirdPartyUrls : props.csvUrls}
                      filename={`${item.domain}_${type.value}.csv`}
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e) => e.stopPropagation()}
                      style={{ color: "white", textAlign: "center" }}
                    >
                      Click here if the file has not been downloaded
                    </CSVLink>
                  ) : (
                    props.user.ui_permissions.includes("csv_export") && (
                      <Button
                        variant="contained"
                        className="searchBtn"
                        onClick={(e) => {
                          e.stopPropagation();
                          props.exportUrls(item.domain, type.value);
                        }}
                        style={{ height: "35px" }}
                      >
                        Export To CSV
                        {props.csvUrlsLoading ===
                        `${item.domain}_${type.value}` ? (
                          <LoaderIcon
                            type="spin"
                            style={{ width: "16px", height: "auto" }}
                          />
                        ) : (
                          <FaFileCsv size={20} />
                        )}
                      </Button>
                    )
                  )}
                </div>
                {expand === `${item.id}_${type.value}` && (
                  <div className="scrollBox">
                    {(item.hasEmailDomain || item.onlyThirdParties) && (
                      <>
                        {!externalLoading && !item.onlyThirdParties && (
                          <>
                            <button
                              className="cardBtn uppercase"
                              onClick={(e) => {
                                if (item.hasEmailDomain) {
                                  externalView
                                    ? setExternalView(null)
                                    : setExternalView(item.domain);
                                }
                              }}
                            >
                              {externalView && thirdPartyUrls.length > 0
                                ? "Show compromised corporate URLs"
                                : "Show third party URLs"}
                            </button>
                            <br />
                            <br />
                          </>
                        )}
                        {((thirdPartyUrls.length > 0 && externalView) ||
                          item.onlyThirdParties) && (
                          <>
                            {item.onlyThirdParties && (
                              <span style={{ color: "#FBCD4E" }}>
                                *Only third party URLs were identified
                              </span>
                            )}
                            <p>
                              Total Third Party URLs:{" "}
                              {item.onlyThirdParties
                                ? item.urls.length
                                : thirdPartyUrls.length}
                            </p>
                          </>
                        )}
                      </>
                    )}
                    {externalLoading && thirdPartyUrls.length <= 0 && (
                      <LoaderIcon
                        type="spin"
                        style={{
                          fill: "white",
                          width: "100px",
                          height: "auto",
                          position: "relative",
                          top: "5rem",
                        }}
                      />
                    )}
                    {externalView ? (
                      <>
                        {thirdPartyUrls.map((item, index) => (
                          <div
                            key={index}
                            style={{
                              textAlign: "left",
                              backgroundColor: "#222831",
                              borderRadius: "20px",
                              padding: "10px",
                              fontSize: "16px",
                              display: "inline-flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                              margin: "5px 0px",
                            }}
                          >
                            <p
                              style={{
                                margin: "0px",
                                width: "70%",
                                wordBreak: "break-word",
                              }}
                            >
                              {item.url}
                            </p>
                            <p style={{ margin: "0px" }}>
                              Occurrence: {item.occurrence}
                            </p>
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        {urlList.map((asset, index) => (
                          <div
                            key={index}
                            style={{
                              textAlign: "left",
                              backgroundColor: "#222831",
                              borderRadius: "20px",
                              padding: "10px",
                              fontSize: "16px",
                              display: "inline-flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                              margin: "5px 0px",
                            }}
                          >
                            {item.blur && !props.system ? (
                              <>
                                {asset.url.split(`.${item.domain}`)[0] ? (
                                  asset.url.split(`.${item.domain}`)[1] ? (
                                    <p>
                                      {
                                        asset.url
                                          .split(`.${item.domain}`)[0]
                                          .split("//")[0]
                                      }
                                      //
                                      <BlurryText
                                        value={`${asset.url
                                          .split(`.${item.domain}`)[0]
                                          .split("//")[1]
                                          .replace(/[a-zA-Z]/g, "•")}`}
                                      />
                                      .{item.domain}/
                                      <BlurryText
                                        value={`${asset.url
                                          .split(`.${item.domain}`)[0]
                                          .replace(/[a-zA-Z]/g, "•")}`}
                                      />
                                    </p>
                                  ) : asset.url
                                      .split(`.${item.domain}`)[0]
                                      .split("//")[1] ? (
                                    <p>
                                      {
                                        asset.url
                                          .split(`.${item.domain}`)[0]
                                          .split("//")[0]
                                      }
                                      //
                                      <BlurryText
                                        value={`${asset.url
                                          .split(`.${item.domain}`)[0]
                                          .split("//")[1]
                                          .replace(/[a-zA-Z]/g, "•")}`}
                                      />
                                      .{item.domain}
                                    </p>
                                  ) : (
                                    <p style={{ margin: "0px" }}>{asset.url}</p>
                                  )
                                ) : (
                                  <p style={{ margin: "0px" }}>{asset.url}</p>
                                )}
                              </>
                            ) : props.system && item.blur ? (
                              <p
                                style={{
                                  margin: "0px",
                                  width: "70%",
                                  wordBreak: "break-word",
                                }}
                              >
                                {asset.url.split(item.domain)[0]}
                                <BlurryText value={item.domain.split(".")[0]} />
                                .{item.domain.split(".")[1]}
                                {asset.url.split(item.domain)[1]}
                              </p>
                            ) : (
                              <p
                                style={{
                                  margin: "0px",
                                  width: "70%",
                                  wordBreak: "break-word",
                                }}
                              >
                                {asset.url}
                              </p>
                            )}
                            <p style={{ margin: "0px" }}>
                              Occurrence: {asset.occurrence}
                            </p>{" "}
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                )}
              </div>
            ))
        ) : props.inPageLoading ? (
          <Container />
        ) : (
          <div style={{ textAlign: "center", marginTop: "2rem" }}>
            <h2>No Results</h2>
          </div>
        )}
      </div>
    </div>
  );
}

export default AssetsDiscovery;
