import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import React from "react";

export default function CensorDates(props) {
  return (
    <>
      <FormGroup>
        <FormControlLabel
          style={{
            justifyContent: "start",
            backgroundColor: "#222831",
            borderRadius: "20px",
            margin: "10px 5px",
          }}
          control={
            <Checkbox
              checked={props.hasCensorDate}
              onChange={() => {
                props.setHasCensorDate(!props.hasCensorDate);
              }}
            />
          }
          label="Enable Censor Date"
        />
      </FormGroup>

      {props.hasCensorDate && (
        <input
          style={{
            width: "100%",
            borderRadius: "5px",
            padding: "4px 8px",
          }}
          type="date"
          id="start"
          name="trip-start"
          value={props.censorDate}
          min={new Date().toISOString().split("T")[0]}
          onChange={props.onChangeCensorDate}
        />
      )}
      <span className="darkText">Exposure Range:</span>
      <div className="censorship-dates-container">
        <input
          style={{
            width: "50%",
            borderRadius: "5px",
            padding: "4px 8px",
          }}
          type="date"
          value={props.since}
          onChange={(e) => {
            props.setSince(e.target.value);
          }}
          dateFormat="yyyy-MM-dd"
          placeholder="Since"
          className="censorship-date-picker"
        />
        <input
          style={{
            width: "50%",
            borderRadius: "5px",
            padding: "4px 8px",
          }}
          type="date"
          value={props.until}
          onChange={(e) => {
            props.setUntil(e.target.value);
          }}
          dateFormat="yyyy-MM-dd"
          placeholder="Until"
          className="censorship-date-picker"
        />
      </div>
    </>
  );
}
