import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginLeft: "6px",
  },
  paper: {
    boxShadow: "none",
    height: 80,
    width: 80,
  },
  control: {
    padding: theme.spacing(1),
  },
}));

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function SpacingGrid(props) {
  const classes = useStyles();

  return (
    <Grid container className={classes.root} spacing={0}>
      <Grid item md={12}>
        <Grid
          container
          justifyContent="center"
          spacing={1}
          style={{ width: "auto", margin: "0px" }}
          className="notification-badges"
        >
          {props.options &&
            props.options.map((option, index) => (
              <Grid
                className="cursor"
                key={index}
                item
                data-badge={numberWithCommas(option.counts.users)}
                onClick={(e) => {
                  props.onClick(e, option.job_company_website);
                }}
              >
                <Paper
                  className={classes.paper}
                  style={{
                    backgroundColor: "black",
                    background:
                      !props.cookies &&
                      props.topApks.find((s) => s._id === option._id).logo
                        ? `url(${
                            props.topApks.find((s) => s._id === option._id).logo
                          })`
                        : !props.cookies &&
                          `url(https://logo.clearbit.com/${
                            props.topApks.find((s) => s._id === option._id)
                              .domain
                          })`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    borderRadius: "10px",
                    textAlign: "center",
                    margin: "auto",
                  }}
                />
                {!props.cookies && (
                  <span
                    className="capitalize"
                    style={{
                      fontSize: "12px",
                      color: "#222831",
                      fontWeight: "bold",
                    }}
                  >
                    {
                      props.topApks
                        .find((s) => s._id === option._id)
                        .domain.split(".")[0]
                    }
                  </span>
                )}
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
