/* eslint-disable */

import { Bar } from "react-chartjs-2";

export default function BarChart(props) {
  const options = {
    responsive: true,
    indexAxis: props.indexAxis,
    plugins: {
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.yLabel;
          },
        },
      },
      //   legend: {
      //     position: "top",
      //   },
      //   title: {
      //     display: false,
      //     text: props.title,
      //   },
    },
  };

  const labels = props.labels;

  const data = {
    labels,
    datasets: [
      {
        label: props.title,
        data: props.data.map((s) => s.count),
        backgroundColor: props.backgroundColor,
      },
    ],
  };

  return <Bar options={options} data={data} height={100} />;
}
