import React from "react";
import Form from "react-bootstrap/Form";
import Select, { components } from "react-select";

const { Option } = components;
const IconOption = (props) => (
  <Option {...props}>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        textAlign: "center",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <img
          src={props.data.flag}
          style={{ width: 36 }}
          alt={props.data.label}
        />
        <div style={{ textAlign: "center" }}>{props.data.label}</div>
      </div>
    </div>
  </Option>
);

export default function GovSearch(props) {
  return (
    <div>
      <Form.Group className="mainSearchBy">
        <Select
          defaultValue={props.options[0]}
          options={props.options}
          components={{ Option: IconOption }}
          onChange={props.setSelected}
          value={props.selected}
          placeholder="SELECT COUNTRY"
        />
      </Form.Group>
    </div>
  );
}
