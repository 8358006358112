import React from "react";
import LoaderIcon from "react-loader-icon";
import Filter from "./Filter";
import UsersList from "./List";

export default function Users(props) {
  const { users, usersLoading } = props;
  return (
    <div style={{ marginTop: "20px" }}>
      {usersLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "70vh",
          }}
        >
          <LoaderIcon type="spin" style={{ fill: "#FBCD4E" }} />
        </div>
      ) : (
        <div>
          <Filter
            placeholder="Filter by User"
            onChange={props.onTimelineUserFilterChange}
            onSubmit={props.onTimelineFilter}
            value={props.userFilter}
            clear={props.clearTimelineUserFilter}
          />
          <br />

          {users && users.length ? (
            <div style={{ width: "100%" }}>
              <UsersList
                data={users}
                getUserActions={(email) => props.getUserActions(email)}
                userActions={props.userActions}
                userActionsLoading={props.userActionsLoading}
                timelineRef={props.timelineRef}
                events={props.events}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "70vh",
              }}
            >
              <h3>NO RESULTS</h3>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
