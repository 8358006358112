import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import React from "react";
import Form from "react-bootstrap/Form";
import LoaderIcon from "react-loader-icon";
import CompanyDataTable from "./CompanyDataTable";

export default function NewCompanyData(props) {
  return (
    <div
      id="userData"
      className="styledBox yellow-bg"
      style={{ margin: "30px auto", width: props.type === "gov" && "50%" }}
    >
      <br />
      <CompanyDataTable
        bulkSet={(type, data) => props.bulkSet(type, data)}
        data={props.data}
        blacklistDomains={props.blacklistDomains}
      />
      <div
        style={{
          width: "50%",
          textAlign: "center",
          margin: "auto",
          padding: "1rem",
          color: "black",
        }}
      >
        <div
          style={{
            width: props.type === "gov" ? "100%" : "60%",
            textAlign: "center",
            margin: "auto",
          }}
        >
          {props.mode === "edit" && (
            <Form.Control
              value={props.companyName}
              maxLength="200"
              onChange={props.setCompanyName}
              name="companyName"
              placeholder={"Company Name"}
              className="blackTextInput"
              disabled={props.mode === "edit"}
            />
          )}
          <br />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className=""
            type="button"
            disabled={props.addedDomains.length > 0 ? false : true}
            onClick={props.saveCompany}
            endIcon={
              props.loading ? (
                <LoaderIcon className="buttonLoader tabsLoader" type="spin" />
              ) : props.saved ? (
                <Icon>done</Icon>
              ) : (
                <Icon>send</Icon>
              )
            }
          >
            {props.type === "gov" ? "SAVE ENTITY" : "SAVE COMPANY"}
          </Button>
          {props.saveError && (
            <>
              {" "}
              <br />
              <span className="error">{props.saveError}</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
