/* eslint-disable */

import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import CountUp from "react-countup";
import { FaUsers } from "react-icons/fa";
import { MdOutlineAdsClick } from "react-icons/md";
import { RiGlobalFill } from "react-icons/ri";
import LoaderIcon from "react-loader-icon";
import CreateApiClient from "../../API";
import { adminUrl } from "../../Config";
import Actions from "./analytics/Actions";
import Overall from "./analytics/Overall";
import Users from "./analytics/Users";

const api = CreateApiClient();

const adjustToGMTPlus3 = (data) => {
  return data.map((item) => {
    let adjustedHour = item.hour + 3;
    let adjustedDay = item.day;

    if (adjustedHour >= 24) {
      adjustedHour -= 24;
      adjustedDay += 1;
    }

    return {
      ...item,
      hour: adjustedHour,
      day: adjustedDay,
    };
  });
};

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const Counter = (count) => (
  <div
    style={{
      display: "inline-flex",
      justifyContent: "space-between",
      width: "100%",
    }}
  >
    {Object.keys(count).map((key) => (
      <div
        key={key}
        className="cyberFont bold yellowColor grid-center"
        style={{
          borderRight: "1px solid",
          borderLeft: "1px solid",
          padding: "0px 30px",
          fontSize: "22px",
        }}
      >
        <CountUp separator="," start={0} end={count[key]} duration={2.75} />
        <span style={{ textAlign: "center", fontSize: "14px" }}>
          {key.split("_").join(" ").toLocaleUpperCase()}
        </span>
      </div>
    ))}
  </div>
);

function filterByActionType(dataArray, filterArray) {
  return dataArray.filter((item) =>
    filterArray.some(
      (filter) => filter.action === item.action && filter.type === item.type
    )
  );
}

function filterByUser(dataArray, filterArray) {
  return dataArray.filter((item) =>
    filterArray.some((filter) => filter.user === item.email)
  );
}

export default function Analytics(props) {
  const [topActions, setTopActions] = useState([]);
  const [topUsers, setTopUsers] = useState([]);
  const [topUsersLoading, setTopUsersLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const [usersLoading, setUsersLoading] = useState(false);

  const [userActions, setUserActions] = useState([]);
  const [userActionsLoading, setUserActionsLoading] = useState(null);

  const [actions, setActions] = useState([]);
  const [filteredActions, setFilteredActions] = useState([]);
  const [actionsLoading, setActionsLoading] = useState(false);
  const [actionUsers, setActionUsers] = useState([]);

  const [actionUsersLoading, setActionUsersLoading] = useState(null);

  const [counts, setCounts] = useState({});
  const [lineChartLoading, setLineChartLoading] = useState(false);
  const [countsLoading, setCountsLoading] = useState(false);
  const [topActionsLoading, setTopActionsLoading] = useState(false);
  const [weeklyTimelineLoading, setWeeklyTimelineLoading] = useState(false);
  const [userTimelineLoading, setUserTimelineLoading] = useState(false);

  const [dailyTimeline, setDailyTimeline] = useState([]);
  const [weeklyTimeline, setWeeklyTimeline] = useState([]);
  const [userTimeline, setUserTimeline] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [events, setEvents] = useState([]);
  const timelineRef = useRef(null);

  const [timelineUserFilter, setTimelineUserFilter] = useState("");
  const [timelineActionFilter, setTimelineActionFilter] = useState("");

  const [cancelToken, setCancelToken] = useState(null);
  const [userSuggestions, setUserSuggestions] = useState([]);

  const [actionSuggestions, setActionSuggestions] = useState([]);

  const timeframeOptions = [
    {
      value: 7,
      label: "7 DAYS",
    },
    {
      value: 14,
      label: "14 DAYS",
    },
    {
      value: 30,
      label: "30 DAYS",
    },
  ];

  const [timeframe, setTimeframe] = useState(timeframeOptions[2]);

  // const socket = io(SERVER_URL, {
  //   path: "/socket",
  //   transports: ["polling", "websocket"],
  //   secure: true,
  //   extraHeaders: {
  //     "Content-Type": "text/plain; charset=utf-8",
  //   },
  // });

  useEffect(() => {
    getCounts();

    // socket.on("connect", () => {
    //   console.log("Socket connected:", socket.id);
    //   socket.emit("login", {
    //     email: props.user.email,
    //   });
    // });

    // socket.on("connect_error", (err) => {
    //   console.error("Connection error:", err);
    // });

    // socket.on("disconnect", (reason) => {
    //   console.log("Socket disconnected:", reason);
    // });

    // socket.on("new-analytics-event", (event) => {
    //   setEvents((prevEvents) => {
    //     const newEvents = [...prevEvents, event];
    //     if (newEvents.length > 1) {
    //       newEvents.shift();
    //     }
    //     return newEvents;
    //   });
    //   if (timelineRef.current) {
    //     timelineRef.current.scrollLeft = timelineRef.current.scrollWidth;
    //   }
    // });

    // return () => {
    //   socket.off("connect");
    //   socket.off("new-analytics-event");
    // };
  }, []);

  const getStatsRequest = async (endpoint) => {
    const response = await api.adminReq(
      "POST",
      `${adminUrl}/analytics/${endpoint}`,
      {
        "auth-token": props.token,
      },
      {}
    );
    return response.data;
  };

  const getUsers = async (page) => {
    setUsersLoading(true);
    const data = await getStatsRequest(`users?page=${page}`);
    setUsersLoading(false);
    setUsers(data);
  };

  const getActions = async (page) => {
    setActionsLoading(true);
    const data = await getStatsRequest(`actions?page=${page}`);
    setActionsLoading(false);
    setActions(data);
  };

  const getTopUsers = async () => {
    setTopUsersLoading(true);
    const data = await getStatsRequest("top-users");
    setTopUsersLoading(false);
    setTopUsers(data);
  };

  const getCounts = async () => {
    setCountsLoading(true);
    const data = await getStatsRequest("count");
    setCountsLoading(false);
    setCounts(data);
  };

  const getTopActions = async () => {
    setTopActionsLoading(true);
    const data = await getStatsRequest("top-actions");
    setTopActionsLoading(false);
    setTopActions(data);
  };

  const getWeeklyTimeline = async (user, action, onlyChartLoading) => {
    setUserSuggestions([]);
    if (user || action || onlyChartLoading) {
      setLineChartLoading(true);
    } else {
      setWeeklyTimelineLoading(true);
    }
    const data = await getStatsRequest(
      `period-timeline?period=${timeframe.value}&user=${user}&action=${action}`
    );
    if (user || action || onlyChartLoading) {
      setLineChartLoading(false);
    } else {
      setWeeklyTimelineLoading(false);
    }
    setWeeklyTimeline(data);
  };

  const getUserTimeline = async (user, limit, period, noLoading) => {
    if (!noLoading) {
      setUserTimelineLoading(true);
    }

    const data = await getStatsRequest(
      `user-timeline?user=${user}&period=${period}&limit=${limit}`
    );

    setUserTimelineLoading(false);

    setEvents(data);
    if (user) {
      setUserTimeline(data);
    }

    if (!noLoading && timelineRef.current) {
      timelineRef.current.scrollLeft = timelineRef.current.scrollWidth;
    }
  };

  const getUserActions = async (email) => {
    if (userActions[0]?.user === email) return;
    setUserActions([]);
    setUserActionsLoading(email);
    window.location.href = `#${email}`;
    await getUserTimeline(email, 500, 300);
    const data = await getStatsRequest(`user-actions?user=${email}`);
    setUserActionsLoading(null);
    setUserActions(data);
    if (timelineRef.current) {
      timelineRef.current.scrollLeft = timelineRef.current.scrollWidth;
    }
  };

  const getActionUsers = async (action, type) => {
    if (
      `${actionUsers[0]?.action}_${actionUsers[0]?.type}` ===
      `${action}_${type}`
    )
      return;
    setActionUsers([]);
    setActionUsersLoading(`${action}_${type}`);
    const data = await getStatsRequest(
      `action-users?action=${action}&type=${type}`
    );
    setActionUsersLoading(null);
    setActionUsers(data);
  };

  const filterTimeline = (e) => {
    e.preventDefault();
    getWeeklyTimeline(timelineUserFilter, timelineActionFilter, null);
  };

  const autocompleteUsers = async (value) => {
    setTimelineUserFilter(value);
    if (value && value.length > 1) {
      if (cancelToken) {
        cancelToken.cancel("Canceled");
      }
      const source = axios.CancelToken.source();
      setCancelToken(source);
      const response = await api.adminReq(
        "POST",
        `${adminUrl}/analytics/autocomplete-users?q=${value}`,
        {
          "auth-token": props.token,
        },
        {},
        source.token
      );
      if (
        users &&
        users.length > 0 &&
        response.data &&
        response.data.length > 0
      ) {
        const filtered = filterByUser(users, response.data);
        setFilteredUsers(filtered);
      }
      setUserSuggestions(response.data);
    }
    return () => {
      if (cancelToken) {
        cancelToken.cancel("Canceled");
      }
    };
  };

  const autocompleteActions = async (value) => {
    setTimelineActionFilter(value);
    if (value && value.length > 1) {
      if (cancelToken) {
        cancelToken.cancel("Canceled");
      }
      const source = axios.CancelToken.source();
      setCancelToken(source);
      const response = await api.adminReq(
        "POST",
        `${adminUrl}/analytics/autocomplete-actions?q=${value}`,
        {
          "auth-token": props.token,
        },
        {},
        source.token
      );

      if (
        actions &&
        actions.length > 0 &&
        response.data &&
        response.data.length > 0
      ) {
        const filtered = filterByActionType(actions, response.data);
        setFilteredActions(filtered);
      }
      setActionSuggestions(response.data);
    }
    return () => {
      if (cancelToken) {
        cancelToken.cancel("Canceled");
      }
    };
  };

  useEffect(() => {
    getWeeklyTimeline(timelineUserFilter, timelineActionFilter, true);
  }, [timeframe]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (userActions[0]) {
        getUserTimeline(userActions[0].user, 500, 300, true);
      } else {
        getUserTimeline(null, 500, 7, true);
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [userActions]);

  const tabs = [
    {
      label: "Overall",
      icon: <RiGlobalFill />,
      component: (
        <Overall
          placeholder={"Switch Timeframe"}
          timeframe={timeframe}
          setTimeframe={setTimeframe}
          options={timeframeOptions}
          onTimelineFilter={filterTimeline}
          userSuggestions={userSuggestions}
          onTimelineUserFilterChange={(e) => autocompleteUsers(e.target.value)}
          userFilter={timelineUserFilter}
          setTimelineUserFilter={(value) => setTimelineUserFilter(value)}
          setUserSuggestions={setUserSuggestions}
          onTimelineActionFilterChange={(e) =>
            autocompleteActions(e.target.value)
          }
          actionFilter={timelineActionFilter}
          setTimelineActionFilter={(value) => setTimelineActionFilter(value)}
          setActionSuggestions={setActionSuggestions}
          actionSuggestions={actionSuggestions}
          userTimelineLoading={userTimelineLoading}
          events={events}
          timelineRef={timelineRef}
          weeklyTimeline={weeklyTimeline}
          weeklyTimelineLoading={weeklyTimelineLoading}
          topActionsLoading={topActionsLoading}
          topActions={topActions}
          topUsers={topUsers}
          topUsersLoading={topUsersLoading}
          lineChartLoading={lineChartLoading}
          clearTimelineUserFilter={() => {
            setTimelineUserFilter("");
            setUserSuggestions([]);
            getWeeklyTimeline(null, timelineActionFilter, true);
          }}
          clearTimelineActionFilter={() => {
            setTimelineActionFilter("");
            setActionSuggestions([]);
            getWeeklyTimeline(timelineUserFilter, null, true);
          }}
        />
      ),
      requests: () => {
        getTopUsers();
        getTopActions();
        // getWeeklyTimeline(null, null);
        getUserTimeline(null, 500, 7);
      },
    },
    {
      label: "Actions",
      icon: <MdOutlineAdsClick />,
      component: (
        <Actions
          actions={
            filteredActions && filteredActions.length > 0
              ? filteredActions
              : actions
          }
          actionsLoading={actionsLoading}
          getActionUsers={(action, type) => getActionUsers(action, type)}
          actionUsers={actionUsers}
          actionUsersLoading={actionUsersLoading}
          // clearActionFilter={() => {
          //   setTimelineActionFilter("");
          //   getActions(0);
          // }}
          // actionFilter={timelineActionFilter}
          // filterActions={filterActions}

          onTimelineActionFilterChange={(e) =>
            autocompleteActions(e.target.value)
          }
          actionFilter={timelineActionFilter}
          setTimelineActionFilter={(value) => setTimelineActionFilter(value)}
          setActionSuggestions={setActionSuggestions}
          actionSuggestions={actionSuggestions}
          clearTimelineActionFilter={() => {
            setTimelineActionFilter("");
            setFilteredActions([]);
            setActionSuggestions([]);
            getActions(0);
          }}
        />
      ),
      requests: () => {
        getActions(0);
      },
    },
    {
      label: "Users",
      icon: <FaUsers />,
      component: (
        <Users
          users={
            filteredUsers && filteredUsers.length > 0 ? filteredUsers : users
          }
          usersLoading={usersLoading}
          getUserActions={(email) => getUserActions(email)}
          userActions={userActions}
          userActionsLoading={userActionsLoading}
          timelineRef={timelineRef}
          events={userTimeline}
          onTimelineFilter={filterTimeline}
          userSuggestions={userSuggestions}
          onTimelineUserFilterChange={(e) => autocompleteUsers(e.target.value)}
          userFilter={timelineUserFilter}
          setTimelineUserFilter={(value) => setTimelineUserFilter(value)}
          setUserSuggestions={setUserSuggestions}
          clearTimelineUserFilter={() => {
            setTimelineUserFilter("");
            setUserSuggestions([]);
            setFilteredUsers([]);
            getUsers(0);
            setUserActions([]);
            setUserTimeline([]);
          }}
        />
      ),
      requests: () => {
        getUsers(0);
      },
    },
    // {
    //   label: "Timeline",
    //   icon: <MdOutlineTimeline />,
    // },
  ];

  const clearInputs = () => {
    setUserSuggestions([]);
    setTimelineUserFilter("");
    setActionSuggestions([]);
    setTimelineActionFilter("");
    setActionUsers([]);
    setUserActions([]);
    setUserTimeline([]);
    setFilteredUsers([]);
    setFilteredActions([]);
  };

  useEffect(() => {
    clearInputs();
    tabs[activeTab].requests();
  }, [activeTab]);

  return (
    <div style={{ padding: "0rem 0rem 0rem 1rem" }} className="darkText">
      <div>
        <h2 className="cyberFont bold yellowColor">Cavalier Analytics</h2>
        <hr />
      </div>
      <div>
        {countsLoading ? (
          <LoaderIcon type="spin" style={{ fill: "#FBCD4E" }} />
        ) : (
          counts && <div>{Counter(counts)}</div>
        )}
        <hr />
        <div
          style={{
            display: "inline-flex",
            justifyContent: "start",
            alignItems: "center",
            width: "100%",
            backgroundColor: "#222831",
            padding: "20px",
            borderRadius: "20px",
          }}
        >
          {tabs.map((item, index) => (
            <div
              key={index}
              className={
                activeTab === index
                  ? "grid-center cyberFont bold yellowColor cursor underline"
                  : "grid-center cyberFont cursor lightGray underlineHover"
              }
              style={{
                margin: "0px",
                fontSize: "32px",
                padding: "0px 30px",
              }}
              onClick={() => setActiveTab(index)}
            >
              {item.icon}
              {item.label}
            </div>
          ))}
        </div>

        {tabs[activeTab].component}
      </div>
    </div>
  );
}
