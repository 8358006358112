import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function SimpleAccordion() {
  const classes = useStyles();

  const faq = [
    {
      question: "Where do these results come from?",
      answer:
        "Every credential presented in Cavalier was stolen directly from the browsers of victims who had a stealer type malware executed on their machine. These malwares are spread by tricking a victim to download a malicious file through various methods. the attacks are usually performed by threat actors in global opportunistic campaigns, with little discretion, so employees in companies that aren't even targeted often have their corporate credentials stolen.",
    },
    {
      question: "How does Hudson Rock obtain this information?",
      answer:
        "Hudson Rock acquires and purchases compromised information directly from top-tier threat actors, and closed circle hacking groups. What sets this information apart, is its low availability in other high-fidelity threat intelligence companies, and its high accessibility to hacker groups looking for potential targets. Our operational knowhow, and our boots-on-the-ground approach to cybercrime comes from the IDF's 8200 Cybercrime division, and its efforts to thwart nation-state adversaries and professional threat actors.",
    },
    {
      question:
        "How do we differentiate between compromised employees and compromised clients?",
      answer: (
        <>
          Hudson Rock uses different logics to differentiate between compromised
          employees and compromised clients. Credentials containing an email
          address of a company (i.e @Tesla.com) that are used to access a
          Tesla.com domain most likely belong to an employee of that company,
          while a @gmail.com email address accessing the same domain is likely a
          client. Additional logic is used to differentiate employees from
          clients - the type of technologies in the domain that the compromised
          credentials accessed may indicate that they belong to an employee, for
          example: The username “john” signing into citrix.tesla.com /
          tesla.com/vpn/login / webmail.tesla.com
        </>
      ),
    },
    {
      question:
        "How is Cavalier different than other threat intelligence platforms?",
      answer: (
        <>
          <p>
            Many cybercrime intelligence intelligence platforms aggregate data
            from publicly available database leaks, whereas Hudson Rock does not
            pursue generally available credentials as its main goal. Publicly
            available databases enable finding old or unrelated credentials by
            the same user, leaving the hacker to check if they were reused in
            other, more sensitive services that they’re trying to access. We
            consider this information to be less valuable, for several reasons:
          </p>
          <ul className="employee-nav">
            <li>
              1. Time - stolen credentials are usually published (for free)
              after hackers think the leads are not very valuable. This is
              usually AFTER the actionable threats occurred. Hudson Rock
              integrates data from compromised computers merely days after they
              were compromised, and at the same time they are obtained by
              sophisticated threat actors attempting to perform data breaches
              and ransomware attacks.
            </li>
            <li>
              2. Relevancy to clients - data from info-stealers is the most
              prominent attack vector used by sophisticated hacking groups to
              obtain initial access to company servers, or to overtake end-user
              accounts, the data is unrivalled because hackers are able to
              bypass traditional security measures such as 2 factor
              authentication by injecting cookies from the compromised
              computers, or finding the backup codes for revoking the 2 factor
              authentication stored on the compromised computer.
            </li>
            <li>
              3. Password reuse dependency - While password reuse is an issue,
              employees don't tend to reuse passwords to critical infrastructure
              across different services and end users don’t always reuse
              passwords across all services they use. The passwords originating
              from leaked databases are not remotely as impactful as passwords
              stolen from the browsers of the victims where there is a direct
              link between the domain they browsed into and their emails and
              plaintext passwords. This is because when a victim has had a
              stealer executed on their machine, all the domains, emails,
              usernames, and passwords they have ever used are captured.
            </li>
          </ul>
          <p>
            This is because when a victim had a stealer executed on his machine,
            all the domains, emails, usernames, and passwords they have ever
            used are captured.
          </p>
        </>
      ),
    },
    {
      question: "How can we prevent such attacks?",
      answer: (
        <>
          <p>
            In order to avoid having a machine in your organization compromised
            by these global malware campaigns,
            <br /> it is advised to adhere to basic security guidelines such as:
          </p>
          <ul className="employee-nav">
            <li>
              - Enforcing file download restrictions on machine devices to
              prevent the download of malware.
            </li>
            <li>
              - Force a password change every 6 months, apply safe length and
              complexity requirements on passwords.
            </li>
          </ul>
          It's important to note that even if all internal computers are secure,
          employees can often access company resources through third party
          computers, which might be compromised.
        </>
      ),
    },
    {
      question:
        "We can see compromised corporate credentials in Cavalier, what can we do?",
      answer: (
        <>
          Hudson Rock recommends revoking all the compromised credentials
          immediately, in order to prevent further access to your network by
          potential hackers with access to these credentials. As long as these
          credentials remain active they can be used to access the
          organization's network in order to deploy ransomware attacks,
          information breaches, corporate espionage, and other intrusions. We
          advise alerting the employee to the breach and ask them to reset all
          personal credentials as well. After revoking the compromised
          credentials, organizations should investigate the usage of these
          credentials to see if they were used maliciously, and if persistent
          access was acquired by using them. In the case of compromised clients
          credentials, we advise resetting the passwords of said clients and
          issuing an email explaining to them that steps to boost their security
          were taken place.
        </>
      ),
    },
  ];

  return (
    <div
      className={classes.root + " faqBody text-left"}
      style={{ width: "100%" }}
    >
      <div className="text-left">
        <h1>FAQ</h1>
        <hr className="text-left" style={{ width: "10%" }}></hr>
      </div>
      {faq.map((content) => (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              {content.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="faqA">{content.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
      {/* <div>
        <br />
        <Link to="/contact">
          <button className="loginBtn contact-btn">Contact Us</button>
        </Link>
      </div> */}
    </div>
  );
}
