/* eslint-disable */

import React from "react";
import "./EmailTemplate.css";

const EmailTemplate = ({ items }) => {
  return (
    <div>
      <center
        className="wrapper"
        style={{ backgroundColor: "#f3f3f3", borderRadius: "20px" }}
        data-link-color="#ba1f1f"
        // data-body-style="font-size:14px; font-family:inherit; color:#000000; background-color:#f3f3f3;"
      >
        <div className="webkit">
          <table
            cellPadding="0"
            cellSpacing="0"
            border="0"
            width="100%"
            className="wrapper"
            style={{ backgroundColor: "f3f3f3" }}
          >
            <tr>
              <td
                valign="top"
                style={{ backgroundColor: "f3f3f3" }}
                width="100%"
              >
                <table
                  width="100%"
                  role="content-container"
                  className="outer"
                  align="center"
                  cellPadding="0"
                  cellSpacing="0"
                  border="0"
                >
                  <tr>
                    <td width="100%">
                      <table
                        width="100%"
                        cellPadding="0"
                        cellSpacing="0"
                        border="0"
                      >
                        <tr>
                          <td>
                            <table
                              width="100%"
                              cellPadding="0"
                              cellSpacing="0"
                              border="0"
                              style={{ width: "100%", maxWidth: "600px" }}
                              align="center"
                            >
                              <tr>
                                <td
                                  role="modules-container"
                                  style={{
                                    padding: "0px 0px 0px 0px",
                                    color: "#000000",
                                    textAlign: "left",
                                    backgroundColor: "#FFFFFF",
                                  }}
                                  width="100%"
                                  align="left"
                                >
                                  <table
                                    className="module preheader preheader-hide"
                                    role="module"
                                    data-type="preheader"
                                    border="0"
                                    cellPadding="0"
                                    cellSpacing="0"
                                    width="100%"
                                    style={{
                                      display: "none !important",
                                      msoHide: "all",
                                      visibility: "hidden",
                                      opacity: "0",
                                      color: "transparent",
                                      height: "0",
                                      width: "0",
                                    }}
                                  >
                                    <tr>
                                      <td role="module-content">
                                        <p></p>
                                      </td>
                                    </tr>
                                  </table>
                                  <table
                                    border="0"
                                    cellPadding="0"
                                    cellSpacing="0"
                                    align="center"
                                    width="100%"
                                    role="module"
                                    data-type="columns"
                                    style={{
                                      padding: "0px 0px 0px 0px",
                                      backgroundColor: "black",
                                    }}
                                    data-distribution="1"
                                  >
                                    <tbody>
                                      <tr role="module-content">
                                        <td height="100%" valign="top">
                                          <table
                                            width="580"
                                            style={{
                                              width: "580px",
                                              borderSpacing: "0",
                                              borderCollapse: "collapse",
                                              margin: "0px 10px 0px 10px",
                                            }}
                                            cellPadding="0"
                                            cellSpacing="0"
                                            align="left"
                                            border="0"
                                            className="column column-0"
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    padding: "0px",
                                                    margin: "0px",
                                                    borderSpacing: "0",
                                                  }}
                                                >
                                                  <table
                                                    className="wrapper"
                                                    role="module"
                                                    data-type="image"
                                                    border="0"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    width="100%"
                                                    style={{
                                                      tableLayout: "fixed",
                                                    }}
                                                    data-muid="81be3b34-58b3-4e33-bc97-7ed24efe3119"
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            fontSize: "6px",
                                                            lineHeight: "10px",
                                                            padding:
                                                              "0px 0px 0px 0px",
                                                          }}
                                                          valign="top"
                                                          align="center"
                                                        >
                                                          <img
                                                            className="max-width"
                                                            border="0"
                                                            style={{
                                                              display: "block",
                                                              color: "#000000",
                                                              textDecoration:
                                                                "none",
                                                              fontFamily:
                                                                "Helvetica, arial, sans-serif",
                                                              fontSize: "16px",
                                                              maxWidth:
                                                                "80% !important",
                                                              width: "80%",
                                                              height:
                                                                "auto !important",
                                                            }}
                                                            width="290"
                                                            alt=""
                                                            data-proportionally-constrained="true"
                                                            data-responsive="true"
                                                            src="http://cdn.mcauto-images-production.sendgrid.net/2ce7ed43c7bb03dd/d922cbde-91cd-4e80-ab1b-32d91e07c58a/1400x359.png"
                                                          />
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <table
                                    className="module"
                                    role="module"
                                    data-type="text"
                                    border="0"
                                    cellPadding="0"
                                    cellSpacing="0"
                                    width="100%"
                                    style={{ tableLayout: "fixed" }}
                                    data-muid="5c3d8a95-55cf-4493-a1e0-678e4de012f4"
                                    data-mc-module-version="2019-10-22"
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          style={{
                                            padding: "18px 0px 18px 0px",
                                            lineHeight: "22px",
                                            textAlign: "inherit",
                                            backgroundColor: "#FBCD4E",
                                          }}
                                          height="100%"
                                          valign="top"
                                          role="module-content"
                                        >
                                          <div>
                                            <div
                                              style={{
                                                fontFamily: "inherit",
                                                textAlign: "center",
                                              }}
                                            >
                                              <a href="http://www.google.com">
                                                <span
                                                  style={{
                                                    fontFamily:
                                                      "'lucida sans unicode', 'lucida grande', sans-serif",
                                                    color: "#222831",
                                                  }}
                                                >
                                                  D
                                                </span>
                                              </a>
                                              <span
                                                style={{
                                                  fontFamily:
                                                    "'lucida sans unicode', 'lucida grande', sans-serif",
                                                  color: "#222831",
                                                }}
                                              >
                                                ear Infostealers Subscriber
                                              </span>
                                            </div>
                                            <div
                                              style={{
                                                fontFamily: "inherit",
                                                textAlign: "center",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontFamily:
                                                    "'lucida sans unicode', 'lucida grande', sans-serif",
                                                  color: "#222831",
                                                }}
                                              >
                                                {items.length > 1
                                                  ? "You might find these new articles interesting:"
                                                  : "You might find this new article interesting:"}
                                              </span>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <table
                                    className="module articlesModule"
                                    role="module"
                                    data-type="code"
                                    border="0"
                                    cellPadding="0"
                                    cellSpacing="0"
                                    width="100%"
                                    style={{ tableLayout: "fixed" }}
                                    data-muid="85e4c884-40b6-4404-ac12-82de87e3709e"
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          height="100%"
                                          valign="top"
                                          role="module-content"
                                        >
                                          {items.map((item, index) => (
                                            <div
                                              className="articleDiv"
                                              key={index}
                                            >
                                              <a href={item.link}>
                                                <img
                                                  src={item.imageLink}
                                                  className="articleImage"
                                                  alt="Image"
                                                />
                                              </a>
                                              <hr />
                                              <p className="articleTitle">
                                                {item.title}
                                              </p>
                                              <br />
                                              <a
                                                className="articleLink"
                                                href={item.link}
                                              >
                                                View Article
                                              </a>
                                            </div>
                                          ))}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <table
                                    className="module"
                                    role="module"
                                    data-type="spacer"
                                    border="0"
                                    cellPadding="0"
                                    cellSpacing="0"
                                    width="100%"
                                    style={{ tableLayout: "fixed" }}
                                    data-muid="0fab0d29-049a-442d-8faf-5efd102c5669.1"
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          style={{
                                            padding: "0px 0px 30px 0px",
                                            backgroundColor: "#fbcd4e",
                                          }}
                                          role="module-content"
                                        ></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <div
                                    data-role="module-unsubscribe"
                                    className="module"
                                    role="module"
                                    data-type="unsubscribe"
                                    style={{
                                      color: "#444444",
                                      fontSize: "12px",
                                      lineHeight: "20px",
                                      padding: "16px 16px 16px 16px",
                                      textAlign: "Center",
                                    }}
                                    data-muid="4e838cf3-9892-4a6d-94d6-170e474d21e5"
                                  >
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        lineHeight: "20px",
                                      }}
                                    >
                                      <a
                                        className="Unsubscribe--unsubscribeLink"
                                        href="{{{unsubscribe}}}"
                                        target="_blank"
                                      >
                                        Unsubscribe
                                      </a>
                                    </p>
                                  </div>
                                  <table
                                    border="0"
                                    cellPadding="0"
                                    cellSpacing="0"
                                    className="module"
                                    data-role="module-button"
                                    data-type="button"
                                    role="module"
                                    style={{ tableLayout: "fixed" }}
                                    width="100%"
                                    data-muid="cbffb97f-4af5-4eb4-acb8-74c0d6f9f847"
                                  ></table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
      </center>
    </div>
  );
};

export default EmailTemplate;
