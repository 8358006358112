import React from "react";
import { MultiSelect } from "react-multi-select-component";
import CreateApiClient from "../../API";
import { adminUrl } from "../../Config";

const api = CreateApiClient();

export default function MultiUserSelection(props) {
  const DefaultItemRenderer = ({ checked, option, onClick, disabled }) => (
    <div className={`item-renderer ${disabled && "disabled"}`}>
      <>
        <input
          type="checkbox"
          onChange={onClick}
          checked={checked}
          tabIndex={-1}
          disabled={disabled}
          className="filter-input"
        />
        <span className="filter-label">{option.label}</span>
      </>
    </div>
  );

  const filterOptions = async (options, filter) => {
    if (filter && filter.length > 1) {
      api
        .adminReq(
          "GET",
          `${adminUrl}/autocomplete-users?q=${filter}`,
          props.headers,
          {}
        )
        .then((res) => {
          props.setUsers(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (!filter) {
      return options;
    }
    const re = new RegExp(filter, "i");
    return options.filter(({ value }) => value && value.match(re));
  };
  return (
    <MultiSelect
      overrideStrings={{
        allItemsAreSelected: "ALL USERS ARE SELECTED.",
        selectSomeItems: "Users",
      }}
      hasSelectAll={false}
      ItemRenderer={DefaultItemRenderer}
      shouldToggleOnHover={true}
      options={props.users}
      value={props.usersSelected && props.usersSelected}
      onChange={props.setUsersSelected}
      labelledBy="Users"
      filterOptions={filterOptions}
    />
  );
}
