/* eslint-disable */

import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { MdRefresh } from "react-icons/md";
import LoaderIcon from "react-loader-icon";
import filters from "../../company_filters.json";
import CompanySearch from "./CompanySearch";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(0),
    verticalAlign: "bottom",
  },
}));

export default function CompanyFilters(props) {
  const classes = useStyles();

  const [industries, setIndustries] = useState([]);
  const [countries, setCountries] = useState([]);
  const [industriesSelected, setIndustriesSelected] = useState([]);
  const [countriesSelected, setCountriesSelected] = useState([]);
  const [regions, setRegions] = useState([]);

  useEffect(() => {
    setIndustries(filters.industries);
    setCountries(filters.countries);
    setRegions(filters.usa_regions);

    let preferences = localStorage.getItem("preferences");
    preferences = JSON.parse(preferences);
    if (preferences) {
      setIndustriesSelected(preferences.industries);
      setCountriesSelected(preferences.countries);
    }
  }, []);

  return (
    <div>
      <MdRefresh
        className="cursor"
        size={35}
        style={{
          fill: "#fecd4e",
          position: "absolute",
          top: "10px",
          right: "10px",
        }}
        onClick={() => {
          setIndustriesSelected([]);
          setCountriesSelected([]);
          props.clear();
        }}
      />
      <h4 style={{ color: "#fecd4e" }}>Filter By Industry Or Location</h4>
      <div
        className="filters-inpage"
        style={{
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CompanySearch
          page="systems"
          loadOptions={props.loadOptions}
          industries={industries}
          countries={countries}
          industriesSelected={industriesSelected}
          setIndustriesSelected={(e) => {
            if (e.length > 0 && e.length !== industries.length) {
              setIndustriesSelected([e[e.length - 1]]);
            } else {
              setIndustriesSelected(e);
            }
          }}
          setCountriesSelected={(e) => {
            if (e.length > 0 && e.length !== countries.length) {
              setCountriesSelected([e[e.length - 1]]);
            } else {
              setCountriesSelected(e);
            }
          }}
          countriesSelected={countriesSelected}
          isUsa={props.isUsa}
        />
        {industriesSelected.length > 0 && countriesSelected.length > 0 ? (
          <Button
            id="systemsFilterBtn"
            type="button"
            variant="contained"
            className={classes.button + " searchBtn desktopSearchBtn"}
            endIcon={
              props.upgradeLoader === "systemsFilterBtn" ? (
                <LoaderIcon
                  className="removeLoader tableLoader"
                  style={{
                    position: "relative",
                    top: "-10px",
                    right: "0px",
                  }}
                />
              ) : (
                <Icon>send</Icon>
              )
            }
            onClick={() =>
              props.getTimeline(industriesSelected, countriesSelected)
            }
          >
            Filter
          </Button>
        ) : (
          <Button
            type="button"
            variant="contained"
            className={classes.button + " searchBtn grayBtn desktopSearchBtn"}
            endIcon={<Icon>send</Icon>}
          >
            Filter
          </Button>
        )}
      </div>
    </div>
  );
}
