/* eslint-disable */

import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import {
  FaCloudDownloadAlt,
  FaTrash,
  FaUser,
  FaUserInjured,
  FaUserSecret,
  FaUserTie,
} from "react-icons/fa";
import { MdOutlinePowerSettingsNew, MdRefresh } from "react-icons/md";
import LoaderIcon from "react-loader-icon";
import { publicAddress } from "../../Config";

const StyledDataGrid = withStyles({
  root: {
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "200px !important",
      overflowY: "scroll",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "200px !important",
      whiteSpace: "normal",
      overflowY: "scroll",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important",
    },
  },
})(DataGrid);

export default function AdminGrid(props) {
  const [copied, isCopied] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [idOpen, seIdOpen] = useState(null);
  const [visibleColumns, setVisibleColumns] = useState({});
  const [since, setSince] = useState("");
  const [until, setUntil] = useState("");

  const handleClick = (event, id, field) => {
    setAnchorEl(event.currentTarget);
    seIdOpen(field + "-" + id);
  };

  const closePopper = () => {
    setAnchorEl(null);
    seIdOpen(null);
  };

  const isOpen = Boolean(anchorEl);

  const id = isOpen ? "simple-popover" : undefined;

  const copyToClipboard = async (id, url) => {
    await navigator.clipboard.writeText(url);
    isCopied(`${id}_${url}`);
    setTimeout(() => {
      isCopied(null);
    }, 2000);
  };

  const renderList = (params) => (
    <>
      <div
        id="title"
        className="cursor cyberFont bold MuiDataGrid-cell MuiDataGrid-cell--textLeft"
        onClick={(event) => handleClick(event, params.id, params.field)}
        style={{
          display: "block",
          textAlign: "left",
          height: "60px",
          overflowY: "scroll",
        }}
      >
        {params.value &&
          params.value.length > 0 &&
          params.value.map((domain, index) => (
            <div key={index}>
              <span>
                {params.field !== "external_domains" ? domain : domain.name}
              </span>
              <br />
            </div>
          ))}

        {/* <ul style={{ listStyle: "none", textAlign: "left" }}>
          {params.value &&
            params.value.map((domain, index) => <li>{domain}</li>)}
        </ul> */}
      </div>
      <div style={{ color: "black" }}>
        <Popover
          id={id}
          open={idOpen === `${params.field}-${params.id}`}
          anchorEl={anchorEl}
          onClose={closePopper}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          style={{ maxHeight: "500px" }}
        >
          <div style={{ margin: "0px 10px" }}>
            <TextField
              onChange={(e) => props.setNewDomain(e.target.value)}
              id="standard-name"
              label={params.field}
              placeholder={params.field}
              value={props.newDomain}
              InputProps={{
                endAdornment:
                  props.updateLoader === `${params.id}_${props.newDomain}` ? (
                    <LoaderIcon
                      className="tableLoader"
                      type="spin"
                      style={{ width: "40px", height: "46px", margin: "0px" }}
                    />
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        props.updateUsers(
                          "add",
                          params.id,
                          params.field,
                          props.newDomain
                        );
                      }}
                    >
                      ADD
                    </Button>
                  ),
              }}
            />
          </div>
          {params.value &&
            params.value.map((domain, index) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0px 15px",
                  borderBottom: "1px solid",
                }}
              >
                <Typography key={index} sx={{ p: 2 }} className="lightText">
                  {params.field !== "external_domains" ? domain : domain.name}
                  <br />
                </Typography>
                {props.updateLoader ===
                `${params.id}_${
                  params.field !== "external_domains" ? domain : domain.name
                }` ? (
                  <LoaderIcon
                    className="tableLoader"
                    type="spin"
                    style={{ width: "20px", height: "24px", margin: "0px" }}
                  />
                ) : (
                  <button
                    className="remove"
                    value={
                      params.field !== "external_domains" ? domain : domain.name
                    }
                    onClick={() =>
                      props.updateUsers(
                        "remove",
                        params.id,
                        params.field,
                        params.field !== "external_domains"
                          ? domain
                          : domain.name
                      )
                    }
                  >
                    X
                  </button>
                )}
              </div>
            ))}
        </Popover>
      </div>
    </>
  );

  const columns = [
    { field: "_id", headerName: "ID", width: 90 },
    {
      field: "name",
      headerName: "Name",
      width: 120,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      editable: true,
    },
    {
      field: "role",
      headerName: "Role",
      width: 110,
      editable: true,
    },
    {
      field: "domains",
      headerName: "Domains",
      width: 150,
      editable: false,
      renderCell: (params) => renderList(params),
    },
    {
      field: "apps",
      headerName: "APKs",
      width: 150,
      editable: false,
      renderCell: (params) => renderList(params),
    },
    {
      field: "monitored_domains",
      headerName: "3rd Party",
      width: 150,
      editable: false,
      renderCell: (params) => renderList(params),
    },
    {
      field: "external_domains",
      headerName: "External Domains",
      width: 150,
      editable: false,
      renderCell: (params) => renderList(params),
    },
    {
      field: "tags",
      headerName: "Tags",
      width: 110,
      editable: true,
      renderCell: (params) => renderList(params),
    },
    {
      field: "url_hash",
      headerName: "URL",
      width: 80,
      editable: false,
      renderCell: (params) => (
        <>
          {params.row.url_hash ? (
            <div className="copyUrlBtn tableBtn">
              {copied ===
              `${params.getValue(
                params.id,
                "_id"
              )}_${publicAddress}/tokenaccess?token=${params.row.url_hash}` ? (
                <span
                  className="success cyberFont bold"
                  style={{ fontSize: "12px" }}
                >
                  Copied!
                </span>
              ) : (
                <Button
                  onClick={() =>
                    copyToClipboard(
                      params.getValue(params.id, "_id"),
                      `${publicAddress}/tokenaccess?token=${params.row.url_hash}`
                    )
                  }
                  className="searchBtn cyberFont bold"
                >
                  Copy
                </Button>
              )}
            </div>
          ) : (
            "X"
          )}
        </>
      ),
    },
    {
      field: "active",
      headerName: "Active",
      width: 80,
      editable: true,
    },
    {
      field: "censor_date",
      headerName: "Censor Date",
      width: 180,
      editable: true,
      renderCell: (params) => (
        <>
          <div className="copyUrlBtn tableBtn">
            <input
              style={{
                width: "100%",
                borderRadius: "5px",
                padding: "4px 8px",
              }}
              type="date"
              id="start"
              name="trip-start"
              value={
                params.row.censor_date
                  ? new Date(params.row.censor_date)
                      .toISOString()
                      .substring(0, 10)
                  : null
              }
              onChange={(e) => {
                props.updateUsers(
                  "edit",
                  params.row._id,
                  "censor_date",
                  e.target.value
                );
              }}
              min={new Date()}
            />
          </div>
        </>
      ),
    },
    {
      field: "ui_permissions",
      headerName: "API Enabled",
      width: 80,
      editable: false,
      renderCell: (params) => (
        <>
          {params.row.ui_permissions &&
          params.row.ui_permissions.includes("key")
            ? "YES"
            : "NO"}
        </>
      ),
    },
    {
      field: "api_key",
      headerName: "API Key",
      width: 80,
      editable: false,
      renderCell: (params) => (
        <>
          {params.row.api_permissions &&
          params.row.api_permissions.length > 0 ? (
            <div className="copyUrlBtn tableBtn">
              {copied ===
              `${params.getValue(params.id, "_id")}_${params.row.api_key}` ? (
                <span
                  className="success cyberFont bold"
                  style={{ fontSize: "12px" }}
                >
                  Copied!
                </span>
              ) : (
                <Button
                  onClick={() =>
                    copyToClipboard(
                      params.getValue(params.id, "_id"),
                      params.row.api_key
                    )
                  }
                  className="searchBtn cyberFont bold"
                >
                  Copy
                </Button>
              )}
            </div>
          ) : (
            "X"
          )}
        </>
      ),
    },
    {
      field: "exposed_dates",
      headerName: "Exposed Dates",
      width: 300,
      editable: false,
      renderCell: (params) => (
        <>
          <div className="censorship-dates-container">
            <input
              style={{
                width: "50%",
                borderRadius: "5px",
                padding: "4px 8px",
              }}
              type="date"
              selected={since}
              value={
                params.row.exposed_dates && params.row.exposed_dates.since
                  ? new Date(params.row.exposed_dates.since)
                      .toISOString()
                      .substring(0, 10)
                  : null
              }
              onChange={(e) => {
                console.log(e.target.value);
                props.updateUsers(
                  "edit",
                  params.row._id,
                  "exposed_dates.since",
                  e.target.value
                );
              }}
              dateFormat="yyyy-MM-dd"
              placeholderText="Since"
              className="censorship-date-picker"
            />
            <input
              style={{
                width: "50%",
                borderRadius: "5px",
                padding: "4px 8px",
              }}
              type="date"
              selected={until}
              value={
                params.row.exposed_dates && params.row.exposed_dates.until
                  ? new Date(params.row.exposed_dates.until)
                      .toISOString()
                      .substring(0, 10)
                  : null
              }
              onChange={(e) => {
                console.log(e.target.value);
                props.updateUsers(
                  "edit",
                  params.row._id,
                  "exposed_dates.until",
                  e.target.value
                );
              }}
              dateFormat="yyyy-MM-dd"
              placeholderText="Until"
              className="censorship-date-picker"
            />
          </div>
        </>
      ),
    },
    {
      field: "api_permissions",
      headerName: "API Permissions",
      width: 250,
      editable: false,
      // renderCell: (params) => renderList(params),
    },
    {
      field: "alert_emails",
      headerName: "Alert Emails",
      width: 150,
      editable: false,
      renderCell: (params) => renderList(params),
    },
  ];

  const rows = props.users;

  function customToolbar() {
    return (
      <div
        style={{
          textAlign: "left",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
          {props.csv.length > 0 && !props.loading ? (
            <CSVLink
              id="csvDownload"
              data={props.csv}
              filename={`all_users.csv`}
              className=""
              target="_blank"
              rel="noreferrer"
            >
              Click here if the file has not been downloaded
            </CSVLink>
          ) : (
            <Button
              color="primary"
              startIcon={<FaCloudDownloadAlt />}
              onClick={() => {
                props.exportAllToCsv(visibleColumns, columns);
              }}
            >
              Download all users
            </Button>
          )}
        </div>
        <div
          style={{
            textAlign: "left",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h5 className="cyberFont" style={{ margin: "0px" }}>
            Actions:
          </h5>
          <Button
            color="primary"
            startIcon={<FaTrash />}
            onClick={() => props.bulkUpdateUsers("delete", true, selectedRows)}
          >
            Delete
          </Button>
          <Button
            color="primary"
            startIcon={<MdRefresh />}
            onClick={() => props.bulkUpdateUsers("active", true, selectedRows)}
          >
            activate
          </Button>
          <Button
            color="primary"
            startIcon={<MdOutlinePowerSettingsNew />}
            onClick={() => props.bulkUpdateUsers("active", false, selectedRows)}
          >
            deactivate
          </Button>
          {/* <Button
            color="primary"
            startIcon={<FaHandRock />}
            onClick={() =>
              props.bulkUpdateUsers(
                "name",
                selectedRows[0].name === "ROCKHUDSONROCK"
                  ? "HudsonRock"
                  : "ROCKHUDSONROCK",
                selectedRows
              )
            }
          >
            rockhudsonrock
          </Button> */}
          <Button
            color="primary"
            startIcon={<FaUser />}
            onClick={() => props.bulkUpdateUsers("role", "User", selectedRows)}
          >
            User
          </Button>
          <Button
            color="primary"
            startIcon={<FaUserInjured />}
            onClick={() => props.bulkUpdateUsers("role", "MSSP", selectedRows)}
          >
            MSSP
          </Button>
          <Button
            color="primary"
            startIcon={<FaUserTie />}
            onClick={() =>
              props.bulkUpdateUsers("role", "Customer", selectedRows)
            }
          >
            Customer
          </Button>
          <Button
            color="primary"
            startIcon={<FaUserSecret />}
            onClick={() =>
              props.bulkUpdateUsers("role", "master", selectedRows)
            }
          >
            Master
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div id="hector-grid" style={{ height: "80vh", width: "100%" }}>
      <StyledDataGrid
        columnVisibilityModel={visibleColumns}
        onColumnVisibilityModelChange={(newModel) => {
          setVisibleColumns(newModel);
        }}
        className="hectorGrid"
        rows={rows}
        columns={columns}
        checkboxSelection={true}
        disableSelectionOnClick
        getRowId={(row) => row._id}
        exportDataAsCsv={(data) => {
          console.log(data);
        }}
        components={{
          Toolbar: customToolbar,
        }}
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const _selectedRows = rows.filter((row) => selectedIDs.has(row._id));
          console.log(_selectedRows);
          setSelectedRows(_selectedRows);
        }}
        onCellEditCommit={(e) => {
          props.updateUsers("edit", e.id, e.field, e.value);
        }}
        filterMode="server"
        onFilterModelChange={(e) => props.filterTable(e)}
        rowsPerPageOptions={[100]}
        rowCount={props.usersCount}
        pagination
        paginationMode="server"
        pageSize={props.pageSize}
        onPageChange={(newPage) => props.setPage(newPage)}
        loading={props.loading}
        page={props.page}
        csv={props.csv}
      />
    </div>
  );
}
