import Button from "@material-ui/core/Button";
import axios from "axios";
import React, { useState } from "react";
import { FcCheckmark } from "react-icons/fc";
import LoaderIcon from "react-loader-icon";
import { useLocation } from "react-router";
import { APIFullEmailAddress } from "../../Config";
import logo from "../../images/hrlogo_new.png";
import NotFound from "../404";

const queryString = require("query-string");

export default function Unsubscribe() {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const location = useLocation();
  const email = queryString.parse(location.search).email;
  const hkey = queryString.parse(location.search).hkey;

  const unsubscribe = async () => {
    setLoading(true);
    await axios
      .post(`${APIFullEmailAddress}/unsubscribe?email=${email}&hkey=${hkey}`)
      .then((res) => {
        email === res.data && setSuccess(true);
      })
      .catch((err) => {
        setError(err.response.data);
      });
    setLoading(false);
  };

  return (
    <div className="App">
      {email && hkey ? (
        <>
          <img
            alt="logo"
            src={logo}
            width="220px"
            style={{ position: "absolute", left: "10px", top: "10px" }}
          />
          {loading ? (
            <LoaderIcon
              type="spin"
              style={{
                height: "80px",
                width: "80px",
                margin: "300px auto",
                fill: "white",
              }}
            />
          ) : (
            <div
              className="styledBox yellow-bg"
              style={{
                margin: "auto",
                width: "50%",
                position: "relative",
                top: "20%",
                height: "400px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {error ? (
                <div style={{ margin: "auto" }}>
                  <h2 className="lightText">{error}</h2>
                  <a
                    href="mailto:support@hudsonrock.com"
                    className="dark-bg no-border padding-btn radius-5 darkText"
                  >
                    support@hudsonrock.com
                  </a>
                </div>
              ) : (
                <>
                  <div style={{ margin: "auto" }}>
                    <>
                      {success ? (
                        <FcCheckmark size={35} />
                      ) : (
                        <h4 className="lightText">
                          Hello {email}, <br />
                        </h4>
                      )}

                      {error ? (
                        <p className="error">{error}</p>
                      ) : success ? (
                        <p className="lightText">
                          You have successfully unsubscribed from all lists
                        </p>
                      ) : (
                        <>
                          <p className="lightText">
                            Click here to unsubscribe your email from future
                            alerts
                          </p>
                          <Button
                            type="button"
                            variant="contained"
                            className={"searchBtn desktopSearchBtn"}
                            onClick={unsubscribe}
                          >
                            Unsubscribe
                          </Button>
                        </>
                      )}
                    </>
                  </div>
                </>
              )}
            </div>
          )}
        </>
      ) : (
        <NotFound />
      )}
    </div>
  );
}
