/* eslint-disable */

import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import React, { useEffect, useRef, useState } from "react";
import CountUp from "react-countup";
import { DiAndroid } from "react-icons/di";
import {
  FaCookieBite,
  FaGlobe,
  FaHashtag,
  FaLayerGroup,
  FaMapMarkedAlt,
  FaMapMarkerAlt,
  FaShieldVirus,
  FaSuitcase,
  FaUserTie,
  FaUsers,
  FaViruses,
} from "react-icons/fa";
import {
  MdExpandLess,
  MdExpandMore,
  MdOutlineTimeline,
  MdPassword,
} from "react-icons/md";
import { RiComputerLine, RiFootprintLine } from "react-icons/ri";
import LoaderIcon from "react-loader-icon";
import Select from "react-select";
import CreateApiClient from "../../API";
import companyFilters from "../../company_filters.json";
import "../../css/cyberFont.css";
import AppBarNew from "../AppBar";
import BarChart from "../BarChart";
import ExportToCsv from "../ExportToCsv";
import FloatingDiv from "../partials/FloatingDiv";
import Footer from "../partials/Footer";
import GlobalMap from "../partials/GlobalMap";
import SearchForm from "../partials/SearchForm";
import SimpleModal from "../partials/SimpleModal";
import SpacingGrid from "../partials/SpacingGrid";
import PasswordStrengthBar from "../stats/PasswordStrengthBar";
import Timeline from "../stats/Timeline";
import passwordStrengthByCountry from "../stats/passwordStrengthByCountry.json";
import passwordStrengthByIndustry from "../stats/passwordStrengthByIndustry.json";

const api = CreateApiClient();

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Browse(props) {
  const [avStats, setAvStats] = useState([]);
  const [user, setUser] = useState(props.user);
  const [email, setEmail] = useState(props.email);
  const [domains, setDomains] = useState(props.domains);
  const [role, setRole] = useState(props.role);
  const [token, setToken] = useState(props.token);
  const [loading, setLoading] = useState(false);
  const [stealersCount, setStealersCount] = useState("");
  const [employeesCount, setEmployeesCount] = useState("");
  const [androidsCount, setAndroidsCount] = useState("");
  const [domainsCount, setDomainsCount] = useState("");
  const [countriesCount, setCountriesCount] = useState("");
  const [topApks, setTopApks] = useState([]);
  const [topApplications, setTopApplications] = useState([]);
  const [passwordStats, setPasswordStats] = useState([27.3, 62.09, 2.69, 7.92]);
  const [passwordFilter, setPasswordFilter] = useState("industry");
  const [passwordIndustry, setPasswordIndustry] = useState("");
  const [passwordCountry, setPasswordCountry] = useState("");

  const [domainInput, setDomainInput] = useState("");
  const [domain, setDomain] = useState("");
  const [domainData, setDomainData] = useState([]);
  const [domainLoading, setDomainLoading] = useState(false);

  const [apk, setApk] = useState("");
  const [apkData, setApkData] = useState([]);
  const [apkLoading, setApkLoading] = useState(false);

  const [cookiesDomain, setCookiesDomain] = useState("coinbase.com");
  const [cookiesData, setCookiesData] = useState([]);
  const [cookiesLoading, setCookiesLoading] = useState(false);

  const [keyword, setKeyword] = useState("citrix");
  const [keywordData, setKeywordData] = useState([]);
  const [keywordLoading, setKeywordLoading] = useState(false);

  const [activeUrlList, setActiveUrlList] = useState(null);
  const [keywordPage, setKeywordPage] = useState(1);
  const [keywordPageLoading, setKeywordPageLoading] = useState(false);
  const [keywordDomains, setKeywordDomains] = useState([]);

  const [stealerFamilies, setStealerFamilies] = useState([]);
  const [modalState, setModalState] = useState(null);

  const [mapStats, setMapStats] = useState(null);

  const sectionOneRef = useRef(null);
  const sectionTwoRef = useRef(null);
  const sectionThreeRef = useRef(null);
  const sectionFourRef = useRef(null);
  const sectionFiveRef = useRef(null);

  useEffect(() => {
    if (user) {
      const time = Date.now().toString().substr(0, 10);
      const nowNum = Number(time);
      if (props.expiry < nowNum) {
        props.handleLogOut();
      } else {
        setUser(user);
        setEmail(user.email);
        setDomains(user.Domains);
        setRole(user.Role);
        setToken(user.Token);
        getCounters();
      }
      async function getCounters() {
        setLoading(true);
        let response;
        response = await api.getFilters("counts");

        response && setLoading(false);
        setStealersCount(response.stealers);
        setEmployeesCount(response.employees);
        setAndroidsCount(response.androids);
        setDomainsCount(response.domains);
        setCountriesCount(response.countries);
      }
    }
  }, []);

  useEffect(() => {
    setPasswordIndustry("");
    setPasswordCountry("");
    setPasswordStats([27.3, 62.09, 2.69, 7.92]);
  }, [passwordFilter]);

  const topSessionCookies = [
    "google.com",
    "youtube.com",
    "microsoft.com",
    "live.com",
    "msn.com",
    "adobe.com",
    "aliexpress.com",
    "yandex.ru",
    "paypal.com",
    "twitter.com",
  ];

  const counters = [
    {
      name: (
        <h2>
          Compromised <br /> Machines
        </h2>
      ),
      text: "Timeline",
      icon: <RiComputerLine size={30} className="counterIcon" />,
      count: Number(stealersCount),
      onNav: true,
      id: "section-one",
      customIcon: <MdOutlineTimeline size={30} className="counterIcon" />,
    },
    {
      name: (
        <h2>
          Compromised <br /> Employees
        </h2>
      ),
      text: "Compromised Employees",
      icon: <FaUserTie size={30} className="counterIcon" />,
      count: Number(employeesCount),
    },
    {
      name: (
        <h2>
          Compromised <br /> Domains
        </h2>
      ),
      text: "Domain Search",
      icon: <FaGlobe size={30} className="counterIcon" />,
      count: domainsCount,
      onNav: true,
      id: "section-two",
    },
    {
      name: (
        <h2>
          Compromised <br /> Androids
        </h2>
      ),
      text: "APK Search",
      icon: <DiAndroid size={30} className="counterIcon" />,
      count: Number(androidsCount),
      onNav: true,
      id: "section-three",
    },
    {
      name: (
        <h2>
          Different <br /> Countries
        </h2>
      ),
      text: "Infections Map",
      icon: <FaMapMarkerAlt size={30} className="counterIcon" />,
      count: Number(countriesCount),
      onNav: true,
      customIcon: <FaMapMarkedAlt size={30} className="counterIcon" />,
      id: "section-four",
    },
    {
      name: (
        <h2>
          Compromised <br /> Applications
        </h2>
      ),
      text: "Compromised Applications",
      icon: <FaHashtag size={30} className="counterIcon" />,
      count: 20,
      suffix: "M+",
    },
    {
      name: (
        <h2>
          Compromised <br /> Passwords
        </h2>
      ),
      text: "Digital Footprint",
      icon: <MdPassword size={30} className="counterIcon" />,
      count: 500,
      suffix: "M+",
      onNav: true,
      id: "section-five",
      customIcon: <RiFootprintLine size={30} className="counterIcon" />,
    },
    {
      name: (
        <h2>
          Compromised <br /> Cookies
        </h2>
      ),
      text: "Compromised Cookies",
      icon: <FaCookieBite size={30} className="counterIcon" />,
      count: 25,
      suffix: "B+",
    },
  ];

  useEffect(() => {
    getMapStats();
    getStealerFamilies();
    getAvStats();
    getTopApks();
    getTopApplications();
    countCookies();
    countKeyword();
  }, []);

  const getMapStats = async () => {
    let url = `counts/global-map`;

    const response = await api.getData(url, {});
    setMapStats(response);
  };

  const getStealerFamilies = async () => {
    let url = `counts/stealer-families`;

    const response = await api.getData(url, {});
    setStealerFamilies(response);
  };

  const changePasswordFilter = (e) => {
    setPasswordFilter(e.value);
  };

  const changePasswordIndustry = (e) => {
    setPasswordIndustry(e);
    const found = passwordStrengthByIndustry.find(
      (s) => s.industry === e.value
    );
    if (found) {
      const stats = [
        found.too_weak.perc,
        found.weak.perc,
        found.medium.perc,
        found.strong.perc,
      ];
      setPasswordStats(stats);
    } else {
      setPasswordStats([0, 0, 0, 0]);
    }
  };

  const changePasswordCountry = (e) => {
    setPasswordCountry(e);
    const found = passwordStrengthByCountry.find((s) => s.country === e.value);
    if (found) {
      const stats = [
        found.too_weak ? found.too_weak.perc : 0,
        found.weak ? found.weak.perc : 0,
        found.medium ? found.medium.perc : 0,
        found.strong ? found.strong.perc : 0,
      ];
      setPasswordStats(stats);
    } else {
      setPasswordStats([0, 0, 0, 0]);
    }
  };

  const getAvStats = async () => {
    setLoading(true);
    let url = `stats/anti-viruses/overall`;

    let config = {
      headers: {
        "auth-token": props.user.Token
          ? props.user.Token
          : JSON.parse(props.user).Token,
      },
    };

    const response = await api.fetchData(url, {}, config);
    setAvStats(response);
    setLoading(false);
  };

  const getTopApks = async () => {
    setLoading(true);
    let url = `counts/top-apks`;

    const response = await api.getData(url, {});
    setTopApks(response);
    setLoading(false);
  };

  const getTopApplications = async () => {
    let url = `stats/applications/top-sensitive`;

    const response = await api.getData(url, {});
    setTopApplications(response.data);
  };

  const handleDomainChange = (e) => {
    e.preventDefault();
    setDomainInput(e.target.value);
  };

  const submitDomain = async (e, _domain) => {
    e && e.preventDefault();

    _domain ? setDomain(_domain) : setDomain(domainInput);
    if (props.state === "main") {
      window.location.href = `search-by-domain/${_domain ? _domain : domain}`;
    } else {
      setDomainData([]);
      setDomainLoading(true);
      let url = `stats/website-results`;

      let config = {
        headers: {
          "auth-token": props.user.Token
            ? props.user.Token
            : JSON.parse(props.user).Token,
        },
      };

      const response = await api.fetchApiData(
        url,
        {
          domain: _domain ? _domain : domainInput,
          source_id: "b5yao6c1QKqu3NtEwWcJYntMktlekodK",
        },
        config
      );
      if (response && response.totalStealers) {
        setDomainData(response);
      } else {
        setDomainData("Something went wrong");
      }
      setDomainLoading(false);
    }
  };

  const handleApkChange = (e) => {
    e.preventDefault();
    setApk(e.target.value);
  };

  const submitApk = async (e, _apk) => {
    e && e.preventDefault();
    if (props.state === "main") {
      window.location.href = `search-by-domain/${_apk ? _apk : apk}`;
    } else {
      setApkData([]);
      setApkLoading(true);
      let url = `counts/domain?apkSearch=true`;

      let config = {
        headers: {
          "auth-token": props.user.Token
            ? props.user.Token
            : JSON.parse(props.user).Token,
        },
      };

      const response = await api.fetchData(
        url,
        { domain: _apk ? _apk : apk },
        config
      );
      if (response && response.stealers) {
        setApkData(response);
      } else {
        setApkData(
          "No result found, please try finding the app on https://play.google.com/store/games?hl=en&gl=US"
        );
      }
      setApkLoading(false);
    }
  };

  const handleCookiesDomainChange = (e) => {
    e.preventDefault();
    setCookiesDomain(e.target.value);
  };

  const countCookies = async (e) => {
    let config = {
      headers: {
        "auth-token": props.user.Token
          ? props.user.Token
          : JSON.parse(props.user).Token,
      },
    };
    e && e.preventDefault();
    setCookiesLoading(true);
    let url = `counts/cookies?domain=${cookiesDomain}`;

    const response = await api.fetchData(url, {}, config);
    setCookiesData(response);
    setCookiesLoading(false);
  };

  const handleKeywordChange = (e) => {
    e.preventDefault();
    setKeyword(e.target.value);
  };

  const countKeyword = async (e, _keyword, count, occ, reset) => {
    e && e.preventDefault();
    _keyword && setKeyword(_keyword);
    let url = `stats/website-results/search-by-keyword?system=${
      _keyword ? _keyword : keyword
    }&noalert=true&page=${reset ? 1 : keywordPage}&count=${count}&occ=${occ}`;
    if (keywordPage > 1 && !reset) {
      setKeywordPageLoading(true);
    } else {
      setKeywordLoading(true);
    }
    const response = await api.fetchApiData(
      url,
      {},
      { headers: { "auth-token": props.token } }
    );

    setKeywordData(response);
    if (keywordPage > 1 && !reset) {
      setKeywordDomains([...keywordDomains, ...response.data]);
      setKeywordPageLoading(false);
    } else {
      setKeywordDomains(response.data);
      setKeywordLoading(false);
    }
  };

  useEffect(() => {
    if (keywordPage > 1) {
      countKeyword(
        null,
        null,
        keywordData.total_domains,
        keywordData.total_occurrences
      );
    }
  }, [keywordPage]);

  const openModal = (e, item) => {
    let name = item.text.split(" ")[0].toLowerCase();
    const found = stealerFamilies.find((s) =>
      s.name.toLowerCase().includes(name)
    );
    if (found) {
      setModalState(found);
    } else {
      setModalState(null);
    }
  };

  const handleClose = () => {
    setModalState(null);
  };

  const stealerFamiliesHtml = modalState && (
    <div>
      <div
        onClick={handleClose}
        className="cursor"
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          backgroundColor: "black",
          color: "white",
          borderRadius: "50px",
          width: "30px",
          height: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        X
      </div>
      <h2>{modalState.name}</h2>
      <div
        style={{
          display: "grid",
        }}
      >
        <span>Created by: {modalState.owner}</span>
        <span>Date Created: {modalState.created_date.split("T")[0]}</span>
        <span>Last Edited: {modalState.modified_date.split("T")[0]}</span>
        <span>{modalState.description}</span>
      </div>
      <br />
      <h3>Techniques ({modalState.techniques.length})</h3>
      <hr />
      <div>
        {modalState.techniques.map((item, index) => (
          <div
            style={{
              backgroundColor: "#fdcd4e",
              margin: "10px auto",
              padding: "10px",
              borderRadius: "10px",
              width: "90%",
            }}
            key={index}
          >
            <h3>{item.technique.name}</h3>
            <div style={{ lineHeight: "0.5" }}>
              <p className="lightText">
                ID: {item.technique.technique_attack_id}
              </p>
              <p className="lightText">
                Tactics:{" "}
                {item.technique.tactic.map((_item) => _item.name).join(",")}
              </p>
            </div>
            {!item.description.includes("(") ? (
              item.description.split(",").map((_item, idx) => (
                <a
                  key={idx}
                  href={_item}
                  target="_blank"
                  rel="noreferrer"
                  style={{ margin: "10px 0px" }}
                >
                  {_item}
                </a>
              ))
            ) : (
              <a
                href={item.description.split("(")[1].split(")")[0]}
                target="_blank"
                rel="noreferrer"
                style={{ margin: "10px 0px" }}
              >
                {item.description.split("(")[1].split(")")[0]}
              </a>
            )}
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div>
      <FloatingDiv
        data={counters}
        sectionFiveRef={sectionFiveRef}
        sectionFourRef={sectionFourRef}
        sectionThreeRef={sectionThreeRef}
        sectionTwoRef={sectionTwoRef}
        sectionOneRef={sectionOneRef}
      />
      <AppBarNew
        className="mobileHeader"
        user={props.user}
        role={props.role}
        token={props.token}
        email={props.email}
        domains={props.domains}
        handleLogOut={props.handleHomeLogOut}
      />
      {modalState && (
        <SimpleModal
          open={modalState !== null}
          html={stealerFamiliesHtml}
          handleClose={handleClose}
        />
      )}
      <div id="generalCounters" style={{ textAlign: "center" }}>
        <>
          <div
            className="counters"
            style={{
              backgroundColor: "#fecd4e",
              color: "black",
              borderRadius: "20px",
              justifyContent: "center",
              width: "70%",
              padding: "0.8rem 0rem 0rem",
            }}
          >
            {counters.map((counter, index) => (
              <div
                key={index}
                className="counter newCounter"
                style={{ width: "12%" }}
              >
                {counter.icon}
                <CountUp
                  separator=","
                  start={0}
                  end={counter.count}
                  duration={2.75}
                  suffix={counter.suffix}
                />
                {counter.name}
              </div>
            ))}
          </div>
        </>
      </div>
      <div ref={sectionOneRef} id="section-one"></div>
      <div
        style={{
          minHeight: "100vh",
          textAlign: "center",
          color: "white",
          width: "70%",
          margin: "0rem auto 6rem",
        }}
      >
        <Timeline
          onClick={(e, item) => {
            submitDomain(e, item);
          }}
          user={props.user}
        />
        <div id="section-two" ref={sectionTwoRef}></div>
        <br />
        <br />
        <div
          style={{
            width: "100%",
            margin: "auto",
            backgroundColor: "#FECD4E",
            borderRadius: "20px",
            padding: "2rem",
          }}
        >
          <FaGlobe
            size={35}
            style={{
              fill: "#222831",
            }}
          />
          <h3 style={{ color: "#222831" }}>Search Any Domain</h3>
          <SearchForm
            onChange={handleDomainChange}
            onSubmit={submitDomain}
            value={domainInput}
            placeholder="Enter domain"
            formStyle={{
              backgroundColor: "#E7F0FE",
              color: "#222831",
              borderColor: "#222831",
              margin: "0px 2px",
              borderRadius: "50px 0px 0px 50px",
              width: "25%",
            }}
            btnStyle={{
              backgroundColor: "#222831",
              color: "#FECD4E",
              borderRadius: "0px 50px 50px 0px",
            }}
          />
          <div>
            {domainLoading ? (
              <>
                <br />
                <br />
                <LoaderIcon type="spin" />
              </>
            ) : domainData &&
              domainData.totalStealers &&
              domainData.totalStealers > 0 ? (
              <>
                <img
                  alt="logo"
                  src={`https://logo.clearbit.com/${domain}`}
                  style={{
                    width: "60px",
                    borderRadius: "8px",
                    margin: "10px auto",
                  }}
                />
                <br />
                <div
                  style={{
                    display: "inline-flex",
                    width: "35%",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#222831",
                      color: "#FECD4E",
                      padding: "10px",
                      borderRadius: "20px",
                      width: "100%",
                      margin: "2px",
                    }}
                  >
                    <FaUserTie
                      size={40}
                      className="counterIcon"
                      style={{ marginBottom: "4px" }}
                    />
                    <CountUp
                      separator=","
                      start={0}
                      end={domainData.employees}
                      duration={2.75}
                    />
                    <br /> Compromised <br /> Employees
                  </div>
                  <div
                    style={{
                      backgroundColor: "#222831",
                      color: "#FECD4E",
                      padding: "10px",
                      borderRadius: "20px",
                      width: "100%",
                      margin: "2px",
                    }}
                  >
                    <FaUsers
                      size={40}
                      className="counterIcon"
                      style={{ marginBottom: "4px" }}
                    />
                    <CountUp
                      separator=","
                      start={0}
                      end={domainData.users}
                      duration={2.75}
                    />
                    <br /> Compromised <br /> Users
                  </div>
                </div>
              </>
            ) : (
              <p className="error">{domainData}</p>
            )}
            {domainData && domainData.data && (
              <div style={{ color: "#222831" }}>
                <br />
                <h3>Top Compromised URLs ({domain}):</h3>

                <div
                  style={{
                    color: "#222831",
                    maxHeight: "200px",
                    overflowY: "scroll",
                    backgroundColor: "#E7F0FE",
                    borderRadius: "20px",
                    border: "1px solid #222831",
                    padding: "1rem",
                    width: "75%",
                    margin: "auto",
                  }}
                >
                  {domainData.data.all_urls.length > 0 &&
                    domainData.data.all_urls.slice(0, 10).map((item, index) => (
                      <div
                        style={{
                          display: "inline-flex",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "#222831",
                          borderRadius: "20px",
                          color: "#FECD4E",
                          padding: "6px 12px",
                          margin: "2px",
                        }}
                        key={index}
                      >
                        <div
                          style={{
                            width: "40%",
                            textAlign: "left",
                            wordBreak: "break-all",
                          }}
                        >
                          {item.url}
                        </div>
                        <div>{item.type} URL</div>
                        <div>
                          Occurrence: {numberWithCommas(item.occurrence)}
                        </div>
                      </div>
                    ))}
                </div>
                <br />
                <a
                  href={`/search-by-domain/${domain}`}
                  style={{
                    borderRadius: "20px",
                    padding: "5px 15px",
                    backgroundColor: "#222831",
                    color: "#FECD4E",
                    border: "0px",
                    display: "block",
                    width: "20%",
                    margin: "auto",
                  }}
                >
                  View Full Results →
                </a>
              </div>
            )}
          </div>
        </div>
        <br />
        <div
          style={{
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginBottom: "2rem",
          }}
        >
          <div
            style={{
              width: "50%",
              margin: "0px 4px",
              backgroundColor: "#222831",
              padding: "30px",
              borderRadius: "50px",
            }}
          >
            <FaShieldVirus
              size={40}
              style={{ fill: "#FECD4E", marginBottom: "10px" }}
            />
            <BarChart
              labelColor="#fff"
              screen="avs"
              data={avStats.labels}
              dataCount={avStats.counts}
              loading={props.loading}
              type=""
              height={20}
              width={100}
              customTitle={"Most Common Anti-Viruses"}
            />
          </div>
          <div
            style={{
              width: "50%",
              margin: "0px 4px",
              backgroundColor: "#222831",
              padding: "30px",
              borderRadius: "50px",
            }}
          >
            <FaViruses
              size={40}
              style={{ fill: "#FECD4E", marginBottom: "10px" }}
            />

            <BarChart
              openModal={(e, item) => openModal(e, item)}
              labelColor="#fff"
              screen="sf"
              data={[
                "Redline ℹ",
                "Raccoon ℹ",
                "Vidar ℹ",
                "Azorult",
                "Lumma ℹ",
                "CRYPTBOT",
                "Predator",
                "Ficker",
                "Taurus",
                "Mystic",
                "StealC",
                "KPOT",
              ]}
              dataCount={[
                "67.28",
                "13.11",
                "6.78",
                "5.44",
                "0.82",
                "0.52",
                "0.35",
                "0.34",
                "0.16",
                "0.13",
                "0.1",
                "0.04",
              ]}
              loading={props.loading}
              type=""
              height={20}
              width={100}
              customTitle={"Stealer Family Statistics"}
            />
          </div>
        </div>
        <div ref={sectionThreeRef} id="section-three"></div>
        <br />
        <div
          style={{
            backgroundColor: "#FECD4E",
            borderRadius: "20px",
            padding: "2rem",
            margin: "auto",
          }}
        >
          <div
            style={{
              textAlign: "center",
              backgroundColor: "#FECD4E",
              color: "#222831",
              margin: "1rem",
            }}
          >
            <DiAndroid size={50} className="counterIcon" />
            <div style={{ fontSize: "42px", marginTop: "10px" }}>
              <span
                style={{
                  color: "#FECD4E",
                  backgroundColor: "#222831",
                  padding: "0.5rem 2rem",
                  borderRadius: "50px",
                }}
              >
                <CountUp
                  separator=","
                  start={0}
                  end={androidsCount}
                  duration={2.75}
                />
              </span>
              <div
                style={{
                  display: "grid",
                  lineHeight: "initial",
                  marginTop: "8px",
                }}
              >
                <span>Compromised</span>
                <span>App Users</span>
              </div>
            </div>
            <hr />
          </div>
          <SpacingGrid
            onClick={(e, item) => {
              setApk(item);
              submitApk(e, item);
            }}
            options={topApks.data}
            topApks={topApks.topApks}
          />
          <br />
          <h2 style={{ color: "#222831" }}>Search Any APK</h2>
          <div style={{ width: "40%", margin: "auto" }}>
            <SearchForm
              onChange={handleApkChange}
              onSubmit={(e) => submitApk(e)}
              value={apk}
              placeholder="Enter APK domain"
              formStyle={{
                backgroundColor: "#E7F0FE",
                color: "#222831",
                borderColor: "#222831",
                margin: "0px 2px",
                borderRadius: "50px 0px 0px 50px",
              }}
              btnStyle={{
                backgroundColor: "#222831",
                color: "#FECD4E",
                borderRadius: "0px 50px 50px 0px",
              }}
            />
            <br />
            <br />
            {apkLoading ? (
              <LoaderIcon type="spin" />
            ) : apkData && apkData.stealers ? (
              <>
                <div
                  style={{
                    backgroundColor: "#222831",
                    color: "#FECD4E",
                    padding: "10px",
                    borderRadius: "20px",
                    width: "50%",
                    margin: "auto",
                  }}
                >
                  <DiAndroid
                    size={40}
                    className="counterIcon"
                    style={{ marginBottom: "4px" }}
                  />
                  <CountUp
                    separator=","
                    start={0}
                    end={apkData.stealers}
                    duration={2.75}
                  />
                  <br /> Compromised <br /> App Users
                </div>
                <br />
                <a
                  href={`/search-by-domain/${apk}`}
                  style={{
                    borderRadius: "20px",
                    padding: "5px 15px",
                    backgroundColor: "#222831",
                    color: "#FECD4E",
                    border: "0px",
                  }}
                >
                  View Full Results →
                </a>
              </>
            ) : (
              <p className="error">{apkData}</p>
            )}
          </div>
        </div>
        <div id="section-four" ref={sectionFourRef}></div>
        <br />
        <br />
        {mapStats && <GlobalMap data={mapStats} />}
        <br />
        <div
          style={{
            display: "inline-flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "100%",
            backgroundColor: "#222831",
            borderRadius: "20px",
            padding: "2rem",
          }}
        >
          <div
            style={{
              color: "black",
              backgroundColor: "#FECD4E",
              padding: "2rem",
              borderRadius: "20px",
            }}
          >
            <MdPassword size={35} />
            <h3>Password Strength Statistics</h3>
            <h5>Filter By Industry Or Location</h5>
            <div
              style={{
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Select
                placeholder="Filter By"
                className="search-by-select selectIndustry desktop"
                options={[
                  {
                    value: "industry",
                    label: "Industry",
                  },
                  {
                    value: "country",
                    label: "Country",
                  },
                ]}
                onChange={changePasswordFilter}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "#FBCD4E",
                    primary: "black",
                  },
                })}
              />
              {passwordFilter === "industry" ? (
                <Select
                  placeholder="Select Industry"
                  className="search-by-select selectIndustry desktop"
                  options={companyFilters.industries}
                  onChange={changePasswordIndustry}
                  value={passwordIndustry}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#FBCD4E",
                      primary: "black",
                    },
                  })}
                />
              ) : (
                <Select
                  placeholder="Select Country"
                  className="search-by-select selectIndustry desktop"
                  options={companyFilters.countries}
                  onChange={changePasswordCountry}
                  value={passwordCountry}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#FBCD4E",
                      primary: "black",
                    },
                  })}
                />
              )}
            </div>
          </div>
          <div
            style={{
              width: "50%",
              margin: "0px 4px",
            }}
          >
            <ul className="legend">
              <Tooltip title="Password with a length of less than 6 characters and only 1 type of character (lowercase, uppercase, numbers and symbols).">
                <li>Too Weak</li>
              </Tooltip>
              <Tooltip title="Password with a length of 6-8 characters and a diversity of 2-3 types of characters (lowercase, uppercase, numbers and symbols).">
                <li>Weak</li>
              </Tooltip>
              <Tooltip title="Password with a length of 8-10 characters and a diversity of 4 types of characters (lowercase, uppercase, numbers and symbols).">
                <li>Medium</li>
              </Tooltip>
              <Tooltip title="Password that is at least 10 characters long and has a diversity of 4 types of characters (lowercase, uppercase, numbers and symbols).">
                <li>Strong</li>
              </Tooltip>
            </ul>
            <PasswordStrengthBar
              loading={props.loading}
              passwordStats={passwordStats}
            />
          </div>
        </div>
        <div id="section-five" ref={sectionFiveRef}></div>
        <br />
        <br />
        <div
          style={{
            backgroundColor: "#222831",
            padding: "2rem",
            borderRadius: "20px",
            display: "inline-flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "50%", height: "60rem", margin: "0px 4px" }}>
            <h2 style={{ color: "#FECD4E" }}>Top Session Cookies</h2>
            <hr />
            <div style={{ height: "12rem" }}>
              <div
                style={{
                  display: "inline-flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {topSessionCookies.slice(0, 5).map((item, index) => (
                  <div
                    className="cursor"
                    key={index}
                    onClick={() => {
                      setCookiesDomain(item);
                      countCookies();
                    }}
                  >
                    <div
                      style={{
                        background: `url(https://logo.clearbit.com/${item})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        borderRadius: "10px",
                        textAlign: "center",
                        margin: "0px 20px",
                        width: "50px",
                        height: "50px",
                      }}
                    ></div>
                    <span style={{ fontSize: "12px" }}>{item}</span>
                  </div>
                ))}
              </div>
              <br />
              <br />
              <div
                style={{
                  display: "inline-flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {topSessionCookies.slice(5).map((item, index) => (
                  <div
                    className="cursor"
                    key={index}
                    onClick={() => {
                      setCookiesDomain(item);
                      countCookies();
                    }}
                  >
                    <div
                      style={{
                        background: `url(https://logo.clearbit.com/${item})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        borderRadius: "10px",
                        textAlign: "center",
                        margin: "0px 20px",
                        width: "50px",
                        height: "50px",
                      }}
                    ></div>
                    <span style={{ fontSize: "12px" }}>{item}</span>
                  </div>
                ))}
              </div>
            </div>
            <br />
            <h6 style={{ color: "#FECD4E", fontWeight: "bold" }}>
              Enter a domain to see how many cookies it has in our database
            </h6>
            <div style={{ width: "70%", margin: "auto", marginBottom: "5px" }}>
              <SearchForm
                onChange={handleCookiesDomainChange}
                onSubmit={countCookies}
                value={cookiesDomain}
                placeholder="Enter Domain"
                formStyle={{
                  backgroundColor: "#E7F0FE",
                  color: "#222831",
                  borderColor: "#222831",
                  margin: "0px 2px",
                  borderRadius: "50px 0px 0px 50px",
                }}
                btnStyle={{
                  backgroundColor: "#FECD4E",
                  color: "#222831",
                  borderRadius: "0px 50px 50px 0px",
                }}
              />
            </div>
            {cookiesLoading ? (
              <LoaderIcon type="spin" style={{ fill: "white" }} />
            ) : (
              cookiesData &&
              cookiesData.data && (
                <div style={{ display: "block" }}>
                  <div
                    style={{
                      display: "inline-flex",
                      width: "65%",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#FECD4E",
                        color: "#222831",
                        padding: "10px",
                        borderRadius: "20px",
                        width: "100%",
                        margin: "2px",
                      }}
                    >
                      <FaCookieBite
                        size={40}
                        className="counterIcon"
                        style={{ marginBottom: "4px" }}
                      />
                      <CountUp
                        separator=","
                        start={0}
                        end={cookiesData.cookies}
                        duration={2.75}
                      />
                      <br /> Session <br /> Cookies
                    </div>
                    <div
                      style={{
                        backgroundColor: "#FECD4E",
                        color: "#222831",
                        padding: "10px",
                        borderRadius: "20px",
                        width: "100%",
                        margin: "2px",
                      }}
                    >
                      <FaLayerGroup
                        size={40}
                        className="counterIcon"
                        style={{ marginBottom: "4px" }}
                      />
                      <CountUp
                        separator=","
                        start={0}
                        end={cookiesData.amout}
                        duration={2.75}
                      />
                      <br /> Cookie <br /> Types
                    </div>
                  </div>
                  <br />
                  <div style={{ width: "60%", margin: "10px auto 0px" }}>
                    <ExportToCsv
                      color="white"
                      type="cookies"
                      data={cookiesData.data}
                      user={props.user}
                      selectedDomain={cookiesDomain}
                    />
                  </div>
                  <br />

                  <div
                    style={{
                      display: "block",
                      overflowY: "scroll",
                      maxHeight: "26rem",
                      backgroundColor: "#1A1C20",
                      borderRadius: "10px",
                      padding: "1rem 0rem",
                    }}
                  >
                    {cookiesData.data.length > 0 &&
                      cookiesData.data.map((item, index) => (
                        <>
                          <div
                            key={index}
                            style={{
                              backgroundColor: "#FECD4E",
                              color: "#222831",
                              borderRadius: "10px",
                              padding: "0.4rem 1rem",
                              width: "80%",
                              margin: "5px auto",
                              textAlign: "center",
                              fontSize: "18px",
                              minHeight: "4.1rem",
                              display: "inline-flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div
                              className={item.class}
                              style={{
                                display: "inline-flex",
                                justifyContent: "start",
                                alignItems: "center",
                                width: "70%",
                                overflowWrap: "anywhere",
                                fontSize: "14px",
                                textAlign: "left",
                              }}
                            >
                              <FaCookieBite
                                size={20}
                                className="counterIcon"
                                style={{
                                  margin: "0px 5px 1px 0px",
                                }}
                              />{" "}
                              {item.name}
                            </div>
                            <div
                              style={{
                                backgroundColor: "#222831",
                                color: "#FECD4E",
                                padding: "2px 5px",
                                borderRadius: "10px",
                                fontSize: "16px",
                              }}
                            >
                              {numberWithCommas(item.count)}+ Occurrences
                            </div>
                          </div>
                        </>
                      ))}
                  </div>
                </div>
              )
            )}
          </div>
          <div style={{ width: "50%", height: "60rem", margin: "0px 4px" }}>
            <h2 style={{ color: "#FECD4E" }}>Top Sensitive Applications</h2>
            <hr />
            <Stack
              direction="row"
              sx={{
                display: "block",
                overflowY: "scroll",
                height: "12rem",
              }}
            >
              {topApplications &&
                topApplications.length > 0 &&
                topApplications.map((app, index) => (
                  <Tooltip
                    title={app.description && app.description[0]}
                    className={
                      app.app === keyword
                        ? "cursor chip activeChip"
                        : "cursor chip"
                    }
                    onClick={(e) => {
                      setKeywordData([]);
                      setKeywordPage(1);
                      countKeyword(e, app.app.toLowerCase(), null, null, true);
                    }}
                  >
                    <Chip
                      sx={{
                        backgroundColor:
                          app.app === keyword ? "#222831" : "#FECD4E",
                        color: app.app === keyword ? "#FECD4E" : "#222831",
                        margin: "2px",
                        padding: "2px",
                        fontSize: "12px",
                        border: app.app === keyword && "1px solid #FECD4E",
                      }}
                      key={index}
                      label={app.app.toUpperCase()}
                      variant="filled"
                      icon={
                        <span
                          className="chip"
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {numberWithCommas(app.count)}
                        </span>
                      }
                    />
                  </Tooltip>
                ))}
            </Stack>
            <br />
            <h6 style={{ color: "#FECD4E", fontWeight: "bold" }}>
              Enter any keyword to see its distribution in our database
            </h6>
            <div style={{ width: "70%", margin: "auto", marginBottom: "5px" }}>
              <SearchForm
                onChange={handleKeywordChange}
                onSubmit={(e) => {
                  setKeywordData([]);
                  setKeywordPage(1);
                  countKeyword(e, null, null, null, true);
                }}
                value={keyword}
                placeholder="Enter Keyword"
                formStyle={{
                  backgroundColor: "#E7F0FE",
                  color: "#222831",
                  borderColor: "#222831",
                  margin: "0px 2px",
                  borderRadius: "50px 0px 0px 50px",
                }}
                btnStyle={{
                  backgroundColor: "#FECD4E",
                  color: "#222831",
                  borderRadius: "0px 50px 50px 0px",
                }}
              />
            </div>
            {keywordLoading ? (
              <LoaderIcon type="spin" style={{ fill: "white" }} />
            ) : (
              keywordData &&
              keywordData.data && (
                <div style={{ display: "block" }}>
                  <div
                    style={{
                      display: "inline-flex",
                      width: "65%",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#FECD4E",
                        color: "#222831",
                        padding: "10px",
                        borderRadius: "20px",
                        width: "100%",
                        margin: "2px",
                      }}
                    >
                      <FaSuitcase
                        size={40}
                        className="counterIcon"
                        style={{ marginBottom: "4px" }}
                      />
                      <CountUp
                        separator=","
                        start={0}
                        end={keywordData.total_domains}
                        duration={2.75}
                      />
                      <br /> Matched <br /> Companies
                    </div>
                    <div
                      style={{
                        backgroundColor: "#FECD4E",
                        color: "#222831",
                        padding: "10px",
                        borderRadius: "20px",
                        width: "100%",
                        margin: "2px",
                      }}
                    >
                      <FaHashtag
                        size={40}
                        className="counterIcon"
                        style={{ marginBottom: "4px" }}
                      />
                      <CountUp
                        separator=","
                        start={0}
                        end={keywordData.total_occurrences}
                        duration={2.75}
                      />
                      <br /> Keyword <br /> Occurrence
                    </div>
                  </div>
                  <br />
                  <div style={{ width: "60%", margin: "10px auto 0px" }}>
                    <ExportToCsv
                      color="white"
                      type="keyword"
                      data={keywordDomains}
                      user={props.user}
                      selectedDomain={keyword}
                    />
                  </div>
                  <br />
                  <div
                    style={{
                      display: "block",
                      overflowY: "scroll",
                      maxHeight: "26rem",
                      backgroundColor: "#1A1C20",
                      borderRadius: "10px",
                      padding: "1rem 0rem",
                    }}
                  >
                    {keywordDomains &&
                      keywordDomains.length > 0 &&
                      keywordDomains.map((item, index) => (
                        <>
                          <div
                            className="cursor"
                            key={index}
                            style={{
                              backgroundColor: "#FECD4E",
                              color: "#222831",
                              borderRadius: "10px",
                              padding: "0.4rem 1rem",
                              display: "inline-flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "80%",
                              margin: "5px auto",
                              height: "4.1rem",
                            }}
                            onClick={() => {
                              activeUrlList === item.domain
                                ? setActiveUrlList(null)
                                : setActiveUrlList(item.domain);
                            }}
                          >
                            <div>
                              <img
                                alt="logo"
                                src={item.logo}
                                style={{ borderRadius: "10px", width: "50px" }}
                              />
                            </div>
                            <div
                              className={item.class}
                              style={{
                                width: "50%",
                                "overflow-wrap": "anywhere",
                              }}
                            >
                              {item.domain}
                            </div>
                            <div
                              style={{
                                backgroundColor: "#222831",
                                color: "#FECD4E",
                                padding: "2px 5px",
                                borderRadius: "10px",
                                fontSize: "16px",
                              }}
                            >
                              {numberWithCommas(item.count)}+ URLs
                            </div>
                            <div
                              onClick={() => {
                                activeUrlList === item.domain
                                  ? setActiveUrlList(null)
                                  : setActiveUrlList(item.domain);
                              }}
                            >
                              {activeUrlList === item.domain ? (
                                <MdExpandLess size={25} />
                              ) : (
                                <MdExpandMore size={25} />
                              )}
                            </div>
                          </div>
                          {activeUrlList === item.domain && (
                            <div
                              style={{
                                backgroundColor: "#FECD4E",
                                color: "#222831",
                                borderRadius: "20px",
                                padding: "0.2rem",
                                width: "80%",
                                margin: "auto",
                              }}
                            >
                              {item.class !== "Blur" && (
                                <a
                                  href={`/search-by-domain/${item.domain}/#assets`}
                                  style={{
                                    borderRadius: "20px",
                                    padding: "5px 15px",
                                    backgroundColor: "#222831",
                                    color: "#FECD4E",
                                    border: "0px",
                                    width: "100%",
                                    display: "block",
                                  }}
                                >
                                  View Full Results →
                                </a>
                              )}
                              {item.urls_list &&
                                item.urls_list.length > 0 &&
                                item.urls_list.map((_item, _index) => (
                                  <div
                                    key={index}
                                    style={{
                                      backgroundColor: "#1A1C20",
                                      color: "#FECD4E",
                                      margin: "4px",
                                      borderRadius: "20px",
                                      padding: "0.2rem",
                                      fontSize: "12px",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    <span className={item.class}>{_item}</span>
                                  </div>
                                ))}
                            </div>
                          )}
                        </>
                      ))}
                    <br />
                    {keywordData.loadMore && (
                      <button
                        style={{
                          borderRadius: "8px",
                          padding: "5px 15px",
                          backgroundColor: "#FECD4E",
                          color: "#222831",
                          border: "0px",
                          width: "80%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "auto",
                        }}
                        onClick={() => {
                          setKeywordPage(keywordPage + 1);
                        }}
                      >
                        Load More{" "}
                        {keywordPageLoading && (
                          <LoaderIcon
                            type="spin"
                            style={{
                              width: "16px",
                              height: "16px",
                              margin: "-10px 0px 0px 10px",
                            }}
                          />
                        )}
                      </button>
                    )}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
