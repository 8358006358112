import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import React from "react";
import CountUp from "react-countup";
import {
  FaDesktop,
  FaExternalLinkAlt,
  FaHashtag,
  FaSuitcase,
} from "react-icons/fa";

import { BsFillPlusCircleFill } from "react-icons/bs";
import { DiAndroid } from "react-icons/di";
import { ImCross } from "react-icons/im";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import LoaderIcon from "react-loader-icon";
import whoisLogo from "../../images/whoisxml-api-logo.png";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const icons = [
  {
    name: "apk",
    icon: <DiAndroid size={25} />,
  },
  {
    name: "primary",
    icon: <FaDesktop size={25} />,
  },
  {
    name: "external",
    icon: <FaExternalLinkAlt size={25} />,
  },
];

export default function DomainsByKeyword(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();

  const handleClick = (event, newPlacement, name) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen(name);
    setPlacement(newPlacement);
  };

  const setIcon = (item) => {
    if (item.whois) {
      return <img src={whoisLogo} width="30" alt="logo" />;
    } else {
      let found = icons.find((i) => i.name === item.type);
      return found ? found.icon : icons[1].icon;
    }
  };

  return props.keywordDomains && props.keywordDomains.length > 0 ? (
    <div
      className="domainsByKeyword"
      style={{
        display: "block",
        overflowY: "scroll",
        minHeight: "12rem",
        maxHeight: "26rem",
        backgroundColor: "#222831",
        borderRadius: "10px",
        padding: "1rem 0rem",
        marginBottom: "10px",
        textAlign: "center",
      }}
    >
      {props.keywordData && props.type !== "gov" && (
        <div style={{ display: "block" }}>
          <div
            style={{
              display: "inline-flex",
              width: "65%",
            }}
          >
            <div
              style={{
                backgroundColor: "#FECD4E",
                color: "#222831",
                padding: "10px",
                borderRadius: "20px",
                width: "100%",
                margin: "2px",
              }}
            >
              <FaSuitcase
                size={40}
                className="counterIcon"
                style={{ marginBottom: "4px" }}
              />
              <CountUp
                separator=","
                start={0}
                end={props.keywordData.total_domains}
                duration={2.75}
              />
              <br /> Matched <br /> Companies
            </div>
            <div
              style={{
                backgroundColor: "#FECD4E",
                color: "#222831",
                padding: "10px",
                borderRadius: "20px",
                width: "100%",
                margin: "2px",
              }}
            >
              <FaHashtag
                size={40}
                className="counterIcon"
                style={{ marginBottom: "4px" }}
              />
              <CountUp
                separator=","
                start={0}
                end={props.keywordData.total_occurrences}
                duration={2.75}
              />
              <br /> Keyword <br /> Occurrence
            </div>
          </div>
        </div>
      )}

      {props.keywordDomains &&
        props.keywordDomains.length > 0 &&
        props.keywordDomains.map((item, index) =>
          props.type !== "gov" ? (
            <div key={`${item.domain}_${index}`}>
              <div
                className={item.blacklist ? "" : "cursor"}
                style={{
                  backgroundColor: item.blacklist ? "lightgray" : "#FECD4E",
                  color: "#222831",
                  borderRadius: "10px",
                  padding: "0.4rem 1rem",
                  display: "inline-flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "80%",
                  margin: "5px auto",
                  height: "4.1rem",
                }}
                onClick={() => {
                  if (!item.blacklist) {
                    props.activeUrlList === item.domain
                      ? props.setActiveUrlList(null)
                      : props.setActiveUrlList(item.domain);
                  }
                }}
              >
                <div style={{ marginRight: "5px" }}>{setIcon(item)}</div>
                <div
                  className={item.class}
                  style={{
                    textAlign: "left",
                    width: "100%",
                    overflowWrap: "anywhere",
                  }}
                >
                  <a
                    href={`https://${item.domain}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.domain}
                  </a>
                </div>
                {props.lazyLoad ? (
                  <div style={{ width: "30%" }}>
                    {numberWithCommas(item.count)}+ Occurrences
                  </div>
                ) : (
                  <div style={{ width: "30%" }}>
                    {numberWithCommas(item.count)} URLS
                  </div>
                )}
                <div
                  onClick={() => {
                    if (!item.blacklist) {
                      props.activeUrlList === item.domain
                        ? props.setActiveUrlList(null)
                        : props.setActiveUrlList(item.domain);
                    }
                  }}
                >
                  {props.activeUrlList === item.domain ? (
                    <MdExpandLess size={25} />
                  ) : (
                    <MdExpandMore size={25} />
                  )}
                </div>
                {props.tooltip ? (
                  <div>
                    <ClickAwayListener onClickAway={() => setOpen(null)}>
                      <div>
                        <Popper
                          open={open === `${item.keyword}.${item.domain}`}
                          anchorEl={anchorEl}
                          placement={placement}
                          transition
                          style={{ zIndex: 999999 }}
                        >
                          {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                              <Paper>
                                {item.blacklist ? (
                                  <div
                                    className="addTooltip"
                                    style={{ display: "grid", padding: "10px" }}
                                  >
                                    <h5>
                                      This domain is too big to monitor in a
                                      company search
                                    </h5>
                                  </div>
                                ) : (
                                  <div
                                    className="addTooltip"
                                    style={{ display: "grid", padding: "10px" }}
                                  >
                                    <h5>ADD DOMAIN TO LIST</h5>
                                    <Button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        props.addExternalDomain(
                                          item.domain,
                                          item.android ? "apk" : "domain"
                                        );
                                        setOpen(null);
                                      }}
                                    >
                                      {item.android
                                        ? "APK DOMAINS"
                                        : "PRIMARY DOMAINS"}
                                    </Button>
                                    <Button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        props.addExternalDomain(
                                          item.domain,
                                          "external"
                                        );
                                        setOpen(null);
                                      }}
                                    >
                                      EXTERNAL DOMAINS
                                    </Button>
                                  </div>
                                )}
                              </Paper>
                            </Fade>
                          )}
                        </Popper>
                      </div>
                    </ClickAwayListener>
                    <Button
                      onClick={(e) => {
                        if (
                          props.addedDomains.includes(item.domain) ||
                          props.addedDomains.includes(
                            `${item.keyword}.${item.domain}`
                          )
                        ) {
                          e.stopPropagation();
                          props.removeDomain(item.domain);
                        } else {
                          handleClick(
                            e,
                            "right-end",
                            `${item.keyword}.${item.domain}`
                          );
                        }
                      }}
                    >
                      {props.addedDomains.includes(item.domain) ||
                      props.addedDomains.includes(
                        `${item.keyword}.${item.domain}`
                      ) ? (
                        <ImCross size={20} />
                      ) : (
                        <BsFillPlusCircleFill size={25} />
                      )}
                    </Button>
                  </div>
                ) : (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      props.addExternalDomain(`${item.keyword}.${item.domain}`);
                    }}
                  >
                    {props.addedDomains.includes(
                      `${item.keyword}.${item.domain}`
                    ) ? (
                      <ImCross size={20} />
                    ) : (
                      <BsFillPlusCircleFill size={25} />
                    )}
                  </div>
                )}
              </div>
              {props.activeUrlList === item.domain &&
              props.urlsLoading !== item.domain ? (
                <div
                  style={{
                    backgroundColor: "#FECD4E",
                    color: "#222831",
                    borderRadius: "20px",
                    padding: "0.2rem",
                    width: "80%",
                    margin: "auto",
                  }}
                >
                  {item.urls_list &&
                    item.urls_list.length > 0 &&
                    item.urls_list.map((_item, _index) => (
                      <div
                        key={_index}
                        style={{
                          backgroundColor: "#1A1C20",
                          color: "#FECD4E",
                          margin: "4px",
                          borderRadius: "20px",
                          padding: "0.2rem",
                          fontSize: "12px",
                          wordBreak: "break-word",
                        }}
                      >
                        <span>{_item}</span>
                      </div>
                    ))}
                </div>
              ) : (
                props.urlsLoading === item.domain && (
                  <LoaderIcon
                    type="spin"
                    style={{
                      width: "50px",
                      height: "100px",
                      margin: "2rem auto 0px",
                      fill: "white",
                    }}
                  />
                )
              )}
            </div>
          ) : (
            <div key={`${item.domain}_${index}`}>
              <div
                className={item.blacklist ? "" : "cursor"}
                style={{
                  backgroundColor: item.blacklist ? "lightgray" : "#FECD4E",
                  color: "#222831",
                  borderRadius: "10px",
                  padding: "0.4rem 1rem",
                  display: "inline-flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "80%",
                  margin: "5px auto",
                  height: "4.1rem",
                }}
                onClick={() => {
                  if (!item.blacklist) {
                    props.activeUrlList === item.domain
                      ? props.setActiveUrlList(null)
                      : props.setActiveUrlList(item.domain);
                  }
                }}
              >
                <div style={{ marginRight: "5px" }}>{setIcon(item)}</div>
                <div
                  className={item.class}
                  style={{
                    textAlign: "left",
                    width: "100%",
                    overflowWrap: "anywhere",
                  }}
                >
                  {item.domain}
                </div>
                <div
                  onClick={() => {
                    if (!item.blacklist) {
                      props.activeUrlList === item.domain
                        ? props.setActiveUrlList(null)
                        : props.setActiveUrlList(item.domain);
                    }
                  }}
                >
                  {props.activeUrlList === item.domain ? (
                    <MdExpandLess size={25} />
                  ) : (
                    <MdExpandMore size={25} />
                  )}
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    if (props.addedDomains.includes(item.domain)) {
                      props.removeDomain(item.domain);
                    } else {
                      props.addExternalDomain(`${item.domain}`, "domain");
                    }
                  }}
                >
                  {props.addedDomains.includes(`${item.domain}`) ? (
                    <ImCross size={20} />
                  ) : (
                    <BsFillPlusCircleFill size={25} />
                  )}
                </div>
              </div>
              {props.activeUrlList === item.domain &&
              item.urls_list &&
              item.urls_list.length > 0 &&
              props.urlsLoading !== item.domain ? (
                <div
                  style={{
                    backgroundColor: "#FECD4E",
                    color: "#222831",
                    borderRadius: "20px",
                    padding: "0.2rem",
                    width: "80%",
                    margin: "auto",
                  }}
                >
                  {item.urls_list.map((_item, _index) => (
                    <div
                      key={_index}
                      style={{
                        backgroundColor: "#1A1C20",
                        color: "#FECD4E",
                        margin: "4px",
                        borderRadius: "20px",
                        padding: "0.2rem",
                        fontSize: "12px",
                        wordBreak: "break-word",
                      }}
                    >
                      <span>{_item}</span>
                    </div>
                  ))}
                </div>
              ) : (
                props.urlsLoading === item.domain && (
                  <LoaderIcon
                    type="spin"
                    style={{
                      width: "50px",
                      height: "100px",
                      margin: "2rem auto 0px",
                      fill: "white",
                    }}
                  />
                )
              )}
            </div>
          )
        )}
      {props.loadMore && (
        <button
          style={{
            borderRadius: "8px",
            padding: "5px 15px",
            backgroundColor: "#FECD4E",
            color: "#222831",
            border: "0px",
            width: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
          }}
          onClick={() => {
            props.setPage(props.page + 1);
          }}
        >
          Load More{" "}
          {props.keywordPageLoading && (
            <LoaderIcon
              type="spin"
              style={{
                width: "16px",
                height: "16px",
                margin: "-10px 0px 0px 10px",
              }}
            />
          )}
        </button>
      )}
    </div>
  ) : (
    props.keywordLoading && (
      <LoaderIcon
        type="spin"
        style={{
          width: "50px",
          height: "auto",
          margin: "2rem auto 0px",
          fill: "white",
        }}
      />
    )
  );
}
