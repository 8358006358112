import axios from "axios";
import React, { useState } from "react";
import { FaFilePdf } from "react-icons/fa";
import LoaderIcon from "react-loader-icon";
import { APIFullAddress } from "../../Config";

const ExportToPDFButton = (props) => {
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);

  const handleExportPDF = async () => {
    try {
      setLoading(true);
      const requestData = {
        domains: props.domains.split("&"),
      };

      let url = `${APIFullAddress}/reports/pdf/domains`;
      if (props.company) {
        url += `?company=${props.company}`;
      }

      const response = await axios.post(url, requestData, {
        headers: {
          // "Content-Type": "application/json",
          // Accept: "application/pdf",
          "auth-token": props.token,
        },
        // responseType: "arraybuffer",
      });

      if (response.data === "OK") {
        setReady(true);
      }
      // const blob = new Blob([response.data], { type: "application/pdf" });
      // const blobUrl = URL.createObjectURL(blob);

      // const a = document.createElement("a");
      // a.href = blobUrl;
      // a.download = props.company
      //   ? `${props.company}.pdf`
      //   : `${props.domains.split("&")[0]}.pdf`;
      // a.click();

      setLoading(false);
    } catch (error) {
      console.error("Error exporting PDF:", error);
      setLoading(false);
    }
  };

  return (
    <>
      <button
        style={{
          borderRadius: "2px",
          padding: "5px 15px",
          backgroundColor: "#FECD4E",
          color: "#222831",
          border: "0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "10px 0px",
          fontSize: "14px",
        }}
        onClick={handleExportPDF}
      >
        {loading ? (
          <LoaderIcon
            type="spin"
            style={{
              width: "16px",
              height: "16px",
              margin: "-10px 10px 0px 0px",
            }}
          />
        ) : (
          <FaFilePdf />
        )}
        EXPORT REPORT TO PDF{" "}
      </button>
      {ready && (
        <h6 style={{ textAlign: "left" }}>
          PDF file will be ready to download from{" "}
          <a
            href="/my-account"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#FDCD4E" }}
          >
            My Account
          </a>{" "}
          page. An email will be sent to {props.email} once ready
        </h6>
      )}
    </>
  );
};

export default ExportToPDFButton;
