/* eslint-disable */

import React, { useEffect, useState } from "react";
import { BiSolidFileHtml, BiSolidFileJson } from "react-icons/bi";
import {
  FaArrowLeft,
  FaFileAlt,
  FaFileCode,
  FaFileCsv,
  FaFilePdf,
  FaFolder,
  FaImage,
} from "react-icons/fa";

import { GrDocumentZip } from "react-icons/gr";
import LoaderIcon from "react-loader-icon";
import CreateApiClient from "../../API";
import AnalyzeStealer from "../partials/AnalyzeStealer";
import FileViewer from "./FileViewer";

const api = CreateApiClient();

// Styles
const containerStyle = {
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  maxHeight: "80vh",
  overflowY: "auto",
  alignItems: "flex-start",
  alignContent: "flex-start",
  padding: "1rem 1rem 0rem",
  width: "100%",
  zIndex: "5",
};

const nodeStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "15px",
  // cursor: "pointer",
  color: "#FFA500",
  width: "120px",
  userSelect: "none",
};

const nodeNameStyle = {
  fontSize: "13px",
  marginTop: "5px",
  textAlign: "center",
  wordWrap: "break-word", // Ensure long words do not overflow
  width: "100%", // Ensure the name utilizes the full width available
};

const selectedIconStyle = {
  backgroundColor: "#0000006e",
  padding: "10px",
  borderRadius: "2px",
};

const selectedFileNameStyle = {
  backgroundColor: "#0264b9bd",
  borderColor: "#0264b9bd",
  borderWidth: "2px",
  borderStyle: "solid",
  width: "80%",
};

function textToBlobUrl(text) {
  // Create a Blob from the text data
  const blob = new Blob([text], { type: "text/plain" });

  // Create a URL for the Blob
  const fileUrl = URL.createObjectURL(blob);
  // window.open(fileUrl);
  return fileUrl;
}

const OverlayLoading = () => {
  return (
    <div className="overlay" style={{ background: "#0000007a" }}>
      <div className="overlay__inner">
        <div className="overlay__content" style={{ textAlign: "center" }}>
          <span className="spinner"></span>
          <h1 className="darkText">Loading Computer Data...</h1>
        </div>
      </div>
    </div>
  );
};

const IconOverlay = (icon, overlay) => {
  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      {icon}
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "14px",
          color: "#222831",
        }}
      >
        {overlay}
      </div>
    </div>
  );
};

const FileNode = ({
  node,
  onNavigate,
  animate,
  isSelected,
  onSelectNode,
  idx,
}) => {
  const isFile = node.type === "file";
  const imageRegex = /\.(png|jpe?g)$/i;
  const configRegex = /.conf/i;
  const pdfRegex = /.pdf/i;
  const jsonRegex = /.json/i;
  const csvRegex = /.csv/i;
  const htmlRegex = /.html/i;

  const getIcon = () => {
    if (isFile) {
      return imageRegex.test(node.name) ? (
        <FaImage size={32} />
      ) : configRegex.test(node.name) ? (
        <FaFileCode size={32} />
      ) : pdfRegex.test(node.name) ? (
        <FaFilePdf size={32} />
      ) : jsonRegex.test(node.name) ? (
        <BiSolidFileJson size={42} />
      ) : csvRegex.test(node.name) ? (
        <FaFileCsv size={32} />
      ) : htmlRegex.test(node.name) ? (
        <BiSolidFileHtml size={32} />
      ) : (
        <FaFileAlt size={32} />
      );
    } else {
      return <FaFolder size={32} />;
    }
  };

  return (
    <div
      style={nodeStyle}
      onDoubleClick={() => onNavigate(node)}
      onClick={() => onSelectNode(idx)}
      className={animate ? "fadeIn custom-cursor-hover" : "custom-cursor-hover"}
    >
      <span style={isSelected ? selectedIconStyle : {}}>{getIcon()}</span>
      <span
        style={
          isSelected
            ? { ...nodeNameStyle, ...selectedFileNameStyle }
            : nodeNameStyle
        }
      >
        {node.name}
      </span>
    </div>
  );
};

const StealerData = ({ stealerData }) => {
  return (
    <div
      style={{
        position: "absolute",
        color: "#ffa500",
        bottom: "15px",
        right: "10px",
        display: "grid",
        width: "20%",
        wordBreak: "break-word",
        zIndex: "1",
        fontSize: "14px",
      }}
    >
      <span>Computer Name: {stealerData.pc}</span>
      <span>IP Address: {stealerData.ip}</span>
      <span>Operating System: {stealerData.operating_system}</span>
      {/* <span>Malware Path: {stealerData.malware_path}</span> */}
      <span>Anti Virus: {stealerData.antivirus}</span>
      <span>Stealer Family: {stealerData.stealer_family}</span>
    </div>
  );
};

const FileExplorer = ({
  initialData,
  dumpLoading,
  dump,
  stealer,
  headers,
  stealerData,
  blocked,
  exportZip,
  exportLoading,
  role,
  userUiPermissions,
}) => {
  const [navigationStack, setNavigationStack] = useState([initialData]);
  const [visibleCount, setVisibleCount] = useState(0);
  const [showViewer, setShowViewer] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [fileOpen, setFileOpen] = useState(null);
  const [fileContent, setFileContent] = useState("");
  const [fileName, setFileName] = useState("");
  const [allowCopy, setAllowCopy] = useState(false);
  const [selectedNodeName, setSelectedNodeName] = useState(null);
  const [fileLoading, setFileLoading] = useState(null);

  const handleSelectNode = (idx) => {
    setSelectedNodeName(idx);
  };

  useEffect(() => {
    if (
      navigationStack.length === 1 &&
      initialData.children &&
      initialData.children.length > 0
    ) {
      setAnimate(true);
      let time = !dump.type ? 15000 / initialData.children.length : 0;
      const intervalId = setInterval(() => {
        setVisibleCount((currentCount) => {
          if (currentCount < initialData.children.length) {
            return currentCount + 1;
          } else {
            clearInterval(intervalId);
            setAnimate(false);
            return currentCount;
          }
        });
      }, time);

      return () => clearInterval(intervalId);
    }
  }, [dump]);

  useEffect(() => {
    setNavigationStack([initialData]);
  }, [initialData]);

  const getFile = async (node) => {
    if (blocked) return;
    const body = {
      filename: node.name,
      navigationStack: navigationStack,
    };

    setFileLoading(node.name);
    setShowViewer(true);
    const data = await api.fetchData("stealers/file", body, { headers });
    const file = textToBlobUrl(data);
    const ext = node.name
      .split(".")
      [node.name.split(".").length - 1].toLowerCase();
    const textFiles = ["txt", "csv", "json", "vdf", "conf"];
    const imgFiles = ["jpg", "png", "jpeg", "gif", "pdf", "docx", "xlsx"];
    if (!imgFiles.includes(ext)) {
      setAllowCopy(true);
    }
    const fileUrl = `${file}.${ext}`;
    setFileLoading(null);
    // setShowViewer(true);
    setFileOpen(fileUrl);
    setFileContent(data);
    setFileName(node.name);
  };

  const clearFile = () => {
    setFileOpen(null);
    setFileName("");
    setFileContent("");
    setShowViewer(false);
    setAllowCopy(false);
  };

  const handleNavigate = async (node) => {
    clearFile();
    if (node.type === "file") {
      await getFile(node);
    } else {
      setSelectedNodeName(null);
      if (node.children) {
        setVisibleCount(200);
      }
      setNavigationStack((prevStack) => [...prevStack, node]);
    }
  };

  const handleGoBack = () => {
    clearFile();
    setSelectedNodeName(null);
    setNavigationStack((prevStack) => prevStack.slice(0, -1));
  };

  const handlePathClick = (index) => {
    clearFile();
    setSelectedNodeName(null);
    setNavigationStack((prevStack) => prevStack.slice(0, index + 1));
  };

  const renderPath = () => {
    return navigationStack.map(
      (node, index) =>
        node && (
          <React.Fragment key={index}>
            {index > 0 && " > "}
            <span
              className="custom-cursor-hover"
              style={{
                color: "#FFA500",
                textDecoration: "underline",
              }}
              onClick={() => handlePathClick(index)}
            >
              {node.name}
            </span>
          </React.Fragment>
        )
    );
  };

  const currentNode = navigationStack[navigationStack.length - 1];

  return (
    <div className="custom-cursor">
      <div
        style={{
          padding: "20px",
          backgroundColor: "#222831",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          width: "100%",
        }}
      >
        {navigationStack.length > 1 && (
          <button
            className="custom-cursor-hover"
            onClick={handleGoBack}
            style={{
              backgroundColor: "transparent",
              border: "0px",
              textDecoration: "underline",
              color: "#FFA500",
              marginRight: "10px",
            }}
          >
            <FaArrowLeft /> Back
          </button>
        )}
        <div
          style={{
            color: "#FFA500",
          }}
        >
          {renderPath()}
        </div>
        <button
          style={{
            position: "absolute",
            right: "20px",
            backgroundColor: "#FDCD4E",
            borderRadius: "2px",
            border: "0px",
            padding: "8px 25px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={exportZip}
        >
          <span style={{ marginRight: "5px" }}>Export to ZIP</span>
          {exportLoading ? (
            <LoaderIcon
              type="spin"
              style={{
                fill: "black",
                width: "16px",
                height: "auto",
              }}
            />
          ) : (
            <GrDocumentZip />
          )}
        </button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "start",
          width: "100%",
        }}
      >
        {/* <LuPointer fill="#fdce4d" size={32} />
        <LuMousePointer2 fill="#fdce4d" size={32} /> */}
        <div style={containerStyle}>
          {navigationStack[navigationStack.length - 1] &&
            navigationStack[navigationStack.length - 1].children.slice(
              0,
              visibleCount
            ).length < 1 &&
            !dumpLoading && (
              <span
                style={{
                  color: "#FFA500",
                }}
              >
                Directory is empty
              </span>
            )}
          {navigationStack[navigationStack.length - 1].children
            .slice(0, visibleCount)
            .map((node, index) => (
              <FileNode
                idx={index}
                animate={animate}
                key={index}
                node={node}
                onNavigate={handleNavigate}
                isSelected={selectedNodeName === index}
                onSelectNode={handleSelectNode}
              />
            ))}
          {navigationStack[navigationStack.length - 1] &&
            navigationStack[navigationStack.length - 1].children.slice(
              0,
              visibleCount
            ).length > 0 &&
            !dumpLoading &&
            userUiPermissions.includes("ai_analysis") && (
              <AnalyzeStealer
                parent={"file_explorer"}
                stealerId={
                  stealerData.stealer_pure
                    ? stealerData.stealer_pure
                    : stealerData.stealer
                }
                headers={headers}
              />
            )}
        </div>
        {showViewer && (
          <FileViewer
            isVisible={showViewer}
            onClose={() => {
              clearFile();
            }}
            fileUrl={fileOpen}
            fileContent={fileContent}
            filename={fileName}
            allowCopy={allowCopy}
            loading={fileLoading}
          />
        )}

        <StealerData stealerData={stealerData} />
      </div>

      {dumpLoading && <OverlayLoading />}
    </div>
  );
};

export default FileExplorer;
