import Axios from "axios";
import { createBrowserHistory } from "history";
import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import LoggedOutModal from "../src/components/LoggedOutModal";
import { authAddress } from "./Config";
import NotFound from "./components/404";
import Account from "./components/Account";
import Domain from "./components/Domain";
import ResetPassword from "./components/ResetPassword";
import SignInSide from "./components/SignIn";
import UrlSignIn from "./components/UrlSignIn";
import AdminTools from "./components/admin/AdminTools";
import ApiDashboard from "./components/admin/ApiDashboard";
import Panel from "./components/admin/Panel";
import Slack from "./components/auth/Slack";
import Docs from "./components/content/Docs";
import Help from "./components/content/Help";
import Unsubscribe from "./components/email/Unsubscribe";
import Browse from "./components/views/Browse";
import Companies from "./components/views/Companies";
import Email from "./components/views/Email";
import Ip from "./components/views/Ip";
import PasswordsDay from "./components/views/PasswordsDay";
import Stealer from "./components/views/Stealer";
import "./css/App.css";
import "./css/Preview.css";

const history = createBrowserHistory();

function Main(props) {
  let indexUser,
    indexToken,
    indexRole,
    indexRefreshToken,
    indexApps,
    indexDemos,
    indexExpiry,
    isDemo,
    indexMonitoredDomains,
    indexUnlockedStealers,
    indexUiPermissions;
  if (props.user) {
    indexUser = props.user;
    indexToken = JSON.parse(indexUser).Token;
    indexRole = JSON.parse(indexUser).Role;
    indexRefreshToken = JSON.parse(indexUser).RefreshToken;
    indexExpiry = JSON.parse(indexUser).expiry;
    indexApps = JSON.parse(indexUser).apps;
    indexDemos = JSON.parse(indexUser).demo_users;
    indexMonitoredDomains = JSON.parse(indexUser).monitored_domains;
    isDemo = JSON.parse(indexUser).demo;
    indexUiPermissions = JSON.parse(indexUser).ui_permissions;
  } else {
    indexUser = "";
    indexToken = "";
    indexRole = "";
    indexRefreshToken = "";
    indexExpiry = "";
    indexApps = "";
    indexDemos = "";
    indexMonitoredDomains = "";
    isDemo = "";
    indexUnlockedStealers = "";
  }

  const [logEmail, setLogEmail] = useState("");
  const [logPassword, setLogPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [user, setUser] = useState(indexUser);
  const [token, setToken] = useState(indexToken);
  const [refreshToken, setRefreshToken] = useState(indexRefreshToken);
  const [userRole, setUserRole] = useState(indexRole);
  const [userDomains, setUserDomains] = useState([]);
  const [expiry, setExpiry] = useState(indexExpiry);
  const [now, setNow] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [loggingOut, isLoggingOut] = useState(false);
  const [userApps, setUserApps] = useState(indexApps);
  const [loading, setLoading] = useState(false);
  const [demoUsers, setDemoUsers] = useState(indexDemos);
  const [isDemoUser, setIsDemoUser] = useState(isDemo);
  const [monitoredDomains, setMonitoredDomains] = useState(
    indexMonitoredDomains
  );
  const [totp, setTotp] = useState(false);
  const [digitsLoading, setDigitsLoading] = useState(false);
  const [userUiPermissions, setUserUiPermissions] =
    useState(indexUiPermissions);
  const currentPage = history.location;

  const unlockedStealers = indexUnlockedStealers;

  const onChangeEmail = async (e) => {
    setLogEmail(e.target.value);
  };

  const onChangePassword = (e) => {
    setLogPassword(e.target.value);
  };

  useLayoutEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      setUser(JSON.parse(loggedInUser));
      setLogEmail(foundUser.email);
      setUserDomains(foundUser.all_domains);
      setUserRole(foundUser.Role);
      setToken(foundUser.Token);
      setRefreshToken(foundUser.RefreshToken);
      setExpiry(foundUser.expiry);
      setUserApps(foundUser.apps);
      setDemoUsers(foundUser.demo_users);
      setUserUiPermissions(foundUser.ui_permissions);
      Axios.post(
        `${authAddress}/get-my-user`,
        {},
        {
          headers: {
            "auth-token": foundUser.Token,
          },
        }
      )
        .then((res) => {
          const data = res.data;
          if (data.ui_permissions) {
            foundUser["ui_permissions"] = data.ui_permissions;
            updateUser(foundUser);
          }

          if (data.search_config) {
            foundUser["search_config"] = data.search_config;
            updateUser(foundUser);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    const time = Date.now().toString().substr(0, 10);
    const nowNum = Number(time);
    setNow(nowNum);
  }, []);

  const handleLogOut = () => {
    // setOpenModal(true);
    localStorage.clear();
    setUser(!user);
    redirectToLoginPage();
    // setOpenModal(false);
    // setTimeout(() => {
    //   localStorage.clear();
    //   setUser(!user);
    //   redirectToLoginPage();
    //   setOpenModal(false);
    // }, 2000);
  };

  const handleHomeLogOut = () => {
    localStorage.clear();
    setUser(!user);
    redirectToLoginPage();
  };

  const urlHashLogin = (hash) => {
    setLoading(true);
    isLoggingOut(false);
    setLoginError(null);

    Axios.post(`${authAddress}/hashlogin?token=${hash}`)
      .then((res) => {
        setUserDomains(res.data.all_domains);
        setUserRole(res.data.Role);
        setToken(res.data.Token);
        setRefreshToken(res.data.RefreshToken);
        setLogEmail(res.data.email);
        setExpiry(res.data.expiry);
        setUserApps(res.data.apps);
        setUser(res.data);
        setMonitoredDomains(res.data.monitored_domains);
        setIsDemoUser(res.data.demo);
        setUserUiPermissions(res.data.ui_permissions);
        localStorage.setItem("user", JSON.stringify(res.data));
        history.push(`/`);
        window.location.reload();
      })
      .catch((error) => {
        if (error.response.data) {
          console.log(error.response.data);
          setLoginError(error.response.data);
        } else {
          setLoginError("Wrong Email Or Password");
        }
        console.log(error);
      });
    setLoading(false);
  };

  const login = async (e) => {
    isLoggingOut(false);
    e.preventDefault();
    setLoginError("");
    const user = {
      email: logEmail,
      password: logPassword,
    };

    const isTotp = await Axios.post(`${authAddress}/check-if-totp`, {
      email: logEmail,
    })
      .then((res) => {
        return res.data.totp;
      })
      .catch((err) => console.log(err));

    if (isTotp) {
      setTotp(true);
    } else {
      Axios.post(`${authAddress}`, user)
        .then((res) => {
          setUserDomains(res.data.all_domains);
          setUserRole(res.data.Role);
          setToken(res.data.Token);
          setRefreshToken(res.data.RefreshToken);
          setLogEmail(res.data.email);
          setExpiry(res.data.expiry);
          setUserApps(res.data.apps);
          setDemoUsers(res.data.demo_users);
          setMonitoredDomains(res.data.monitored_domains);
          setIsDemoUser(res.data.demo);
          setUser(res.data);
          setUserUiPermissions(res.data.ui_permissions);
          localStorage.setItem("user", JSON.stringify(res.data));
        })
        .catch((error) => {
          console.log(error);
          if (error.response && error.response.data) {
            console.log(error.response.data);
            setLoginError(error.response.data);
          } else {
            setLoginError("Wrong Email Or Password");
          }
          console.log(error);
        });
    }
  };

  const redirectToLoginPage = () => {
    history.push("/api/auth/sso/auth0/logout");
    window.location.reload();
  };

  const refresh = () => {
    window.location.reload();
  };

  const checkDigits = async (digits) => {
    setDigitsLoading(true);
    const user = {
      email: logEmail,
      password: logPassword,
      digits: JSON.stringify(digits),
    };

    Axios.post(`${authAddress}`, user)
      .then((res) => {
        setUserDomains(res.data.all_domains);
        setUserRole(res.data.Role);
        setToken(res.data.Token);
        setRefreshToken(res.data.RefreshToken);
        setLogEmail(res.data.email);
        setExpiry(res.data.expiry);
        setUserApps(res.data.apps);
        setDemoUsers(res.data.demo_users);
        setMonitoredDomains(res.data.monitored_domains);
        setIsDemoUser(res.data.demo);
        setUser(res.data);
        setUserUiPermissions(res.data.ui_permissions);
        localStorage.setItem("user", JSON.stringify(res.data));
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          setLoginError(error.response.data);
        }
      });
    setDigitsLoading(false);
    setLoginError("");
    setTotp(false);
  };

  const updateUser = (_user) => {
    localStorage.setItem("user", JSON.stringify(_user));
    setUser(_user);
  };

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={process.env.PUBLIC_URL + "/login"}
          errorElement={<NotFound />}
        >
          {user && expiry > now ? (
            userRole === "MSSP" ||
            userRole === "Admin" ||
            userRole === "master" ? (
              <Redirect to="/" />
            ) : userDomains && userDomains.length > 0 ? (
              <Redirect to={`/search-by-domain/${userDomains.join("&")}`} />
            ) : (
              <Redirect to={`/my-account`} />
            )
          ) : (
            <SignInSide
              loggingOut={loggingOut}
              login={login}
              email={onChangeEmail}
              password={onChangePassword}
              error={loginError}
              totp={totp}
              checkDigits={(digits) => checkDigits(digits)}
              digitsLoading={digitsLoading}
            />
          )}
        </Route>
        {/* <Route
          exact
          path={process.env.PUBLIC_URL + "/sso-login"}
          errorElement={<NotFound />}
        >
          {user && expiry > now ? (
            userRole === "MSSP" ||
            userRole === "Admin" ||
            userRole === "master" ? (
              <Redirect to="/" />
            ) : userDomains && userDomains.length > 0 ? (
              <Redirect to={`/search-by-domain/${userDomains.join("&")}`} />
            ) : (
              <Redirect to={`/my-account`} />
            )
          ) : (
            <SignInSide
              loggingOut={loggingOut}
              login={login}
              email={onChangeEmail}
              password={onChangePassword}
              error={loginError}
              totp={totp}
              checkDigits={(digits) => checkDigits(digits)}
              digitsLoading={digitsLoading}
            />
          )}
        </Route> */}
        <Route
          exact
          path={process.env.PUBLIC_URL + "/"}
          errorElement={<NotFound />}
        >
          {user && expiry > now ? (
            userRole === "MSSP" ||
            userRole === "Admin" ||
            userRole === "master" ? (
              <Browse
                expiry={expiry}
                unlockedStealers={unlockedStealers}
                monitoredDomains={monitoredDomains}
                user={user}
                token={token}
                refreshToken={refreshToken}
                role={userRole}
                domains={userDomains}
                email={logEmail}
                handleLogOut={() => handleLogOut()}
                handleHomeLogOut={() => handleHomeLogOut()}
              />
            ) : (
              userDomains &&
              userDomains[0] &&
              (userDomains.length > 0 ? (
                <Redirect to={`/search-by-domain/${userDomains.join("&")}`} />
              ) : userDomains && userDomains.length === 0 ? (
                <Redirect to={`/search-by-domain/${userDomains[0]}`} />
              ) : (
                <Redirect to={`/my-account`} />
              ))
            )
          ) : (
            <Redirect to="/api/auth/sso/auth0" />
          )}
        </Route>
        <Route
          errorElement={<NotFound />}
          path={process.env.PUBLIC_URL + "/passwords"}
        >
          <PasswordsDay />
        </Route>
        <Route
          errorElement={<NotFound />}
          path={process.env.PUBLIC_URL + "/my-account"}
        >
          {user && expiry > now ? (
            <Account
              refresh={refresh}
              now={now}
              demoUsers={demoUsers}
              token={token}
              handleLogOut={() => handleLogOut()}
              handleHomeLogOut={() => handleHomeLogOut()}
              user={user}
              email={logEmail}
              domains={userDomains}
              apps={userApps}
              onlyDomains={
                userApps && userDomains
                  ? userDomains.filter((e) => !userApps.includes(e))
                  : userDomains
              }
              isDemo={isDemoUser}
              role={userRole}
              currentPage={currentPage.pathname}
            />
          ) : (
            <Redirect to="/api/auth/sso/auth0" />
          )}
        </Route>
        <Route
          errorElement={<NotFound />}
          path={process.env.PUBLIC_URL + "/hector-panel"}
        >
          {user && expiry > now ? (
            userRole === "Admin" ? (
              <Panel
                handleHomeLogOut={() => handleHomeLogOut()}
                user={user}
                currentPage={currentPage.pathname}
                role={userRole}
                token={token}
                email={logEmail}
                domains={userDomains}
                handleLogOut={() => handleLogOut()}
              />
            ) : (
              <Redirect to="/" />
            )
          ) : (
            <Redirect to="/api/auth/sso/auth0" />
          )}
        </Route>
        <Route
          errorElement={<NotFound />}
          path={process.env.PUBLIC_URL + "/hazan-tools"}
        >
          {user && expiry > now ? (
            userRole === "Admin" ? (
              <AdminTools
                handleHomeLogOut={() => handleHomeLogOut()}
                user={user}
                currentPage={currentPage.pathname}
                role={userRole}
                token={token}
                email={logEmail}
                domains={userDomains}
                handleLogOut={() => handleLogOut()}
              />
            ) : (
              <Redirect to="/" />
            )
          ) : (
            <Redirect to="/api/auth/sso/auth0" />
          )}
        </Route>
        <Route
          errorElement={<NotFound />}
          path={process.env.PUBLIC_URL + "/api-admin"}
        >
          {user && expiry > now ? (
            userRole === "Admin" ? (
              <ApiDashboard
                handleHomeLogOut={() => handleHomeLogOut()}
                user={user}
                currentPage={currentPage.pathname}
                role={userRole}
                token={token}
                email={logEmail}
                domains={userDomains}
                handleLogOut={() => handleLogOut()}
              />
            ) : (
              <Redirect to="/" />
            )
          ) : (
            <Redirect to="/api/auth/sso/auth0" />
          )}
        </Route>
        <Route
          errorElement={<NotFound />}
          path={process.env.PUBLIC_URL + "/slackbot-oauth"}
        >
          {user && expiry > now ? (
            <Slack
              handleHomeLogOut={() => handleHomeLogOut()}
              user={user}
              currentPage={currentPage.pathname}
              role={userRole}
              token={token}
              email={logEmail}
              domains={userDomains}
              handleLogOut={() => handleLogOut()}
            />
          ) : (
            <Redirect to="/api/auth/sso/auth0" />
          )}
        </Route>
        <Route
          errorElement={<NotFound />}
          path={process.env.PUBLIC_URL + "/docs"}
        >
          <Docs />
        </Route>
        <Route
          errorElement={<NotFound />}
          path={process.env.PUBLIC_URL + "/tokenaccess"}
        >
          <UrlSignIn
            login={urlHashLogin}
            user={user}
            loading={loading}
            token={token}
            userRole={userRole}
            userDomains={userDomains}
            logEmail={logEmail}
            handleLogOut={() => handleLogOut()}
            handleHomeLogOut={() => handleHomeLogOut()}
            loginError={loginError}
          />
        </Route>
        <Route
          errorElement={<NotFound />}
          exact
          path={process.env.PUBLIC_URL + "/search-by-domain/:domain"}
        >
          {user && expiry > now ? (
            <Domain
              expiry={expiry}
              unlockedStealers={unlockedStealers}
              monitoredDomains={monitoredDomains}
              user={user}
              token={token}
              refreshToken={refreshToken}
              role={userRole}
              domains={userDomains}
              email={logEmail}
              handleLogOut={() => handleLogOut()}
              handleHomeLogOut={() => handleHomeLogOut()}
            />
          ) : (
            <Redirect to="/api/auth/sso/auth0" />
          )}
        </Route>
        <Route
          errorElement={<NotFound />}
          exact
          path={process.env.PUBLIC_URL + "/search-by-company/:id"}
        >
          {user && expiry > now ? (
            <Domain
              expiry={expiry}
              unlockedStealers={unlockedStealers}
              monitoredDomains={monitoredDomains}
              user={user}
              token={token}
              refreshToken={refreshToken}
              role={userRole}
              domains={userDomains}
              email={logEmail}
              handleLogOut={() => handleLogOut()}
              handleHomeLogOut={() => handleHomeLogOut()}
            />
          ) : (
            <Redirect to="/api/auth/sso/auth0" />
          )}
        </Route>
        <Route
          errorElement={<NotFound />}
          exact
          path={process.env.PUBLIC_URL + "/search-by-stealer/:stealer"}
        >
          {user &&
          expiry > now &&
          userUiPermissions &&
          userUiPermissions.includes("search-by-stealer") ? (
            <Stealer
              userUiPermissions={userUiPermissions}
              expiry={expiry}
              unlockedStealers={unlockedStealers}
              monitoredDomains={monitoredDomains}
              user={user}
              token={token}
              refreshToken={refreshToken}
              role={userRole}
              domains={userDomains}
              email={logEmail}
              handleLogOut={() => handleLogOut()}
              handleHomeLogOut={() => handleHomeLogOut()}
            />
          ) : userUiPermissions && userUiPermissions.includes("ai_analysis") ? (
            <Stealer
              userUiPermissions={userUiPermissions}
              expiry={expiry}
              unlockedStealers={unlockedStealers}
              monitoredDomains={monitoredDomains}
              user={user}
              token={token}
              refreshToken={refreshToken}
              role={userRole}
              domains={userDomains}
              email={logEmail}
              handleLogOut={() => handleLogOut()}
              handleHomeLogOut={() => handleHomeLogOut()}
            />
          ) : (
            <Redirect to="/api/auth/sso/auth0" />
          )}
        </Route>
        <Route
          errorElement={<NotFound />}
          exact
          path={process.env.PUBLIC_URL + "/search-by-email/:email"}
        >
          {user &&
          expiry > now &&
          userUiPermissions &&
          userUiPermissions.includes("search-by-email") ? (
            <Email
              userUiPermissions={userUiPermissions}
              expiry={expiry}
              unlockedStealers={unlockedStealers}
              monitoredDomains={monitoredDomains}
              user={user}
              token={token}
              refreshToken={refreshToken}
              role={userRole}
              domains={userDomains}
              email={logEmail}
              handleLogOut={() => handleLogOut()}
              handleHomeLogOut={() => handleHomeLogOut()}
            />
          ) : (
            <Redirect to="/api/auth/sso/auth0" />
          )}
        </Route>
        <Route
          errorElement={<NotFound />}
          exact
          path={process.env.PUBLIC_URL + "/search-by-ip/:ip"}
        >
          {user &&
          expiry > now &&
          userUiPermissions &&
          userUiPermissions.includes("search-by-ip") ? (
            <Ip
              userUiPermissions={userUiPermissions}
              expiry={expiry}
              unlockedStealers={unlockedStealers}
              monitoredDomains={monitoredDomains}
              user={user}
              token={token}
              refreshToken={refreshToken}
              role={userRole}
              domains={userDomains}
              email={logEmail}
              handleLogOut={() => handleLogOut()}
              handleHomeLogOut={() => handleHomeLogOut()}
            />
          ) : (
            <Redirect to="/api/auth/sso/auth0" />
          )}
        </Route>
        <Route
          errorElement={<NotFound />}
          exact
          path={process.env.PUBLIC_URL + "/companies"}
        >
          {user &&
          userUiPermissions &&
          userUiPermissions.includes("companies") &&
          expiry > now ? (
            <Companies
              updateUser={(user) => updateUser(user)}
              expiry={expiry}
              unlockedStealers={unlockedStealers}
              monitoredDomains={monitoredDomains}
              user={user}
              token={token}
              refreshToken={refreshToken}
              role={userRole}
              domains={userDomains}
              email={logEmail}
              handleLogOut={() => handleLogOut()}
              handleHomeLogOut={() => handleHomeLogOut()}
            />
          ) : (
            <Redirect to="/api/auth/sso/auth0" />
          )}
        </Route>
        <Route
          errorElement={<NotFound />}
          exact
          path={process.env.PUBLIC_URL + "/help"}
        >
          <Help
            handleHomeLogOut={() => handleHomeLogOut()}
            user={user}
            currentPage={currentPage.pathname}
            role={userRole}
            token={token}
            email={logEmail}
            domains={userDomains}
            handleLogOut={() => handleLogOut()}
          />
        </Route>
        <Route
          errorElement={<NotFound />}
          path={process.env.PUBLIC_URL + "/password-reset/:id/:token"}
        >
          <ResetPassword />
        </Route>
        <Route
          errorElement={<NotFound />}
          path={process.env.PUBLIC_URL + "/unsubscribe"}
        >
          <Unsubscribe />
        </Route>
        <Route path="*">
          {user && expiry > now ? (
            <NotFound />
          ) : (
            <SignInSide
              loggingOut={loggingOut}
              login={login}
              email={onChangeEmail}
              password={onChangePassword}
              error={loginError}
              totp={totp}
              checkDigits={(digits) => checkDigits(digits)}
              digitsLoading={digitsLoading}
            />
          )}
        </Route>
      </Switch>
      <LoggedOutModal open={openModal} />
    </Router>
  );
}

export default Main;
