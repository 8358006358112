/* eslint-disable */

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import AccountCircle from "@material-ui/icons/AccountCircle";
import BusinessIcon from "@material-ui/icons/Business";
import EmailIcon from "@material-ui/icons/Email";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import cryptoRandomString from "crypto-random-string";
import React, { useEffect, useState } from "react";
import { FaUser, FaUserNinja, FaUserSecret, FaUserTie } from "react-icons/fa";
import LoaderIcon from "react-loader-icon";
const TextGrid = (props) => {
  return (
    <>
      <Grid item>{props.icon}</Grid>
      <Grid item style={{ width: "70%" }}>
        <TextField
          fullWidth
          autoComplete="new-password"
          id={`${props.name}_${Date.now()}`}
          name={`${props.name}_${Date.now()}`}
          label={props.name}
          className={`${props.className} whiteInput`}
          onChange={props.onChange}
          value={props.value}
        />
      </Grid>
    </>
  );
};

const YellowBoxes = (props) => {
  const roles = [
    {
      name: "User",
      value: "User",
      icon: <FaUser size={25} />,
    },
    {
      name: "Customer",
      value: "Customer",
      icon: <FaUserTie size={25} />,
    },
    {
      name: "MSSP",
      value: "MSSP",
      icon: <FaUserSecret size={25} />,
    },
    {
      name: "Master",
      value: "master",
      icon: <FaUserNinja size={25} />,
    },
  ];
  return (
    <div className="inline-flex justify-center align-items-center w-100 rolesBox">
      {roles.map((item, index) => (
        <div
          onClick={() => props.onClick(item)}
          key={index}
          className={
            props.role === item.value
              ? "yellowBG grid-center cursor"
              : "lightGrayBG grid-center cursor"
          }
        >
          {item.icon}
          {item.name}
        </div>
      ))}
    </div>
  );
};

const isValidEmail = (email) => {
  const re =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return re.test(email);
};

export default function UserDetails(props) {
  const [activeStage, setActiveStage] = useState(0);

  const next = () => {
    setActiveStage(activeStage + 1);
  };

  useEffect(() => {
    if (activeStage === 1) {
      populateData();
    }
  }, [activeStage]);

  const populateDetails = (e) => {
    e.preventDefault();
    const randomPassword = cryptoRandomString({ length: 16, type: "url-safe" });
    props.setPassword(randomPassword);
    handleEmailChange(props.email);
    next();
  };

  const populateData = (e) => {
    props.populateUserData();
  };

  const handleEmailChange = (value) => {
    props.setEmail(value);
    const primaryDomain = value.split("@")[1];
    const userName = value.split("@")[0];
    if (primaryDomain) {
      const companyName = primaryDomain.split(".")[0];
      props.setCompany(companyName);
      props.setKeyword(companyName);
    }
    props.setName(userName);
  };

  const handleCompanyChange = (e) => {
    props.setCompany(e.target.value);
    // props.setKeyword(e.target.value);
  };

  const userDetails = [
    {
      name: "Email",
      field: "email",
      value: props.email,
      icon: <EmailIcon style={{ fill: "white" }} />,
      onChange: (e) => handleEmailChange(e.target.value),
    },
    {
      name: "Name",
      field: "name",
      value: props.name,
      icon: <AccountCircle style={{ fill: "white" }} />,
      onChange: (e) => props.setName(e.target.value),
      className: "capitalize",
    },
    {
      name: "Company",
      field: "company",
      value: props.company,
      icon: <BusinessIcon style={{ fill: "white" }} />,
      onChange: (e) => {
        handleCompanyChange(e);
      },
      className: "capitalize",
    },
    {
      name: "Password",
      field: "password",
      value: props.password,
      icon: <VpnKeyIcon style={{ fill: "white" }} />,
      onChange: (e) => props.setPassword(e.target.value),
    },
  ];

  const stages = [
    {
      title: "Start by simply entering an Email:",
      component: (
        <Grid
          container
          spacing={1}
          alignItems="flex-end"
          justifyContent="center"
        >
          <TextGrid
            value={props.email}
            onChange={(e) => props.setEmail(e.target.value.toLowerCase())}
            name="Email"
            icon={<EmailIcon style={{ fill: "white" }} />}
          />
          <Button
            style={{ width: "70%", marginTop: "10px" }}
            variant="contained"
            color="primary"
            className=""
            type="submit"
            // onClick={populateDetails}
            disabled={!isValidEmail(props.email)}
            endIcon={
              props.loading ? (
                <LoaderIcon className="buttonLoader tabsLoader" type="spin" />
              ) : props.saved ? (
                <Icon>done</Icon>
              ) : (
                <Icon>send</Icon>
              )
            }
          >
            LET'S GO
          </Button>
        </Grid>
      ),
    },
    {
      title: "Feel free to edit the details:",
      component: (
        <>
          {userDetails.map((item, index) => (
            <Grid
              key={index}
              container
              spacing={1}
              alignItems="flex-end"
              justifyContent="center"
            >
              <TextGrid
                key={index}
                onChange={item.onChange}
                name={item.name}
                icon={item.icon}
                value={item.value}
                className={item.className}
              />
            </Grid>
          ))}
          {!props.userDataLoading && (
            <Button
              style={{ width: "80%", margin: "10px auto" }}
              variant="contained"
              color="primary"
              className=""
              type="button"
              onClick={populateData}
              disabled={!isValidEmail(props.email)}
              endIcon={
                props.loading ? (
                  <LoaderIcon className="buttonLoader tabsLoader" type="spin" />
                ) : props.saved ? (
                  <Icon>done</Icon>
                ) : (
                  <Icon>send</Icon>
                )
              }
            >
              GET COMPANY DATA
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <form onSubmit={populateDetails}>
      <div className="userDetailsBox" style={{ backgroundColor: "#222831" }}>
        <YellowBoxes
          role={props.role}
          onClick={(e) => props.setRole(e.value)}
        />
        <br />
        <br />
        <h4 className="cyberFont bold text-center yellowColor">
          {stages[activeStage].title}
        </h4>
        {stages[activeStage].component}
      </div>
    </form>
  );
}
