import mammoth from "mammoth";
import React, { useEffect, useState } from "react";

const DocxViewer = ({ docxUrl }) => {
  const [docHtml, setDocHtml] = useState("");

  useEffect(() => {
    const loadDocx = async (url) => {
      // Fetch the .docx file as a Blob
      const response = await fetch(url);
      const blob = await response.blob();

      // Use Mammoth.js to convert the .docx Blob to HTML
      mammoth
        .convertToHtml({ arrayBuffer: await blob.arrayBuffer() })
        .then((result) => {
          setDocHtml(result.value);
        })
        .catch((err) => {
          console.error("Error converting .docx to HTML:", err);
          setDocHtml("<p>Failed to load document.</p>");
        });
    };

    if (docxUrl) {
      loadDocx(docxUrl);
    }
  }, [docxUrl]); // Rerun the effect if the docxUrl changes

  return (
    <div
      className="docx-content"
      dangerouslySetInnerHTML={{ __html: docHtml }}
    ></div>
  );
};

export default DocxViewer;
