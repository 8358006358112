import React from "react";
import { Link } from "react-router-dom";
function NotFound(props) {
  return (
    <div className="App">
      <div className="content">
        <h1>Page Not Found</h1>
        <br />
        <h3>We're sorry, the page you requested could not be found.</h3>
        <h4>Please go back to the homepage</h4>
        <Link to="/">
          <button className="loginBtn">Go Home</button>
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
