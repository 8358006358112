export const Schema = {
  date_uploaded: "2008-11-01T18:03:03.833Z",
  date_compromised: "2008-10-31T00:52:54.000Z",
  stealer: "US[84A9A4763718C8066F757039D966D3C5] [2008-10-31T05_43_39.2782540]",
  stealer_family: "RedLine",
  ip: "1.3.3.7",
  computer_name: "Satoshi Nakamoto",
  operating_system: "Windows 7 Ultimate x32",
  malware_path:
    "C:\\Users\\Satoshi\\AppData\\Local\\Temp\\7zSC104A5D7\\625b7babdef04_Sun02dd5dfaa6df.exe",
  antiviruses: ["Windows Defender"],
  employeeAt: ["bitcoin.org"],
  clientAt: ["bitcointalk.org"],
  credentials: [
    {
      type: "employee",
      url: "https://mine.bitcoin.org/admin",
      domain: "bitcoin.org",
      username: "satoshi@bitcoin.org",
      password: "StopPrintingMoney2008",
    },
    {
      type: "client",
      url: "https://bitcointalk.org/index.php?action=login",
      domain: "bitcointalk.org",
      username: "satoshi@gmail.com",
      password: "StopPrintingMoney2008",
    },
  ],
  installed_software: [
    {
      program: "Bitcoin Core",
      version: "0.16.0",
    },
    {
      program: "Tor Browser",
      version: "1.2.0",
    },
  ],
};

export const creds = {
  credentials: [
    {
      type: "employee",
      url: "https://webmail.example.com/login.aspx",
      domain: "example.com",
      username: "admin@example.com",
      password: "ant@102030",
    },
  ],
};

export const client = {
  credentials: [
    {
      type: "client",
      url: "https://example.com/login",
      domain: "example.com",
      username: "johndoe@gmail.com",
      password: "oc300dwp",
    },
  ],
};

export const tree = {
  name: "BE[84B0415EF1910CC058EAE0039562018F] [2022-04-12T12_46_30.0143822-07_00]",
  type: "directory",
  children: [
    {
      name: "Steam",
      type: "directory",
      children: [
        {
          name: "coplay_76561198437305837.vdf",
          type: "file",
        },
        {
          name: "ssfn58921797684987530",
          type: "file",
        },
        {
          name: "DialogConfigOverlay_1920x1080.vdf",
          type: "file",
        },
        {
          name: "ssfn7004504802990646383",
          type: "file",
        },
        {
          name: "libraryfolders.vdf",
          type: "file",
        },
        {
          name: "config.vdf",
          type: "file",
        },
        {
          name: "DialogConfig.vdf",
          type: "file",
        },
        {
          name: "loginusers.vdf",
          type: "file",
        },
        {
          name: "DialogConfigOverlay_1680x1050.vdf",
          type: "file",
        },
      ],
    },
    {
      name: "Discord",
      type: "directory",
      children: [
        {
          name: "Tokens.txt",
          type: "file",
        },
      ],
    },
    {
      name: "DomainDetects.txt",
      type: "file",
    },
    {
      name: "Wallets",
      type: "directory",
      children: [
        {
          name: "Google_[Chrome]_Default_MaiarDeFiWallet",
          type: "directory",
          children: [
            {
              name: "000003.log",
              type: "file",
            },
            {
              name: "MANIFEST-000001",
              type: "file",
            },
            {
              name: "LOG",
              type: "file",
            },
            {
              name: "LOG.old",
              type: "file",
            },
            {
              name: "CURRENT",
              type: "file",
            },
          ],
        },
        {
          name: "Google_[Chrome]_Default_Phantom",
          type: "directory",
          children: [
            {
              name: "000018.ldb",
              type: "file",
            },
            {
              name: "000005.ldb",
              type: "file",
            },
            {
              name: "MANIFEST-000001",
              type: "file",
            },
            {
              name: "LOG",
              type: "file",
            },
            {
              name: "LOG.old",
              type: "file",
            },
            {
              name: "CURRENT",
              type: "file",
            },
            {
              name: "000016.log",
              type: "file",
            },
          ],
        },
        {
          name: "BraveSoftware_[Brave-Browser]_Default_Phantom",
          type: "directory",
          children: [
            {
              name: "000003.log",
              type: "file",
            },
            {
              name: "MANIFEST-000001",
              type: "file",
            },
            {
              name: "LOG",
              type: "file",
            },
            {
              name: "LOG.old",
              type: "file",
            },
            {
              name: "CURRENT",
              type: "file",
            },
          ],
        },
        {
          name: "Google_[Chrome]_Default_YoroiWallet",
          type: "directory",
          children: [
            {
              name: "000003.log",
              type: "file",
            },
            {
              name: "MANIFEST-000001",
              type: "file",
            },
            {
              name: "LOG",
              type: "file",
            },
            {
              name: "LOG.old",
              type: "file",
            },
            {
              name: "CURRENT",
              type: "file",
            },
          ],
        },
        {
          name: "Google_[Chrome]_Profile 12_Metamask",
          type: "directory",
          children: [
            {
              name: "000005.ldb",
              type: "file",
            },
            {
              name: "MANIFEST-000001",
              type: "file",
            },
            {
              name: "000028.ldb",
              type: "file",
            },
            {
              name: "LOG",
              type: "file",
            },
            {
              name: "000031.log",
              type: "file",
            },
            {
              name: "LOG.old",
              type: "file",
            },
            {
              name: "000030.ldb",
              type: "file",
            },
            {
              name: "CURRENT",
              type: "file",
            },
          ],
        },
        {
          name: "BraveSoftware_[Brave-Browser]_Default_Metamask",
          type: "directory",
          children: [
            {
              name: "000005.ldb",
              type: "file",
            },
            {
              name: "006421.ldb",
              type: "file",
            },
            {
              name: "006419.log",
              type: "file",
            },
            {
              name: "MANIFEST-000001",
              type: "file",
            },
            {
              name: "LOG",
              type: "file",
            },
            {
              name: "LOG.old",
              type: "file",
            },
            {
              name: "CURRENT",
              type: "file",
            },
          ],
        },
        {
          name: "Google_[Chrome]_Default_Metamask",
          type: "directory",
          children: [
            {
              name: "001312.ldb",
              type: "file",
            },
            {
              name: "001313.log",
              type: "file",
            },
            {
              name: "000005.ldb",
              type: "file",
            },
            {
              name: "001310.ldb",
              type: "file",
            },
            {
              name: "001314.ldb",
              type: "file",
            },
            {
              name: "MANIFEST-000001",
              type: "file",
            },
            {
              name: "LOG",
              type: "file",
            },
            {
              name: "LOG.old",
              type: "file",
            },
            {
              name: "001308.ldb",
              type: "file",
            },
            {
              name: "CURRENT",
              type: "file",
            },
          ],
        },
        {
          name: "Exodus",
          type: "directory",
          children: [
            {
              name: "market-history-cache.json",
              type: "file",
            },
            {
              name: "exodus.conf.json",
              type: "file",
            },
            {
              name: "exodus.wallet",
              type: "directory",
              children: [
                {
                  name: "seed.seco",
                  type: "file",
                },
                {
                  name: "info.seco",
                  type: "file",
                },
                {
                  name: "twofactor-secret.seco",
                  type: "file",
                },
                {
                  name: "twofactor.seco",
                  type: "file",
                },
              ],
            },
            {
              name: "window-state.json",
              type: "file",
            },
            {
              name: "announcements.json",
              type: "file",
            },
          ],
        },
      ],
    },
    {
      name: "InstalledSoftware.txt",
      type: "file",
    },
    {
      name: "FileGrabber",
      type: "directory",
      children: [
        {
          name: "Users",
          type: "directory",
          children: [
            {
              name: "augus",
              type: "directory",
              children: [
                {
                  name: "Desktop",
                  type: "directory",
                  children: [
                    {
                      name: "hack.txt",
                      type: "file",
                    },
                    {
                      name: "Amazon liste.txt",
                      type: "file",
                    },
                    {
                      name: "PC.txt",
                      type: "file",
                    },
                    {
                      name: "NFT.txt",
                      type: "file",
                    },
                    {
                      name: "planning avril mai.txt",
                      type: "file",
                    },
                    {
                      name: "comptes PrimeXBT.txt",
                      type: "file",
                    },
                  ],
                },
                {
                  name: "Documents",
                  type: "directory",
                  children: [
                    {
                      name: "2.docx",
                      type: "file",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Passwords.txt",
      type: "file",
    },
    {
      name: "UserInformation.txt",
      type: "file",
    },
    {
      name: "ImportantAutofills.txt",
      type: "file",
    },
    {
      name: "Cookies",
      type: "directory",
      children: [
        {
          name: "BraveSoftware_[Brave-Browser]_Profile 3 Network.txt",
          type: "file",
        },
        {
          name: "BraveSoftware_[Brave-Browser]_Profile 2 Network.txt",
          type: "file",
        },
        {
          name: "Google_[Chrome]_Profile 6 Network.txt",
          type: "file",
        },
        {
          name: "Google_[Chrome]_Profile 3 Network.txt",
          type: "file",
        },
        {
          name: "Google_[Chrome]_Profile 4 Network.txt",
          type: "file",
        },
        {
          name: "Google_[Chrome]_Profile 8 Network.txt",
          type: "file",
        },
        {
          name: "Google_[Chrome]_Profile 11 Network.txt",
          type: "file",
        },
        {
          name: "Google_[Chrome]_Profile 5 Network.txt",
          type: "file",
        },
        {
          name: "BraveSoftware_[Brave-Browser]_Profile 4 Network.txt",
          type: "file",
        },
        {
          name: "Google_[Chrome]_Profile 12 Network.txt",
          type: "file",
        },
        {
          name: "Google_[Chrome]_Profile 9 Network.txt",
          type: "file",
        },
        {
          name: "Google_[Chrome]_Default Network.txt",
          type: "file",
        },
        {
          name: "Battle.net_[BrowserCache]_Unknown.txt",
          type: "file",
        },
        {
          name: "Google_[Chrome]_Default Extension.txt",
          type: "file",
        },
        {
          name: "Microsoft_[Edge]_Default Network.txt",
          type: "file",
        },
        {
          name: "BraveSoftware_[Brave-Browser]_Default Network.txt",
          type: "file",
        },
        {
          name: "BraveSoftware_[Brave-Browser]_Profile 1 Network.txt",
          type: "file",
        },
        {
          name: "Steam_[htmlcache]_Unknown.txt",
          type: "file",
        },
        {
          name: "BraveSoftware_[Brave-Browser]_Default Extension.txt",
          type: "file",
        },
        {
          name: "Google_[Chrome]_Profile 1 Network.txt",
          type: "file",
        },
        {
          name: "Google_[Chrome]_Profile 7 Network.txt",
          type: "file",
        },
      ],
    },
    {
      name: "InstalledBrowsers.txt",
      type: "file",
    },
    {
      name: "Autofills",
      type: "directory",
      children: [
        {
          name: "Google_[Chrome]_Profile 4.txt",
          type: "file",
        },
        {
          name: "Microsoft_[Edge]_Default.txt",
          type: "file",
        },
        {
          name: "Google_[Chrome]_Default.txt",
          type: "file",
        },
        {
          name: "BraveSoftware_[Brave-Browser]_Profile 2.txt",
          type: "file",
        },
        {
          name: "Google_[Chrome]_Profile 7.txt",
          type: "file",
        },
        {
          name: "Google_[Chrome]_Profile 5.txt",
          type: "file",
        },
        {
          name: "Google_[Chrome]_Profile 12.txt",
          type: "file",
        },
        {
          name: "Google_[Chrome]_Profile 1.txt",
          type: "file",
        },
        {
          name: "Google_[Chrome]_Profile 6.txt",
          type: "file",
        },
        {
          name: "BraveSoftware_[Brave-Browser]_Default.txt",
          type: "file",
        },
        {
          name: "BraveSoftware_[Brave-Browser]_Profile 1.txt",
          type: "file",
        },
        {
          name: "Google_[Chrome]_Profile 3.txt",
          type: "file",
        },
        {
          name: "Google_[Chrome]_Profile 9.txt",
          type: "file",
        },
        {
          name: "Google_[Chrome]_Profile 8.txt",
          type: "file",
        },
        {
          name: "BraveSoftware_[Brave-Browser]_Profile 3.txt",
          type: "file",
        },
        {
          name: "BraveSoftware_[Brave-Browser]_Profile 4.txt",
          type: "file",
        },
      ],
    },
  ],
};
