import React from "react";
import { MdOutlineAdsClick } from "react-icons/md";
import LoaderIcon from "react-loader-icon";
import Filter from "./Filter";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Actions(props) {
  const { actions, actionsLoading } = props;
  return (
    <div style={{ marginTop: "20px" }}>
      {actionsLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "70vh",
          }}
        >
          <LoaderIcon type="spin" style={{ fill: "#FBCD4E" }} />
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          <div
            style={
              {
                // display: "grid",
                // gridTemplateColumns: "1fr 1fr 1fr",
                // gap: "1rem",
                // padding: "1rem",
              }
            }
          >
            <Filter
              placeholder="Filter by Action"
              onChange={props.onTimelineActionFilterChange}
              onSubmit={props.onTimelineFilter}
              value={props.actionFilter}
              clear={props.clearTimelineActionFilter}
            />
            <br />
            {actions && actions.length > 0 ? (
              <>
                {actions.map((item, index) => (
                  <div key={index}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        alignItems: "center",
                        textAlign: "center",
                        border: "1px solid",
                        padding: "1rem",
                        borderRadius: "8px",
                        backgroundColor: "#FBCD4E",
                        color: "black",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        props.getActionUsers(item.action, item.type)
                      }
                    >
                      <MdOutlineAdsClick size={40} />
                      <div
                        className="cyberFont bold"
                        style={{ margin: "0.5rem 0" }}
                      >
                        {item.action.toLocaleUpperCase()} -{" "}
                        {item.type.split("_").join(" ").toLocaleUpperCase()}
                      </div>
                      <div>{numberWithCommas(item.count)}</div>
                    </div>
                    {props.actionUsersLoading ===
                    `${item.action}_${item.type}` ? (
                      <div style={{ margin: "10rem auto" }}>
                        <LoaderIcon type="spin" style={{ fill: "#FBCD4E" }} />
                      </div>
                    ) : (
                      <div
                        style={{
                          backgroundColor: "#222831",
                          color: "#FBCD4E",
                          borderRadius: "20px",
                          margin: "10px",
                        }}
                      >
                        {props.actionUsers &&
                          props.actionUsers.length > 0 &&
                          `${props.actionUsers[0].action}_${props.actionUsers[0].type}` ===
                            `${item.action}_${item.type}` && (
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                                gap: "0.5rem",
                                padding: "1rem",
                                maxHeight: "300px",
                                overflowY: "scroll",
                                overflowX: "hidden",
                              }}
                            >
                              {props.actionUsers.map((item, index) => (
                                <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    textAlign: "center",
                                    border: "1px solid #ddd",
                                    padding: "1rem",
                                    borderRadius: "8px",
                                  }}
                                >
                                  <div
                                    className="cyberFont bold"
                                    style={{ marginBottom: "0.5rem" }}
                                  >
                                    {item.user}
                                  </div>
                                  <div>{numberWithCommas(item.count)}</div>
                                </div>
                              ))}
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                ))}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "70vh",
                }}
              >
                <h3>NO RESULTS</h3>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
