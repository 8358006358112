import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React, { useEffect, useState } from "react";
import { MdDelete, MdNotifications, MdNotificationsOff } from "react-icons/md";
import LoaderIcon from "react-loader-icon";
import cavaLogo from "../../images/thumbnail.jpeg";
import NewCompanyForm from "./NewCompanyForm";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const defaultSrc = (ev) => {
  ev.target.src = cavaLogo;
};

export default function Row(props) {
  const classes = useRowStyles();
  const [reDiscover, setRediscover] = useState(null);

  useEffect(() => {
    if (!props.open) {
      setRediscover(null);
    }
  }, [props.open]);

  return (
    <React.Fragment>
      <TableRow
        className={classes.root + " cursor"}
        onClick={() => {
          if (props.open === props.row._id) {
            props.setOpen(null);
            setRediscover(null);
          } else {
            props.setOpen(props.row._id);
          }
        }}
      >
        <TableCell
          style={{
            borderTop: props.open === props.row._id && "3px solid #fbcd4e",
            borderLeft: props.open === props.row._id && "3px solid #fbcd4e",
          }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              if (props.open === props.row._id) {
                props.setOpen(null);
                setRediscover(null);
              } else {
                props.setOpen(props.row._id);
              }
            }}
          >
            {props.open === props.row._id ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell
          style={{
            borderTop: props.open === props.row._id && "3px solid #fbcd4e",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <img
              alt="logo"
              onError={defaultSrc}
              src={
                props.row.logo
                  ? props.row.logo
                  : `https://logo.clearbit.com/${props.row.data.domains[0]}`
              }
              className="companyLogo"
            />
            <br />
            <span className="capitalize">{props.row.name}</span>
          </div>
        </TableCell>
        {Object.keys(props.row.data).map((key) => (
          <TableCell
            key={key}
            scope="row"
            align="left"
            style={{
              borderTop: props.open === props.row._id && "3px solid #fbcd4e",
            }}
          >
            {props.row.data[key].length}
          </TableCell>
        ))}
        <TableCell
          style={{
            borderTop: props.open === props.row._id && "3px solid #fbcd4e",
          }}
        >
          {props.alertLoading === props.row._id ? (
            <LoaderIcon className="tabsLoader smallLoader" type="spin" />
          ) : props.row.alerts ? (
            <Tooltip title="Email notifications for this company's domains are on.">
              <span>
                <MdNotifications
                  size={25}
                  onClick={(e) => {
                    e.stopPropagation();
                    props.toggleAlerts(props.row._id, false);
                  }}
                  style={{ fill: "#FDCD4E" }}
                />
              </span>
            </Tooltip>
          ) : (
            <Tooltip title="Email notifications for this company's domains are turned off.">
              <span>
                <MdNotificationsOff
                  size={25}
                  onClick={(e) => {
                    e.stopPropagation();
                    props.toggleAlerts(props.row._id, true);
                  }}
                />
              </span>
            </Tooltip>
          )}
        </TableCell>
        <TableCell
          style={{
            borderTop: props.open === props.row._id && "3px solid #fbcd4e",
          }}
        >
          <div className="grid-center">
            <Button
              fullWidth
              type="button"
              href={`/search-by-company/${props.row._id}`}
              variant="contained"
              className={"searchBtn desktopSearchBtn"}
              endIcon={<Icon>send</Icon>}
              onClick={(e) => e.stopPropagation()}
            >
              DISPLAY DATA
            </Button>
            <Button
              fullWidth
              variant="contained"
              className={"searchBtn desktopSearchBtn reDiscoverBtn"}
              type="button"
              endIcon={<Icon>refresh</Icon>}
              onClick={(e) => {
                if (props.open === props.row._id) {
                  e.stopPropagation();
                } else {
                  props.setOpen(props.row._id);
                }
                setRediscover(props.row);
              }}
            >
              {props.row.type === "gov"
                ? "RE-DISCOVER ENTITY"
                : "RE-DISCOVER COMPANY"}
            </Button>
          </div>
        </TableCell>
        <TableCell
          style={{
            borderTop: props.open === props.row._id && "3px solid #fbcd4e",
            borderRight: props.open === props.row._id && "3px solid #fbcd4e",
          }}
        >
          {props.deleteLoading === props.row._id ? (
            <LoaderIcon className="tabsLoader smallLoader" type="spin" />
          ) : (
            <MdDelete
              size={25}
              onClick={(e) => {
                e.stopPropagation();
                props.deleteCompany(props.row._id);
              }}
            />
          )}
        </TableCell>
      </TableRow>
      {props.open === props.row._id && (
        <TableRow>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              borderRight: "3px solid #fbcd4e",
              borderLeft: "3px solid #fbcd4e",
              borderBottom: "3px solid #fbcd4e",
            }}
            colSpan={8}
            className="whiteBg yellowBorder"
          >
            <Collapse
              in={props.open === props.row._id}
              timeout="auto"
              unmountOnExit
            >
              {props.data && (
                <>
                  <NewCompanyForm
                    type={props.row.type}
                    reDiscover={reDiscover}
                    mode="edit"
                    data={props.data()}
                    domains={props.domains}
                    apks={props.apks}
                    externals={props.externals}
                    addedDomains={props.domains
                      .concat(props.apks)
                      .concat(props.externals)}
                    companyName={props.companyName}
                    saveCompany={(companyName, companyData) =>
                      props.updateCompany(
                        companyName,
                        companyData,
                        props.row._id
                      )
                    }
                    user={props.user}
                    openRow={props.row}
                    loading={props.loading}
                    saved={props.saved}
                    saveError={props.saveError}
                    blacklistDomains={props.blacklistDomains}
                  />
                  {/* <NewCompanyData
                    mode="edit"
                    data={props.data()}
                    companyName={props.companyName}
                    setCompanyName={props.setCompanyName}
                    addedDomains={props.domains
                      .concat(props.apks)
                      .concat(props.externals)}
                    saveCompany={() =>
                      props.updateCompany(
                        props.companyName,
                        props.data(),
                        props.row._id
                      )
                    }
                    loading={props.loading}
                    saved={props.saved}
                    saveError={props.saveError}
                    blacklistDomains={props.blacklistDomains}
                  /> */}
                </>
              )}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}
