/* eslint-disable */

import React, { Component } from "react";
import CreateApiClient from "../API";
import Preset from "./Preset";
import Search from "./Search";

const api = CreateApiClient();

class Form extends Component {
  constructor(props) {
    super(props);
    this.onStealerChange = this.onStealerChange.bind(this);
    this.onChangeDomain = this.onChangeDomain.bind(this);
    this.onChangeDomainClients = this.onChangeDomainClients.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onIpChange = this.onIpChange.bind(this);

    this.state = {
      user: props.user,
      loading: false,
      domains: props.domains,
      email: props.email,
      role: props.role,
      type: "Employees",
      domain: "",
      stealer: "",
      searchedEmail: "",
      ip: "",
      data: [],
      results: "",
      token: props.token,
      // selectDomain: props.domains[0],
      employees: true,
      gmail: true,
      arrows: false,
      selectors: ["User", "Customer"],
      searchers: ["Admin", "MSSP"],
      suggestions: [],
      companies: [],
      machines: "",
      employeesCount: "",
      hasAccess: false,
      activeDomains: [],
    };
  }

  config = {
    headers: {
      "auth-token": this.props.token,
      "refresh-token": this.props.refreshToken,
    },
  };

  componentDidMount() {
    this.getPresets();
  }

  getPresets = async () => {
    const role = this.state.role;
    const searchers = this.state.searchers;
    searchers.includes(role)
      ? this.setState({ hasAccess: true })
      : this.setState({ hasAccess: false });
    this.setState({ loading: true });
    // if (role === "Admin" || role === "MSSP" || role === "master") {
    //   const companiesData = await api.fetchData("companies", {}, this.config);
    //   this.setState({ companies: companiesData });
    // }
    this.setState({ loading: false });
  };

  submitFromCard = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const clickedDomain = e.target.value;
    this.setState({ domain: clickedDomain });
    let query = {
      domain: clickedDomain,
    };
    const response = await api.fetchData("employees", query, this.config);
    this.dataValidation(response);
    this.setState({ arrows: true });
  };

  onChangeDomain(e) {
    const response = api.fetchSuggestions(e.target.value.toLowerCase());
    response.then((result) => {
      this.setState({ suggestions: result });
    });
    this.setState({
      domain: e.target.value.toLowerCase(),
    });
  }

  onStealerChange(e) {
    this.setState({
      stealer: e.target.value,
    });
  }

  onEmailChange(e) {
    this.setState({
      searchedEmail: e.target.value,
    });
  }

  onIpChange(e) {
    this.setState({
      ip: e.target.value,
    });
  }

  onChangeDomainClients(e) {
    if (e.target.value !== undefined) {
      if (e.target.value !== 0) {
        this.setState({
          domain: e.target.value.toLowerCase(),
        });
      } else if (e.target.innerText !== undefined) {
        this.setState({
          domain: e.target.innerText.toLowerCase(),
        });
      } else {
        this.setState({
          domain: null,
        });
      }
    } else {
      this.setState({
        domain: null,
      });
    }
  }

  selectMultipleDomains = (e) => {
    let selectedDomains = this.state.activeDomains;
    let checkedDomain;
    if (e.target.value !== undefined) {
      if (e.target.value !== 0) {
        checkedDomain = e.target.value.toLowerCase();
        if (checkedDomain === "all") {
          this.state.activeDomains && this.state.activeDomains.length > 0
            ? this.setState({ activeDomains: [] })
            : this.setState({ activeDomains: this.state.domains });
        } else {
          let index = selectedDomains.indexOf(checkedDomain);
          if (index > -1) {
            selectedDomains.splice(index, 1);
          } else {
            selectedDomains.push(checkedDomain);
          }
          this.setState({ activeDomains: selectedDomains });
        }
      } else if (e.target.innerText !== undefined) {
        if (e.target.innerText.toLowerCase() === "all") {
          this.state.activeDomains && this.state.activeDomains.length > 0
            ? this.setState({ activeDomains: [] })
            : this.setState({ activeDomains: this.state.domains });
        }
      } else {
        // this.setState({
        //   domain: null,
        // });
      }
    } else if (e.target.id !== undefined) {
      if (e.target.id.toLowerCase() === "all") {
        this.state.activeDomains && this.state.activeDomains.length > 0
          ? this.setState({ activeDomains: [] })
          : this.setState({ activeDomains: this.state.domains });
      }
    }
  };

  dataValidation = (dataFound) => {
    dataFound.length > 0
      ? this.setState({ data: dataFound })
      : this.setState({ data: [] });
    this.setState({ results: "Nothing Found" });
    this.setState({ loading: false });
  };

  handleSubjectSelect = (e) => {
    if (e.target.value.toString() === "Employees") {
      this.setState({ employees: true });
      this.setState({ type: "Employees" });
    } else if (e.target.value.toString() === "Clients") {
      this.setState({ employees: false });
      this.setState({ type: "Clients" });
    } else {
      this.setState({ employees: true });
    }
  };

  domainPicked = (e) => {
    e.preventDefault();
    const pickedDomain = e.target.attributes[0].nodeValue;
    this.setState({ domain: pickedDomain });
    this.setState({ suggestions: [] });
  };

  allDomains = () => {
    const options = [];
    this.props.domains.map((domain) => {
      options.push({ label: domain, value: domain });
    });
    return options;
  };

  render() {
    const role = this.state.role;
    return (
      <div className="mainDiv">
        {role === "MSSP" || role === "Admin" || role === "master" ? (
          <div
            className="form domainForm newDomainForm"
            style={{ top: role === "MSSP" && "50px" }}
          >
            <Search
              selectedDomain={this.state.domain}
              machines={this.state.machines}
              employeesCount={this.state.employeesCount}
              value={this.state.domain}
              stealer={this.state.stealer}
              onStealerChange={this.onStealerChange}
              email={this.state.searchedEmail}
              onEmailChange={this.onEmailChange}
              ip={this.state.ip}
              onIpChange={this.onIpChange}
              onChange={this.onChangeDomain}
              role={role}
              suggestions={this.state.suggestions}
              pickDomain={this.domainPicked}
              loader={this.state.loading}
              activeDomain={this.props.activeDomain}
              user={this.props.user}
            />
          </div>
        ) : (
          <div className="form domainForm">
            <Preset
              token={this.state.token}
              type={this.state.type}
              selectedDomain={this.state.domain}
              machines={this.state.machines}
              employeesCount={this.state.employeesCount}
              handleSubjectSelect={this.handleSubjectSelect}
              value={this.state.domain}
              onChange={this.selectMultipleDomains}
              role={role}
              suggestions={this.state.suggestions}
              loader={this.state.loading}
              domains={this.state.domains}
              activeDomains={this.state.activeDomains}
              defaultValue={this.allDomains}
              allDomains={this.props.domains}
            />
          </div>
        )}
      </div>
    );
  }
}

export default Form;
