import React, { useEffect, useState } from "react";
import LoaderIcon from "react-loader-icon";
import CreateApiClient from "../../API";
import loadingLogo from "../../images/hrlogo_new.png";
import logo from "../../images/logo-1.png";
import ErrorBoundary from "../Error";
import DocsMenu from "../content/ApiReference";
import Drawer from "../partials/Drawer";

const api = CreateApiClient();

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function Docs(props) {
  const [totalStealers, setTotalStealers] = useState(null);
  const [samples, setSamples] = useState({});

  const getTotalStealers = async () => {
    const response = await api.getData("samples");
    const counts = numberWithCommas(response?.count?.total_machines);
    setSamples(response);
    setTotalStealers(counts);
  };

  useEffect(() => {
    getTotalStealers();
  }, []);

  return (
    <ErrorBoundary>
      <div className="App docs-page">
        {totalStealers ? (
          <Drawer
            title="Cavalier API"
            menu={DocsMenu(totalStealers)}
            img={logo}
            samples={samples}
          />
        ) : (
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "block" }}>
              <LoaderIcon type="spin" color="white" />
              <br />
              <img src={loadingLogo} width="300" />
            </div>
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
}

export default Docs;
