/* eslint-disable */

import axios from "axios";
import React, { useEffect, useState } from "react";
import LoaderIcon from "react-loader-icon";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { authAddress } from "../../Config";
import AppBarNew from "../AppBar";

const queryString = require("query-string");

export default function Slack(props) {
  const location = useLocation();
  const code = queryString.parse(location.search).code;
  const [accessToken, setAccessToken] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAccessToken(code);
  }, []);

  const getAccessToken = async (_code) => {
    await axios
      .post(
        `${authAddress}/slack-access-token?code=${code}`,
        {},
        { headers: { "auth-token": props.token } }
      )
      .then((res) => {
        if (res.data) {
          if (res.data.ok && res.data.ok !== false) {
            setAccessToken(res.data.access_token);
          } else {
            setError(res.data.error);
          }
        } else {
          setError("Access Denied");
        }
      })
      .catch((err) => setError("Access Denied"));
    setLoading(false);
  };

  return (
    <div className="App">
      <AppBarNew
        className="mobileHeader"
        user={props.user}
        role={props.role}
        token={props.token}
        email={props.email}
        domains={props.domains}
        handleLogOut={props.handleHomeLogOut}
      />
      <div style={{ width: "50%", margin: "10rem auto" }}>
        <div className="styledBox yellow-bg lightText uppercase">
          {accessToken ? (
            <>
              <h2>Webhook has been successfully configured</h2>
              <Link to="/my-account">
                <button
                  style={{
                    backgroundColor: "#222831",
                    borderRadius: "5px",
                    color: "#FBCD4E",
                    padding: "8px 16px",
                    border: "0px",
                  }}
                >
                  Back to my account
                </button>
              </Link>
            </>
          ) : error ? (
            <>
              <h2>{error}</h2>
              <br />
              <h3>Please try again</h3>
            </>
          ) : (
            loading && (
              <>
                <h2>Configuring Webhook...</h2>
                <br />
                <LoaderIcon type="spin" />
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
}
