import Button from "@material-ui/core/Button";
import React from "react";
import { FaSadTear } from "react-icons/fa";
import FeedbackModal from "../partials/FeedbackModal";

export default function Error(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      style={{
        color: "#FFA500",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "95vh",
        width: "100%",
      }}
    >
      <div style={{ display: "grid", placeItems: "center", width: "100%" }}>
        <FaSadTear size={160} />
        <br />
        <h5>{props.msg}</h5>
        <br />
        <h5>Please report this issue here:</h5>
        <Button
          onClick={() => handleClickOpen()}
          className=""
          style={{ backgroundColor: "#FFA500", width: "200px" }}
        >
          Report
        </Button>
        {open && (
          <FeedbackModal
            page={window.location}
            user={props.user}
            open={open}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
          />
        )}
      </div>
    </div>
  );
}
