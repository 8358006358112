/* eslint-disable */

import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { DiAndroid } from "react-icons/di";
import {
  FaCookieBite,
  FaGlobe,
  FaHashtag,
  FaMapMarkedAlt,
  FaMapMarkerAlt,
  FaUserTie,
  FaUsers,
} from "react-icons/fa";
import { MdFingerprint, MdOutlineTimeline, MdPassword } from "react-icons/md";
import { RiComputerLine } from "react-icons/ri";
import LoaderIcon from "react-loader-icon";
import CreateApiClient from "../../API";
import "../../css/cyberFont.css";
import logo from "../../images/hrlogo_new.png";
import thumbnail from "../../images/thumbnail.jpeg";
import StackedBar from "../StackedBar";
import Footer from "../partials/Footer";
import SearchForm from "../partials/SearchForm";

const api = CreateApiClient();

const domainRegex = /^[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+[a-zA-Z0-9]$/;

const validateDomain = (domain) => {
  if (domainRegex.test(domain) && !domain.includes("www.")) {
    return true;
  } else {
    return false;
  }
};

export default function PasswordsDay(props) {
  const [loading, setLoading] = useState(false);
  const [stealersCount, setStealersCount] = useState("");
  const [employeesCount, setEmployeesCount] = useState("");
  const [androidsCount, setAndroidsCount] = useState("");
  const [domainsCount, setDomainsCount] = useState("");
  const [countriesCount, setCountriesCount] = useState("");

  const [domain, setDomain] = useState("");
  const [domainInput, setDomainInput] = useState("");

  const [domainData, setDomainData] = useState([]);
  const [domainLoading, setDomainLoading] = useState(false);

  const [userUrls, setUserUrls] = useState([]);
  const [empUrls, setEmpUrls] = useState([]);
  const [empExpand, setEmpExpand] = useState(null);
  const [userExpand, setUserExpand] = useState(null);
  const [userLoading, setUserLoading] = useState(null);
  const [empLoading, setEmpLoading] = useState(null);

  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(null);

  const defaultSrc = (ev) => {
    ev.target.src = thumbnail;
  };

  useEffect(() => {
    getCounters();
  }, []);

  async function getCounters() {
    setLoading(true);
    let response;
    response = await api.getFilters("counts");

    response && setLoading(false);
    setStealersCount(response.stealers);
    setEmployeesCount(response.employees);
    setAndroidsCount(response.androids);
    setDomainsCount(response.domains);
    setCountriesCount(response.countries);
  }

  const counters = [
    {
      name: (
        <h2>
          Compromised <br /> Machines
        </h2>
      ),
      text: "Timeline",
      icon: <RiComputerLine size={30} className="counterIcon" />,
      count: Number(stealersCount),
      onNav: true,
      id: "section-one",
      customIcon: <MdOutlineTimeline size={30} className="counterIcon" />,
    },
    {
      name: (
        <h2>
          Compromised <br /> Employees
        </h2>
      ),
      text: "Compromised Employees",
      icon: <FaUserTie size={30} className="counterIcon" />,
      count: Number(employeesCount),
    },
    {
      name: (
        <h2>
          Compromised <br /> Domains
        </h2>
      ),
      text: "Domain Search",
      icon: <FaGlobe size={30} className="counterIcon" />,
      count: domainsCount,
      onNav: true,
      id: "section-two",
    },
    {
      name: (
        <h2>
          Compromised <br /> Androids
        </h2>
      ),
      text: "APK Search",
      icon: <DiAndroid size={30} className="counterIcon" />,
      count: Number(androidsCount),
      onNav: true,
      id: "section-three",
    },
    {
      name: (
        <h2>
          Different <br /> Countries
        </h2>
      ),
      text: "Infections Map",
      icon: <FaMapMarkerAlt size={30} className="counterIcon" />,
      count: Number(countriesCount),
      onNav: true,
      customIcon: <FaMapMarkedAlt size={30} className="counterIcon" />,
      id: "section-four",
    },
    {
      name: (
        <h2>
          Compromised <br /> Applications
        </h2>
      ),
      text: "Compromised Applications",
      icon: <FaHashtag size={30} className="counterIcon" />,
      count: 20,
      suffix: "M+",
    },
    {
      name: (
        <h2>
          Compromised <br /> Passwords
        </h2>
      ),
      text: "Digital Footprint",
      icon: <MdPassword size={30} className="counterIcon" />,
      count: 500,
      suffix: "M+",
      onNav: true,
      id: "section-five",
      customIcon: <MdFingerprint size={30} className="counterIcon" />,
    },
    {
      name: (
        <h2>
          Compromised <br /> Cookies
        </h2>
      ),
      text: "Compromised Cookies",
      icon: <FaCookieBite size={30} className="counterIcon" />,
      count: 25,
      suffix: "B+",
    },
  ];

  const re =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

  const isValidEmail = (email) => {
    return re.test(email);
  };

  const handleEmailChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const submitEmail = async (e) => {
    e && e.preventDefault();
    if (isValidEmail(email)) {
      const response = await api.sendEmail(
        `send/lead?email=${email}&page=happy-world-password-day`
      );
      setSent(response);
      setEmail("");
    } else {
      setSent("Invalid Email");
    }
  };

  const handleDomainChange = (e) => {
    e.preventDefault();
    setDomainInput(e.target.value);
  };

  const submitDomain = async (e) => {
    e && e.preventDefault();
    const validDomain = validateDomain(domainInput);
    if (!validDomain) {
      alert("Please insert a valid domain (no https or www)");
    } else {
      setDomain(domainInput);
      setDomainData([]);
      setDomainLoading(true);
      let url = `stats/passwords/passwords-day`;

      const response = await api.fetchData(url, {
        domains: [domainInput],
      });
      if (response && response.totalStealers) {
        console.log(response);
        setDomainData(response);
      } else {
        setDomainData("No Data Found");
      }
      setDomainLoading(false);
    }
  };

  const getUrlsStrength = async (domain, type) => {
    type === "employee"
      ? setEmpExpand(`${domain}_${type}`)
      : setUserExpand(`${domain}_${type}`);
    type === "employee"
      ? setEmpLoading(`${domain}_${type}`)
      : setUserLoading(`${domain}_${type}`);

    const response = await api.fetchData(
      `stats/passwords/urls-strength?domain=${domain}&type=${type}&limit=1000`,
      {}
    );
    type === "employee" ? setEmpUrls(response) : setUserUrls(response);
    type === "employee" ? setEmpLoading(null) : setUserLoading(null);
  };

  return (
    <>
      <div style={{ textAlign: "center", minHeight: "100vh" }}>
        <div
          style={{
            position: "relative",
            top: "2rem",
          }}
        >
          <img src={logo} width="300" alt="logo" />
        </div>
        <div
          className="desktop"
          id="generalCounters"
          style={{ textAlign: "center" }}
        >
          <>
            <div
              className="counters"
              style={{
                backgroundColor: "#fecd4e",
                color: "black",
                borderRadius: "20px",
                justifyContent: "center",
                width: "90%",
                padding: "0.8rem 0rem 0rem",
              }}
            >
              {counters.map((counter, index) => (
                <div
                  key={index}
                  className="counter newCounter"
                  style={{ width: "12%" }}
                >
                  {counter.icon}
                  <CountUp
                    separator=","
                    start={0}
                    end={counter.count}
                    duration={2.75}
                    suffix={counter.suffix}
                  />
                  {counter.name}
                </div>
              ))}
            </div>
          </>
        </div>
        <div
          style={{
            // minHeight: "100vh",
            textAlign: "center",
            color: "white",
            width: "90%",
            margin: "0rem auto",
          }}
        >
          <div
            id="wpdDomainForm"
            style={{
              width: "100%",
              margin: "auto",
              backgroundColor: "#222831",
              borderRadius: "20px",
              padding: "2rem",
            }}
          >
            <FaGlobe
              size={35}
              style={{
                fill: "#FDCD4E",
              }}
            />
            <h3 style={{ color: "#FDCD4E" }}>Search Any Domain</h3>
            <SearchForm
              onChange={handleDomainChange}
              onSubmit={(e) => submitDomain(e)}
              value={domainInput}
              placeholder="Enter domain"
              formStyle={{
                backgroundColor: "#E7F0FE",
                color: "#222831",
                borderColor: "#222831",
                margin: "0px 2px",
                borderRadius: "50px 0px 0px 50px",
                width: "25%",
              }}
              btnStyle={{
                backgroundColor: "#FDCD4E",
                color: "#222831",
                borderRadius: "0px 50px 50px 0px",
              }}
              name="domain"
            />
            <div>
              {domainLoading ? (
                <>
                  <br />
                  <br />
                  <LoaderIcon type="spin" style={{ fill: "white" }} />
                </>
              ) : domainData &&
                domainData.totalStealers &&
                domainData.totalStealers > 0 ? (
                <>
                  <img
                    alt="logo"
                    src={
                      domainData.logo
                        ? domainData.logo
                        : `https://logo.clearbit.com/${domain}`
                    }
                    style={{
                      width: "60px",
                      borderRadius: "8px",
                      margin: "10px auto",
                    }}
                    onError={defaultSrc}
                  />
                  <br />
                  <div
                    id="wpdDomainCounters"
                    style={{
                      display: "inline-flex",
                      width: "35%",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#FECD4E",
                        color: "#222831",
                        padding: "10px",
                        borderRadius: "20px",
                        width: "100%",
                        margin: "2px",
                      }}
                    >
                      <FaUserTie
                        size={40}
                        className="counterIcon"
                        style={{ marginBottom: "4px" }}
                      />
                      <CountUp
                        separator=","
                        start={0}
                        end={domainData.employees}
                        duration={2.75}
                      />
                      <br /> Compromised <br /> Employees
                    </div>
                    <div
                      style={{
                        backgroundColor: "#FECD4E",
                        color: "#222831",
                        padding: "10px",
                        borderRadius: "20px",
                        width: "100%",
                        margin: "2px",
                      }}
                    >
                      <FaUsers
                        size={40}
                        className="counterIcon"
                        style={{ marginBottom: "4px" }}
                      />
                      <CountUp
                        separator=","
                        start={0}
                        end={domainData.users}
                        duration={2.75}
                      />
                      <br /> Compromised <br /> Users
                    </div>
                  </div>
                </>
              ) : (
                <p className="error">{domainData}</p>
              )}
            </div>
            {domainData &&
            domainData.totalStealers &&
            domainData.totalStealers > 0 ? (
              <div
                className="desktopFlex"
                style={{
                  padingBottom: "1rem",
                  // minHeight: "100vh",
                  overflowY: "scroll",
                  width: "100%",
                  marginTop: "1rem",
                  // maxHeight: "600px",
                  // border: "2px solid #fecd4e",
                }}
              >
                {domainData.employee_passwords &&
                domainData.employee_passwords.totalPass > 0 ? (
                  <div
                    className="mobile100"
                    style={{
                      width: "50%",
                    }}
                  >
                    <div className="x-axis">
                      <h2 style={{ color: "#FECD4E" }}>
                        Employee Password Strength
                      </h2>
                      <ul className="legend darkText">
                        <Tooltip title="Password with a length of less than 6 characters and only 1 type of character (lowercase, uppercase, numbers and symbols).">
                          <li>Too Weak</li>
                        </Tooltip>
                        <Tooltip title="Password with a length of 6-8 characters and a diversity of 2-3 types of characters (lowercase, uppercase, numbers and symbols).">
                          <li>Weak</li>
                        </Tooltip>
                        <Tooltip title="Password with a length of 8-10 characters and a diversity of 4 types of characters (lowercase, uppercase, numbers and symbols).">
                          <li>Medium</li>
                        </Tooltip>
                        <Tooltip title="Password that is at least 10 characters long and has a diversity of 4 types of characters (lowercase, uppercase, numbers and symbols).">
                          <li>Strong</li>
                        </Tooltip>
                      </ul>
                    </div>
                    {domainData.employee_passwords.totalPass > 0 ? (
                      <div>
                        <StackedBar
                          logo={domainData.logo}
                          block="domains"
                          customMargin={
                            domainData.user_passwords.totalPass
                              ? "8px"
                              : "8px auto"
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            if (empExpand === `${domain}_employee`) {
                              setEmpExpand(null);
                            } else {
                              getUrlsStrength(domain, "employee");
                            }
                          }}
                          color="#222831"
                          bg="#FECD4E"
                          type="Employees"
                          domain={domain}
                          arr={domainData.employee_passwords}
                          typeFilter="employee"
                          getUrlsStrength={() => {
                            if (empExpand === `${domain}_employee`) {
                              setEmpExpand(null);
                            } else {
                              getUrlsStrength(domain, "employee");
                            }
                          }}
                          expandBtn={
                            <Button
                              className=""
                              onClick={(e) => {
                                e.stopPropagation();
                                if (empExpand === `${domain}_employee`) {
                                  setEmpExpand(null);
                                } else {
                                  getUrlsStrength(domain, "employee");
                                }
                              }}
                            >
                              {empExpand === `${domain}_employee`
                                ? "Close ↑"
                                : "Expand ↓"}
                            </Button>
                          }
                          expand={empExpand}
                          setExpand={setEmpExpand}
                          child={
                            empExpand === `${domain}_employee` && (
                              <div
                                className="scrollBox"
                                style={{
                                  borderRadius: "20px",
                                  // width: "95%",
                                  margin: "auto",
                                  backgroundColor: "#222831",
                                }}
                              >
                                {empLoading === `${domain}_employee` && (
                                  <LoaderIcon
                                    type="spin"
                                    style={{
                                      fill: "white",
                                      width: "100px",
                                      height: "auto",
                                      position: "relative",
                                      top: "5rem",
                                    }}
                                  />
                                )}
                                {!empLoading && (
                                  <>
                                    {empUrls.map(
                                      (item, index) =>
                                        item.employees.totalPass > 0 && (
                                          <StackedBar
                                            logo={domainData.logo}
                                            color="#FECD4E"
                                            key={index}
                                            bg="#1A1C20"
                                            user={props.user}
                                            type="Employees"
                                            domain={item.domain}
                                            arr={item.employees}
                                            typeFilter="employee"
                                          />
                                        )
                                    )}
                                  </>
                                )}
                              </div>
                            )
                          }
                        />
                      </div>
                    ) : props.passwordsLoading ? (
                      <>
                        <LoaderIcon type="spin" style={{ fill: "white" }} />
                        <br />
                        Loading Password Strength Data...
                      </>
                    ) : (
                      <h2 className="lightText">No data found</h2>
                    )}
                  </div>
                ) : (
                  !domainLoading && (
                    <div
                      style={{
                        width: "50%",
                        height: "200px",
                        display: "inline-flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h2>No Employee Passwords Statistics</h2>
                    </div>
                  )
                )}
                {domainData.user_passwords &&
                domainData.user_passwords.totalPass > 0 ? (
                  <div
                    className="mobile100"
                    style={{
                      width: "50%",
                    }}
                  >
                    <div className="x-axis">
                      <h2 style={{ color: "#fecd4e" }}>
                        User Password Strength
                      </h2>
                      <ul className="legend darkText">
                        <Tooltip title="Password with a length of less than 6 characters and only 1 type of character (lowercase, uppercase, numbers and symbols).">
                          <li>Too Weak</li>
                        </Tooltip>
                        <Tooltip title="Password with a length of 6-8 characters and a diversity of 2-3 types of characters (lowercase, uppercase, numbers and symbols).">
                          <li>Weak</li>
                        </Tooltip>
                        <Tooltip title="Password with a length of 8-10 characters and a diversity of 4 types of characters (lowercase, uppercase, numbers and symbols).">
                          <li>Medium</li>
                        </Tooltip>
                        <Tooltip title="Password that is at least 10 characters long and has a diversity of 4 types of characters (lowercase, uppercase, numbers and symbols).">
                          <li>Strong</li>
                        </Tooltip>
                      </ul>
                    </div>
                    {domainData.user_passwords.totalPass > 0 ? (
                      <div>
                        <StackedBar
                          logo={domainData.logo}
                          block="domains"
                          customMargin={
                            domainData.employee_passwords.totalPass
                              ? "8px"
                              : "8px auto"
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            if (userExpand === `${domain}_client`) {
                              setUserExpand(null);
                            } else {
                              getUrlsStrength(domain, "client");
                            }
                          }}
                          bg="#fecd4e"
                          color="#222831"
                          type="Users"
                          domain={domain}
                          arr={domainData.user_passwords}
                          typeFilter="client"
                          getUrlsStrength={() => {
                            if (userExpand === `${domain}_client`) {
                              setUserExpand(null);
                            } else {
                              getUrlsStrength(domain, "client");
                            }
                          }}
                          setExpand={setUserExpand}
                          expand={userExpand}
                          expandBtn={
                            <Button
                              className=""
                              onClick={(e) => {
                                e.stopPropagation();
                                if (userExpand === `${domain}_client`) {
                                  setUserExpand(null);
                                } else {
                                  getUrlsStrength(domain, "client");
                                }
                              }}
                            >
                              {userExpand === `${domain}_client`
                                ? "Close ↑"
                                : "Expand ↓"}
                            </Button>
                          }
                          child={
                            userExpand === `${domain}_client` && (
                              <div
                                className="scrollBox"
                                style={{
                                  borderRadius: "20px",
                                  // width: "95%",
                                  margin: "auto",
                                  backgroundColor: "#222831",
                                }}
                              >
                                {userLoading === `${domain}_client` && (
                                  <LoaderIcon
                                    type="spin"
                                    style={{
                                      fill: "white",
                                      width: "100px",
                                      height: "auto",
                                      position: "relative",
                                      top: "5rem",
                                    }}
                                  />
                                )}
                                {!userLoading && (
                                  <>
                                    {userUrls.map(
                                      (item, index) =>
                                        item.users.totalPass > 0 && (
                                          <StackedBar
                                            logo={domainData.logo}
                                            bg="#1A1C20"
                                            key={index}
                                            color="#FCCE4C"
                                            user={props.user}
                                            type="Users"
                                            domain={item.domain}
                                            arr={item.users}
                                            typeFilter="client"
                                          />
                                        )
                                    )}
                                  </>
                                )}
                              </div>
                            )
                          }
                        />
                      </div>
                    ) : props.passwordsLoading ? (
                      <>
                        <LoaderIcon type="spin" style={{ fill: "white" }} />
                        <br />
                        Loading Password Strength Data...
                      </>
                    ) : (
                      <h2 className="lightText">No data found</h2>
                    )}
                  </div>
                ) : (
                  !domainLoading && (
                    <div
                      style={{
                        width: "50%",
                        height: "200px",
                        display: "inline-flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h2>No User Passwords Statistics</h2>
                    </div>
                  )
                )}
              </div>
            ) : (
              <div></div>
            )}
            {domainData &&
              domainData !== "No Data Found" &&
              (domainData.employee_passwords || domainData.user_passwords) && (
                <div id="wpdText">
                  <p className="lightText">
                    *By analysing data of compromised accounts from over
                    12,000,000 computers infected by info-stealers worldwide,
                    the tool can determine which services follow password
                    complexity standards and which ones need improvement.
                  </p>
                </div>
              )}
          </div>
        </div>
        {domainData &&
          domainData.totalStealers &&
          domainData.totalStealers > 0 && (
            <div
              id="wpdContact"
              style={{
                backgroundColor: "#fecd4e",
                width: "90%",
                margin: "0.5rem auto 6rem",
                borderRadius: "20px",
                padding: "2rem",
              }}
            >
              <h2 className="lightText">
                Talk to us to learn about your company’s cybercrime risks
              </h2>
              <SearchForm
                onChange={handleEmailChange}
                onSubmit={(e) => submitEmail(e)}
                value={email}
                placeholder="Enter your email"
                formStyle={{
                  backgroundColor: "#E7F0FE",
                  color: "#222831",
                  borderColor: "#222831",
                  margin: "0px 2px",
                  borderRadius: "50px 0px 0px 50px",
                  width: "25%",
                }}
                btnStyle={{
                  backgroundColor: "#222831",
                  color: "#FECD4E",
                  borderRadius: "0px 50px 50px 0px",
                }}
                customBtnTxt="Submit"
                bottomTxt={sent}
                name="email"
              />
              {sent && sent}
            </div>
          )}
      </div>
      <Footer />
    </>
  );
}
