import React from "react";
import {
  BsShieldFillCheck,
  BsShieldFillExclamation,
  BsShieldSlashFill,
} from "react-icons/bs";
import { FaUserShield } from "react-icons/fa";
import LoaderIcon from "react-loader-icon";
import thumbnail from "../../images/thumbnail.jpeg";

export default function AntiVirus(props) {
  const defaultSrc = (ev) => {
    ev.target.src = thumbnail;
  };

  const addFreeLabel = (name) => {
    const free_list = [
      "Windows Defender",
      "Avast Antivirus",
      "McAfee VirusScan",
      "McAfee Firewall",
      "Malwarebytes",
      "AVG Antivirus",
    ];

    if (free_list.includes(name)) {
      return `${name} (FREE)`;
    } else {
      return name;
    }
  };
  return (
    <>
      <h1 className="chartTitle">{props.typeLabel} Anti-Virus Installs</h1>
      <br />
      {props.data &&
      props.data[0] &&
      props.data.find(
        (s) =>
          s && s.antiviruses_stats && s.antiviruses_stats.employees.total > 0
      ) ? (
        <div
          id="antiVirusesBox"
          style={{
            margin: "auto",
            // backgroundColor: "#222831",
            padding: "10px",
          }}
          // className="styledBox"
        >
          {props.data.map(
            (item, index) =>
              ((item &&
                item.antiviruses_stats &&
                item.antiviruses_stats[`${props.type}`].found > 0) ||
                item.antiviruses_stats[`${props.type}`].not_found > 0 ||
                item.antiviruses_stats[`${props.type}`].free > 0) && (
                <div
                  key={index}
                  style={{
                    display: "inline-flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "5px 0px",
                    borderRadius: "20px",
                    backgroundColor: "#1A1C20",
                    padding: "15px",
                  }}
                >
                  <div style={{ display: "block", textAlign: "center" }}>
                    <img
                      id="logoImg"
                      style={{
                        backgroundColor: "#1A1C20",
                        border: "0px",
                        width: "50px",
                        height: "50px",
                        borderRadius: "50px",
                        marginRight: "5px",
                      }}
                      src={`https://logo.clearbit.com/${item.job_company_website}`}
                      onError={defaultSrc}
                      alt="logo"
                    />
                    <p
                      style={{
                        marginBottom: "0px",
                        marginTop: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {item.job_company_website.toUpperCase()}
                    </p>
                  </div>
                  <div className="skills">
                    <div className="charts">
                      <div className="chart--dev">
                        <ul
                          className="chart--horiz"
                          style={{ margin: "0px", listStyle: "none" }}
                        >
                          <li
                            className="chart__bar firstBar"
                            style={{
                              width: "22rem",
                              // width: `${
                              //   400 -
                              //   item.antiviruses_stats.employees.not_found -
                              //   item.antiviruses_stats.employees.free
                              // }px`,
                              margin: "5px",
                            }}
                          >
                            <span className="chart__label">
                              <BsShieldFillCheck
                                style={{ marginRight: "5px" }}
                              />
                              {item.antiviruses_stats[`${props.type}`].found}%
                              Premium Anti-Virus
                            </span>
                          </li>
                          <li
                            className="chart__bar secondBar"
                            style={{
                              width: "22rem",
                              // width: `${
                              //   400 -
                              //   item.antiviruses_stats.employees.found -
                              //   item.antiviruses_stats.employees.not_found
                              // }px`,
                              margin: "5px",
                            }}
                          >
                            <span className="chart__label">
                              <BsShieldFillExclamation
                                style={{ marginRight: "5px" }}
                              />
                              {item.antiviruses_stats[`${props.type}`].free}%
                              Free Anti-Virus
                            </span>
                          </li>
                          <li
                            className="chart__bar thirdBar"
                            style={{
                              width: "22rem",
                              // width: `${
                              //   400 -
                              //   item.antiviruses_stats.employees.found -
                              //   item.antiviruses_stats.employees.free
                              // }px`,
                              margin: "5px",
                            }}
                          >
                            <span className="chart__label">
                              <BsShieldSlashFill
                                style={{ marginRight: "5px" }}
                              />
                              {
                                item.antiviruses_stats[`${props.type}`]
                                  .not_found
                              }
                              % No Anti-Virus
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      width: "30%",
                      height: "100px",
                      overflowY: "scroll",
                      backgroundColor: "#222831",
                      padding: "8px",
                      borderRadius: "10px",
                      margin: "auto",
                    }}
                  >
                    <span
                      style={{
                        color: "#FBCD4E",
                        margin: "0px",
                        fontSize: "14px",
                      }}
                    >
                      <FaUserShield style={{ color: "#FBCD4E" }} /> Anti-Virus
                      Installed:
                    </span>
                    {item.antiviruses_stats[`${props.type}`].found > 0 ||
                    item.antiviruses_stats[`${props.type}`].free > 0 ? (
                      item.antiviruses_stats[`${props.type}`].list
                        .filter((s) => s.name !== "Not Found")
                        .sort((a, b) => b.count - a.count)
                        .map((item, index) => (
                          <p style={{ margin: "0px", fontSize: "12px" }}>
                            {addFreeLabel(item.name)} - {item.count}
                          </p>
                        ))
                    ) : (
                      <p style={{ margin: "0px", fontSize: "12px" }}>None</p>
                    )}
                  </div>
                </div>
              )
          )}
        </div>
      ) : props.avLoading ? (
        <>
          <LoaderIcon type="spin" style={{ fill: "white" }} />
          <br />
          Loading AntiVirus Data...
        </>
      ) : (
        <h2>No data found</h2>
      )}
    </>
  );
}
