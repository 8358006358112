/* eslint-disable */

import Button from "@material-ui/core/Button";
import axios from "axios";
import React, { useEffect, useState } from "react";
import LoaderIcon from "react-loader-icon";
import Select from "react-select";
import { JSON_API, adminUrl } from "../../Config";
import DomainsByKeyword from "../partials/DomainsByKeyword";
import SearchForm from "../partials/SearchForm";

const AddExternalDomains = (props) => {
  const [selectedUser, setSelectedUser] = useState("");
  const [name, setName] = useState("");
  const [domain, setDomain] = useState("");
  const [system, setSystem] = useState("");
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [activeUrlList, setActiveUrlList] = useState(null);
  const [keywordLoading, setKeywordLoading] = useState(false);
  const [keywordPage, setKeywordPage] = useState(1);
  const [keywordPageLoading, setKeywordPageLoading] = useState(false);
  const [suggestedExternalDomains, setSuggestedExternalDomains] = useState([]);
  const [loadMore, setLoadMore] = useState(false);

  useEffect(() => {
    if (keyword) {
      onChangeExternalDomains();
    }
  }, [keywordPage]);

  const onChangeExternalDomains = (e) => {
    e && e.preventDefault();
    if (keywordPage > 1) {
      setKeywordPageLoading(true);
    } else {
      setSuggestedExternalDomains([]);
      setKeywordLoading(true);
    }

    axios
      .post(
        `${JSON_API}/stats/website-results/search-by-keyword-dev?system=${keyword}&noalert=true&page=${keywordPage}&email=${selectedUser}`,
        {
          domains: "domainsByEmail",
          // keyword: keyword,
        },
        {
          headers: { "auth-token": props.user.Token },
        }
      )
      .then((response) => {
        if (keywordPage > 1) {
          setKeywordPageLoading(false);
          setSuggestedExternalDomains([
            ...suggestedExternalDomains,
            ...response.data.data,
          ]);
        } else {
          setKeywordLoading(false);
          setSuggestedExternalDomains(response.data.data);
        }
        setLoadMore(response.data.loadMore);
      })
      .catch((err) => {
        if (keywordPage > 1) {
          setKeywordPageLoading(false);
        } else {
          setKeywordLoading(false);
        }
        console.log(err);
      });
  };
  const handleUserChange = (event) => {
    setSelectedUser(event.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDomainChange = (event) => {
    setDomain(event.target.value);
  };

  const handleSystemChange = (event) => {
    setSystem(event.target.value);
  };

  const handleSubmit = () => {
    setLoading(true);
    const data = {
      email: selectedUser,
      name,
      //   domain,
      //   system,
    };

    axios
      .post(`${adminUrl}/external-domains`, data, {
        headers: { "auth-token": props.user.Token },
      })
      .then((response) => {
        if (response.data === "OK") {
          setMsg("Successfuly added external domains");
        } else {
          setMsg("Error, tell dev");
        }
        setTimeout(() => {
          setName("");
          setSelectedUser("");
          setMsg(null);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        setMsg("Error, tell dev");
      });
    setLoading(false);
  };

  return (
    <div
      style={{
        backgroundColor: "lightGray",
        borderRadius: "20px",
        padding: "20px",
      }}
    >
      <Select
        onChange={handleUserChange}
        placeholder="Select User"
        className="search-by-select usersSelect desktop"
        options={props.usersList}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "#FBCD4E",
            primary: "black",
          },
        })}
      />
      <br />
      <SearchForm
        onChange={(e) => setKeyword(e.target.value)}
        onSubmit={onChangeExternalDomains}
        value={keyword}
        placeholder="Enter Keyword"
        formStyle={{
          backgroundColor: selectedUser ? "#E7F0FE" : "gray",
          color: "#222831",
          borderColor: "#222831",
          margin: "5px 2px",
          borderRadius: "4px 0px 0px 4px",
        }}
        btnStyle={{
          backgroundColor: selectedUser ? "#FECD4E" : "gray",
          color: "#222831",
          borderRadius: "0px 4px 4px 0px",
        }}
        inputDisabled={!selectedUser}
      />
      <br />
      <DomainsByKeyword
        keywordLoading={keywordLoading}
        addExternalDomain={(_name) => {
          if (typeof name !== "string") {
            if (name.includes(_name)) {
              let filterted = name.filter((e) => e !== _name);
              setName(filterted);
            } else {
              if (name) {
                setName([...name, _name]);
              } else {
                setName([_name]);
              }
            }
          } else {
            if (name.replace(/ /, "").split(",").includes(_name)) {
              let filterted = name.filter((e) => e !== _name);
              setName(filterted);
            } else {
              if (name) {
                setName([...name.replace(/ /, "").split(","), _name]);
              } else {
                setName([_name]);
              }
            }
          }
        }}
        addedDomains={name}
        activeUrlList={activeUrlList}
        setActiveUrlList={setActiveUrlList}
        keywordDomains={suggestedExternalDomains}
        loadMore={loadMore}
        keywordPageLoading={keywordPageLoading}
        page={keywordPage}
        setPage={setKeywordPage}
      />
      <input
        id="nameBlack"
        type="text"
        value={name}
        onChange={handleNameChange}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        placeholder="Full Name e.g steria.onmicrosoft.com"
        className="form-control blackTextFill"
      />
      {/* <br />
      <input
        id="domainBlack"
        type="text"
        value={domain}
        onChange={handleDomainChange}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        placeholder="Domain e.g onmicrosoft.com"
        className="form-control blackTextFill"
      />
      <br />
      <input
        id="systemBlack"
        type="text"
        value={system}
        onChange={handleSystemChange}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        placeholder="System e.g steria"
        className="form-control blackTextFill"
      /> */}
      <br />
      <Button
        type="button"
        fullWidth
        variant="contained"
        color="primary"
        className="searchBtn"
        onClick={handleSubmit}
      >
        Add External Domains{" "}
        {loading && (
          <LoaderIcon
            type="spin"
            style={{
              width: "16px",
              height: "auto",
              margin: "-2px 10px 0px",
            }}
          />
        )}
      </Button>
      {msg && <div className="text-center lightText">{msg}</div>}
    </div>
  );
};

export default AddExternalDomains;
