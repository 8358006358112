/* eslint-disable */

import axios from "axios";
import cryptoRandomString from "crypto-random-string";
import React, { useEffect, useState } from "react";
import LoaderIcon from "react-loader-icon";
import CreateApiClient from "../../API";
import { JSON_API, adminUrl } from "../../Config";
import UserData from "./UserData";
import UserDetails from "./UserDetails";

const api = CreateApiClient();

const searchers = ["MSSP", "master"];
const selectors = ["User", "Customer"];

export default function UserCreation(props) {
  const [activeStage] = useState(0);
  const [role, setRole] = useState("User");
  const [userDataLoading, setUserDataLoading] = useState(false);
  const [urlsLoading, setUrlsLoading] = useState(null);
  const [suggestedDomains, setSuggestedDomains] = useState([]);
  const [keywordLoading, setKeywordLoading] = useState(false);
  const [activeUrlList, setActiveUrlList] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [keywordPage, setKeywordPage] = useState(1);
  const [keywordPageLoading, setKeywordPageLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [keywordData, setKeywordData] = useState({});
  const [noResults, setNoResults] = useState(null);
  const [email, setEmail] = useState("");
  const [urlHash, setUrlHash] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [password, setPassword] = useState("");

  const [hasCensorDate, setHasCensorDate] = useState(false);
  const [censorDate, setCensorDate] = useState("");
  const [since, setSince] = useState("");
  const [until, setUntil] = useState("");

  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState("");

  const onChangeCensorDate = (e) => {
    setCensorDate(e.target.value);
  };

  const searchKeyword = async (e) => {
    e && e.preventDefault();
    setSuggestedDomains([]);
    if (selectors.includes(role)) {
      if (keywordPage > 1) {
        setKeywordPageLoading(true);
      } else {
        setKeywordLoading(true);
      }
      const response = await api.adminReq(
        "POST",
        `${adminUrl}/populate-user-data?keyword=${keyword}&page=${keywordPage}`,
        {
          "auth-token": props.token,
        },
        {}
      );

      let primaries = response.data.data.filter(
        (d) => d.domain.includes(keyword) && !d.android
      );
      let apks = response.data.data.filter((d) => d.android);
      let externals = response.data.data.filter(
        (d) => !d.domain.includes(keyword) && !d.android
      );
      let sortedData = primaries.concat(apks).concat(externals);

      if (keywordPage > 1) {
        setKeywordPageLoading(false);
        setSuggestedDomains([...suggestedDomains, ...sortedData]);
      } else {
        setKeywordData({
          total_domains: response.data.total_domains,
          total_occurrences: response.data.total_occurrences,
        });
        setKeywordPageLoading(false);
        setSuggestedDomains(sortedData);
        if (
          response.data.data &&
          response.data.data.length === 0 &&
          props.mode === "edit"
        ) {
          setNoResults("No new domains were discovered");
        }
        if (response.data && response.data.total_domains === 0) {
          setNoResults("NO RESULTS");
        }
      }
      setLoadMore(response.data.loadMore);
      setKeywordLoading(false);
    }
  };

  const populateUserData = async () => {
    const randomUrlHash = cryptoRandomString({ length: 64, type: "url-safe" });
    const randomApiKey = cryptoRandomString({ length: 64, type: "url-safe" });
    setUrlHash(randomUrlHash);
    setApiKey(randomApiKey);
    if (selectors.includes(role)) {
      if (keywordPage > 1) {
        setKeywordPageLoading(true);
      } else {
        setUserDataLoading(true);
      }
      const response = await api.adminReq(
        "POST",
        `${adminUrl}/populate-user-data?keyword=${
          keyword ? keyword : company
        }&page=${keywordPage}`,
        {
          "auth-token": props.token,
        },
        {}
      );

      if (keywordPage > 1) {
        setKeywordPageLoading(false);
        setSuggestedDomains([...suggestedDomains, ...response.data.data]);
      } else {
        setKeywordData({
          total_domains: response.data.total_domains,
          total_occurrences: response.data.total_occurrences,
        });
        setKeywordPageLoading(false);
        setSuggestedDomains(response.data.data);
        if (
          response.data.data &&
          response.data.data.length === 0 &&
          props.mode === "edit"
        ) {
          setNoResults("No new domains were discovered");
        }
        if (response.data && response.data.total_domains === 0) {
          setNoResults("NO RESULTS");
        }
      }
      setLoadMore(response.data.loadMore);
      setUserDataLoading(false);
    }
  };

  const getDomainUrls = (domain) => {
    setUrlsLoading(domain);
    const isWhois = suggestedDomains.find(
      (s) => s.domain === domain && s.whois
    );
    let _keyword;
    if (!isWhois) {
      _keyword = keyword ? keyword : company;
    } else {
      _keyword = null;
    }
    axios
      .post(
        `${JSON_API}/stats/website-results/urls-by-domain-dev?system=${_keyword}&domain=${domain}`,
        {},
        {
          headers: { "auth-token": props.token },
        }
      )
      .then((response) => {
        const added = suggestedDomains.map((s) => {
          if (s.domain === domain) {
            s.urls_list = response.data;
          }
          return s;
        });
        setSuggestedDomains(added);
        setUrlsLoading(null);
      })
      .catch((err) => {
        console.log(err);
        setUrlsLoading(null);
      });
  };

  useEffect(() => {
    if (activeUrlList) {
      getDomainUrls(activeUrlList);
    }
  }, [activeUrlList]);

  useEffect(() => {
    if (keywordPage > 1) {
      populateUserData();
    }
  }, [keywordPage]);

  const resetForm = () => {
    setEmail("");
    setName("");
    setCompany("");
    setKeyword("");
    setPassword("");
    setHasCensorDate(false);
    setCensorDate("");
    setSince("");
    setUntil("");
    setSaved(false);
    setError("");
    setLoading(false);
    setApiKey("");
    setUrlHash("");
    setKeywordData([]);
    setSuggestedDomains([]);
  };

  const saveUser = async (
    domains,
    apks,
    externals,
    alertEmails,
    permissions
  ) => {
    setError("");
    setLoading(true);
    let externalsArr = [];
    if (externals.length > 0) {
      externalsArr = externals.map((item) => {
        let system = item.split(".")[0];
        let domain = item.replace(`${system}.`, "");
        let obj = {
          name: item,
          system: item.split(".")[0],
          domain: domain,
        };
        return obj;
      });
    }

    const newUser = {
      name: name,
      email: email,
      password: password,
      company: company,
      role: role,
      domains: domains,
      apps: apks,
      external_domains: externalsArr,
      api_key: apiKey,
      url_hash: urlHash,
      alert_emails: alertEmails,
      api_permissions: permissions.permissions,
      ui_permissions: permissions.uiPermissions,
      censor_fields: permissions.censorFields,
      censor_date: hasCensorDate ? censorDate : null,
      exposed_dates: {
        since: since,
        until: until,
      },
    };

    const config = {
      headers: {
        "auth-token": props.token,
      },
    };

    const response = await api.registerUser(
      `register?newForm=true`,
      newUser,
      config
    );

    if (response && response.User) {
      setLoading(false);
      setSaved(true);
      setTimeout(() => {
        resetForm();
      }, 2000);
    } else {
      setLoading(false);
      if (typeof response === "string") {
        setError(response);
      } else {
        setError("Something went wrong, please try again");
      }
    }
  };

  return (
    <div className="darkText">
      <h2 className="cyberFont bold text-center yellowColor">
        Welcome to the new user creation experience!
      </h2>
      <hr />
      <UserDetails
        activeStage={activeStage}
        populateUserData={() => populateUserData()}
        role={role}
        setRole={setRole}
        email={email}
        setEmail={setEmail}
        name={name}
        setName={setName}
        company={company}
        setCompany={setCompany}
        password={password}
        setPassword={setPassword}
        userDataLoading={userDataLoading}
        setKeyword={setKeyword}
      />
      <br />

      {company && !userDataLoading ? (
        <UserData
          saveUser={(domains, apks, externals, alertEmails, permissions) =>
            saveUser(domains, apks, externals, alertEmails, permissions)
          }
          searchKeyword={searchKeyword}
          userDataLoading={userDataLoading}
          populateUserData={populateUserData}
          saved={saved}
          loading={loading}
          error={error}
          urlHash={urlHash}
          apiKey={apiKey}
          email={email}
          searchers={searchers}
          selectors={selectors}
          role={role}
          token={props.token}
          suggestedDomains={suggestedDomains}
          urlsLoading={urlsLoading}
          lazyLoad={true}
          keywordLoading={keywordLoading}
          activeUrlList={activeUrlList}
          setActiveUrlList={setActiveUrlList}
          loadMore={loadMore}
          keywordPageLoading={keywordPageLoading}
          keywordPage={keywordPage}
          setKeywordPage={setKeywordPage}
          tooltip={true}
          keywordData={keywordData}
          getDomainUrls={(domain) => getDomainUrls(domain)}
          company={company}
          hasCensorDate={hasCensorDate}
          setHasCensorDate={setHasCensorDate}
          censorDate={censorDate}
          setCensorDate={setCensorDate}
          since={since}
          setSince={setSince}
          until={until}
          setUntil={setUntil}
          onChangeCensorDate={onChangeCensorDate}
          keyword={keyword}
          setKeyword={setKeyword}
        />
      ) : (
        userDataLoading && (
          <LoaderIcon type={"spokes"} className="yellowLoader" />
        )
      )}
    </div>
  );
}
