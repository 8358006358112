/* eslint-disable */

import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Collapse from "@material-ui/core/Collapse";
import SvgIcon from "@material-ui/core/SvgIcon";
import Tab from "@material-ui/core/Tab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tabs from "@material-ui/core/Tabs";
import Tooltip from "@material-ui/core/Tooltip";
import { alpha, makeStyles, withStyles } from "@material-ui/core/styles";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import { animated, useSpring } from "@react-spring/web"; // web.cjs is required for IE 11 support
import axios from "axios";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { AiFillDatabase } from "react-icons/ai";
import { BsEyeFill, BsFillArrowDownCircleFill } from "react-icons/bs";
import {
  FaCheckCircle,
  FaCookie,
  FaCookieBite,
  FaCopy,
  FaFacebook,
  FaFile,
  FaFileCsv,
  FaRegCalendarAlt,
  FaRegIdCard,
  FaSearch,
  FaShieldAlt,
  FaUnlockAlt,
  FaUserCircle,
  FaUserSecret,
  FaViruses,
} from "react-icons/fa";
import {
  GiArtificialIntelligence,
  GiFamilyTree,
  GiSherlockHolmes,
  GiWireframeGlobe,
} from "react-icons/gi";
import { ImCross } from "react-icons/im";
import {
  MdCorporateFare,
  MdOutlineWarning,
  MdPassword,
  MdSchedule,
  MdSettingsApplications,
  MdSettingsEthernet,
} from "react-icons/md";
import {
  RiBracketsLine,
  RiComputerLine,
  RiExternalLinkFill,
  RiFolderOpenFill,
  RiInstallFill,
  RiLockPasswordFill,
  RiVirusLine,
} from "react-icons/ri";
import { VscDiffAdded } from "react-icons/vsc";
import LoaderIcon from "react-loader-icon";
import { APIAlertsAddress, APIFullAddress } from "../Config";
import cavaLogo from "../images/thumbnail.jpeg";
import BlurryText from "./Blur";

import CustomizedTreeView from "./Tree";
import FeedbackModal from "./partials/FeedbackModal";
import InstalledSoftware from "./partials/InstalledSoftware";
import SimpleModal from "./partials/SimpleModal";
import Typewriter from "./partials/Typewriter";

const defaultSrc = (ev) => {
  ev.target.src = cavaLogo;
};

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: "translate3d(20px,0,0)" },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    "& .close": {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha("#1A1C20", 0.4)}`,
  },
}))((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const emailRe =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export default function EmployeeTabs(props) {
  const [open, setOpen] = React.useState(false);
  const [modalState, setModalState] = useState(null);
  const [machineDetails, setMachineDetails] = useState([]);
  const [machineData, setMachineData] = useState([]);
  const [blur, setBlur] = useState(true);
  const [loading, isLoading] = useState(true);
  const [currentMachine, setCurrentMachine] = useState([]);
  const [passwords, setPasswords] = useState([]);

  const userRole = props.userRole;
  const selectedDomain = props.selectedDomain;
  const config = props.config;
  const tableData = props.data;
  const [externalView, setExternalView] = useState(false);
  const [viewLoading, setViewLoading] = useState(false);
  const [expandedApplication, setExpandedApplication] = useState("");
  const [archiveLoader, setArchiveLoader] = useState(null);
  const [cookiesDomain, setCookiesDomain] = useState("");
  const [applications, setApplications] = useState([]);
  const [copied, isCopied] = useState("");
  const [passListLoader, setPassListLoader] = useState(false);
  const [requestModalOpen, setRequestModalOpen] = useState(false);

  const openRequestModal = () => {
    setRequestModalOpen(true);
  };

  const closeRequestModal = () => {
    setRequestModalOpen(false);
  };

  const copyToClipboard = async (id) => {
    await navigator.clipboard.writeText(id);
    isCopied(id);
    setTimeout(() => {
      isCopied(null);
    }, 2000);
  };

  const toggleCookies = (domain) => {
    if (cookiesDomain === domain) {
      setCookiesDomain("");
    } else {
      setCookiesDomain(domain);
    }
  };

  useEffect(() => {
    userRole === "MSSP" || userRole === "User" ? setBlur(true) : setBlur(false);
  }, [blur, userRole]);

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const archiveStealer = async (row, index, machineData) => {
    setArchiveLoader(row._id);
    await axios
      .post(
        `${APIFullAddress}/archive?id=${row._id}&ip=${row.ip}&type=${
          props.isThirdParty ? "third_parties" : "employees"
        }`,
        {},
        config
      )
      .then((res) => {
        let newData = props.data.filter((s) => s._id !== row._id);
        if (newData) {
          props.setData(newData);
          let nextTab = props.data[index + 1];
          let previousTab = props.data[index - 1];
          let _activeTabId = nextTab ? nextTab._id : previousTab._id;

          if (!nextTab && previousTab) {
            handleChange(null, props.data.indexOf(previousTab));
          }
          handleCollapse(_activeTabId, _activeTabId);
        } else {
          props.setData([]);
        }
      })
      .catch((err) => console.log(err));
    setArchiveLoader(null);
  };

  const unresolveStealer = async (row, index, machineData) => {
    setArchiveLoader(row._id);
    await axios
      .post(
        `${APIFullAddress}/archive/unresolve?id=${row._id}&ip=${row.ip}&type=${
          props.isThirdParty ? "third_parties" : "employees"
        }`,
        {},
        config
      )
      .then((res) => {
        let newData = props.data.filter((s) => s._id !== row._id);
        if (newData) {
          props.setData(newData);
          let nextTab = props.data[index + 1];
          let previousTab = props.data[index - 1];
          let _activeTabId = nextTab ? nextTab._id : previousTab._id;

          if (!nextTab && previousTab) {
            handleChange(null, props.data.indexOf(previousTab));
          }
          handleCollapse(_activeTabId, _activeTabId);
        } else {
          props.setData([]);
        }
      })
      .catch((err) => console.log(err));
    setArchiveLoader(null);
  };

  const loadPasswords = async (id, stealer) => {
    setCurrentMachine(stealer);
    setPassListLoader(true);
    await axios
      .post(
        `${APIFullAddress}/machine/passwords?type=${
          props.isThirdParty ? "third_parties" : "employees"
        }`,
        { id: id },
        config
      )
      .then((res) => {
        setPasswords(res.data);
      })
      .catch((err) => console.log(err));
    setPassListLoader(false);
    setModalState("passwords");
    handleOpen();
  };

  const showApplication = (app) => {
    let _expanded = machineData[0].applications.find(
      (a) => a.name.toLowerCase() === app.toLowerCase()
    );
    setExpandedApplication(_expanded);
    setModalState("application");
    handleOpen();
  };

  const showApplications = (apps) => {
    setApplications(apps.filter((a) => a.ai_scenarios));
    setModalState("applications");
    handleOpen();
  };

  const showSoftware = () => {
    // let _expanded = machineData[0].installed_software.find(
    //   (a) => a.program.toLowerCase() === software.toLowerCase()
    // );
    // setExpandedApplication(_expanded);
    setModalState("software");
    handleOpen();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setModalState(null);
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCollapse = async (id, machine, changeView) => {
    setCookiesDomain("");
    if (changeView !== undefined) {
      setViewLoading(true);
    } else {
      setExternalView(false);
      isLoading(true);
    }
    const query = {
      domain: selectedDomain,
      id: machine,
      companySearch: props.companySearch,
    };

    getMachineData(query, config);

    async function getMachineData(query, config) {
      let endpoint;
      try {
        if (props.isThirdParty) {
          endpoint = `${APIFullAddress}/machine?thirdparties=true`;
        } else {
          endpoint = `${APIFullAddress}/machine?external=${changeView}`;
        }
        await axios.post(endpoint, query, config).then((res) => {
          setMachineData(res.data);
        });
      } catch (e) {
        console.log(e);
      }
      if (changeView !== undefined) {
        setViewLoading(false);
      } else {
        isLoading(false);
      }
    }

    // setMachineDetails(id);

    const shownState = machineDetails.slice();
    const index = shownState.indexOf(machine);
    if (index > 0) {
      shownState.splice(index, 1);
      setMachineDetails(shownState);
    } else {
      shownState.pop();
      shownState.push(machine);
      setMachineDetails(shownState);
    }
  };

  useEffect(() => {
    if (tableData.length > 0) {
      handleCollapse(tableData[0]._id, tableData[0]._id);
    }
  }, []);

  const populateListItem = (item) => {
    return (
      <>
        {expandedApplication[`${item}`] ? (
          <li className="capitalize">
            {item.replace(/_/g, " ")}: {expandedApplication[`${item}`]}
          </li>
        ) : (
          <></>
        )}
      </>
    );
  };

  const slackAlert = async (type) => {
    const msg = `${props.user.email} just exported ${
      props.isThirdParty ? "third parties" : "employees"
    } stealer (${type})`;
    await axios.post(`${APIAlertsAddress}/slack`, { message: msg }, config);
  };

  const passwordsHtml = (
    <div>
      <div>
        <h3 className="align-center">Additional Passwords Analysis</h3>
        <CSVLink
          data={passwords.csv}
          filename={currentMachine && `${currentMachine}_passwords.csv`}
          className="cardBtn"
          target="_blank"
          rel="noreferrer"
        >
          Export To CSV
          <FaFileCsv size={20} />
        </CSVLink>
      </div>
      <br />
      <Table size="small" aria-label="more" className="credsTable systemTable">
        <TableHead>
          <TableRow className="credsRow">
            <TableCell className="profileTh">
              <span>
                <MdPassword size={20} className="credsIcons" />
              </span>
              Password
            </TableCell>
            <TableCell className="profileTh">
              <span>
                <RiBracketsLine size={20} className="credsIcons" />
              </span>
              Contains
            </TableCell>
            <TableCell className="profileTh">
              <span>
                <GiSherlockHolmes size={20} className="credsIcons" />
              </span>
              Strength
            </TableCell>
          </TableRow>
        </TableHead>
        {passwords &&
          passwords.data &&
          passwords.data.length > 0 &&
          passwords.data.map((item) => (
            <TableRow className="credsRow">
              <TableCell className="profileTd">
                <Chip
                  size="small"
                  label={item.password}
                  style={{ backgroundColor: "#FBCD4E" }}
                />
              </TableCell>
              <TableCell className="profileTd">
                {item.contains.map((char) => (
                  <Chip size="small" label={char} style={{ margin: "2px" }} />
                ))}
              </TableCell>
              <TableCell className={"profileTd " + item.class}>
                {item.strength}
              </TableCell>
            </TableRow>
          ))}
      </Table>
    </div>
  );

  const applicationHtml = expandedApplication && (
    <div>
      <h2 className="uppercase">{expandedApplication.name}</h2>
      <div
        style={{ display: "inline-flex", width: "100%", alignItems: "center" }}
      >
        <div style={{ width: expandedApplication.risk ? "50%" : "100%" }}>
          <p className="lightText">{expandedApplication.description}</p>
          <ul style={{ listStyle: "none" }}>
            {populateListItem("category")}
            {populateListItem("subcategory")}
            {populateListItem("technology")}
            {populateListItem("saas")}
            {populateListItem("depends_on_applications")}
            {populateListItem("tunnels_other_applications")}
            {populateListItem("implicit_use_applications")}
            {populateListItem("widely_used")}
          </ul>
        </div>
        <div>
          {expandedApplication.risk && (
            <p className="lightText">Risk: {expandedApplication.risk}</p>
          )}
          <ul style={{ listStyle: "none" }}>
            {populateListItem("has_known_vulnerabilities")}
            {populateListItem("data_breaches")}
            {populateListItem("prone_to_misuse")}
            {populateListItem("used_by_malware")}
            {populateListItem("ip_based_restrictions")}
            {populateListItem("standard_ports")}
            {populateListItem("evasive")}
            {populateListItem("excessive_bandwidth")}
            {populateListItem("certifications")}
            {populateListItem("poor_financial_viability")}
            {populateListItem("poor_terms_of_service")}
          </ul>
        </div>
      </div>
    </div>
  );

  const applicationsHtml = applications && applications.length > 0 && (
    <div style={{ color: "white" }}>
      <h2>
        <GiArtificialIntelligence size={45} style={{ fil: "white" }} /> AI
        Attack Scenarios
      </h2>
      <br />
      {applications.map((app, index) => (
        <div key={index}>
          <div>
            <span
              className="uppercase red-chip"
              style={{ marginBottom: "5px" }}
            >
              {app.name}{" "}
            </span>
            {app.ai_scenarios.map((item, index) => (
              <div key={index}>
                <Typewriter text={item} delay={15} infinite={false} />
              </div>
            ))}
          </div>
          <hr
            style={{ borderTop: "2px dashed #cc2f30", color: "transparent" }}
          />
        </div>
      ))}
    </div>
  );

  const softwareHtml = machineData[0] &&
    machineData[0].installed_software &&
    machineData[0] &&
    machineData[0].installed_software.length > 0 && (
      <div>
        <div>
          <h3 className="align-center">Installed Software</h3>
          <CSVLink
            data={machineData[0] && machineData[0].installed_software}
            filename={`${
              machineData[0] && machineData[0].source
            }_installed_software.csv`}
            className="cardBtn"
            target="_blank"
            rel="noreferrer"
          >
            Export To CSV
            <FaFileCsv size={20} />
          </CSVLink>
        </div>
        <br />
        <Table
          size="small"
          aria-label="more"
          className="credsTable systemTable"
        >
          <TableHead>
            <TableRow className="credsRow" style={{ border: "1px solid" }}>
              <TableCell className="profileTh">Software Name</TableCell>
              <TableCell
                className="profileTh alignRight"
                style={{ textAlign: "right" }}
              >
                Version
              </TableCell>
            </TableRow>
          </TableHead>
          {machineData[0] &&
            machineData[0].installed_software.map((item) => (
              <TableRow className="credsRow" style={{ border: "1px solid" }}>
                <TableCell className="profileTd">
                  <Chip
                    size="small"
                    label={item.program}
                    style={{ backgroundColor: "#FBCD4E" }}
                  />
                </TableCell>
                <TableCell
                  className={"profileTd " + item.class}
                  style={{ textAlign: "right" }}
                >
                  {item.version ? item.version : "UNKNOWN"}
                </TableCell>
              </TableRow>
            ))}
        </Table>
      </div>
    );
  return (
    <>
      {props.data.length > 0 && (
        <div className={classes.root + " employeesTable"}>
          <Tabs
            id="employeesTabs"
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.tabs + " employeeTabsList"}
          >
            {props.data.map((row, index) => (
              <Tab
                className="employeeTab"
                key={index}
                onClick={() => handleCollapse(row._id, row._id)}
                style={{ userSelect: "text" }}
                label={
                  <ul className="employee-nav">
                    <li>
                      <FaRegIdCard size={20} className="credsIcons" />
                      <span className="employee-label">Stealer ID:</span>

                      {row.blur ? (
                        <>
                          <BlurryText value={" " + row.source} />
                        </>
                      ) : (
                        <>{" " + row.source}</>
                      )}
                      {machineDetails.includes(row._id) && (
                        <span
                          style={{
                            position: "absolute",
                            right: "5px",
                            top: "5px",
                            backgroundColor: "#FECD4E",
                            // marginLeft: "5px",
                            borderRadius: "50px",
                            padding: "2px 8px",
                            fontSize: "12px",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            copyToClipboard(row.source);
                          }}
                        >
                          {copied === row.source ? "COPIED!" : <FaCopy />}
                        </span>
                      )}
                    </li>
                    {row.stealer_family && row.stealer_family !== "" && (
                      <li>
                        <FaViruses size={20} className="credsIcons" />
                        <span className="capitalize">
                          <span className="employee-label">
                            Stealer Family:
                          </span>{" "}
                          {row.stealer_family}
                        </span>
                      </li>
                    )}
                    <li>
                      <MdSettingsEthernet size={20} className="credsIcons" />
                      <span className="employee-label">IP Address:</span>

                      {row.blur ? (
                        <>
                          <BlurryText value={" " + row.ip} />
                        </>
                      ) : props.user?.ui_permissions?.includes(
                          "search-by-ip"
                        ) && row.ip != "Not Found" ? (
                        <a
                          href={`/search-by-ip/${row.ip}`}
                          target="_blank"
                          style={{
                            textDecoration: "underline",
                            color: machineDetails.includes(row._id)
                              ? "black"
                              : "white",
                          }}
                        >
                          {" " + row.ip} →
                        </a>
                      ) : (
                        <>{" " + row.ip}</>
                      )}
                    </li>
                    <li>
                      <RiVirusLine size={20} className="credsIcons" />
                      <span className="employee-label">Malware Path:</span>
                      {row.blur ? (
                        <span>
                          {row.path && row.path.includes(".") ? (
                            <span>
                              <BlurryText value={row.path.split(".")[0]} />.
                              {row.path.split(".")[1]}
                            </span>
                          ) : (
                            <BlurryText value={row.path} />
                          )}
                        </span>
                      ) : (
                        <>{row.path}</>
                      )}
                    </li>
                    <li>
                      <FaRegCalendarAlt size={20} className="credsIcons" />
                      <span className="employee-label">Date Compromised:</span>
                      {" " + row.date}
                    </li>
                    <li>
                      <VscDiffAdded size={20} className="credsIcons" />
                      <span className="employee-label">Latest Detection:</span>
                      {" " + row.date_added}
                    </li>
                  </ul>
                }
                {...a11yProps(index)}
              />
            ))}

            {props.loader ? (
              <LoaderIcon className="tabsLoader" type={"spin"} />
            ) : (
              <div style={{ textAlign: "center" }}>
                {props.hasNextPage ? (
                  <button className="cardBtn loadMore" onClick={props.loadMore}>
                    Load More
                  </button>
                ) : (
                  <div>
                    {/* <h3 className="align-center">No More Results</h3> */}
                  </div>
                )}
              </div>
            )}
          </Tabs>
          {props.data.map((row, index) => (
            <TabPanel
              value={value}
              index={index}
              key={index}
              className="tabPanel"
              id="contentTab"
            >
              {machineDetails.includes(row._id) && !loading ? (
                <div>
                  <button
                    title="Resolve"
                    id="unlockBtn"
                    onClick={(e) => {
                      e.stopPropagation();
                      props.archiveMode === "unresolved"
                        ? archiveStealer(row, index, machineData)
                        : unresolveStealer(row, index, machineData);
                    }}
                    style={{
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className="cardBtn archiveBtn shadow-box"
                  >
                    <span>
                      {props.archiveMode === "resolved"
                        ? "Unresolved"
                        : "Resolved"}
                    </span>
                    {archiveLoader === row._id ? (
                      <LoaderIcon
                        style={{
                          width: "16px",
                          height: "16px",
                          fill: "black",
                          position: "relative",
                          top: "-5px",
                          left: "2px",
                        }}
                        type={"spin"}
                      />
                    ) : props.archiveMode === "unresolved" ? (
                      <FaCheckCircle
                        size={16}
                        style={{ marginLeft: "2px" }}
                        className=""
                        onClick={(e) => {
                          e.stopPropagation();
                          archiveStealer(row, index, machineData);
                        }}
                      />
                    ) : (
                      <ImCross
                        size={16}
                        style={{ marginLeft: "2px" }}
                        className=""
                        onClick={(e) => {
                          e.stopPropagation();
                          unresolveStealer(row, index, machineData);
                        }}
                      />
                    )}
                  </button>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      width: "85%",
                    }}
                  >
                    <h3
                      className="lightText credTitle"
                      style={{ margin: "0px" }}
                    >
                      <span className="titleIcon">
                        <FaUserSecret size={30} />
                      </span>
                      Profile
                      {machineData[0]?.showAiButton && (
                        <a
                          target={machineData[0].visualize ? "_blank" : ""}
                          rel="noreferrer"
                          href={
                            machineData[0].visualize
                              ? `/search-by-stealer/${row.source}`
                              : undefined
                          }
                          onClick={
                            !machineData[0].visualize && openRequestModal
                          }
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#000000",
                            color: "#FDA503",
                            border: "2px solid",
                            fontSize: "14px",
                            marginLeft: "10px",
                            padding: "12px 16px",
                            borderRadius: "40px",
                            position: "relative",
                            top: "-5px",
                          }}
                        >
                          <GiArtificialIntelligence
                            size={25}
                            style={{
                              position: "relative",
                              top: "-2px",
                              right: "5px",
                            }}
                          />
                          Analyze with AI (NEW)
                        </a>
                      )}
                    </h3>
                    {requestModalOpen && (
                      <FeedbackModal
                        title={"Unlock a Feature"}
                        text={"Submit this form to unlock this feature"}
                        hideInput={true}
                        thanks={"Your request has been submitted!"}
                        user={props.user}
                        open={requestModalOpen}
                        handleClickOpen={openRequestModal}
                        handleClose={closeRequestModal}
                        customMessage={`${props.user.email} has requested to unlock the AI Analysis feature.`}
                      />
                    )}
                    <div style={{ display: "flex", marginLeft: "10px" }}>
                      <div
                        style={{
                          display: "inline-flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#FDCD4E",
                          borderRadius: "5px",
                          padding: "5px 15px",
                        }}
                      >
                        <CSVLink
                          onClick={() => slackAlert("credentials")}
                          data={machineData.map((item) => {
                            let obj = {
                              stealer: item.source,
                              date_compromised: item.date,
                              date_added: item.date_added,
                              ip: item.ip,
                              pc: item.pc,
                              os: item.os,
                              anti_virus: item.av,
                              facebook:
                                item.fb && item.fb !== "Not Found"
                                  ? `https://facebook.com/${item.fb}`
                                  : "Not Found",
                              malware_path: item.path,
                              domain: item.domain,
                              url: item.url,
                              login: item.login,
                              suggested_login: item.suggested_login
                                ? item.suggested_login
                                : "",
                              top_logins: item.top_logins
                                ? item.top_logins.map((t) => t.login).join(",")
                                : "",
                              password: item.password,
                              applications:
                                item.applications &&
                                item.applications.length > 0
                                  ? item.applications
                                      .map((item) => item.name)
                                      .join(",")
                                  : "Not Found",
                              installed_software:
                                item.installed_software &&
                                item.installed_software.length > 0
                                  ? item.installed_software
                                      .map((item) => item.program)
                                      .join(",")
                                  : "Not Found",
                            };
                            return obj;
                          })}
                          filename={`${blur ? row.id : row.source}_${new Date()
                            .toISOString()
                            .substring(0, 10)}.csv`}
                          className={"lightText"}
                          style={{
                            color: "black",
                            fontSize: "16px",
                          }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <MdPassword
                            size={20}
                            style={{ marginRight: "5px" }}
                          />
                          Export Credentials
                        </CSVLink>
                      </div>
                      {machineData[0] && machineData[0].cookies && (
                        <div
                          style={{
                            display: "inline-flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#FDCD4E",
                            borderRadius: "5px",
                            padding: "5px 15px",
                            marginLeft: "2px",
                          }}
                        >
                          <CSVLink
                            onClick={() => slackAlert("cookies")}
                            data={machineData[0].raw_cookies.map((item) => {
                              let obj = {
                                stealer: machineData[0].source,
                                date_compromised: machineData[0].date,
                                date_added: machineData[0].date_added,
                                ip: machineData[0].ip,
                                pc: machineData[0].pc,
                                os: machineData[0].os,
                                anti_virus: machineData[0].av,
                                facebook:
                                  machineData[0].fb &&
                                  machineData[0].fb !== "Not Found"
                                    ? `https://facebook.com/${machineData[0].fb}`
                                    : "Not Found",
                                malware_path: machineData[0].path,
                                domain: item.url_stripped,
                                url: item.url,
                                cookie_name: item.cookie_name,
                                cookie_value: item.value,
                                cookie_expiry: item.expiry,
                                applications:
                                  machineData[0].applications &&
                                  machineData[0].applications.length > 0
                                    ? machineData[0].applications
                                        .map((item) => item.name)
                                        .join(",")
                                    : "Not Found",
                                installed_software:
                                  machineData[0].installed_software &&
                                  machineData[0].installed_software.length > 0
                                    ? machineData[0].installed_software
                                        .map((item) => item.program)
                                        .join(",")
                                    : "Not Found",
                              };
                              return obj;
                            })}
                            filename={`${row.source}_${new Date()
                              .toISOString()
                              .substring(0, 10)}.csv`}
                            className={"lightText"}
                            style={{
                              color: "black",
                              fontSize: "16px",
                            }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FaCookieBite
                              size={20}
                              style={{ marginRight: "5px" }}
                            />
                            Export Cookies
                          </CSVLink>
                        </div>
                      )}
                    </div>
                  </div>

                  <hr style={{ borderTop: "1px solid black" }} />

                  {/* {userRole === "MSSP" &&
                    !unlockedStealers.some(
                      (e) =>
                        e.id === row._id && e.domain === props.selectedDomain
                    ) &&
                    (unlockLoader ? (
                      <LoaderIcon
                        className="unlockLoader"
                        type={"spinningBubbles"}
                      />
                    ) : (
                      <button
                        onClick={() => unlockStealer(row._id, index)}
                        className="cardBtn unlockBtn"
                      >
                        Unlock This Stealer
                        <BsUnlockFill size={15} className="smIcons" />
                      </button>
                    ))} */}

                  <Table
                    size="small"
                    aria-label="more"
                    className="credsTable systemTable"
                  >
                    <TableHead>
                      <TableRow className="credsRow">
                        <TableCell className="profileTh">
                          <span>
                            <RiComputerLine size={20} className="credsIcons" />
                          </span>
                          Computer Name
                        </TableCell>
                        <TableCell className="profileTh">
                          <span>
                            <AiFillDatabase size={20} className="credsIcons" />
                          </span>
                          Operating System
                        </TableCell>
                        <TableCell className="profileTh">
                          <span>
                            <FaShieldAlt size={20} className="credsIcons" />
                          </span>
                          Anti Virus
                        </TableCell>
                        <TableCell className="profileTh">
                          <span>
                            <FaFacebook size={20} className="credsIcons" />
                          </span>
                          Facebook
                        </TableCell>
                        <TableCell className="profileTh">
                          <span>
                            <GiFamilyTree size={20} className="credsIcons" />
                          </span>
                          Virtualization
                        </TableCell>
                        {(userRole === "Customer" ||
                          userRole === "Admin" ||
                          userRole === "master") && (
                          <TableCell className="profileTh">
                            <span>
                              <MdPassword size={20} className="credsIcons" />
                            </span>
                            Passwords
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    {machineData.length > 0 && (
                      <TableBody>
                        <TableRow className="credsRow credsTable">
                          {machineData[0].blur &&
                          machineData[0].pc !== "Not Found" ? (
                            <TableCell>
                              <BlurryText value={machineData[0].pc} />
                            </TableCell>
                          ) : (
                            <TableCell>{machineData[0].pc}</TableCell>
                          )}
                          <TableCell>{machineData[0].os}</TableCell>
                          <TableCell>{machineData[0].av}</TableCell>
                          <TableCell>
                            {machineData[0].fb !== "Not Found" ? (
                              <button
                                className="cardBtn"
                                style={{ padding: "8px 10px" }}
                              >
                                <a
                                  style={{ display: "flex" }}
                                  rel="noreferrer"
                                  target="_blank"
                                  href={`https://facebook.com/${machineData[0].fb}`}
                                >
                                  <span>View Profile</span>
                                  <RiExternalLinkFill
                                    size={20}
                                    className="smIcons"
                                  />
                                </a>
                              </button>
                            ) : (
                              <button
                                className="cardBtn grayBtn"
                                style={{ padding: "8px 10px" }}
                              >
                                Not Found
                              </button>
                            )}
                          </TableCell>
                          <TableCell>
                            {machineData[0].tree ? (
                              <TreeView
                                className={classes.root}
                                defaultExpanded={["1"]}
                                defaultCollapseIcon={<MinusSquare />}
                                defaultExpandIcon={
                                  <RiFolderOpenFill size={25} />
                                }
                                defaultEndIcon={<FaFile size={12} />}
                              >
                                <StyledTreeItem
                                  nodeId={1}
                                  label={<span>Directory</span>}
                                >
                                  <CustomizedTreeView
                                    data={machineData[0].tree}
                                  />
                                </StyledTreeItem>
                              </TreeView>
                            ) : (
                              <span>Not Found</span>
                            )}
                          </TableCell>
                          {(userRole === "Customer" ||
                            userRole === "Admin" ||
                            userRole === "master") && (
                            <TableCell>
                              <button
                                onClick={() =>
                                  loadPasswords(row._id, row.source)
                                }
                                className="cardBtn"
                                style={{ display: "flex", padding: "8px 10px" }}
                              >
                                <span>Lookup</span>
                                {passListLoader ? (
                                  <LoaderIcon
                                    type={"spin"}
                                    style={{
                                      margin: "auto 2px",
                                      width: "18px",
                                      height: "18px",
                                    }}
                                  />
                                ) : (
                                  <FaSearch
                                    size={18}
                                    style={{ marginLeft: "2px" }}
                                  />
                                )}
                              </button>
                            </TableCell>
                          )}
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>

                  {machineData && machineData.length > 0 && (
                    <>
                      <>
                        <h6 className="lightText">
                          <BsEyeFill size={25} /> Initial Detection:{" "}
                          <span>
                            {machineData[0].firstSeen} (
                            {machineData[0].detected})
                          </span>
                        </h6>
                      </>
                      {machineData[0].applications &&
                        machineData[0].applications.length > 0 && (
                          <div
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <h6 className="lightText">
                              <MdSettingsApplications size={25} /> Applications
                              found:{" "}
                              {machineData[0].applications.map((app, index) => (
                                <span
                                  onClick={() => showApplication(app.name)}
                                  className="yellow-chip cursor"
                                  key={index}
                                >
                                  {app.name}
                                </span>
                              ))}
                              {machineData[0].applications.find(
                                (app) => app.ai_scenarios
                              ) && (
                                <div
                                  onClick={() =>
                                    showApplications(
                                      machineData[0].applications
                                    )
                                  }
                                  className="purple-chip cursor"
                                  style={{
                                    color: "white",
                                    borderRadius: "20px",
                                  }}
                                >
                                  <GiArtificialIntelligence size={25} /> AI
                                  Attack Scenarios →
                                </div>
                              )}
                            </h6>
                          </div>
                        )}

                      {machineData &&
                        machineData[0].installed_software &&
                        machineData[0].installed_software.length > 0 && (
                          <>
                            <h6 className="lightText">
                              <RiInstallFill size={25} /> Installed software:{" "}
                              <InstalledSoftware
                                data={machineData[0].installed_software}
                              />
                              <span
                                onClick={() => showSoftware()}
                                className="cursor"
                                style={{
                                  fontSize: "14px",
                                  marginLeft: "10px",
                                  // color: "#FBCD4E",
                                  color: "#222831",
                                  backgroundColor: "lightgray",
                                  padding: "5px 10px",
                                  borderRadius: "20px",
                                }}
                              >
                                View →
                              </span>
                              {<br />}
                            </h6>
                          </>
                        )}
                      {machineData &&
                        machineData[0].password_reuse &&
                        machineData[0].password_reuse !== "" && (
                          <>
                            <h6 className="lightText">
                              <MdOutlineWarning size={25} />{" "}
                              {machineData[0].password_reuse}
                            </h6>
                          </>
                        )}
                      {machineData &&
                        machineData[0].domainData &&
                        machineData[0].domainData.data && (
                          <>
                            <h6
                              className="lightText"
                              style={{ margin: "10px 0px" }}
                            >
                              <MdCorporateFare size={25} /> This computer has
                              been identified as a corporate machine
                              {<br />}
                            </h6>
                          </>
                        )}

                      {machineData[0] &&
                        machineData[0].top_logins &&
                        machineData[0].top_logins.length > 0 && (
                          <div
                            style={{
                              color: "black",
                              fontSize: "13px",
                            }}
                          >
                            <div
                              style={{
                                display: "inline-flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: "10px",
                              }}
                            >
                              <FaUserCircle size={20} className="credsIcons" />
                              <h6 style={{ marginBottom: "0px" }}>
                                Most Common Logins:
                              </h6>
                            </div>
                            <div
                              style={{
                                display: "inline-flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {machineData[0].top_logins.map((i) => (
                                <div
                                  style={{
                                    backgroundColor: "#222831",
                                    color: "#FBCD4E",
                                    borderRadius: "20px",
                                    padding: "4px 15px",
                                    margin: "0px 5px",
                                    display: "inline-flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  {props.user?.ui_permissions?.includes(
                                    "search-by-email"
                                  ) && emailRe.test(i.login) ? (
                                    <a
                                      href={`/search-by-email/${i.login}`}
                                      target="_blank"
                                      style={{
                                        textDecoration: "underline",
                                        color: "#FBCD4E",
                                      }}
                                    >
                                      {i.login} →
                                    </a>
                                  ) : (
                                    <div>{i.login}</div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                    </>
                  )}
                  <br />
                  <br />
                  {!viewLoading ? (
                    <>
                      <h3 className="lightText credTitle">
                        <span className="titleIcon">
                          <FaUnlockAlt size={30} />
                        </span>
                        {!externalView
                          ? `Corporate Credentials Found: ${machineData.length}`
                          : `Third Party Credentials Found: ${machineData.length}`}
                      </h3>
                      <Table
                        size="small"
                        aria-label="more"
                        className="credsTable"
                      >
                        <TableHead>
                          <TableRow className="credsRow">
                            <TableCell>
                              <span>
                                <GiWireframeGlobe
                                  size={20}
                                  className="credsIcons"
                                />
                              </span>
                              URL
                            </TableCell>
                            <TableCell>
                              <span>
                                <FaUserCircle
                                  size={20}
                                  className="credsIcons"
                                />
                              </span>
                              Login
                            </TableCell>
                            <TableCell>
                              <span>
                                <RiLockPasswordFill
                                  size={20}
                                  className="credsIcons"
                                />
                              </span>
                              Password
                            </TableCell>
                            <TableCell>
                              <span>
                                <GiSherlockHolmes
                                  size={20}
                                  className="credsIcons"
                                />
                              </span>
                              Password Strength
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {machineData.map((item, index) => (
                            <TableRow
                              className="credsRow credsTable"
                              key={index}
                            >
                              <TableCell>
                                {/* {item.url.split("•••••••••••")[0]}
                            <BlurryText value="•••••••••••" />
                            {item.url.split("•••••••••••")[1]} */}
                                {item.url}
                              </TableCell>
                              {props.user.ui_permissions &&
                              !props.user.ui_permissions.includes(
                                "usernames"
                              ) ? (
                                <TableCell>
                                  {item.login.includes("@") ? (
                                    <span>
                                      <BlurryText
                                        value={item.login.split("@")[0]}
                                      />
                                      @{item.login.split("@")[1]}
                                    </span>
                                  ) : (
                                    <BlurryText value={item.login} />
                                  )}
                                </TableCell>
                              ) : (
                                <TableCell>
                                  {item.login === "Not Found" ? (
                                    <div>
                                      {item.suggested_login ? (
                                        <>
                                          <Tooltip title="No login found in this credential, this login has the highest probability.">
                                            <span
                                              style={{
                                                textDecoration: "underline",
                                              }}
                                            >
                                              Suggested Login ↓
                                            </span>
                                          </Tooltip>
                                          <br />
                                          {props.user?.ui_permissions?.includes(
                                            "search-by-email"
                                          ) &&
                                          emailRe.test(item.suggested_login) ? (
                                            <a
                                              href={`/search-by-email/${item.suggested_login}`}
                                              target="_blank"
                                              style={{
                                                textDecoration: "underline",
                                              }}
                                            >
                                              {item.suggested_login} →
                                            </a>
                                          ) : (
                                            <span>{item.login}</span>
                                          )}
                                        </>
                                      ) : (
                                        <span>Not Found</span>
                                      )}
                                    </div>
                                  ) : props.user?.ui_permissions?.includes(
                                      "search-by-email"
                                    ) && emailRe.test(item.login) ? (
                                    <a
                                      href={`/search-by-email/${item.login}`}
                                      target="_blank"
                                      style={{ textDecoration: "underline" }}
                                    >
                                      {item.login} →
                                    </a>
                                  ) : (
                                    <span>{item.login}</span>
                                  )}
                                </TableCell>
                              )}
                              {item.blur ||
                              (props.user.ui_permissions &&
                                !props.user.ui_permissions.includes(
                                  "employee_passwords"
                                )) ? (
                                <TableCell>
                                  <BlurryText value={item.password} />
                                </TableCell>
                              ) : (
                                <TableCell>{item.password}</TableCell>
                              )}
                              <TableCell className={item.passStrength.class}>
                                {item.passStrength.value}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      {machineData[0] &&
                        machineData[0].cookies &&
                        machineData[0].cookies.length > 0 && (
                          <>
                            <hr style={{ borderTop: "2px black dashed" }} />
                            <h3 className="lightText credTitle">
                              <span className="titleIcon">
                                <FaCookieBite size={30} />
                              </span>
                              Domains With Sensitive Cookies Found:
                              {machineData[0].cookies.length}{" "}
                            </h3>
                            <Table
                              size="small"
                              aria-label="more"
                              className="credsTable"
                            >
                              <TableHead>
                                <TableRow className="credsRow">
                                  <TableCell>
                                    <span>
                                      <GiWireframeGlobe
                                        size={20}
                                        className="credsIcons"
                                      />
                                    </span>
                                    Domain
                                  </TableCell>
                                  <TableCell>
                                    <span>
                                      <FaCookie
                                        size={20}
                                        className="credsIcons"
                                      />
                                    </span>
                                    Cookies
                                  </TableCell>
                                  <TableCell>
                                    <span>
                                      <MdSchedule
                                        size={20}
                                        className="credsIcons"
                                      />
                                    </span>
                                    Valid Until
                                  </TableCell>
                                  <TableCell
                                    style={{ width: "0px" }}
                                  ></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {machineData[0].cookies.map((item, index) => (
                                  <>
                                    <TableRow
                                      className="credsRow credsTable"
                                      key={index}
                                      style={{
                                        borderTop: "2px black dashed",
                                      }}
                                    >
                                      <TableCell>
                                        <img
                                          src={`https://logo.clearbit.com/${item.domain}`}
                                          style={{
                                            width: "40px",
                                            borderRadius: "50px",
                                          }}
                                          onError={defaultSrc}
                                        />{" "}
                                        <span>{item.domain}</span>
                                      </TableCell>
                                      <TableCell>
                                        {item.cookies.length}
                                      </TableCell>
                                      <TableCell>
                                        {item.cookies[0].expiry}
                                      </TableCell>
                                      <TableCell>
                                        <div
                                          style={{
                                            backgroundColor: "#fdcd4e",
                                            display: "flex",
                                            borderRadius: "4px",
                                            padding: "8px 5px",
                                            justifyContent: "center",
                                          }}
                                          className="cursor"
                                          onClick={() =>
                                            toggleCookies(item.domain)
                                          }
                                        >
                                          VIEW ALL
                                          <BsFillArrowDownCircleFill
                                            size={20}
                                            style={{ marginLeft: "5px" }}
                                          />
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                    {cookiesDomain === item.domain && (
                                      <TableRow
                                        style={{ backgroundColor: "#fbcd4e" }}
                                        className={
                                          cookiesDomain === item.domain
                                            ? "activeRow expandedRow"
                                            : "expandedRow"
                                        }
                                      >
                                        <TableCell
                                          style={{
                                            paddingBottom: 0,
                                            paddingTop: 0,
                                          }}
                                          colSpan={12}
                                        >
                                          <Collapse
                                            in={cookiesDomain === item.domain}
                                            timeout="auto"
                                            unmountOnExit
                                            style={{
                                              boxShadow:
                                                "inset 0 0 10px #000000",
                                            }}
                                          >
                                            <Box
                                              margin={1}
                                              style={{
                                                height: "250px",
                                                margin: "30px",
                                                backgroundColor: "#fbcd4e",
                                                overflowY: "scroll",
                                              }}
                                            >
                                              {" "}
                                              <Table
                                                size="small"
                                                aria-label="more"
                                                className="credsTable"
                                              >
                                                <TableHead>
                                                  <TableRow className="credsRow credsRowYellow">
                                                    <TableCell>
                                                      <span>
                                                        <GiWireframeGlobe
                                                          size={20}
                                                          className="credsIcons"
                                                        />
                                                      </span>
                                                      URL
                                                    </TableCell>
                                                    <TableCell>
                                                      <span>
                                                        <FaCookie
                                                          size={20}
                                                          className="credsIcons"
                                                        />
                                                      </span>
                                                      Name
                                                    </TableCell>
                                                    <TableCell>
                                                      <span>
                                                        <MdPassword
                                                          size={20}
                                                          className="credsIcons"
                                                        />
                                                      </span>
                                                      Value
                                                    </TableCell>
                                                    <TableCell>
                                                      <span>
                                                        <MdSchedule
                                                          size={20}
                                                          className="credsIcons"
                                                        />
                                                      </span>
                                                      Expiry
                                                    </TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {item.cookies.map(
                                                    (_item, index) => (
                                                      <TableRow
                                                        className="credsRow credsTable credsRowYellow"
                                                        key={index}
                                                      >
                                                        <TableCell>
                                                          {_item.url}
                                                        </TableCell>
                                                        <TableCell>
                                                          {_item.cookie_name}
                                                        </TableCell>
                                                        {machineData[0]
                                                          .cookie_blur ? (
                                                          <TableCell>
                                                            <BlurryText
                                                              value={
                                                                _item.value
                                                              }
                                                            />
                                                          </TableCell>
                                                        ) : (
                                                          <TableCell>
                                                            {_item.value}
                                                          </TableCell>
                                                        )}
                                                        <TableCell>
                                                          {_item.expiry}
                                                        </TableCell>
                                                      </TableRow>
                                                    )
                                                  )}
                                                </TableBody>
                                              </Table>
                                            </Box>
                                          </Collapse>
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </>
                                ))}
                              </TableBody>
                            </Table>
                          </>
                        )}
                    </>
                  ) : (
                    <LoaderIcon type="spin" />
                  )}
                </div>
              ) : (
                <LoaderIcon
                  className="tableLoader"
                  type={"spin"}
                  style={{ marginTop: "10rem" }}
                />
              )}
            </TabPanel>
          ))}
          {open && (
            <SimpleModal
              halfHeight={modalState !== "passwords"}
              halfWidth={modalState !== "passwords"}
              open={open}
              handleClose={handleClose}
              bg={modalState === "applications" && "#222831"}
              borderRadius={modalState === "applications" && "10px"}
              // flex={modalState === "applications" && true}
              html={
                modalState === "passwords"
                  ? passwordsHtml
                  : modalState === "application"
                  ? applicationHtml
                  : modalState === "applications"
                  ? applicationsHtml
                  : softwareHtml
              }
            />
          )}
        </div>
      )}
    </>
  );
}
