import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import React from "react";

export default function ToggleButtons(props) {
  return (
    <ToggleButtonGroup
      value={props.value}
      exclusive
      onChange={(e, newValue) => props.onChange(e, newValue)}
      aria-label={props.ariaLabel}
    >
      {props.options.map((item, index) => (
        <ToggleButton
          key={index}
          value={item.value}
          aria-label={item.label}
          onClick={(e) => e.stopPropagation()}
        >
          {item.value}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
