import React from "react";
import thumbnail from "../images/thumbnail.jpeg";

export default function StackedBar(props) {
  const defaultSrc = (ev) => {
    ev.target.src = thumbnail;
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div
      className={
        props.block === "domains"
          ? "figureWrapper"
          : "figureWrapper urlFigureWrapper"
      }
      style={{
        backgroundColor: props.bg,
        margin: props.customMargin && props.customMargin,
        color: props.color && props.color,
      }}
    >
      <figure
        style={{ marginBottom: "2rem" }}
        className={props.domain.domain && "cursor"}
        onClick={() => {
          props.domain.domain && props.getUrlsStrength();
        }}
      >
        <div className="graphic">
          <div className="row">
            <div
              className={props.onClick ? "cursor" : ""}
              onClick={props.onClick}
            >
              <img
                className="desktop"
                id="logoImg"
                style={{
                  backgroundColor: "#1A1C20",
                  border: "0px",
                  width: "40px",
                  height: "40px",
                  borderRadius: "50px",
                  marginRight: "5px",
                }}
                src={
                  props.logo
                    ? props.logo
                    : `https://logo.clearbit.com/${
                        props.domain.domain ? props.domain.domain : props.domain
                      }`
                }
                onError={defaultSrc}
                alt="logo"
              />
              <h6 style={{ margin: "0px", wordBreak: "break-all" }}>
                {props.domain.domain ? props.domain.domain : props.domain} (
                {numberWithCommas(props.arr.totalPass)}+ passwords)
              </h6>
              {props.exportBtn && props.domain.domain && props.exportBtn}
              {props.domain && props.expandBtn}
            </div>

            {/* <div className="chart" style={{ minWidth: "100%" }}>
            <span
              className="block"
              title="Category A"
              style={{
                maxWidth: `${props.arr.too_weak.perc * 7}px`,
                width:
                  props.arr.too_weak.perc > 10
                    ? `${props.arr.too_weak.perc + window.innerWidth / 12}px`
                    : "60px",
              }}
            >
              <span className="value">{props.arr.too_weak.perc}%</span>
            </span>
            <span
              className="block"
              title="Category B"
              style={{
                maxWidth: `${props.arr.weak.perc * 7}px`,
                width:
                  props.arr.weak.perc > 10
                    ? `${props.arr.weak.perc + window.innerWidth / 12}px`
                    : "60px",
              }}
            >
              <span className="value">{props.arr.weak.perc}%</span>
            </span>
            <span
              className="block"
              title="Category C"
              style={{
                maxWidth: `${props.arr.medium.perc * 7}px`,
                width:
                  props.arr.medium.perc > 10
                    ? `${props.arr.medium.perc + window.innerWidth / 12}px`
                    : "60px",
              }}
            >
              <span className="value">{props.arr.medium.perc}%</span>
            </span>
            <span
              className="block"
              title="Category D"
              style={{
                maxWidth: `${props.arr.strong.perc * 7}px`,
                width:
                  props.arr.strong.perc > 10
                    ? `${props.arr.strong.perc + window.innerWidth / 12}px`
                    : "60px",
              }}
            >
              <span className="value">{props.arr.strong.perc}%</span>
            </span>
          </div> */}
            <div
              className={
                props.domain.domain || props.block === "domains"
                  ? "chart"
                  : "chart urlsChart"
              }
              style={{
                minWidth: "95%",
                borderRadius: "50px",
              }}
            >
              {Number(props.arr.too_weak.perc) > 0.5 && (
                <div
                  className="absoluteValue firstValue"
                  style={{ left: props.arr.too_weak.perc < 5 ? "2%" : "3.5%" }}
                >
                  {Number(props.arr.too_weak.perc).toFixed(1)}%
                </div>
              )}
              {Number(props.arr.too_weak.perc) > 0.5 && (
                <span
                  className={
                    props.domain.domain || props.domain
                      ? "block firstCategory"
                      : "block firstCategory urlsBlock"
                  }
                  title="Category A"
                  style={{
                    width: `${props.arr.too_weak.perc.toFixed(1) - 0.1}%`,
                    borderRadius: props.arr.too_weak.perc > 99 && "50px",
                  }}
                ></span>
              )}
              {Number(props.arr.weak.perc) > 0.5 && (
                <div
                  className="absoluteValue secondValue"
                  style={{
                    left: `${
                      props.arr.too_weak.perc < 5
                        ? props.arr.too_weak.perc + props.arr.weak.perc / 2 + 5
                        : props.arr.too_weak.perc + props.arr.weak.perc / 2
                    }%`,
                  }}
                >
                  {Number(props.arr.weak.perc).toFixed(1)}%
                </div>
              )}
              {Number(props.arr.weak.perc) > 0.5 && (
                <span
                  className={
                    props.domain.domain || props.domain
                      ? "block secondCategory"
                      : "block secondCategory urlsBlock"
                  }
                  title="Category B"
                  style={{
                    width: `${props.arr.weak.perc.toFixed(1) - 0.1}%`,
                    borderRadius: props.arr.weak.perc > 99 && "50px",
                  }}
                ></span>
              )}
              {Number(props.arr.medium.perc) > 0.5 && (
                <div
                  className="absoluteValue thirdValue"
                  // style={{
                  //   right: `${
                  //     props.arr.strong.perc + props.arr.medium.perc / 2
                  //   }%`,
                  // }}
                  style={{
                    right: `${
                      props.arr.strong.perc < 5
                        ? props.arr.strong.perc + props.arr.medium.perc / 2 + 5
                        : props.arr.strong.perc + props.arr.medium.perc / 2
                    }%`,
                  }}
                >
                  {Number(props.arr.medium.perc).toFixed(1)}%
                </div>
              )}
              {Number(props.arr.medium.perc) > 0.5 && (
                <span
                  className={
                    props.domain.domain || props.domain
                      ? "block thirdCategory"
                      : "block thirdCategory urlsBlock"
                  }
                  title="Category C"
                  style={{
                    width: `${props.arr.medium.perc.toFixed(1) - 0.1}%`,
                    borderRadius: props.arr.medium.perc > 99 && "50px",
                  }}
                ></span>
              )}
              {Number(props.arr.strong.perc) > 0.5 && (
                <div
                  className="absoluteValue fourthValue"
                  style={{ right: props.arr.strong.perc < 5 ? "2%" : "3.5%" }}
                >
                  {Number(props.arr.strong.perc).toFixed(1)}%
                </div>
              )}
              {Number(props.arr.strong.perc) > 0.5 && (
                <span
                  className={
                    props.domain.domain || props.domain
                      ? "block fourthCategory"
                      : "block fourthCategory urlsBlock"
                  }
                  title="Category D"
                  style={{
                    width: `${props.arr.strong.perc.toFixed(1) - 0.1}%`,
                    borderRadius: props.arr.strong.perc > 99 && "50px",
                  }}
                ></span>
              )}
            </div>
          </div>
        </div>
      </figure>
      {props.child}
    </div>
  );
}
