import React, { useEffect, useState } from "react";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const Card = (props) => {
  return (
    <li
      className={`card delay${props.index + 1} cursor animate`}
      style={{
        backgroundColor: "#fecd4e",
        borderRadius: "20px",
        border: "0px",
      }}
    >
      <div
        style={{
          display: "block",
          borderRadius: "20px",
          backgroundColor: "#fecd4e",
          padding: "10px 10px 0px",
        }}
        onClick={(e) => {
          props.onClick(e, props.domain);
        }}
      >
        {props.img}
        <p
          className={`delay${props.index + 1} lightText animate`}
          style={{
            margin: "4px 0px",
            wordBreak: "break-all",
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          {props.domain}
        </p>
        {props.topEmps ? (
          <p
            className={`delay${props.index + 1} animate`}
            style={{ margin: "4px 0px" }}
          >
            {props.topEmps.find((s) => s.domain === props.domain) && (
              <span
                style={{
                  backgroundColor: "#222831",
                  color: "#fecd4e",
                  borderRadius: "20px",
                  padding: "4px 10px",
                  margin: "0px",
                }}
              >
                {numberWithCommas(props.count)}+
              </span>
            )}
            <br />
            <div style={{ display: "grid" }}>
              <span style={{ color: "#222831", fontSize: "16px" }}>
                Compromised
              </span>
              <span style={{ color: "#222831", fontSize: "16px" }}>
                Employees
              </span>
            </div>
          </p>
        ) : (
          props.topUsers && (
            <p
              className={`delay${props.index + 1} animate`}
              style={{ margin: "4px 0px" }}
            >
              {props.topUsers.find((s) => s.domain === props.domain) && (
                <span
                  style={{
                    backgroundColor: "#222831",
                    color: "#fecd4e",
                    borderRadius: "20px",
                    padding: "4px 10px",
                    margin: "0px",
                  }}
                >
                  {numberWithCommas(
                    props.topUsers.find((s) => s.domain === props.domain).count
                  )}
                  +
                </span>
              )}
              <br />
              <span style={{ color: "#222831" }}>
                Compromised <br /> Users
              </span>
            </p>
          )
        )}
      </div>
      <a
        href={`/search-by-domain/${props.domain}`}
        style={{
          borderRadius: "0px 0px 15px 15px",
          padding: "5px 15px",
          backgroundColor: "#222831",
          color: "#FECD4E",
          border: "0px",
          width: "100%",
        }}
      >
        View Results
      </a>
    </li>
  );
};

const Carousel = (props) => {
  const [moveClass, setMoveClass] = useState("");
  const [carouselItems, setCarouselItems] = useState(
    props.topEmps ? props.topEmps : props.topUsers
  );

  useEffect(() => {
    setCarouselItems(props.topEmps ? props.topEmps : props.topUsers);
  }, [props.topEmps, props.topUsers]);

  useEffect(() => {
    document.documentElement.style.setProperty("--num", carouselItems.length);
  }, [carouselItems]);

  const handleAnimationEnd = () => {
    if (moveClass === "prev") {
      shiftNext([...carouselItems]);
    } else if (moveClass === "next") {
      shiftPrev([...carouselItems]);
    }
    setMoveClass("");
  };

  const shiftPrev = (copy) => {
    let lastcard = copy.pop();
    copy.splice(0, 0, lastcard);
    setCarouselItems(copy);
  };

  const shiftNext = (copy) => {
    let firstcard = copy.shift();
    copy.splice(copy.length, 0, firstcard);
    setCarouselItems(copy);
  };

  return (
    <div className="carouselwrapper module-wrapper">
      <div className="ui">
        <button onClick={() => setMoveClass("next")} className="prev">
          <span className="material-icons">chevron_left</span>
        </button>
        <button onClick={() => setMoveClass("prev")} className="next">
          <span className="material-icons">chevron_right</span>
        </button>
      </div>
      <ul
        onAnimationEnd={handleAnimationEnd}
        className={`${moveClass} carousel`}
      >
        {carouselItems &&
          carouselItems.length > 0 &&
          carouselItems.map((t, index) => (
            <Card
              onClick={(e, domain) => props.onClick(e, domain)}
              key={index}
              domain={t.domain}
              count={t.count}
              index={index}
              topEmps={props.topEmps}
              topUsers={props.topUsers}
              img={
                <img
                  alt="logo"
                  src={t.logo}
                  className={`animate delay${props.index + 1}`}
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "60px",
                  }}
                  key={props.index}
                ></img>
              }
            />
          ))}
      </ul>
    </div>
  );
};

export default Carousel;
