/* eslint-disable */

import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles } from "@material-ui/core/styles";
import BarChartIcon from "@material-ui/icons/BarChart";
import { animated, useSpring } from "@react-spring/web"; // web.cjs is required for IE 11 support
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { AiFillDatabase } from "react-icons/ai";
import { DiAndroid } from "react-icons/di";
import { FaUserTie, FaUsers } from "react-icons/fa";
import { GiWireframeGlobe } from "react-icons/gi";
import { ImCheckmark, ImCross } from "react-icons/im";
import LoaderIcon from "react-loader-icon";
import MapGL, {
  FullscreenControl,
  GeolocateControl,
  NavigationControl,
  Popup,
  ScaleControl,
} from "react-map-gl";
import CreateApiClient from "../API";
import { MapApi } from "../Config";
import BarChart from "./BarChart";
import CityInfo from "./CityInfo";
import LineChart from "./LineChart";
import Pins from "./MarkersMap";
import Container from "./ProgressBar";

/* eslint import/no-webpack-loader-syntax: off */
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const api = CreateApiClient();

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: "translate3d(20px,0,0)" },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function ThirdPartyTabs(props) {
  const [machineDetails, setMachineDetails] = useState([]);
  const [machineData, setMachineData] = useState([]);
  const [type, setType] = useState("employees");
  const [loading, isLoading] = useState(true);
  const [activeDomain, setActiveDomain] = useState("");
  const [employeesStats, setEmployeesStats] = useState([]);
  const [clientsStats, setClientsStats] = useState([]);
  const [timeLabels, setTimeLabels] = useState([]);
  const [counts, setCounts] = useState([]);
  const currentYear = new Date().getFullYear();
  const [employeesUrls, setEmployeesUrls] = useState([]);
  const [clientsUrls, setClientsUrls] = useState([]);
  const [employeesUrlsCount, setEmployeesUrlsCount] = useState([]);
  const [clientsUrlsCount, setClientsUrlsCount] = useState([]);
  const [cities, setCities] = useState([]);
  const [employeesCities, setEmployeesCities] = useState([]);
  const [clientsCities, setClientsCities] = useState([]);
  const [section, setSection] = useState("");
  const [monthly, isMonthly] = useState(true);
  const [year, setYear] = React.useState("All Time");
  const [yearsOptions, setYearsOptions] = useState([]);
  const [chartLoader, setChartLoader] = useState(false);
  const [pieLoader, setPieLoader] = useState(false);
  const [mapLoading, setMapLoading] = useState(false);
  const userRole = props.userRole;
  // const selectedDomain = props.monitoredDomains;
  const tableData = props.data;

  useEffect(() => {
    if (tableData.length > 0) {
      handleCollapse(tableData[0].domain, tableData[0].domain);
    }
  }, []);

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCollapse = async (id, _id) => {
    setType("employees");
    setActiveDomain(id);
    isLoading(true);
    const config = {
      headers: {
        "auth-token": props.token,
      },
    };

    getDomainData(config);

    async function getDomainData(config) {
      const counts = await api.fetchData(
        "counts/domain",
        {
          domain: id,
          id: _id,
        },
        config
      );
      const obj = { counts: counts };
      await getStats(
        id,
        `stats?frame=all&currentyear=${currentYear}`,
        props.user
      );
      await getUrls(id, "stats/urls", props.user);
      await getMapData(id, "stats/ips?type=employees", props.user);
      setMachineData(obj);
      isLoading(false);
    }

    const shownState = machineDetails.slice();
    const index = shownState.indexOf(id);

    if (index > 0) {
      shownState.splice(index, 1);
      setMachineDetails(shownState);
    } else {
      shownState.pop();
      shownState.push(id);
      setMachineDetails(shownState);
    }
  };

  const getStats = async (domain, query, user) => {
    setChartLoader(true);
    if (query === `stats?frame=monthly&year=${year}`) {
      isMonthly(true);
    }
    setSection(query);

    let config = {
      headers: {
        "auth-token": user.Token,
      },
    };

    let body = {
      domain: domain,
    };

    const response = await api.fetchData(query, body, config);
    if (response) {
      if (response.datasets && response.datasets.length > 0) {
        setCounts(response.datasets);
      }
      setYearsOptions(response.years);
      setTimeLabels(response.labels);
      setEmployeesStats(response.employeesCount);
      setClientsStats(response.clientsCount);
    } else {
      setCounts([]);
      setTimeLabels([]);
      setEmployeesStats([]);
      setClientsStats([]);
    }
    setChartLoader(false);
  };

  const handleYearChange = (event) => {
    if (event.target.value === "All Time") {
      switchToAllTimeChart();
    } else {
      setTimelineChart(activeDomain, event.target.value, props.user);
    }
    setYear(event.target.value);
  };

  const switchToAllTimeChart = () => {
    setAllTimeChart(props.user);
    isMonthly(false);
  };

  const switchToMonthlyChart = () => {
    isMonthly(true);
    getStats(activeDomain, `stats?frame=monthly&year=${year}`, props.user);
  };

  const setTimelineChart = (domain, year, loggedInUser) => {
    getStats(domain, `stats?frame=monthly&year=${year}`, loggedInUser);
  };

  const setAllTimeChart = (loggedInUser) => {
    getStats(
      activeDomain,
      `stats?frame=all&currentyear=${currentYear}`,
      loggedInUser
    );
  };

  const getUrls = async (domain, query, user) => {
    setPieLoader(true);
    let config = {
      headers: {
        "auth-token": user.Token,
      },
    };

    let body = {
      domain: domain,
    };

    const response = await api.fetchData(query, body, config);
    setEmployeesUrls(response.employees_urls);
    setEmployeesUrlsCount(response.employees_count);
    setClientsUrls(response.clients_urls);
    setClientsUrlsCount(response.clients_count);
    setPieLoader(false);
  };

  const getMapData = async (domain, query, user) => {
    setMapLoading(true);
    let config = {
      headers: {
        "auth-token": user.Token,
      },
    };
    const body = {
      domain: domain,
    };
    const response = await api.fetchData(query, body, config);
    if (response.length > 0) {
      setCities(response);
      splitIps(response);
    }
    setMapLoading(false);
  };

  const splitIps = (data) => {
    let employeesCities = [];
    let clientsCities = [];
    data.map((item) => {
      item.type === "employee"
        ? employeesCities.push(item)
        : clientsCities.push(item);
    });
    setEmployeesCities(employeesCities);
    setClientsCities(clientsCities);
  };

  const counters = machineData.counts && [
    {
      label: (
        <div className="counter tabsCounter">
          <CountUp
            separator=","
            start={0}
            end={machineData.counts.stealers}
            duration={2.75}
          />
          Total <br /> Stealers
        </div>
      ),
      icon: <BarChartIcon className="materialIcons" id="firstIcon" />,
      count: machineData.counts.stealers,
      path: "overview",
      class: "overviewTab",
    },
    {
      label: (
        <div className="counter tabsCounter">
          <CountUp
            separator=","
            start={0}
            end={machineData.counts.employees}
            duration={2.75}
          />
          Compromised <br /> Employees
        </div>
      ),
      icon: <FaUserTie size={30} className="counterIcon" />,
      count: machineData.counts.employees,
      class: "employeeHorizontalTab",
    },
    {
      label: (
        <div className="counter tabsCounter">
          <CountUp
            separator=","
            start={0}
            end={machineData.counts.clients}
            duration={2.75}
          />
          Compromised <br /> Users
        </div>
      ),
      icon: <FaUsers size={30} className="counterIcon" />,
      count: machineData.counts.clients,
      path: "clients",
      class: "clientsTab",
    },
    {
      label: (
        <div className="counter tabsCounter">
          {typeof machineData.counts.androids !== "boolean" ? (
            <CountUp
              separator=","
              start={0}
              end={machineData.counts.androids}
              duration={2.75}
            />
          ) : (
            <div>
              {machineData.counts.androids === true ? (
                <ImCheckmark className="iconIndicator" size={25} />
              ) : (
                <ImCross className="iconIndicator" size={25} />
              )}
            </div>
          )}
          Compromised <br /> App Users
        </div>
      ),
      icon: <DiAndroid size={30} className="counterIcon" />,
      count: machineData.counts.androids,
      path: "androids",
      class: "appsTab",
    },
    {
      label: (
        <div className="counter tabsCounter">
          <CountUp
            separator=","
            start={0}
            end={machineData.counts.dbs}
            duration={2.75}
          />
          Compromised <br /> DBs Accounts
        </div>
      ),
      icon: <AiFillDatabase size={30} className="counterIcon" />,
      count: machineData.counts.dbs,
      path: "databases",
      class: "dbsTab",
    },
  ];

  const geolocateStyle = {
    top: 0,
    left: 0,
    padding: "10px",
  };

  const fullscreenControlStyle = {
    top: 36,
    left: 0,
    padding: "10px",
  };

  const navStyle = {
    top: 72,
    left: 0,
    padding: "10px",
  };

  const scaleControlStyle = {
    bottom: 36,
    left: 0,
    padding: "10px",
  };

  const [viewport, setViewport] = useState({
    latitude: 20,
    longitude: 0,
    zoom: 1.5,
    bearing: 0,
    pitch: 0,
  });
  const [popupInfo, setPopupInfo] = useState(null);

  const [settings, setSettings] = useState({
    scrollZoom: false,
    dragRotate: false,
    // dragPan: false,
  });

  const closeOnMapClick = () => {
    popupInfo && setPopupInfo(null);
  };

  const handleMapChange = async (e) => {
    setType(e.target.value);
    await getMapData(
      activeDomain,
      `stats/ips?type=${e.target.value}`,
      props.user
    );
    popupInfo && setPopupInfo(null);
  };

  return (
    <>
      {props.data.length > 0 ? (
        <div className={classes.root + " employeesTable thirdPartyTable"}>
          <Tabs
            id="employeesTabs"
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.tabs + " employeeTabsList"}
          >
            {props.data.map((row, index) => (
              <Tab
                className="employeeTab"
                key={index}
                onClick={() => handleCollapse(row.domain, row.domain)}
                label={
                  <ul className="employee-nav">
                    <li>
                      <GiWireframeGlobe size={20} className="credsIcons" />
                      <span className="employee-label">Domain:</span>
                      <span>{row.domain}</span>
                    </li>
                    <li>
                      <span className="employee-label">
                        {row.count} Compromised Machines
                      </span>
                    </li>
                  </ul>
                }
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
          {props.data.map((row, index) => (
            <TabPanel
              value={value}
              index={index}
              key={row.domain}
              className="tabPanel"
              id="contentTab"
            >
              {machineDetails && machineData.counts && !loading ? (
                <>
                  <AppBar position="static" className="thirdPartyAppBar">
                    <Tabs
                      id="thirdPartyHorizontalTabs"
                      value={value}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="scrollable force tabs"
                    >
                      {machineData.counts.stealers > 0 ? (
                        counters.map((counter, index) => (
                          <Tab
                            className={counter.class}
                            key={index}
                            label={counter.label}
                            icon={counter.icon}
                            disabled
                          />
                        ))
                      ) : props.loading ? (
                        <LoaderIcon
                          className="homeLoader"
                          type={"spinningBubbles"}
                        />
                      ) : (
                        <h1>No Results</h1>
                      )}
                    </Tabs>
                  </AppBar>
                  {!props.loading &&
                  machineData.counts.stealers &&
                  machineData.counts.stealers > 0 ? (
                    <div className="thirdPartyStats">
                      {chartLoader ? (
                        <LoaderIcon
                          className="tableLoader chartLoader"
                          type={"spinningBubbles"}
                        />
                      ) : (
                        <LineChart
                          labelColor="#1a1c20"
                          domains={activeDomain}
                          yearsOptions={yearsOptions}
                          monthly={monthly}
                          switchToAllTimeChart={switchToAllTimeChart}
                          switchToMonthlyChart={switchToMonthlyChart}
                          handleYearChange={handleYearChange}
                          year={year}
                          employeesStats={employeesStats}
                          clientsStats={clientsStats}
                          data={counts}
                          labels={timeLabels}
                          loading={loading}
                          stealersCount={machineData.counts.stealers}
                        />
                      )}

                      <div className="donuts">
                        <div className="leftCol">
                          <BarChart
                            height={20}
                            width={100}
                            labelColor="#1a1c20"
                            screen="thirdParty"
                            data={employeesUrls}
                            dataCount={employeesUrlsCount}
                            loading={loading}
                            type="(Employees)"
                          />
                        </div>
                        <div className="rightCol">
                          <BarChart
                            height={20}
                            width={100}
                            labelColor="#1a1c20"
                            screen="thirdParty"
                            data={clientsUrls}
                            dataCount={clientsUrlsCount}
                            loading={loading}
                            type="(Users)"
                          />
                        </div>
                      </div>

                      <div className="map">
                        <h1 id="geoTitle" className="chartTitle">
                          Geo’s of Compromised Computers{" "}
                        </h1>
                        <FormControl component="fieldset" className="inputs">
                          <RadioGroup
                            onChange={handleMapChange}
                            row
                            aria-label="position"
                            name="position"
                            value={type ? type : "employees"}
                          >
                            <FormControlLabel
                              value="employees"
                              control={<Radio color="primary" />}
                              label={
                                <div className="typeIcon">
                                  <FaUserTie size={22} className="" />
                                  Employees
                                </div>
                              }
                              labelPlacement="top"
                            />
                            <FormControlLabel
                              value="users"
                              control={<Radio color="primary" />}
                              label={
                                <div className="typeIcon">
                                  <FaUsers size={25} className="" />
                                  Users
                                </div>
                              }
                              labelPlacement="top"
                            />
                          </RadioGroup>
                        </FormControl>
                        <hr></hr>
                        {mapLoading ? (
                          <LoaderIcon
                            className="tableLoader chartLoader"
                            type={"spinningBubbles"}
                          />
                        ) : (
                          <MapGL
                            {...viewport}
                            {...settings}
                            width="100%"
                            height="500px"
                            mapStyle="mapbox://styles/mapbox/light-v9"
                            onViewportChange={setViewport}
                            mapboxApiAccessToken={MapApi}
                            onClick={closeOnMapClick}
                          >
                            <Pins
                              data={cities}
                              onClick={setPopupInfo}
                              clientsCities={clientsCities}
                              employeesCities={employeesCities}
                              type={type}
                            />
                            {popupInfo && (
                              <Popup
                                tipSize={5}
                                anchor="top"
                                longitude={popupInfo.longitude}
                                latitude={popupInfo.latitude}
                                city={popupInfo.city}
                                country={popupInfo.country}
                                region={popupInfo.region}
                                timezone={popupInfo.timezone}
                                isp={popupInfo.isp}
                                proxy={popupInfo.proxy}
                                closeOnClick={false}
                                onClose={setPopupInfo}
                              >
                                <CityInfo info={popupInfo} type={type} />
                              </Popup>
                            )}

                            <GeolocateControl style={geolocateStyle} />
                            <FullscreenControl style={fullscreenControlStyle} />
                            <NavigationControl style={navStyle} />
                            <ScaleControl style={scaleControlStyle} />
                          </MapGL>
                        )}
                      </div>
                    </div>
                  ) : props.loading ? (
                    <Container />
                  ) : (
                    <h1>No Results</h1>
                  )}
                </>
              ) : (
                <div className="loaderDiv">
                  <LoaderIcon
                    className="tableLoader"
                    type={"spinningBubbles"}
                  />
                  <h1 className="lightText loaderText">
                    Loading Data, Please Wait...
                  </h1>
                </div>
              )}
            </TabPanel>
          ))}
        </div>
      ) : (
        <>
          {userRole === "Admin" || userRole === "MSSP" ? (
            <>
              {!props.onSearch ? "" : <h2>No Results, Try Another Domain</h2>}
            </>
          ) : (
            <>
              <h2>No Results, Try Another Domain</h2>
            </>
          )}
        </>
      )}
    </>
  );
}
