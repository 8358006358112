import React from "react";
import AppBarNew from "../AppBar";
import ContactForm from "../ContactForm";
import SimpleAccordion from "../FaqAccordion";
import Footer from "../partials/Footer";

export default function Help(props) {
  return (
    <div className="App">
      {props.user && (
        <AppBarNew
          className="mobileHeader"
          user={props.user}
          role={props.role}
          token={props.token}
          email={props.email}
          domains={props.domains}
          handleLogOut={props.handleHomeLogOut}
        />
      )}
      <div className="help-page flex-wrapper">
        <div
          style={{
            minHeight: "85vh",
            padding: "140px 100px",
          }}
        >
          <div style={{ width: "60%", float: "left" }}>
            <SimpleAccordion />
          </div>
          <div style={{ width: "40%", float: "right" }}>
            <ContactForm />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
