import axios from "axios";
import React from "react";
import { errorsUrl } from "../Config";
import logo from "../images/hrlogo_new.png";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    axios
      .post(errorsUrl, { error: error.toString(), info: info })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          className="App"
          style={{
            textAlign: "center",
            padding: "10rem",
          }}
        >
          <img src={logo} style={{ width: "400px" }} alt="logo" />
          <br />
          <div style={{ padding: "5rem" }}>
            <h3>
              Oops! Something went wrong. We're sorry for the inconvenience. Our
              team is aware of the issue and is working hard to fix it as soon
              as possible. In the meantime, please try refreshing the page or
              come back later. Thank you for your patience and understanding.
            </h3>
          </div>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
