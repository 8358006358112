import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { auth0Address } from "../Config";
import Overlay from "./Overlay";
import Container from "./ProgressBar";

function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      className="copyrights"
    >
      {"Copyright © "}
      <Link color="inherit" href="https://hudsonrock.com/">
        Hudson Rock
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  // image: {
  //   backgroundImage: { cavaLogo },
  //   backgroundRepeat: "no-repeat",
  //   backgroundColor:
  //     theme.palette.type === "light"
  //       ? theme.palette.grey[50]
  //       : theme.palette.grey[900],
  //   backgroundSize: "cover",
  //   backgroundPosition: "center",
  // },
  paper: {
    margin: theme.spacing(14, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignInSide(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    window.location.href = `${auth0Address}/sso/auth0/logout`;
    setLoading(false);
  }, []);

  return (
    <div>
      {props.digitsLoading && <Overlay login={true} />}
      {/* <PrimarySearchAppBar /> */}
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        {/* <Grid
          id="loginImg"
          item
          xs={false}
          sm={4}
          md={7}
          className={classes.image}
        /> */}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          component={Paper}
          elevation={12}
          square
          className="loginPage"
        >
          <div style={{ display: "block", width: "100%" }}>
            <Container redirect={false} />
          </div>
          {/* <div className={classes.paper + " loginDiv"}>
            <img className="loginLogo" src={logo} alt="logo" />
            <Typography className="signInText" component="h1" variant="h3">
              Sign In
            </Typography>
            <form
              id="loginForm"
              onSubmit={props.login}
              className={classes.form}
              noValidate
            >
              <TextField
                className="loginInput"
                type="email"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                onChange={props.email}
                autoComplete="email"
                autoFocus
              />
              <TextField
                className="loginInput"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                onChange={props.password}
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {props.totp ? (
                <SixDigits
                  checkDigits={(digits) => props.checkDigits(digits)}
                  digitsLoading={props.digitsLoading}
                  theme="dark"
                />
              ) : (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit + " searchBtn"}
                >
                  Sign In
                </Button>
              )}
              <br />
              <br />
              <div style={{ margin: "auto" }} className="text-center">
                <h4 className="darkText">OR</h4>
              </div>
              <br />
              <MicrosoftSignIn />
              <Grid container>
                <Grid item xs>
                  <p className="error">{props.error}</p>
                </Grid>
              </Grid>
              <ChangePass reset={true} />
              <Box mt={5} className="loginBottomBox">
                <Copyright />
              </Box>
            </form>
          </div> */}
        </Grid>
      </Grid>
    </div>
  );
}
