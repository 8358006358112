import React, { useState } from "react";
import {
  FaChartLine,
  FaClone,
  FaDatabase,
  FaExternalLinkAlt,
  FaUserPlus,
} from "react-icons/fa";
import { SiMinutemailer } from "react-icons/si";
import AppBarNew from "../AppBar";
import Analytics from "./Analytics";
import BulkUserEdit from "./BulkUserEdit";
import CloneUser from "./CloneUser";
import ExternalDomains from "./ExternalDomains";
import Sidebar from "./Sidebar";
import UserCreation from "./UserCreation";
import Newsletter from "./newsletter/Newsletter";

export default function AdminTools(props) {
  const [activeTab, setActiveTab] = useState(0);

  const toolsMenu = [
    {
      name: "register",
      label: "CREATE NEW USER",
      icon: <FaUserPlus />,
      component: <UserCreation token={props.token} />,
    },
    {
      name: "clone",
      label: "CLONE USER",
      icon: <FaClone />,
      component: <CloneUser token={props.token} />,
    },
    {
      name: "externals",
      label: "EXTERNAL DOMAINS",
      icon: <FaExternalLinkAlt />,
      component: (
        <ExternalDomains
          token={props.token}
          user={props.user}
        ></ExternalDomains>
      ),
    },
    {
      name: "bulk-edit",
      label: "BULK EDIT",
      icon: <FaDatabase />,
      component: <BulkUserEdit token={props.token} />,
    },
    {
      name: "analytics",
      label: "ANALYTICS",
      icon: <FaChartLine />,
      component: <Analytics token={props.token} user={props.user} />,
    },
    {
      name: "newsletter",
      label: "NEWSLETTER (INFOSTEALERS)",
      icon: <SiMinutemailer />,
      component: <Newsletter token={props.token} user={props.user} />,
    },
  ];

  return (
    <div>
      <AppBarNew
        className="mobileHeader"
        user={props.user}
        role={props.role}
        token={props.token}
        email={props.email}
        domains={props.domains}
        handleLogOut={props.handleHomeLogOut}
      />
      <div className="inline-flex w-100">
        <Sidebar
          menu={toolsMenu}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className="activeTool w-94">{toolsMenu[activeTab].component}</div>
      </div>
    </div>
  );
}
