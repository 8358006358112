/* eslint-disable */

import React, { useEffect, useState } from "react";

const FloatingDiv = (props) => {
  const [activeTab, setActiveTab] = useState("section-one");

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveTab(entry.target.id);
        }
      });
    }, observerOptions);

    observer.observe(props.sectionOneRef.current);
    observer.observe(props.sectionTwoRef.current);
    observer.observe(props.sectionThreeRef.current);
    observer.observe(props.sectionFourRef.current);
    observer.observe(props.sectionFiveRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="floating-div">
      {props.data
        .filter((s) => s.onNav)
        .map((item, index) => (
          <div
            key={index}
            className="inner-div"
            style={{ backgroundColor: activeTab === item.id && "#fbcd4e" }}
            onClick={() => {
              window.location.href = `/#${item.id}`;
            }}
          >
            <span>{item.customIcon ? item.customIcon : item.icon}</span>
            <span className="text">{item.text}</span>
          </div>
        ))}
    </div>
  );
};

export default FloatingDiv;
