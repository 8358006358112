import ChartDataLabels from "chartjs-plugin-datalabels";
import React, { useRef } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import LoaderIcon from "react-loader-icon";
import Container from "./ProgressBar";

function BarChart(props) {
  const myContainer = useRef(null);
  const overviewCharts = ["avs", "apks", "sf"];
  let hovering = false;
  const data = {
    labels: props.data,
    datasets: [
      {
        data: props.dataCount,
        fill: true,
        color: props.labelColor,
        backgroundColor: props.bar
          ? [
              "rgba(255, 99, 132, 0.3)",
              "rgb(245, 115, 40, 0.3)",
              "rgb(250, 199, 11, 0.3)",
              "rgb(54, 126, 24, 0.3)",
            ]
          : [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
              "rgba(101,255,102,0.2)",
              "rgba(255,0,0,0.2)",
              "rgba(101,255,247,0.2)",
              "rgba(243,255,0,0.2)",
              "rgba(0, 0, 128, 0.2)",
              "rgba(128, 0, 128, 0.2)",
            ],
        borderColor: props.bar
          ? [
              "rgba(255, 99, 132, 0.5)",
              "rgb(245, 115, 40, 0.4)",
              "rgb(250, 199, 11, 0.3)",
              "rgb(54, 126, 24, 0.3)",
            ]
          : [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
              "rgba(101,255,102,1)",
              "rgba(255,0,0,1)",
              "rgba(101,255,247,1)",
              "rgba(243,255,0,1)",
              "rgba(0, 0, 128, 1)",
              "rgba(128, 0, 128, 1)",
            ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    layout: {
      padding: 0,
    },
    plugins: {
      datalabels: {
        formatter: (value) => {
          if (
            props.screen === "avs" ||
            props.screen === "sf" ||
            props.percentage
          ) {
            return value + "%";
          } else {
            return value;
          }
        },
        display: true,
        color: props.labelColor,
      },
      legend: {
        onHover: function (event, legendItem) {
          if (hovering) {
            return;
          }
          hovering = true;
          if (!overviewCharts.includes(props.screen)) {
            myContainer.current.style.display = "block";
            myContainer.current.innerHTML = legendItem.text;
            if (props.screen === "main") {
              if (props.type === "(Users)") {
                myContainer.current.style.left = event.x + 550 + "px";
                myContainer.current.style.top = event.y + 650 + "px";
              } else {
                myContainer.current.style.left = event.x + 10 + "px";
                myContainer.current.style.top = event.y + 655 + "px";
              }
            } else {
              if (props.type === "(Users)") {
                myContainer.current.style.left = event.x + 10 + "px";
                myContainer.current.style.top = event.y + 1060 + "px";
              } else {
                myContainer.current.style.left = event.x + 10 + "px";
                myContainer.current.style.top = event.y + 530 + "px";
              }
            }
          }
        },
        onClick: function (event, legendItem) {
          if (props.screen === "sf" && legendItem.text.includes("ℹ")) {
            props.openModal(event, legendItem);
          }
        },
        onLeave: function () {
          hovering = false;
          myContainer.current.innerHTML = "";
          myContainer.current.style.display = "none";
        },
        display: props.bar ? false : true,
        position: "left",
        labels: {
          usePointStyle: true,
          color: props.labelColor,
        },
      },
    },
    indexAxis: "x",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
  };

  return (
    <>
      {!props.loading ? (
        <>
          <div
            ref={myContainer}
            id="tooltip"
            style={{
              backgroundColor:
                overviewCharts.includes(props.screen) && "#222831",
            }}
          ></div>
          {!props.noTitle && (
            <div className="header">
              {props.customTitle ? (
                <h2 className="title chartTitle">{props.customTitle}</h2>
              ) : (
                <h2 className="title chartTitle">
                  Top Compromised URLs {props.type}
                </h2>
              )}
              {props.spacer && (
                <div
                  style={{
                    padding: "10px",
                    margin: "auto",
                    backgroundColor: "transparent",
                  }}
                >
                  <h6 style={{ margin: "0px", color: "transparent" }}>.</h6>
                </div>
              )}
              {props.subtitle && (
                <div
                  onClick={props.discoverMore}
                  className="cursor"
                  style={{
                    backgroundColor: "#FBCD4E",
                    color: "#222831",
                    padding: "10px",
                    borderRadius: "5px",
                    width: "30%",
                    margin: "auto",
                  }}
                >
                  <h6 style={{ margin: "0px" }}>{props.subtitle}</h6>
                </div>
              )}
              {props.totalCount && (
                <h4 className="title chartTitle">
                  Total URLs: {props.totalCount}
                </h4>
              )}
              <hr></hr>
            </div>
          )}
          {props.data && props.data.length > 0 ? (
            <>
              {props.bar ? (
                <Bar
                  data={data}
                  options={options}
                  plugins={[ChartDataLabels]}
                  height={props.height}
                  width={props.width}
                  style={{
                    backgroundColor: props.screen === "main" && "#222831",
                    padding: props.screen === "main" && "30px",
                    borderRadius: props.screen === "main" && "50px",
                  }}
                />
              ) : (
                <Doughnut
                  className="donutChart"
                  data={data}
                  options={options}
                  plugins={[ChartDataLabels]}
                  height={props.height}
                  width={props.width}
                  style={{
                    backgroundColor: props.screen === "main" && "#222831",
                    padding: props.screen === "main" && "30px",
                    borderRadius: props.screen === "main" && "50px",
                  }}
                />
              )}
            </>
          ) : !props.sfLoading ? (
            props.screen !== "thirdParty" && (
              <h1>No Stats To Show {props.type}</h1>
            )
          ) : (
            <>
              <LoaderIcon type="spin" style={{ fill: "white" }} />
              <br />
              Loading {props.title} Data...
            </>
          )}
        </>
      ) : (
        <Container />
      )}
    </>
  );
}

export default BarChart;
