/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import CreateApiClient from "../../API";
import windowsBackground from "../../images/windows_7_dark-wallpaper-1920x1200.jpg";
import AppBarNew from "../AppBar";
import Container from "../ProgressBar";
import FileExplorer from "../partials/FileExplorer";
import AiAnalysis from "./AiAnalysis";
import Error from "./Error";

const api = CreateApiClient();

export default function Stealer(props) {
  const location = useLocation();
  const stealer = location.pathname.split("/")[2];
  const [stealerLoading, setStealerLoading] = useState(false);
  const [dumpLoading, setDumpLoading] = useState(false);
  const [stealerData, setStealerData] = useState(null);
  const [dirTree, setDirTree] = useState({});
  const [dump, setDump] = useState([]);
  const [blocked, setBlocked] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [stage, setStage] = useState("Fetching Stealer's Dump...");
  const [error, setError] = useState("");

  const headers = {
    "auth-token": props.token,
    "Content-Type": "application/json",
  };

  const getStealer = async () => {
    setStealerLoading(true);
    setBlocked(false);
    setError(null);
    const body = { stealer: stealer };
    const data = await api.fetchData("stealers", body, { headers });
    setStealerData(data);
    setDirTree(data.dir_tree);
    if (data.dump_file_name) {
      let stealer_pure = data.stealer_pure ? data.stealer_pure : stealer;
      extractDump(data.dump_file_name, stealer_pure, data.ai_analysis);
    } else {
      window.alert("Stealer files cannot be opened");
      setBlocked(true);
    }

    setStealerLoading(false);
  };

  const extractDump = async (dump_file_name, stealer_pure, ai_analysis) => {
    if (
      props.userUiPermissions.includes("ai_analysis") &&
      !props.userUiPermissions.includes("search-by-stealer")
    ) {
      if (ai_analysis && ai_analysis.length > 0) {
        setDumpLoading(false);
        setStage(null);
        return;
      } else {
        setStage(`Extracting Stealer ${stealer_pure}...`);
      }
    }
    setDumpLoading(true);
    setDump([]);
    const body = { dump_file_name: dump_file_name, stealer_pure: stealer_pure };
    const data = await api.fetchData("stealers/extract", body, { headers });
    if (data === "HEAVY") {
      window.alert("Stealer files cannot be opened");
      setBlocked(true);
    } else if (data.type && data.type === "directory") {
      setDirTree(data);
      setDump(data);
      setDumpLoading(false);
      setStage(null);
    } else {
      setDumpLoading(false);
      setError(data);
      // window.alert(data);
    }
  };

  useEffect(() => {
    getStealer();
  }, [stealer]);

  const exportZip = async () => {
    setExportLoading(true);
    try {
      const headers = {
        "auth-token": props.token,
        "Content-Type": "application/json",
        Accept: "application/zip",
      };

      const response = await api.fetchData(
        `stealers/export?dir=${encodeURIComponent(
          stealerData.stealer_pure ? stealerData.stealer_pure : stealer
        )}`,
        {},
        { headers }
      );

      if (response.file) {
        const data = response;
        const fileData = new Uint8Array(response.file.data);
        const blob = new Blob([fileData], { type: "application/zip" });
        const downloadUrl = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = data.filename;
        document.body.appendChild(a);
        a.click();

        setTimeout(() => {
          window.URL.revokeObjectURL(downloadUrl);
          document.body.removeChild(a);
        }, 0);
      } else {
        console.error("Failed to download file");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
    setExportLoading(false);
  };

  return (
    <div>
      <AppBarNew
        className="mobileHeader"
        user={props.user}
        role={props.role}
        token={props.token}
        email={props.email}
        handleLogOut={props.handleHomeLogOut}
        domains={props.domains}
      />
      {!error ? (
        <div
          style={{
            padding: "4rem 0 0 0",
            // backgroundImage: props.userUiPermissions.includes("search-by-stealer")
            //   ? `url(${windowsBackground})`
            //   : "none",
            background: props.userUiPermissions.includes("search-by-stealer")
              ? `url(${windowsBackground})`
              : "none",
            backgroundPosition: "top center",
            backgroundSize: "cover",
            height: "100vh",
          }}
        >
          {stealerLoading ? (
            <Container paddingTop={"3rem"} classes={"mainNewLoader"} />
          ) : stealerData &&
            props.userUiPermissions &&
            props.userUiPermissions.includes("search-by-stealer") ? (
            <FileExplorer
              initialData={dirTree}
              dumpLoading={dumpLoading}
              dump={dump}
              stealer={stealer}
              headers={headers}
              stealerData={stealerData}
              blocked={blocked}
              exportZip={exportZip}
              exportLoading={exportLoading}
              role={props.role}
              userUiPermissions={props.userUiPermissions}
            />
          ) : (
            props.userUiPermissions &&
            props.userUiPermissions.includes("ai_analysis") && (
              <AiAnalysis
                stage={stage}
                headers={headers}
                stealerData={stealerData}
              />
            )
          )}
        </div>
      ) : (
        <Error msg={error} user={props.user} />
      )}
    </div>
  );
}
