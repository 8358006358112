import React from "react";
import BarChart from "../BarChart";

export default function PasswordStrengthBar(props) {
  return (
    <BarChart
      labelColor="#fff"
      screen="avs"
      data={["Too Weak", "Weak", "Medium", "Strong"]}
      dataCount={props.passwordStats}
      loading={props.loading}
      type=""
      height={50}
      width={100}
      noTitle={true}
      bar={true}
    />
  );
}
