import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import React from "react";
import LoaderIcon from "react-loader-icon";
import Select from "react-select";
import CreateApiClient from "../../API";
import { adminUrl } from "../../Config";

const api = CreateApiClient();

export default function CloneSelection(props) {
  const autoComplete = async (q) => {
    if (q && q.length > 1) {
      api
        .adminReq(
          "GET",
          `${adminUrl}/autocomplete-users?q=${q}`,
          { "auth-token": props.token },
          {}
        )
        .then((res) => {
          props.setEmailList(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div style={{ width: "50%", margin: "auto" }}>
      <h4 className="darkText text-center cyberFont bold">OR</h4>
      <div
        style={{
          background: "#222831",
          borderRadius: "20px",
          padding: "2rem",
          boxShadow:
            "7px 9px 17px 0px rgba(0, 0, 0, 0.253) 6px 6px 20px 0px rgba(0, 0, 0, 0.199)",
        }}
      >
        <h2 className="darkText text-center cyberFont bold">Select User</h2>
        <Select
          onInputChange={(e) => {
            autoComplete(e);
          }}
          onChange={(e) => props.pickUser(e.value.toLowerCase())}
          value={
            props.pickedUser
              ? {
                  value: props.pickedUser.toLowerCase(),
                  label: props.pickedUser.toLowerCase(),
                }
              : props.usersList[0]
          }
          placeholder="Select User"
          className="search-by-select usersSelect desktop"
          options={props.usersList}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "#FBCD4E",
              primary: "black",
            },
          })}
        />
        <br />
        <input
          type="email"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="emailField"
          placeholder="Email Address"
          name={new Date()}
          className="form-control"
          onChange={props.onChangeEmail}
          value={props.email}
        />
        <br />
        <input
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name={new Date()}
          placeholder="Password"
          type="text"
          id="passwordField"
          className="form-control"
          onChange={props.onChangePassword}
          value={props.password}
        />
        <br />
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          className="searchBtn"
          onClick={props.cloneUser}
          endIcon={
            props.cloneLoading ? (
              <LoaderIcon className="buttonLoader tableLoader" type="spin" />
            ) : props.saved ? (
              <Icon>done</Icon>
            ) : (
              <Icon>send</Icon>
            )
          }
        >
          Clone
        </Button>
      </div>
    </div>
  );
}
