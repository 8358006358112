/* eslint-disable */

import React from "react";

function Sidebar(props) {
  return (
    <div id="adminSidebar">
      <nav>
        <ul>
          {props.menu.map((item, index) => (
            <li
              key={index}
              className={props.activeTab === index ? "yellowBG" : ""}
              onClick={() => props.setActiveTab(index)}
            >
              <a
                className={
                  props.activeTab === index ? "lightText toggle" : "toggle"
                }
              >
                <span className="icon">{item.icon}</span>
                <span className="title">{item.label}</span>
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}

export default Sidebar;
