/* eslint-disable */

// import "bootstrap/dist/css/bootstrap.min.css";
import React, { useRef } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { RiVirusLine } from "react-icons/ri";
import "../../css/HorizontalTimeline.css";

const popover = (diffInDays, diffInHours, stealer) => {
  return (
    <Popover id="popover-basic" style={{ zIndex: 99999 }}>
      <Popover.Title as="h3">
        {diffInDays > 0
          ? `Uploaded ${diffInDays} ${diffInDays > 1 ? " days" : " day"} later`
          : `Uploaded ${diffInDays} ${
              diffInHours > 1 ? " hours" : " hour"
            } later`}
      </Popover.Title>
      <Popover.Content>{stealer}</Popover.Content>
    </Popover>
  );
};

const HorizontalTimeline = (props) => {
  // const itemRefs = useRef([]);

  // const handleItemClick = (item, index) => {
  //   props.setMachine(item);
  //   if (itemRefs.current[index]) {
  //     itemRefs.current[index].scrollIntoView({
  //       behavior: "smooth",
  //       inline: "start",
  //     });
  //   }
  // };

  const scrollContainerRef = useRef(null);

  const handleItemClick = (item, index) => {
    props.setMachine(item);
    if (scrollContainerRef.current) {
      const itemElement = scrollContainerRef.current.children[index];
      const containerWidth = scrollContainerRef.current.offsetWidth;
      const itemOffsetLeft = itemElement.offsetLeft;

      scrollContainerRef.current.scrollTo({
        left: itemOffsetLeft - containerWidth / 2 + itemElement.offsetWidth / 2,
        behavior: "smooth",
      });
    }
  };

  return (
    <div style={{ padding: "30px 0px 0px 60px" }}>
      <h4 style={{ color: "#f6cd65", marginBottom: "20px" }}>
        Detected{" "}
        {props.data.length > 1
          ? `${props.data.length} Times`
          : `${props.data.length} Time`}{" "}
        →
      </h4>
      <div className="line_box" ref={scrollContainerRef}>
        {props.data &&
          props.data.length > 0 &&
          props.data.map((item, index) => (
            <div
              className="text_circle done"
              key={index}
              // ref={(el) => (itemRefs.current[index] = el)}
            >
              <OverlayTrigger
                trigger="hover"
                placement="top"
                overlay={popover(
                  item.diffInDays,
                  item.diffInHours,
                  item.stealer
                )}
              >
                <a
                  href="javascript:void(0)"
                  className="tvar"
                  onClick={() => handleItemClick(item, index)}
                >
                  <span>
                    <RiVirusLine />
                  </span>
                </a>
              </OverlayTrigger>
              <div
                className={
                  props.machine.stealer === item.stealer
                    ? "circle picked pointer"
                    : "circle pointer"
                }
                onClick={() => handleItemClick(item, index)}
              >
                <div class="subline">
                  <h6>{item.date}</h6>
                  <p>{item.time}</p>
                </div>
              </div>
              {props.machine.stealer === item.stealer && (
                <div className="verticalLine"></div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default HorizontalTimeline;
