// Global
export const serverAPIPort = 8000;
export const frontendPort = 5050;
export const jsonApi = "/api/json/v2";
export const APIEndpoint = "/api/data";
export const APIEmail = "/api/email";
export const APIauth = "/api/user";
export const APIauth0 = "/api/auth";
export const APIadmin = "/api/admin";
export const APIalerts = "/api/alerts";
export const APIerror = "/api/errors";
export const MapApi =
  "pk.eyJ1IjoiZG91YmxlYmFja3NsYXNoIiwiYSI6ImNrdG10MDh3MDBmMjQydXM4ZG9jZ2l2bGkifQ.-hqbVHfS3AMn1RoS-WFoQQ";
export const clearbitApi =
  "https://autocomplete.clearbit.com/v1/companies/suggest?query=";

export const host = process.env.REACT_APP_SERVER_URL;
export const SERVER_URL =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? `${host}:${serverAPIPort}`
    : `${host}`;

export const JSON_API =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? `${host}:${serverAPIPort}${jsonApi}`
    : `${host}${jsonApi}`;
export const APIAlertsAddress =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? `${host}:${serverAPIPort}${APIalerts}`
    : `${host}${APIalerts}`;
export const APIFullAddress =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? `${host}:${serverAPIPort}${APIEndpoint}`
    : `${host}${APIEndpoint}`;
export const APIFullEmailAddress =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? `${host}:${serverAPIPort}${APIEmail}`
    : `${host}${APIEmail}`;
export const auth0Address =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? `${host}:${serverAPIPort}${APIauth0}`
    : `${host}${APIauth0}`;
export const authAddress =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? `${host}:${serverAPIPort}${APIauth}`
    : `${host}${APIauth}`;
export const publicAddress =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? `${host}:${frontendPort}`
    : `${host}`;
export const baseUrl =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? `${host}:${serverAPIPort}${jsonApi}`
    : `${host}${jsonApi}`;

export const adminUrl =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? `${host}:${serverAPIPort}${APIadmin}`
    : `${host}${APIadmin}`;

export const errorsUrl =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? `${host}:${serverAPIPort}${APIerror}`
    : `${host}${APIerror}`;

export const REDIRECT_URI =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? `${host}:${frontendPort}/login`
    : `${host}/login`;

export const AZURE_CLIENT_ID = "4d0764b4-62e9-4ef3-a6ac-fa9adcdb3a29";
export const RECAPTCHA_KEY = "6LfSlMopAAAAABC9hqcNILDbED4TCj1wYHMd9XvV";
export const RECAPTCHA_V2 = "6LfcsCEqAAAAAKuxAccoZeyM54WNqDwCf82PBa-2";
// Production
// export const host = "https://cavalier.hudsonrock.com";
// export const APIFullAddress = `${host}${APIEndpoint}`;
// export const APIFullEmailAddress = `${host}${APIEmail}`;
// export const authAddress = `${host}${APIauth}`;
// export const publicAddress = `${host}`;
// export const baseUrl = `${host}${jsonApi}`;

// Local (Dev)
// export const host = "http://localhost";
// export const APIFullAddress = `${host}:${serverAPIPort}${APIEndpoint}`;
// export const APIFullEmailAddress = `${host}:${serverAPIPort}${APIEmail}`;
// export const authAddress = `${host}:${serverAPIPort}${APIauth}`;
// export const publicAddress = `${host}:${frontendPort}`;
// export const baseUrl = `${host}:${serverAPIPort}${jsonApi}`;
