/* eslint-disable */

import cryptoRandomString from "crypto-random-string";
import React, { useEffect, useState } from "react";
import LoaderIcon from "react-loader-icon";
import CreateApiClient from "../../API";
import { adminUrl } from "../../Config";
import CloneSelection from "./CloneSelection";
import LastUsersCreated from "./LastUsersCreated";

const api = CreateApiClient();

export default function CloneUser(props) {
  const [lastUsers, setLastUsers] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pickedUser, setPickedUser] = useState("");
  const [cloneLoading, setCloneLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState("");

  const getLastUsers = async () => {
    const response = await api.adminReq(
      "POST",
      `${adminUrl}/last-users`,
      {
        "auth-token": props.token,
      },
      {}
    );
    setLastUsers(response.data);
  };

  const pageSetup = async () => {
    setPageLoading(true);
    const randomPassword = cryptoRandomString({ length: 16, type: "url-safe" });
    setPassword(randomPassword);
    await getLastUsers();
    setPageLoading(false);
  };

  const resetForm = () => {
    setEmail("");
    setPassword("");
    setSaved(false);
  };

  const onChangeEmail = (e) => {
    e.preventDefault();
    setEmail(e.target.value.toLowerCase());
  };

  const onChangePassword = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  const pickUser = (e) => {
    setPickedUser(e);
  };

  const cloneUser = async () => {
    setCloneLoading(true);
    const api_token = cryptoRandomString({ length: 64, type: "url-safe" });
    const url_hash = cryptoRandomString({ length: 64, type: "url-safe" });

    const body = {
      toBeCloned: pickedUser,
      email: email,
      password: password,
      api_key: api_token,
      url_hash: url_hash,
      alert_emails: [email],
    };

    const res = await api.registerUser(`clone-user`, body, {
      headers: {
        "auth-token": props.token,
      },
    });

    if (res && res.User) {
      setCloneLoading(false);
      setSaved(true);
      setTimeout(() => {
        resetForm();
      }, 2000);
    } else {
      setCloneLoading(false);
      if (typeof res === "string") {
        setError(res);
      } else {
        setError("Something went wrong, please try again");
      }
    }
  };

  useEffect(() => {
    pageSetup();
  }, []);

  return (
    <div className="darkText">
      <h2 className="cyberFont bold text-center yellowColor">
        Welcome to the new user clone experience!
      </h2>
      <hr />
      {pageLoading ? (
        <div
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoaderIcon type={"spokes"} className="yellowLoader" />
        </div>
      ) : (
        <div>
          <LastUsersCreated data={lastUsers} pickUser={pickUser} />
          <CloneSelection
            usersList={emailList}
            setEmailList={setEmailList}
            email={email}
            password={password}
            pickedUser={pickedUser}
            cloneLoading={cloneLoading}
            saved={saved}
            error={error}
            cloneUser={cloneUser}
            pickUser={pickUser}
            onChangeEmail={onChangeEmail}
            onChangePassword={onChangePassword}
            token={props.token}
          />
        </div>
      )}
    </div>
  );
}
