/* eslint-disable */

import axios from "axios";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useDetectClickOutside } from "react-detect-click-outside";
import SearchForm from "./SearchForm";

export default function FetchSuggestions(props) {
  const [cancelToken, setCancelToken] = useState(null);
  const [displayDropdown, setDisplayDropdown] = useState(true);

  const onChange = (e) => {
    e && e.preventDefault();
    const fiteredValue = e.target.value.replace(
      /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/]/g,
      ""
    );
    props.setValue(fiteredValue);
    props.setLoading(true);
    props.setData([]);
    if (cancelToken) {
      cancelToken.cancel("Canceled");
    }
    const source = axios.CancelToken.source();

    setCancelToken(source);

    axios
      .get(`${props.url}?query=${fiteredValue}`, {
        cancelToken: source.token,
      })
      .then((response) => {
        props.setLoading(false);
        props.setData(response.data);
      })
      .catch((err) => {
        props.setLoading(false);
        props.setData([]);
        console.log(err);
      });
    return () => {
      if (cancelToken) {
        cancelToken.cancel("Canceled");
      }
    };
  };

  const closeDropdown = (e) => {
    e.target.id !== "blackTextFill"
      ? setDisplayDropdown(false)
      : setDisplayDropdown(true);
  };

  const ref = useDetectClickOutside({ onTriggered: closeDropdown });

  return (
    <>
      <SearchForm
        onChange={onChange}
        onSubmit={props.onSubmit}
        value={props.value}
        placeholder={props.placeholder}
        formStyle={{
          backgroundColor: props.inputDisabled ? "gray" : "#E7F0FE",
          color: "#222831",
          borderColor: "#222831",
          margin: "5px 2px",
          borderRadius: "50px 0px 0px 50px",
        }}
        btnStyle={{
          backgroundColor:
            props.value && !props.inputDisabled ? "#FECD4E" : "gray",
          color: "#222831",
          borderRadius: "0px 50px 50px 0px",
        }}
        btnDisabled={!props.value}
        inputDisabled={props.inputDisabled}
        locked={props.inputDisabled}
        unlocked={!props.inputDisabled}
      />
      {props.data.length > 0 && (
        <div
          className="prospectSuggestions"
          ref={ref}
          style={{
            width:
              document.querySelector("#blackTextFill") &&
              `${document.querySelector("#blackTextFill").offsetWidth}px`,
            border: displayDropdown && "1px solid",
            borderRadius: "10px",
            backgroundColor: "white",
            position: "absolute",
            zIndex: "99",
          }}
        >
          {displayDropdown && (
            <div>
              {props.data.map((company, index) => (
                <Form.Text
                  value={company.name}
                  onClick={() => {
                    let keyword = company.domain.split(".")[0];
                    props.setData([]);
                    props.setValue(keyword);
                    // props.pickValue(company.name);
                    props.setLogo(company.logo);
                  }}
                  key={index}
                  className="text-muted prospectSugRow"
                >
                  <img
                    alt={company.name}
                    value={company.name}
                    className="prospectLogo"
                    src={company.logo}
                  />
                  <div style={{ display: "grid" }}>
                    <span>{company.name}</span>
                    <span>{company.domain}</span>
                  </div>
                </Form.Text>
              ))}
              <span style={{ fontSize: "14px" }}>
                If you don't see your company in the auto-suggestions, just
                search your keyword without selecting a domain.
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
}
