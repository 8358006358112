/* eslint-disable */

import React, { useEffect, useState } from "react";
import ReactJson from "react-json-view";

export default function MachineJson(props) {
  const machine = props.machine;
  const [highlightedJson, setHighlightedJson] = useState(machine);

  useEffect(() => {
    setHighlightedJson(machine);
  }, [machine]);

  return (
    <div id="machineByEmail">
      <div className="inline-flex" style={{ columnGap: "5px" }}>
        <a
          target="_blank"
          rel="noreferrer"
          href={`/search-by-stealer/${machine.stealer}`}
          style={{
            backgroundColor: "#FCCD4E",
            color: "#222831",
            fontSize: "14px",
            padding: "8px 16px",
            border: "0px",
            borderRadius: "4px",
            textTransform: "uppercase",
          }}
        >
          Analyze stealer with AI →
        </a>
        {machine.ip &&
          machine.ip != "Not Found" &&
          props.component === "email" && (
            <a
              target="_blank"
              rel="noreferrer"
              href={`/search-by-ip/${machine.ip}`}
              style={{
                backgroundColor: "#FCCD4E",
                color: "#222831",
                fontSize: "14px",
                padding: "8px 16px",
                border: "0px",
                borderRadius: "4px",
                textTransform: "uppercase",
              }}
            >
              IP Lookup →
            </a>
          )}
      </div>

      <ReactJson
        src={{
          stealer: machine.stealer,
          ip: machine.ip,
          computer_name: machine.computer_name,
          stealer_family: machine.stealer_family,
          operating_system: machine.operating_system,
          malware_path: machine.malware_path,
          antiviruses: machine.antiviruses,
          facebook_id: machine.facebook,
          date_compromised: machine.date_compromised,
          date_uploaded: machine.date_uploaded,
          credentials: machine.credentials,
          employee_session_cookies: machine.employee_session_cookies,
          installed_software: machine.installed_software,
        }}
        // src={{
        //   stealer: highlightedJson.stealer,
        //   ip: highlightedJson.ip,
        //   computer_name: highlightedJson.computer_name,
        //   stealer_family: highlightedJson.stealer_family,
        //   operating_system: highlightedJson.operating_system,
        //   malware_path: highlightedJson.malware_path,
        //   antiviruses: highlightedJson.antiviruses,
        //   facebook_id: highlightedJson.facebook,
        //   date_compromised: highlightedJson.date_compromised,
        //   date_uploaded: highlightedJson.date_uploaded,
        //   credentials: highlightedJson.credentials,
        //   employee_session_cookies: highlightedJson.employee_session_cookies,
        //   installed_software: highlightedJson.installed_software,
        // }}
        theme="monokai"
        displayDataTypes={false}
        style={{
          textAlign: "left",
          backgroundColor: "transparent",
          marginTop: "10px",
        }}
        name={null}
        collapsed={3}
        quotesOnKeys={false}
      />
    </div>
  );
}
