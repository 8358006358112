import Button from "@material-ui/core/Button";
import React from "react";
import Form from "react-bootstrap/Form";

export default function SearchForm(props) {
  return (
    <form
      onSubmit={props.onSubmit}
      style={{
        display: "inline-flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Form.Control
        autoComplete="off"
        id="blackTextFill"
        style={props.formStyle}
        maxLength="999"
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        required={true}
        name={props.name ? props.name : "text"}
        disabled={props.inputDisabled}
      />
      <Button
        variant="contained"
        type="submit"
        style={props.btnStyle}
        disabled={props.btnDisabled || props.inputDisabled}
      >
        {props.customBtnTxt ? props.customBtnTxt : "Search"}
      </Button>
    </form>
  );
}
