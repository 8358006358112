/* eslint-disable */

import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { FcCheckmark } from "react-icons/fc";
import { MdDelete, MdLibraryBooks } from "react-icons/md";
import LoaderIcon from "react-loader-icon";
import { authAddress } from "../../Config";
import slackIcon from "../../images/Slack_icon.png";
import AppBarNew from "../AppBar";
import ConfirmModal from "../mui/ConfirmModal";
import FullScreenDialog from "../mui/FullScreenDialog";
import Footer from "../partials/Footer";
import NewCompanyForm from "../partials/NewCompanyForm";
import CollapsibleTable from "./CollapsibleTable";

const blacklistDomains = [
  "google.com",
  "facebook.com",
  "live.com",
  "instagram.com",
  "netflix.com",
  "discord.com",
  "roblox.com",
  "twitter.com",
  "com.facebook.katana",
  "steampowered.com",
  "paypal.com",
  "twitch.tv",
  "mega.nz",
  "epicgames.com",
  "riotgames.com",
  "steamcommunity.com",
  "com.netflix.mediaclient",
  "com.instagram.android",
  "linkedin.com",
  "spotify.com",
  "com.spotify.music",
  "yahoo.com",
  "com.discord",
  "com.roblox.client",
  "tv.twitch.android.app",
  "rockstargames.com",
  "discordapp.com",
  "minecraft.net",
  "tlauncher.org",
  "leagueoflegends.com",
  "aliexpress.com",
  "com.snapchat.android",
  "com.twitter.android",
];

export default function Companies(props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [savedLoading, setSavedLoading] = useState(false);
  const [saveError, setSaveError] = useState("");
  const [saved, setSaved] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [domains, setDomains] = useState([]);
  const [apks, setApks] = useState([]);
  const [externals, setExternals] = useState([]);
  const [domainsInput, setDomainsInput] = useState("");
  const [apksInput, setApksInput] = useState("");
  const [externalsInput, setExternalsInput] = useState("");
  const [openRow, setOpenRow] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [deleteLoading] = useState(null);
  const [y, setY] = useState(window.scrollY);
  const [modalOpen, setModalOpen] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [modalHTML, setModalHTML] = useState(null);
  const [modalState, setModalState] = useState("");
  const [alertLoading] = useState(null);

  const handleModalClose = () => {
    setModalOpen(null);
  };

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        console.log("scrolling up");
      } else if (y < window.scrollY) {
        console.log("scrolling down");
      }
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  const addDomains = () => {
    let _domains = domainsInput.replace(/ /, "").split(",");
    let filtered = _domains.filter((d) => !domains.includes(d));
    setDomains([...domains, ...filtered]);
    setDomainsInput("");
  };

  const removeDomain = (_domain) => {
    let filtered = domains.filter((d) => d !== _domain);
    setDomains(filtered);
  };

  const addApks = () => {
    let _apks = apksInput.replace(/ /, "").split(",");
    let filtered = _apks.filter((d) => !apks.includes(d));
    setApks([...apks, ...filtered]);
    setApksInput("");
  };

  const removeApk = (_apk) => {
    let filtered = apks.filter((a) => a !== _apk);
    setApks(filtered);
  };

  const addExternalDomains = () => {
    let _domains = externalsInput.replace(/ /, "").split(",");
    let filtered = _domains.filter((d) => !externals.includes(d));
    setExternals([...externals, ...filtered]);
    setExternalsInput("");
  };

  const removeExternalDomain = (_domain) => {
    let filtered = externals.filter((d) => d !== _domain);
    setExternals(filtered);
  };

  const config = {
    headers: {
      "auth-token": props.user.Token
        ? props.user.Token
        : JSON.parse(props.user).Token,
    },
  };

  const handleClose = () => {
    setSaveError("");
    setSaved(false);
    setOpen(false);
    setModalHTML(null);
  };

  const handleClickOpen = (state) => {
    if (state === "company") {
      setModalTitle("ADD NEW COMPANY");
    } else if (state === "gov") {
      setModalTitle("ADD GOVERNMENT");
    } else {
      setModalTitle("TUTORIAL");
    }
    setModalState(state);
    setOpen(true);
  };

  const saveCompany = async (company, data, gov) => {
    setSaveError("");
    setSavedLoading(true);
    const url = `${authAddress}/add-company`;
    let apks = data.find((d) => d.field === "apks");
    let domains = data.find((d) => d.field === "domains");
    let external_domains = data.find((d) => d.field === "external_domains");

    const body = {
      logo: gov ? gov.flag : null,
      name: gov ? `${gov.label} GOVERNMENT` : company,
      code: gov ? gov.code : null,
      type: gov ? "gov" : "company",
      data: {
        domains: domains ? domains.list : [],
        apks: apks ? apks.list : [],
        external_domains: external_domains ? external_domains.list : [],
      },
    };
    const response = await axios
      .post(url, body, config)
      .then((res) => res.data)
      .catch((err) => err);
    if (response === "updated") {
      setSavedLoading(false);
      setSaved(true);
      setTimeout(() => {
        handleClose();
      }, 1000);
    } else {
      setSaveError("Something went wrong, please try again later.");
      setSavedLoading(false);
      handleClose();
    }
  };

  const toggleAlerts = async (id, value) => {
    const modified = data.map((item) => {
      if (item._id.toString() === id) {
        item.alerts = !item.alerts;
      }
      return item;
    });

    setData(modified);
    const url = `${authAddress}/company-alerts?id=${id}`;
    const response = await axios
      .post(url, { value: value }, config)
      .then((res) => res.data)
      .catch((err) => err);
    if (response === "updated") {
      getUserCompanies(true);
    } else {
    }
  };

  const updateCompany = async (company, data, id) => {
    setSaveError("");
    setSavedLoading(true);
    const url = `${authAddress}/update-company?id=${id}`;
    let apks = data.find((d) => d.field === "apks");
    let domains = data.find((d) => d.field === "domains");
    let external_domains = data.find((d) => d.field === "external_domains");

    const body = {
      name: company,
      data: {
        domains: domains ? domains.list : [],
        apks: apks ? apks.list : [],
        external_domains: external_domains ? external_domains.list : [],
      },
    };
    const response = await axios
      .post(url, body, config)
      .then((res) => res.data)
      .catch((err) => err);
    if (response === "updated") {
      getUserCompanies(true);
      setSavedLoading(false);
      setSaved(true);
      setTimeout(() => {
        setSaved(false);
        setOpenRow(null);
      }, 1000);
    } else {
      setSaveError("Something went wrong, please try again later.");
      setSavedLoading(false);
      setOpenRow(null);
    }
  };

  const deleteCompany = async (id) => {
    const filtered = data.filter((s) => {
      if (id === "all") {
        return [];
      } else {
        return s._id.toString() !== id;
      }
    });

    setData(filtered);

    const url = `${authAddress}/delete-company?id=${id}`;
    const response = await axios
      .post(url, {}, config)
      .then((res) => res.data)
      .catch((err) => err);
    if (response === "deleted") {
      getUserCompanies(true);
    } else {
    }
    handleModalClose();
  };

  const getUserCompanies = async (noLoading) => {
    if (!noLoading) {
      setLoading(true);
    }
    const url = `${authAddress}/get-user-companies`;
    await axios
      .post(url, {}, config)
      .then((res) => {
        let _user = localStorage.getItem("user");
        _user = JSON.parse(_user);
        if (_user) {
          _user.companies = res.data;
          props.updateUser(_user);
        }
        setData(res.data);
      })
      .catch((err) => {
        setError("No Companies Found");
      });

    if (!noLoading) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserCompanies();
  }, [open]);

  const createCompanyData = () => {
    let companyData = [];
    if (openRow) {
      companyData = [
        {
          name: "Domains",
          field: "domains",
          list: domains,
          removeFunc: (domain) => removeDomain(domain),
          addFunc: () => addDomains(),
          inputValue: domainsInput,
          onChange: (e) => setDomainsInput(e.target.value),
        },
        {
          name: "APK's",
          field: "apks",
          list: apks,
          removeFunc: (domain) => removeApk(domain),
          addFunc: () => addApks(),
          inputValue: apksInput,
          onChange: (e) => setApksInput(e.target.value),
        },
        {
          name: "External Domains",
          field: "external_domains",
          list: externals,
          removeFunc: (domain) => removeExternalDomain(domain),
          addFunc: () => addExternalDomains(),
          inputValue: externalsInput,
          onChange: (e) => setExternalsInput(e.target.value),
        },
      ];
    }

    return companyData;
  };

  const resetData = () => {
    setDomains([]);
    setApks([]);
    setExternals([]);
    setCompanyName("");
  };

  useEffect(() => {
    if (openRow) {
      const company = data.find((s) => s._id.toString() === openRow.toString());
      setDomains(company.data.domains);
      setApks(company.data.apks);
      setExternals(company.data.external_domains);
      setCompanyName(company.name);
    } else {
      resetData();
    }
  }, [openRow, companyName]);

  const tableHeaders = [
    {
      name: "Company",
    },
    {
      name: "Domains",
    },
    {
      name: "APK's",
    },
    {
      name: "External Domains",
    },
    {
      name: "Notifications",
    },
    {
      name: "Actions",
    },
    {
      name: "",
    },
  ];

  const tutorialBody = (
    <div>
      <iframe
        src="https://scribehow.com/embed/How_to_monitor_a_Company_in_Cavalier__IwgKWc_oR_6VTuyd50Ob-A"
        width="100%"
        height="640"
        allowfullscreen
        frameborder="0"
        title="How to mointor a Company in Cavalier"
      ></iframe>
    </div>
  );

  return (
    <div>
      <AppBarNew
        className="mobileHeader"
        user={props.user}
        role={props.role}
        token={props.token}
        email={props.email}
        domains={props.domains}
        handleLogOut={props.handleHomeLogOut}
      />
      <div className="companiesScreen">
        <div className="tableWrap">
          <h2>Companies</h2>
          <br />
          <div style={{ padding: "1rem" }}>
            <div
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div className="actions">
                <Button
                  type="button"
                  variant="contained"
                  className={"searchBtn desktopSearchBtn"}
                  startIcon={<Icon>add</Icon>}
                  onClick={() => handleClickOpen("company")}
                  style={{ margin: "0px 5px" }}
                >
                  Add Company
                </Button>
                {props.user.ui_permissions &&
                  props.user.ui_permissions.includes("governments") && (
                    <Button
                      type="button"
                      variant="contained"
                      className={"searchBtn desktopSearchBtn"}
                      startIcon={<Icon>add</Icon>}
                      onClick={() => handleClickOpen("gov")}
                      style={{ margin: "0px 5px" }}
                    >
                      Add Government
                    </Button>
                  )}
              </div>
              <div>
                <Button
                  type="button"
                  variant="contained"
                  className={"searchBtn desktopSearchBtn"}
                  startIcon={
                    deleteLoading === "all" ? (
                      <LoaderIcon
                        className="buttonLoader tableLoader"
                        type="spin"
                      />
                    ) : (
                      <MdDelete />
                    )
                  }
                  onClick={() => {
                    setModalOpen("all");
                  }}
                >
                  DELETE ALL COMPANIES
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  className={"searchBtn desktopSearchBtn"}
                  startIcon={<MdLibraryBooks />}
                  onClick={() => handleClickOpen("tutorial")}
                  style={{ margin: "0px 5px" }}
                >
                  TUTORIAL
                </Button>
                {props.user.webhook_urls &&
                props.user.webhook_urls.length > 0 ? (
                  <span
                    style={{
                      backgroundColor: "white",
                      padding: "9px 10px",
                      borderRadius: "4px",
                    }}
                  >
                    <img
                      alt="Slack Notifications"
                      src={slackIcon}
                      style={{ width: "20px", marginRight: "5px" }}
                    />
                    Slack notifications{" "}
                    <FcCheckmark size={25} style={{ marginTop: "-5px" }} />
                  </span>
                ) : (
                  <a
                    href="https://slack.com/oauth/v2/authorize?client_id=2147716558119.3810903297856&scope=incoming-webhook"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      backgroundColor: "white",
                      padding: "9px 10px",
                      borderRadius: "4px",
                    }}
                  >
                    <img
                      alt="Slack Notifications"
                      src={slackIcon}
                      style={{ width: "20px", marginRight: "5px" }}
                    />
                    Slack Notifications →
                  </a>
                )}
              </div>
            </div>

            <br />
            <br />
          </div>

          {loading ? (
            <LoaderIcon type="spin" className="tabsLoader" />
          ) : (
            <CollapsibleTable
              blacklistDomains={blacklistDomains}
              headers={tableHeaders}
              rows={data}
              openRow={openRow}
              setOpenRow={setOpenRow}
              createCompanyData={() => createCompanyData()}
              saveCompany={(company, data) => saveCompany(company, data)}
              updateCompany={(company, data, id) =>
                updateCompany(company, data, id)
              }
              user={props.user}
              loading={savedLoading}
              saved={saved}
              saveError={saveError}
              domains={domains}
              apks={apks}
              externals={externals}
              companyName={companyName}
              setCompanyName={setCompanyName}
              deleteCompany={(id) => {
                setModalOpen(id);
              }}
              deleteLoading={deleteLoading}
              alertLoading={alertLoading}
              toggleAlerts={(id, value) => toggleAlerts(id, value)}
            />
          )}
        </div>
      </div>
      <FullScreenDialog
        open={open}
        handleClose={handleClose}
        title={modalTitle}
        classes="addCompanyDialog"
        body={
          modalState === "tutorial" ? (
            tutorialBody
          ) : (
            <NewCompanyForm
              saveCompany={(company, data, gov) =>
                saveCompany(company, data, gov)
              }
              user={props.user}
              open={open}
              loading={savedLoading}
              saved={saved}
              saveError={saveError}
              blacklistDomains={blacklistDomains}
              type={modalState}
            />
          )
        }
      />
      <ConfirmModal
        title={"Are you sure?"}
        open={modalOpen !== null}
        handleClose={handleModalClose}
        confirmText="Delete"
        action={() => deleteCompany(modalOpen)}
      />
      <Footer />
    </div>
  );
}
