/* eslint-disable */

import Axios from "axios";
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import LoaderIcon from "react-loader-icon";
import { MultiSelect } from "react-multi-select-component";
import { authAddress } from "../Config";
import "../css/App.css";
import { getItem } from "../hooks/useLs";
import SearchBtn from "./SearchBtn";

function Preset(props) {
  const [customerDomains, setCustomerDomains] = useState([]);
  const [loadOptions, setLoadOptions] = useState(false);

  const DefaultItemRenderer = ({ checked, option, onClick, disabled }) => (
    <div className={`item-renderer ${disabled && "disabled"}`}>
      {loadOptions ? (
        <LoaderIcon className="tabsLoader" type={"spinningBubbles"} />
      ) : (
        <>
          <input
            type="checkbox"
            onChange={onClick}
            checked={checked}
            tabIndex={-1}
            disabled={disabled}
          />
          <span>{option.label}</span>
        </>
      )}
    </div>
  );

  const getAllDomains = (user) => {
    setCustomerDomains(user.all_domains);
  };

  useEffect(() => {
    const user = getItem("user");
    user ? getAllDomains(user) : fetchAllDomains();
  }, []);

  const [selected, setSelected] = useState(props.defaultValue);

  const fetchAllDomains = async () => {
    setLoadOptions(true);
    Axios.post(
      `${authAddress}/getalldomains`,
      {},
      {
        headers: {
          "auth-token": props.token,
        },
      }
    )
      .then((res) => {
        setCustomerDomains(res.data.all_domains);
      })
      .catch((err) => console.log(err));

    setLoadOptions(false);
  };
  const options = [];
  customerDomains &&
    customerDomains.map((domain) => {
      options.push({
        label: domain,
        value: domain,
      });
    });

  return (
    <div>
      <div className="formDiv">
        <Form
          onMouseEnter={() => getAllDomains(getItem("user"))}
          onSubmit={(e) => {
            e.preventDefault();
          }}
          autoComplete="off"
          className="freeSearchForm customerForm customerFormNew"
        >
          <MultiSelect
            overrideStrings={{
              allItemsAreSelected: "All URLs are selected.",
            }}
            hasSelectAll={true}
            ItemRenderer={DefaultItemRenderer}
            shouldToggleOnHover={true}
            options={options}
            value={selected.length > options.length ? options : selected}
            onChange={setSelected}
            labelledBy="Select"
          />
          <SearchBtn
            domain={props.selectedDomain}
            type={props.type}
            activeDomains={
              selected.length > options.length ? options : selected
            }
            role={props.role}
          />
        </Form>
      </div>
    </div>
  );
}

export default Preset;
