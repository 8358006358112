import axios from "axios";
import {
  APIFullAddress,
  APIFullEmailAddress,
  JSON_API,
  authAddress,
  clearbitApi,
} from "./Config";
const CreateApiClient = () => {
  return {
    fetch_data: async (target, query, config) => {
      try {
        const res = await axios.post(
          `${APIFullAddress}/${target}`,
          query,
          config
        );
        return res.data;
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("request canceled");
          throw new Error("Request canceled");
        } else {
          console.log("server response error");
        }
        if (error.response?.data) {
          throw new Error(error.response.data);
        } else {
          throw new Error("Unknown error occurred");
        }
      }
    },
    fetchData: async (target, query, config) => {
      return await axios
        .post(`${APIFullAddress}/${target}`, query, config)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log("request canceled");
          } else {
            console.log("server response error");
          }
          if (error.response?.data) {
            return error.response.data;
          } else {
            return error;
          }
        });
    },
    fetchApiData: async (target, query, config) => {
      return await axios
        .post(`${JSON_API}/${target}`, query, config)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log("request canceled");
          } else {
            console.log("server response error");
          }
          return error;
        });
    },
    getApiData: async (target, query, config) => {
      return await axios
        .get(`${JSON_API}/${target}`, query, config)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log("request canceled");
          } else {
            console.log("server response error");
          }
          return error;
        });
    },
    fetchSuggestions: async (domain) => {
      return await axios
        .get(`${clearbitApi}/${domain}`)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return error;
        });
    },
    getFilters: async (target) => {
      return await axios
        .get(`${APIFullAddress}/${target}`)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return error;
        });
    },
    sendEmail: async (target, data) => {
      return await axios
        .post(`${APIFullEmailAddress}/${target}`, data)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return error.response.data;
        });
    },
    changePassword: async (target, data, config) => {
      return await axios
        .post(`${APIFullAddress}/${target}`, data, config)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return error;
        });
    },
    registerUser: async (target, data, config) => {
      return await axios
        .post(`${authAddress}/${target}`, data, config)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            return error.response.data;
          } else {
            return error;
          }
        });
    },
    adminReq: async (method, url, headers, data, cancelToken) => {
      let config = {
        method: method,
        url: url,
        cancelToken: cancelToken,
        headers: headers,
        data: data,
      };
      return await axios(config)
        .then((res) => res)
        .catch((err) => err);
    },
    getData: async (target, config) => {
      return await axios
        .get(`${APIFullAddress}/${target}`, config)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log("request canceled");
          } else {
            console.log("server response error");
          }
          return error;
        });
    },
  };
};

export default CreateApiClient;
