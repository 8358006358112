/* eslint-disable */

import React, { useEffect, useState } from "react";
import "./Timeline.css";

import { DiAndroid } from "react-icons/di";
import { FaEye, FaFileExport, FaUserTie, FaUsers } from "react-icons/fa";
import { FaEyeSlash, FaShareNodes } from "react-icons/fa6";
import { IoMdAddCircle, IoMdCheckmarkCircle, IoMdLogIn } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { RiLineChartFill } from "react-icons/ri";

import { RxCrossCircled } from "react-icons/rx";

import animationData from "./iyKeCPKwiy.json";

const icons = [
  {
    name: "timeline",
    icon: <RiLineChartFill size={30} />,
  },
  {
    name: "censored",
    icon: <FaEyeSlash size={30} />,
  },
  {
    name: "employee",
    icon: <FaUserTie size={30} />,
  },
  {
    name: "employees",
    icon: <FaUsers size={30} />,
  },
  {
    name: "user",
    icon: <FaUsers size={30} />,
  },
  {
    name: "users",
    icon: <FaUserTie size={30} />,
  },
  {
    name: "apk",
    icon: <DiAndroid size={30} />,
  },
  {
    name: "apks",
    icon: <DiAndroid size={30} />,
  },
  {
    name: "thirdparty",
    icon: <FaShareNodes size={30} />,
  },
  {
    name: "thirdparties",
    icon: <FaShareNodes size={30} />,
  },
  {
    name: "login",
    icon: <IoMdLogIn size={30} />,
  },
  {
    name: "add",
    icon: <IoMdAddCircle size={30} />,
  },
  {
    name: "delete",
    icon: <MdDelete size={30} />,
  },
  {
    name: "export",
    icon: <FaFileExport size={30} />,
  },
  {
    name: "resolve",
    icon: <IoMdCheckmarkCircle size={30} />,
  },
  {
    name: "unresolve",
    icon: <RxCrossCircled size={30} />,
  },
  {
    name: "view",
    icon: <FaEye size={30} />,
  },
];

export default function Timeline(props) {
  const [maxWidth, setMaxWidth] = useState(0);

  const generateX = (width) => {
    const repeatedText = "x".repeat(props.events.length * props.x);

    return repeatedText;
  };

  const formatDate = (dateString) => {
    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Add 3 hours to the time
    // date.setHours(date.getHours() + 3);

    // Format the date and time
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Return the formatted date string
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const scrollLeft = () => {
    if (props.timelineRef.current) {
      props.timelineRef.current.scrollBy({ left: -1100, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (props.timelineRef.current) {
      props.timelineRef.current.scrollBy({ left: 1100, behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (props.timelineRef.current) {
      setMaxWidth(props.timelineRef.current.offsetWidth);
    }
  }, [props.timelineRef, props.events]);

  const options = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  return (
    <div
      style={{
        position: "relative",
        height: "160px",
        // width: "90%",
        // margin: "auto",
      }}
    >
      <button className="nav-button left" onClick={scrollLeft}>
        &#9664;
      </button>
      {/* <div
        className="lottie-container"
        style={{
          position: "absolute",
          top: "45%",
          left: 0,
          width: "100vw",
          height: "30%",
          zIndex: 0,
          pointerEvents: "none",
        }}
      >
        <Lottie options={options} speed={0.3} />
      </div> */}
      <div className="timeline-container" ref={props.timelineRef}>
        {/* <div
          className="lottie-container"
          style={{
            position: "absolute",
            top: "45%",
            left: 0,
            width: "100vw",
            height: "30%",
            zIndex: 0,
            pointerEvents: "none",
          }}
        >
          <Lottie options={options} speed={0.3} />
        </div> */}
        <div
          className="wavy-line wavy-line-yellow"
          data-text={generateX()}
        ></div>
        <div className="timeline">
          {props.events.map((event, index) => (
            <div key={index} className={`${event.action}_action timeline-item`}>
              <div className="timeline-content grid-center">
                {event.tags && <div className="userTags">{event.tags[0]}</div>}
                <span>
                  {
                    icons.find((i) =>
                      `${event.action} ${event.type}`.includes(i.name)
                    ).icon
                  }
                </span>
                <span className="uppercase" style={{ marginTop: "5px" }}>
                  {event.action}
                </span>
                <span className="capitalize">
                  {`${event?.type?.split("_")?.join(" ")}`}
                </span>
                <span>{formatDate(event.date)}</span>
                <span style={{ textAlign: "center" }}>{event.user}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <button className="nav-button right" onClick={scrollRight}>
        &#9654;
      </button>
    </div>
  );
}
