import React from "react";
import ToggleButtons from "./Toggle";

export default function DataHeader(props) {
  return (
    <div>
      <ToggleButtons
        options={props.options}
        onChange={(e, newValue) => props.onModeChange(e, newValue)}
        value={props.mode}
        ariaLabel="mode"
      />
    </div>
  );
}
