import Collapse from "@material-ui/core/Collapse";
import SvgIcon from "@material-ui/core/SvgIcon";
import { alpha, withStyles } from "@material-ui/core/styles";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import { animated, useSpring } from "@react-spring/web"; // web.cjs is required for IE 11 support
import PropTypes from "prop-types";
import React from "react";
import { FaFile } from "react-icons/fa";
import { RiFolderOpenFill } from "react-icons/ri";
import ReactJson from "react-json-view";
import SimpleAccordion from "../FaqAccordion";
import CustomizedTreeView from "../Tree";
import Block from "../partials/CodeBlock";
import { Schema, client, creds, tree } from "./Schema";

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: "translate3d(20px,0,0)" },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    "& .close": {
      opacity: 0.3,
    },
  },
  group: {
    color: "white",
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha("#1A1C20", 0.4)}`,
  },
}))((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
));

export default function DocsMenu(totalStealers) {
  return [
    {
      id: 1,
      label: "introduction",
      headline: "Cavalier API Reference",
      blocks: [
        <Block
          copy={true}
          code="https://cavalier.hudsonrock.com/api/json/v2"
          language="bash"
          showLineNumbers={false}
          title="Base URL"
        />,
      ],
      content: (
        <>
          <p>
            Hudson Rock provides its clients the ability to query a database of
            over {totalStealers} computers which were compromised through global
            info-stealer campaigns performed by threat actors. The database is
            updated with new compromised computers every day, offering
            cybersecurity providers the ability to alert security teams ahead of
            imminent attacks, when users get compromised and have their
            credentials stolen.
          </p>
          <br />
          <h4>Exclusive Data</h4>
          <p>
            Hudson Rock acquires and purchases compromised data directly from
            top-tier threat actors operating in closed circle hacking groups.
            What sets our data apart is its quality in providing high
            accessibility to hacker groups looking for potential targets, and
            the speed in which we make it available to clients compared to other
            threat intelligence companies.
            <br />
            Our operational knowhow, and our boots-on-the-ground approach to
            cybercrime originates from the IDF's 8200 Cybercrime division, and
            its efforts to thwart nation-state adversaries and professional
            threat actors.
          </p>
          <br />
          <br />
          <h4>
            How is Cavalier different from other cybercrime intelligence
            solutions?
          </h4>
          <p>
            Cavalier stands out among other cybercrime intelligence solutions in
            a number of ways. Unlike other products that rely on publicly
            available, often outdated data from database leaks, Hudson Rock
            focuses on obtaining the most valuable and timely information
            available. <br />
            <br />
            First and foremost, our approach is based on the principle of
            time-sensitivity. We understand that stolen credentials are often
            published long after they've been compromised and the actionable
            threats have been leveraged. That's why we work tirelessly to
            integrate information from recently compromised computers within
            just days of the compromise. This allows us to stay ahead of
            sophisticated threat actors and provide you with actionable
            information to protect against data breaches and ransomware attacks.
            <br />
            <br />
            Additionally, the data we provide is highly relevant to our clients.
            As the most prominent attack vector used by sophisticated hacking
            groups, information from info-stealers is unrivalled in its ability
            to provide initial access to company servers or take over end-user
            accounts. Info stealers data allows hackers to bypass traditional
            security measures such as 2-Factor Authentication by injecting
            cookies from the compromised computers, or finding the backup codes
            for revoking the 2FA stored on the compromised computer.
            <br />
            <br />
            Finally, our approach is not dependent on password reuse, which is
            often not as impactful as passwords stolen from the browsers of
            victims. When a stealer is executed on a machine, all the domains,
            emails, usernames, and passwords that the victim has ever used are
            captured, providing a direct link between the domain they browsed
            into and their emails and plaintext passwords. This type of data is
            much more valuable for protecting against cyber threats. Upgrade to
            Cavalier and take the first step in safeguarding your business.
          </p>
        </>
      ),
    },
    {
      id: 2,
      label: "raw data",
      headline: "raw data virtualisation",
      content: (
        <>
          {/* <div
          className="lightText styledBox no-shadow yellow-bg"
          style={{ padding: "10px" }}
        >
          <h4>General</h4>
          <p className="lightText" style={{ margin: "0px" }}>
            A lot of data is captured by the stealer, all the data that is
            captured is stolen in the purpose of helping threat actors perform
            breaches, Hudson Rock parses the data from the stealers in order to
            help companies avoid all sorts of breaches.
          </p>
        </div> */}
          <h4>General</h4>
          <p style={{ margin: "0px" }}>
            Info-stealers capture a significant amount of data that is then used
            by threat actors to perform attacks. Hudson Rock parses the data so
            it can be easily provided to its clients.
          </p>
          <br />
          <br />
          <div
            style={{
              backgroundColor: "#FCCE4E",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            <a
              href="https://inteltechniques.com/blog/2022/07/06/new-breach-data-lesson-ii-stealer-logs/ "
              target="_blank"
              rel="noreferrer"
            >
              To learn more about the structure of the raw data, please read
              this guide by IntelTechniques. →
            </a>
          </div>

          <br />
          <br />
          <table className="docsTable">
            <tr>
              <th>File/Directory</th>
              <th>Explanation</th>
            </tr>
            <tr>
              <td>passwords.txt</td>
              <td>
                This file contains all the URLs, logins, and passwords stored on
                the compromised computer.
              </td>
            </tr>
            <tr>
              <td>UserInformation.txt</td>
              <td>
                This file contains all the technical information of the
                compromised computer, including the computer's name, IP address,
                path in which the malware was installed, anti-viruses installed
                on the computer, operation system specifications and more.
              </td>
            </tr>
            <tr>
              <td>Cookies</td>
              <td>
                These files contain the cookies that were captured from the
                compromised computer, allowing threat actors to bypass
                traditional security measures such as 2FA by stealing the
                session of the victim.
              </td>
            </tr>
          </table>
        </>
      ),
      blocks: [
        // <div
        //   className="flow-chart-div"
        //   style={{
        //     height: "500px",
        //     width: "95%",
        //     color: "white",
        //     textAlign: "center",
        //     margin: "auto",
        //     marginTop: "2rem",
        //   }}
        // >
        //   <FlowChart data={tree} />
        // </div>,
        <>
          <h5 style={{ textAlign: "left" }}>
            Directory Tree of a Single Compromised Computer
          </h5>
          <div
            style={{
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            <TreeView
              id="docs-dir-tree"
              className="docs-dir-tree"
              style={{ margin: "0px", borderRadius: "10px" }}
              // className={classes.root}
              // defaultExpanded={["2"]}
              defaultExpanded={["1"]}
              defaultCollapseIcon={<MinusSquare />}
              defaultExpandIcon={<RiFolderOpenFill size={25} />}
              defaultEndIcon={<FaFile size={12} />}
            >
              <StyledTreeItem nodeId="1" label={<span>{tree.name}</span>}>
                <CustomizedTreeView data={tree} />
              </StyledTreeItem>
            </TreeView>
          </div>
        </>,
      ],
    },
    // {
    //   id: 2,
    //   label: "authentication",
    //   headline: "authentication",
    //   explain: (
    //     <div
    //       className="styledBox no-shadow"
    //       style={{ width: "95%", margin: "auto", backgroundColor: "#1A1F35" }}
    //     >
    //       <p style={{ marginBottom: "0px", textAlign: "left" }}>
    //         {
    //           "To test requests using your account, replace ROCKHUDSONROCK with your actual API key."
    //         }
    //       </p>
    //     </div>
    //   ),
    //   blocks: [
    //     <Block
    //       copy={true}
    //       code={`curl -L -X POST 'https://cavalier.hudsonrock.com/api/json/v2/search-by-domain' \
    // -H 'api-key: ROCKHUDSONROCK' \
    // -H 'Content-Type: application/json' \
    // --data-raw '{"domains": ["tesla.com"]}'`}
    //       language="bash"
    //       showLineNumbers={false}
    //       title="AUTHENTICATED REQUEST"
    //     />,
    //   ],
    //   content: (
    //     <>
    //       <p>The Cavalier API uses API keys to authenticate requests.</p>
    //       <p>
    //         Your credentials carry many privileges, so be sure to keep them
    //         secure! <br />
    //         Do not share your secret keys in publicly accessible areas such as
    //         GitHub, client-side code, and so forth.
    //         <br />
    //         <br />
    //         Authentication to the API is performed via HTTP Basic Auth.
    //         <br />
    //         Provide your username and password as the basic auth username and
    //         password values and the API key as a header named "api-key".
    //       </p>
    //       <div className="styledBox no-shadow yellow-bg">
    //         <p className="lightText" style={{ fontSize: "16px", margin: "0px" }}>
    //           Please note - we provide free querying of our data in limited
    //           capacity and censored view of sensitive information, we provide the
    //           same information in unlimited volume and the data being uncensored.
    //         </p>
    //       </div>
    //     </>
    //   ),
    // },
    {
      id: 3,
      label: "stealer schema",
      headline: "stealer schema",
      blocks: [
        <div style={{ textAlign: "left" }}>
          <h4>Sample Response</h4>
          <ReactJson
            src={Schema}
            theme="monokai"
            displayDataTypes={true}
            style={{ textAlign: "left" }}
          />
        </div>,
      ],
      content: (
        <>
          <table className="docsTable">
            <tr>
              <th>Key</th>
              <th>Data Type</th>
              <th>Value</th>
            </tr>
            <tr>
              <td>date_uploaded</td>
              <td>ISO Date String</td>
              <td>
                The date in which the stealer was integrated into Hudson Rock’s
                platform.
              </td>
            </tr>
            <tr>
              <td>date_compromised</td>
              <td>ISO Date String</td>
              <td>The date in which the computer was infected.</td>
            </tr>
            <tr>
              <td>stealer</td>
              <td>string</td>
              <td>
                The unique ID of the stealer, typically indicating which stealer
                type it is, but often it is just random. Hudson Rock provides it
                as it was acquired from the threat actors.{" "}
              </td>
            </tr>
            <tr>
              <td>stealer_family</td>
              <td>string</td>
              <td>Indicating which stealer type it is.</td>
            </tr>
            <tr>
              <td>ip</td>
              <td>string</td>
              <td>
                The IP of the infected computer at the time of the infection.{" "}
              </td>
            </tr>
            <tr>
              <td>computer_name</td>
              <td>string</td>
              <td>The name of the compromised computer.</td>
            </tr>
            <tr>
              <td>operating_system</td>
              <td>string</td>
              <td>Operating System of the compromised computer.</td>
            </tr>
            <tr>
              <td>malware_path</td>
              <td>string</td>
              <td>The path in which the stealer malware was installed.</td>
            </tr>
            <tr>
              <td>antiviruses</td>
              <td>array/list</td>
              <td>Anti-viruses installed on the victim's computer.</td>
            </tr>
            <tr>
              <td>employeeAt</td>
              <td>array/list</td>
              <td>
                An aggregation of all the companies (domains) that the retrieved
                computer is found to be an employee at, based on the credentials
                from their computer.
              </td>
            </tr>
            <tr>
              <td>clientAt</td>
              <td>array/list</td>
              <td>
                An aggregation of all the companies (domains) that the retrieved
                computer is found to be a client at, based on the credentials
                from their computer
              </td>
            </tr>
            <tr>
              <td>credentials</td>
              <td>array/list</td>
              <td>
                Array of objects where each one contains the field type, url,
                domain, username and password.
              </td>
            </tr>
            <tr>
              <td>employee_session_cookies</td>
              <td>array/list</td>
              <td>
                cookies that were captured from the compromised computer,
                allowing threat actors to bypass traditional security measures
                such as 2FA by stealing the session of the victim.
              </td>
            </tr>
            <tr>
              <td>installed_software</td>
              <td>array/list</td>
              <td>
                Array of installed software names and versions which were found
                on the compromised computer.
              </td>
            </tr>
          </table>
          <br />
          {/* <div
          className="styledBox no-shadow yellow-bg cursor"
          onClick={() => clickTab("docs-tab-3.5")}
        >
          <p className="lightText" style={{ fontSize: "16px", margin: "0px" }}>
            Learn more about the credentials field in "credentials" section or
            click here.
          </p>
        </div> */}
        </>
      ),
    },
    {
      id: 3.5,
      label: "credentials",
      headline: "credentials",
      blocks: [
        <div style={{ textAlign: "left", marginTop: "3rem" }}>
          <h4 style={{ color: "#FBCD4E" }}>Employee Credentials</h4>
          <ReactJson
            src={creds}
            theme="monokai"
            displayDataTypes={false}
            style={{ textAlign: "left" }}
          />
          <br />
          <h4 style={{ color: "#FBCD4E" }}>User Credentials</h4>
          <ReactJson
            src={client}
            theme="monokai"
            displayDataTypes={false}
            style={{ textAlign: "left" }}
          />
        </div>,
      ],
      content: (
        <>
          <p>
            Every credential presented in Cavalier was stolen directly from the
            browsers of victims who had a stealer type malware executed on their
            machine. These malwares are spread by tricking a victim into
            downloading a malicious file through various methods. The attacks
            are usually performed by threat actors in global opportunistic
            campaigns, with little discretion, so employees in companies that
            aren't directly targeted often have their corporate credentials
            stolen.
          </p>
          <br />
          <h4 style={{ color: "#FBCD4E" }}>
            How do we differentiate between compromised employees and
            compromised users?
          </h4>
          <p>
            <br />
            Hudson Rock uses various forms of logic to differentiate between an
            organization’s compromised employees and compromised end-users.
            Credentials containing an email address of a company (i.e
            @Tesla.com) that are used to access a Tesla.com domain most likely
            belong to an employee of that company, while a @gmail.com email
            address accessing the same domain is likely an end-user.
            <br />
            <br />
            Additionally, the type of infrastructure used by a domain also helps
            differentiate between the employees and users, for example: The
            username “john” signing into citrix.tesla.com / tesla.com/vpn/login
            / webmail.tesla.com will be categorised as an employee
          </p>
          <h5>Schema</h5>
          <table className="docsTable">
            <tr>
              <th>Key</th>
              <th>Data Type</th>
              <th>Value</th>
            </tr>
            <tr>
              <td>type</td>
              <td>string</td>
              <td>
                Indicates whether the credentials found are corporate (of an
                employee) or not (of a user)
              </td>
            </tr>
            <tr>
              <td>url</td>
              <td>string</td>
              <td>The URL the victim entered credentials into.</td>
            </tr>
            <tr>
              <td>domain</td>
              <td>string</td>
              <td>Only the domain without any additions.</td>
            </tr>
            <tr>
              <td>username</td>
              <td>string</td>
              <td>The login entered by the victim.</td>
            </tr>
            <tr>
              <td>password</td>
              <td>string</td>
              <td>The password entered by the victim.</td>
            </tr>
          </table>
          <br />
        </>
      ),
    },
    {
      id: 5,
      label: "API Endpoints",
      parent: true,
      children: [
        {
          id: 5.1,
          label: "getting started",
          headline: "authentication",
          explain: (
            <div
              className="styledBox no-shadow"
              style={{
                width: "95%",
                margin: "auto",
                backgroundColor: "#1A1F35",
              }}
            >
              <p style={{ marginBottom: "0px", textAlign: "left" }}>
                {
                  "To test requests using your account, replace ROCKHUDSONROCK with your actual API key."
                }
              </p>
            </div>
          ),
          blocks: [
            <Block
              copy={true}
              code={`curl -L -X POST 'https://cavalier.hudsonrock.com/api/json/v2/search-by-domain' \
      -H 'api-key: ROCKHUDSONROCK' \
      -H 'Content-Type: application/json' \
      --data-raw '{"domains": ["tesla.com"]}'`}
              language="bash"
              showLineNumbers={false}
              title="AUTHENTICATED REQUEST"
            />,
          ],
          content: (
            <>
              <p>The Cavalier API uses API keys to authenticate requests.</p>
              <p>
                Your credentials carry many privileges, so be sure to keep them
                secure! <br />
                Do not share your secret keys in publicly accessible areas such
                as GitHub, client-side code, and so forth.
                <br />
                <br />
                Authentication to the API is performed via API key.
                <br />
                Provide your API key as a header named "api-key".
              </p>
              <br />
              <div className="styledBox no-shadow yellow-bg">
                <p
                  className="lightText"
                  style={{ fontSize: "16px", margin: "0px" }}
                >
                  Please be aware that Hudson Rock offers a free service for
                  querying data with a limited capacity and censored view of
                  sensitive information. However, for those who require more
                  extensive data access, we also provide the same information
                  without query limitations and in its uncensored form.
                </p>
              </div>
              <br />
              <h5>Rate Limit:</h5>
              <p>50 requests per 10 second</p>
              <h5>Pagination:</h5>
              <p>50 stealers per page</p>
              {/* <h5>Request Timeout:</h5>
              <p>100 seconds</p> */}
            </>
          ),
        },
        {
          id: 5.12,
          label: "End user protection",
          headline: "End user protection",
          parent: true,
          children: [
            {
              id: 5.2,
              endpoint: "search-by-login",
              label: "Search By Login",
              headline: "Search By Login",
              sample: true,
              explain: (
                <div
                  className="styledBox no-shadow"
                  style={{
                    width: "95%",
                    margin: "auto",
                    backgroundColor: "#1A1F35",
                  }}
                >
                  <p style={{ marginBottom: "0px", textAlign: "left" }}>
                    {
                      "To test requests using your account, replace ROCKHUDSONROCK with your actual API key."
                    }
                  </p>
                </div>
              ),
              blocks: [
                <Block
                  copy={true}
                  code={`curl -L -X POST 'https://cavalier.hudsonrock.com/api/json/v2/search-by-login' \
        -H 'api-key: ROCKHUDSONROCK' \
        -H 'Content-Type: application/json' \
        --data-raw '{"login": "john@gmail.com"}'`}
                  language="bash"
                  showLineNumbers={false}
                  title="SINGLE EMAIL REQUEST"
                />,
              ],
              content: (
                <>
                  <p>
                    The Problem: Computers get compromised through global
                    malware spreading campaigns, allowing the sensitive
                    credentials stored on the compromised computers to be used
                    against the victims in attacks ranging from account
                    over-take, to network over-takes, and even sophisticated
                    social engineering attacks.
                  </p>

                  <p>
                    The Solution: Hudson Rock provides its clients the ability
                    to query a database of over {totalStealers} computers which
                    were compromised through info-stealers. The database is
                    updated with new compromised computers every day, offering
                    companies the ability to alert users when their computers
                    get compromised before their credentials are taken advantage
                    of.{" "}
                  </p>
                  <br />
                  <div
                    style={{
                      backgroundColor: "#FCCE4E",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <a
                      href="https://www.hudsonrock.com/blog/cyrus-part-1-info-stealers"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Click here to see how Identity Protection company, Cyrus,
                      uses this endpoint to protect its customers. →
                    </a>
                  </div>
                  <br />
                  <br />
                  <h4>URL Parameters:</h4>
                  <table className="docsTable">
                    <tr>
                      <th>Parameter</th>
                      <th>Options/Data Type</th>
                      <th>Default</th>
                      <th>Explanation</th>
                    </tr>
                    <tr>
                      <td>sortby</td>
                      <td>
                        1.date_compromised
                        <br />
                        2.date_uploaded
                      </td>
                      <td>date_compromised</td>
                      <td>
                        The API allows for sorting of the machine records by
                        date of compromise or date added to Hudson Rock's
                        system, with the results being returned in descending
                        order.
                      </td>
                    </tr>
                    <tr>
                      <td>page</td>
                      <td>Number</td>
                      <td>1</td>
                      <td>
                        The API utilises data pagination, where a maximum of 50
                        documents (stealers) per request are returned. When
                        querying for a specific page, such as page 2, the API
                        will skip the first 50 documents and return the next 50.
                      </td>
                    </tr>
                    <tr>
                      <td>installed_software</td>
                      <td>Boolean (true/false)</td>
                      <td>false</td>
                      <td>
                        When set to true, installed software from the
                        compromised computer will be shown.
                      </td>
                    </tr>
                  </table>
                </>
              ),
            },
            {
              id: 5.3,
              endpoint: "search-by-login/bulk",
              example: ["john@gmail.com", "johndoe@gmail.com"],
              label: "Search By Login (Bulk)",
              headline: "Search By Login (Bulk)",
              sample: true,
              explain: (
                <div
                  className="styledBox no-shadow"
                  style={{
                    width: "95%",
                    margin: "auto",
                    backgroundColor: "#1A1F35",
                  }}
                >
                  <p style={{ marginBottom: "0px", textAlign: "left" }}>
                    {
                      "To test requests using your account, replace ROCKHUDSONROCK with your actual API key."
                    }
                  </p>
                </div>
              ),
              blocks: [
                <Block
                  copy={true}
                  code={`curl -L -X POST 'https://cavalier.hudsonrock.com/api/json/v2/search-by-login/bulk' \
        -H 'api-key: ROCKHUDSONROCK' \
        -H 'Content-Type: application/json' \
        --data-raw '{"logins": ["johndoe@gmail.com", "john@gmail.com"]}'`}
                  language="bash"
                  showLineNumbers={false}
                  title="SINGLE EMAIL REQUEST"
                />,
                <Block
                  copy={true}
                  code={`curl -L -X POST 'https://cavalier.hudsonrock.com/api/json/v2/search-by-login/bulk?sortby=date_uploaded' \
                -H 'api-key: ROCKHUDSONROCK' \
                -H 'Content-Type: application/json' \
                --data-raw '{"logins": ["johndoe@gmail.com", "john@gmail.com"]}'`}
                  language="bash"
                  showLineNumbers={false}
                  title="SINGLE EMAIL REQUEST (SORTED BY DATE UPLOADED)"
                />,
                <Block
                  copy={true}
                  code={`curl -L -X POST 'https://cavalier.hudsonrock.com/api/json/v2/search-by-login/bulk?sortby=date_uploaded&page=2' \
                        -H 'api-key: ROCKHUDSONROCK' \
                        -H 'Content-Type: application/json' \
                        --data-raw '{"logins": ["johndoe@gmail.com", "john@gmail.com"]}'`}
                  language="bash"
                  showLineNumbers={false}
                  title="SINGLE EMAIL REQUEST (SORTED BY DATE UPLOADED) - PAGE 2"
                />,
              ],
              content: (
                <>
                  <p>
                    The Problem: Computers get compromised through global
                    malware spreading campaigns, allowing the sensitive
                    credentials stored on the compromised computers to be used
                    against the victims in attacks ranging from account
                    over-take, to network over-takes, and even sophisticated
                    social engineering attacks.
                  </p>

                  <p>
                    The Solution: Hudson Rock provides its clients the ability
                    to query a database of over {totalStealers} computers which
                    were compromised through info-stealers. The database is
                    updated with new compromised computers every day, offering
                    companies the ability to alert users when their computers
                    get compromised before their credentials are taken advantage
                    of.{" "}
                  </p>
                  <br />

                  <h4>URL Parameters:</h4>
                  <table className="docsTable">
                    <tr>
                      <th>Parameter</th>
                      <th>Options/Data Type</th>
                      <th>Default</th>
                      <th>Explanation</th>
                    </tr>
                    <tr>
                      <td>sortby</td>
                      <td>
                        1.date_compromised
                        <br />
                        2.date_uploaded
                      </td>
                      <td>date_compromised</td>
                      <td>
                        The API allows for sorting of the machine records by
                        date of compromise or date added to Hudson Rock's
                        system, with the results being returned in descending
                        order.
                      </td>
                    </tr>
                    <tr>
                      <td>page</td>
                      <td>Number</td>
                      <td>1</td>
                      <td>
                        The API utilises data pagination, where a maximum of 50
                        documents (stealers) per request are returned. When
                        querying for a specific page, such as page 2, the API
                        will skip the first 50 documents and return the next 50.
                      </td>
                    </tr>
                    <tr>
                      <td>installed_software</td>
                      <td>Boolean (true/false)</td>
                      <td>false</td>
                      <td>
                        When set to true, installed software from the
                        compromised computer will be shown.
                      </td>
                    </tr>
                  </table>
                  <br />
                  <h4>Body:</h4>
                  <table className="docsTable">
                    <tr>
                      <th>Parameter</th>
                      <th>Options/Data Type</th>
                      <th>Limitations</th>
                      <th>Example</th>
                    </tr>
                    <tr>
                      <td>logins</td>
                      <td>Array</td>
                      <td>Maximum of 100 email addresses for each request.</td>
                      <td>["johndoe@gmail.com", "john@gmail.com"]</td>
                    </tr>
                  </table>
                </>
              ),
            },
            {
              id: 5.5,
              endpoint: "search-by-ip",
              example: "190.3.30.97",
              label: "Search By IP",
              headline: "Search By IP",
              sample: true,
              params: [
                {
                  name: "page",
                  type: "number",
                  options: Array.from(Array(11).keys()).filter((s) => s > 0),
                  default: 1,
                  width: "90px",
                },
                {
                  name: "added_since",
                  type: "date",
                },
                {
                  name: "added_until",
                  type: "date",
                },
                {
                  name: "compromised_since",
                  type: "date",
                },
                {
                  name: "compromised_until",
                  type: "date",
                },
              ],
              explain: (
                <div
                  className="styledBox no-shadow"
                  style={{
                    width: "95%",
                    margin: "auto",
                    backgroundColor: "#1A1F35",
                  }}
                >
                  <p style={{ marginBottom: "0px", textAlign: "left" }}>
                    {
                      "To test requests using your account, replace ROCKHUDSONROCK with your actual API key."
                    }
                  </p>
                </div>
              ),
              blocks: [
                <Block
                  copy={true}
                  code={`curl -L -X POST 'https://cavalier.hudsonrock.com/api/json/v2/search-by-ip' \
                -H 'api-key: ROCKHUDSONROCK' \
                -H 'Content-Type: application/json' \
    --data-raw '{"ip": "190.3.30.97"}'`}
                  language="bash"
                  showLineNumbers={false}
                  title="SINGLE IP REQUEST"
                />,
                <Block
                  copy={true}
                  code={`curl -L -X POST 'https://cavalier.hudsonrock.com/api/json/v2/search-by-ip' \
                        -H 'api-key: ROCKHUDSONROCK' \
                        -H 'Content-Type: application/json' \
            --data-raw '{"ip": "45.166.26.62/28"}'`}
                  language="bash"
                  showLineNumbers={false}
                  title="CIDR REQUEST"
                />,
              ],
              content: (
                <>
                  <p>
                    The Problem: Cybersecurity professionals are tasked with
                    protecting companies from ransomware attacks, network
                    over-takes, and corporate espionage. In recent years,
                    corporate credentials stolen from computers are a prominent
                    attack vector used by cybercriminals to infiltrate corporate
                    networks.{" "}
                  </p>
                  <p>
                    The Solution: Hudson Rock provides its clients the ability
                    to query a database of over {totalStealers} computers which
                    were compromised through global info-stealer campaigns
                    performed by threat actors. The database is updated with new
                    compromised computers every day, offering cybersecurity
                    providers the ability to alert security teams ahead of
                    imminent attacks, when users get compromised and have their
                    credentials stolen.
                  </p>
                  <div
                    style={{
                      padding: "1rem",
                      backgroundColor: "#FECD4E",
                      borderRadius: "20px",
                      color: "black",
                    }}
                  >
                    <h6 style={{ margin: "0px" }}>
                      This endpoint also supports CIDR lookup, for an example
                      response search for the following term: 45.166.26.62/28{" "}
                    </h6>
                    <br />
                    <span>*CIDR must have a suffix between 21-32</span>
                  </div>
                  <br />
                  <h4>URL Parameters:</h4>
                  <table className="docsTable">
                    <tr>
                      <th>Parameter</th>
                      <th>Options/Data Type</th>
                      <th>Default</th>
                      <th>Explanation</th>
                    </tr>
                    <tr>
                      <td>page</td>
                      <td>Number</td>
                      <td>1</td>
                      <td>
                        The API utilises data pagination, where a maximum of 50
                        documents (stealers) per request are returned. When
                        querying for a specific page, such as page 2, the API
                        will skip the first 50 documents and return the next 50.
                        <br />
                        When there are no more results in the response, a square
                        bracket will be shown "[]".
                      </td>
                    </tr>
                    <tr>
                      <td>added_since</td>
                      <td>
                        ISO Date: YYYY-MM-DDThh:mm:ss.sssZ
                        <br />
                        e.g: {new Date().toISOString()}
                      </td>
                      <td>All Time</td>
                      <td>
                        Filter results to show only stealers which were added
                        since a specified date. Results will be sorted according
                        to this parameter in an descending order.
                      </td>
                    </tr>
                    <tr>
                      <td>added_until</td>
                      <td>
                        ISO Date: YYYY-MM-DDThh:mm:ss.sssZ
                        <br />
                        e.g: {new Date().toISOString()}
                      </td>{" "}
                      <td>All Time</td>
                      <td>
                        Filter results to show only stealers which were added
                        until a specified date. Results will be sorted according
                        to this parameter in an descending order.
                      </td>
                    </tr>
                    <tr>
                      <td>compromised_since</td>
                      <td>
                        ISO Date: YYYY-MM-DDThh:mm:ss.sssZ
                        <br />
                        e.g: {new Date().toISOString()}
                      </td>{" "}
                      <td>All Time</td>
                      <td>
                        Filter results to show only stealers which were
                        compromised since a specified date. Results will be
                        sorted according to this parameter in an descending
                        order.
                      </td>
                    </tr>
                    <tr>
                      <td>compromised_until</td>
                      <td>
                        ISO Date: YYYY-MM-DDThh:mm:ss.sssZ
                        <br />
                        e.g: {new Date().toISOString()}
                      </td>{" "}
                      <td>All Time</td>
                      <td>
                        Filter results to show only stealers which were
                        compromised until a specified date. Results will be
                        sorted according to this parameter in an descending
                        order.
                      </td>
                    </tr>
                    <tr>
                      <td>installed_software</td>
                      <td>Boolean (true/false)</td>
                      <td>false</td>
                      <td>
                        When set to true, installed software from the
                        compromised computer will be shown.
                      </td>
                    </tr>
                  </table>
                </>
              ),
            },
            {
              id: 5.9,
              endpoint: "search-by-stealer",
              example: "EG_196.158.196.83",
              label: "Search By Stealer",
              headline: "Search By Stealer",
              sample: true,
              params: [
                {
                  name: "sortby",
                  type: "date",
                },
              ],
              explain: (
                <div
                  className="styledBox no-shadow"
                  style={{
                    width: "95%",
                    margin: "auto",
                    backgroundColor: "#1A1F35",
                  }}
                >
                  <p style={{ marginBottom: "0px", textAlign: "left" }}>
                    {
                      "To test requests using your account, replace ROCKHUDSONROCK with your actual API key."
                    }
                  </p>
                </div>
              ),
              blocks: [
                <Block
                  copy={true}
                  code={`curl -L -X POST 'https://cavalier.hudsonrock.com/api/json/v2/search-by-stealer' \
                -H 'api-key: ROCKHUDSONROCK' \
                -H 'Content-Type: application/json' \
    --data-raw '{"stealers": ["EG_196.158.196.83"]}'`}
                  language="bash"
                  showLineNumbers={false}
                  title="SINGLE STEALER REQUEST"
                />,
              ],
              content: (
                <>
                  <p>
                    The Problem: Cybersecurity professionals are tasked with
                    protecting companies from ransomware attacks, network
                    over-takes, and corporate espionage. In recent years,
                    corporate credentials stolen from computers are a prominent
                    attack vector used by cybercriminals to infiltrate corporate
                    networks.{" "}
                  </p>
                  <p>
                    The Solution: Hudson Rock provides its clients the ability
                    to query a database of over {totalStealers} computers which
                    were compromised through global info-stealer campaigns
                    performed by threat actors. The database is updated with new
                    compromised computers every day, offering cybersecurity
                    providers the ability to alert security teams ahead of
                    imminent attacks, when users get compromised and have their
                    credentials stolen.
                  </p>
                  <br />
                  <h4>URL Parameters:</h4>
                  <table className="docsTable">
                    <tr>
                      <th>Parameter</th>
                      <th>Options/Data Type</th>
                      <th>Default</th>
                      <th>Explanation</th>
                    </tr>
                    <tr>
                      <td>sortby</td>
                      <td>
                        date_compromised <br />
                        date_uploaded{" "}
                      </td>
                      <td>date_compromised</td>
                      <td>
                        The API allows for sorting of the machine records by
                        date of compromise or date added to Hudson Rock's
                        system, with the results being returned in descending
                        order.
                      </td>
                    </tr>
                  </table>
                  <br />
                  <h4>Body:</h4>
                  <table className="docsTable">
                    <tr>
                      <th>Parameter</th>
                      <th>Options/Data Type</th>
                      <th>Limitations</th>
                      <th>Example</th>
                    </tr>
                    <tr>
                      <td>stealers</td>
                      <td>Array where each item represents a stealer name.</td>
                      <td>Maximum of 20 stealer IDs for each request.</td>
                      <td style={{ width: "100px" }}>
                        ["EG_196.158.196.83", "MY_14.192.208.234"]
                      </td>
                    </tr>
                  </table>
                </>
              ),
            },
          ],
        },
        // {
        //   id: 5.2,
        //   endpoint: "search-by-login",
        //   label: "End user protection",
        //   headline: "End user protection",
        //   sample: true,
        //   explain: (
        //     <div
        //       className="styledBox no-shadow"
        //       style={{
        //         width: "95%",
        //         margin: "auto",
        //         backgroundColor: "#1A1F35",
        //       }}
        //     >
        //       <p style={{ marginBottom: "0px", textAlign: "left" }}>
        //         {
        //           "To test requests using your account, replace ROCKHUDSONROCK with your actual API key."
        //         }
        //       </p>
        //     </div>
        //   ),
        //   blocks: [
        //     <Block
        //       copy={true}
        //       code={`curl -L -X POST 'https://cavalier.hudsonrock.com/api/json/v2/search-by-login' \
        // -H 'api-key: ROCKHUDSONROCK' \
        // -H 'Content-Type: application/json' \
        // --data-raw '{"login": "john@gmail.com"}'`}
        //       language="bash"
        //       showLineNumbers={false}
        //       title="SINGLE EMAIL REQUEST"
        //     />,
        //   ],
        //   content: (
        //     <>
        //       <p>
        //         The Problem: Computers get compromised through global malware
        //         spreading campaigns, allowing the sensitive credentials stored
        //         on the compromised computers to be used against the victims in
        //         attacks ranging from account over-take, to network over-takes,
        //         and even sophisticated social engineering attacks.
        //       </p>

        //       <p>
        //         The Solution: Hudson Rock provides its clients the ability to
        //         query a database of over {totalStealers} computers which were
        //         compromised through info-stealers. The database is updated with
        //         new compromised computers every day, offering companies the
        //         ability to alert users when their computers get compromised
        //         before their credentials are taken advantage of.{" "}
        //       </p>
        //       <br />
        //       <div
        //         style={{
        //           backgroundColor: "#FCCE4E",
        //           padding: "10px",
        //           borderRadius: "10px",
        //         }}
        //       >
        //         <a
        //           href="https://www.hudsonrock.com/blog/cyrus-part-1-info-stealers"
        //           target="_blank" rel="noreferrer"
        //         >
        //           Click here to see how Identity Protection company, Cyrus, uses
        //           this endpoint to protect its customers. →
        //         </a>
        //       </div>
        //       <br />
        //       <br />
        //       <h4>URL Parameters:</h4>
        //       <table className="docsTable">
        //         <tr>
        //           <th>Parameter</th>
        //           <th>Options/Data Type</th>
        //           <th>Default</th>
        //           <th>Explanation</th>
        //         </tr>
        //         <tr>
        //           <td>sortby</td>
        //           <td>
        //             1.date_compromised
        //             <br />
        //             2.date_uploaded
        //           </td>
        //           <td>date_compromised</td>
        //           <td>
        //             The API allows for sorting of the machine records by date of
        //             compromise or date added to Hudson Rock's system, with the
        //             results being returned in descending order.
        //           </td>
        //         </tr>
        //         <tr>
        //           <td>page</td>
        //           <td>Number</td>
        //           <td>1</td>
        //           <td>
        //             The API utilises data pagination, where a maximum of 50
        //             documents (stealers) per request are returned. When querying
        //             for a specific page, such as page 2, the API will skip the
        //             first 50 documents and return the next 50.
        //           </td>
        //         </tr>
        //         <tr>
        //           <td>installed_software</td>
        //           <td>Boolean (true/false)</td>
        //           <td>false</td>
        //           <td>
        //             When set to true, installed software from the compromised
        //             computer will be shown.
        //           </td>
        //         </tr>
        //       </table>
        //     </>
        //   ),
        // },
        // {
        //   id: 5.3,
        //   endpoint: "search-by-login/bulk",
        //   example: ["john@gmail.com", "johndoe@gmail.com"],
        //   label: "End user protection (Bulk)",
        //   headline: "End user protection",
        //   sample: true,
        //   explain: (
        //     <div
        //       className="styledBox no-shadow"
        //       style={{
        //         width: "95%",
        //         margin: "auto",
        //         backgroundColor: "#1A1F35",
        //       }}
        //     >
        //       <p style={{ marginBottom: "0px", textAlign: "left" }}>
        //         {
        //           "To test requests using your account, replace ROCKHUDSONROCK with your actual API key."
        //         }
        //       </p>
        //     </div>
        //   ),
        //   blocks: [
        //     <Block
        //       copy={true}
        //       code={`curl -L -X POST 'https://cavalier.hudsonrock.com/api/json/v2/search-by-login/bulk' \
        // -H 'api-key: ROCKHUDSONROCK' \
        // -H 'Content-Type: application/json' \
        // --data-raw '{"logins": ["johndoe@gmail.com", "john@gmail.com"]}'`}
        //       language="bash"
        //       showLineNumbers={false}
        //       title="SINGLE EMAIL REQUEST"
        //     />,
        //     <Block
        //       copy={true}
        //       code={`curl -L -X POST 'https://cavalier.hudsonrock.com/api/json/v2/search-by-login/bulk?sortby=date_uploaded' \
        //         -H 'api-key: ROCKHUDSONROCK' \
        //         -H 'Content-Type: application/json' \
        //         --data-raw '{"logins": ["johndoe@gmail.com", "john@gmail.com"]}'`}
        //       language="bash"
        //       showLineNumbers={false}
        //       title="SINGLE EMAIL REQUEST (SORTED BY DATE UPLOADED)"
        //     />,
        //     <Block
        //       copy={true}
        //       code={`curl -L -X POST 'https://cavalier.hudsonrock.com/api/json/v2/search-by-login/bulk?sortby=date_uploaded&page=2' \
        //                 -H 'api-key: ROCKHUDSONROCK' \
        //                 -H 'Content-Type: application/json' \
        //                 --data-raw '{"logins": ["johndoe@gmail.com", "john@gmail.com"]}'`}
        //       language="bash"
        //       showLineNumbers={false}
        //       title="SINGLE EMAIL REQUEST (SORTED BY DATE UPLOADED) - PAGE 2"
        //     />,
        //   ],
        //   content: (
        //     <>
        //       <p>
        //         The Problem: Computers get compromised through global malware
        //         spreading campaigns, allowing the sensitive credentials stored
        //         on the compromised computers to be used against the victims in
        //         attacks ranging from account over-take, to network over-takes,
        //         and even sophisticated social engineering attacks.
        //       </p>

        //       <p>
        //         The Solution: Hudson Rock provides its clients the ability to
        //         query a database of over {totalStealers} computers which were
        //         compromised through info-stealers. The database is updated with
        //         new compromised computers every day, offering companies the
        //         ability to alert users when their computers get compromised
        //         before their credentials are taken advantage of.{" "}
        //       </p>
        //       <br />

        //       <h4>URL Parameters:</h4>
        //       <table className="docsTable">
        //         <tr>
        //           <th>Parameter</th>
        //           <th>Options/Data Type</th>
        //           <th>Default</th>
        //           <th>Explanation</th>
        //         </tr>
        //         <tr>
        //           <td>sortby</td>
        //           <td>
        //             1.date_compromised
        //             <br />
        //             2.date_uploaded
        //           </td>
        //           <td>date_compromised</td>
        //           <td>
        //             The API allows for sorting of the machine records by date of
        //             compromise or date added to Hudson Rock's system, with the
        //             results being returned in descending order.
        //           </td>
        //         </tr>
        //         <tr>
        //           <td>page</td>
        //           <td>Number</td>
        //           <td>1</td>
        //           <td>
        //             The API utilises data pagination, where a maximum of 50
        //             documents (stealers) per request are returned. When querying
        //             for a specific page, such as page 2, the API will skip the
        //             first 50 documents and return the next 50.
        //           </td>
        //         </tr>
        //         <tr>
        //           <td>installed_software</td>
        //           <td>Boolean (true/false)</td>
        //           <td>false</td>
        //           <td>
        //             When set to true, installed software from the compromised
        //             computer will be shown.
        //           </td>
        //         </tr>
        //       </table>
        //       <br />
        //       <h4>Body:</h4>
        //       <table className="docsTable">
        //         <tr>
        //           <th>Parameter</th>
        //           <th>Options/Data Type</th>
        //           <th>Limitations</th>
        //           <th>Example</th>
        //         </tr>
        //         <tr>
        //           <td>logins</td>
        //           <td>Array</td>
        //           <td>Maximum of 100 email addresses for each request.</td>
        //           <td>["johndoe@gmail.com", "john@gmail.com"]</td>
        //         </tr>
        //       </table>
        //     </>
        //   ),
        // },
        {
          id: 5.4,
          limit: 5,
          endpoint: "search-by-domain",
          example: "tesla.com",
          label: "Domain intelligence",
          headline: "Domain intelligence",
          sample: true,
          explain: (
            <div
              className="styledBox no-shadow"
              style={{
                width: "95%",
                margin: "auto",
                backgroundColor: "#1A1F35",
              }}
            >
              <p style={{ marginBottom: "0px", textAlign: "left" }}>
                {
                  "To test requests using your account, replace ROCKHUDSONROCK with your actual API key."
                }
              </p>
            </div>
          ),
          blocks: [
            <Block
              copy={true}
              code={`curl -L -X POST 'https://cavalier.hudsonrock.com/api/json/v2/search-by-domain?compromised_since=${new Date(
                new Date().setDate(new Date().getDate() - 90)
              )
                .toISOString()
                .substr(0, 10)}&compromised_until=${new Date()
                .toISOString()
                .substr(0, 10)}' \
                -H 'api-key: ROCKHUDSONROCK' \
                -H 'Content-Type: application/json' \
                --data-raw '{"domains": ["tesla.com", "disney.com"]}'`}
              language="bash"
              showLineNumbers={false}
              title="MULTIPLE DOMAINS REQUEST, LAST 90 DAYS RESULTS, SORTED BY DATE COMPROMISED"
            />,
            <Block
              copy={true}
              code={`curl -L -X POST 'https://cavalier.hudsonrock.com/api/json/v2/search-by-domain?type=employees&page=2' \
        -H 'api-key: ROCKHUDSONROCK' \
        -H 'Content-Type: application/json' \
        --data-raw '{"domains": ["tesla.com", "disney.com"]}'`}
              language="bash"
              showLineNumbers={false}
              title="MULTIPLE DOMAINS REQUEST, EMPLOYEES ONLY, PAGE 2"
            />,
          ],
          params: [
            // {
            //   name: "sortby",
            //   type: "string",
            //   options: ["date_compromised", "date_uploaded"],
            //   default: "date_compromised",
            //   width: "170px",
            // },
            {
              name: "page",
              type: "number",
              options: Array.from(Array(11).keys()).filter((s) => s > 0),
              default: 1,
              width: "90px",
            },
            {
              name: "type",
              type: "string",
              options: ["employees", "users", "all"],
              default: "all",
              width: "90px",
            },
            // {
            //   name: "count",
            //   type: "bool",
            //   options: ["true", "false"],
            //   default: "false",
            //   width: "90px",
            // },
            // {
            //   name: "filtered",
            //   type: "bool",
            //   options: ["true", "false"],
            //   default: "false",
            //   width: "90px",
            // },
            {
              name: "third_party_domains",
              type: "bool",
              options: ["true", "false"],
              default: "false",
              width: "90px",
            },
            {
              name: "added_since",
              type: "date",
            },
            {
              name: "added_until",
              type: "date",
            },
            {
              name: "compromised_since",
              type: "date",
            },
            {
              name: "compromised_until",
              type: "date",
            },
          ],
          content: (
            <>
              <p>
                The Problem: Cybersecurity professionals are tasked with
                protecting companies from ransomware attacks, network
                over-takes, and corporate espionage. In recent years, corporate
                credentials stolen from computers are a prominent attack vector
                used by cybercriminals to infiltrate corporate networks.{" "}
              </p>

              <p>
                The Solution: Hudson Rock provides its clients the ability to
                query a database of over {totalStealers} computers which were
                compromised through global info-stealer campaigns performed by
                threat actors. The database is updated with new compromised
                computers every day, offering cybersecurity providers the
                ability to alert security teams ahead of imminent attacks, when
                users get compromised and have their credentials stolen.
              </p>
              <p>
                Please note that specifying{" "}
                <span
                  style={{
                    backgroundColor: "#3f3f3f",
                    padding: "4px",
                    borderRadius: "4px",
                  }}
                >
                  type=employee
                </span>{" "}
                in the API call does not directly mirror the functionality of
                the "compromised employee" tab within the Cavalier platform.
                Instead, it indicates whether a sensitive keyword was detected
                within the URL (e.g., "confluence" in
                "confluence.hudsonrock.com") or if a corporate email address
                (e.g., john@hudsonrock.com) accessed the domain (e.g.,
                hudsonrock.com). In practical terms, if the URL does not contain
                sensitive information, the endpoint will categorize the access
                as{" "}
                <span
                  style={{
                    backgroundColor: "#3f3f3f",
                    padding: "4px",
                    borderRadius: "4px",
                  }}
                >
                  type=users
                </span>
                , even if it is associated with a device that holds corporate
                credentials for that domain.
                <br />
                <br />
                For comprehensive data retrieval and results akin to those in
                the "compromised employee" tab, please utilize the{" "}
                <span
                  style={{
                    backgroundColor: "#3f3f3f",
                    padding: "4px",
                    borderRadius: "4px",
                  }}
                >
                  type=all
                </span>{" "}
                parameter in your request.
              </p>
              <br />
              <h4>URL Parameters:</h4>
              <table className="docsTable">
                <tr>
                  <th>Parameter</th>
                  <th>Options/Data Type</th>
                  <th>Default</th>
                  <th>Explanation</th>
                </tr>
                {/* <tr
              >
                <td>sortby</td>
                <td>
                  1.date_compromised
                  <br />
                  2.date_uploaded
                </td>
                <td>date_compromised</td>
                <td>
                  The API allows for sorting of the machine records by date of
                  compromise or date added to Hudson Rock's system, with the
                  results being returned in descending order.
                </td>
              </tr> */}
                <tr>
                  <td>page</td>
                  <td>Number</td>
                  <td>1</td>
                  <td>
                    The API utilises data pagination, where a maximum of 50
                    documents (stealers) per request are returned. When querying
                    for a specific page, such as page 2, the API will skip the
                    first 50 documents and return the next 50.
                    <br />
                    When there are no more results in the response, a square
                    bracket will be shown "[]".
                    <br /> *Pages above 500 return an error, API queries support
                    up to 500 pages, use date parameters in order to filter
                    results and minimize the amount of pages.
                  </td>
                </tr>
                <tr>
                  <td>type</td>
                  <td>
                    1.employees
                    <br />
                    2.users
                    <br />
                    3. all
                  </td>
                  <td>all</td>
                  <td>
                    Cavalier supports two type of credentials: Employees and
                    users (APKs are considered as ‘user’ type). Filtering
                    displays only one type for the desired domain{" "}
                  </td>
                </tr>
                <tr>
                  <td>added_since</td>
                  <td>
                    ISO Date: YYYY-MM-DDThh:mm:ss.sssZ
                    <br />
                    e.g: {new Date().toISOString()}
                  </td>
                  <td>All Time</td>
                  <td>
                    Filter results to show only stealers which were added since
                    a specified date. Results will be sorted according to this
                    parameter in an descending order.
                  </td>
                </tr>
                <tr>
                  <td>added_until</td>
                  <td>
                    ISO Date: YYYY-MM-DDThh:mm:ss.sssZ
                    <br />
                    e.g: {new Date().toISOString()}
                  </td>{" "}
                  <td>All Time</td>
                  <td>
                    Filter results to show only stealers which were added until
                    a specified date. Results will be sorted according to this
                    parameter in an descending order.
                    <br />
                    <br />
                    *When using added_until parameter in the API without
                    explicitly setting the time, the default time is
                    automatically set to 00:00. This means that the provided
                    date becomes non-inclusive. For instance, if added_until is
                    set to{" "}
                    {new Date(new Date().setDate(new Date().getDate()))
                      .toISOString()
                      .substr(0, 10)}
                    , the API will retrieve data up to{" "}
                    {new Date(new Date().setDate(new Date().getDate()))
                      .toISOString()
                      .substr(0, 10)}{" "}
                    at 00:00 .
                  </td>
                </tr>
                <tr>
                  <td>compromised_since</td>
                  <td>
                    ISO Date: YYYY-MM-DDThh:mm:ss.sssZ
                    <br />
                    e.g: {new Date().toISOString()}
                  </td>{" "}
                  <td>All Time</td>
                  <td>
                    Filter results to show only stealers which were compromised
                    since a specified date. Results will be sorted according to
                    this parameter in an descending order.
                  </td>
                </tr>
                <tr>
                  <td>compromised_until</td>
                  <td>
                    ISO Date: YYYY-MM-DDThh:mm:ss.sssZ
                    <br />
                    e.g: {new Date().toISOString()}
                  </td>{" "}
                  <td>All Time</td>
                  <td>
                    Filter results to show only stealers which were compromised
                    until a specified date. Results will be sorted according to
                    this parameter in an descending order.
                    <br />
                    <br />
                    *When using compromised_until parameter in the API without
                    explicitly setting the time, the default time is
                    automatically set to 00:00. This means that the provided
                    date becomes non-inclusive. For instance, if
                    compromised_until is set to{" "}
                    {new Date(new Date().setDate(new Date().getDate()))
                      .toISOString()
                      .substr(0, 10)}
                    , the API will retrieve data up to{" "}
                    {new Date(new Date().setDate(new Date().getDate()))
                      .toISOString()
                      .substr(0, 10)}{" "}
                    at 00:00 .
                  </td>
                </tr>
                {/* <tr>
                <td>count</td>
                <td>Boolean (true/false)</td>
                <td>false</td>
                <td>
                  Displays the total number of stealers found for the domain
                  searched, and the total number of pages. This information is
                  provided to assist developers in determining the number of
                  pages to navigate through to view all the results.
                </td>
              </tr> */}
                <tr>
                  <td>filtered</td>
                  <td>Boolean (true/false)</td>
                  <td>Depends on user's permissions</td>
                  <td>
                    Filter results to show only credentials which are related to
                    the specified domain/s. <br /> *This is only applicable for
                    when "type" parameter is set to "employees".
                  </td>
                </tr>
                <tr>
                  <td>third_party_domains</td>
                  <td>Boolean (true/false)</td>
                  <td>false</td>
                  <td>
                    When set to true, corporate credentials of compromised
                    employees of the searched domain found in external domains
                    will be shown, i.e - in a search for company.com
                    john@company.com logging into zoom.us will be shown.
                    <br /> *This is only applicable for when "type" parameter is
                    set to "employees".
                  </td>
                </tr>
                <tr>
                  <td>installed_software</td>
                  <td>Boolean (true/false)</td>
                  <td>false</td>
                  <td>
                    When set to true, installed software from the compromised
                    computer will be shown.
                  </td>
                </tr>
              </table>
              <br />
              <h4>Body:</h4>
              <table className="docsTable">
                <tr>
                  <th>Parameter</th>
                  <th>Options/Data Type</th>
                  <th>Limitations</th>
                  <th>Example</th>
                  <th>Explanation</th>
                </tr>
                <tr>
                  <td>domains</td>
                  <td>Array</td>
                  <td>Maximum of 50 domains for each request.</td>
                  <td>
                    Example 1 - ["tesla.com", "disney.com"] (Primary domains){" "}
                    <br />
                    <br />
                    Example 2 - ["tesla.taleo.net", "tesla.citrix.com"]
                    (External domains)
                  </td>
                  <td>
                    Set the "domains" parameter to indicate which domain/domains
                    you should receive results from. <br />
                    <br />
                    *External Domains: Note that if external domains were added
                    to your Cavalier account you can search them as well, you
                    can check for your whitelisted external domains under "My
                    Account" in your Cavalier account.
                  </td>
                </tr>
                <tr>
                  <td>keywords</td>
                  <td>Array</td>
                  <td>Maximum of 50 keywords for each request.</td>
                  <td>["vpn","confluence","disney"]</td>
                  <td>
                    Filter the results to only see URLs containing the specified
                    keyword/keywords.
                  </td>
                </tr>
              </table>
              <br />
              <h3>Best Practice:</h3>
              <div
                style={{
                  padding: "1rem",
                  backgroundColor: "#FECD4E",
                  borderRadius: "20px",
                  color: "black",
                }}
              >
                <h5>Date Pagination:</h5>
                <p className="lightText">
                  For optimal performance, it is recommended to split queries
                  into shorter date ranges, for example by specifying a range
                  such as "compromised_since=
                  {new Date(new Date().setDate(new Date().getDate() - 30))
                    .toISOString()
                    .substr(0, 10)}
                  &compromised_until=
                  {new Date(new Date().setDate(new Date().getDate() - 14))
                    .toISOString()
                    .substr(0, 10)}
                  " and then "compromised_since=
                  {new Date(new Date().setDate(new Date().getDate() - 14))
                    .toISOString()
                    .substr(0, 10)}
                  &compromised_until={new Date().toISOString().substr(0, 10)}"
                  in subsequent requests.
                </p>
                <h6>Parameters:</h6>
                <ol className="marginList">
                  <li>
                    compromised_since: The start date for the date range when
                    sorting by "date_compromised".
                  </li>
                  <li>
                    compromised_until: The end date for the date range when
                    sorting by "date_compromised".
                  </li>
                  <li>
                    added_since: The start date for the date range when sorting
                    by "date_uploaded".
                  </li>
                  <li>
                    added_until: The end date for the date range when sorting by
                    "date_uploaded".
                  </li>
                  <li>
                    page: The page number for the pagination. If there is no
                    data in the specified page, the response will be empty.
                  </li>
                  {/* <li>
                  count: Set to "true" to display the total stealers and pages
                  for the specified query.
                </li> */}
                </ol>
                <h5>Sorting by "date_compromised"</h5>
                <p className="lightText">
                  To retrieve data on computers which were compromised between{" "}
                  {new Date(new Date().setDate(new Date().getDate() - 14))
                    .toISOString()
                    .substr(0, 10)}{" "}
                  and {new Date().toISOString().substr(0, 10)} sorted by
                  "date_compromised", use the following query:
                </p>
                <Block
                  copy={true}
                  code={`curl -L -X POST 'https://cavalier.hudsonrock.com/api/json/v2/search-by-domain?compromised_since=${new Date(
                    new Date().setDate(new Date().getDate() - 14)
                  )
                    .toISOString()
                    .substr(0, 10)}&compromised_until=${new Date()
                    .toISOString()
                    .substr(0, 10)}' \
        -H 'api-key: ROCKHUDSONROCK' \
        -H 'Content-Type: application/json' \
        --data-raw '{"domains": ["tesla.com", "disney.com"]}'`}
                  language="bash"
                  showLineNumbers={false}
                />
                <h5>Sorting by "date_uploaded"</h5>
                <p className="lightText">
                  To retrieve data on computers which were added between{" "}
                  {new Date(new Date().setDate(new Date().getDate() - 14))
                    .toISOString()
                    .substr(0, 10)}{" "}
                  and {new Date().toISOString().substr(0, 10)} sorted by
                  "date_uploaded", use the following query:
                </p>
                <Block
                  copy={true}
                  code={`curl -L -X POST 'https://cavalier.hudsonrock.com/api/json/v2/search-by-domain?added_since=${new Date(
                    new Date().setDate(new Date().getDate() - 14)
                  )
                    .toISOString()
                    .substr(0, 10)}&added_until=${new Date()
                    .toISOString()
                    .substr(0, 10)}' \
        -H 'api-key: ROCKHUDSONROCK' \
        -H 'Content-Type: application/json' \
        --data-raw '{"domains": ["tesla.com", "disney.com"]}'`}
                  language="bash"
                  showLineNumbers={false}
                />
                <p className="lightText">
                  We suggest building queries that have a short time frame (1-2
                  months each), running through the pagination until reaching an
                  empty response indicating that there are no more results
                  (square brackets) and then querying the next two months. Here
                  is an example of how some concurrent queries should look like
                  -
                </p>
                <Block
                  copy={true}
                  code={`curl -L -X POST 'https://cavalier.hudsonrock.com/api/json/v2/search-by-domain?compromised_since=2022-01-01&compromised_until=2022-01-31&page=1' \
        -H 'api-key: ROCKHUDSONROCK' \
        -H 'Content-Type: application/json' \
        --data-raw '{"domains": ["infosys.com"]}'`}
                  language="bash"
                  showLineNumbers={false}
                />
                <Block
                  copy={true}
                  code={`curl -L -X POST 'https://cavalier.hudsonrock.com/api/json/v2/search-by-domain?compromised_since=2022-01-01&compromised_until=2022-01-31&page=2' \
        -H 'api-key: ROCKHUDSONROCK' \
        -H 'Content-Type: application/json' \
        --data-raw '{"domains": ["infosys.com"]}'`}
                  language="bash"
                  showLineNumbers={false}
                />

                <p className="lightText">… (pages of pagination)</p>

                <Block
                  copy={true}
                  code={`curl -L -X POST 'https://cavalier.hudsonrock.com/api/json/v2/search-by-domain?compromised_since=2022-01-01&compromised_until=2022-01-31&page=7' \
        -H 'api-key: ROCKHUDSONROCK' \
        -H 'Content-Type: application/json' \
        --data-raw '{"domains": ["infosys.com"]}'`}
                  language="bash"
                  showLineNumbers={false}
                />

                <p className="lightText">Next Timeframe:</p>

                <Block
                  copy={true}
                  code={`curl -L -X POST 'https://cavalier.hudsonrock.com/api/json/v2/search-by-domain?compromised_since=2022-01-31&compromised_until=2022-02-31&page=1' \
        -H 'api-key: ROCKHUDSONROCK' \
        -H 'Content-Type: application/json' \
        --data-raw '{"domains": ["infosys.com"]}'`}
                  language="bash"
                  showLineNumbers={false}
                />

                <p className="lightText">
                  The following are queries for infosys.com between the dates
                  2022-01-01 to 2022-01-31, running through 7 pages until
                  reaching an empty response "[]", and then switching the dates
                  to be between 2022-01-31 to 2022-02-31
                </p>
              </div>
            </>
          ),
        },
        {
          id: 5.8,
          limit: 20,
          label: "assets intelligence",
          headline: "assets intelligence",
          endpoint: "search-by-system",
          example: "rdweb",
          sample: true,
          explain: (
            <div
              className="styledBox no-shadow"
              style={{
                width: "95%",
                margin: "auto",
                backgroundColor: "#1A1F35",
              }}
            >
              <p style={{ marginBottom: "0px", textAlign: "left" }}>
                {
                  "To test requests using your account, replace ROCKHUDSONROCK with your actual API key."
                }
              </p>
            </div>
          ),
          blocks: [
            <Block
              copy={true}
              code={`curl -L -X POST 'https://cavalier.hudsonrock.com/api/json/v2/search-by-system' \
            -H 'api-key: ROCKHUDSONROCK' \
            -H 'Content-Type: application/json' \
    --data-raw '{"system": "rdweb"}'`}
              language="bash"
              showLineNumbers={false}
              title="ASSETS INTELLIGENCE"
            />,
          ],
          content: (
            <>
              <p>
                The problem - Pentesters and MSSPs often require knowing which
                companies use specific technologies in order to check whether
                they are vulnerable to a new CVE that came out or if they can
                discover companies using outdated solutions and offer them
                security products that can help bolster their cybersecurity
                capabilities.{" "}
              </p>

              <p>
                The solution - Hudson Rock offers Pentesters and MSSPs the
                ability to query across hundreds of millions of URLs and find
                which technologies are used by different companies, for instance
                - 1. Pentesters can lookup "Citrix" to identify companies using
                Citrix products and see if they are vulnerable to a new CVE. 2.
                MSSPs can lookup "Roundcube" to discover companies using
                Roundcube as their email provider, and offer them a more secured
                webmail product.
              </p>
              <h4>URL Parameters:</h4>
              <table className="docsTable">
                <tr>
                  <th>Parameter</th>
                  <th>Options/Data Type</th>
                  <th>Default</th>
                  <th>Explanation</th>
                </tr>
                <tr>
                  <td>page</td>
                  <td>Number</td>
                  <td>1</td>
                  <td>
                    The API utilises data pagination, where a maximum of 50
                    documents (stealers) per request are returned. When querying
                    for a specific page, such as page 2, the API will skip the
                    first 50 documents and return the next 50.
                  </td>
                </tr>
              </table>
              <br />
              <table className="docsTable">
                <tr>
                  <th>Field</th>
                  <th>Explanation</th>
                </tr>
                <tr>
                  <td>count</td>
                  <td>
                    The amount of distinct urls which contain the specified
                    term.
                  </td>
                </tr>
                <tr>
                  <td>domain</td>
                  <td>The domain which the urls belong to.</td>
                </tr>
                <tr>
                  <td>urls_list</td>
                  <td>The urls which contain the specified term.</td>
                </tr>
              </table>
              <br />
            </>
          ),
        },
        {
          id: 5.6,
          limit: 10,
          label: "Third Party Risk Assessment",
          headline: "Third Party Risk Assessment",
          endpoint: "search-by-domain/assessment",
          example: "trendmicro.com",
          sample: true,
          explain: (
            <div
              className="styledBox no-shadow"
              style={{
                width: "95%",
                margin: "auto",
                backgroundColor: "#1A1F35",
              }}
            >
              <p style={{ marginBottom: "0px", textAlign: "left" }}>
                {
                  "To test requests using your account, replace ROCKHUDSONROCK with your actual API key."
                }
              </p>
            </div>
          ),
          blocks: [
            <Block
              copy={true}
              code={`curl -L -X POST 'https://cavalier.hudsonrock.com/api/json/v2/search-by-domain/assessment' \
            -H 'api-key: ROCKHUDSONROCK' \
            -H 'Content-Type: application/json' \
    --data-raw '{"domain": "trendmicro.com"}'`}
              language="bash"
              showLineNumbers={false}
              title="ASSESSMENT BY DOMAIN"
            />,
          ],
          content: (
            <>
              <p>
                The Problem: Companies often suffer data breaches caused by
                3rd-parties and are in need of a risk management solution that
                focuses on identifying and reducing risks relating to their
                supply chain (sometimes referred to as vendors, suppliers,
                partners, contractors, or service providers).{" "}
              </p>

              <p>
                The Solution: Hudson Rock provides its clients the ability to
                query a database of over {totalStealers} computers which were
                compromised through info-stealers. The database is updated with
                new compromised computers every day, providing companies the
                ability to gather cybercrime intelligence data regarding
                3rd-party companies related to employee and user computers which
                were compromised through global malware spreading campaigns.{" "}
              </p>
              <br />
              <table className="docsTable">
                <tr>
                  <th>Field</th>
                  <th>Explanation</th>
                </tr>
                <tr>
                  <td>employees_count</td>
                  <td>
                    Amount of compromised employees found for the searched
                    domain, Hudson Rock determines whether the compromised
                    computer belongs to the employee of the company or its
                    client based on the type of credentials (corporate or not)
                    and technologies (sensitive infrastructure) accessed within
                    the company
                  </td>
                </tr>
                <tr>
                  <td>users_count</td>
                  <td>
                    Amount of compromised clients found for the searched domain,
                    Hudson Rock determines whether the compromised computer
                    belongs to the employee of the company or its client based
                    on the type of credentials (corporate or not) and
                    technologies (sensitive infrastructure) accessed within the
                    company
                  </td>
                </tr>
              </table>
            </>
          ),
        },
        {
          id: 5.7,
          label: "External attack surface",
          headline: "External attack surface",
          endpoint: "search-by-domain/discovery",
          example: "trendmicro.com",
          sample: true,
          explain: (
            <div
              className="styledBox no-shadow"
              style={{
                width: "95%",
                margin: "auto",
                backgroundColor: "#1A1F35",
              }}
            >
              <p style={{ marginBottom: "0px", textAlign: "left" }}>
                {
                  "To test requests using your account, replace ROCKHUDSONROCK with your actual API key."
                }
              </p>
            </div>
          ),
          blocks: [
            <Block
              copy={true}
              code={`curl -L -X POST 'https://cavalier.hudsonrock.com/api/json/v2/search-by-domain/discovery' \
            -H 'api-key: ROCKHUDSONROCK' \
            -H 'Content-Type: application/json' \
    --data-raw '{"domain": "trendmicro.com"}'`}
              language="bash"
              showLineNumbers={false}
              title="EXTERNAL ATTACK SURFACE BY DOMAIN"
            />,
          ],
          content: (
            <>
              <p>
                The Problem: Sensitive publicly exposed infrastructure poses
                significant risk to companies as threat actors attempt to hack
                access any way they can, and through any technologies a company
                uses.{" "}
              </p>
              <p>
                The Solution: Hudson Rock provides an External attack surface
                Solution that is derived from URLs accessed by computers that
                were compromised through global malware spreading campaigns
                performed by threat actors. This helps identifying employees of
                companies that had their computers compromised and are accessing
                sensitive infrastructure belonging to the company that cannot be
                found through scraping or otherwise, but is known to threat
                actors attempting to hack companies through shadow IT.
              </p>
              {/* <br />
            <table className="docsTable">
              <tr>
                <th>Parameter</th>
                <th>Options/Data Type</th>
                <th>Default</th>
                <th>Explanation</th>
              </tr>
              <tr>
                <td>type</td>
                <td>
                  1.employees
                  <br />
                  2.users
                </td>
                <td>all</td>
                <td>
                  Cavalier support 2 type of credentials: employees and users
                  (APKs are being considered as user type). You can filter for
                  displaying only one type for the desired domain.
                </td>
              </tr>
            </table> */}
              <br />
            </>
          ),
        },
      ],
    },
    {
      id: 8,
      label: "Common Errors",
      content: (
        <div>
          <h2>Common Errors</h2>
          <br />
          <table className="docsTable">
            <tr>
              <th>Code</th>
              <th>Explanation</th>
              <th>Solution</th>
            </tr>
            <tr>
              <td>400</td>
              <td>
                In most cases the 400 error code indicates that one or more
                parameters in the query are invalid.
              </td>
              <td>
                Review the relevant documentation for the specified endpoint to
                avoid these kind of errors.
              </td>
            </tr>
            <tr>
              <td>401</td>
              <td>Authentication Error.</td>
              <td>
                Make sure you inserted your API key correctly in the request
                header as an "api-key" and that your Cavalier account is active.
              </td>
            </tr>
            <tr>
              <td>403</td>
              <td>Permission Error.</td>
              <td>
                This error indicates that you did manage to authenticate but
                your account doesn't have the right permission to access the
                specified resource, if you think this is a mistake, please
                contact us at support@hudsonrock.com.
              </td>
            </tr>
            <tr>
              <td>408</td>
              <td>Query timeout exceeded.</td>
              <td>
                Error 408 indicates the response to the query is taking too
                long, it typically indicates that the query needs to be
                shortened using the compromised_since and compromised_until
                parameters in a shorter time period (e.g 1 month).{" "}
              </td>
            </tr>
            <tr>
              <td>429</td>
              <td>Too Many Requests.</td>
              <td>
                This error code indicates that you have sent too many requests,
                beyond our 5 requests per second rate limiting, and results in a
                60 seconds timeout.{" "}
              </td>
            </tr>
            <tr>
              <td>524</td>
              <td>Request timeout.</td>
              <td>
                This error code indicates that the server took more than 100
                seconds to respond.
              </td>
            </tr>
          </table>
        </div>
      ),
    },
    {
      id: 7,
      label: "Schedule a demo",
      link: "https://www.hudsonrock.com/schedule-demo",
    },
    {
      id: 6,
      label: "Help",
      headline: "",
      blocks: [<div style={{ paddingTop: "64px" }}></div>],
      content: (
        <div
        // style={{
        //   display: "inline-flex",
        //   justifyContent: "center",
        //   alignItems: "center",
        //   width: "200%",
        // }}
        >
          <div>
            {/* <h3>Brand Protection Pricing:</h3>
            <hr className="text-left" style={{ width: "10%" }}></hr>
            <table className="docsTable">
              <tr>
                <th>Domains</th>
                <th>Cost per Year</th>
              </tr>
              <tr>
                <td>5</td>
                <td>$20,000</td>
              </tr>
              <tr>
                <td>6-10</td>
                <td>$35,000</td>
              </tr>
              <tr>
                <td>11-20</td>
                <td>$50,000</td>
              </tr>
              <tr>
                <td>21-100</td>
                <td>$75,000</td>
              </tr>
              <tr>
                <td>100+</td>
                <td>Custom</td>
              </tr>
            </table> */}
          </div>
          <br />
          <SimpleAccordion />
        </div>
      ),
    },
  ];
}
